import { ofType, ActionsObservable, Epic } from "redux-observable";
import { switchMap, pluck } from "rxjs/operators";

import {
  getResourcesSuccess,
  getResourcesFailure,
} from "../../actions/resources";

import { apiCall } from "../../../services/api";

// Types
import {
  getResourcesPayload,
  getResourcesAction,
} from "../../../models/redux/resources";
import { XHRPayload } from "../../../models/common";

// Get Lessons

export const getResourcesEpic: Epic = (action$: ActionsObservable<getResourcesAction>) =>
  action$.pipe(
    ofType("GET_RESOURCES"),
    pluck("payload"),
    switchMap(getResources),
  );

export const getResources = (payload: getResourcesPayload) => {
  return (
    apiCall(
      `/resources/resources?type=${payload.type}`,
      "GET",
      {},
      (data: XHRPayload) => getResourcesSuccess(data.response),
      getResourcesFailure,
    )
  );
};
