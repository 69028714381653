import React from "react";

import { ReactComponent as CheckmarkIcon } from "../../../../../../assets/images/reports/checkmark.svg";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  points: number;
  totalPoints: number;
  editMode: boolean;
}

export const TestReportStudentPoints = (props: Props) => {
  return (
    <div className={styles.container}>
      <CheckmarkIcon/>
      <div className={styles.textContainer}>
        <h4>{translate("report.gained-points")}</h4>
        <h5>
          <span>
            {props.points && !props.editMode ? `${props.points}` : "--"}
          </span>
          /{props.totalPoints}
        </h5>
      </div>
    </div>
  );
};

export default TestReportStudentPoints;
