import pathOr from "ramda/src/pathOr";

// Types
import { userStateModel, userActionModel } from "../../../models/redux/user";

const initialState: userStateModel = {
  email: "",
  roles: [],
  card_info: {
    last4: "",
    brand: "",
  },
  access: {
    expires_at: "",
    full_access: false,
  },
  app: "",
  currency: "",
  language: "",
  country: "",
  in_progress: {
    billing_card: false,
  },
  errors: {
    billing_card: [],
  },
};

export const user = (state = initialState, action: userActionModel) => {
  switch (action.type) {
    case "SIGN_IN_BY_TOKEN_SUCCESS":
    case "SIGN_UP_SUCCESS":
    case "SIGN_IN_SUCCESS":
    case "SIGN_UP_AS_GUEST_SUCCESS":
    case "UPGRADE_GUEST_ACCOUNT_SUCCESS":
      return {
        ...state,
        email: pathOr("", ["payload", "email"], action),
        roles: pathOr([], ["payload", "roles"], action),
        card_info: pathOr(
          {
            last4: "",
            brand: "",
          },
          ["payload", "card_info"],
          action,
        ),
        access: pathOr(
          {
            expires_at: "",
            full_access: false,
          },
          ["payload", "access"],
          action,
        ),
        app: pathOr("", ["payload", "app"], action),
        currency: pathOr("", ["payload", "currency"], action),
        language: pathOr("", ["payload", "language"], action),
        country: pathOr("", ["payload", "country"], action),
      };
    case "ADD_BILLING_CARD":
    case "DELETE_BILLING_CARD":
      return {
        ...state,
        in_progress: {
          billing_card: true,
        },
        errors: {
          billing_card: [],
        },
      };
    case "ADD_BILLING_CARD_SUCCESS":
      return {
        ...state,
        card_info: pathOr(
          {
            last4: "",
            brand: "",
          },
          ["payload", "data", "card_info"],
          action,
        ),
        in_progress: {
          billing_card: false,
        },
        errors: {
          billing_card: [],
        },
      };
    case "ADD_BILLING_CARD_FAILURE":
    case "DELETE_BILLING_CARD_FAILURE":
      return {
        ...state,
        in_progress: {
          billing_card: false,
        },
        errors: {
          ...state.errors,
          billing_card: pathOr([], ["payload", "errors"], action),
        },
      };
    case "DELETE_BILLING_CARD_SUCCESS":
      return {
        ...state,
        card_info: {
          last4: "",
          brand: "",
        },
        in_progress: {
          billing_card: false,
        },
        errors: {
          billing_card: [],
        },
      };
    case "RESET_BILLING_ERRORS":
      return {
        ...state,
        errors: {
          billing_card: [],
        },
      };
    default:
      return state;
  }
};
