import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import translate from "../../../services/translate";

import { ReactComponent as WarningIcon } from "../../../assets/images/warning.svg";

import Modal from "../Modal";

// Types

import styles from "./styles.module.scss";

interface Props {
  hide: () => any;
  onSubmit: (value?: any) => any;
  inProgress: boolean;
}

export const GeneratePublicLinkModal = (props: Props) => {
  return (
    <Modal hide={props.hide} customStyles={styles.generateNewPublicLinkModal}>
      <div className={styles.generateNewPublicLinkContainer}>
        <h2>
          <WarningIcon/>
          &nbsp;
          <span>{translate("global.warning")}</span>
        </h2>
        <span className={styles.description}>
          {translate("common.generate-new-public-link-description")}
        </span>
        <span className={styles.description}>
          {translate("common.do-you-want-to-continue")}
        </span>
        <div className={styles.buttonContainer}>
          <button type="button" onClick={props.hide}>
            {translate("global.no")}
          </button>
          <button
            type="button"
            disabled={props.inProgress}
            onClick={props.onSubmit}
          >
            {props.inProgress ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <span>{translate("global.yes")}</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GeneratePublicLinkModal;
