// Types
import {
  addBillingCardAction,
  addBillingCardPayload,
  addBillingCardSuccessAction,
  addBillingCardSuccessPayload,
  addBillingCardFailureAction,
  deleteBillingCardAction,
  deleteBillingCardSuccessAction,
  deleteBillingCardFailureAction,
  resetBillingErrorsAction,
  getBillingNextPaymentAction,
  getBillingNextPaymentSuccessAction,
  getBillingNextPaymentFailureAction,
  getBillingNextPaymentSuccessPayload,
  updateBillingSettingsPayload,
  updateBillingSettingsAction,
  updateBillingSettingsSuccessAction,
  updateBillingSettingsFailureAction,
  getDiscountCodesAction,
  getDiscountCodesSuccessPayload,
  getDiscountCodesSuccessAction,
  getDiscountCodesFailureAction,
  updateDiscountCodesAction,
  updateDiscountCodesPayload,
  updateDiscountCodesSuccessAction,
  updateDiscountCodesFailureAction,
  updateInvoiceDataPayload,
  updateInvoiceDataAction,
  updateInvoiceDataSuccessAction,
  updateInvoiceDataFailureAction,
  getInvoiceDataAction,
  getInvoiceDataSuccessAction,
  getInvoiceDataFailureAction,
  getInvoiceDataPayload,
  validateReferrerAccountIDPayload,
  validateReferrerAccountIDAction,
  validateReferrerAccountIDSuccessAction,
  validateReferrerAccountIDFailureAction,
  getReferralsAction,
  getReferralsSuccessPayload,
  getReferralsSuccessAction,
  getReferralsFailureAction,
} from "../../../models/redux/billing";
import { ErrorsPayload } from "../../../models/common";

// Add Billing Card

export const addBillingCard = (payload: addBillingCardPayload): addBillingCardAction => {
  return {
    type: "ADD_BILLING_CARD",
    payload,
  };
};

export const addBillingCardSuccess = (payload: addBillingCardSuccessPayload): addBillingCardSuccessAction => {
  return {
    type: "ADD_BILLING_CARD_SUCCESS",
    payload,
  };
};

export const addBillingCardFailure = (payload: ErrorsPayload): addBillingCardFailureAction => {
  return {
    type: "ADD_BILLING_CARD_FAILURE",
    payload,
  };
};

// Delete Billing Card

export const deleteBillingCard = (): deleteBillingCardAction => {
  return {
    type: "DELETE_BILLING_CARD",
  };
};

export const deleteBillingCardSuccess = (): deleteBillingCardSuccessAction => {
  return {
    type: "DELETE_BILLING_CARD_SUCCESS",
  };
};

export const deleteBillingCardFailure = (payload: ErrorsPayload): deleteBillingCardFailureAction => {
  return {
    type: "DELETE_BILLING_CARD_FAILURE",
    payload,
  };
};

// Get Billing Next Payment

export const getBillingNextPayment = (): getBillingNextPaymentAction => {
  return {
    type: "GET_BILLING_NEXT_PAYMENT",
  };
};

export const getBillingNextPaymentSuccess = (payload: getBillingNextPaymentSuccessPayload): getBillingNextPaymentSuccessAction => {
  return {
    type: "GET_BILLING_NEXT_PAYMENT_SUCCESS",
    payload,
  };
};

export const getBillingNextPaymentFailure = (payload: ErrorsPayload): getBillingNextPaymentFailureAction => {
  return {
    type: "GET_BILLING_NEXT_PAYMENT_FAILURE",
    payload,
  };
};

// Update Billing Settings

export const updateBillingSettings = (payload: updateBillingSettingsPayload): updateBillingSettingsAction => {
  return {
    type: "UPDATE_BILLING_SETTINGS",
    payload,
  };
};

export const updateBillingSettingsSuccess = (): updateBillingSettingsSuccessAction => {
  return {
    type: "UPDATE_BILLING_SETTINGS_SUCCESS",
  };
};

export const updateBillingSettingsFailure = (payload: ErrorsPayload): updateBillingSettingsFailureAction => {
  return {
    type: "UPDATE_BILLING_SETTINGS_FAILURE",
    payload,
  };
};

// Get Discount Codes

export const getDiscountCodes = (): getDiscountCodesAction => {
  return {
    type: "GET_DISCOUNT_CODES",
  };
};

export const getDiscountCodesSuccess = (payload: getDiscountCodesSuccessPayload): getDiscountCodesSuccessAction => {
  return {
    type: "GET_DISCOUNT_CODES_SUCCESS",
    payload,
  };
};

export const getDiscountCodesFailure = (payload: ErrorsPayload): getDiscountCodesFailureAction => {
  return {
    type: "GET_DISCOUNT_CODES_FAILURE",
    payload,
  };
};

// Upade Discount Codes

export const updateDiscountCodes = (payload: updateDiscountCodesPayload): updateDiscountCodesAction => {
  return {
    type: "UPDATE_DISCOUNT_CODES",
    payload,
  };
};

export const updateDiscountCodesSuccess = (): updateDiscountCodesSuccessAction => {
  return {
    type: "UPDATE_DISCOUNT_CODES_SUCCESS",
  };
};

export const updateDiscountCodesFailure = (payload: ErrorsPayload): updateDiscountCodesFailureAction => {
  return {
    type: "UPDATE_DISCOUNT_CODES_FAILURE",
    payload,
  };
};

// Get Invoice Data

export const getInvoiceData = (): getInvoiceDataAction => {
  return {
    type: "GET_INVOICE_DATA",
  };
};

export const getInvoiceDataSuccess = (payload: getInvoiceDataPayload): getInvoiceDataSuccessAction => {
  return {
    type: "GET_INVOICE_DATA_SUCCESS",
    payload,
  };
};

export const getInvoiceDataFailure = (payload: ErrorsPayload): getInvoiceDataFailureAction => {
  return {
    type: "GET_INVOICE_DATA_FAILURE",
    payload,
  };
};

// Upade Invoice Data

export const updateInvoiceData = (payload: updateInvoiceDataPayload, callback?: () => any): updateInvoiceDataAction => {
  return {
    type: "UPDATE_INVOICE_DATA",
    payload,
    callback,
  };
};

export const updateInvoiceDataSuccess = (callback?: () => any): updateInvoiceDataSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "UPDATE_INVOICE_DATA_SUCCESS",
  };
};

export const updateInvoiceDataFailure = (payload: ErrorsPayload): updateInvoiceDataFailureAction => {
  return {
    type: "UPDATE_INVOICE_DATA_FAILURE",
    payload,
  };
};

// Validate Referrer Account ID

export const validateReferrerAccountID = (payload: validateReferrerAccountIDPayload): validateReferrerAccountIDAction => {
  return {
    type: "VALIDATE_REFERRER_ACCOUNT_ID",
    payload,
  };
};

export const validateReferrerAccountIDSuccess = (): validateReferrerAccountIDSuccessAction => {
  return {
    type: "VALIDATE_REFERRER_ACCOUNT_ID_SUCCESS",
  };
};

export const validateReferrerAccountIDFailure = (payload: ErrorsPayload): validateReferrerAccountIDFailureAction => {
  return {
    type: "VALIDATE_REFERRER_ACCOUNT_ID_FAILURE",
    payload,
  };
};

// Get Referrals

export const getReferrals = (): getReferralsAction => {
  return {
    type: "GET_REFERRALS",
  };
};

export const getReferralsSuccess = (payload: getReferralsSuccessPayload): getReferralsSuccessAction => {
  return {
    type: "GET_REFERRALS_SUCCESS",
    payload,
  };
};

export const getReferralsFailure = (payload: ErrorsPayload): getReferralsFailureAction => {
  return {
    type: "GET_REFERRALS_FAILURE",
    payload,
  };
};

// Other

export const resetBillingErrors = (): resetBillingErrorsAction => {
  return {
    type: "RESET_BILLING_ERRORS",
  };
};
