import React from "react";
import classNames from "classnames";

import { ReactComponent as File } from "../../../../../assets/images/test_details/file.svg";

import styles from "./styles.module.scss";


interface Props {
  mode: string;
  setMode: (value: string) => any;
}

export const TestDetailsSidebarLeft = (props: Props) => {
  const isActive = (value: string) => classNames({
    [styles.active]: props.mode === value,
  });

  return (
    <div className={styles.sidebarLeft}>
      <button
        type="button"
        className={isActive("previewMode")}
        onClick={() => props.setMode("previewMode")}
      >
        <File/>
      </button>
    </div>
  );
};

export default TestDetailsSidebarLeft;
