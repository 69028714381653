import React, { useState, useRef, useEffect } from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";

import { debounce } from "../../../../../../../../services/common";
import translate from "../../../../../../../../services/translate";

import styles from "./styles.module.scss";

// Types
import {
  replaceQuestionGap as replaceQuestionGapFunction,
  gapModel,
} from "../../../../../../../../models/redux/test";

interface Props {
  testID: string;
  classID: string;
  questionID: string;
  questionBody: any;
  updateQuestion: (body_html?: string, gaps?: string[][]) => any;
  replaceQuestionGap: replaceQuestionGapFunction;
  hasError: boolean;
  gaps: gapModel[];
}

export const FillInGapsBody = (props: Props) => {
  const bodyElement = useRef(null) as any;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (bodyElement.current && props.questionBody) {
      if (bodyElement.current.innerHTML === "") {
        bodyElement.current.innerHTML = props.questionBody.html;
      }
    }
  }, [props.questionBody, bodyElement]);

  const Sanatize = (value: string) => {
    return DOMPurify.sanitize(value, { ALLOWED_TAGS: ["input", "br"] });
  };

  const SetSanatizedValue = (value: string) => {
    return setValue(Sanatize(value));
  };

  useEffect(() => {
    if (bodyElement.current) {
      bodyElement.current.addEventListener("paste", (e: any) => {
        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData("text/plain");
        window.document.execCommand(
          "insertText",
          false,
          Sanatize(text.replace(/[^a-zA-Z0-9_ ]/g, " ")),
        );
      });
    }
  }, [bodyElement]);

  const createInput = () => {
    return "<input value='' />";
  };

  const updateQuestion = debounce((val) => {
    props.updateQuestion(val);
  }, 500);

  const contentEditableClass = classNames({
    [styles.contentEditable]: true,
    [styles.contentEditableError]: props.hasError,
  });

  return (
    <>
      <div
        contentEditable
        suppressContentEditableWarning
        ref={bodyElement}
        className={contentEditableClass}
        onBlur={(e) => {
          let gaps = Array.from(
            e.currentTarget.getElementsByTagName("input"),
          ).map((c) => [c.value]) as string[][];

          if (props.gaps.length) {
            gaps = gaps.map((gap, index) => {
              return Object.assign(
                [],
                props.gaps.map((item) => item.names)[index],
                // eslint-disable-next-line no-useless-computed-key
                { [0]: gap },
              ).flat();
            });
          }

          if (gaps.length) {
            props.replaceQuestionGap({
              testID: props.testID,
              classID: props.classID,
              questionID: props.questionID,
              names: gaps,
            });
          } else {
            props.replaceQuestionGap({
              testID: props.testID,
              classID: props.classID,
              questionID: props.questionID,
              names: [],
            });
          }

          const innerHTML = e.currentTarget.innerHTML;

          if (innerHTML.includes("<input")) {
            const splittedHTML = innerHTML
              .replace(/<input value=(.*?)>/g, "[[gap]]")
              .split("[[gap]]");

            const updatedHTML = splittedHTML
              .map((txt, index) => {
                if (index + 1 <= splittedHTML.length - 1) {
                  const value = gaps[index][0];
                  return txt + `<input value="${value}">`;
                }
                return txt;
              })
              .join("");

            SetSanatizedValue(updatedHTML);
            updateQuestion(Sanatize(updatedHTML));
          } else {
            SetSanatizedValue(innerHTML);
            updateQuestion(Sanatize(innerHTML));
          }
        }}
        onInput={(e) => {
          SetSanatizedValue(e.currentTarget.innerHTML);
          updateQuestion(Sanatize(e.currentTarget.innerHTML));
        }}
      />
      <button
        className={styles.addGapButton}
        onClick={() => {
          if (window.getSelection) {
            var sel, range;
            sel = window.getSelection();

            if (sel) {
              const updatedVal = Sanatize(`${value} ${createInput()}`);
              if (!sel.getRangeAt || !sel.rangeCount || sel.type === "None") {
                SetSanatizedValue(updatedVal);
                if (bodyElement.current) {
                  bodyElement.current.innerHTML = updatedVal;
                }
                updateQuestion(updatedVal);
                return;
              }

              if (
                sel.getRangeAt(0).startContainer.parentNode !==
                bodyElement.current
              ) {
                SetSanatizedValue(updatedVal);
                if (bodyElement.current) {
                  bodyElement.current.innerHTML = updatedVal;
                }
                updateQuestion(updatedVal);
              } else if (
                sel.getRangeAt &&
                sel.rangeCount &&
                sel.getRangeAt(0).startContainer.parentNode ===
                  bodyElement.current
              ) {
                range = sel.getRangeAt(0);
                range.deleteContents();
                var el = document.createElement("div");
                el.innerHTML = createInput();
                var frag = document.createDocumentFragment(),
                  node,
                  lastNode;
                while ((node = el.firstChild)) {
                  lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);

                if (lastNode) {
                  range = range.cloneRange();
                  range.setStartAfter(lastNode);
                  range.collapse(true);
                  sel.removeAllRanges();
                  sel.addRange(range);
                }

                if (range.startContainer) {
                  const startContainer = range.startContainer as any;
                  SetSanatizedValue(startContainer.innerHTML);
                  updateQuestion(Sanatize(startContainer.innerHTML));
                }
              }
            }
          }
        }}
      >
        <span>+ {translate("creator.add-gap")}</span>
      </button>
    </>
  );
};

export default FillInGapsBody;
