import React, { useState, useEffect } from "react";
import propOr from "ramda/src/propOr";

import { getErrorMessage } from "../../../../../../../services/common";
import { ErrorObject } from "../../../../../../../models/common";

import QuestionBodyEditor from "./Editor";
import FillInGapsBody from "./FillInGaps";

// Types
import {
  updateQuestion as updateQuestionFunction,
  replaceQuestionGap as replaceQuestionGapFunction,
  QuestionTypes,
  gapModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  id: string;
  testID: string;
  classID: string;
  type: string;
  order: number;
  questionBodyHTML: string | null;
  questionBody: string | null;
  testErrors: ErrorObject[];
  updateQuestion: updateQuestionFunction;
  questionInProgress: boolean;
  replaceQuestionGap: replaceQuestionGapFunction;
  gaps: gapModel[];
}

export const QuestionBody = (props: Props) => {
  const [questionBody, setQuestionBody] = useState({
    html: propOr("", "questionBodyHTML", props),
    plain: propOr("", "questionBody", props),
  });

  useEffect(() => {
    setQuestionBody({
      html: propOr("", "questionBodyHTML", props),
      plain: propOr("", "questionBody", props),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionBody, props.questionBodyHTML]);

  const getError = (id: string) => getErrorMessage(props.testErrors, `${id}/body`) || getErrorMessage(props.testErrors, `questions/${id}/gaps`);

  return (
    <div className={styles.container}>
      {props.type === QuestionTypes.FILL_IN_THE_GAPS ?
        <FillInGapsBody
          questionBody={questionBody}
          updateQuestion={(body_html?: string, gaps?: string[][]) => {
            props.updateQuestion({
              questionID: props.id,
              testID: props.testID,
              classID: props.classID,
              body_html: body_html ? body_html : "",
              body: body_html ? body_html : "",
              gaps,
            });
          }}
          testID={props.testID}
          classID={props.classID}
          questionID={props.id}
          replaceQuestionGap={props.replaceQuestionGap}
          gaps={props.gaps}
          hasError={!!getError(props.id)}
        /> :
        <QuestionBodyEditor
          questionBody={questionBody}
          setQuestionBody={setQuestionBody}
          type={props.type}
          updateQuestion={(body_html?: string, body?: string) => {
            props.updateQuestion({
              questionID: props.id,
              testID: props.testID,
              classID: props.classID,
              body_html: body_html ? body_html : propOr("", "html", questionBody),
              body: body ? body : propOr("", "plain", questionBody),
            });
          }}
          hasError={!!getError(props.id)}
        />
      }
      {getError(props.id) &&
        <span className={styles.error}>{getError(props.id)}</span>
      }
    </div>
  );
};

export default QuestionBody;
