import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as GirlIcon } from "../../../assets/images/plan_limit/girl.svg";
import { ReactComponent as TestIcon } from "../../../assets/images/plan_limit/test.svg";

import translate from "../../../services/translate";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  history: History;
  match: any;
}

export const PlanLimit = (props: Props) => {
  const isClassType = props.match.params.type === "class";
  const isTestType = props.match.params.type === "test";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <span>{translate("global.with-wisdy")}</span>
          <span className={styles.pink}>
            &nbsp;{translate("global.premium")}&nbsp;
          </span>
          <span>{translate("global.you-can-do-more")}</span>
        </h3>
      </div>
      {isClassType && <GirlIcon/>}
      {isTestType && <TestIcon/>}
      <p>
        <span>
          {translate(`free-plan-limit-${props.match.params.type}-description`)}
          .&nbsp;
        </span>
        <span>{translate("global.free-plan-limit-exceeded")}.</span>
        <span>{translate("global.premium-plan-description")}</span>
      </p>
      <Link to="/profile/billing">
        <span>{translate("global.premium-account")}</span>
      </Link>
      <div className={styles.bottomContainer}>
        <button
          type="button"
          onClick={() =>
            isClassType ? props.history.push("/") : props.history.back()
          }
        >
          {translate("global.back")}
        </button>
      </div>
    </div>
  );
};

export default PlanLimit;
