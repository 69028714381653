import React from "react";
import pathOr from "ramda/src/pathOr";

import { Link } from "react-router-dom";

import translate from "../../../../../../../services/translate";
import { getSubjectIcon } from "../../../../../../../services/common/getSubjectIcon";

import { isWindowSmallScreen } from "../../../../../../../services/common";

// Types
import { History } from "history";
import { testModel } from "../../../../../../../models/redux/class";
import { testsBaseTestModel } from "../../../../../../../models/redux/testsBase";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  item: testModel | testsBaseTestModel;
}

export const TestsListItemGlobal = (props: Props) => {
  const itemClick = (e: any) => {
    if (isWindowSmallScreen()) {
      props.history.push(`/test?testID=${props.item.id}`);
    }
  };

  return (
    <li className={styles.listItem} onClick={itemClick} onTouchEnd={() => {}}>
      <span>{props.item.name}</span>
      {getSubjectIcon(props.item.subject.type)}
      <div className={styles.infoContainer}>
        {pathOr("", ["item", "is_new"], props) && (
          <span className={styles.newItem}>{translate("global.new")}</span>
        )}
        {pathOr("", ["item", "questions_count"], props) && (
          <div className={styles.numberOfQuestionsContainer}>
            <span className={styles.title}>
              {translate("tests.number-of-questions")}:
            </span>
            <span className={styles.number}>
              {pathOr("", ["item", "questions_count"], props)}
            </span>
          </div>
        )}
      </div>
      <Link className={styles.seeMoreLink} to={`/test?testID=${props.item.id}`}>
        <span>{translate("global.see-more")}</span>
      </Link>
    </li>
  );
};

export default TestsListItemGlobal;
