import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import equals from "ramda/src/equals";
import uniq from "ramda/src/uniq";
import concat from "ramda/src/concat";
import reject from "ramda/src/reject";

import TestsListItem from "../Class/Tests/List/Item";
import Checkbox from "../../Common/Checkbox";

import { getAllTests } from "../../../redux/actions/tests";
import { deleteTest } from "../../../redux/actions/test";
import { getSubjects } from "../../../redux/actions/teacher";

import translate from "../../../services/translate";
import { getSubjectIcon } from "../../../services/common/getSubjectIcon";

// Types
import { History } from "history";
import {
  testsStateModel,
  getAllTests as getAllTestsFunction,
  testModel,
} from "../../../models/redux/tests";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../models/redux/teacher";
import { deleteTest as deleteTestFunction } from "../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  getAllTests: getAllTestsFunction;
  getSubjects: getSubjectsFunction;
  deleteTest: deleteTestFunction;
  tests: testModel[];
  teacher: teacherStateModel;
}

export const Tests = (props: Props) => {
  const [selectedFilterItems, updateSelectedFilterItems] = useState<string[]>(
    [],
  );

  useEffect(() => {
    props.getSubjects();
    props.getAllTests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedFilterItems.length) {
      const teacherSubjects = props.teacher.subjects
        .filter((item) => item.is_chosen)
        .map((subject) => ({
          id: subject.id,
          name: subject.name,
          type: subject.type,
        }));
      if (teacherSubjects.length) {
        const typesOfTeacherSubjects = teacherSubjects.map((item) => item.type);
        if (!equals(typesOfTeacherSubjects, selectedFilterItems)) {
          updateSelectedFilterItems(typesOfTeacherSubjects);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tests, props.teacher.subjects]);

  const getFilterList = () => {
    const testsSubjects = props.tests
      .map((item) => item.subject)
      .map((subject) => ({
        id: subject.id,
        name: subject.name,
        type: subject.type,
      }));
    const teacherSubjects = props.teacher.subjects
      .filter((item) => item.is_chosen)
      .map((subject) => ({
        id: subject.id,
        name: subject.name,
        type: subject.type,
      }));

    return uniq(concat(testsSubjects, teacherSubjects));
  };

  const isFilterItemSelected = (type: string) =>
    selectedFilterItems.includes(type);

  const changeSelectedFilterItems = (type: string) => {
    if (!isFilterItemSelected(type)) {
      const selectedItems = uniq(selectedFilterItems.concat(type));
      updateSelectedFilterItems(selectedItems);
      return;
    }

    const selectedItems = reject(
      (val: any) => val === type,
      selectedFilterItems,
    );

    updateSelectedFilterItems(selectedItems);
  };

  const filterItemClass = (type: string) =>
    classNames({
      [styles.active]: isFilterItemSelected(type),
    });

  const getAllTestsList = () => {
    return props.tests.filter((test) =>
      selectedFilterItems.includes(test.subject.type),
    );
  };

  return (
    <section className={styles.container}>
      <h1>{translate("global.all-tests")}</h1>
      <ul className={styles.filters}>
        <span>{translate("global.subjects")}:</span>
        {getFilterList().map((item: any) => (
          <li key={item.type}>
            <button
              className={filterItemClass(item.type)}
              onClick={() => changeSelectedFilterItems(item.type)}
            >
              <Checkbox
                checked={isFilterItemSelected(item.type)}
                onClick={() => {}}
              />
              <span>{item.name}</span>
              {getSubjectIcon(item.type)}
            </button>
          </li>
        ))}
      </ul>
      {!props.tests.length && (
        <div className={styles.placeholder}>
          <div className={styles.createTestContainer}>
            <h3>{translate("global.this-place-is-empty")}</h3>
            <Link
              to="/test/creator/select-class"
              className={styles.createTestLink}
            >
              <span>+</span>
              <span>
                {translate("global.create")} {translate("global.new")}{" "}
                {translate("global.test")}
              </span>
            </Link>
          </div>
        </div>
      )}
      {!!props.tests.length && (
        <div className={styles.tests}>
          <h3>{translate("tests.all-tests-prefix")}</h3>
          <ul className={styles.allTestsList}>
            {getAllTestsList().map((item: testModel) => (
              <TestsListItem
                key={`all_tests_${item.id}`}
                history={props.history}
                classID={item.class_id}
                item={item}
                deleteTest={props.deleteTest}
              />
            ))}
          </ul>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: {
  tests: testsStateModel;
  teacher: teacherStateModel;
}) => ({
  teacher: state.teacher,
  tests: state.tests.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAllTests,
      getSubjects,
      deleteTest,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tests);
