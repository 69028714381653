import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import ReactTooltip from "react-tooltip";

import Input from "../../../Common/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translate from "../../../../services/translate";
import {
  getErrorMessage,
  freemiumUserMaxClassroomLimit,
} from "../../../../services/common";

import { createClass, unmountTeacher } from "../../../../redux/actions/teacher";

// Types
import { History } from "history";
import {
  teacherStateModel,
  createClass as createClassFunction,
} from "../../../../models/redux/teacher";
import { ErrorsPayload } from "../../../../models/common";
import { userStateModel } from "../../../../models/redux/user";
import { classModel } from "../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  isLoading: boolean;
  errors: ErrorsPayload[];
  unmountTeacher: any;
  createClass: createClassFunction;
  userCountry: string;
  classes: classModel[];
  hasFullAccess: boolean;
}

export const AddClass = (props: Props) => {
  const [class_name, setClassName] = useState("");
  const [number_of_students, setNumberOfStudents] = useState("");

  const hasFreemiumLimit = () =>
    !props.hasFullAccess &&
    props.classes.length === freemiumUserMaxClassroomLimit;

  useEffect(() => {
    if (hasFreemiumLimit()) {
      props.history.push("/plan-limit/class");
    }

    return () => {
      props.unmountTeacher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasFreemiumLimit()) {
      props.history.push("/plan-limit/class");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classes]);

  const isNextButtonDisabled = () =>
    !class_name.length ||
    !number_of_students ||
    Number(number_of_students) < 1 ||
    hasFreemiumLimit();

  return (
    <section className={styles.mainContainer}>
      {!hasFreemiumLimit() && (
        <section className={styles.container}>
          <div className={styles.top}>
            <h2>
              {translate("global.create")}&nbsp;
              <span className={styles.primaryHeadingText}>
                {translate("class.class-accusative")}
              </span>
            </h2>
            <p>{translate("class.create-class-desc")}</p>
            <div className={styles.box}>
              <div className={styles.inputContainer}>
                <Input
                  id="class_name"
                  type="text"
                  name="class_name"
                  value={class_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setClassName(e.target.value)
                  }
                  label={translate("class.classname")}
                  error={getErrorMessage(props.errors, "name")}
                />
              </div>
              <div className={styles.inputContainer}>
                <Input
                  id="number_of_students"
                  type="number"
                  name="number_of_students"
                  value={number_of_students}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNumberOfStudents(e.target.value)
                  }
                  label={translate("class.number-of-students")}
                  error={getErrorMessage(props.errors, "number_of_students")}
                />
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <div className={styles.tooltipContainer}>
                {hasFreemiumLimit() && (
                  <ReactTooltip
                    id="tooltip"
                    place="top"
                    effect="solid"
                    className={styles.tooltip}
                  >
                    <span>
                      {translate(
                        "class.classes-limit-paid-version-description",
                      )}
                    </span>
                  </ReactTooltip>
                )}
                <div data-tip data-for="tooltip">
                  <button
                    className={styles.nextButton}
                    onClick={() => {
                      props.createClass({
                        name: class_name,
                        number_of_students: Number(number_of_students),
                        history: props.history,
                        selected_curriculums: [],
                      });
                    }}
                    disabled={isNextButtonDisabled() || props.isLoading}
                  >
                    {props.isLoading ? (
                      <FontAwesomeIcon
                        className="fa-spin"
                        icon="circle-notch"
                      />
                    ) : (
                      <>{translate("global.next")}</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  user: userStateModel;
}) => ({
  isLoading: state.teacher.isLoading,
  classes: state.teacher.classes,
  hasFullAccess: state.user.access.full_access,
  errors: state.teacher.errors,
  userCountry: state.user.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createClass,
      unmountTeacher,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClass);
