import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import DOMPurify from "dompurify";
import queryString from "query-string";
import propOr from "ramda/src/propOr";
import pathOr from "ramda/src/pathOr";

import translate from "../../../../../services/translate";

import { ReactComponent as ArrowLeft } from "../../../../../assets/images/arrow_left.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/referral/copy.svg";

import { copyToClipboard } from "../../../../../services/common/copyToClipboard";

import LessonDetailsNav from "./Nav";
import { ToggleContent } from "../../../../../components/Common/Modal";
import Toggle from "../../../../Common/Toggle";
import GeneratePublicLinkModal from "../../../../Common/GeneratePublicLinkModal";
import DuplicateLessonModal from "../../../../Common/DuplicateLessonModal";

import {
  getLesson,
  unmountLesson,
  lessonGenerateAccessToken,
  publishLesson,
  unpublishLesson,
} from "../../../../../redux/actions/lesson";
import { showToast } from "../../../../../redux/actions/app";

// Types
import { Location, History } from "history";
import {
  lessonStateModel,
  getLesson as getLessonFunction,
  lessonGenerateAccessToken as lessonGenerateAccessTokenFunction,
  publishLesson as publishLessonFunction,
  unpublishLesson as unpublishLessonFunction,
  lessonStatus,
} from "../../../../../models/redux/lesson";
import { authStateModel } from "../../../../../models/redux/auth";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

interface Props {
  location: Location;
  history: History;
  lesson: lessonStateModel;
  getLesson: getLessonFunction;
  match: any;
  unmountLesson: () => any;
  auth: authStateModel;
  showToast: any;
  lessonGenerateAccessToken: lessonGenerateAccessTokenFunction;
  publishLesson: publishLessonFunction;
  unpublishLesson: unpublishLessonFunction;
}

export const LessonDetails = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const accessToken = propOr("", "access-token", parsed) as string;
  const classID = pathOr("", ["match", "params", "classID"], props);
  const lessonID = pathOr("", ["match", "params", "lessonID"], props);
  const isPublic = props.location.pathname.startsWith("/lessons");

  useEffect(() => {
    props.getLesson({ lesson_id: lessonID, access_token: accessToken });

    return () => {
      props.unmountLesson();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getLesson({ lesson_id: lessonID, access_token: accessToken });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lesson.status]);

  const isLessonPublic = props.lesson.status === lessonStatus.public;

  console.log(DOMPurify.sanitize(props.lesson.goal));

  return (
    <section className={styles.container}>
      {isPublic && <LessonDetailsNav/>}
      {!isPublic && (
        <div className={styles.header}>
          <h3>
            <Link to={`/class/${classID}/lessons`}>
              <ArrowLeft/>
            </Link>
            <span>{translate("global.lesson")}</span>
          </h3>
          {props.lesson.status && (
            <div className={styles.centerBox}>
              <span className={styles.sectionText}>
                {translate("lessons.lesson-status")}:
              </span>
              <Toggle
                leftText={translate("lessons.private-lesson-short")}
                rightText={translate("lessons.public-lesson-short")}
                onChange={() => {
                  if (isLessonPublic) {
                    props.unpublishLesson({ lesson_id: lessonID });
                    return;
                  }
                  props.publishLesson({ lesson_id: lessonID });
                }}
                isOn={isLessonPublic}
                isDisabled={props.lesson.in_progress.update_lesson}
              />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <ToggleContent
              toggle={(show: any) => (
                <button type="button" onClick={show}>
                  <span>{translate("lessons.duplicate-a-lesson")}</span>
                </button>
              )}
              content={(hide: () => {}) => (
                <DuplicateLessonModal lessonID={lessonID} hide={hide}/>
              )}
            />
            <Link to={`/class/${classID}/lessons/${lessonID}/edit`}>
              <span>{translate("lessons.edit-lesson")}</span>
            </Link>
          </div>
        </div>
      )}
      <div className={styles.view}>
        {!isPublic && (
          <div className={styles.settings}>
            <span className={styles.sectionText}>
              {translate("lessons.public-link-to-lesson")}
            </span>
            <div className={styles.settingsBox}>
              <div className={styles.generatePublicLinkBox}>
                <p>
                  {props.lesson.access_token
                    ? translate("lessons.public-link-to-lesson-description")
                    : translate(
                      "lessons.public-link-to-lesson-generate-description",
                    )}
                </p>
                <div className={styles.right}>
                  <div className={styles.inputContainer}>
                    <input
                      id="lesson_access_token_input"
                      type="text"
                      value={
                        props.lesson.access_token
                          ? `${window.location.origin}/lessons/${lessonID}?access-token=${props.lesson.access_token}`
                          : ""
                      }
                      onChange={() => {}}
                    />
                    <button
                      type="button"
                      onClick={
                        props.lesson.access_token
                          ? () =>
                            copyToClipboard("lesson_access_token_input", () =>
                              props.showToast({
                                text: "global.copied",
                                status: "success",
                              }),
                            )
                          : () =>
                            props.lessonGenerateAccessToken({
                              lesson_id: lessonID,
                            })
                      }
                    >
                      <CopyIcon/>
                      &nbsp;&nbsp;
                      <span>
                        {props.lesson.access_token
                          ? translate("global.copy")
                          : translate("lessons.generate")}
                      </span>
                    </button>
                  </div>
                  {props.lesson.access_token && (
                    <ToggleContent
                      toggle={(show: any) => (
                        <button
                          type="button"
                          className={styles.regenerateLinkButton}
                          onClick={show}
                        >
                          {translate("lessons.generate-new-lesson-public-link")}
                        </button>
                      )}
                      content={(hide: () => {}) => (
                        <GeneratePublicLinkModal
                          hide={hide}
                          onSubmit={() =>
                            props.lessonGenerateAccessToken(
                              { lesson_id: lessonID },
                              hide,
                            )
                          }
                          inProgress={props.lesson.in_progress.update_lesson}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.top}>
            <span className={styles.sectionText}>
              {translate("lessons.lesson-topic")}
            </span>
            <div className={styles.right}>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("class.classname")}:&nbsp;
                </span>
                <span className={styles.violet}>{props.lesson.class_name}</span>
              </div>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("global.subject")}:&nbsp;
                </span>
                <span className={styles.violet}>
                  {props.lesson.subject_name}
                </span>
              </div>
            </div>
          </div>
          <h1>{props.lesson.topic}</h1>
        </div>
        <div className={styles.innerView}>
          {!!props.lesson.goal && (
            <div className={styles.box}>
              <span className={styles.sectionText}>
                {translate("lessons.lesson-goal")}
              </span>
              <div
                className={`${styles.lessonHTMLText} lessonHTMLGlobal`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.lesson.goal, {
                    ADD_TAGS: ["iframe"],
                  }),
                }}
              ></div>
            </div>
          )}
          {!!props.lesson.success_criteria && (
            <div className={styles.box}>
              <span className={styles.sectionText}>
                {translate("lessons.lesson-success-criteria")}
              </span>
              <div
                className={`${styles.lessonHTMLText} lessonHTMLGlobal`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.lesson.success_criteria, {
                    ADD_TAGS: ["iframe"],
                  }),
                }}
              ></div>
            </div>
          )}
          {!!props.lesson.description && (
            <div className={styles.box}>
              <span className={styles.sectionText}>
                {translate("lessons.lesson-description")}
              </span>
              <div
                className={`${styles.lessonHTMLText} lessonHTMLGlobal`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.lesson.description, {
                    ADD_TAGS: ["iframe"],
                  }),
                }}
              ></div>
            </div>
          )}
          {!!props.lesson.tasks && (
            <div className={styles.box}>
              <span className={styles.sectionText}>
                {translate("lessons.lesson-tasks")}
              </span>
              <div
                className={`${styles.lessonHTMLText} lessonHTMLGlobal`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.lesson.tasks, {
                    ADD_TAGS: ["iframe"],
                  }),
                }}
              ></div>
            </div>
          )}
          {!!props.lesson.homework && (
            <div className={styles.box}>
              <span className={styles.sectionText}>
                {translate("lesson-homework")}
              </span>
              <div
                className={`${styles.lessonHTMLText} lessonHTMLGlobal`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.lesson.homework, {
                    ADD_TAGS: ["iframe"],
                  }),
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  lesson: lessonStateModel;
  auth: authStateModel;
}) => ({
  lesson: state.lesson,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getLesson,
      unmountLesson,
      showToast,
      lessonGenerateAccessToken,
      publishLesson,
      unpublishLesson,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetails);
