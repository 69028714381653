import React from "react";

import { ReactComponent as PercentageIcon } from "../../../../../../assets/images/reports/percentage.svg";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  pointsPercentage: number;
  editMode: boolean;
}

export const TestReportStudentPercentagePoints = (props: Props) => {
  return (
    <div className={styles.container}>
      <PercentageIcon/>
      <div className={styles.textContainer}>
        <h4>{translate("report.score-percentage-points")}</h4>
        <h5>
          {props.pointsPercentage && !props.editMode
            ? `${props.pointsPercentage}%`
            : "--"}
        </h5>
      </div>
    </div>
  );
};

export default TestReportStudentPercentagePoints;
