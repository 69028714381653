import React from "react";
import classNames from "classnames";
import propOr from "ramda/src/propOr";

import { ReactComponent as LinesNote } from "../../../../../../assets/images/question_notes/lines.svg";
import { ReactComponent as CheckedNote } from "../../../../../../assets/images/question_notes/checked.svg";
import { ReactComponent as BlankNote } from "../../../../../../assets/images/question_notes/blank.svg";
import { ReactComponent as DottedNote } from "../../../../../../assets/images/question_notes/dotted.svg";
import { ReactComponent as PenmanshipNote } from "../../../../../../assets/images/question_notes/penmanship.svg";

import QuestionHeaderPreview from "../Question/Header";
import QuestionMultipleChoiceSingleAnswerPreview from "../Question/MultipleChoice/Single";
import QuestionMultipleChoiceMultipleAnswersPreview from "../Question/MultipleChoice/Multiple";
import QuestionImagePreview from "../Question/Image";
import QuestionTrueFalsePreview from "../Question/TrueFalse";

import QuestionPreview from "../Question";
import PreviewTestHeader from "../Header";
import PreviewTestInstruction from "../Instruction";

import { getTotalPoints } from "../../../../../../services/common/test";

// Types
import {
  classModel,
} from "../../../../../../models/redux/teacher";
import {
  testStateModel,
  questionModel,
} from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  classID: string;
  classes: classModel[];
  test: testStateModel;
  pages: any[];
  reloadPreview: () => any;
  hideInstruction: boolean;
  isCompact?: boolean;
}

export const PreviewTestPages = (props: Props) => {
  const noteContainerClass = (index: number, questionID: string) => classNames({
    [styles.noteContainer]: true,
    [styles.noteContainerFirst]: !index && getQuestion(questionID).type_data.notes_size > 1,
    [styles.noteContainerLast]: (
      index === (getQuestion(questionID).type_data.notes_size -1) &&
      getQuestion(questionID).type_data.notes_size > 1
    ),
    [styles.noteContainerSingle]: getQuestion(questionID).type_data.notes_size === 1,
  });

  const noteContainerBlankClass = (index: number, questionID: string) => classNames({
    [styles.noteContainerBlank]: true,
    [styles.noteContainerBlankFirst]: !index && getQuestion(questionID).type_data.notes_size > 1,
    [styles.noteContainerBlankLast]: (
      index === (getQuestion(questionID).type_data.notes_size -1) &&
      getQuestion(questionID).type_data.notes_size > 1
    ),
    [styles.noteContainerBlankSingle]: getQuestion(questionID).type_data.notes_size === 1,
  });

  const getQuestion = (questionID: string) => (
    props.test.questions
      .find(question => question.id === questionID) as questionModel
  );

  return (
    <>
      {props.pages.map((page: [], index: number) => (
        <div key={`preview_test_${index}`} className={styles.pageContainer}>
          <div className={styles.a4}>
            {!index &&
              <PreviewTestHeader
                classID={props.classID}
                classes={props.classes}
                subjectName={props.test.subject.name}
                testName={props.test.name}
                totalPoints={getTotalPoints(props.test.questions)}
                isCompact={propOr(false, "isCompact", props)}
              />
            }
            {(!index && !props.hideInstruction) && <PreviewTestInstruction/>}
            {page.map((item: any, index: number) => {
              if (item.fullQuestion) {
                return (
                  <QuestionPreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    index={index}
                    question={getQuestion(item.questionID)}
                    reloadPreview={props.reloadPreview}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.type === "question-header") {
                return (
                  <QuestionHeaderPreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    question={getQuestion(item.questionID)}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.type === "question-image") {
                return (
                  <QuestionImagePreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    index={index}
                    question={getQuestion(item.questionID)}
                    reloadPreview={props.reloadPreview}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.type === "question-answers-mc-sa") {
                return (
                  <QuestionMultipleChoiceSingleAnswerPreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    question={getQuestion(item.questionID)}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.type === "question-answers-tf") {
                return (
                  <QuestionTrueFalsePreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    question={getQuestion(item.questionID)}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.type === "question-answers-mc-ma") {
                return (
                  <QuestionMultipleChoiceMultipleAnswersPreview
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                    question={getQuestion(item.questionID)}
                    isCompact={propOr(false, "isCompact", props)}
                  />
                );
              }
              if (item.subtype === "question-notes-checked") {
                return (
                  <div
                    className={noteContainerClass(item.subtypeIndex, item.questionID)}
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                  >
                    <CheckedNote/>
                  </div>
                );
              }
              if (item.subtype === "question-notes-lines") {
                return (
                  <div
                    className={noteContainerClass(item.subtypeIndex, item.questionID)}
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                  >
                    <LinesNote/>
                  </div>
                );
              }
              if (item.subtype === "question-notes-penmanship") {
                return (
                  <div
                    className={noteContainerClass(item.subtypeIndex, item.questionID)}
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                  >
                    <PenmanshipNote/>
                  </div>
                );
              }
              if (item.subtype === "question-notes-dotted") {
                return (
                  <div
                    className={noteContainerClass(item.subtypeIndex, item.questionID)}
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                  >
                    <DottedNote/>
                  </div>
                );
              }
              if (item.subtype === "question-notes-blank") {
                return (
                  <div
                    className={noteContainerBlankClass(item.subtypeIndex, item.questionID)}
                    key={`preview_test_details_page_${item.questionID}_${index}`}
                  >
                    <BlankNote/>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default PreviewTestPages;
