import propOr from "ramda/src/propOr";
import path from "ramda/src/path";
import isEmpty from "ramda/src/isEmpty";
import {
  getTokenFromHeaders,
  saveTokenInLocalStorage,
  removeTokenFromLocalStorage,
  saveLanguageInLocalStorage,
  setTokenInCookies,
  removeTokenFromCookies,
} from "../../../services/api";

// Types
import {
  signUpPayload,
  signUpAction,
  signUpSuccessAction,
  signUpFailureAction,
  signUpConfirmPayload,
  signUpConfirmAction,
  signUpConfirmSuccessAction,
  signUpConfirmFailureAction,
  signUpConfirmResendLinkPayload,
  signUpConfirmResendLinkAction,
  signUpConfirmResendLinkSuccessAction,
  signUpConfirmResendLinkFailureAction,
  signInPayload,
  signInAction,
  signInSuccessAction,
  signInFailureAction,
  signInByTokenAction,
  signInByTokenSuccessAction,
  signInByTokenFailureAction,
  unmountAuthAction,
  forgotPasswordAction,
  forgotPasswordPayload,
  forgotPasswordSuccessAction,
  forgotPasswordFailureAction,
  resetPasswordPayload,
  resetPasswordAction,
  resetPasswordSuccessAction,
  resetPasswordFailureAction,
  signOutAction,
  signOutSuccessAction,
  signInSuccessPayload,
  signUpSuccessPayload,
  signInByTokenSuccessPayload,
  deleteAccountAction,
  deleteAccountSuccessAction,
  deleteAccountFailureAction,
  updateAccountPayload,
  updateAccountAction,
  updateAccountSuccessAction,
  updateAccountFailureAction,
  changePasswordPayload,
  changePasswordAction,
  changePasswordSuccessAction,
  changePasswordFailureAction,
} from "../../../models/redux/auth";
import { XHRPayload, ErrorsPayload } from "../../../models/common";
import { History } from "history";

// Sign Up

export const signUp = (payload: signUpPayload): signUpAction => {
  return {
    type: "SIGN_UP",
    payload,
  };
};

export const signUpSuccess = (
  payload: XHRPayload,
  history: History,
): signUpSuccessAction => {
  const data = path(
    ["response", "data", "session"],
    payload,
  ) as signUpSuccessPayload;
  const token = getTokenFromHeaders(payload.xhr);
  const roles = propOr([], "roles", data) as string[];

  saveTokenInLocalStorage(token);

  if (roles && !roles.includes("guest")) {
    setTokenInCookies(token);
  }

  if (propOr("", "language", data)) {
    saveLanguageInLocalStorage(data.language);
  }

  history.push("/class/add");

  return {
    type: "SIGN_UP_SUCCESS",
    payload: {
      token: token,
      email: propOr("", "email", data),
      roles,
      card_info: !isEmpty(propOr({}, "card_info", data))
        ? data.card_info
        : { last4: "", brand: "" },
      access: propOr(
        {
          expires_at: "",
          full_access: false,
        },
        "access",
        data,
      ),
      app: propOr("", "app", data),
      currency: propOr("", "currency", data),
      language: propOr("", "language", data),
      country: propOr("", "country", data),
    },
  };
};

export const signUpFailure = (payload: ErrorsPayload): signUpFailureAction => {
  return {
    type: "SIGN_UP_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Sign Up Confirm

export const signUpConfirm = (
  payload: signUpConfirmPayload,
): signUpConfirmAction => {
  return {
    type: "SIGN_UP_CONFIRM",
    payload,
  };
};

export const signUpConfirmSuccess = (
  history: History,
): signUpConfirmSuccessAction => {
  history.push("/");
  return {
    type: "SIGN_UP_CONFIRM_SUCCESS",
  };
};

export const signUpConfirmFailure = (
  payload: ErrorsPayload,
): signUpConfirmFailureAction => {
  return {
    type: "SIGN_UP_CONFIRM_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Sign Up Confirm Resend Link

export const signUpConfirmResendLink = (
  payload: signUpConfirmResendLinkPayload,
): signUpConfirmResendLinkAction => {
  return {
    type: "SIGN_UP_CONFIRM_RESEND_LINK",
    payload,
  };
};

export const signUpConfirmResendLinkSuccess = (
  history: History,
): signUpConfirmResendLinkSuccessAction => {
  history.push("/email-has-been-sent");
  return {
    type: "SIGN_UP_CONFIRM_RESEND_LINK_SUCCESS",
  };
};

export const signUpConfirmResendLinkFailure = (
  payload: ErrorsPayload,
): signUpConfirmResendLinkFailureAction => {
  return {
    type: "SIGN_UP_CONFIRM_RESEND_LINK_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Sign In

export const signIn = (payload: signInPayload): signInAction => {
  return {
    type: "SIGN_IN",
    payload,
  };
};

export const signInSuccess = (payload: XHRPayload): signInSuccessAction => {
  const token = getTokenFromHeaders(payload.xhr);
  const data = path(
    ["response", "data", "session"],
    payload,
  ) as signInSuccessPayload;
  const roles = propOr([], "roles", data) as string[];

  saveTokenInLocalStorage(token);

  if (roles && !roles.includes("guest")) {
    setTokenInCookies(token);
  }

  if (propOr("", "language", data)) {
    saveLanguageInLocalStorage(data.language);
  }

  return {
    type: "SIGN_IN_SUCCESS",
    payload: {
      token: token,
      email: propOr("", "email", data),
      roles,
      card_info: !isEmpty(propOr({}, "card_info", data))
        ? data.card_info
        : { last4: "", brand: "" },
      access: propOr(
        {
          expires_at: "",
          full_access: false,
        },
        "access",
        data,
      ),
      app: propOr("", "app", data),
      currency: propOr("", "currency", data),
      language: propOr("", "language", data),
      country: propOr("", "country", data),
    },
  };
};

export const signInFailure = (payload: ErrorsPayload): signInFailureAction => {
  return {
    type: "SIGN_IN_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Sign In By Token

export const signInByToken = (): signInByTokenAction => {
  return {
    type: "SIGN_IN_BY_TOKEN",
  };
};

export const signInByTokenSuccess = (
  payload: XHRPayload,
): signInByTokenSuccessAction => {
  const data = path(
    ["response", "data", "session"],
    payload,
  ) as signInByTokenSuccessPayload;
  const roles = propOr([], "roles", data) as string[];

  if (propOr("", "language", data)) {
    saveLanguageInLocalStorage(data.language);
  }

  if (roles && !roles.includes("guest")) {
    setTokenInCookies(getTokenFromHeaders(payload.xhr));
  }

  return {
    type: "SIGN_IN_BY_TOKEN_SUCCESS",
    payload: {
      token: getTokenFromHeaders(payload.xhr),
      email: propOr("", "email", data),
      roles,
      card_info: !isEmpty(propOr({}, "card_info", data))
        ? data.card_info
        : { last4: "", brand: "" },
      access: propOr(
        {
          expires_at: "",
          full_access: false,
        },
        "access",
        data,
      ),
      app: propOr("", "app", data),
      currency: propOr("", "currency", data),
      language: propOr("", "language", data),
      country: propOr("", "country", data),
    },
  };
};

export const signInByTokenFailure = (): signInByTokenFailureAction => {
  removeTokenFromLocalStorage();
  removeTokenFromCookies();
  return {
    type: "SIGN_IN_BY_TOKEN_FAILURE",
  };
};

// Other

export const unmountAuth = (): unmountAuthAction => {
  return {
    type: "UNMOUNT_AUTH",
  };
};

// Forgot Password

export const forgotPassword = (
  payload: forgotPasswordPayload,
): forgotPasswordAction => {
  return {
    type: "FORGOT_PASSWORD",
    payload,
  };
};

export const forgotPasswordSuccess = (
  history: History,
): forgotPasswordSuccessAction => {
  history.push("/email-has-been-sent");
  return {
    type: "FORGOT_PASSWORD_SUCCESS",
  };
};

export const forgotPasswordFailure = (
  payload: ErrorsPayload,
): forgotPasswordFailureAction => {
  return {
    type: "FORGOT_PASSWORD_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Reset Password

export const resetPassword = (
  payload: resetPasswordPayload,
): resetPasswordAction => {
  return {
    type: "RESET_PASSWORD",
    payload,
  };
};

export const resetPasswordSuccess = (
  history: History,
): resetPasswordSuccessAction => {
  history.push("/sign-in");
  return {
    type: "RESET_PASSWORD_SUCCESS",
  };
};

export const resetPasswordFailure = (
  payload: ErrorsPayload,
): resetPasswordFailureAction => {
  return {
    type: "RESET_PASSWORD_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Sign Out

export const signOut = (): signOutAction => {
  return {
    type: "SIGN_OUT",
  };
};

export const signOutSuccess = (): signOutSuccessAction => {
  removeTokenFromLocalStorage();
  window.location.replace("/sign-in");
  removeTokenFromCookies();
  return {
    type: "SIGN_OUT_SUCCESS",
  };
};

// Delete Account

export const deleteAccount = (): deleteAccountAction => {
  return {
    type: "DELETE_ACCOUNT",
  };
};

export const deleteAccountSuccess = (): deleteAccountSuccessAction => {
  removeTokenFromLocalStorage();
  window.location.replace("https://wisdy.co");
  return {
    type: "DELETE_ACCOUNT_SUCCESS",
  };
};

export const deleteAccountFailure = (): deleteAccountFailureAction => {
  return {
    type: "DELETE_ACCOUNT_FAILURE",
  };
};

// Update Account

export const updateAccount = (
  payload: updateAccountPayload,
): updateAccountAction => {
  return {
    type: "UPDATE_ACCOUNT",
    payload,
  };
};

export const updateAccountSuccess = (): updateAccountSuccessAction => {
  return {
    type: "UPDATE_ACCOUNT_SUCCESS",
  };
};

export const updateAccountFailure = (): updateAccountFailureAction => {
  return {
    type: "UPDATE_ACCOUNT_FAILURE",
  };
};

// Change Password

export const changePassword = (
  payload: changePasswordPayload,
  callback?: () => any,
): changePasswordAction => {
  return {
    type: "CHANGE_PASSWORD",
    payload,
    callback,
  };
};

export const changePasswordSuccess = (
  callback?: () => any,
): changePasswordSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "CHANGE_PASSWORD_SUCCESS",
  };
};

export const changePasswordFailure = (
  payload: ErrorsPayload,
): changePasswordFailureAction => {
  return {
    type: "CHANGE_PASSWORD_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};
