import { of, concat } from "rxjs";
import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, takeUntil, map, mergeMap } from "rxjs/operators";
import pathOr from "ramda/src/pathOr";
import {
  signUpSuccess,
  signUpFailure,
  signUpConfirmSuccess,
  signUpConfirmFailure,
  signUpConfirmResendLinkSuccess,
  signUpConfirmResendLinkFailure,
  signInSuccess,
  signInFailure,
  signInByTokenSuccess,
  signInByTokenFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  signOutSuccess,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateAccountSuccess,
  updateAccountFailure,
  changePasswordSuccess,
  changePasswordFailure,
} from "../../actions/auth";
import { showToast } from "../../actions/app";

import { apiCall, getTokenFromState } from "../../../services/api";

// Types
import {
  signUpPayload,
  signUpAction,
  signUpConfirmPayload,
  signUpConfirmAction,
  signUpConfirmResendLinkPayload,
  signUpConfirmResendLinkAction,
  signInPayload,
  signInAction,
  signInByTokenAction,
  forgotPasswordAction,
  forgotPasswordPayload,
  resetPasswordAction,
  resetPasswordPayload,
  signOutAction,
  deleteAccountAction,
  updateAccountAction,
  updateAccountPayload,
  updateAccountSuccessAction,
  updateAccountFailureAction,
  changePasswordAction,
  changePasswordPayload,
  changePasswordSuccessAction,
} from "../../../models/redux/auth";
import { XHRPayload } from "../../../models/common";

// Sign Up

export const signUpEpic: Epic = (action$: ActionsObservable<signUpAction>) =>
  action$.pipe(
    ofType("SIGN_UP"),
    pluck("payload"),
    switchMap((payload: signUpPayload) => signUp(payload)),
  );

export const signUp = (payload: signUpPayload) =>
  apiCall(
    "/sign-up/",
    "POST",
    {
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      ...(pathOr("", ["referrer_account_id"], payload)
        ? { referrer_account_id: payload.referrer_account_id }
        : {}),
    },
    (data: XHRPayload) => signUpSuccess(data, payload.history),
    signUpFailure,
  );

// Sign Up Confirm

export const signUpConfirmEpic: Epic = (
  action$: ActionsObservable<signUpConfirmAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("SIGN_UP_CONFIRM"),
    pluck("payload"),
    switchMap((payload: signUpConfirmPayload) => signUpConfirm(payload, state$)),
  );

export const signUpConfirm = (
  payload: signUpConfirmPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    "/sign-up/confirm",
    "POST",
    {
      token: payload.token,
    },
    () => signUpConfirmSuccess(payload.history),
    signUpConfirmFailure,
    getTokenFromState(state$),
  );

// Sign Up Confirm Resend Link

export const signUpConfirmResendLinkEpic: Epic = (
  action$: ActionsObservable<signUpConfirmResendLinkAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("SIGN_UP_CONFIRM_RESEND_LINK"),
    pluck("payload"),
    switchMap((payload: signUpConfirmResendLinkPayload) =>
      signUpConfirmResendLink(payload, state$),
    ),
  );

export const signUpConfirmResendLink = (
  payload: signUpConfirmResendLinkPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    "/sign-up/send-confirmation",
    "POST",
    {},
    () => signUpConfirmResendLinkSuccess(payload.history),
    signUpConfirmResendLinkFailure,
    getTokenFromState(state$),
  );

// Sign In

export const signInEpic: Epic = (action$: ActionsObservable<signInAction>) =>
  action$.pipe(
    ofType("SIGN_IN"),
    pluck("payload"),
    switchMap((payload: signInPayload) =>
      signIn(payload).pipe(takeUntil(action$.pipe(ofType("UNMOUNT_AUTH")))),
    ),
  );

export const signIn = (payload: signInPayload) =>
  apiCall(
    "/authentication/sign-in",
    "POST",
    {
      email: payload.email,
      password: payload.password,
    },
    (data: XHRPayload) => signInSuccess(data),
    signInFailure,
  );

// Sign In By Token

export const signInByTokenEpic: Epic = (
  action$: ActionsObservable<signInByTokenAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("SIGN_IN_BY_TOKEN"),
    switchMap(() => signInByToken(state$)),
  );

export const signInByToken = (state$: StateObservable<void>) =>
  apiCall(
    "/authentication/session",
    "GET",
    {},
    (data: XHRPayload) => signInByTokenSuccess(data),
    signInByTokenFailure,
    getTokenFromState(state$),
  );

// Forgot Password

export const forgotPasswordEpic: Epic = (
  action$: ActionsObservable<forgotPasswordAction>,
) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD"),
    pluck("payload"),
    switchMap(forgotPassword),
  );

export const forgotPassword = (payload: forgotPasswordPayload) =>
  apiCall(
    "/authentication/reset-password",
    "POST",
    {
      email: payload.email,
    },
    () => forgotPasswordSuccess(payload.history),
    forgotPasswordFailure,
  );

// Reset Password

export const resetPasswordEpic: Epic = (
  action$: ActionsObservable<resetPasswordAction>,
) =>
  action$.pipe(
    ofType("RESET_PASSWORD"),
    pluck("payload"),
    switchMap(resetPassword),
  );

export const resetPassword = (payload: resetPasswordPayload) =>
  apiCall(
    "/authentication/set-password",
    "POST",
    {
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      token: payload.token,
    },
    () => resetPasswordSuccess(payload.history),
    resetPasswordFailure,
  );

// Sign Out

export const signOutEpic: Epic = (action$: ActionsObservable<signOutAction>) =>
  action$.pipe(ofType("SIGN_OUT"), map(signOutSuccess));

// Delete Account

export const deleteAccountEpic: Epic = (
  action$: ActionsObservable<deleteAccountAction>,
) =>
  action$.pipe(
    ofType("DELETE_ACCOUNT"),
    pluck("payload"),
    switchMap(deleteAccount),
  );

export const deleteAccount = () =>
  apiCall(
    "/authentication/accounts",
    "DELETE",
    {},
    deleteAccountSuccess,
    deleteAccountFailure,
  );

// Update Account

export const updateAccountEpic: Epic = (
  action$: ActionsObservable<updateAccountAction>,
) =>
  action$.pipe(
    ofType("UPDATE_ACCOUNT"),
    pluck("payload"),
    switchMap(updateAccount),
  );

export const updateAccount = (payload: updateAccountPayload) =>
  apiCall(
    "/authentication/accounts/settings",
    "PATCH",
    payload,
    updateAccountSuccess,
    updateAccountFailure,
  );

export const updateAccountSuccessEpic: Epic = (
  action$: ActionsObservable<updateAccountSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_ACCOUNT_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "global.changes-have-been-saved",
            status: "success",
          }),
        ),
        signInByToken(state$),
      ),
    ),
  );

export const updateAccountFailureEpic: Epic = (
  action$: ActionsObservable<updateAccountFailureAction>,
) =>
  action$.pipe(
    ofType("UPDATE_ACCOUNT_FAILURE"),
    map(() =>
      showToast({ text: "global.changes-have-not-been-saved", status: "error" }),
    ),
  );

// Change Password

export const changePasswordEpic: Epic = (
  action$: ActionsObservable<changePasswordAction>,
) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD"),
    switchMap((data) => changePassword(data.payload, data.callback)),
  );

export const changePassword = (
  payload: changePasswordPayload,
  callback?: () => any,
) =>
  apiCall(
    "/authentication/change-password",
    "PATCH",
    payload,
    () => changePasswordSuccess(callback),
    changePasswordFailure,
  );

export const changePasswordSuccessEpic: Epic = (
  action$: ActionsObservable<changePasswordSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "global.changes-have-been-saved",
            status: "success",
          }),
        ),
        signInByToken(state$),
      ),
    ),
  );
