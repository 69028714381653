import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal, {
  ToggleContent,
} from "../../../../../../components/Common/Modal";
import Input from "../../../../../Common/Input";

import translate from "../../../../../../services/translate";

import { ReactComponent as LockIcon } from "../../../../../../assets/images/lock.svg";

import { getErrorMessage } from "../../../../../../services/common";

// Types
import { changePassword as changePasswordFunction } from "../../../../../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  changePassword: changePasswordFunction;
  errors: any[];
  inProgress: boolean;
}

export const ChangePassword = (props: Props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  return (
    <ToggleContent
      toggle={(show: any) => (
        <button
          type="button"
          className={styles.changePasswordButton}
          onClick={show}
        >
          <LockIcon/>
          &nbsp;
          <span>{translate("profile.change-password")}</span>
        </button>
      )}
      content={(hide: () => {}) => (
        <Modal customStyles={styles.modal} hide={hide}>
          <div className={styles.changePasswordModal}>
            <LockIcon/>
            <span className={styles.description}>
              {translate("profile.change-password")}
            </span>
            <Input
              id="current_password"
              type="password"
              name="current_password"
              value={currentPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentPassword(e.target.value)
              }
              label={translate("profile.current-password")}
              error={getErrorMessage(props.errors, "current_password", true)}
            />
            <Input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              label={translate("reset_password.new-password")}
              error={getErrorMessage(props.errors, "password", true)}
            />
            <Input
              id="password_confirmation"
              type="password"
              name="password_confirmation"
              value={passwordConfirmation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPasswordConfirmation(e.target.value)
              }
              label={translate("reset_password.repeat-new-password")}
              error={getErrorMessage(
                props.errors,
                "password_confirmation",
                true,
              )}
            />
            <div className={styles.buttonContainer}>
              <button type="button" onClick={hide}>
                {translate("global.cancel")}
              </button>
              <button
                type="button"
                disabled={props.inProgress}
                onClick={() => {
                  props.changePassword(
                    {
                      current_password: currentPassword,
                      password,
                      password_confirmation: passwordConfirmation,
                    },
                    () => hide(),
                  );
                }}
              >
                {props.inProgress ? (
                  <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
                ) : (
                  <span>{translate("global.save-changes")}</span>
                )}
              </button>
            </div>
          </div>
        </Modal>
      )}
    />
  );
};

export default ChangePassword;
