import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import classNames from "classnames";
import merge from "ramda/src/merge";

import { ReactComponent as Pen } from "../../../../../../assets/images/pen_edit.svg";

import { createTest, updateTest } from "../../../../../../redux/actions/test";

import translate from "../../../../../../services/translate";
import { getErrorMessage } from "../../../../../../services/common";

// Types
import { History } from "history";
import {
  teacherStateModel,
  subjectModel,
  classModel,
} from "../../../../../../models/redux/teacher";
import {
  testStateModel,
  createTest as createTestFunction,
  updateTest as updateTestFunction,
} from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  classes: classModel[];
  subjects: subjectModel[];
  test: testStateModel;
  createTest: createTestFunction;
  updateTest: updateTestFunction;
  classID: string;
  selectedSubjectType: string;
  testID: string;
  selectedClassName: string;
  selectedSubjectName: string;
  selectedTestType: string;
  isDemo?: boolean;
  selectedTime?: string;
}

export const TestCreatorHeader = (props: Props) => {
  const [testName, setTestName] = useState("");

  const testNameInputElement = useRef(null) as any;

  useEffect(() => {
    if (!props.test.id && !props.test.name) {
      setTestName(`${props.selectedSubjectName} - ${props.selectedClassName}`);
    } else {
      setTestName(props.test.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.id, props.selectedClassName, props.selectedSubjectName]);

  useEffect(() => {
    if (
      props.test.id &&
      !getErrorMessage(props.test.errors, `${props.test.id}/name`, true) &&
      !testName
    ) {
      setTestName(props.test.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.errors]);

  useEffect(() => {
    if (
      !props.testID &&
      props.classID &&
      props.selectedSubjectType &&
      props.test.id
    ) {
      props.history.push(
        `${props.isDemo ? "/demo" : ""}/test/creator${
          props.history.location.search
        }&testID=${props.test.id}`,
      );
    }
  }, [
    props.history,
    props.classes,
    props.subjects,
    props.testID,
    props.classID,
    props.selectedSubjectType,
    props.test.id,
    props.test.name,
    props.selectedClassName,
    props.selectedSubjectName,
    props.isDemo,
  ]);

  const updateTestName = (value: string) => {
    let val = value;
    if (value.length > 50) {
      val = value.substring(0, 50);
    }
    setTestName(val);
  };

  const createOrUpdateTest = () => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === props.selectedSubjectType,
    );

    if (!props.test.id && !props.testID && selectedSubject) {
      props.createTest({
        class_id: props.classID,
        lang: "",
        name: testName,
        subject_id: selectedSubject.id,
        history: props.history,
        type: props.selectedTestType,
        ...merge(
          {},
          props.selectedTestType === "online" && props.selectedTime
            ? {
              online_data: {
                time_limit: props.selectedTime,
              },
            }
            : {},
        ),
      });
    } else if (props.test.id || props.testID) {
      props.updateTest({
        id: props.test.id || props.testID,
        name: testName,
      });
    }
  };

  const errorClass = classNames({
    [styles.error]: getErrorMessage(
      props.test.errors,
      `${props.test.id}/name`,
      true,
    ),
  });

  const focusTestNameInputElement = () => {
    if (testNameInputElement) {
      testNameInputElement.current.focus();
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.top}>
        <span
          className={`${styles.violet} ${errorClass}`}
          onClick={focusTestNameInputElement}
        >
          {translate("global.testname")}
        </span>
        <div className={styles.right}>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("class.classname")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.selectedClassName}</span>
          </div>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.subject")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.selectedSubjectName}</span>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Pen className={errorClass} onClick={focusTestNameInputElement}/>
        <input
          id="test-name-input"
          className={errorClass}
          onChange={(e) => updateTestName(e.target.value)}
          onBlur={createOrUpdateTest}
          value={testName}
          ref={testNameInputElement}
        />
        <div className={styles.bottomInfo}>
          {getErrorMessage(
            props.test.errors,
            `${props.test.id}/name`,
            true,
          ) && (
            <span className={errorClass}>
              {getErrorMessage(
                props.test.errors,
                `${props.test.id}/name`,
                true,
              )}
            </span>
          )}
          <span className={errorClass}>{testName.length}/50</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  test: testStateModel;
}) => ({
  classes: state.teacher.classes,
  subjects: state.teacher.subjects,
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createTest,
      updateTest,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCreatorHeader);
