import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EducationLevelBox from "../../Curriculum/EducationLevel/Box";
import CurriculumListItem from "../../Curriculum/ListItem";

import translate from "../../../../services/translate";
import {
  getCurriculumsList,
  updateSelectedCurriculumsArray,
  curriculumsListItem,
} from "../../../../services/common/curriculums";

import {
  getEducationLevel,
  getCurriculums,
  assignCurriculums,
} from "../../../../redux/actions/curriculum";
import { getSubjects } from "../../../../redux/actions/teacher";

// Types
import { History } from "history";
import { teacherStateModel } from "../../../../models/redux/teacher";
import { userStateModel } from "../../../../models/redux/user";
import {
  curriculumStateModel,
  educationLevel,
} from "../../../../models/redux/curriculum";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  setSelectedCurriculums: (ids: string[]) => any;
  selectedCurriculums: string[];
  subject: {
    id: string;
    name: string;
    type: string;
  };
  classId: string;
}

export const AddClassCurriculum = (props: Props) => {
  const dispatch = useDispatch();
  const [selectedEducationLevelID, setSelectedEducationLevelID] = useState("");
  const state = useSelector(
    (state: {
      curriculum: curriculumStateModel;
      teacher: teacherStateModel;
      user: userStateModel;
    }) => ({
      curriculum: state.curriculum,
      isLoading: state.teacher.isLoading,
      userLanguage: state.user.language,
    }),
  );

  useEffect(() => {
    dispatch(getSubjects());

    if (props.subject.id) {
      dispatch(
        getEducationLevel({
          subjects: [props.subject.id],
        }),
      );
    }

    // return () => {
    //   dispatch(unmountCurriculum());
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEducationLevelID) {
      dispatch(
        getCurriculums({
          id: selectedEducationLevelID,
          subject_id: props.subject.id,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEducationLevelID]);

  useEffect(() => {
    if (props.subject.id) {
      dispatch(
        getEducationLevel({
          subjects: [props.subject.id],
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subject.id]);

  return (
    <div className={styles.curriculumBox}>
      {!state.curriculum.in_progress.get_education_level &&
        !!state.curriculum.education_level.length && (
        <>
          <h2>
            {translate("global.pick-up")}&nbsp;
            <span className={styles.primaryHeadingText}>
              {translate("class.education-level")}
            </span>
            <span className={styles.secondaryHeadingText}>
                &nbsp;({translate("global.optional")})
            </span>
          </h2>
          <p>{translate("class.select-education-level-description")}</p>
        </>
      )}
      <div className={styles.educationLevelContainer}>
        {state.curriculum.in_progress.get_education_level ? (
          <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
        ) : (
          state.curriculum.education_level.map((item: educationLevel) => {
            return (
              <EducationLevelBox
                key={item.id}
                id={item.id}
                level={item.level}
                onClick={(id: string) => {
                  props.setSelectedCurriculums([]);
                  setSelectedEducationLevelID(id);
                }}
                isChecked={item.id === selectedEducationLevelID}
              />
            );
          })
        )}
      </div>
      <div className={styles.curriculumsContainer}>
        {state.curriculum.in_progress.get_curriculums ? (
          <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
        ) : (
          !!state.curriculum.curriculums.length && (
            <>
              <h2>
                {translate("global.pick-up")}&nbsp;
                <span className={styles.primaryHeadingText}>
                  {translate("class.variant-or-variants")}
                </span>
              </h2>
              <p>{translate("curriculum.select-curriculum-description")}</p>
              {getCurriculumsList(state.curriculum.curriculums).map(
                (item: curriculumsListItem) => {
                  return (
                    <CurriculumListItem
                      key={item.subject}
                      subject={item.subject_name}
                      list={item.list}
                      selectedCurriculums={props.selectedCurriculums}
                      onClick={(id: string) => {
                        props.setSelectedCurriculums(
                          updateSelectedCurriculumsArray(
                            id,
                            props.selectedCurriculums,
                            item.list,
                          ),
                        );
                      }}
                    />
                  );
                },
              )}
              <button
                className={styles.saveButton}
                onClick={() => {
                  dispatch(
                    assignCurriculums({
                      curriculums: props.selectedCurriculums,
                      class_id: props.classId,
                    }),
                  );
                }}
              >
                {translate("global.save")}
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default AddClassCurriculum;
