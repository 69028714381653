import React, { useState } from "react";

import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

import translate from "../../../../../../services/translate";
import { getSubjectIcon } from "../../../../../../services/common/getSubjectIcon";
import Modal, { ToggleContent } from "../../../../../Common/Modal";
import DuplicateTestModal from "../../../../../Common/DuplicateTestModal";

import { ReactComponent as EllipsesVerticalIcon } from "../../../../../../assets/images/ellipses_vertical.svg";
import { ReactComponent as PenIcon } from "../../../../../../assets/images/pen.svg";
import { ReactComponent as CopyIcon } from "../../../../../../assets/images/copy.svg";
import { ReactComponent as TrashIcon } from "../../../../../../assets/images/trash.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../../assets/images/trash_alt.svg";
import { ReactComponent as OnlineTestIcon } from "../../../../../../assets/images/test_types/online.svg";

import {
  isWindowSmallScreen,
  outsideClickHelper,
} from "../../../../../../services/common";

// Types
import { History } from "history";
import { testModel } from "../../../../../../models/redux/class";
import {
  TestStatusTypes,
  deleteTest as deleteTestFunction,
  testTypes,
} from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  item: testModel;
  classID: string;
  deleteTest: deleteTestFunction;
}

export const TestsListItem = (props: Props) => {
  const [testDropdown, setTestDropdown] = useState("");

  const itemClick = (e: any) => {
    if (isWindowSmallScreen()) {
      props.history.push(
        `/test?classID=${props.classID}&testID=${props.item.id}`,
      );
    }
  };

  return (
    <li className={styles.listItem} onClick={itemClick} onTouchEnd={() => {}}>
      <div className={styles.header}>
        <div className={styles.statusContainer}>
          <i className={styles[`icon_${props.item.status}`]}/>
          <span>{translate(`test-status-${props.item.status}`)}</span>
        </div>
        <div className={styles.testSettings}>
          {testDropdown === `dropdown_${props.item.id}` && (
            <OutsideClickHandler
              onOutsideClick={(e: any) => {
                outsideClickHelper(e, "modalContainer", () =>
                  setTestDropdown(""),
                );
              }}
            >
              <ul className={styles.testSettingsDropdown}>
                {props.item.status !== TestStatusTypes.COMPLETED && (
                  <li>
                    <Link
                      to={`/test/creator?classID=${props.classID}&type=${
                        props.item.type
                      }&subject=${props.item.subject.type}&testID=${
                        props.item.id
                      }${
                        props.item.time_limit !== undefined
                          ? `&time=${props.item.time_limit}`
                          : ""
                      }`}
                    >
                      <PenIcon/>
                      <span>{translate("global.edit")}</span>
                    </Link>
                  </li>
                )}
                <li>
                  <ToggleContent
                    toggle={(show: any) => (
                      <button onClick={show}>
                        <CopyIcon/>
                        <span>{translate("global.duplicate")}</span>
                      </button>
                    )}
                    content={(hide: () => {}) => (
                      <DuplicateTestModal
                        testID={props.item.id}
                        hide={() => {
                          hide();
                          setTestDropdown("");
                        }}
                        currentClassID={props.classID}
                        history={props.history}
                      />
                    )}
                  />
                </li>
                <li>
                  <ToggleContent
                    toggle={(show: any) => (
                      <button onClick={show}>
                        <TrashIcon/>
                        <span>{translate("global.delete")}</span>
                      </button>
                    )}
                    content={(hide: () => {}) => (
                      <Modal isSmall hide={hide}>
                        <div className={styles.deleteQuestionModal}>
                          <TrashAltIcon/>
                          <span>
                            {translate("tests.delete-test-confirmation")}
                          </span>
                          <div className={styles.buttonContainer}>
                            <button type="button" onClick={hide}>
                              {translate("global.no")}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                hide();
                                props.deleteTest({
                                  id: props.item.id,
                                  classID: props.classID,
                                });
                              }}
                            >
                              {translate("global.yes")}
                            </button>
                          </div>
                        </div>
                      </Modal>
                    )}
                  />
                </li>
              </ul>
            </OutsideClickHandler>
          )}
          <button
            type="button"
            className={styles.testSettingsButton}
            onClick={() => setTestDropdown(`dropdown_${props.item.id}`)}
          >
            <EllipsesVerticalIcon/>
          </button>
        </div>
      </div>
      {getSubjectIcon(props.item.subject.type)}
      <span>{props.item.name}</span>
      {props.item.type === testTypes.ONLINE && (
        <div className={styles.onlineTestLabel}>
          <OnlineTestIcon/>
        </div>
      )}
      <Link
        className={styles.seeMoreLink}
        to={`/test?classID=${props.classID}&testID=${props.item.id}`}
      >
        <span>{translate("global.see-more")}</span>
      </Link>
    </li>
  );
};

export default TestsListItem;
