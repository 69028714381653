import React from "react";
import classNames from "classnames";

// Types
import { curriculum } from "../../../../models/redux/curriculum";

import styles from "./styles.module.scss";


interface Props {
  onClick: (id: string) => any;
  subject?: string;
  list: curriculum[];
  selectedCurriculums: string[];
  singleSubject?: boolean;
}

const CurriculumListItem = (props: Props) => {
  const curriculumClass = (id: string) => classNames({
    [styles.curriculum]: !props.singleSubject,
    [styles.curriculumSingleSubject]: props.singleSubject,
    [styles.active]: props.selectedCurriculums.includes(id),
  });
  const curriculumsContainerClass = classNames({
    [styles.curriculumsContainer]: true,
    [styles.curriculumsContainerWithSubject]: !!props.subject,
  });

  return (
    <>
      {!!props.list.length &&
        <li
          className={styles.listItem}
        >
          {props.subject && <span>{props.subject}:</span>}
          <div className={curriculumsContainerClass}>
            {props.list.map((item: curriculum) => (
              <button
                type="button"
                key={item.id}
                className={curriculumClass(item.id)}
                onClick={() => props.onClick(item.id)}
              >
                <span>{item.variant || item.education_level}</span>
              </button>
            ))}
          </div>
        </li>
      }
    </>
  );
};

export default CurriculumListItem;
