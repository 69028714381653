// Types
import {
  getClassPayload,
  getClassAction,
  getClassSuccessAction,
  getClassFailureAction,
  getClassSuccessPayload,
  unmountClassAction,
  getStudentsPayload,
  getStudentsAction,
  getStudentsSuccessPayload,
  getStudentsSuccessAction,
  getStudentsFailureAction,
  deleteStudentPayload,
  deleteStudentAction,
  deleteStudentFailureAction,
  deleteStudentSuccessAction,
  deleteStudentSuccessPayload,
  addStudentPayload,
  addStudentAction,
  addStudentSuccessPayload,
  addStudentSuccessAction,
  addStudentFailureAction,
  getStudentDetailsPayload,
  getStudentDetailsAction,
  getStudentDetailsSuccessPayload,
  getStudentDetailsSuccessAction,
  getStudentDetailsFailureAction,
  deleteClassPayload,
  deleteClassAction,
  deleteClassSuccessAction,
  deleteClassFailureAction,
  resetClassErrorsAction,
  updateClassPayload,
  updateClassAction,
  updateClassSuccessAction,
  updateClassFailureAction,
  updateClassSuccessPayload,
} from "../../../models/redux/class";
import { ErrorsPayload } from "../../../models/common";

// Get Class

export const getClass = (payload: getClassPayload): getClassAction => {
  return {
    type: "GET_CLASS",
    payload,
  };
};

export const getClassSuccess = (payload: getClassSuccessPayload): getClassSuccessAction => {
  return {
    type: "GET_CLASS_SUCCESS",
    payload,
  };
};

export const getClassFailure = (): getClassFailureAction => {
  return {
    type: "GET_CLASS_FAILURE",
  };
};

// Delete Class

export const deleteClass = (payload: deleteClassPayload, callback: () => any): deleteClassAction => {
  return {
    type: "DELETE_CLASS",
    payload,
    callback,
  };
};

export const deleteClassSuccess = (callback: () => any): deleteClassSuccessAction => {
  callback();
  return {
    type: "DELETE_CLASS_SUCCESS",
  };
};

export const deleteClassFailure = (payload: ErrorsPayload): deleteClassFailureAction => {
  return {
    type: "DELETE_CLASS_FAILURE",
    payload,
  };
};

// Update Class

export const updateClass = (payload: updateClassPayload): updateClassAction => {
  return {
    type: "UPDATE_CLASS",
    payload,
  };
};

export const updateClassSuccess = (payload: updateClassSuccessPayload): updateClassSuccessAction => {
  return {
    type: "UPDATE_CLASS_SUCCESS",
    payload,
  };
};

export const updateClassFailure = (payload: ErrorsPayload): updateClassFailureAction => {
  return {
    type: "UPDATE_CLASS_FAILURE",
    payload,
  };
};

// Get Students

export const getStudents = (payload: getStudentsPayload): getStudentsAction => {
  return {
    type: "GET_STUDENTS",
    payload,
  };
};

export const getStudentsSuccess = (payload: getStudentsSuccessPayload): getStudentsSuccessAction => {
  return {
    type: "GET_STUDENTS_SUCCESS",
    payload,
  };
};

export const getStudentsFailure = (): getStudentsFailureAction => {
  return {
    type: "GET_STUDENTS_FAILURE",
  };
};

// Delete Student

export const deleteStudent = (payload: deleteStudentPayload): deleteStudentAction => {
  return {
    type: "DELETE_STUDENT",
    payload,
  };
};

export const deleteStudentSuccess = (payload: deleteStudentSuccessPayload): deleteStudentSuccessAction => {
  return {
    type: "DELETE_STUDENT_SUCCESS",
    payload,
  };
};

export const deleteStudentFailure = (): deleteStudentFailureAction => {
  return {
    type: "DELETE_STUDENT_FAILURE",
  };
};

// Add Student

export const addStudent = (payload: addStudentPayload): addStudentAction => {
  return {
    type: "ADD_STUDENT",
    payload,
  };
};

export const addStudentSuccess = (payload: addStudentSuccessPayload): addStudentSuccessAction => {
  return {
    type: "ADD_STUDENT_SUCCESS",
    payload,
  };
};

export const addStudentFailure = (): addStudentFailureAction => {
  return {
    type: "ADD_STUDENT_FAILURE",
  };
};

// Get Student Details

export const getStudentDetails = (payload: getStudentDetailsPayload): getStudentDetailsAction => {
  return {
    type: "GET_STUDENT_DETAILS",
    payload,
  };
};

export const getStudentDetailsSuccess = (payload: getStudentDetailsSuccessPayload): getStudentDetailsSuccessAction => {
  return {
    type: "GET_STUDENT_DETAILS_SUCCESS",
    payload,
  };
};

export const getStudentDetailsFailure = (): getStudentDetailsFailureAction => {
  return {
    type: "GET_STUDENT_DETAILS_FAILURE",
  };
};

// Other

export const unmountClass = (): unmountClassAction => {
  return {
    type: "UNMOUNT_CLASS",
  };
};

export const resetClassErrors = (): resetClassErrorsAction => {
  return {
    type: "RESET_CLASS_ERRORS",
  };
};
