import propOr from "ramda/src/propOr";

// Types
import {
  getResourcesPayload,
  getResourcesAction,
  getResourcesSuccessPayload,
  getResourcesSuccessAction,
  getResourcesFailureAction,
  unmountResourcesAction,
} from "../../../models/redux/resources";
import { ErrorsPayload } from "../../../models/common";

// Get Resources

export const getResources = (payload: getResourcesPayload): getResourcesAction => {
  return {
    type: "GET_RESOURCES",
    payload,
  };
};

export const getResourcesSuccess = (payload: getResourcesSuccessPayload): getResourcesSuccessAction => {
  return {
    type: "GET_RESOURCES_SUCCESS",
    payload,
  };
};

export const getResourcesFailure = (payload: ErrorsPayload): getResourcesFailureAction => {
  return {
    type: "GET_RESOURCES_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Other

export const unmountResources = (): unmountResourcesAction => {
  return {
    type: "UNMOUNT_RESOURCES",
  };
};
