import React from "react";

import ProfileEditUser from "./User";

import translate from "../../../../services/translate";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
}

export const ProfileEdit = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          {translate("global.edit")} {translate("global.profile")}
        </h3>
      </div>
      <div className={styles.boxContainer}>
        <ProfileEditUser/>
      </div>
    </div>
  );
};

export default ProfileEdit;
