import React from "react";
import uniq from "ramda/src/uniq";
import concat from "ramda/src/concat";
import pathOr from "ramda/src/pathOr";
import DOMPurify from "dompurify";

import Checkbox from "../../../../../Common/Checkbox";

// Types
import { curriculumDetailsSkill } from "../../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";



interface Props {
  id: string;
  parentIDs: string[];
  parentID: string;
  rootParentID: string;
  body: string;
  children: any[];
  isChosen: boolean;
  selectedSkills: string[];
  siblings: curriculumDetailsSkill[];
  ancestors?: curriculumDetailsSkill[];
  inProgress: boolean;
  onClick: (ids: string[]) => any;
  chosenIfExists?: boolean;
  readOnly?: boolean;
}

const CurriculumDetailsBoxChildren = (props: Props) => {
  const handleOnClick = () => {
    if (props.isChosen) {
      return props.onClick(
        props.selectedSkills
          .filter((item: string) => (
            !getAllChildrensIDs(
              props.children,
              [],
              props.id,
              isMoreThanOneSiblingChosen() ?
                [] :
                isMoreThanOneAncestorChosen() ?
                  [props.parentID] :
                  props.parentIDs,
            ).includes(item)
          )),
      );
    } else {
      return props.onClick(
        uniq(
          concat(
            props.selectedSkills,
            getAllChildrensIDs(props.children, [], props.id, props.parentIDs),
          ),
        ),
      );
    }
  };

  const isMoreThanOneSiblingChosen = () => props.siblings
    .filter((item: curriculumDetailsSkill) => {
      if (props.chosenIfExists) {
        return props.selectedSkills.includes(item.id);
      }
      return item.is_chosen;
    })
    .length > 1;
  const isMoreThanOneAncestorChosen = () => {
    if (props.ancestors) {
      return props.ancestors
        .filter((item: curriculumDetailsSkill) => {
          if (props.chosenIfExists) {
            return props.selectedSkills.includes(item.id);
          }
          return item.is_chosen;
        })
        .length > 1;
    }
    return false;
  };

  const getAllChildrensIDs = (children: any[], IDsArray?: any, id?: string, parentIDs?: string[]) => {
    let arr = IDsArray || [] as string[];

    if (parentIDs) {
      arr.push(...parentIDs);
    }
    if (id) {
      arr.push(id);
    }
    children.forEach((children: any) => {
      arr.push(children.id);
      if (children.children && !!children.children.length) {
        getAllChildrensIDs(children.children, arr);
      }
    });

    return arr;
  };

  return (
    <li className={styles.listItem}>
      <div className={styles.content}>
        {!props.readOnly &&
          <Checkbox
            onClick={handleOnClick}
            checked={props.isChosen}
            disabled={props.inProgress}
          />
        }
        <div dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(props.body)}` }}/>
      </div>
      {!!props.children.length &&
        <ul>
          {props.children.map((item: curriculumDetailsSkill) => (
            <CurriculumDetailsBoxChildren
              key={item.id}
              id={item.id}
              parentIDs={props.parentIDs.concat(props.id)}
              parentID={props.id}
              rootParentID={props.rootParentID}
              body={item.body}
              children={item.children}
              isChosen={props.chosenIfExists ?
                props.selectedSkills.includes(item.id) :
                item.is_chosen
              }
              selectedSkills={props.selectedSkills}
              siblings={props.children}
              ancestors={concat(pathOr([], ["ancestors"], props), props.siblings)}
              inProgress={props.inProgress}
              onClick={(ids: string[]) => props.onClick(ids)}
              chosenIfExists={!!props.chosenIfExists}
              readOnly={props.readOnly}
            />
          ))}
        </ul>
      }
    </li>
  );
};

export default CurriculumDetailsBoxChildren;
