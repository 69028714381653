import map from "ramda/src/map";
import groupWith from "ramda/src/groupWith";
import eqProps from "ramda/src/eqProps";

// Types
import {
  curriculum,
} from "../../../models/redux/curriculum";


export interface curriculumsListItem {
  subject_name: string;
  subject: string;
  list: curriculum[];
}

export const getCurriculumsList = (curriculums: curriculum[]) => {
  if (!curriculums) {
    return [];
  }

  return map(
    (items: curriculum[]) => ({
      subject_name: items[0].subject_name,
      subject: items[0].subject,
      list: items,
    }),
    groupWith(
      eqProps("subject"), curriculums,
    ),
  ) as curriculumsListItem[];
};

export const updateSelectedCurriculumsArray = (id: string, selectedCurriculums: string[], curriculumsFromSameSubject: curriculum[]) => {
  if (selectedCurriculums.includes(id)) {
    return selectedCurriculums;
  }

  if (curriculumsFromSameSubject) {
    const curriculumFromSameSubject = curriculumsFromSameSubject.find((item: curriculum) => selectedCurriculums.includes(item.id));

    if (curriculumFromSameSubject) {
      return selectedCurriculums
        .filter((item: string) => item !== curriculumFromSameSubject.id)
        .concat(id);
    }
  }

  return selectedCurriculums.concat(id);
};
