import propOr from "ramda/src/propOr";

// Types
import {
  getEducationLevelAction,
  getEducationLevelSuccessPayload,
  getEducationLevelSuccessAction,
  getEducationLevelFailureAction,
  getCurriculumsPayload,
  getCurriculumsAction,
  getCurriculumsSuccessPayload,
  getCurriculumsSuccessAction,
  getCurriculumsFailureAction,
  createCurriculumPayload,
  createCurriculumSuccessAction,
  createCurriculumFailureAction,
  createCurriculumAction,
  assignCurriculumsPayload,
  assignCurriculumsAction,
  createCurriculumSuccessPayload,
  getCurriculumsForClassPayload,
  getCurriculumsForClassAction,
  getCurriculumsForClassSuccessPayload,
  getCurriculumsForClassSuccessAction,
  getCurriculumsForClassFailureAction,
  unmountCurriculumAction,
  getCurriculumForClassPayload,
  getCurriculumForClassAction,
  getCurriculumForClassSuccessPayload,
  getCurriculumForClassSuccessAction,
  getCurriculumForClassFailureAction,
  updateClassSkillsPayload,
  updateClassSkillsAction,
  updateClassSkillsSuccessAction,
  updateClassSkillsFailureAction,
  updateClassSkillsSuccessPayload,
  deleteCurriculumPayload,
  deleteCurriculumAction,
  deleteCurriculumSuccessPayload,
  deleteCurriculumSuccessAction,
  deleteCurriculumFailureAction,
  getQuestionSkillsPayload,
  getQuestionSkillsSuccessPayload,
  getQuestionSkillsAction,
  getQuestionSkillsSuccessAction,
  getQuestionSkillsFailureAction,
  updateQuestionSkillsPayload,
  updateQuestionSkillsAction,
  updateQuestionSkillsSuccessAction,
  updateQuestionSkillsFailureAction,
  updateCurriculumForClassPayload,
  updateCurriculumForClassAction,
  updateCurriculumForClassSuccessAction,
  updateCurriculumForClassFailureAction,
  updateQuestionSkillsSuccessPayload,
  getEducationLevelPayload,
} from "../../../models/redux/curriculum";
import { ErrorsPayload } from "../../../models/common";

// Get Education Level

export const getEducationLevel = (payload: getEducationLevelPayload): getEducationLevelAction => {
  return {
    type: "GET_EDUCATION_LEVEL",
    payload,
  };
};

export const getEducationLevelSuccess = (payload: getEducationLevelSuccessPayload): getEducationLevelSuccessAction => {
  return {
    type: "GET_EDUCATION_LEVEL_SUCCESS",
    payload,
  };
};

export const getEducationLevelFailure = (payload: ErrorsPayload): getEducationLevelFailureAction => {
  return {
    type: "GET_EDUCATION_LEVEL_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Get Curriculums

export const getCurriculums = (payload: getCurriculumsPayload): getCurriculumsAction => {
  return {
    type: "GET_CURRICULUMS",
    payload,
  };
};

export const getCurriculumsSuccess = (payload: getCurriculumsSuccessPayload): getCurriculumsSuccessAction => {
  return {
    type: "GET_CURRICULUMS_SUCCESS",
    payload,
  };
};

export const getCurriculumsFailure = (payload: ErrorsPayload): getCurriculumsFailureAction => {
  return {
    type: "GET_CURRICULUMS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Create Curriculum

export const createCurriculum = (payload: createCurriculumPayload, callback?: () => any): createCurriculumAction => {
  return {
    type: "CREATE_CURRICULUM",
    payload,
    callback,
  };
};

export const createCurriculumSuccess = (payload: createCurriculumSuccessPayload): createCurriculumSuccessAction => {
  return {
    type: "CREATE_CURRICULUM_SUCCESS",
    payload,
  };
};

export const createCurriculumFailure = (payload: ErrorsPayload): createCurriculumFailureAction => {
  return {
    type: "CREATE_CURRICULUM_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Assign Curriculums

export const assignCurriculums = (payload: assignCurriculumsPayload, callback?: () => any): assignCurriculumsAction => {
  return {
    type: "ASSIGN_CURRICULUMS",
    payload,
    callback,
  };
};

// Get Curriculums For Class

export const getCurriculumsForClass = (payload: getCurriculumsForClassPayload, callback?: () => any): getCurriculumsForClassAction => {
  return {
    type: "GET_CURRICULUMS_FOR_CLASS",
    payload,
    callback,
  };
};

export const getCurriculumsForClassSuccess = (payload: getCurriculumsForClassSuccessPayload): getCurriculumsForClassSuccessAction => {
  return {
    type: "GET_CURRICULUMS_FOR_CLASS_SUCCESS",
    payload,
  };
};

export const getCurriculumsForClassFailure = (payload: ErrorsPayload): getCurriculumsForClassFailureAction => {
  return {
    type: "GET_CURRICULUMS_FOR_CLASS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Get Curriculum For a Class

export const getCurriculumForClass = (payload: getCurriculumForClassPayload, callback?: () => any): getCurriculumForClassAction => {
  return {
    type: "GET_CURRICULUM_FOR_CLASS",
    payload,
    callback,
  };
};

export const getCurriculumForClassSuccess = (payload: getCurriculumForClassSuccessPayload): getCurriculumForClassSuccessAction => {
  return {
    type: "GET_CURRICULUM_FOR_CLASS_SUCCESS",
    payload,
  };
};

export const getCurriculumForClassFailure = (payload: ErrorsPayload): getCurriculumForClassFailureAction => {
  return {
    type: "GET_CURRICULUM_FOR_CLASS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Update Curriculum For a Class

export const updateCurriculumForClass = (payload: updateCurriculumForClassPayload, callback?: () => any): updateCurriculumForClassAction => {
  return {
    type: "UPDATE_CURRICULUM_FOR_CLASS",
    payload,
    callback,
  };
};

export const updateCurriculumForClassSuccess = (callback?: () => any): updateCurriculumForClassSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "UPDATE_CURRICULUM_FOR_CLASS_SUCCESS",
  };
};

export const updateCurriculumForClassFailure = (payload: ErrorsPayload): updateCurriculumForClassFailureAction => {
  return {
    type: "UPDATE_CURRICULUM_FOR_CLASS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Update Class Skills

export const updateClassSkills = (payload: updateClassSkillsPayload, callback?: () => any): updateClassSkillsAction => {
  return {
    type: "UPDATE_CLASS_SKILLS",
    payload,
    callback,
  };
};

export const updateClassSkillsSuccess = (payload: updateClassSkillsSuccessPayload): updateClassSkillsSuccessAction => {
  return {
    type: "UPDATE_CLASS_SKILLS_SUCCESS",
    payload,
  };
};

export const updateClassSkillsFailure = (payload: ErrorsPayload): updateClassSkillsFailureAction => {
  return {
    type: "UPDATE_CLASS_SKILLS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Delete Curriculum

export const deleteCurriculum = (payload: deleteCurriculumPayload, callback?: () => any): deleteCurriculumAction => {
  return {
    type: "DELETE_CURRICULUM",
    payload,
    callback,
  };
};

export const deleteCurriculumSuccess = (payload: deleteCurriculumSuccessPayload, callback?: () => any): deleteCurriculumSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "DELETE_CURRICULUM_SUCCESS",
    payload,
  };
};

export const deleteCurriculumFailure = (payload: ErrorsPayload): deleteCurriculumFailureAction => {
  return {
    type: "DELETE_CURRICULUM_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Get Question's Skills

export const getQuestionSkills = (payload: getQuestionSkillsPayload, callback?: () => any): getQuestionSkillsAction => {
  return {
    type: "GET_QUESTION_SKILLS",
    payload,
    callback,
  };
};

export const getQuestionSkillsSuccess = (payload: getQuestionSkillsSuccessPayload, callback?: () => any): getQuestionSkillsSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "GET_QUESTION_SKILLS_SUCCESS",
    payload,
  };
};

export const getQuestionSkillsFailure = (payload: ErrorsPayload): getQuestionSkillsFailureAction => {
  return {
    type: "GET_QUESTION_SKILLS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Update Question's Skills

export const updateQuestionSkills = (payload: updateQuestionSkillsPayload, callback?: () => any): updateQuestionSkillsAction => {
  return {
    type: "UPDATE_QUESTION_SKILLS",
    payload,
    callback,
  };
};

export const updateQuestionSkillsSuccess = (payload: updateQuestionSkillsSuccessPayload, callback?: () => any): updateQuestionSkillsSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "UPDATE_QUESTION_SKILLS_SUCCESS",
    payload,
  };
};

export const updateQuestionSkillsFailure = (payload: ErrorsPayload): updateQuestionSkillsFailureAction => {
  return {
    type: "UPDATE_QUESTION_SKILLS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Unmount Curriculum

export const unmountCurriculum = (): unmountCurriculumAction => {
  return {
    type: "UNMOUNT_CURRICULUM",
  };
};
