import React from "react";

import { getLanguageFromLocalStorage } from "../../../../services/api";
import translate from "../../../../services/translate";

import { ReactComponent as CirclesIcon } from "../../../../assets/images/dashboard/mobile_app_box/circles.svg";
import PhonePL from "../../../../assets/images/dashboard/mobile_app_box/phone_pl.png";
import PhoneEN from "../../../../assets/images/dashboard/mobile_app_box/phone_en.png";
import QRCodeAndroid from "../../../../assets/images/dashboard/mobile_app_box/qr_code_android.png";
import QRCodeIOS from "../../../../assets/images/dashboard/mobile_app_box/qr_code_ios.png";
import { ReactComponent as AppleStorePLIcon } from "../../../../assets/images/dashboard/mobile_app_box/apple_store_pl.svg";
import { ReactComponent as AppleStoreENIcon } from "../../../../assets/images/dashboard/mobile_app_box/apple_store_en.svg";
import { ReactComponent as GooglePlayPLIcon } from "../../../../assets/images/dashboard/mobile_app_box/google_play_pl.svg";
import { ReactComponent as GooglePlayENIcon } from "../../../../assets/images/dashboard/mobile_app_box/google_play_en.svg";

import styles from "./styles.module.scss";

export const MobileAppBox = () => {
  const isPL = getLanguageFromLocalStorage() === "pl";

  return (
    <div className={styles.mobileAppBox}>
      <h3>{translate("global.dashboard-mobile-app-box-heading")}</h3>
      <p className={styles.paragraphTop}>
        {translate("global.dashboard-mobile-app-box-desktop-text-1")}&nbsp;
        <b>{translate("global.dashboard-mobile-app-box-desktop-text-2")}</b>
        &nbsp;
        {translate("global.dashboard-mobile-app-box-desktop-text-3")}
      </p>
      <div className={styles.mobileContainer}>
        <img src={isPL ? PhonePL : PhoneEN} alt="phone"/>
        <div className={styles.QRbox}>
          <div>
            <span>iOS</span>
            <img src={QRCodeIOS} alt="ios"/>
          </div>
        </div>
        <div className={styles.QRbox}>
          <div>
            <span>Android</span>
            <img src={QRCodeAndroid} alt="android"/>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <p>
            <b>{translate("global.dashboard-mobile-app-box-mobile-text-1")}</b>
            &nbsp;
            {translate("global.dashboard-mobile-app-box-mobile-text-1")}
          </p>
          {isPL ? (
            <a
              href="https://apps.apple.com/app/wisdy/id1486131619"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppleStorePLIcon/>
            </a>
          ) : (
            <a
              href="https://apps.apple.com/app/wisdy/id1486131619"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppleStoreENIcon/>
            </a>
          )}
          {isPL ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.wisdy.wisdymobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlayPLIcon/>
            </a>
          ) : (
            <a
              href="https://play.google.com/store/apps/details?id=com.wisdy.wisdymobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlayENIcon/>
            </a>
          )}
        </div>
      </div>
      <CirclesIcon/>
    </div>
  );
};

export default MobileAppBox;
