import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useSpring, animated } from "react-spring";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";

import { ReactComponent as TimesIcon } from "../../../assets/images/times.svg";

import styles from "./styles.module.scss";


export const ToggleContent = ({ toggle, content }: any) => {
  const [isShown, setIsShown] = useState(false);
  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  return (
    <>
      {toggle(show)}
      {isShown && content(hide)}
    </>
  );
};

const Modal = ({ children, hide, isBig, isSmall, customStyles }: any) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  const modalContainerClass = classNames({
    [styles.modalContainer]: true,
    [styles.modalSmall]: isSmall,
    [styles.modalBig]: isBig,
    [customStyles]: customStyles,
  });

  const modalClass = classNames({
    [styles.modal]: true,
  });

  return (
    ReactDOM.createPortal(
      <div className={styles.container}>
        <animated.div style={props} className={modalContainerClass}>
          <OutsideClickHandler onOutsideClick={(mouseEvent: any) => {
            if (mouseEvent && mouseEvent.path) {
              const isModalFormulaOpened = mouseEvent.path.find((item: HTMLElement) => item.id === "modal-formula");
              const isModalVideoOpened = mouseEvent.path.find((item: HTMLElement) => item.id === "modal-video");

              if (isModalFormulaOpened || isModalVideoOpened) {
                return;
              }
            } else if (!mouseEvent.path) {
              return;
            }

            hide();
          }}>
            <button type="button" onClick={hide}>
              <TimesIcon/>
            </button>
            <div className={modalClass}>
              {children}
            </div>
          </OutsideClickHandler>
        </animated.div>
      </div>,
      document.getElementById("modal-root") as HTMLElement,
    )
  );
};

export default Modal;
