import React from "react";
import DOMPurify from "dompurify";
import pathOr from "ramda/src/pathOr";

import translate from "../../../../../../../../services/translate";

// Types
import {
  QuestionTypes,
  questionModel,
} from "../../../../../../../../models/redux/test";

interface Props {
  question: questionModel;
}

export const TestQuestionBodyCompactPDF = (props: Props) => {
  const isTrueFalse = (question: questionModel) => {
    return question.type === QuestionTypes.TRUE_FALSE;
  };

  return (
    <div
      style={{
        width: "100%",
        pageBreakInside: "avoid",
        margin: "0",
        padding: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          padding: "0 0 3px 0",
          margin: "0",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px 5px",
            textAlign: "center",
            height: "28px",
            minWidth: "38px",
            width: "38px",
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
            fontSize: "12px",
            alignSelf: "flex-start",
            margin: "0 17px 0 0",
            boxSizing: "border-box",
            background: "black",
            color: "white",
          }}
        >
          {props.question.order}
        </span>
        {isTrueFalse(props.question) ? (
          <p
            style={{
              flex: 1,
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#000",
              padding: "5px 30px 0 0",
              margin: "0",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <>
              <span>{translate("creator.true-false-desc-1")}</span>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                &nbsp;{props.question.answer_choices[0].name}&nbsp;
              </span>
              <span>{translate("creator.true-false-desc-2")}</span>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                &nbsp;{props.question.answer_choices[1].name}&nbsp;
              </span>
              <span>{translate("creator.true-false-desc-3")}</span>
            </>
          </p>
        ) : (
          <div
            style={{
              flex: 1,
              width: "100%",
              margin: 0,
              fontSize: "14px",
              padding: "5px 30px 0 0",
            }}
            className="questionBodyGlobal"
            dangerouslySetInnerHTML={{
              __html: `${
                props.question.body_html
                  ? DOMPurify.sanitize(props.question.body_html)
                  : DOMPurify.sanitize(pathOr("", ["question", "body"], props))
              }`,
            }}
          ></div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "5px 40px 0 0",
          }}
        >
          <span
            style={{
              fontSize: "12px",
              color: "#000",
              margin: "0 0 0 15px",
            }}
          >
            {props.question.points}p
          </span>
        </div>
      </div>
    </div>
  );
};

export default TestQuestionBodyCompactPDF;
