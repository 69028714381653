import React, { useEffect, useState } from "react";

import classNames from "classnames";

import merge from "ramda/src/merge";
import map from "ramda/src/map";
import when from "ramda/src/when";
import propEq from "ramda/src/propEq";
import propOr from "ramda/src/propOr";

import { ReactComponent as TrashIcon } from "../../../../../../../../../assets/images/trash.svg";

import QuestionAnswerEditor from "../Editor";
import { getErrorMessage } from "../../../../../../../../../services/common";

// Types
import {
  answerChoiceModel,
} from "../../../../../../../../../models/redux/test";
import { ErrorObject } from "../../../../../../../../../models/common";

import styles from "../../styles.module.scss";


interface Props {
  answerChoices: answerChoiceModel[];
  questionID: string;
  createQuestionAnswerChoice: () => any,
  updateQuestion: (name_html: string, name: string, id: string, isCorrect: boolean) => any;
  deleteQuestionAnswerChoice: (answerID: string) => any;
  testErrors: ErrorObject[];
}

export const Multiple = (props: Props) => {
  const [answerChoices, setAnswerChoices] = useState([] as answerChoiceModel[]);
  const [checkedItems, setCheckedItems] = useState([] as string[]);

  const getValue = (item: answerChoiceModel) => `multiple-answer_${props.questionID}_${item.id}`;

  useEffect(() => {
    const checkedAnswerChoices = props.answerChoices.filter(item => item.is_correct);

    setAnswerChoices(props.answerChoices);

    if (checkedAnswerChoices.length) {
      setCheckedItems(checkedAnswerChoices.map((item: any) => getValue(item)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const answerChoicesLength = props.answerChoices.length;
    const localAnswerChoicesLength = answerChoices.length;
    if (localAnswerChoicesLength && answerChoicesLength > localAnswerChoicesLength) {
      setAnswerChoices(answerChoices.concat(props.answerChoices[answerChoicesLength - 1]));
      return;
    }

    if (localAnswerChoicesLength !== answerChoicesLength) {
      setAnswerChoices(props.answerChoices);
      return;
    }

    const checkedAnswerChoices = props.answerChoices.filter(item => item.is_correct);

    if (checkedAnswerChoices.length) {
      setCheckedItems(checkedAnswerChoices.map((item: any) => getValue(item)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answerChoices]);

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answerChoices]);

  const onAnswerNameChange = (value: any, id: string, isHTML?: boolean) => {
    const answer = answerChoices.find(item => item.id === id);

    if (answer) {
      setAnswerChoices(
        map(
          when(
            propEq("id", id),
            (item: answerChoiceModel) => merge(item, { name_html: value.html, name: value.plain }),
          ),
          answerChoices,
        ),
      );
    }
  };

  const setCorrectAnswer = (item: answerChoiceModel) => {
    const checkedItem = getValue(item) as string;
    const isChecked = checkedItems.includes(checkedItem);

    props.updateQuestion(
      item.name_html,
      item.name,
      item.id,
      !isChecked,
    );

    if (isChecked) {
      setCheckedItems(checkedItems.filter(value => value !== checkedItem));
      return;
    }

    setCheckedItems(checkedItems.concat(checkedItem));
  };

  const getError = (id: string) => getErrorMessage(props.testErrors, `${id}/name`);

  const radioHasErrorClass = () => classNames({
    [styles.error]: getErrorMessage(props.testErrors, `questions/${props.questionID}/answer_choices`),
  });

  return (
    <>
      {answerChoices.map((item: answerChoiceModel) => (
        <div key={`${item.id}_${item.order}`} className={styles.listItemContainer}>
          <li>
            <div>
              <input
                type="checkbox"
                name={`${props.questionID}_question_answer_checkbox`}
                checked={checkedItems.includes(getValue(item))}
                value={getValue(item)}
                onChange={() => setCorrectAnswer(item)}
                className={radioHasErrorClass()}
              />
              <QuestionAnswerEditor
                questionAnswer={propOr("", "name_html", item)}
                setQuestionAnswer={(value: string) => onAnswerNameChange(value, item.id)}
                updateQuestion={(name_html?: string, name?: string) => {
                  const answer = answerChoices.find((answer) => answer.id === item.id) as answerChoiceModel;
                  props.updateQuestion(
                    name_html ? name_html : answer.name_html,
                    name ? name : answer.name,
                    item.id,
                    item.is_correct,
                  );
                }}
                hasError={!!getError(item.id)}
              />
            </div>
            <button type="button" onClick={(e) => {
              setAnswerChoices(
                answerChoices
                  .filter(answer => answer.id !== item.id)
                  .map((item, index) => ({ ...item, order: index+1 })),
              );
              props.deleteQuestionAnswerChoice(item.id);
            }}>
              <TrashIcon/>
            </button>
          </li>
          {getError(item.id) &&
            <span className={styles.error}>{getError(item.id)}</span>
          }
        </div>
      ))}
    </>
  );
};

export default Multiple;
