import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

import propOr from "ramda/src/propOr";
import map from "ramda/src/map";
import when from "ramda/src/when";
import propEq from "ramda/src/propEq";
import assoc from "ramda/src/assoc";

import Modal, { ToggleContent } from "../../../../../Common/Modal";
import AnswerChoiceCheckbox from "./AnswerChoiceCheckbox";

import translate from "../../../../../../services/translate";

import { ReactComponent as EyeIcon } from "../../../../../../assets/images/eye_icon.svg";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/images/small_arrow_down_grey.svg";

// Types
import { studentReportQuestionModel } from "../../../../../../models/redux/report";
import {
  resultObject,
  QuestionTypes,
} from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";
import assocPath from "ramda/src/assocPath";

interface Props {
  questions: studentReportQuestionModel[];
  testID: string;
  classID: string;
  studentID: string;
  displayOnly?: boolean;
  editMode?: boolean;
  localStudentResults?: resultObject[];
  setLocalStudentResults?: any;
}

export const TestReportStudentQuestions = (props: Props) => {
  const [questionInfo, setQuestionInfo] = useState<number[]>([]);

  const isFilledOutOpenQuestion = (answer: string) =>
    answer.includes("<p>") ||
    answer.includes("<ul>") ||
    answer.includes("<li>") ||
    answer.includes("<br>") ||
    answer.includes("<em>") ||
    answer.includes("<u>") ||
    answer.includes("<span class=");
  const answerWasGiven = (answer: string) => !answer.includes("-");

  const arrowClass = (number: number) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: questionInfo.includes(number),
    });

  const gainedPointsClass = (gainedPoints: number) =>
    classNames({
      [styles.zero]: !gainedPoints,
    });

  const answerGivenClass = (
    answer: string,
    correctAnswer: string,
    type: string,
  ) =>
    classNames({
      [styles.correct]:
        answer === correctAnswer &&
        answerWasGiven(answer) &&
        type !== QuestionTypes.OPEN,
      [styles.incorrect]:
        answer !== correctAnswer &&
        answerWasGiven(answer) &&
        type !== QuestionTypes.OPEN,
    });

  const openQuestionEditInputClass = (value: string | number) =>
    classNames({
      [styles.filled]: !!`${value}`.length,
      [styles.empty]: !`${value}`.length,
    });

  const toggleArrow = (number: number) => {
    const isChecked = questionInfo.includes(number);

    if (isChecked) {
      setQuestionInfo(questionInfo.filter((value) => value !== number));
      return;
    }

    setQuestionInfo(questionInfo.concat(number));
  };

  const getResult = (number: number) => {
    if (Array.isArray(props.localStudentResults)) {
      return props.localStudentResults.find(
        (item: resultObject) => item.number === number,
      );
    }
    return;
  };

  const onPointsChange = (e: any, number: number) => {
    e.preventDefault();

    const result = getResult(number);

    if (
      result &&
      !e.target.value.includes(".") &&
      (!e.target.value.length || Number.isInteger(Number(e.target.value)))
    ) {
      props.setLocalStudentResults(
        map(
          when(propEq("number", number), assoc("points", e.target.value)),
          props.localStudentResults,
        ),
      );
    }
  };

  const onPointsBlur = (number: number) => {
    const result = getResult(number);

    if (result) {
      if (result.points && result.max_points) {
        if (result.points > result.max_points) {
          props.setLocalStudentResults(
            map(
              when(
                propEq("number", number),
                assoc("points", result.max_points),
              ),
              props.localStudentResults,
            ),
          );
        }
      } else {
        props.setLocalStudentResults(
          map(
            when(propEq("number", number), assoc("points", 0)),
            props.localStudentResults,
          ),
        );
      }
    }
  };

  const onAnswerChange = (number: number, answer: number) => {
    const result = getResult(number);

    if (result && result.answer_choices) {
      const isChecked = result.answer_choices.selected.includes(answer);
      props.setLocalStudentResults(
        map(
          when(
            propEq("number", number),
            assocPath(
              ["answer_choices", "selected"],
              isChecked
                ? result.answer_choices.selected.filter(
                  (value) => value !== answer,
                )
                : result.answer_choices.selected.concat(answer),
            ),
          ),
          props.localStudentResults,
        ),
      );
    }
  };

  return (
    <ul className={styles.questions}>
      <li className={styles.headingItem}>
        <div className={styles.questionNumber}>
          <span>{translate("report.question-number-short-2")}</span>
        </div>
        <div className={styles.questionType}>
          <span>{translate("report.question-type")}</span>
        </div>
        <div className={styles.answerGiven}>
          <span>{translate("report.answer-given-short")}</span>
        </div>
        <div className={styles.correctAnswer}>
          <span>{translate("report.correct-answer-short")}</span>
        </div>
        <div className={styles.gainedPoints}>
          <span>{translate("report.gained-points")}</span>
        </div>
        <div className={styles.actions}></div>
      </li>
      {props.questions.map((item: studentReportQuestionModel) => (
        <li className={styles.listItem} key={item.number}>
          <div className={styles.questionNumber}>
            <span>{item.number}</span>
          </div>
          <div className={styles.questionType}>
            <span>{item.type_name}</span>
          </div>
          <div className={styles.answerGiven}>
            {props.editMode && item.type !== QuestionTypes.OPEN ? (
              <div>
                <AnswerChoiceCheckbox
                  id={item.id}
                  result={getResult(item.number)}
                  onAnswerChange={onAnswerChange}
                />
              </div>
            ) : item.type === QuestionTypes.OPEN &&
              isFilledOutOpenQuestion(item.answer) ? (
                <ToggleContent
                  toggle={(show: any) =>
                    !props.editMode && (
                      <button type="button" onClick={show}>
                        {translate("global.show")}
                      </button>
                    )
                  }
                  content={(hide: () => {}) => (
                    <Modal hide={hide} customStyles={styles.modal}>
                      <div className={styles.openQuestionAnswerModal}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.answer),
                          }}
                        ></div>
                        <div className={styles.buttonContainer}>
                          <button type="button" onClick={hide}>
                            {translate("global.close")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  )}
                />
              ) : (
                <span
                  className={answerGivenClass(
                    item.answer,
                    item.correct_answer,
                    item.type,
                  )}
                >
                  {item.answer}
                </span>
              )}
          </div>
          <div className={styles.correctAnswer}>
            <span>{item.correct_answer}</span>
          </div>
          <div className={styles.gainedPoints}>
            {props.editMode &&
            (item.type === QuestionTypes.OPEN ||
              item.type === QuestionTypes.MATCHING ||
              item.type === QuestionTypes.FILL_IN_THE_GAPS) ? (
                <span>
                  <input
                    className={openQuestionEditInputClass(
                      propOr("", "points", getResult(item.number)),
                    )}
                    value={propOr("", "points", getResult(item.number))}
                    onChange={(e) => onPointsChange(e, item.number)}
                    onBlur={() => onPointsBlur(item.number)}
                  ></input>
                /{item.max_points}
                </span>
              ) : (
                <span>
                  <span className={gainedPointsClass(item.gained_points)}>
                    {propOr("-", "gained_points", item)}
                  </span>
                /{item.max_points}
                </span>
              )}
          </div>
          <div className={styles.actions}>
            {!props.displayOnly && (
              <div className={styles.tooltipContainer}>
                <ReactTooltip
                  id="tooltip"
                  place="left"
                  effect="solid"
                  className={styles.tooltip}
                >
                  <p>{translate("global.details")}</p>
                </ReactTooltip>
                <i data-tip data-for="tooltip">
                  <Link
                    to={`/test/${props.testID}/report/class/${props.classID}/question/${item.number}?studentID=${props.studentID}`}
                  >
                    <EyeIcon/>
                  </Link>
                </i>
              </div>
            )}
            <button
              className={arrowClass(item.number)}
              onClick={() => toggleArrow(item.number)}
            >
              <ArrowIcon/>
            </button>
          </div>
          {questionInfo.includes(item.number) && (
            <div className={styles.dropDownContainer}>
              <ul>
                <li className={styles.answerGiven}>
                  <span>{translate("report.answer-given-short")}</span>
                  {props.editMode && item.type !== QuestionTypes.OPEN ? (
                    <div className={styles.answerChoiceCheckboxContainer}>
                      <AnswerChoiceCheckbox
                        id={item.id}
                        result={getResult(item.number)}
                        onAnswerChange={onAnswerChange}
                      />
                    </div>
                  ) : item.type === QuestionTypes.OPEN &&
                    isFilledOutOpenQuestion(item.answer) ? (
                      <ToggleContent
                        toggle={(show: any) =>
                          !props.editMode && (
                            <button type="button" onClick={show}>
                              {translate("global.show")}
                            </button>
                          )
                        }
                        content={(hide: () => {}) => (
                          <Modal hide={hide} customStyles={styles.modal}>
                            <div className={styles.openQuestionAnswerModal}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item.answer),
                                }}
                              ></div>
                              <div className={styles.buttonContainer}>
                                <button type="button" onClick={hide}>
                                  {translate("global.close")}
                                </button>
                              </div>
                            </div>
                          </Modal>
                        )}
                      />
                    ) : (
                      <span
                        className={answerGivenClass(
                          item.answer,
                          item.correct_answer,
                          item.type,
                        )}
                      >
                        {item.answer}
                      </span>
                    )}
                </li>
                <li className={styles.correctAnswer}>
                  <span>{translate("report.correct-answer-short")}</span>
                  <span>{item.correct_answer}</span>
                </li>
                <li className={styles.gainedPoints}>
                  <span>{translate("report.gained-points")}</span>
                  {props.editMode && QuestionTypes.OPEN ? (
                    <span>
                      <input
                        className={openQuestionEditInputClass(
                          propOr("", "points", getResult(item.number)),
                        )}
                        value={propOr("", "points", getResult(item.number))}
                        onChange={(e) => onPointsChange(e, item.number)}
                        onBlur={() => onPointsBlur(item.number)}
                      ></input>
                      /{item.max_points}
                    </span>
                  ) : (
                    <span>
                      <span className={gainedPointsClass(item.gained_points)}>
                        {propOr("-", "gained_points", item)}
                      </span>
                      /{item.max_points}
                    </span>
                  )}
                </li>
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default TestReportStudentQuestions;
