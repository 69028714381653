import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import pathOr from "ramda/src/pathOr";
import isEmpty from "ramda/src/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import QuestionCurriculumSelectCurriculum from "./SelectCurriculum";
import QuestionCurriculumSelectSkills from "./SelectSkills";

import translate from "../../../../../../../../services/translate";

import {
  getCurriculumsForClass,
  getEducationLevel,
  getCurriculums,
  getCurriculumForClass,
  getQuestionSkills,
  updateQuestionSkills,
  unmountCurriculum,
  assignCurriculums,
} from "../../../../../../../../redux/actions/curriculum";

// Types
import {
  curriculum,
  curriculumDetails,
  curriculumStateModel,
  questionCurriculum,
  getCurriculumsForClass as getCurriculumsForClassFunction,
  getEducationLevel as getEducationLevelFunction,
  getCurriculums as getCurriculumsFunction,
  getCurriculumForClass as getCurriculumForClassFunction,
  getQuestionSkills as getQuestionSkillsFunction,
  updateQuestionSkills as updateQuestionSkillsFunction,
  unmountCurriculum as unmountCurriculumFunction,
  assignCurriculums as assignCurriculumsFunction,
} from "../../../../../../../../models/redux/curriculum";
import { testStateModel } from "../../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  hide: () => any;
  questionID: string;
  curriculum: curriculumStateModel;
  test: testStateModel;
  getCurriculumsForClass: getCurriculumsForClassFunction;
  getEducationLevel: getEducationLevelFunction;
  getCurriculums: getCurriculumsFunction;
  getCurriculumForClass: getCurriculumForClassFunction;
  getQuestionSkills: getQuestionSkillsFunction;
  updateQuestionSkills: updateQuestionSkillsFunction;
  unmountCurriculum: unmountCurriculumFunction;
  assignCurriculums: assignCurriculumsFunction;
}

export const QuestionCurriculumModal = (props: Props) => {
  const initialAssignedCurriculum = {
    completeness: 0,
    education_level: "",
    id: "",
    skills: [],
    subject: "",
    subject_name: "",
    variant: "",
  };
  const [assignedCurriculum, setAssignedCurriculum] =
    useState<curriculumDetails>(initialAssignedCurriculum);
  const [selectedCurriculums, setSelectedCurriculums] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [internalInProgress, setInternalInProgress] = useState(true);

  useEffect(() => {
    if (props.test.class_id) {
      props.getCurriculumsForClass({ class_id: props.test.class_id });
    }

    props.getQuestionSkills({ question_id: props.questionID });

    setTimeout(() => setInternalInProgress(false), 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(props.curriculum.curriculum_details)) {
      setAssignedCurriculum(
        props.curriculum.curriculum_details as curriculumDetails,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculum.curriculum_details]);

  useEffect(() => {
    const curriculum = pathOr(
      [],
      ["curriculum", "question_skills"],
      props,
    ).find(
      (item: questionCurriculum) => item.curriculum_id === assignedCurriculum.id,
    );

    if (curriculum) {
      setSelectedSkills(getAllChosenSkills(pathOr([], ["skills"], curriculum)));
    }

    if (
      !!props.curriculum.question_skills.length &&
      props.curriculum.question_skills[0].curriculum_id !==
        assignedCurriculum.id
    ) {
      props.getCurriculumForClass({
        class_id: props.test.class_id,
        curriculum_id: props.curriculum.question_skills[0].curriculum_id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculum.question_skills, assignedCurriculum]);

  useEffect(() => {
    if (props.test.subject) {
      getAssignedCurriculum();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculum.class_curriculums, props.test.subject]);

  const getAssignedCurriculum = () => {
    if (props.curriculum.class_curriculums.length) {
      const pickedCurriculum = props.curriculum.class_curriculums.find(
        (item: curriculum) =>
          item.subject === pathOr("", ["test", "subject", "type"], props),
      );
      if (pickedCurriculum && pickedCurriculum.id) {
        props.getCurriculumForClass({
          class_id: props.test.class_id,
          curriculum_id: pickedCurriculum.id,
        });
      }
    }
  };

  const getAllChosenSkills = (children: any[], IDsArray?: any) => {
    let arr = IDsArray || ([] as string[]);
    children.forEach((item: any) => {
      arr.push(item.id);
      if (item.children && !!item.children.length) {
        getAllChosenSkills(item.children, arr);
      }
    });

    return arr;
  };

  const unmount = () => {
    setAssignedCurriculum(initialAssignedCurriculum);
    setSelectedCurriculums([]);
    setSelectedSkills([]);
    props.unmountCurriculum();
  };

  return (
    <div className={styles.assignSkillModal}>
      {!props.curriculum.in_progress.get_question_skills &&
        !props.curriculum.in_progress.get_curriculum_for_class &&
        !internalInProgress &&
        (assignedCurriculum.id ? (
          <QuestionCurriculumSelectSkills
            questionID={props.questionID}
            curriculum={props.curriculum}
            setSelectedSkills={setSelectedSkills}
            selectedSkills={selectedSkills}
            unmount={unmount}
          />
        ) : (
          <QuestionCurriculumSelectCurriculum
            curriculum={props.curriculum}
            subject={pathOr("", ["test", "subject", "type"], props)}
            subjectID={pathOr("", ["test", "subject", "id"], props)}
            getEducationLevel={props.getEducationLevel}
            getCurriculums={props.getCurriculums}
            setSelectedCurriculums={setSelectedCurriculums}
            selectedCurriculums={selectedCurriculums}
            assignCurriculums={assignCurriculums}
            classId={props.test.class_id}
          />
        ))}
      <div className={styles.buttonContainer}>
        <button
          type="button"
          onClick={() => {
            unmount();
            props.hide();
          }}
        >
          {translate("global.cancel")}
        </button>
        {assignedCurriculum.id ? (
          <button
            type="button"
            disabled={props.curriculum.in_progress.update_question_skills}
            onClick={() => {
              props.updateQuestionSkills(
                {
                  question_id: props.questionID,
                  skills: selectedSkills,
                  test_id: props.test.id,
                  class_id: props.test.class_id,
                },
                () => {
                  unmount();
                  props.hide();
                },
              );
            }}
          >
            {props.curriculum.in_progress.update_question_skills ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              translate("global.save")
            )}
          </button>
        ) : (
          <button
            type="button"
            disabled={!selectedCurriculums.length}
            onClick={() => {
              props.getCurriculumForClass({
                class_id: props.test.class_id,
                curriculum_id: selectedCurriculums[0],
              });
            }}
          >
            {translate("global.next")}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  curriculum: curriculumStateModel;
  test: testStateModel;
}) => ({
  curriculum: state.curriculum,
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCurriculumsForClass,
      getEducationLevel,
      getCurriculums,
      getCurriculumForClass,
      getQuestionSkills,
      updateQuestionSkills,
      unmountCurriculum,
      assignCurriculums,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionCurriculumModal);
