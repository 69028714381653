import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";
import merge from "ramda/src/merge";
import propOr from "ramda/src/propOr";
import classNames from "classnames";

import translate from "../../../../../services/translate";
import { getTotalPoints } from "../../../../../services/common/test";

import {
  createTestResultsByStudent,
  getTest,
  unmountTest,
} from "../../../../../redux/actions/test";

import TestOnlineNav from "../Nav";
import QuestionMultipleChoiceSingleAnswerOnline from "./Questions/MultipleChoice/Single";
import QuestionMultipleChoiceMultipleAnswersOnline from "./Questions/MultipleChoice/Multiple";
import QuestionTrueFalseOnline from "./Questions/TrueFalse";
import QuestionOpenOnline from "./Questions/Open";
import QuestionMatchingOnline from "./Questions/Matching";
import QuestionFillInTheGapsOnline from "./Questions/FillInTheGaps";
import OnlineTestTimer from "./Timer";

// Types
import { Location } from "history";
import {
  testStateModel,
  QuestionTypes,
  QuestionSubtype,
  studentAnswer,
  getTest as getTestFunction,
  createTestResultsByStudent as createTestResultsByStudentFunction,
} from "../../../../../models/redux/test";
import { studentStateModel } from "../../../../../models/redux/student";

import styles from "./styles.module.scss";

interface Props {
  history: any;
  location: Location;
  match: any;
  test: testStateModel;
  getTest: getTestFunction;
  unmountTest: () => {};
  createTestResultsByStudent: createTestResultsByStudentFunction;
  student: studentStateModel;
  isReadOnly?: boolean;
}

export const TestOnlineSolve = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const testID = parsed.testID as string;

  const [answers, setAnswers] = useState<studentAnswer[]>([]);
  const [disabledTest, setDisabledTest] = useState(false);

  useEffect(() => {
    if (!props.isReadOnly && props.student.code && props.student.number) {
      props.getTest({
        classID,
        testID,
        studentCode: props.student.code,
        studentNumber: `${props.student.number}`,
      });
    }

    return () => {
      props.unmountTest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !props.isReadOnly &&
      props.test.id !== testID &&
      props.test.class_id !== classID &&
      !props.student.code
    ) {
      props.history.replace(
        `/test-online-verification${props.history.location.search}`,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test]);

  useEffect(() => {
    if (props.test.questions.length) {
      const initAnswers = props.test.questions.map((item) => ({
        question_id: item.id,
        ...merge({}, item.type === QuestionTypes.OPEN ? { answer: "" } : {}),
        ...merge(
          {},
          item.type === QuestionTypes.MATCHING ? { matchings: [] } : {},
        ),
        ...merge(
          {},
          item.type !== QuestionTypes.OPEN ? { answer_choices: [] } : {},
        ),
      }));

      setAnswers(initAnswers);
    }
  }, [props.test.questions]);

  const getCheckedAnswerChoices = (questionID: string) => {
    const question = answers.find((item) => item.question_id === questionID);

    if (question) {
      return propOr([], "answer_choices", question) as [];
    }
    return [];
  };

  const onAnswerChoiceClick = (
    questionID: string,
    order: number,
    questionType: string,
  ) => {
    const question = answers.find((item) => item.question_id === questionID);
    let updatedAnswers = [...answers];

    if (question) {
      const questionIndex = answers.findIndex(
        (item) => item.question_id === questionID,
      );
      const questionAnswerChoices = propOr(
        [],
        "answer_choices",
        question,
      ) as number[];

      if (questionType === QuestionSubtype.MULTIPLE) {
        updatedAnswers = Object.assign(updatedAnswers, {
          [questionIndex]: {
            question_id: questionID,
            answer_choices: questionAnswerChoices.includes(order)
              ? questionAnswerChoices.filter((item) => item !== order)
              : questionAnswerChoices.concat(order),
          },
        });
      }

      if (
        questionType === QuestionSubtype.SINGLE ||
        questionType === QuestionTypes.TRUE_FALSE
      ) {
        updatedAnswers = Object.assign(updatedAnswers, {
          [questionIndex]: {
            question_id: questionID,
            answer_choices: questionAnswerChoices.includes(order)
              ? questionAnswerChoices
              : [order],
          },
        });
      }
    }
    setAnswers(updatedAnswers);
  };

  const onAnswerChoiceChange = (
    questionID: string,
    answerHTML: string,
    questionType: string,
  ) => {
    const question = answers.find((item) => item.question_id === questionID);
    let updatedAnswers = [...answers];

    if (question) {
      const questionIndex = answers.findIndex(
        (item) => item.question_id === questionID,
      );

      if (questionType === QuestionTypes.OPEN) {
        updatedAnswers = Object.assign(updatedAnswers, {
          [questionIndex]: {
            question_id: questionID,
            answer: answerHTML,
          },
        });
      }
    }
    setAnswers(updatedAnswers);
  };

  const onMatchChange = (
    questionID: string,
    arr: any[],
    questionType: string,
  ) => {
    const question = answers.find((item) => item.question_id === questionID);
    let updatedAnswers = [...answers];

    if (question) {
      const questionIndex = answers.findIndex(
        (item) => item.question_id === questionID,
      );

      if (questionType === QuestionTypes.MATCHING) {
        updatedAnswers = Object.assign(updatedAnswers, {
          [questionIndex]: {
            question_id: questionID,
            matchings: arr.map((item) => ({
              left: Number(item.left),
              right: Number(item.right),
            })),
          },
        });
      }
    }
    setAnswers(updatedAnswers);
  };

  const onFillInTheGapsChange = (
    questionID: string,
    gaps: string[],
    questionType: string,
  ) => {
    const question = answers.find((item) => item.question_id === questionID);
    let updatedAnswers = [...answers];

    if (question) {
      const questionIndex = answers.findIndex(
        (item) => item.question_id === questionID,
      );

      if (questionType === QuestionTypes.FILL_IN_THE_GAPS) {
        updatedAnswers = Object.assign(updatedAnswers, {
          [questionIndex]: {
            question_id: questionID,
            gaps,
          },
        });
      }
    }
    setAnswers(updatedAnswers);
  };

  const sendAnswers = (studentCode: string) =>
    props.createTestResultsByStudent(
      {
        answers,
        studentCode,
        studentNumber: Number(props.student.number),
        testID,
      },
      () => props.history.replace("/test-online-success"),
    );

  const containerClass = classNames({
    [styles.container]: true,
    [styles.containerReadOnly]: props.isReadOnly,
    [styles.containerNotReadOnly]: !props.isReadOnly,
  });

  return (
    <div className={containerClass}>
      {!props.isReadOnly && (
        <TestOnlineNav
          history={props.history}
          location={props.location}
          sendAnswers={sendAnswers}
          verified
        />
      )}
      <div className={styles.innerContainer}>
        {!props.isReadOnly && (
          <div className={styles.topContainer}>
            <div className={styles.right}>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("class.classname")}:&nbsp;
                </span>
                <span className={styles.violet}>{props.test.class_name}</span>
              </div>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("global.subject")}:&nbsp;
                </span>
                <span className={styles.violet}>{props.test.subject.name}</span>
              </div>
            </div>
            <div className={styles.headerBottomWrapper}>
              <h1>{props.test.name}</h1>
              {props.test.ends_at && (
                <OnlineTestTimer
                  endsAt={props.test.ends_at}
                  onFinish={() => {
                    setDisabledTest(true);
                    sendAnswers(props.history.location.state.verificationCode);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {!props.isReadOnly && (
          <div className={styles.summaryBox}>
            <span>{translate("global.summary")}:</span>
            <div>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("global.questions")}:&nbsp;
                </span>
                <span className={styles.violet}>
                  {props.test.questions.length}
                </span>
              </div>
              <div className={styles.textContainer}>
                <span className={styles.darkGrey}>
                  {translate("global.points")}:&nbsp;
                </span>
                <span className={styles.violet}>
                  {getTotalPoints(props.test.questions)}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={styles.testContainer}>
          {props.test.questions.map((item) => {
            if (item.type === QuestionTypes.MULTIPLE_CHOICE) {
              if (item.type_data.subtype === QuestionSubtype.MULTIPLE) {
                return (
                  <QuestionMultipleChoiceMultipleAnswersOnline
                    key={item.id}
                    question={item}
                    checkedAnswerChoices={getCheckedAnswerChoices(item.id)}
                    onAnswerChoiceClick={(order: number) =>
                      onAnswerChoiceClick(
                        item.id,
                        order,
                        QuestionSubtype.MULTIPLE,
                      )
                    }
                    isReadOnly={props.isReadOnly || disabledTest}
                  />
                );
              }
              if (item.type_data.subtype === QuestionSubtype.SINGLE) {
                return (
                  <QuestionMultipleChoiceSingleAnswerOnline
                    key={item.id}
                    question={item}
                    checkedAnswerChoices={getCheckedAnswerChoices(item.id)}
                    onAnswerChoiceClick={(order: number) =>
                      onAnswerChoiceClick(
                        item.id,
                        order,
                        QuestionSubtype.SINGLE,
                      )
                    }
                    isReadOnly={props.isReadOnly || disabledTest}
                  />
                );
              }
            }
            if (item.type === QuestionTypes.TRUE_FALSE) {
              return (
                <QuestionTrueFalseOnline
                  key={item.id}
                  question={item}
                  checkedAnswerChoices={getCheckedAnswerChoices(item.id)}
                  onAnswerChoiceClick={(order: number) =>
                    onAnswerChoiceClick(
                      item.id,
                      order,
                      QuestionTypes.TRUE_FALSE,
                    )
                  }
                  isReadOnly={props.isReadOnly || disabledTest}
                />
              );
            }
            if (item.type === QuestionTypes.OPEN) {
              return (
                <QuestionOpenOnline
                  history={props.history}
                  location={props.location}
                  key={item.id}
                  question={item}
                  onAnswerChoiceChange={(answerHTML: string) =>
                    onAnswerChoiceChange(
                      item.id,
                      answerHTML,
                      QuestionTypes.OPEN,
                    )
                  }
                  isReadOnly={props.isReadOnly || disabledTest}
                />
              );
            }
            if (item.type === QuestionTypes.MATCHING) {
              return (
                <QuestionMatchingOnline
                  key={item.id}
                  question={item}
                  isReadOnly={props.isReadOnly || disabledTest}
                  onMatchChange={(matchings: any) =>
                    onMatchChange(item.id, matchings, QuestionTypes.MATCHING)
                  }
                />
              );
            }
            if (item.type === QuestionTypes.FILL_IN_THE_GAPS) {
              return (
                <QuestionFillInTheGapsOnline
                  key={item.id}
                  question={item}
                  isReadOnly={props.isReadOnly || disabledTest}
                  onFillInTheGapsChange={(gaps: any) =>
                    onFillInTheGapsChange(
                      item.id,
                      gaps,
                      QuestionTypes.FILL_IN_THE_GAPS,
                    )
                  }
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  test: testStateModel;
  student: studentStateModel;
}) => ({
  test: state.test,
  student: state.student,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createTestResultsByStudent,
      getTest,
      unmountTest,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestOnlineSolve);
