import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OutsideClickHandler from "react-outside-click-handler";
import propOr from "ramda/src/propOr";

import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";

import { ReactComponent as ArrowDownGrey } from "../../../assets/images/small_arrow_down_grey.svg";

import Modal from "../Modal";
import Input from "../Input";

import { getClasses } from "../../../redux/actions/teacher";
import { duplicateLesson } from "../../../redux/actions/lesson";
import { getClass } from "../../../redux/actions/class";

// Types
import {
  classModel,
  getClasses as getClassesFunction,
  teacherStateModel,
} from "../../../models/redux/teacher";
import {
  duplicateLesson as duplicateLessonFunction,
  lessonStateModel,
} from "../../../models/redux/lesson";
import { getClass as getClassFunction } from "../../../models/redux/class";

import styles from "./styles.module.scss";

interface Props {
  lessonID: string;
  classes: classModel[];
  duplicateLesson: duplicateLessonFunction;
  getClasses: getClassesFunction;
  getClass: getClassFunction;
  hide: () => any;
  inProgress: boolean;
}

export const DuplicateLessonModal = (props: Props) => {
  const [selectedClass, setSelectedClass] = useState({} as classModel);
  const [className, setClassName] = useState("");
  const [classList, setClassList] = useState([] as classModel[]);
  const [showDropdown, toggleDropdown] = useState(false);

  useEffect(() => {
    props.getClasses();

    setClassList(props.classes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClassList(props.classes);
  }, [props.classes]);

  const isNextButtonDisabled = () =>
    !(selectedClass && selectedClass.id) || props.inProgress;

  return (
    <Modal customStyles={styles.modal} hide={props.hide}>
      <div className={styles.duplicateLessonModal}>
        <span>
          <span>{translate("global.duplicate")}</span>{" "}
          {translate("lessons.a-lesson")}
        </span>
        <p>{translate("lessons.duplicate-lesson-description")}</p>
        <OutsideClickHandler
          onOutsideClick={(e: any) => {
            outsideClickHelper(e, "dropdown", () => toggleDropdown(false));
            setClassName(propOr("", "name", selectedClass));
            setClassList(props.classes);
          }}
        >
          <div className={styles.wrapper}>
            <div onClick={() => toggleDropdown(true)}>
              <Input
                id="class_name"
                type="text"
                name="class_name"
                value={className}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const classItem = props.classes.filter((item: classModel) =>
                    item.name.includes(e.target.value),
                  ) as classModel[];
                  setClassList(classItem);
                  setClassName(e.target.value);
                }}
                icon={() => <ArrowDownGrey/>}
                label={
                  <>
                    {translate("global.pick-up")}{" "}
                    {translate("sign_up.a-class-accusative")}
                  </>
                }
                labelHiddenOnActive
              />
            </div>
            {showDropdown && (
              <div className={styles.dropdown}>
                <ul>
                  {classList.map((item: classModel) => (
                    <li key={item.id}>
                      <button
                        type="button"
                        onClick={() => {
                          setClassName(item.name);
                          setSelectedClass(item);
                          toggleDropdown(false);
                        }}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                  {!classList.length && (
                    <li>
                      <span>{translate("global.no-results")}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </OutsideClickHandler>
        <button
          type="button"
          disabled={isNextButtonDisabled()}
          onClick={() => {
            props.duplicateLesson(
              {
                lesson_id: props.lessonID,
                class_id: selectedClass.id,
              },
              () => {
                props.hide();
              },
            );
          }}
        >
          {translate("global.duplicate")}
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  lesson: lessonStateModel;
}) => ({
  classes: state.teacher.classes,
  inProgress: state.lesson.in_progress.update_lesson,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
      duplicateLesson,
      getClass,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DuplicateLessonModal);
