import React from "react";

// Types
import {
  questionModel,
} from "../../../../../../../models/redux/test";

import stylesDefault from "./styles.module.scss";
import stylesCompact from "./styles_compact.module.scss";


interface Props {
  question: questionModel;
  index: number;
  reloadPreview?: () => any;
  isCompact?: boolean;
}

export const QuestionImagePreview = (props: Props) => {
  const styles = props.isCompact ? stylesCompact : stylesDefault;

  return (
    <>
      {props.question.image &&
        <img
          id={`question_img_${props.question.id}`}
          data-type="question-image"
          className={styles.questionImg}
          src={props.question.image}
          alt={`img_${props.question.id}_${props.index}`}
          onLoad={() => props.reloadPreview && props.reloadPreview()}
        />
      }
    </>
  );
};

export default QuestionImagePreview;
