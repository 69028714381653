import React, { useEffect, useState, useMemo } from "react";
import { differenceInSeconds, parseISO } from "date-fns";
import { ReactComponent as HourglassIcon } from "../../../../../../assets/images/hourglass.svg";
import Modal from "../../../../../Common/Modal";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  endsAt: string;
  onFinish: () => void;
}

export const OnlineTestTimer = (props: Props) => {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [showTimeModal, setShowTimeModal] = useState(false);

  const diffInSeconds = differenceInSeconds(
    parseISO(props.endsAt),
    currentTime,
  );

  const getCoundown = () => {
    if (diffInSeconds < 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE,
    );
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const countdown = useMemo(getCoundown, [ONE_DAY, ONE_HOUR, diffInSeconds]);

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      !showTimeModal &&
      countdown.days === 0 &&
      countdown.hours === 0 &&
      countdown.minutes === 1 &&
      countdown.seconds === 0
    ) {
      setShowTimeModal(true);
    }
    if (
      countdown.days === 0 &&
      countdown.hours === 0 &&
      countdown.minutes === 0 &&
      countdown.seconds === 0
    ) {
      props.onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const time = `${
    countdown.hours > 0
      ? countdown.hours < 10
        ? `0${countdown.hours}:`
        : `${countdown.hours}:`
      : ""
  }${
    countdown.minutes > 0
      ? countdown.minutes < 10
        ? `0${countdown.minutes}:`
        : `${countdown.minutes}:`
      : "00:"
  }${countdown.seconds < 10 ? `0${countdown.seconds}` : countdown.seconds}`;

  return (
    <div className={styles.endTimeContainer}>
      <HourglassIcon/>
      <span>{time}</span>
      {!!showTimeModal && (
        <Modal
          hide={() => {
            setShowTimeModal(false);
          }}
          customStyles={styles.modal}
        >
          <div className={styles.modalContent}>
            <h2>{translate("test.information")}</h2>
            <p>
              {translate("test.time-limit-time-left-info-1")}{" "}
              <span className={styles.modalContentTime}>{time}</span>{" "}
              {translate("test.time-limit-time-left-info-2")}.
            </p>
            <p>
              {translate(
                "test.time-limit-answers-will-be-sent-automatically-info",
              )}
              .
            </p>
            <button onClick={() => setShowTimeModal(false)}>
              {translate("global.close")}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OnlineTestTimer;
