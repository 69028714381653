import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";

import { showToastEpic } from "./epics/app";
import {
  signUpEpic,
  signUpConfirmEpic,
  signUpConfirmResendLinkEpic,
  signInEpic,
  signInByTokenEpic,
  forgotPasswordEpic,
  resetPasswordEpic,
  signOutEpic,
  deleteAccountEpic,
  updateAccountEpic,
  updateAccountSuccessEpic,
  updateAccountFailureEpic,
  changePasswordEpic,
  changePasswordSuccessEpic,
} from "./epics/auth";
import {
  getSubjectsEpic,
  updateSubjectsEpic,
  createClassEpic,
  getClassesEpic,
  createClassSuccessEpic,
} from "./epics/teacher";
import {
  getClassEpic,
  getStudentsEpic,
  deleteStudentEpic,
  deleteStudentSuccessEpic,
  deleteStudentFailureEpic,
  addStudentEpic,
  addStudentSuccessEpic,
  addStudentFailureEpic,
  getStudentDetailsEpic,
  deleteClassEpic,
  deleteClassSuccessEpic,
  updateClassEpic,
  updateClassSuccessEpic,
} from "./epics/class";
import {
  createTestEpic,
  updateTestEpic,
  getQuestionTypes,
  createQuestionEpic,
  getTestEpic,
  getQuestionTypesEpic,
  createTestSuccessEpic,
  createQuestionSuccessEpic,
  updateQuestionEpic,
  updateQuestionSuccessEpic,
  deleteQuestionEpic,
  deleteQuestionSuccessEpic,
  createQuestionAnswerChoiceEpic,
  createQuestionAnswerChoiceSuccessEpic,
  updateQuestionAnswerChoiceEpic,
  deleteQuestionAnswerChoiceEpic,
  createQuestionMatchingEpic,
  updateQuestionMatchingEpic,
  deleteQuestionMatchingEpic,
  deleteTestEpic,
  deleteTestSuccessEpic,
  generatePDFEpic,
  validateTestEpic,
  deleteQuestionImageEpic,
  deleteQuestionImageSuccessEpic,
  duplicateTestEpic,
  duplicateTestSuccessEpic,
  duplicateTestFailureEpic,
  getTestResultsEpic,
  updateTestResultsEpic,
  updateTestResultsSuccessEpic,
  updateTestResultsFailureEpic,
  createTestResultsEpic,
  deleteTestResultsEpic,
  deleteTestResultsSuccessEpic,
  deleteTestResultsFailureEpic,
  updateQuestionAnswerChoiceSuccessEpic,
  createTestFailureEpic,
  getTestFailureEpic,
  createTestResultsByStudentEpic,
  createTestResultsByStudentFailureEpic,
  replaceQuestionGapEpic,
  replaceQuestionGapSuccessEpic,
} from "./epics/test";
import {
  addBillingCardEpic,
  deleteBillingCardEpic,
  addBillingCardSuccessEpic,
  addBillingCardFailureEpic,
  getBillingNextPaymentEpic,
  updateBillingSettingsEpic,
  updateBillingSettingsSuccessEpic,
  getDiscountCodesEpic,
  updateDiscountCodesEpic,
  updateDiscountCodesSuccessEpic,
  updateInvoiceDataEpic,
  getInvoiceDataEpic,
  validateReferrerAccountIDEpic,
  getReferralsEpic,
} from "./epics/billing";
import {
  getClassReportEpic,
  generateClassReportPDFEpic,
  getStudentReportEpic,
  generateStudentReportPDFEpic,
} from "./epics/report";
import { getAllTestsEpic } from "./epics/tests";
import {
  testsBaseGetTestsEpic,
  testsBaseGetTestsNextPageEpic,
  getTestFromTestsBaseEpic,
  publishTestInTestsBaseEpic,
  publishTestInTestsSuccessEpic,
  publishTestInTestsFailureEpic,
  duplicateTestFromTestsBaseEpic,
  duplicateTestFromTestsBaseSuccessEpic,
  duplicateTestFromTestsBaseFailureEpic,
} from "./epics/testsBase";
import {
  duplicateQuestionsFromQuestionsBaseEpic,
  getQuestionsFromQuestionsBaseEpic,
  getQuestionsFromQuestionsBaseNextPageEpic,
  voteForAQuestionEpic,
  duplicateQuestionFromQuestionsBaseFailureEpic,
  duplicateQuestionFromQuestionsBaseSuccessEpic,
  getUserVotesEpic,
  voteForAQuestionSuccessEpic,
} from "./epics/questionsBase";
import {
  getEducationLevelEpic,
  getCurriculumsEpic,
  createCurriculumEpic,
  assignCurriculumsEpic,
  createCurriculumSuccessEpic,
  getCurriculumsForClassEpic,
  getCurriculumForClassEpic,
  updateClassSkillsEpic,
  updateClassSkillsSuccessEpic,
  deleteCurriculumEpic,
  deleteCurriculumSuccessEpic,
  deleteCurriculumFailureEpic,
  getQuestionSkillsEpic,
  updateQuestionSkillsEpic,
  updateQuestionSkillsSuccessEpic,
  updateQuestionSkillsFailureEpic,
  updateCurriculumForClassEpic,
  updateCurriculumForClassSuccessEpic,
  updateCurriculumForClassFailureEpic,
} from "./epics/curriculum";
import { signUpAsGuestEpic, upgradeGuestAccountEpic } from "./epics/demo";
import {
  createLessonEpic,
  createLessonSuccessEpic,
  getLessonEpic,
  updateLessonEpic,
  updateLessonSuccessEpic,
  deleteLessonEpic,
  deleteLessonSuccessEpic,
  lessonGenerateAccessTokenEpic,
  lessonGenerateAccessTokenSuccessEpic,
  publishLessonEpic,
  unpublishLessonEpic,
  duplicateLessonEpic,
  duplicateLessonSuccessEpic,
  duplicateLessonFailureEpic,
} from "./epics/lesson";
import {
  getLessonsEpic,
  lessonsGenerateAccessTokenEpic,
  lessonsGenerateAccessTokenSuccessEpic,
} from "./epics/lessons";
import { getResourcesEpic } from "./epics/resources";

import { app } from "./reducers/app";
import { auth } from "./reducers/auth";
import { billing } from "./reducers/billing";
import { classReducer } from "./reducers/class";
import { curriculum } from "./reducers/curriculum";
import { lesson } from "./reducers/lesson";
import { lessons } from "./reducers/lessons";
import { questionsBase } from "./reducers/questionsBase";
import { report } from "./reducers/report";
import { resources } from "./reducers/resources";
import { student } from "./reducers/student";
import { teacher } from "./reducers/teacher";
import { test } from "./reducers/test";
import { tests } from "./reducers/tests";
import { testsBase } from "./reducers/testsBase";
import { user } from "./reducers/user";

export const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmEpic,
  signUpConfirmResendLinkEpic,
  signInEpic,
  signInByTokenEpic,
  forgotPasswordEpic,
  resetPasswordEpic,
  getSubjectsEpic,
  updateSubjectsEpic,
  signOutEpic,
  createClassEpic,
  getClassesEpic,
  createClassSuccessEpic,
  getClassEpic,
  createTestEpic,
  updateTestEpic,
  getQuestionTypes,
  createQuestionEpic,
  getTestEpic,
  getQuestionTypesEpic,
  createTestSuccessEpic,
  createQuestionSuccessEpic,
  updateQuestionEpic,
  updateQuestionSuccessEpic,
  deleteQuestionEpic,
  deleteQuestionSuccessEpic,
  createQuestionAnswerChoiceEpic,
  createQuestionAnswerChoiceSuccessEpic,
  updateQuestionAnswerChoiceEpic,
  deleteQuestionAnswerChoiceEpic,
  createQuestionMatchingEpic,
  updateQuestionMatchingEpic,
  deleteQuestionMatchingEpic,
  deleteTestEpic,
  deleteTestSuccessEpic,
  generatePDFEpic,
  validateTestEpic,
  getStudentsEpic,
  deleteStudentEpic,
  deleteStudentSuccessEpic,
  deleteStudentFailureEpic,
  addStudentEpic,
  addStudentSuccessEpic,
  addStudentFailureEpic,
  showToastEpic,
  addBillingCardEpic,
  deleteBillingCardEpic,
  addBillingCardSuccessEpic,
  addBillingCardFailureEpic,
  getBillingNextPaymentEpic,
  getClassReportEpic,
  generateClassReportPDFEpic,
  updateBillingSettingsEpic,
  updateBillingSettingsSuccessEpic,
  getDiscountCodesEpic,
  updateDiscountCodesEpic,
  updateDiscountCodesSuccessEpic,
  updateInvoiceDataEpic,
  getInvoiceDataEpic,
  getStudentReportEpic,
  generateStudentReportPDFEpic,
  getStudentDetailsEpic,
  deleteQuestionImageEpic,
  deleteQuestionImageSuccessEpic,
  validateReferrerAccountIDEpic,
  getReferralsEpic,
  deleteAccountEpic,
  getAllTestsEpic,
  deleteClassEpic,
  deleteClassSuccessEpic,
  updateClassEpic,
  updateClassSuccessEpic,
  testsBaseGetTestsEpic,
  testsBaseGetTestsNextPageEpic,
  duplicateTestEpic,
  duplicateTestSuccessEpic,
  duplicateTestFailureEpic,
  getTestFromTestsBaseEpic,
  publishTestInTestsBaseEpic,
  publishTestInTestsSuccessEpic,
  publishTestInTestsFailureEpic,
  duplicateTestFromTestsBaseEpic,
  duplicateTestFromTestsBaseSuccessEpic,
  duplicateTestFromTestsBaseFailureEpic,
  getTestResultsEpic,
  updateTestResultsEpic,
  updateTestResultsSuccessEpic,
  updateTestResultsFailureEpic,
  createTestResultsEpic,
  deleteTestResultsEpic,
  deleteTestResultsSuccessEpic,
  deleteTestResultsFailureEpic,
  getEducationLevelEpic,
  getCurriculumsEpic,
  createCurriculumEpic,
  assignCurriculumsEpic,
  createCurriculumSuccessEpic,
  updateAccountEpic,
  updateAccountSuccessEpic,
  updateAccountFailureEpic,
  signUpAsGuestEpic,
  upgradeGuestAccountEpic,
  getCurriculumsForClassEpic,
  getCurriculumForClassEpic,
  updateClassSkillsEpic,
  updateClassSkillsSuccessEpic,
  deleteCurriculumEpic,
  deleteCurriculumSuccessEpic,
  deleteCurriculumFailureEpic,
  getQuestionSkillsEpic,
  updateQuestionSkillsEpic,
  updateQuestionSkillsSuccessEpic,
  updateQuestionSkillsFailureEpic,
  updateCurriculumForClassEpic,
  updateCurriculumForClassSuccessEpic,
  updateCurriculumForClassFailureEpic,
  changePasswordEpic,
  changePasswordSuccessEpic,
  updateQuestionAnswerChoiceSuccessEpic,
  createTestFailureEpic,
  createLessonEpic,
  createLessonSuccessEpic,
  getLessonEpic,
  getLessonsEpic,
  updateLessonEpic,
  updateLessonSuccessEpic,
  deleteLessonEpic,
  deleteLessonSuccessEpic,
  lessonGenerateAccessTokenEpic,
  lessonGenerateAccessTokenSuccessEpic,
  getResourcesEpic,
  publishLessonEpic,
  unpublishLessonEpic,
  lessonsGenerateAccessTokenEpic,
  lessonsGenerateAccessTokenSuccessEpic,
  duplicateLessonEpic,
  duplicateLessonSuccessEpic,
  duplicateLessonFailureEpic,
  getTestFailureEpic,
  createTestResultsByStudentEpic,
  createTestResultsByStudentFailureEpic,
  duplicateQuestionsFromQuestionsBaseEpic,
  getQuestionsFromQuestionsBaseEpic,
  getQuestionsFromQuestionsBaseNextPageEpic,
  voteForAQuestionEpic,
  duplicateQuestionFromQuestionsBaseFailureEpic,
  duplicateQuestionFromQuestionsBaseSuccessEpic,
  getUserVotesEpic,
  voteForAQuestionSuccessEpic,
  replaceQuestionGapEpic,
  replaceQuestionGapSuccessEpic,
);

export const rootReducer = combineReducers({
  app,
  auth,
  billing,
  classReducer,
  curriculum,
  lesson,
  lessons,
  questionsBase,
  report,
  resources,
  student,
  teacher,
  test,
  tests,
  testsBase,
  user,
});
