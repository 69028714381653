import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import {
  switchMap,
  pluck,
  debounceTime,
  throttleTime,
  mergeMap,
  map,
} from "rxjs/operators";

import merge from "ramda/src/merge";

import {
  createTestSuccess,
  createTestFailure,
  updateTestSuccess,
  updateTestFailure,
  getQuestionTypesSuccess,
  getQuestionTypesFailure,
  createQuestionSuccess,
  createQuestionFailure,
  getTestSuccess,
  getTestFailure,
  updateQuestionSuccess,
  updateQuestionFailure,
  createQuestionAnswerChoiceSuccess,
  createQuestionAnswerChoiceFailure,
  updateQuestionAnswerChoiceSuccess,
  updateQuestionAnswerChoiceFailure,
  deleteQuestionAnswerChoiceSuccess,
  deleteQuestionAnswerChoiceFailure,
  deleteTestSuccess,
  deleteTestFailure,
  generatePDFSuccess,
  generatePDFFailure,
  validateTestSuccess,
  validateTestFailure,
  deleteQuestionImageSuccess,
  deleteQuestionImageFailure,
  duplicateTestSuccess,
  duplicateTestFailure,
  updateTestResultsSuccess,
  updateTestResultsFailure,
  getTestResultsSuccess,
  getTestResultsFailure,
  createTestResultsSuccess,
  createTestResultsFailure,
  deleteTestResultsSuccess,
  deleteTestResultsFailure,
  createTestResultsByStudentSuccess,
  createTestResultsByStudentFailure,
  deleteQuestionSuccess,
  deleteQuestionFailure,
  createQuestionMatchingSuccess,
  createQuestionMatchingFailure,
  updateQuestionMatchingSuccess,
  updateQuestionMatchingFailure,
  deleteQuestionMatchingSuccess,
  deleteQuestionMatchingFailure,
  replaceQuestionGapSuccess,
  replaceQuestionGapFailure,
} from "../../actions/test";
import { showToast, empty, historyPush } from "../../actions/app";

import {
  apiCall,
  getTokenFromState,
  defaultHeaders,
} from "../../../services/api";
import { getClass } from "../class";
import { getAllTests } from "../tests";

// Types
import {
  createTestPayload,
  createTestAction,
  updateTestAction,
  updateTestPayload,
  getQuestionTypesAction,
  createQuestionAction,
  createQuestionPayload,
  createTestSuccessAction,
  getTestPayload,
  getTestAction,
  getTestFailureAction,
  createQuestionSuccessAction,
  updateQuestionPayload,
  updateQuestionAction,
  updateQuestionSuccessAction,
  deleteQuestionAction,
  deleteQuestionPayload,
  deleteQuestionSuccessAction,
  createQuestionAnswerChoiceAction,
  createQuestionAnswerChoicePayload,
  createQuestionAnswerChoiceSuccessAction,
  updateQuestionAnswerChoiceAction,
  updateQuestionAnswerChoicePayload,
  deleteQuestionAnswerChoiceAction,
  deleteQuestionAnswerChoicePayload,
  deleteTestAction,
  deleteTestPayload,
  deleteTestSuccessAction,
  generatePDFAction,
  generatePDFPayload,
  validateTestAction,
  validateTestPayload,
  deleteQuestionImageAction,
  deleteQuestionImagePayload,
  deleteQuestionImageSuccessAction,
  duplicateTestAction,
  duplicateTestPayload,
  duplicateTestSuccessAction,
  duplicateTestFailureAction,
  updateTestResultsAction,
  updateTestResultsPayload,
  updateTestResultsSuccessAction,
  updateTestResultsFailureAction,
  getTestResultsAction,
  getTestResultsPayload,
  createTestResultsAction,
  createTestResultsPayload,
  deleteTestResultsAction,
  deleteTestResultsPayload,
  deleteTestResultsSuccessAction,
  deleteTestResultsFailureAction,
  updateQuestionAnswerChoiceSuccessAction,
  createTestFailureAction,
  createTestResultsByStudentAction,
  createTestResultsByStudentPayload,
  createTestResultsByStudentFailureAction,
  createQuestionMatchingAction,
  createQuestionMatchingPayload,
  createQuestionMatchingSuccessAction,
  updateQuestionMatchingAction,
  updateQuestionMatchingPayload,
  updateQuestionMatchingSuccessAction,
  deleteQuestionMatchingAction,
  deleteQuestionMatchingPayload,
  getQuestionTypesPayload,
  replaceQuestionGapAction,
  replaceQuestionGapPayload,
  replaceQuestionGapSuccessAction,
} from "../../../models/redux/test";
import { XHRPayload, ErrorCodes, ErrorsPayload } from "../../../models/common";

// Create Test

export const createTestEpic: Epic = (
  action$: ActionsObservable<createTestAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_TEST"),
    pluck("payload"),
    throttleTime(1000),
    switchMap((payload) => createTest(payload, state$)),
  );

export const createTest = (
  payload: createTestPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    "/tests/tests",
    "POST",
    {
      class_id: payload.class_id,
      lang: payload.lang,
      name: payload.name,
      subject_id: payload.subject_id,
      type: payload.type,
      ...merge(
        {},
        payload.type === "online" && payload.online_data
          ? {
            online_data: {
              ...payload.online_data,
              time_limit: Number(payload.online_data.time_limit)
                ? Number(payload.online_data.time_limit)
                : null,
            },
          }
          : {},
      ),
    },
    (data: XHRPayload) =>
      createTestSuccess(data.response.data.id, payload.class_id),
    (error: ErrorsPayload) => createTestFailure(error, payload.history),
    getTokenFromState(state$),
  );

export const createTestSuccessEpic: Epic = (
  action$: ActionsObservable<createTestSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_TEST_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

export const createTestFailureEpic: Epic = (
  action$: ActionsObservable<createTestFailureAction>,
) =>
  action$.pipe(
    ofType("CREATE_TEST_FAILURE"),
    pluck("payload"),
    map((payload) => {
      const hasPlanLimitError = payload.errors.find(
        (error) => error.code === ErrorCodes.freePlanLimitExceeded,
      );
      if (hasPlanLimitError) {
        return historyPush(payload.history, "/plan-limit/test");
      }
      return empty();
    }),
  );

// Update Test

export const updateTestEpic: Epic = (
  action$: ActionsObservable<updateTestAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_TEST"),
    debounceTime(1000),
    switchMap((data) => updateTest(data.payload, state$, data.callback)),
  );

export const updateTest = (
  payload: updateTestPayload,
  state$: StateObservable<void>,
  callback?: () => any,
) =>
  apiCall(
    `/tests/tests/${payload.id}`,
    "PATCH",
    {
      ...merge({}, payload.name ? { name: payload.name } : {}),
      ...merge({}, payload.status ? { status: payload.status } : {}),
      ...merge(
        {},
        payload.online_data !== undefined
          ? { online_data: payload.online_data }
          : {},
      ),
    },
    () =>
      updateTestSuccess(
        {
          testID: payload.id,
        },
        callback,
      ),
    updateTestFailure,
    getTokenFromState(state$),
  );

// Delete Test

export const deleteTestEpic: Epic = (
  action$: ActionsObservable<deleteTestAction>,
) =>
  action$.pipe(ofType("DELETE_TEST"), pluck("payload"), switchMap(deleteTest));

export const deleteTest = (payload: deleteTestPayload) =>
  apiCall(
    `/tests/tests/${payload.id}`,
    "DELETE",
    {},
    () => deleteTestSuccess(payload),
    deleteTestFailure,
  );

export const deleteTestSuccessEpic: Epic = (
  action$: ActionsObservable<deleteTestSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_TEST_SUCCESS"),
    pluck("payload"),
    switchMap((payload: deleteTestPayload) => {
      if (window.location.pathname === "/tests/all") {
        return getAllTests();
      }
      return getClass({ id: payload.classID });
    }),
  );

// Get Question Types

export const getQuestionTypesEpic: Epic = (
  action$: ActionsObservable<getQuestionTypesAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GET_QUESTION_TYPES"),
    pluck("payload"),
    switchMap((payload) => getQuestionTypes(payload, state$)),
  );

export const getQuestionTypes = (
  payload: getQuestionTypesPayload,
  state$: StateObservable<void>,
) => {
  return apiCall(
    `/tests/question_types?type=${payload.type}`,
    "GET",
    {},
    (data: XHRPayload) => getQuestionTypesSuccess(data.response),
    getQuestionTypesFailure,
    getTokenFromState(state$),
  );
};

// Create Question

export const createQuestionEpic: Epic = (
  action$: ActionsObservable<createQuestionAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION"),
    pluck("payload"),
    throttleTime(1000),
    switchMap((payload) => createQuestion(payload, state$)),
  );

export const createQuestion = (
  payload: createQuestionPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/tests/${payload.testID}/questions`,
    "POST",
    {
      body_html: payload.body,
      body: payload.body,
      lang: payload.lang,
      points: payload.points,
      ...(payload.points_per_item
        ? { points_per_item: payload.points_per_item }
        : {}),
      type: payload.type,
    },
    () =>
      createQuestionSuccess({
        classID: payload.classID,
        testID: payload.testID,
      }),
    createQuestionFailure,
    getTokenFromState(state$),
  );

export const createQuestionSuccessEpic: Epic = (
  action$: ActionsObservable<createQuestionSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Update Question

export const updateQuestionEpic: Epic = (
  action$: ActionsObservable<updateQuestionAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION"),
    switchMap((data) => updateQuestion(data.payload, state$, data.callback)),
  );

export const updateQuestion = (
  payload: updateQuestionPayload,
  state$: StateObservable<void>,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/questions/${payload.questionID}`,
    "PATCH",
    !payload.image
      ? {
        ...merge(
          {},
          payload.body_html || payload.body_html === ""
            ? { body_html: payload.body_html }
            : {},
        ),
        ...merge(
          {},
          payload.body || payload.body === "" ? { body: payload.body } : {},
        ),
        ...merge({}, payload.points ? { points: payload.points } : {}),
        ...merge(
          {},
          payload.pointsPerItem
            ? { points_per_item: payload.pointsPerItem }
            : {},
        ),
        ...merge({}, payload.order ? { order: payload.order } : {}),
        ...merge(
          {},
          payload.type_data ? { type_data: payload.type_data } : {},
        ),
        ...merge({}, payload.gaps ? { gaps: payload.gaps } : {}),
      }
      : payload.image,
    () =>
      updateQuestionSuccess(
        {
          classID: payload.classID,
          testID: payload.testID,
          questionID: payload.questionID,
        },
        callback,
      ),
    updateQuestionFailure,
    getTokenFromState(state$),
    defaultHeaders(getTokenFromState(state$), payload.image),
  );
};

export const updateQuestionSuccessEpic: Epic = (
  action$: ActionsObservable<updateQuestionSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Delete Question

export const deleteQuestionEpic: Epic = (
  action$: ActionsObservable<deleteQuestionAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION"),
    pluck("payload"),
    switchMap((payload) => deleteQuestion(payload, state$)),
  );

export const deleteQuestion = (
  payload: deleteQuestionPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/tests/${payload.testID}/questions/${payload.questionID}`,
    "DELETE",
    {},
    () =>
      deleteQuestionSuccess({
        classID: payload.classID,
        testID: payload.testID,
        questionID: payload.questionID,
      }),
    deleteQuestionFailure,
    getTokenFromState(state$),
  );

export const deleteQuestionSuccessEpic: Epic = (
  action$: ActionsObservable<deleteQuestionSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Create Question Answer Choice

export const createQuestionAnswerChoiceEpic: Epic = (
  action$: ActionsObservable<createQuestionAnswerChoiceAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION_ANSWER_CHOICE"),
    pluck("payload"),
    throttleTime(1000),
    switchMap((payload) => createQuestionAnswerChoice(payload, state$)),
  );

export const createQuestionAnswerChoice = (
  payload: createQuestionAnswerChoicePayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/answer_choices`,
    "POST",
    {
      name: payload.name,
    },
    (data: XHRPayload) =>
      createQuestionAnswerChoiceSuccess({
        classID: payload.classID,
        testID: payload.testID,
        answerID: data.response.data.id,
        questionID: payload.questionID,
      }),
    createQuestionAnswerChoiceFailure,
    getTokenFromState(state$),
  );

export const createQuestionAnswerChoiceSuccessEpic: Epic = (
  action$: ActionsObservable<createQuestionAnswerChoiceSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION_ANSWER_CHOICE_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Update Question Answer Choice

export const updateQuestionAnswerChoiceEpic: Epic = (
  action$: ActionsObservable<updateQuestionAnswerChoiceAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_ANSWER_CHOICE"),
    pluck("payload"),
    mergeMap((payload) => updateQuestionAnswerChoice(payload, state$)),
  );

export const updateQuestionAnswerChoice = (
  payload: updateQuestionAnswerChoicePayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/answer_choices/${payload.answerID}`,
    "PATCH",
    {
      name_html: payload.name_html,
      name: payload.name,
      is_correct: payload.isCorrect,
    },
    () =>
      updateQuestionAnswerChoiceSuccess({
        classID: payload.classID,
        testID: payload.testID,
        answerID: payload.answerID,
        questionID: payload.questionID,
        ...merge({}, payload.isCorrect ? { isCorrect: true } : {}),
      }),
    updateQuestionAnswerChoiceFailure,
    getTokenFromState(state$),
  );

export const updateQuestionAnswerChoiceSuccessEpic: Epic = (
  action$: ActionsObservable<updateQuestionAnswerChoiceSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Delete Question Answer Choice

export const deleteQuestionAnswerChoiceEpic: Epic = (
  action$: ActionsObservable<deleteQuestionAnswerChoiceAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION_ANSWER_CHOICE"),
    pluck("payload"),
    mergeMap((payload) => deleteQuestionAnswerChoice(payload, state$)),
  );

export const deleteQuestionAnswerChoice = (
  payload: deleteQuestionAnswerChoicePayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/answer_choices/${payload.answerID}`,
    "DELETE",
    {},
    () =>
      deleteQuestionAnswerChoiceSuccess({
        classID: payload.classID,
        testID: payload.testID,
        questionID: payload.questionID,
        answerID: payload.answerID,
      }),
    deleteQuestionAnswerChoiceFailure,
    getTokenFromState(state$),
  );

// Create Question Matching

export const createQuestionMatchingEpic: Epic = (
  action$: ActionsObservable<createQuestionMatchingAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION_MATCHING"),
    pluck("payload"),
    throttleTime(1000),
    switchMap((payload) => createQuestionMatching(payload, state$)),
  );

export const createQuestionMatching = (
  payload: createQuestionMatchingPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/matchings`,
    "POST",
    {
      item: payload.item,
      item_html: payload.item_html,
      matched_item: payload.matched_item,
      matched_item_html: payload.matched_item_html,
    },
    (data: XHRPayload) =>
      createQuestionMatchingSuccess({
        classID: payload.classID,
        testID: payload.testID,
        matchingID: data.response.data.id,
        questionID: payload.questionID,
      }),
    createQuestionMatchingFailure,
    getTokenFromState(state$),
  );

export const createQuestionMatchingSuccessEpic: Epic = (
  action$: ActionsObservable<createQuestionMatchingSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_QUESTION_MATCHING_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Update Question Matching

export const updateQuestionMatchingEpic: Epic = (
  action$: ActionsObservable<updateQuestionMatchingAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_MATCHING"),
    pluck("payload"),
    mergeMap((payload) => updateQuestionMatching(payload, state$)),
  );

export const updateQuestionMatching = (
  payload: updateQuestionMatchingPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/matchings/${payload.matchingID}`,
    "PATCH",
    {
      item: payload.item,
      item_html: payload.item_html,
      matched_item: payload.matched_item,
      matched_item_html: payload.matched_item_html,
    },
    () =>
      updateQuestionMatchingSuccess({
        classID: payload.classID,
        testID: payload.testID,
        matchingID: payload.matchingID,
        questionID: payload.questionID,
      }),
    updateQuestionMatchingFailure,
    getTokenFromState(state$),
  );

export const updateQuestionMatchingSuccessEpic: Epic = (
  action$: ActionsObservable<updateQuestionMatchingSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_MATCHING_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Delete Question Matching

export const deleteQuestionMatchingEpic: Epic = (
  action$: ActionsObservable<deleteQuestionMatchingAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION_MATCHING"),
    pluck("payload"),
    mergeMap((payload) => deleteQuestionMatching(payload, state$)),
  );

export const deleteQuestionMatching = (
  payload: deleteQuestionMatchingPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/matchings/${payload.matchingID}`,
    "DELETE",
    {},
    () =>
      deleteQuestionMatchingSuccess({
        classID: payload.classID,
        testID: payload.testID,
        questionID: payload.questionID,
        matchingID: payload.matchingID,
      }),
    deleteQuestionMatchingFailure,
    getTokenFromState(state$),
  );

// Replace Question Gap

export const replaceQuestionGapEpic: Epic = (
  action$: ActionsObservable<replaceQuestionGapAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("REPLACE_QUESTION_GAP"),
    pluck("payload"),
    debounceTime(500),
    switchMap((payload) => replaceQuestionGap(payload, state$)),
  );

export const replaceQuestionGap = (
  payload: replaceQuestionGapPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/questions/${payload.questionID}/gaps/replace`,
    "PATCH",
    {
      names: payload.names,
    },
    () =>
      replaceQuestionGapSuccess({
        classID: payload.classID,
        testID: payload.testID,
        questionID: payload.questionID,
      }),
    replaceQuestionGapFailure,
    getTokenFromState(state$),
  );

export const replaceQuestionGapSuccessEpic: Epic = (
  action$: ActionsObservable<replaceQuestionGapSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("REPLACE_QUESTION_GAP_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Get Test

export const getTestEpic: Epic = (
  action$: ActionsObservable<getTestAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GET_TEST"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

export const getTest = (
  payload: getTestPayload,
  state$: StateObservable<void>,
) => {
  const searchQuery = () => {
    if (payload.studentCode && payload.studentNumber) {
      return `?student%5Bcode%5D=${payload.studentCode}&student%5Bnumber%5D=${payload.studentNumber}`;
    }
    return "";
  };
  return apiCall(
    `/tests/classes/${payload.classID}/tests/${payload.testID}${searchQuery()}`,
    "GET",
    {},
    (data: XHRPayload) => getTestSuccess(data.response),
    (error: ErrorsPayload) => getTestFailure(error, !!searchQuery()),
    getTokenFromState(state$),
  );
};

export const getTestFailureEpic: Epic = (
  action$: ActionsObservable<getTestFailureAction>,
) =>
  action$.pipe(
    ofType("GET_TEST_FAILURE"),
    pluck("payload"),
    map((payload) => {
      if (payload.hasStudentCodeAndNumber) {
        return showToast({
          text: "test.wrong-student-code-or-student-number",
          status: "error",
        });
      }
      return empty();
    }),
  );

// Generate PDF

export const generatePDFEpic: Epic = (
  action$: ActionsObservable<generatePDFAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GENERATE_PDF"),
    pluck("payload"),
    switchMap((payload: generatePDFPayload) => generatePDF(payload, state$)),
  );

export const generatePDF = (
  payload: generatePDFPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/tests/${payload.testID}/generate`,
    "POST",
    { html: payload.html },
    (data: XHRPayload) => generatePDFSuccess(data.response),
    generatePDFFailure,
    getTokenFromState(state$),
    defaultHeaders(getTokenFromState(state$)),
  );

// Validate Test

export const validateTestEpic: Epic = (
  action$: ActionsObservable<validateTestAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("VALIDATE_TEST"),
    pluck("payload"),
    switchMap((payload) => validateTest(payload, state$)),
  );

export const validateTest = (
  payload: validateTestPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/tests/tests/${payload.testID}/validate`,
    "GET",
    {},
    () => validateTestSuccess(payload),
    validateTestFailure,
    getTokenFromState(state$),
  );

// Delete Question Image

export const deleteQuestionImageEpic: Epic = (
  action$: ActionsObservable<deleteQuestionImageAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION_IMAGE"),
    pluck("payload"),
    mergeMap((payload) => deleteQuestionImage(payload, state$)),
  );

export const deleteQuestionImage = (
  payload: deleteQuestionImagePayload,
  state$: StateObservable<void>,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/questions/${payload.questionID}/images`,
    "DELETE",
    {},
    () =>
      deleteQuestionImageSuccess({
        classID: payload.classID,
        testID: payload.testID,
        questionID: payload.questionID,
      }),
    deleteQuestionImageFailure,
    getTokenFromState(state$),
  );
};

export const deleteQuestionImageSuccessEpic: Epic = (
  action$: ActionsObservable<deleteQuestionImageSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("DELETE_QUESTION_IMAGE_SUCCESS"),
    pluck("payload"),
    switchMap((payload) => getTest(payload, state$)),
  );

// Duplicate Test

export const duplicateTestEpic: Epic = (
  action$: ActionsObservable<duplicateTestAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST"),
    switchMap((data) => duplicateTest(data.payload, data.callback)),
  );

export const duplicateTest = (
  payload: duplicateTestPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/duplicate`,
    "POST",
    { class_id: payload.classID },
    () => duplicateTestSuccess(callback),
    (error: ErrorsPayload) => duplicateTestFailure(error, payload.history),
  );
};

export const duplicateTestSuccessEpic: Epic = (
  action$: ActionsObservable<duplicateTestSuccessAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST_SUCCESS"),
    map(() =>
      showToast({ text: "test.test-has-been-duplicated", status: "success" }),
    ),
  );

export const duplicateTestFailureEpic: Epic = (
  action$: ActionsObservable<duplicateTestFailureAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST_FAILURE"),
    pluck("payload"),
    map((payload) => {
      const hasPlanLimitError = payload.errors.find(
        (error) => error.code === ErrorCodes.freePlanLimitExceeded,
      );
      if (hasPlanLimitError) {
        return historyPush(payload.history, "/plan-limit/test");
      }
      return showToast({
        text: "test.test-has-not-been-duplicated",
        status: "error",
      });
    }),
  );

// Create Test Results

export const createTestResultsEpic: Epic = (
  action$: ActionsObservable<createTestResultsAction>,
) =>
  action$.pipe(
    ofType("CREATE_TEST_RESULTS"),
    switchMap((data) => createTestResults(data.payload, data.callback)),
  );

export const createTestResults = (
  payload: createTestResultsPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/results/`,
    "POST",
    {
      student_id: payload.studentID,
    },
    () => createTestResultsSuccess(callback),
    createTestResultsFailure,
  );
};

// Create Test Results By Student

export const createTestResultsByStudentEpic: Epic = (
  action$: ActionsObservable<createTestResultsByStudentAction>,
) =>
  action$.pipe(
    ofType("CREATE_TEST_RESULTS_BY_STUDENT"),
    switchMap((data) => createTestResultsByStudent(data.payload, data.callback)),
  );

export const createTestResultsByStudent = (
  payload: createTestResultsByStudentPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/students/tests/${payload.testID}/results`,
    "POST",
    {
      answers: payload.answers,
      student: {
        code: payload.studentCode,
        number: payload.studentNumber,
      },
    },
    () => createTestResultsByStudentSuccess(callback),
    createTestResultsByStudentFailure,
  );
};

export const createTestResultsByStudentFailureEpic: Epic = (
  action$: ActionsObservable<createTestResultsByStudentFailureAction>,
) =>
  action$.pipe(
    ofType("CREATE_TEST_RESULTS_BY_STUDENT_FAILURE"),
    map(() =>
      showToast({ text: "test.answers-has-not-been-sent", status: "error" }),
    ),
  );

// Get Test Results

export const getTestResultsEpic: Epic = (
  action$: ActionsObservable<getTestResultsAction>,
) =>
  action$.pipe(
    ofType("GET_TEST_RESULTS"),
    switchMap((data) => getTestResults(data.payload)),
  );

export const getTestResults = (payload: getTestResultsPayload) => {
  return apiCall(
    `/tests/tests/${payload.testID}/results/${payload.resultID}`,
    "GET",
    {},
    (data: XHRPayload) => getTestResultsSuccess(data.response),
    getTestResultsFailure,
  );
};

// Update Test Results

export const updateTestResultsEpic: Epic = (
  action$: ActionsObservable<updateTestResultsAction>,
) =>
  action$.pipe(
    ofType("UPDATE_TEST_RESULTS"),
    switchMap((data) => updateTestResults(data.payload, data.callback)),
  );

export const updateTestResults = (
  payload: updateTestResultsPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/results/${payload.resultID}`,
    "PATCH",
    { answers: payload.answers },
    () => updateTestResultsSuccess(callback),
    updateTestResultsFailure,
  );
};

export const updateTestResultsSuccessEpic: Epic = (
  action$: ActionsObservable<updateTestResultsSuccessAction>,
) =>
  action$.pipe(
    ofType("UPDATE_TEST_RESULTS_SUCCESS"),
    map(() =>
      showToast({ text: "global.changes-have-been-saved", status: "success" }),
    ),
  );

export const updateTestResultsFailureEpic: Epic = (
  action$: ActionsObservable<updateTestResultsFailureAction>,
) =>
  action$.pipe(
    ofType("UPDATE_TEST_RESULTS_FAILURE"),
    map(() =>
      showToast({ text: "global.changes-have-not-been-saved", status: "error" }),
    ),
  );

// Delete Test Result

export const deleteTestResultsEpic: Epic = (
  action$: ActionsObservable<deleteTestResultsAction>,
) =>
  action$.pipe(
    ofType("DELETE_TEST_RESULTS"),
    switchMap((data) => deleteTestResults(data.payload, data.callback)),
  );

export const deleteTestResults = (
  payload: deleteTestResultsPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/tests/tests/${payload.testID}/results/${payload.resultID}`,
    "DELETE",
    {},
    () => deleteTestResultsSuccess(callback),
    deleteTestResultsFailure,
  );
};

export const deleteTestResultsSuccessEpic: Epic = (
  action$: ActionsObservable<deleteTestResultsSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_TEST_RESULTS_SUCCESS"),
    map(() =>
      showToast({ text: "global.changes-have-been-saved", status: "success" }),
    ),
  );

export const deleteTestResultsFailureEpic: Epic = (
  action$: ActionsObservable<deleteTestResultsFailureAction>,
) =>
  action$.pipe(
    ofType("DELETE_TEST_RESULTS_FAILURE"),
    map(() =>
      showToast({ text: "global.changes-have-not-been-saved", status: "error" }),
    ),
  );
