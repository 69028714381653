import { History } from "history";
import propOr from "ramda/src/propOr";

// Types
import {
  createTestPayload,
  createTestAction,
  createTestSuccessAction,
  createTestFailureAction,
  unmountTestAction,
  updateTestAction,
  updateTestPayload,
  updateTestSuccessAction,
  updateTestFailureAction,
  getQuestionTypesAction,
  getQuestionTypesSuccessAction,
  getQuestionTypesFailureAction,
  getQuestionTypesSuccessPayload,
  createQuestionAction,
  createQuestionSuccessAction,
  createQuestionFailureAction,
  createQuestionPayload,
  getTestPayload,
  getTestSuccessPayload,
  getTestAction,
  getTestSuccessAction,
  getTestFailureAction,
  createQuestionSuccessPayload,
  updateQuestionAction,
  updateQuestionSuccessAction,
  updateQuestionFailureAction,
  updateQuestionPayload,
  updateQuestionSuccessPayload,
  deleteQuestionPayload,
  deleteQuestionAction,
  deleteQuestionSuccessAction,
  deleteQuestionFailureAction,
  deleteQuestionSuccessPayload,
  createQuestionAnswerChoicePayload,
  createQuestionAnswerChoiceAction,
  createQuestionAnswerChoiceSuccessPayload,
  createQuestionAnswerChoiceSuccessAction,
  createQuestionAnswerChoiceFailureAction,
  updateQuestionAnswerChoicePayload,
  updateQuestionAnswerChoiceAction,
  updateQuestionAnswerChoiceSuccessPayload,
  updateQuestionAnswerChoiceSuccessAction,
  updateQuestionAnswerChoiceFailureAction,
  deleteQuestionAnswerChoicePayload,
  deleteQuestionAnswerChoiceAction,
  deleteQuestionAnswerChoiceSuccessPayload,
  deleteQuestionAnswerChoiceSuccessAction,
  deleteQuestionAnswerChoiceFailureAction,
  deleteTestPayload,
  deleteTestAction,
  deleteTestSuccessAction,
  deleteTestFailureAction,
  generatePDFPayload,
  generatePDFSuccessPayload,
  generatePDFAction,
  generatePDFSuccessAction,
  generatePDFFailureAction,
  validateTestAction,
  validateTestPayload,
  validateTestSuccessAction,
  validateTestSuccessPayload,
  validateTestFailureAction,
  updateTestSuccessPayload,
  deleteQuestionImagePayload,
  deleteQuestionImageAction,
  deleteQuestionImageSuccessPayload,
  deleteQuestionImageSuccessAction,
  deleteQuestionImageFailureAction,
  duplicateTestPayload,
  duplicateTestAction,
  duplicateTestSuccessAction,
  duplicateTestFailureAction,
  updateTestResultsPayload,
  updateTestResultsAction,
  updateTestResultsSuccessAction,
  updateTestResultsFailureAction,
  getTestResultsPayload,
  getTestResultsAction,
  getTestResultsSuccessAction,
  getTestResultsFailureAction,
  getTestResultsSuccessPayload,
  createTestResultsPayload,
  createTestResultsAction,
  createTestResultsSuccessAction,
  createTestResultsFailureAction,
  deleteTestResultsPayload,
  deleteTestResultsAction,
  deleteTestResultsSuccessAction,
  deleteTestResultsFailureAction,
  createTestResultsByStudentPayload,
  createTestResultsByStudentAction,
  createTestResultsByStudentSuccessAction,
  createTestResultsByStudentFailureAction,
  createQuestionMatchingPayload,
  createQuestionMatchingAction,
  createQuestionMatchingSuccessPayload,
  createQuestionMatchingSuccessAction,
  createQuestionMatchingFailureAction,
  updateQuestionMatchingPayload,
  updateQuestionMatchingAction,
  updateQuestionMatchingSuccessPayload,
  updateQuestionMatchingSuccessAction,
  updateQuestionMatchingFailureAction,
  deleteQuestionMatchingPayload,
  deleteQuestionMatchingAction,
  deleteQuestionMatchingSuccessPayload,
  deleteQuestionMatchingSuccessAction,
  deleteQuestionMatchingFailureAction,
  getQuestionTypesPayload,
  replaceQuestionGapPayload,
  replaceQuestionGapAction,
  replaceQuestionGapSuccessAction,
  replaceQuestionGapFailureAction,
  replaceQuestionGapSuccessPayload,
} from "../../../models/redux/test";
import { ErrorsPayload } from "../../../models/common";

// Create Test

export const createTest = (payload: createTestPayload): createTestAction => {
  return {
    type: "CREATE_TEST",
    payload,
  };
};

export const createTestSuccess = (testID: string, classID: string): createTestSuccessAction => {
  return {
    type: "CREATE_TEST_SUCCESS",
    payload: {
      testID,
      classID,
    },
  };
};

export const createTestFailure = (payload: ErrorsPayload, history: History): createTestFailureAction => {
  return {
    type: "CREATE_TEST_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
      history,
    },
  };
};

// Update Test

export const updateTest = (payload: updateTestPayload, callback?: () => any): updateTestAction => {
  return {
    type: "UPDATE_TEST",
    payload,
    callback,
  };
};

export const updateTestSuccess = (payload: updateTestSuccessPayload, callback?: () => any): updateTestSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "UPDATE_TEST_SUCCESS",
    payload,
  };
};

export const updateTestFailure = (payload: ErrorsPayload): updateTestFailureAction => {
  return {
    type: "UPDATE_TEST_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Delete Test

export const deleteTest = (payload: deleteTestPayload): deleteTestAction => {
  return {
    type: "DELETE_TEST",
    payload,
  };
};

export const deleteTestSuccess = (payload: deleteTestPayload): deleteTestSuccessAction => {
  return {
    type: "DELETE_TEST_SUCCESS",
    payload,
  };
};

export const deleteTestFailure = (payload: ErrorsPayload): deleteTestFailureAction => {
  return {
    type: "DELETE_TEST_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Get Question Types

export const getQuestionTypes = (payload: getQuestionTypesPayload): getQuestionTypesAction => {
  return {
    type: "GET_QUESTION_TYPES",
    payload,
  };
};

export const getQuestionTypesSuccess = (payload: getQuestionTypesSuccessPayload): getQuestionTypesSuccessAction => {
  return {
    type: "GET_QUESTION_TYPES_SUCCESS",
    payload,
  };
};

export const getQuestionTypesFailure = (payload: ErrorsPayload): getQuestionTypesFailureAction => {
  return {
    type: "GET_QUESTION_TYPES_FAILURE",
    payload,
  };
};

// Create Question

export const createQuestion = (payload: createQuestionPayload): createQuestionAction => {
  return {
    type: "CREATE_QUESTION",
    payload,
  };
};

export const createQuestionSuccess = (payload: createQuestionSuccessPayload): createQuestionSuccessAction => {
  return {
    type: "CREATE_QUESTION_SUCCESS",
    payload,
  };
};

export const createQuestionFailure = (payload: ErrorsPayload): createQuestionFailureAction => {
  return {
    type: "CREATE_QUESTION_FAILURE",
    payload,
  };
};

// Update Question

export const updateQuestion = (payload: updateQuestionPayload, callback?: () => any): updateQuestionAction => {
  return {
    type: "UPDATE_QUESTION",
    payload,
    callback,
  };
};

export const updateQuestionSuccess = (payload: updateQuestionSuccessPayload, callback?: () => any): updateQuestionSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "UPDATE_QUESTION_SUCCESS",
    payload,
  };
};

export const updateQuestionFailure = (payload: ErrorsPayload): updateQuestionFailureAction => {
  return {
    type: "UPDATE_QUESTION_FAILURE",
    payload,
  };
};

// Delete Question

export const deleteQuestion = (payload: deleteQuestionPayload): deleteQuestionAction => {
  return {
    type: "DELETE_QUESTION",
    payload,
  };
};

export const deleteQuestionSuccess = (payload: deleteQuestionSuccessPayload): deleteQuestionSuccessAction => {
  return {
    type: "DELETE_QUESTION_SUCCESS",
    payload,
  };
};

export const deleteQuestionFailure = (payload: ErrorsPayload): deleteQuestionFailureAction => {
  return {
    type: "DELETE_QUESTION_FAILURE",
    payload,
  };
};

// Get Test

export const getTest = (payload: getTestPayload): getTestAction => {
  return {
    type: "GET_TEST",
    payload,
  };
};

export const getTestSuccess = (payload: getTestSuccessPayload): getTestSuccessAction => {
  return {
    type: "GET_TEST_SUCCESS",
    payload,
  };
};

export const getTestFailure = (payload: ErrorsPayload, hasStudentCodeAndNumber: boolean): getTestFailureAction => {
  return {
    type: "GET_TEST_FAILURE",
    payload: {
      errors: payload.errors,
      hasStudentCodeAndNumber: hasStudentCodeAndNumber,
    },
  };
};

// Create Question Answer Choice

export const createQuestionAnswerChoice = (payload: createQuestionAnswerChoicePayload): createQuestionAnswerChoiceAction => {
  return {
    type: "CREATE_QUESTION_ANSWER_CHOICE",
    payload,
  };
};

export const createQuestionAnswerChoiceSuccess = (payload: createQuestionAnswerChoiceSuccessPayload): createQuestionAnswerChoiceSuccessAction => {
  return {
    type: "CREATE_QUESTION_ANSWER_CHOICE_SUCCESS",
    payload,
  };
};

export const createQuestionAnswerChoiceFailure = (payload: ErrorsPayload): createQuestionAnswerChoiceFailureAction => {
  return {
    type: "CREATE_QUESTION_ANSWER_CHOICE_FAILURE",
    payload,
  };
};

// Update Question Answer Choice

export const updateQuestionAnswerChoice = (payload: updateQuestionAnswerChoicePayload): updateQuestionAnswerChoiceAction => {
  return {
    type: "UPDATE_QUESTION_ANSWER_CHOICE",
    payload,
  };
};

export const updateQuestionAnswerChoiceSuccess = (payload: updateQuestionAnswerChoiceSuccessPayload): updateQuestionAnswerChoiceSuccessAction => {
  return {
    type: "UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS",
    payload,
  };
};

export const updateQuestionAnswerChoiceFailure = (payload: ErrorsPayload): updateQuestionAnswerChoiceFailureAction => {
  return {
    type: "UPDATE_QUESTION_ANSWER_CHOICE_FAILURE",
    payload,
  };
};

// Delete Question Answer Choice

export const deleteQuestionAnswerChoice = (payload: deleteQuestionAnswerChoicePayload): deleteQuestionAnswerChoiceAction => {
  return {
    type: "DELETE_QUESTION_ANSWER_CHOICE",
    payload,
  };
};

export const deleteQuestionAnswerChoiceSuccess = (payload: deleteQuestionAnswerChoiceSuccessPayload): deleteQuestionAnswerChoiceSuccessAction => {
  return {
    type: "DELETE_QUESTION_ANSWER_CHOICE_SUCCESS",
    payload,
  };
};

export const deleteQuestionAnswerChoiceFailure = (payload: ErrorsPayload): deleteQuestionAnswerChoiceFailureAction => {
  return {
    type: "DELETE_QUESTION_ANSWER_CHOICE_FAILURE",
    payload,
  };
};

// Create Question Matching

export const createQuestionMatching = (payload: createQuestionMatchingPayload): createQuestionMatchingAction => {
  return {
    type: "CREATE_QUESTION_MATCHING",
    payload,
  };
};

export const createQuestionMatchingSuccess = (payload: createQuestionMatchingSuccessPayload): createQuestionMatchingSuccessAction => {
  return {
    type: "CREATE_QUESTION_MATCHING_SUCCESS",
    payload,
  };
};

export const createQuestionMatchingFailure = (payload: ErrorsPayload): createQuestionMatchingFailureAction => {
  return {
    type: "CREATE_QUESTION_MATCHING_FAILURE",
    payload,
  };
};

// Update Question Matching

export const updateQuestionMatching = (payload: updateQuestionMatchingPayload): updateQuestionMatchingAction => {
  return {
    type: "UPDATE_QUESTION_MATCHING",
    payload,
  };
};

export const updateQuestionMatchingSuccess = (payload: updateQuestionMatchingSuccessPayload): updateQuestionMatchingSuccessAction => {
  return {
    type: "UPDATE_QUESTION_MATCHING_SUCCESS",
    payload,
  };
};

export const updateQuestionMatchingFailure = (payload: ErrorsPayload): updateQuestionMatchingFailureAction => {
  return {
    type: "UPDATE_QUESTION_MATCHING_FAILURE",
    payload,
  };
};

// Delete Question Matching

export const deleteQuestionMatching = (payload: deleteQuestionMatchingPayload): deleteQuestionMatchingAction => {
  return {
    type: "DELETE_QUESTION_MATCHING",
    payload,
  };
};

export const deleteQuestionMatchingSuccess = (payload: deleteQuestionMatchingSuccessPayload): deleteQuestionMatchingSuccessAction => {
  return {
    type: "DELETE_QUESTION_MATCHING_SUCCESS",
    payload,
  };
};

export const deleteQuestionMatchingFailure = (payload: ErrorsPayload): deleteQuestionMatchingFailureAction => {
  return {
    type: "DELETE_QUESTION_MATCHING_FAILURE",
    payload,
  };
};

// Replace Question Gap

export const replaceQuestionGap = (payload: replaceQuestionGapPayload): replaceQuestionGapAction => {
  return {
    type: "REPLACE_QUESTION_GAP",
    payload,
  };
};

export const replaceQuestionGapSuccess = (payload: replaceQuestionGapSuccessPayload): replaceQuestionGapSuccessAction => {
  return {
    type: "REPLACE_QUESTION_GAP_SUCCESS",
    payload,
  };
};

export const replaceQuestionGapFailure = (payload: ErrorsPayload): replaceQuestionGapFailureAction => {
  return {
    type: "REPLACE_QUESTION_GAP_FAILURE",
    payload,
  };
};

// Generate PDF

export const generatePDF = (payload: generatePDFPayload): generatePDFAction => {
  return {
    type: "GENERATE_PDF",
    payload,
  };
};

export const generatePDFSuccess = (payload: generatePDFSuccessPayload): generatePDFSuccessAction => {
  window.open(payload.data.url, "_parent", "download");

  return {
    type: "GENERATE_PDF_SUCCESS",
    payload,
  };
};

export const generatePDFFailure = (payload: ErrorsPayload): generatePDFFailureAction => {
  return {
    type: "GENERATE_PDF_FAILURE",
    payload,
  };
};

// Validate Test

export const validateTest = (payload: validateTestPayload): validateTestAction => {
  return {
    type: "VALIDATE_TEST",
    payload,
  };
};

export const validateTestSuccess = (payload: validateTestSuccessPayload): validateTestSuccessAction => {
  payload.callback();
  return {
    type: "VALIDATE_TEST_SUCCESS",
    payload,
  };
};

export const validateTestFailure = (payload: ErrorsPayload): validateTestFailureAction => {
  return {
    type: "VALIDATE_TEST_FAILURE",
    payload,
  };
};

// Delete Question Image

export const deleteQuestionImage = (payload: deleteQuestionImagePayload): deleteQuestionImageAction => {
  return {
    type: "DELETE_QUESTION_IMAGE",
    payload,
  };
};

export const deleteQuestionImageSuccess = (payload: deleteQuestionImageSuccessPayload): deleteQuestionImageSuccessAction => {
  return {
    type: "DELETE_QUESTION_IMAGE_SUCCESS",
    payload,
  };
};

export const deleteQuestionImageFailure = (payload: ErrorsPayload): deleteQuestionImageFailureAction => {
  return {
    type: "DELETE_QUESTION_IMAGE_FAILURE",
    payload,
  };
};

// Duplicate Test

export const duplicateTest = (payload: duplicateTestPayload, callback?: () => any): duplicateTestAction => {
  return {
    type: "DUPLICATE_TEST",
    payload,
    callback,
  };
};

export const duplicateTestSuccess = (callback?: () => any): duplicateTestSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "DUPLICATE_TEST_SUCCESS",
  };
};

export const duplicateTestFailure = (payload: ErrorsPayload, history: History): duplicateTestFailureAction => {
  return {
    type: "DUPLICATE_TEST_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
      history,
    },
  };
};

// Create Test Results

export const createTestResults = (payload: createTestResultsPayload, callback?: () => any): createTestResultsAction => {
  return {
    type: "CREATE_TEST_RESULTS",
    payload,
    callback,
  };
};

export const createTestResultsSuccess = (callback?: () => any): createTestResultsSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "CREATE_TEST_RESULTS_SUCCESS",
  };
};

export const createTestResultsFailure = (payload: ErrorsPayload): createTestResultsFailureAction => {
  return {
    type: "CREATE_TEST_RESULTS_FAILURE",
    payload,
  };
};

// Create Test Results By Student

export const createTestResultsByStudent = (payload: createTestResultsByStudentPayload, callback?: () => any): createTestResultsByStudentAction => {
  return {
    type: "CREATE_TEST_RESULTS_BY_STUDENT",
    payload,
    callback,
  };
};

export const createTestResultsByStudentSuccess = (callback?: () => any): createTestResultsByStudentSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS",
  };
};

export const createTestResultsByStudentFailure = (payload: ErrorsPayload): createTestResultsByStudentFailureAction => {
  return {
    type: "CREATE_TEST_RESULTS_BY_STUDENT_FAILURE",
    payload,
  };
};

// Get Test Results

export const getTestResults = (payload: getTestResultsPayload): getTestResultsAction => {
  return {
    type: "GET_TEST_RESULTS",
    payload,
  };
};

export const getTestResultsSuccess = (payload: getTestResultsSuccessPayload): getTestResultsSuccessAction => {
  return {
    type: "GET_TEST_RESULTS_SUCCESS",
    payload,
  };
};

export const getTestResultsFailure = (payload: ErrorsPayload): getTestResultsFailureAction => {
  return {
    type: "GET_TEST_RESULTS_FAILURE",
    payload,
  };
};

// Update Test Results

export const updateTestResults = (payload: updateTestResultsPayload, callback?: () => any): updateTestResultsAction => {
  return {
    type: "UPDATE_TEST_RESULTS",
    payload,
    callback,
  };
};

export const updateTestResultsSuccess = (callback?: () => any): updateTestResultsSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "UPDATE_TEST_RESULTS_SUCCESS",
  };
};

export const updateTestResultsFailure = (payload: ErrorsPayload): updateTestResultsFailureAction => {
  return {
    type: "UPDATE_TEST_RESULTS_FAILURE",
    payload,
  };
};

// Delete Test Result

export const deleteTestResults = (payload: deleteTestResultsPayload, callback?: () => any): deleteTestResultsAction => {
  return {
    type: "DELETE_TEST_RESULTS",
    payload,
    callback,
  };
};

export const deleteTestResultsSuccess = (callback?: () => any): deleteTestResultsSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "DELETE_TEST_RESULTS_SUCCESS",
  };
};

export const deleteTestResultsFailure = (payload: ErrorsPayload): deleteTestResultsFailureAction => {
  return {
    type: "DELETE_TEST_RESULTS_FAILURE",
    payload,
  };
};

// Other

export const unmountTest = (): unmountTestAction => {
  return {
    type: "UNMOUNT_TEST",
  };
};
