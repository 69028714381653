import pathOr from "ramda/src/pathOr";

// Types
import { authStateModel, authActionModel } from "../../../models/redux/auth";

const initialState: authStateModel = {
  token: "",
  errors: [],
  isAuthenticated: null,
  in_progress: {
    general: false,
    update_account: false,
  },
};

export const auth = (state = initialState, action: authActionModel) => {
  switch (action.type) {
    case "SIGN_UP":
    case "SIGN_IN":
    case "UPGRADE_GUEST_ACCOUNT":
      return {
        ...state,
        errors: [],
        isAuthenticated: null,
        in_progress: {
          ...state.in_progress,
          general: true,
        },
      };
    case "SIGN_UP_CONFIRM":
    case "SIGN_IN_BY_TOKEN":
    case "FORGOT_PASSWORD":
    case "RESET_PASSWORD":
      return {
        ...state,
        errors: [],
        in_progress: {
          ...state.in_progress,
          general: true,
        },
      };
    case "SIGN_UP_SUCCESS":
    case "SIGN_IN_SUCCESS":
    case "SIGN_IN_BY_TOKEN_SUCCESS":
    case "SIGN_UP_AS_GUEST_SUCCESS":
      return {
        ...state,
        token: pathOr("", ["payload", "token"], action),
        errors: [],
        isAuthenticated: true,
        in_progress: {
          ...state.in_progress,
          general: false,
        },
      };
    case "SIGN_UP_FAILURE":
    case "SIGN_IN_FAILURE":
    case "SIGN_IN_BY_TOKEN_FAILURE":
    case "UPGRADE_GUEST_ACCOUNT_FAILURE":
      return {
        ...state,
        errors: pathOr([], ["payload", "errors"], action),
        isAuthenticated: false,
        in_progress: {
          ...state.in_progress,
          general: false,
        },
      };
    case "SIGN_UP_CONFIRM_FAILURE":
    case "SIGN_UP_CONFIRM_RESEND_LINK_FAILURE":
    case "FORGOT_PASSWORD_FAILURE":
    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        errors: pathOr([], ["payload", "errors"], action),
        in_progress: {
          ...state.in_progress,
          general: false,
        },
      };
    case "SIGN_UP_CONFIRM_SUCCESS":
    case "SIGN_UP_CONFIRM_RESEND_LINK_SUCCESS":
    case "FORGOT_PASSWORD_SUCCESS":
    case "RESET_PASSWORD_SUCCESS":
    case "UNMOUNT_AUTH":
      return {
        ...state,
        errors: [],
        in_progress: {
          ...state.in_progress,
          general: false,
        },
      };
    case "SIGN_OUT":
      return {
        ...initialState,
      };
    case "SIGN_UP_CONFIRM_RESEND_LINK":
      return {
        ...state,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_account: true,
        },
      };
    case "UPDATE_ACCOUNT_SUCCESS":
    case "UPDATE_ACCOUNT_FAILURE":
    case "CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_account: false,
        },
      };
    case "CHANGE_PASSWORD":
      return {
        ...state,
        errors: [],
        in_progress: {
          ...state.in_progress,
          update_account: true,
        },
      };
    case "CHANGE_PASSWORD_FAILURE":
      return {
        ...state,
        errors: pathOr([], ["payload", "errors"], action),
        in_progress: {
          ...state.in_progress,
          update_account: false,
        },
      };
    default:
      return state;
  }
};
