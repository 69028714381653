import {
  getQuestionsFromQuestionsBaseAction,
  getQuestionsFromQuestionsBasePayload,
  getQuestionsFromQuestionsBaseSuccessAction,
  getQuestionsFromQuestionsBaseFailureAction,
  getQuestionsFromQuestionsBaseSuccessPayload,
  duplicateQuestionFromQuestionsBaseSuccessPayload,
  unmountQuestionsBaseAction,
  getQuestionsFromQuestionsBaseNextPageAction,
  getQuestionsFromQuestionsBaseNextPageSuccessAction,
  getQuestionsFromQuestionsBaseNextPageFailureAction,
  voteForAQuestionPayload,
  voteForAQuestionAction,
  voteForAQuestionSuccessAction,
  getUserVotesAction,
  getUserVotesSuccessPayload,
  getUserVotesSuccessAction,
  duplicateQuestionsFromQuestionsBasePayload,
  duplicateQuestionFromQuestionsBaseFailureAction,
  duplicateQuestionFromQuestionsBaseSuccessAction,
  duplicateQuestionFromQuestionsBasePayload,
} from "../../../models/redux/questionsBase";
import { ErrorsPayload } from "../../../models/common";

// Get Questions From Questions Base

export const getQuestionsFromQuestionsBase = (payload: getQuestionsFromQuestionsBasePayload): getQuestionsFromQuestionsBaseAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE",
    payload,
  };
};

export const getQuestionsFromQuestionsBaseSuccess = (payload: getQuestionsFromQuestionsBaseSuccessPayload): getQuestionsFromQuestionsBaseSuccessAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE_SUCCESS",
    payload,
  };
};

export const getQuestionsFromQuestionsBaseFailure = (payload: ErrorsPayload): getQuestionsFromQuestionsBaseFailureAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE_FAILURE",
    payload,
  };
};

// Get Questions From Questions Base Next Page

export const getQuestionsFromQuestionsBaseNextPage = (payload: getQuestionsFromQuestionsBasePayload): getQuestionsFromQuestionsBaseNextPageAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE_NEXT_PAGE",
    payload,
  };
};

export const getQuestionsFromQuestionsBaseNextPageSuccess = (payload: getQuestionsFromQuestionsBaseSuccessPayload): getQuestionsFromQuestionsBaseNextPageSuccessAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE_NEXT_PAGE_SUCCESS",
    payload,
  };
};

export const getQuestionsFromQuestionsBaseNextPageFailure = (payload: ErrorsPayload): getQuestionsFromQuestionsBaseNextPageFailureAction => {
  return {
    type: "GET_QUESTIONS_FROM_QUESTIONS_BASE_NEXT_PAGE_FAILURE",
    payload,
  };
};

// Duplicate Questions From Questions Base

export const duplicateQuestionsFromQuestionsBase = (payload: duplicateQuestionsFromQuestionsBasePayload) => {
  return {
    type: "DUPLICATE_QUESTIONS_FROM_QUESTIONS_BASE",
    payload,
  };
};

// Duplicate Question From Questions Base

export const duplicateQuestionFromQuestionsBaseSuccess =
  (payload: duplicateQuestionFromQuestionsBaseSuccessPayload, redirectPayload: duplicateQuestionFromQuestionsBasePayload): duplicateQuestionFromQuestionsBaseSuccessAction => {
    return {
      type: "DUPLICATE_QUESTION_FROM_QUESTIONS_BASE_SUCCESS",
      payload,
      redirectPayload,
    };
  };

export const duplicateQuestionFromQuestionsBaseFailure =
  (payload: ErrorsPayload, redirectPayload: duplicateQuestionFromQuestionsBasePayload): duplicateQuestionFromQuestionsBaseFailureAction => {
    return {
      type: "DUPLICATE_QUESTION_FROM_QUESTIONS_BASE_FAILURE",
      payload,
      redirectPayload,
    };
  };

// Vote For A Question

export const voteForAQuestion = (payload: voteForAQuestionPayload): voteForAQuestionAction => {
  return {
    type: "VOTE_FOR_A_QUESTION",
    payload,
  };
};

export const voteForAQuestionSuccess = (payload: voteForAQuestionPayload): voteForAQuestionSuccessAction => {
  return {
    type: "VOTE_FOR_A_QUESTION_SUCCESS",
    payload,
  };
};

export const voteForAQuestionFailure = (payload: ErrorsPayload) => {
  return {
    type: "VOTE_FOR_A_QUESTION_FAILURE",
    payload,
  };
};

// Get User Votes

export const getUserVotes = (): getUserVotesAction => {
  return {
    type: "GET_USER_VOTES",
  };
};

export const getUserVotesSuccess = (payload: getUserVotesSuccessPayload): getUserVotesSuccessAction => {
  return {
    type: "GET_USER_VOTES_SUCCESS",
    payload,
  };
};

export const getUserVotesFailure = (payload: ErrorsPayload) => {
  return {
    type: "GET_USER_VOTES_FAILURE",
    payload,
  };
};

// Other

export const unmountQuestionsBase = (): unmountQuestionsBaseAction => {
  return {
    type: "UNMOUNT_QUESTIONS_BASE",
  };
};
