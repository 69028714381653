import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import pathOr from "ramda/src/pathOr";

import { ReactComponent as BoxBackground } from "../../../assets/images/dashboard_bg.svg";
import { ReactComponent as Avatar } from "../../../assets/images/avatar.svg";

import translate from "../../../services/translate";

import PaymentBox from "./PaymentBox";
import MyClassesBox from "./MyClassesBox";
import ReferralBox from "./ReferralBox";
import MobileAppBox from "./MobileAppBox";

// Types
import { History } from "history";
import { userStateModel } from "../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
  history: History;
}

export const Dashboard = (props: Props) => {
  return (
    <section className={styles.container}>
      <h1>
        {translate("global.discover")}{" "}
        <span>{translate("global.teacher-panel")}</span>
      </h1>
      <p>{translate("global.dashboard-desc")}</p>
      <div className={styles.topContainer}>
        <section className={styles.boxesLeft}>
          <div className={styles.userBox}>
            <BoxBackground/>
            <div className={styles.userBoxContent}>
              <Avatar/>
              <div className={styles.info}>
                <span>{pathOr("", ["user", "email"], props)}</span>
                <Link to="/profile/edit">
                  {translate("global.edit")} {translate("global.profile")}
                </Link>
              </div>
            </div>
          </div>
          <PaymentBox user={props.user}/>
          <MyClassesBox/>
          {/* <MySubjectsBox/> */}
        </section>
        <section className={styles.boxesMiddle}>
          <ReferralBox/>
        </section>
        <section className={styles.boxesRight}>
          <MobileAppBox/>
        </section>
      </div>
    </section>
  );
};

const mapStateToProps = (state: { user: userStateModel }) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Dashboard);
