import React from "react";
import DOMPurify from "dompurify";
import pathOr from "ramda/src/pathOr";

// Types
import {
  questionModel,
  answerChoiceModel,
} from "../../../../../../../../models/redux/test";


interface Props {
  question: questionModel;
}

export const TestQuestionTrueFalseCompactPDF = (props: Props) => {
  return (
    <div
      style={{
        width: "100%",
        pageBreakInside: "avoid",
        margin: "0",
        padding: "0 40px 10px 55px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "0",
          justifyContent: "space-between",
          margin: "0",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            flex: 1,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#000",
            border: "1px solid black",
            padding: "15px 10px",
            margin: "0 20px 0 0",
            textOverflow: "ellipsis",
            width: "100%",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
          }}
          className="questionBodyGlobal"
          dangerouslySetInnerHTML={{
            __html: `${props.question.body_html ?
              DOMPurify.sanitize(props.question.body_html) :
              DOMPurify.sanitize(pathOr("", ["question", "body"], props))}`,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            minWidth: "40px",
            minHeight: "40px",
          }}
        >
          {props.question.answer_choices.map((item: answerChoiceModel) => (
            <span
              key={item.id}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #000",
                fontSize: "24px",
                color: "#000",
                minWidth: "40px",
                minHeight: "40px",
                margin: "0 0 0 -1px",
              }}
            >
              {item.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestQuestionTrueFalseCompactPDF;
