import React from "react";
import AuthContainer from "../Common/Auth/Container";
import translate from "../../services/translate";
import { getLandingPageURL } from "../../services/common";

import styles from "./styles.module.scss";

export function EmailHasBeenSent() {
  return (
    <AuthContainer heading={translate("global.you-did-it")} success>
      <div className={styles.container}>
        <p className={styles.info}>{translate("global.email-has-been-sent")}</p>
        <div className={styles.linkContainer}>
          <a href={getLandingPageURL()}>
            {translate("global.back-to-homepage")}
          </a>
        </div>
      </div>
    </AuthContainer>
  );
}

export default EmailHasBeenSent;
