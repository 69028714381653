import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { unmountAuth } from "../../../redux/actions/auth";
import { upgradeGuestAccount } from "../../../redux/actions/demo";

import AuthContainer from "../../Common/Auth/Container";
import Input from "../../Common/Input";
import Checkbox from "../../Common/Checkbox";
import { getErrorMessage, getLandingPageURL } from "../../../services/common";
import translate from "../../../services/translate";

import {
  validateReferrerAccountID,
  resetBillingErrors,
} from "../../../redux/actions/billing";

// Types
import { History, Location } from "history";
import { authStateModel } from "../../../models/redux/auth";
import {
  billingStateModel,
  validateReferrerAccountID as validateReferrerAccountIDFunction,
  resetBillingErrors as resetBillingErrorsFunction,
} from "../../../models/redux/billing";
import { upgradeGuestAccount as upgradeGuestAccountFunction } from "../../../models/redux/demo";

import styles from "../../SignUp/styles.module.scss";

interface Props {
  location: Location;
  history: History;
  errors: any[];
  isLoading: boolean;
  upgradeGuestAccount: upgradeGuestAccountFunction;
  unmountAuth: () => {};
  validateReferrerAccountID: validateReferrerAccountIDFunction;
  resetBillingErrors: resetBillingErrorsFunction;
  billing: billingStateModel;
  token: string;
}

export const DemoSignUp = (props: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [policyAccepted, setPolicyAccepted] = useState(false);

  useEffect(() => {
    if (!props.token) {
      props.history.replace("/demo");
    }

    return () => {
      props.unmountAuth();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmailEmpty = () => !email.length;
  const isPasswordEmpty = () => !password.length;
  const isPasswordConfirmationEmpty = () => !password_confirmation.length;
  const isSubmitButtonDisabled = () => {
    return (
      isEmailEmpty() ||
      isPasswordEmpty() ||
      isPasswordConfirmationEmpty() ||
      props.isLoading ||
      !policyAccepted
    );
  };

  return (
    <AuthContainer heading={translate("sign_up.registration")}>
      <form
        className={styles.form}
        onSubmit={(e: any) => {
          e.preventDefault();
          props.upgradeGuestAccount({
            email,
            password,
            password_confirmation,
            history: props.history,
          });
        }}
      >
        <Input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          label={translate("global.email-address")}
          error={getErrorMessage(props.errors, "email")}
        />
        <Input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          label={translate("global.password")}
          tooltip={translate("sign_up.password-validation")}
          error={getErrorMessage(props.errors, "password", true)}
        />
        <Input
          id="password_confirmation"
          type="password"
          name="password_confirmation"
          value={password_confirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordConfirmation(e.target.value)
          }
          label={translate("sign_up.repeat-password")}
          error={getErrorMessage(props.errors, "password_confirmation", true)}
        />
        <Checkbox
          onClick={() => setPolicyAccepted(!policyAccepted)}
          checked={policyAccepted}
        >
          {
            <>
              {translate("sign_up.privacy-policy-acceptance-first-part")}
              &nbsp;
              <a
                href={getLandingPageURL({
                  default: "/privacy-policy",
                  pl: "/polityka-prywatnosci",
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("sign_up.privacy-policy-acceptance-link-part")}
              </a>
              &nbsp;
              {translate("sign_up.privacy-policy-acceptance-last-part")}
              &nbsp;
              <a
                href={getLandingPageURL({
                  default: "/terms-of-use",
                  pl: "/warunki",
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("global.terms-of-use")}
              </a>
              &nbsp;
            </>
          }
        </Checkbox>
        <div className={`${styles.buttonsContainer} ${styles.submitContainer}`}>
          <button
            type="submit"
            disabled={isSubmitButtonDisabled() || props.isLoading}
            className={props.isLoading ? styles.buttonIsLoading : ""}
          >
            {translate("sign_up.sign-up")}
            {props.isLoading && (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            )}
          </button>
        </div>
      </form>
    </AuthContainer>
  );
};

const mapStateToProps = (state: {
  auth: authStateModel;
  billing: billingStateModel;
}) => ({
  errors: state.auth.errors,
  token: state.auth.token,
  isLoading: state.auth.in_progress.general,
  billing: state.billing,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      upgradeGuestAccount,
      unmountAuth,
      validateReferrerAccountID,
      resetBillingErrors,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoSignUp);
