import pathOr from "ramda/src/pathOr";

// Types
import { testsActionModel, testsStateModel } from "../../../models/redux/tests";

const initialState: testsStateModel = {
  list: [],
};

export const tests = (state = initialState, action: testsActionModel) => {
  switch (action.type) {
    case "GET_ALL_TESTS_SUCCESS":
      return {
        ...state,
        list: pathOr([], ["payload", "data"], action),
      };
    default:
      return state;
  }
};
