import React from "react";

import QuestionCurriculumModal from "./Modal";

import translate from "../../../../../../../services/translate";

import Modal, { ToggleContent } from "../../../../../../Common/Modal";

import { ReactComponent as BookIcon } from "../../../../../../../assets/images/book.svg";

// Types
import { questionModel } from "../../../../../../../models/redux/test";
import { unmountCurriculum as unmountCurriculumFunction } from "../../../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";

interface Props {
  question: questionModel;
  unmountCurriculum?: unmountCurriculumFunction;
}

export const QuestionCurriculum = (props: Props) => {
  const hasQuestionSkill = () => {
    if (props.question) {
      return !!props.question.skills.length;
    }
    return false;
  };

  return (
    <ToggleContent
      toggle={(show: any) => (
        <button type="button" onClick={show}>
          <BookIcon/>
          {hasQuestionSkill()
            ? translate("creator.re-assign-skill")
            : translate("creator.assign-skill")}
        </button>
      )}
      content={(hide: () => any) => (
        <Modal
          hide={() => {
            if (props.unmountCurriculum) {
              props.unmountCurriculum();
            }
            hide();
          }}
          customStyles={styles.modal}
        >
          <QuestionCurriculumModal questionID={props.question.id} hide={hide}/>
        </Modal>
      )}
    />
  );
};

export default QuestionCurriculum;
