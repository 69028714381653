import { classModel } from "../../../models/redux/teacher";
import { questionModel } from "../../../models/redux/test";

export const getSelectedClassName = (classes: classModel[], classID: string) => {
  const selectedClass = classes.find((item: any) => item.id === classID);

  if (selectedClass) {
    return selectedClass.name;
  }
  return "";
};

export const getTotalPoints = (questions: questionModel[]) => questions
  .map((item: questionModel) => item.points)
  .reduce((previousValue, currentValue) =>
    previousValue + currentValue, 0,
  );

export const notesTypes = {
  checked: "checked",
  lines: "lines",
  blank: "blank",
  dotted: "dotted",
  penmanship: "penmanship",
};
