import React, { useState, useEffect } from "react";
import classNames from "classnames";
import uniq from "ramda/src/uniq";
import concat from "ramda/src/concat";
import DOMPurify from "dompurify";

import Checkbox from "../../../../Common/Checkbox";
import CurriculumDetailsBoxChildren from "./Children";

import translate from "../../../../../services/translate";

// Types
import { curriculumDetailsSkill } from "../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";

interface Props {
  id: string;
  body: string;
  children: any[];
  isChosen: boolean;
  selectedSkills: string[];
  inProgress: boolean;
  onClick: (ids: string[]) => any;
  unfoldOnParentClick?: boolean;
  darkMode?: boolean;
  chosenIfExists?: boolean;
  readOnly?: boolean;
}

const CurriculumDetailsBox = (props: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(props.readOnly ? false : true);

  useEffect(() => {
    if (props.unfoldOnParentClick && props.isChosen) {
      setIsCollapsed(false);
    }
  }, [props.isChosen, props.unfoldOnParentClick]);

  const headerClass = classNames({
    [styles.header]: true,
    [styles.collapsedHeader]: !isCollapsed && !!props.children.length,
  });

  const listItemClass = classNames({
    [styles.listItem]: !props.darkMode,
    [styles.listItemDarkMode]: props.darkMode,
  });

  const handleOnClick = () => {
    if (props.isChosen) {
      return props.onClick(
        props.selectedSkills.filter(
          (item: string) =>
            !getAllChildrensIDs(props.children, props.id).includes(item),
        ),
      );
    } else {
      return props.onClick(
        uniq(
          concat(
            props.selectedSkills,
            getAllChildrensIDs(props.children, props.id),
          ),
        ),
      );
    }
  };

  const getAllChildrensIDs = (
    children: any,
    id?: string | undefined,
    IDsArray?: string[],
  ) => {
    let arr = IDsArray || ([] as string[]);
    if (id) {
      arr.push(id);
    }
    children.forEach((children: any) => {
      arr.push(children.id);
      if (children.children && !!children.children.length) {
        getAllChildrensIDs(children.children, undefined, arr);
      }
    });

    return arr;
  };

  return (
    <li className={listItemClass}>
      <div className={headerClass}>
        {!props.readOnly && (
          <Checkbox
            onClick={handleOnClick}
            checked={props.isChosen}
            disabled={props.inProgress}
          />
        )}
        {props.body && (
          <div
            dangerouslySetInnerHTML={{
              __html: `${DOMPurify.sanitize(props.body)}`,
            }}
          />
        )}
        {!!props.children.length && !props.readOnly && (
          <button type="button" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed
              ? translate("global.unfold")
              : translate("global.fold")}
          </button>
        )}
      </div>
      {!!props.children.length && !isCollapsed && (
        <ul>
          {props.children.map((item: curriculumDetailsSkill) => (
            <CurriculumDetailsBoxChildren
              key={item.id}
              id={item.id}
              parentIDs={[props.id]}
              parentID={props.id}
              rootParentID={props.id}
              body={item.body}
              children={item.children}
              isChosen={
                props.chosenIfExists
                  ? props.selectedSkills.includes(item.id)
                  : item.is_chosen
              }
              selectedSkills={props.selectedSkills}
              siblings={props.children}
              inProgress={props.inProgress}
              onClick={(ids: string[]) => props.onClick(ids)}
              chosenIfExists={!!props.chosenIfExists}
              readOnly={props.readOnly}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CurriculumDetailsBox;
