import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Modal, { ToggleContent } from "../../../../components/Common/Modal";
import Input from "../../../Common/Input";

import { ReactComponent as EyeIcon } from "../../../../assets/images/eye_icon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/images/trash.svg";
import { ReactComponent as TrashAltIcon } from "../../../../assets/images/trash_alt.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/images/small_arrow_down_grey.svg";
import { ReactComponent as Warning } from "../../../../assets/images/warning.svg";

import {
  getStudents,
  unmountClass,
  deleteStudent,
  addStudent,
  getClass,
} from "../../../../redux/actions/class";

import translate from "../../../../services/translate";

// Types
import { History } from "history";
import {
  classStateModel,
  getStudents as getStudentsFunction,
  deleteStudent as deleteStudentFunction,
  addStudent as addStudentFunction,
  getClass as getClassFunction,
  studentModel,
} from "../../../../models/redux/class";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  match: any;
  class: classStateModel;
  getStudents: getStudentsFunction;
  unmountClass: () => {};
  deleteStudent: deleteStudentFunction;
  getClass: getClassFunction;
  addStudent: addStudentFunction;
}

export const StudentsList = (props: Props) => {
  const [studentInfo, setStudentInfo] = useState<string[]>([]);
  const [newStudentNumber, setNewStudentNumber] = useState<string>("");
  const [addStudentConfirmation, setAddStudentConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    props.getClass({ id: props.match.params.id });
    props.getStudents({ classID: props.match.params.id });

    return () => {
      props.unmountClass();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getStudents({ classID: props.match.params.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const arrowClass = (id: string) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: studentInfo.includes(id),
    });

  const toggleArrow = (id: string) => {
    const isChecked = studentInfo.includes(id);

    if (isChecked) {
      setStudentInfo(studentInfo.filter((value) => value !== id));
      return;
    }

    setStudentInfo(studentInfo.concat(id));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <Link to={`/class/${props.class.id}`}>{props.class.name}</Link>
          &nbsp;-&nbsp;
          <span>{translate("global.students-list")}</span>
        </h3>
        <ToggleContent
          toggle={(show: any) => (
            <button type="button" onClick={show}>
              <span>+&nbsp;</span>
              <span>{translate("students.add-student")}</span>
            </button>
          )}
          content={(hide: () => {}) => {
            if (addStudentConfirmation) {
              return (
                <Modal
                  hide={() => {
                    hide();
                    setAddStudentConfirmation(false);
                  }}
                  isBig
                >
                  <div className={styles.addStudentModal}>
                    <h3 className={styles.violet}>
                      <Warning/>
                      <span>{translate("global.warning")}</span>
                    </h3>
                    <p>
                      {translate(
                        "students.add-student-double-confirmation-desc",
                      )}
                    </p>
                    <div className={styles.buttonContainerDouble}>
                      <button
                        type="button"
                        onClick={() => setAddStudentConfirmation(false)}
                      >
                        {translate("global.no")}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          hide();
                          props.addStudent({
                            classID: props.match.params.id,
                            number: newStudentNumber,
                          });
                          setNewStudentNumber("");
                          setAddStudentConfirmation(false);
                        }}
                      >
                        {translate("global.yes")}
                      </button>
                    </div>
                  </div>
                </Modal>
              );
            }
            return (
              <Modal
                hide={() => {
                  hide();
                  setAddStudentConfirmation(false);
                }}
                isBig
              >
                <div className={styles.addStudentModal}>
                  <h3>
                    <span>{translate("global.new")} </span>
                    <span>{translate("students.student")}</span>
                  </h3>
                  <p>{translate("students.add-student-desc")}</p>
                  <Input
                    id="add-student-input"
                    type="text"
                    name="add-student-input"
                    label={
                      <span>
                        {translate("students.number-from-lesson-diary")}
                      </span>
                    }
                    value={newStudentNumber}
                    labelHiddenOnActive
                    onChange={(e: any) => setNewStudentNumber(e.target.value)}
                    hideErrorMessage
                  />
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      disabled={
                        !Number(newStudentNumber) || !newStudentNumber.length
                      }
                      onClick={() => {
                        if (
                          Number(newStudentNumber) <=
                          props.class.students.length
                        ) {
                          setAddStudentConfirmation(true);
                          return;
                        }
                        hide();
                        props.addStudent({
                          classID: props.match.params.id,
                          number: newStudentNumber,
                        });
                        setNewStudentNumber("");
                      }}
                    >
                      <span>+&nbsp;</span>
                      <span>{translate("students.add-student")}</span>
                    </button>
                  </div>
                </div>
              </Modal>
            );
          }}
        />
      </div>
      <ul className={styles.students}>
        <li className={styles.headingItem}>
          <div className={styles.studentNumber}>
            <span>{translate("students.student-number-heading")}</span>
          </div>
          <div className={styles.accessCode}>
            <span>{translate("global.access-code")}</span>
          </div>
          <div className={styles.tests}>
            <span>{translate("global.tests")}</span>
          </div>
          <div className={styles.pointsAverage}>
            <span>{translate("global.average-score")}</span>
          </div>
          <div className={styles.date}>
            <span>{translate("global.date-added")}</span>
          </div>
          <div className={styles.actions}>
            <span>{translate("class.actions")}</span>
          </div>
        </li>
        {props.class.students.map((item: studentModel) => (
          <li className={styles.listItem} key={item.id}>
            <div className={styles.studentNumber}>
              <span className={styles.number}>
                {translate("students.student-number")}&nbsp;{item.number}
              </span>
              <span className={styles.new}>
                {item.is_new && <div>{translate("global.new")}</div>}
              </span>
            </div>
            <div className={styles.accessCode}>
              <span className={styles.number}>{item.code}</span>
            </div>
            <div className={styles.tests}>
              <span className={styles.number}>{item.results_count}</span>
            </div>
            <div className={styles.pointsAverage}>
              <span className={styles.number}>{item.points_average} %</span>
            </div>
            <div className={styles.date}>
              <span className={styles.number}>{item.inserted_at}</span>
            </div>
            <div className={styles.actions}>
              <div className={styles.tooltipContainer}>
                <ReactTooltip
                  id="tooltip"
                  place="left"
                  effect="solid"
                  className={styles.tooltip}
                >
                  <p>{translate("global.details")}</p>
                </ReactTooltip>
                <i data-tip data-for="tooltip">
                  <Link
                    to={`/class/${props.match.params.id}/student/${item.id}`}
                  >
                    <EyeIcon/>
                  </Link>
                </i>
              </div>
              <ToggleContent
                toggle={(show: any) => (
                  <button type="button" onClick={show}>
                    <TrashIcon/>
                  </button>
                )}
                content={(hide: () => {}) => (
                  <Modal isSmall hide={hide}>
                    <div className={styles.deleteStudentModal}>
                      <TrashAltIcon/>
                      <span>
                        {translate("class.delete-student-confirmation")}
                      </span>
                      <div className={styles.buttonContainer}>
                        <button type="button" onClick={hide}>
                          {translate("global.no")}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            hide();
                            props.deleteStudent({
                              classID: props.match.params.id,
                              studentID: item.id,
                            });
                          }}
                        >
                          {translate("global.yes")}
                        </button>
                      </div>
                    </div>
                  </Modal>
                )}
              />
              <button
                className={arrowClass(item.id)}
                onClick={() => toggleArrow(item.id)}
              >
                <ArrowIcon/>
              </button>
            </div>
            {studentInfo.includes(item.id) && (
              <div className={styles.dropDownContainer}>
                <ul>
                  <li className={styles.accessCode}>
                    <span>{translate("global.access-code")}</span>
                    <span>{item.code}</span>
                  </li>
                  <li className={styles.tests}>
                    <span>{translate("global.tests")}</span>
                    <span>{item.results_count}</span>
                  </li>
                  <li className={styles.pointsAverage}>
                    <span>{translate("global.average-score")}</span>
                    <span>{item.points_average} %</span>
                  </li>
                  <li className={styles.date}>
                    <span>{translate("global.date-added")}</span>
                    <span>{item.inserted_at}</span>
                  </li>
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: { classReducer: classStateModel }) => ({
  class: state.classReducer,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getStudents,
      unmountClass,
      deleteStudent,
      addStudent,
      getClass,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
