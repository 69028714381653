import React, { useEffect, useState } from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";

import MultipleChoiceSubtype from "./MultipleChoiceSubtype";

import translate from "../../../../../../../services/translate";

import { ReactComponent as DnDIcon } from "../../../../../../../assets/images/dnd_icon.svg";

// Types
import {
  questionSubtypeModel,
  QuestionTypes,
  answerChoiceModel,
  updateQuestion as updateQuestionFunction,
  matchingModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  questionID: string;
  testID: string;
  classID: string;
  order: number;
  points: number;
  pointsPerItem: number | null;
  type: string;
  subtype: string | null;
  subtypes: questionSubtypeModel[];
  updateQuestion: updateQuestionFunction;
  answerChoices: answerChoiceModel[];
  matchings: matchingModel[];
  gaps: string[];
  questionBodyHTML: string;
  questionBody: string;
  isCollapsed: boolean;
  isLastQuestion: boolean;
  collapsedQuestions: string[];
  collapse: any;
  inDndMode?: boolean;
}

export const QuestionHeader = (props: Props) => {
  const [points, setPoints] = useState(`${props.points}p`);
  const [pointsPerItem, setPointsPerItem] = useState(`${props.pointsPerItem}p`);

  const updatePoints = (value: string) => {
    const val = Number(value);
    if (value === "") {
      setPoints(value);
      return;
    }

    if (val && !value.includes(".") && !value.includes(" ")) {
      setPoints(value);
    }
  };

  const updatePointsPerItem = (value: string) => {
    const val = Number(value);
    if (value === "") {
      setPointsPerItem(value);
      return;
    }

    if (val && !value.includes(".") && !value.includes(" ")) {
      if (isMatching() && props.matchings.length > 0) {
        const numberOfMatchings = props.matchings[0].length;
        if (Number.isInteger(numberOfMatchings)) {
          setPoints(`${Number(value) * Number(numberOfMatchings)}p`);
          setPointsPerItem(value);
        }
      }

      if (isFillInTheGaps() && props.gaps.length > 0) {
        const numberOfGaps = props.gaps.length;
        if (Number.isInteger(numberOfGaps)) {
          setPoints(`${Number(value) * Number(numberOfGaps)}p`);
          setPointsPerItem(value);
        }
      }
    }
  };

  useEffect(() => {
    if (isMatching() && props.matchings.length > 0) {
      const numberOfMatchings = props.matchings[0].length;
      if (Number.isInteger(numberOfMatchings) && numberOfMatchings > 0) {
        const updatedPoints =
          Number(props.pointsPerItem) * Number(numberOfMatchings);
        if (updatedPoints !== props.points) {
          props.updateQuestion({
            questionID: props.questionID,
            testID: props.testID,
            classID: props.classID,
            points: Number(updatedPoints),
            pointsPerItem: Number(pointsPerItem),
          });
          setPoints(`${updatedPoints}p`);
          setPointsPerItem(`${props.pointsPerItem}p`);
        }
      }
    }

    if (isFillInTheGaps() && props.gaps.length > 0) {
      const numberOfGaps = props.gaps.length;
      if (Number.isInteger(numberOfGaps)) {
        const updatedPoints =
          Number(props.pointsPerItem) * Number(numberOfGaps);
        if (updatedPoints !== props.points) {
          props.updateQuestion({
            questionID: props.questionID,
            testID: props.testID,
            classID: props.classID,
            points: Number(updatedPoints),
            pointsPerItem: Number(pointsPerItem),
          });
          setPoints(`${updatedPoints}p`);
          setPointsPerItem(`${props.pointsPerItem}p`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matchings, props.gaps]);

  const isTrueFalse = () => {
    return props.type === QuestionTypes.TRUE_FALSE;
  };

  const isMultipleChoice = () => {
    return props.type === QuestionTypes.MULTIPLE_CHOICE;
  };

  const isOpen = () => {
    return props.type === QuestionTypes.OPEN;
  };

  const isMatching = () => {
    return props.type === QuestionTypes.MATCHING;
  };

  const isFillInTheGaps = () => {
    return props.type === QuestionTypes.FILL_IN_THE_GAPS;
  };

  const pointsClass = classNames({
    [styles.points]: true,
    [styles.pointsFlex]: isOpen() || isMatching() || isFillInTheGaps(),
    [styles.pointsLastItem]: props.isLastQuestion,
    [styles.pointsDnDMode]: props.inDndMode,
  });

  const headerClass = classNames({
    [styles.header]: true,
    [styles.headerIsCollapsed]: props.isCollapsed,
  });

  return (
    <div className={headerClass}>
      {props.inDndMode && <DnDIcon/>}
      <span className={styles.order}>{props.order}</span>
      {!props.isCollapsed ? (
        <>
          {isMultipleChoice() && (
            <MultipleChoiceSubtype
              subtype={props.subtype}
              subtypes={props.subtypes}
              updateQuestion={props.updateQuestion}
              questionID={props.questionID}
              testID={props.testID}
              classID={props.classID}
            />
          )}
          {isTrueFalse() && (
            <p className={styles.body}>
              <span>{translate("creator.true-false-desc-1")}</span>
              <span className={styles.bold}>
                &nbsp;{props.answerChoices[0].name}&nbsp;
              </span>
              <span>{translate("creator.true-false-desc-2")}</span>
              <span className={styles.bold}>
                &nbsp;{props.answerChoices[1].name}&nbsp;
              </span>
              <span>{translate("creator.true-false-desc-3")}</span>
            </p>
          )}
        </>
      ) : (
        <div
          className={`${styles.body} questionBodyGlobal`}
          dangerouslySetInnerHTML={{
            __html: `${
              props.questionBodyHTML
                ? DOMPurify.sanitize(props.questionBodyHTML)
                : DOMPurify.sanitize(props.questionBody)
            }`,
          }}
        ></div>
      )}
      <div className={pointsClass}>
        {isMatching() || isFillInTheGaps() ? (
          <div>
            <b>{translate("creator.punctation")}</b>
            {/* <span>{translate("creator.punctation-per-item")}</span> */}
            <span>{translate("creator.punctation-per-item")}</span>
            <input
              onChange={(e) => updatePointsPerItem(e.target.value)}
              onClick={() => setPointsPerItem(pointsPerItem.slice(0, -1))}
              value={pointsPerItem}
              onBlur={() => {
                if (pointsPerItem === "") {
                  setPointsPerItem(`${props.pointsPerItem}p`);
                  return;
                }
                props.updateQuestion({
                  questionID: props.questionID,
                  testID: props.testID,
                  classID: props.classID,
                  points: Number(points),
                  pointsPerItem: Number(pointsPerItem),
                });
                setPointsPerItem(`${pointsPerItem}p`);
              }}
            />
            {/* <span>{translate("creator.punctation-per-question")}</span> */}
            <span>{translate("creator.punctation-per-question")}</span>
            <input
              onChange={(e) => {}}
              onClick={() => {}}
              value={points}
              disabled
            />
          </div>
        ) : (
          <div>
            <span>{translate("creator.punctation")}</span>
            <input
              onChange={(e) => updatePoints(e.target.value)}
              onClick={() => setPoints(points.slice(0, -1))}
              value={points}
              onBlur={() => {
                if (points === "") {
                  setPoints(`${props.points}p`);
                  return;
                }
                props.updateQuestion({
                  questionID: props.questionID,
                  testID: props.testID,
                  classID: props.classID,
                  points: Number(points),
                });
                setPoints(`${points}p`);
              }}
            />
          </div>
        )}
        {!props.inDndMode && (
          <button
            type="button"
            onClick={() =>
              props.collapse(
                props.isCollapsed
                  ? props.collapsedQuestions.filter(
                    (item) => item !== props.questionID,
                  )
                  : props.collapsedQuestions.concat(props.questionID),
              )
            }
            className={styles.collapseQuestionButton}
          >
            {props.isCollapsed
              ? translate("global.unfold")
              : translate("global.fold")}
          </button>
        )}
      </div>
    </div>
  );
};

export default QuestionHeader;
