import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { forgotPassword, unmountAuth } from "../../redux/actions/auth";
import AuthContainer from "../Common/Auth/Container";
import Input from "../Common/Input";
import { getErrorMessage, getLandingPageURL } from "../../services/common";
import translate from "../../services/translate";

// Types
import { Location, History } from "history";
import {
  authStateModel,
  forgotPassword as forgotPasswordFunction,
} from "../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  errors: any[];
  isLoading: boolean;
  forgotPassword: forgotPasswordFunction;
  unmountAuth: () => {};
  location: Location;
  history: History;
  isAuthenticated: null | boolean;
}

export const ForgotPassword = (props: Props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    return () => {
      props.unmountAuth();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.push("/");
    }
  }, [props]);

  const isEmailEmpty = () => !email.length;
  const isSubmitButtonDisabled = () => {
    return isEmailEmpty() || props.isLoading;
  };

  return (
    <AuthContainer heading={translate("global.forgot-password")}>
      <>
        <p className={styles.info}>
          {translate("forgot_password.forgot-password-description")}
        </p>
        <form
          className={styles.form}
          onSubmit={(e: any) => {
            e.preventDefault();
            props.forgotPassword({ email, history: props.history });
          }}
        >
          <Input
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            label={translate("global.email-address")}
            error={getErrorMessage(props.errors, "email")}
          />
          <div
            className={`${styles.buttonsContainer} ${styles.submitContainer}`}
          >
            <button
              type="submit"
              disabled={isSubmitButtonDisabled() || props.isLoading}
              className={props.isLoading ? styles.buttonIsLoading : ""}
            >
              {translate("global.send")}
              {props.isLoading && (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              )}
            </button>
          </div>
          <div className={`${styles.buttonsContainer} ${styles.linkContainer}`}>
            <a href={getLandingPageURL()}>
              {translate("global.back-to-homepage")}
            </a>
          </div>
        </form>
      </>
    </AuthContainer>
  );
};

const mapStateToProps = (state: { auth: authStateModel }) => ({
  errors: state.auth.errors,
  isLoading: state.auth.in_progress.general,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      forgotPassword,
      unmountAuth,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
