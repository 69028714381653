import React, { useEffect, useState } from "react";

// Types
import {
  answerChoiceModel,
} from "../../../../../../../../models/redux/test";
import { ErrorObject } from "../../../../../../../../models/common";

import styles from "../styles.module.scss";


interface Props {
  answerChoices: answerChoiceModel[];
  updateQuestion: (name_html: string, name: string, id: string, isCorrect: boolean) => any;
  questionID: string;
  testErrors: ErrorObject[];
}

export const TrueFalse = (props: Props) => {
  const [answerChoices, setAnswerChoices] = useState([] as answerChoiceModel[]);
  const [isChecked, setIsChecked] = useState("");

  const getValue = (item: answerChoiceModel) => `true-false-answer_${props.questionID}_${item.id}`;

  useEffect(() => {
    const isCheckedItem = props.answerChoices.find(item => item.is_correct);

    setAnswerChoices(props.answerChoices);

    if (isCheckedItem) {
      setIsChecked(getValue(isCheckedItem));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCorrectAnswer = (item: answerChoiceModel) => {
    props.updateQuestion(
      item.name_html,
      item.name,
      item.id,
      true,
    );

    setIsChecked(getValue(item));
  };

  return (
    <>
      {answerChoices.map((item: answerChoiceModel) => (
        <div key={item.id} className={styles.listItemContainer}>
          <li>
            <input
              type="radio"
              name={`${props.questionID}_question_answer_radio`}
              checked={isChecked === getValue(item)}
              value={getValue(item)}
              onChange={() => setCorrectAnswer(item)}
            />
            <span className={styles.answerName}>
              {item.name}
            </span>
          </li>
        </div>
      ))}
    </>
  );
};

export default TrueFalse;
