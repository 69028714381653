import React from "react";

import translate from "../../../../../../services/translate";

export const TestInstructionPDF = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "17px",
        margin: "15px 0 25px 0",
        border: "1px solid black",
      }}
    >
      <h2
        style={{
          fontSize: "18px",
          fontWeight: "bolder",
          fontFamily: "Lato",
          textTransform: "uppercase",
          color: "black",
          margin: "0 0 10px 0",
        }}
      >
        {translate("creator.test-instruction-heading")}
      </h2>
      <p
        style={{
          fontSize: "12px",
          fontFamily: "Lato",
          lineHeight: "24px",
          margin: "0",
          color: "black",
        }}
      >
        <span
          style={{
            verticalAlign: "middle",
          }}
        >
          {translate("creator.test-instruction-desc-1")}&nbsp;
        </span>
        <span
          style={{
            verticalAlign: "middle",
          }}
        >
          {translate("creator.test-instruction-desc-2")}
        </span>
        <i
          style={{
            display: "inline-block",
            width: "15px",
            height: "15px",
            background: "black",
            margin: "0 5px",
            verticalAlign: "middle",
          }}
        ></i>
        <span
          style={{
            verticalAlign: "middle",
          }}
        >
          {translate("creator.test-instruction-desc-3")}
        </span>
        <span
          style={{
            display: "inline-block",
            padding: "0px",
            border: "1px solid black",
            borderRadius: "100%",
            height: "30px",
            width: "30px",
            margin: "0 5px",
            position: "relative",
            verticalAlign: "middle",
          }}
        >
          <i
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              top: "50%",
              right: "0",
              left: "50",
              transform: "translate(-50%, -50%)",
              display: "inline-block",
              width: "15px",
              height: "15px",
              background: "black",
              verticalAlign: "middle",
            }}
          ></i>
        </span>
        <span
          style={{
            fontSize: "12px",
            verticalAlign: "middle",
          }}
        >
          {translate("creator.test-instruction-desc-4")}
        </span>
      </p>
    </div>
  );
};

export default TestInstructionPDF;
