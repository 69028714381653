import React, { useEffect, useState } from "react";
import katex from "katex";

import propOr from "ramda/src/propOr";
import pathOr from "ramda/src/pathOr";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import translate from "../../../../../../services/translate";

import QuestionHeader from "./Header";
import QuestionBody from "./Body";
import QuestionNotes from "./Notes";
import QuestionAnswers from "./Answers";
import QuestionBottom from "./Bottom";
import QuestionCurriculum from "./Curriculum";
import DeleteQuestionButton from "./Common/DeleteQuestionButton";
import AddPictureButton from "./Common/AddPictureButton";
import FillInTheGapsSettings from "./Common/FillInTheGaps/Settings";

import { ReactComponent as TrashIcon } from "../../../../../../assets/images/trash.svg";

import { userFromPL } from "../../../../../../services/common";

// Types
import {
  answerChoiceModel,
  matchingModel,
  questionModel,
  testTypes,
  updateQuestion as updateQuestionFunction,
  questionTypeModel,
  questionSubtypeModel,
  deleteQuestion as deleteQuestionFunction,
  createQuestionAnswerChoice as createQuestionAnswerChoiceFunction,
  updateQuestionAnswerChoice as updateQuestionAnswerChoiceFunction,
  deleteQuestionAnswerChoice as deleteQuestionAnswerChoiceFunction,
  createQuestionMatching as createQuestionMatchingFunction,
  updateQuestionMatching as updateQuestionMatchingFunction,
  deleteQuestionMatching as deleteQuestionMatchingFunction,
  deleteQuestionImage as deleteQuestionImageFunction,
  replaceQuestionGap as replaceQuestionGapFunction,
  QuestionTypes,
} from "../../../../../../models/redux/test";
import { userStateModel } from "../../../../../../models/redux/user";
import { ErrorObject } from "../../../../../../models/common";
import { unmountCurriculum as unmountCurriculumFunction } from "../../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";

(window as any).katex = katex;

interface Props {
  testID: string;
  classID: string;
  question: questionModel;
  updateQuestion: updateQuestionFunction;
  addNextQuestion: any;
  questionIndex: number;
  questionsLength: number;
  questionTypes: questionTypeModel[];
  testErrors: ErrorObject[];
  deleteQuestion: deleteQuestionFunction;
  createQuestionAnswerChoice: createQuestionAnswerChoiceFunction;
  updateQuestionAnswerChoice: updateQuestionAnswerChoiceFunction;
  deleteQuestionAnswerChoice: deleteQuestionAnswerChoiceFunction;
  createQuestionMatching: createQuestionMatchingFunction;
  updateQuestionMatching: updateQuestionMatchingFunction;
  deleteQuestionMatching: deleteQuestionMatchingFunction;
  deleteQuestionImage: deleteQuestionImageFunction;
  questionInProgress: boolean;
  fullQuestionInProgress: boolean;
  collapse: any;
  collapsedQuestions: string[];
  user: userStateModel;
  selectedTestType: string;
  replaceQuestionGap: replaceQuestionGapFunction;
  unmountCurriculum?: unmountCurriculumFunction;
  hasCurriculum?: boolean;
  inDndMode?: boolean;
}

export const Question = (props: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(props.collapsedQuestions.includes(props.question.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsCollapsed(props.collapsedQuestions.includes(props.question.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collapsedQuestions]);

  const isLastQuestion = () => {
    return props.questionsLength === props.questionIndex + 1;
  };

  const getSubtypes = () => {
    const questionType = props.questionTypes.find(
      (item: questionTypeModel) => item.type === props.question.type,
    );

    return propOr([], "subtypes", questionType) as questionSubtypeModel[];
  };

  const questionContainerClass = classNames({
    [styles.questionContainer]: true,
    [styles.questionContainerNotCollapsed]: !isCollapsed,
  });

  const collapseQuestionButtonClass = classNames({
    [styles.collapseQuestionButton]: !isCollapsed,
    [styles.collapseQuestionButtonActive]: isCollapsed,
  });

  const bottomContainerClass = classNames({
    [styles.bottomContainer]: true,
    [styles.bottomContainerFolded]: isCollapsed,
  });

  const showAssignSkillButton = () =>
    userFromPL(props.user.country) && !isCollapsed && props.hasCurriculum;

  return (
    <section className={styles.questionMainContainer}>
      <section className={questionContainerClass}>
        {props.fullQuestionInProgress && (
          <div className={styles.fullQuestionInProgress}>
            <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
          </div>
        )}
        <QuestionHeader
          testID={props.testID}
          classID={props.classID}
          questionID={pathOr("", ["question", "id"], props)}
          order={pathOr(1, ["question", "order"], props)}
          points={pathOr(1, ["question", "points"], props)}
          pointsPerItem={pathOr(null, ["question", "points_per_item"], props)}
          type={pathOr("", ["question", "type"], props)}
          subtype={pathOr("", ["question", "type_data", "subtype"], props)}
          subtypes={getSubtypes()}
          updateQuestion={props.updateQuestion}
          answerChoices={pathOr(
            [] as answerChoiceModel[],
            ["question", "answer_choices"],
            props,
          )}
          matchings={pathOr(
            [] as matchingModel[],
            ["question", "matchings"],
            props,
          )}
          gaps={pathOr([] as string[], ["question", "gaps"], props)}
          questionBodyHTML={pathOr("", ["question", "body_html"], props)}
          questionBody={pathOr("", ["question", "body"], props)}
          isCollapsed={isCollapsed}
          isLastQuestion={isLastQuestion()}
          collapse={props.collapse}
          collapsedQuestions={props.collapsedQuestions}
          inDndMode={props.inDndMode}
        />
        {!isCollapsed &&
          props.question.type === QuestionTypes.FILL_IN_THE_GAPS &&
          !!props.question.gaps
            .map((item) => item.names[0])
            .filter((item) => !!item.length).length &&
          props.question.type_data.show_gap_options && (
          <div className={styles.fillInTheGapsGapsOptionsContent}>
            <ul className={styles.fillInTheGapsGapsOptions}>
              {props.question.gaps.map((item) => (
                <li key={item.id}>{item.names[0]}</li>
              ))}
            </ul>
          </div>
        )}
        {!isCollapsed && (
          <>
            <div className={styles.content}>
              <QuestionBody
                id={pathOr("", ["question", "id"], props)}
                type={pathOr("", ["question", "type"], props)}
                order={pathOr(1, ["question", "order"], props)}
                questionBodyHTML={pathOr("", ["question", "body_html"], props)}
                questionBody={pathOr("", ["question", "body"], props)}
                testErrors={props.testErrors}
                updateQuestion={props.updateQuestion}
                testID={props.testID}
                classID={props.classID}
                questionInProgress={props.questionInProgress}
                replaceQuestionGap={props.replaceQuestionGap}
                gaps={pathOr([], ["question", "gaps"], props)}
              />
            </div>
            {props.question.image && (
              <div className={styles.questionImgContainer}>
                <img
                  className={styles.questionImg}
                  src={props.question.image}
                  alt={`img_${props.question.id}`}
                />
                <button
                  type="button"
                  disabled={props.questionInProgress}
                  onClick={() => {
                    props.deleteQuestionImage({
                      classID: props.classID,
                      testID: props.testID,
                      questionID: pathOr("", ["question", "id"], props),
                    });
                  }}
                >
                  <TrashIcon/>
                </button>
              </div>
            )}
            <QuestionAnswers
              testID={props.testID}
              classID={props.classID}
              questionID={pathOr("", ["question", "id"], props)}
              subtype={pathOr("", ["question", "type_data", "subtype"], props)}
              type={pathOr("", ["question", "type"], props)}
              answerChoices={pathOr(
                [] as answerChoiceModel[],
                ["question", "answer_choices"],
                props,
              )}
              matchings={pathOr(
                [] as matchingModel[],
                ["question", "matchings"],
                props,
              )}
              testErrors={props.testErrors}
              createQuestionAnswerChoice={props.createQuestionAnswerChoice}
              updateQuestionAnswerChoice={props.updateQuestionAnswerChoice}
              deleteQuestionAnswerChoice={props.deleteQuestionAnswerChoice}
              createQuestionMatching={props.createQuestionMatching}
              updateQuestionMatching={props.updateQuestionMatching}
              deleteQuestionMatching={props.deleteQuestionMatching}
              isOnline={props.selectedTestType === testTypes.ONLINE}
            />
            {props.question.type === QuestionTypes.FILL_IN_THE_GAPS && (
              <FillInTheGapsSettings
                replaceQuestionGap={props.replaceQuestionGap}
                questionID={pathOr("", ["question", "id"], props)}
                gaps={pathOr([], ["question", "gaps"], props)}
                testID={props.testID}
                classID={props.classID}
                typeData={props.question.type_data}
                updateQuestion={props.updateQuestion}
                questionInProgress={props.questionInProgress}
              />
            )}
            <div className={styles.settingsMobile}>
              {showAssignSkillButton() && (
                <QuestionCurriculum
                  question={props.question}
                  unmountCurriculum={props.unmountCurriculum}
                />
              )}
              <AddPictureButton
                questionID={props.question.id}
                testID={props.testID}
                classID={props.classID}
                updateQuestion={props.updateQuestion}
                questionInProgress={props.questionInProgress}
              />
              <DeleteQuestionButton
                testID={props.testID}
                classID={props.classID}
                questionID={pathOr("", ["question", "id"], props)}
                deleteQuestion={props.deleteQuestion}
              />
            </div>
            {props.selectedTestType === testTypes.PAPER && (
              <QuestionNotes
                type={pathOr("", ["question", "type"], props)}
                notesSize={pathOr(
                  0,
                  ["question", "type_data", "notes_size"],
                  props,
                )}
                notesType={pathOr(
                  "",
                  ["question", "type_data", "notes_type"],
                  props,
                )}
                questionID={pathOr("", ["question", "id"], props)}
                testID={props.testID}
                classID={props.classID}
                updateQuestion={props.updateQuestion}
              />
            )}
          </>
        )}
        <QuestionBottom
          addNextQuestion={props.addNextQuestion}
          isLastQuestion={isLastQuestion()}
          deleteQuestion={props.deleteQuestion}
          testID={props.testID}
          classID={props.classID}
          questionID={props.question.id}
          isCollapsed={isCollapsed}
        />
      </section>
      <div className={styles.settings}>
        <button
          type="button"
          onClick={() =>
            props.collapse(
              isCollapsed
                ? props.collapsedQuestions.filter(
                  (item) => item !== props.question.id,
                )
                : props.collapsedQuestions.concat(props.question.id),
            )
          }
          className={collapseQuestionButtonClass}
        >
          {isCollapsed ? translate("global.unfold") : translate("global.fold")}
        </button>
        {showAssignSkillButton() && (
          <QuestionCurriculum
            question={props.question}
            unmountCurriculum={props.unmountCurriculum}
          />
        )}
        {!isCollapsed && (
          <AddPictureButton
            questionID={props.question.id}
            testID={props.testID}
            classID={props.classID}
            updateQuestion={props.updateQuestion}
            questionInProgress={props.questionInProgress}
          />
        )}
        <div className={bottomContainerClass}>
          {!isCollapsed && (
            <DeleteQuestionButton
              testID={props.testID}
              classID={props.classID}
              questionID={pathOr("", ["question", "id"], props)}
              deleteQuestion={props.deleteQuestion}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Question;
