import { concat } from "rxjs";
import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, map, mergeMap } from "rxjs/operators";
import {
  addBillingCardSuccess,
  addBillingCardFailure,
  deleteBillingCardSuccess,
  deleteBillingCardFailure,
  getBillingNextPaymentSuccess,
  getBillingNextPaymentFailure,
  updateBillingSettingsSuccess,
  updateBillingSettingsFailure,
  getDiscountCodesSuccess,
  getDiscountCodesFailure,
  updateDiscountCodesSuccess,
  updateDiscountCodesFailure,
  updateInvoiceDataSuccess,
  updateInvoiceDataFailure,
  getInvoiceDataSuccess,
  getInvoiceDataFailure,
  validateReferrerAccountIDSuccess,
  validateReferrerAccountIDFailure,
  getReferralsSuccess,
  getReferralsFailure,
} from "../../actions/billing";
import { showToast } from "../../actions/app";

import { signInByToken } from "../auth";

import { apiCall } from "../../../services/api";

// Types
import {
  addBillingCardAction,
  addBillingCardPayload,
  deleteBillingCardAction,
  addBillingCardSuccessAction,
  addBillingCardFailureAction,
  getBillingNextPaymentAction,
  updateBillingSettingsAction,
  updateBillingSettingsPayload,
  updateBillingSettingsSuccessAction,
  getDiscountCodesAction,
  updateDiscountCodesAction,
  updateDiscountCodesPayload,
  updateDiscountCodesSuccessAction,
  updateInvoiceDataAction,
  updateInvoiceDataPayload,
  getInvoiceDataAction,
  validateReferrerAccountIDAction,
  validateReferrerAccountIDPayload,
  getReferralsAction,
} from "../../../models/redux/billing";
import { XHRPayload } from "../../../models/common";

// Add Billing Card

export const addBillingCardEpic: Epic = (
  action$: ActionsObservable<addBillingCardAction>,
) =>
  action$.pipe(
    ofType("ADD_BILLING_CARD"),
    pluck("payload"),
    switchMap((payload: addBillingCardPayload) => addBillingCard(payload)),
  );

export const addBillingCard = (payload: addBillingCardPayload) =>
  apiCall(
    "/billing/cards",
    "POST",
    { payment_method_id: payload.payment_method_id },
    (data: XHRPayload) => addBillingCardSuccess(data.response),
    addBillingCardFailure,
  );

export const addBillingCardSuccessEpic: Epic = (
  action$: ActionsObservable<addBillingCardSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("ADD_BILLING_CARD_SUCCESS"),
    map(() =>
      showToast({
        text: "profile.credit-card-has-been-added",
        status: "success",
      }),
    ),
    switchMap(() => signInByToken(state$)),
  );

export const addBillingCardFailureEpic: Epic = (
  action$: ActionsObservable<addBillingCardFailureAction>,
) =>
  action$.pipe(
    ofType("ADD_BILLING_CARD_FAILURE"),
    map(() =>
      showToast({
        text: "profile.credit-card-has-not-been-added",
        status: "error",
      }),
    ),
  );

// Delete Billing Card

export const deleteBillingCardEpic: Epic = (
  action$: ActionsObservable<deleteBillingCardAction>,
) => action$.pipe(ofType("DELETE_BILLING_CARD"), switchMap(deleteBillingCard));

export const deleteBillingCard = () =>
  apiCall(
    "/billing/cards",
    "DELETE",
    {},
    deleteBillingCardSuccess,
    deleteBillingCardFailure,
  );

// Get Billing Next Payment

export const getBillingNextPaymentEpic: Epic = (
  action$: ActionsObservable<getBillingNextPaymentAction>,
) =>
  action$.pipe(
    ofType("GET_BILLING_NEXT_PAYMENT"),
    switchMap(getBillingNextPayment),
  );

export const getBillingNextPayment = () =>
  apiCall(
    "/billing/payments/next",
    "GET",
    {},
    (data: XHRPayload) => getBillingNextPaymentSuccess(data.response),
    getBillingNextPaymentFailure,
  );

// Update Billing Settings

export const updateBillingSettingsEpic: Epic = (
  action$: ActionsObservable<updateBillingSettingsAction>,
) =>
  action$.pipe(
    ofType("UPDATE_BILLING_SETTINGS"),
    pluck("payload"),
    switchMap((payload: updateBillingSettingsPayload) =>
      updateBillingSettings(payload),
    ),
  );

export const updateBillingSettings = (payload: updateBillingSettingsPayload) =>
  apiCall(
    "/billing/settings",
    "PATCH",
    payload,
    updateBillingSettingsSuccess,
    updateBillingSettingsFailure,
  );

export const updateBillingSettingsSuccessEpic: Epic = (
  action$: ActionsObservable<updateBillingSettingsSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_BILLING_SETTINGS_SUCCESS"),
    mergeMap(() => concat(signInByToken(state$), getBillingNextPayment())),
  );

// Get Discount Codes

export const getDiscountCodesEpic: Epic = (
  action$: ActionsObservable<getDiscountCodesAction>,
) => action$.pipe(ofType("GET_DISCOUNT_CODES"), switchMap(getDiscountCodes));

export const getDiscountCodes = () =>
  apiCall(
    "/billing/discount_codes",
    "GET",
    {},
    (data: XHRPayload) => getDiscountCodesSuccess(data.response),
    getDiscountCodesFailure,
  );

// Update Discount Codes

export const updateDiscountCodesEpic: Epic = (
  action$: ActionsObservable<updateDiscountCodesAction>,
) =>
  action$.pipe(
    ofType("UPDATE_DISCOUNT_CODES"),
    pluck("payload"),
    switchMap((payload: updateDiscountCodesPayload) =>
      updateDiscountCodes(payload),
    ),
  );

export const updateDiscountCodes = (payload: updateDiscountCodesPayload) =>
  apiCall(
    `/billing/discount_codes/${payload.discount_code_id}`,
    "PATCH",
    {},
    updateDiscountCodesSuccess,
    updateDiscountCodesFailure,
  );

export const updateDiscountCodesSuccessEpic: Epic = (
  action$: ActionsObservable<updateDiscountCodesSuccessAction>,
) =>
  action$.pipe(
    ofType("UPDATE_DISCOUNT_CODES_SUCCESS"),
    mergeMap(() => concat(getDiscountCodes(), getBillingNextPayment())),
  );

// Get Invoice Data

export const getInvoiceDataEpic: Epic = (
  action$: ActionsObservable<getInvoiceDataAction>,
) => action$.pipe(ofType("GET_INVOICE_DATA"), switchMap(getInvoiceData));

export const getInvoiceData = () =>
  apiCall(
    "/billing/invoice_data",
    "GET",
    {},
    (data: XHRPayload) => getInvoiceDataSuccess(data.response),
    getInvoiceDataFailure,
  );

// Upade Invoice Data

export const updateInvoiceDataEpic: Epic = (
  action$: ActionsObservable<updateInvoiceDataAction>,
) =>
  action$.pipe(
    ofType("UPDATE_INVOICE_DATA"),
    switchMap((data) => updateInvoiceData(data.payload, data.callback)),
  );

export const updateInvoiceData = (
  payload: updateInvoiceDataPayload,
  callback: any,
) =>
  apiCall(
    "/billing/invoice_data",
    "PATCH",
    payload,
    () => updateInvoiceDataSuccess(callback),
    updateInvoiceDataFailure,
  );

// Validate Referrer Account ID

export const validateReferrerAccountIDEpic: Epic = (
  action$: ActionsObservable<validateReferrerAccountIDAction>,
) =>
  action$.pipe(
    ofType("VALIDATE_REFERRER_ACCOUNT_ID"),
    switchMap((data) => validateReferrerAccountID(data.payload)),
  );

export const validateReferrerAccountID = (
  payload: validateReferrerAccountIDPayload,
) =>
  apiCall(
    `/billing/referrals/validate?referrer_account_id=${payload.referrer_account_id}`,
    "GET",
    {},
    validateReferrerAccountIDSuccess,
    validateReferrerAccountIDFailure,
  );

// Get Referrals

export const getReferralsEpic: Epic = (
  action$: ActionsObservable<getReferralsAction>,
) => action$.pipe(ofType("GET_REFERRALS"), switchMap(getReferrals));

export const getReferrals = () =>
  apiCall(
    "/billing/referrals",
    "GET",
    {},
    (data: XHRPayload) => getReferralsSuccess(data.response),
    getReferralsFailure,
  );
