import {
  testsBaseGetTestsAction,
  testsBaseGetTestsPayload,
  testsBaseGetTestsSuccessAction,
  testsBaseGetTestsFailureAction,
  testsBaseGetTestsSuccessPayload,
  testsBaseGetTestsNextPagePayload,
  testsBaseGetTestsNextPageAction,
  testsBaseGetTestsNextPageSuccessPayload,
  testsBaseGetTestsNextPageSuccessAction,
  testsBaseGetTestsNextPageFailureAction,
  getTestFromTestsBasePayload,
  getTestFromTestsBaseAction,
  getTestFromTestsBaseSuccessPayload,
  getTestFromTestsBaseSuccessAction,
  getTestFromTestsBaseFailureAction,
  publishTestInTestsBasePayload,
  publishTestInTestsBaseAction,
  publishTestInTestsBaseSuccessAction,
  publishTestInTestsBaseFailureAction,
  duplicateTestFromTestsBasePayload,
  duplicateTestFromTestsBaseAction,
  duplicateTestFromTestsBaseSuccessAction,
  duplicateTestFromTestsBaseFailureAction,
} from "../../../models/redux/testsBase";
import { ErrorsPayload } from "../../../models/common";

// Tests Base Get Tests

export const testsBaseGetTests = (payload: testsBaseGetTestsPayload): testsBaseGetTestsAction => {
  return {
    type: "TESTS_BASE_GET_TESTS",
    payload,
  };
};

export const testsBaseGetTestsSuccess = (payload: testsBaseGetTestsSuccessPayload): testsBaseGetTestsSuccessAction => {
  return {
    type: "TESTS_BASE_GET_TESTS_SUCCESS",
    payload,
  };
};

export const testsBaseGetTestsFailure = (payload: ErrorsPayload): testsBaseGetTestsFailureAction => {
  return {
    type: "TESTS_BASE_GET_TESTS_FAILURE",
    payload,
  };
};

// Get All Tests Next Page

export const testsBaseGetTestsNextPage = (payload: testsBaseGetTestsNextPagePayload): testsBaseGetTestsNextPageAction => {
  return {
    type: "TESTS_BASE_GET_TESTS_NEXT_PAGE",
    payload,
  };
};

export const testsBaseGetTestsNextPageSuccess = (payload: testsBaseGetTestsNextPageSuccessPayload): testsBaseGetTestsNextPageSuccessAction => {
  return {
    type: "TESTS_BASE_GET_TESTS_NEXT_PAGE_SUCCESS",
    payload,
  };
};

export const testsBaseGetTestsNextPageFailure = (payload: ErrorsPayload): testsBaseGetTestsNextPageFailureAction => {
  return {
    type: "TESTS_BASE_GET_TESTS_NEXT_PAGE_FAILURE",
    payload,
  };
};

// Get Test From Tests Base

export const getTestFromTestsBase = (payload: getTestFromTestsBasePayload): getTestFromTestsBaseAction => {
  return {
    type: "GET_TEST_FROM_TESTS_BASE",
    payload,
  };
};

export const getTestFromTestsBaseSuccess = (payload: getTestFromTestsBaseSuccessPayload): getTestFromTestsBaseSuccessAction => {
  return {
    type: "GET_TEST_FROM_TESTS_BASE_SUCCESS",
    payload,
  };
};

export const getTestFromTestsBaseFailure = (payload: ErrorsPayload): getTestFromTestsBaseFailureAction => {
  return {
    type: "GET_TEST_FROM_TESTS_BASE_FAILURE",
    payload,
  };
};

// Publish Test in Tests Base

export const publishTestInTestsBase = (payload: publishTestInTestsBasePayload): publishTestInTestsBaseAction => {
  return {
    type: "PUBLISH_TEST_IN_TESTS_BASE",
    payload,
  };
};

export const  publishTestInTestsBaseSuccess = (): publishTestInTestsBaseSuccessAction => {
  return {
    type: "PUBLISH_TEST_IN_TESTS_BASE_SUCCESS",
  };
};

export const publishTestInTestsBaseFailure = (payload: ErrorsPayload): publishTestInTestsBaseFailureAction => {
  return {
    type: "PUBLISH_TEST_IN_TESTS_BASE_FAILURE",
    payload,
  };
};

// Duplicate a Test from Tests Base

export const duplicateTestFromTestsBase = (payload: duplicateTestFromTestsBasePayload, callback?: () => any): duplicateTestFromTestsBaseAction => {
  return {
    type: "DUPLICATE_TEST_FROM_TESTS_BASE",
    payload,
    callback,
  };
};

export const  duplicateTestFromTestsBaseSuccess = (callback?: () => any): duplicateTestFromTestsBaseSuccessAction => {
  if (callback) {
    callback();
  }

  return {
    type: "DUPLICATE_TEST_FROM_TESTS_BASE_SUCCESS",
  };
};

export const duplicateTestFromTestsBaseFailure = (payload: ErrorsPayload): duplicateTestFromTestsBaseFailureAction => {
  return {
    type: "DUPLICATE_TEST_FROM_TESTS_BASE_FAILURE",
    payload,
  };
};
