import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import translate from "../../../../services/translate";
import { calculateDaysTill } from "../../../../services/common";

// Types
import { userStateModel } from "../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
}

export const PaymentBox = (props: Props) => {
  const [daysTillOver, setDaysTillOver] = useState<number>(0);

  useEffect(() => {
    calculateDaysTillOver();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateDaysTillOver = () => {
    const expires_at = moment(props.user.access.expires_at, "YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    const daysTillSubscriptionOver = Math.ceil(
      calculateDaysTill(expires_at, today),
    );

    if (daysTillSubscriptionOver >= 0) {
      setDaysTillOver(daysTillSubscriptionOver);
    }
  };

  return (
    <div className={styles.paymentsBox}>
      <h3>{translate("global.premium-account")}</h3>
      <div className={styles.info}>
        <span>{daysTillOver}</span>
        <p>{translate("global.days-till-subscription-over-two")}</p>
      </div>
      <Link to="/profile/billing">{translate("global.extend-validity")}</Link>
    </div>
  );
};

export default PaymentBox;
