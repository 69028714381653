import React from "react";

import translate from "../../../../../../services/translate";
import { getSelectedClassName } from "../../../../../../services/common/test";

import QuestionPreview from "../Question";

// Types
import { classModel } from "../../../../../../models/redux/teacher";
import { questionModel } from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";
import testInstructionStyles from "../../../../../../styles/Test/Instruction/styles.module.scss";

interface Props {
  classID: string;
  subjectName: string;
  testName: string;
  classes: classModel[];
  questions: questionModel[];
  reloadPreview?: () => any;
  hideInstruction: boolean;
  isCompact: boolean;
}

export const PreviewHiddenTest = (props: Props) => {
  return (
    <div className={styles.hidden_a4} id="hidden_a4">
      <div id="testTopHidden">
        <div className={styles.testInfo} id="testInfoHeaderHidden">
          <div className={styles.top}>
            <div className={styles.textContainer}>
              <span className={styles.darkGrey}>
                {translate("class.class")}:&nbsp;
              </span>
              <span className={styles.violet}>
                {getSelectedClassName(props.classes, props.classID)}
              </span>
            </div>
            <div className={styles.textContainer}>
              <span className={styles.darkGrey}>
                {translate("global.subject")}:&nbsp;
              </span>
              <span className={styles.violet}>{props.subjectName}</span>
            </div>
          </div>
          <div className={styles.bottom}>
            <h1>{props.testName}</h1>
          </div>
        </div>
        {!props.hideInstruction && (
          <div
            className={testInstructionStyles.instruction}
            id="testInfoInstructionHidden"
          >
            <h2>{translate("creator.test-instruction-heading")}</h2>
            <p>
              <span>{translate("creator.test-instruction-desc-1")}&nbsp;</span>
              <span>{translate("creator.test-instruction-desc-2")}</span>
              <i></i>
              <span>{translate("creator.test-instruction-desc-3")}</span>
              <span className={testInstructionStyles.circle}>
                <i></i>
              </span>
              <span>{translate("creator.test-instruction-desc-4")}</span>
            </p>
          </div>
        )}
      </div>
      <div id="preview-test-container">
        {props.questions.map((question, index) => (
          <div
            key={`preview_item_${question.id}_${index}`}
            id={`preview_item_${question.id}`}
          >
            <QuestionPreview
              index={index}
              isHidden
              question={question}
              reloadPreview={props.reloadPreview}
              isCompact={props.isCompact}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviewHiddenTest;
