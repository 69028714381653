import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
// import queryString from "query-string";
import pathOr from "ramda/src/pathOr";

import { LessonCreatorHeader } from "./Header";
import LessonCreatorEditor from "./Editor";

import translate from "../../../../../services/translate";
// import { getSubjectByType } from "../../../../../services/common/subjects";

import {
  createLesson,
  updateLesson,
  unmountLesson,
  getLesson,
} from "../../../../../redux/actions/lesson";
import { getClass, unmountClass } from "../../../../../redux/actions/class";
import { getSubjects } from "../../../../../redux/actions/teacher";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";
import {
  lessonStateModel,
  createLesson as createLessonFunction,
  updateLesson as updateLessonFunction,
  getLesson as getLessonFunction,
} from "../../../../../models/redux/lesson";
import {
  classStateModel,
  getClass as getClassFunction,
} from "../../../../../models/redux/class";

import styles from "./styles.module.scss";
import { getErrorMessage } from "../../../../../services/common";

interface Props {
  history: History;
  location: Location;
  teacher: teacherStateModel;
  lesson: lessonStateModel;
  createLesson: createLessonFunction;
  class: classStateModel;
  unmountClass: () => {};
  unmountLesson: () => {};
  getClass: getClassFunction;
  getSubjects: getSubjectsFunction;
  match: any;
  updateLesson: updateLessonFunction;
  getLesson: getLessonFunction;
}

export const LessonCreator = (props: Props) => {
  // const parsed = queryString.parse(props.location.search);
  const classID = pathOr("", ["match", "params", "classID"], props);
  const lessonID = pathOr("", ["match", "params", "lessonID"], props);

  const [lessonTopic, setLessonTopic] = useState("");
  const [lessonDescription, setLessonDescription] = useState({
    html: "",
    plain: "",
  });
  const [lessonGoal, setLessonGoal] = useState({
    html: "",
    plain: "",
  });
  const [lessonTasks, setLessonTasks] = useState({
    html: "",
    plain: "",
  });
  const [lessonHomework, setLessonHomework] = useState({
    html: "",
    plain: "",
  });
  const [lessonSuccessCriteria, setLessonSuccessCriteria] = useState({
    html: "",
    plain: "",
  });

  useEffect(() => {
    props.getClass({ id: classID });
    props.getSubjects();

    if (lessonID) {
      props.getLesson({ lesson_id: lessonID });
    }

    return () => {
      props.unmountClass();
      props.unmountLesson();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLessonTopic(props.lesson.topic);
    setLessonDescription({
      html: pathOr("", ["lesson", "description"], props),
      plain: "",
    });
    setLessonGoal({
      html: pathOr("", ["lesson", "goal"], props),
      plain: "",
    });
    setLessonTasks({
      html: pathOr("", ["lesson", "tasks"], props),
      plain: "",
    });
    setLessonHomework({
      html: pathOr("", ["lesson", "homework"], props),
      plain: "",
    });
    setLessonSuccessCriteria({
      html: pathOr("", ["lesson", "success_criteria"], props),
      plain: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.lesson.topic,
    props.lesson.description,
    props.lesson.goal,
    props.lesson.tasks,
    props.lesson.homework,
    props.lesson.success_criteria,
  ]);

  const getError = (source: string) =>
    getErrorMessage(props.lesson.errors, source);

  return (
    <section className={styles.container}>
      <h1>
        <span>{translate("global.lesson")}</span>
      </h1>
      <LessonCreatorHeader
        history={props.history}
        lessonTopic={lessonTopic}
        setLessonTopic={setLessonTopic}
        className={props.class.name}
        subjectName={props.class.subject.name}
        errors={props.lesson.errors}
      />
      <div className={styles.content}>
        <div className={styles.box}>
          <h2>
            <span>{translate("lessons.lesson-goal")}</span> (
            {translate("global.optional")})
          </h2>
          <LessonCreatorEditor
            history={props.history}
            location={props.location}
            value={lessonGoal}
            handleOnChange={setLessonGoal}
            hasError={!!getError("goal")}
          />
          {getError("goal") && (
            <span className={styles.error}>{getError("goal")}</span>
          )}
        </div>
        <div className={styles.box}>
          <h2>
            <span>{translate("lessons.lesson-description")}</span> (
            {translate("global.optional")})
          </h2>
          <LessonCreatorEditor
            history={props.history}
            location={props.location}
            value={lessonDescription}
            handleOnChange={setLessonDescription}
            hasError={!!getError("description")}
          />
          {getError("description") && (
            <span className={styles.error}>{getError("description")}</span>
          )}
        </div>
        <div className={styles.box}>
          <h2>
            <span>{translate("lessons.lesson-success-criteria")}</span> (
            {translate("global.optional")})
          </h2>
          <LessonCreatorEditor
            history={props.history}
            location={props.location}
            value={lessonSuccessCriteria}
            handleOnChange={setLessonSuccessCriteria}
            hasError={!!getError("success_criteria")}
          />
          {getError("goal") && (
            <span className={styles.error}>{getError("success_criteria")}</span>
          )}
        </div>
        <div className={styles.box}>
          <h2>
            <span>{translate("lessons.lesson-tasks")}</span> (
            {translate("global.optional")})
          </h2>
          <LessonCreatorEditor
            history={props.history}
            location={props.location}
            value={lessonTasks}
            handleOnChange={setLessonTasks}
            hasError={!!getError("tasks")}
          />
          {getError("tasks") && (
            <span className={styles.error}>{getError("tasks")}</span>
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={styles.submitButton}
          disabled={
            props.lesson.in_progress.create_lesson ||
            props.lesson.in_progress.update_lesson
          }
          onClick={() => {
            if (lessonID) {
              props.updateLesson({
                lesson_id: lessonID,
                class_id: props.class.id,
                topic: lessonTopic,
                description: lessonDescription.html,
                goal: lessonGoal.html,
                tasks: lessonTasks.html,
                homework: lessonHomework.html,
                success_criteria: lessonSuccessCriteria.html,
                history: props.history,
              });
              return;
            }
            props.createLesson({
              class_id: props.class.id,
              subject_id: props.class.subject.id,
              topic: lessonTopic,
              description: lessonDescription.html,
              goal: lessonGoal.html,
              tasks: lessonTasks.html,
              homework: lessonHomework.html,
              success_criteria: lessonSuccessCriteria.html,
              history: props.history,
            });
          }}
        >
          <span>
            {lessonID
              ? translate("lessons.update-lesson")
              : translate("lessons.create-lesson")}
          </span>
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  lesson: lessonStateModel;
  classReducer: classStateModel;
}) => ({
  teacher: state.teacher,
  lesson: state.lesson,
  class: state.classReducer,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createLesson,
      getClass,
      unmountClass,
      unmountLesson,
      getSubjects,
      updateLesson,
      getLesson,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonCreator);
