import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import StudentDetailsReports from "./Reports";

import Modal, { ToggleContent } from "../../../../components/Common/Modal";

import { ReactComponent as TrashIcon } from "../../../../assets/images/trash.svg";
import { ReactComponent as TrashAltIcon } from "../../../../assets/images/trash_alt.svg";

import {
  deleteStudent,
  getStudentDetails,
} from "../../../../redux/actions/class";

import translate from "../../../../services/translate";

// Types
import { History } from "history";
import {
  classStateModel,
  deleteStudent as deleteStudentFunction,
  getStudentDetails as getStudentDetailsFunction,
} from "../../../../models/redux/class";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  match: any;
  class: classStateModel;
  deleteStudent: deleteStudentFunction;
  getStudentDetails: getStudentDetailsFunction;
}

export const StudentDetails = (props: Props) => {
  useEffect(() => {
    props.getStudentDetails({
      studentID: props.match.params.studentID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          {translate("students.student-number")}{" "}
          {props.class.studentDetails.number}
        </h3>
        <ToggleContent
          toggle={(show: any) => (
            <button type="button" onClick={show}>
              <TrashIcon/>
              &nbsp;
              <span>{translate("class.delete-student")}</span>
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.deleteStudentModal}>
                <TrashAltIcon/>
                <span>{translate("class.delete-student-confirmation")}</span>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      hide();
                      props.deleteStudent({
                        classID: props.match.params.classID,
                        studentID: props.match.params.studentID,
                      });
                      props.history.push(
                        `/class/${props.match.params.classID}/students`,
                      );
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
      </div>
      <div className={styles.content}>
        <span>{translate("class.reports")}</span>
        <div className={styles.reportsContainer}>
          <StudentDetailsReports
            reports={props.class.studentDetails.reports}
            match={props.match}
          />
        </div>
        <div className={styles.box}>
          <span>{translate("class.average-score-from-all-tests")}</span>
          <span>{props.class.studentDetails.percentage_points}%</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { classReducer: classStateModel }) => ({
  class: state.classReducer,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      deleteStudent,
      getStudentDetails,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails);
