import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Route, Switch } from "react-router-dom";

import { getClasses } from "../../redux/actions/teacher";

import Dashboard from "./Dashboard";
import Header from "./Header";
import BottomBar from "./BottomBar";
import Sidebar from "./Sidebar";
import PickUpSubjects from "./PickUpSubjects";
import AddClass from "./Class/Add";
import AddClassSuccess from "./Class/Add/Success";
import TestsClass from "./Class/Tests";
import StudentsClass from "./Class/Students";
import StudentsDetails from "./Class/StudentDetails";
import TestCreator from "./Test/Creator";
import SelectTestType from "./Test/Creator/SelectTestType";
import QuestionsBase from "./Test/Creator/QuestionsBase";
import SelectSubjectForTest from "./Test/Creator/SelectSubject";
import SelectTimeForTest from "./Test/Creator/SelectTime";
import SelectClassForTest from "./Test/Creator/SelectClass";
import PreviewTest from "./Test/Creator/Preview";
import GeneratePDF from "./Test/Creator/GeneratePDF";
import Classes from "./Classes";
import Class from "./Class";
import ClassCurriculumAdd from "./Class/Curriculum/Add";
import ClassCurriculumDetails from "./Class/Curriculum/Details";
import ProfileEdit from "./Profile/Edit";
import ProfileBilling from "./Profile/Billing";
import TestReportClass from "./Test/Report/Class";
import TestReportStudent from "./Test/Report/Student";
import TestReportQuestionDetails from "./Test/Report/QuestionDetails";
import TestDetails from "./Test/Details";
import Tests from "./Tests";
import Referral from "./Referral";
import TestsBase from "./TestsBase";
import PlanLimit from "./PlanLimit";
import FormativeAssessment from "./FormativeAssessment";
import LessonsClass from "./Class/Lessons";
import LessonCreator from "./Class/Lessons/Creator";
import LessonDetails from "./Class/Lessons/Details";

// Types
import { Location } from "history";
import {
  teacherStateModel,
  getClasses as getClassesFunction,
  classModel,
} from "../../models/redux/teacher";
import { userStateModel } from "../../models/redux/user";
import { appStateModel } from "../../models/redux/app";

import styles from "./styles.module.scss";

interface Props {
  history: any;
  location: Location;
  getClasses: getClassesFunction;
  classes: classModel[];
  user: userStateModel;
}

export const App = (props: Props) => {
  useEffect(() => {
    props.getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.app}>
      <Sidebar
        classes={props.classes}
        location={props.location}
        history={props.history}
        user={props.user}
      />
      <section className={styles.container}>
        <Header classes={props.classes} history={props.history}/>
        <div className={styles.view}>
          <div className={styles.innerView} id="innerView">
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route
                exact
                path="/pick-up-subjects/:id"
                component={PickUpSubjects}
              />
              <Route exact path="/profile/edit" component={ProfileEdit}/>
              <Route exact path="/profile/billing" component={ProfileBilling}/>
              <Route exact path="/class/add" component={AddClass}/>
              <Route
                exact
                path="/class/add/success"
                component={AddClassSuccess}
              />
              <Route exact path="/classes" component={Classes}/>
              <Route exact path="/class/:id" component={Class}/>
              <Route
                exact
                path="/class/curriculum/add"
                component={ClassCurriculumAdd}
              />
              <Route
                exact
                path="/class/curriculum/:id"
                component={ClassCurriculumDetails}
              />
              <Route exact path="/class/:id/tests" component={TestsClass}/>
              <Route
                exact
                path="/class/:id/students"
                component={StudentsClass}
              />
              <Route
                exact
                path="/class/:classID/student/:studentID"
                component={StudentsDetails}
              />
              <Route
                exact
                path="/class/:classID/lessons"
                component={LessonsClass}
              />
              <Route
                exact
                path="/class/:classID/lessons/add"
                component={LessonCreator}
              />
              <Route
                exact
                path="/class/:classID/lessons/:lessonID"
                component={LessonDetails}
              />
              <Route
                exact
                path="/class/:classID/lessons/:lessonID/edit"
                component={LessonCreator}
              />
              <Route exact path="/test/creator" component={TestCreator}/>
              <Route
                exact
                path="/test/creator/questions-base"
                component={QuestionsBase}
              />
              <Route exact path="/test" component={TestDetails}/>
              <Route
                exact
                path="/test/:id/report/class"
                component={TestReportClass}
              />
              <Route
                exact
                path="/test/:id/report/student/:studentID"
                component={TestReportStudent}
              />
              <Route
                exact
                path="/test/:testID/report/class/:classID/question/:questionNumber"
                component={TestReportQuestionDetails}
              />
              <Route
                exact
                path="/test/creator/select-type"
                component={SelectTestType}
              />
              <Route
                exact
                path="/test/creator/select-class"
                component={SelectClassForTest}
              />
              <Route
                exact
                path="/test/creator/select-subject"
                component={SelectSubjectForTest}
              />
              <Route
                exact
                path="/test/creator/select-time"
                component={SelectTimeForTest}
              />
              <Route
                exact
                path="/test/creator/preview"
                component={PreviewTest}
              />
              <Route
                exact
                path="/test/creator/generate-pdf"
                component={GeneratePDF}
              />
              <Route exact path="/tests/all" component={Tests}/>
              <Route exact path="/tests-base" component={TestsBase}/>
              <Route exact path="/referral" component={Referral}/>
              <Route exact path="/plan-limit/:type" component={PlanLimit}/>
              {/* <Route
                exact
                path="/pricing"
                component={Pricing}
              /> */}
              <Route
                exact
                path="/formative-assessment"
                component={FormativeAssessment}
              />
            </Switch>
          </div>
        </div>
        <BottomBar history={props.history} user={props.user}/>
      </section>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  app: appStateModel;
  user: userStateModel;
}) => ({
  classes: state.teacher.classes,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
