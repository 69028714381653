import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import translate from "../../../services/translate";

import {
  getResources,
  unmountResources,
} from "../../../redux/actions/resources";

// Types
import { History } from "history";
import {
  resourcesStateModel,
  getResources as getResourcesFunction,
  resourceType,
} from "../../../models/redux/resources";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  resources: resourcesStateModel;
  getResources: getResourcesFunction;
  unmountResources: () => any;
}

export const FormativeAssessment = (props: Props) => {
  useEffect(() => {
    props.getResources({ type: resourceType.formativeAssessment });

    return () => {
      props.unmountResources();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <h1>{translate("global.formative-assessment")}</h1>
      <div className={styles.topContainer}>
        {props.resources.list.map((item) => (
          <div className={styles.box}>
            <img src={item.image} alt={item.name}></img>
            <a href={item.file} target="_blank" rel="noopener noreferrer">
              <span>{translate("test.download-pdf")}</span>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state: { resources: resourcesStateModel }) => ({
  resources: state.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getResources,
      unmountResources,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormativeAssessment);
