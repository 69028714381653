import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import pathOr from "ramda/src/pathOr";

import QuestionHeaderOnline from "../Header";
import QuestionImageOnline from "../Image";

// Types
import {
  QuestionTypes,
  questionModel,
  answerChoiceModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  question: questionModel;
  checkedAnswerChoices: number[];
  onAnswerChoiceClick: (order: number) => any;
  isReadOnly?: boolean;
}

export const QuestionTrueFalseOnline = (props: Props) => {
  const isTrueFalse = () => {
    return props.question.type === QuestionTypes.TRUE_FALSE;
  };

  const trueFalseAnswerClass = (order: number) => classNames({
    [styles.trueFalseAnswer]: true,
    [styles.trueFalseAnswerCorrect]: props.checkedAnswerChoices.includes(order),
  });

  return (
    <div className={styles.mainContainer}>
      <QuestionHeaderOnline question={props.question}/>
      <QuestionImageOnline question={props.question}/>
      {isTrueFalse() &&
        <div data-type="question-answers-tf" className={styles.trueFalse}>
          <div
            className={`${styles.trueFalseBody} questionBodyGlobal`}
            dangerouslySetInnerHTML={{
              __html: `${props.question.body_html ?
                DOMPurify.sanitize(props.question.body_html) :
                DOMPurify.sanitize(pathOr("", ["question", "body"], props))}`,
            }}
          ></div>
          <div className={styles.trueFalseAnswerContainer}>
            {props.question.answer_choices.map((item: answerChoiceModel) => (
              <button
                type="button"
                key={item.id}
                className={trueFalseAnswerClass(item.order)}
                onClick={() => {
                  if (props.isReadOnly) {
                    return;
                  }
                  props.onAnswerChoiceClick(item.order);
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default QuestionTrueFalseOnline;
