import React, { useState } from "react";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "../../../../assets/images/small_arrow_down_grey.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/images/referral/checkmark.svg";
import { ReactComponent as LoadingIcon } from "../../../../assets/images/referral/loading.svg";

import translate from "../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  list: any[];
}

export const ReferralList = (props: Props) => {
  const [referralsInfo, setReferralsInfo] = useState<string[]>([]);

  const arrowClass = (email: string) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: referralsInfo.includes(email),
    });

  const toggleArrow = (email: string) => {
    const isChecked = referralsInfo.includes(email);

    if (isChecked) {
      setReferralsInfo(referralsInfo.filter((value) => value !== email));
      return;
    }

    setReferralsInfo(referralsInfo.concat(email));
  };

  const getRegistrationStatus = (registeredAt: string, confirmedAt: string) => {
    if (registeredAt) {
      if (confirmedAt) {
        return (
          <span className={styles.greenText}>
            <CheckmarkIcon/>
            &nbsp;{translate("global.referral-status-finished")}
          </span>
        );
      }
      return (
        <span className={styles.navyBlueText}>
          <LoadingIcon/>
          &nbsp;{translate("global.referral-status-in-progress")}
        </span>
      );
    }
    return "-";
  };

  return (
    <ul className={styles.referrals}>
      <li className={styles.headingItem}>
        <div className={styles.identificationNumber}>
          <span>{translate("global.identification-number-short")}</span>
        </div>
        <div className={styles.emailAddress}>
          <span>{translate("global.email-address")}</span>
        </div>
        <div className={styles.signUpDate}>
          <span>{translate("global.sign-up-date")}</span>
        </div>
        <div className={styles.signUpConfirmationDate}>
          <span>{translate("global.sign-up-confirmation-date")}</span>
        </div>
        <div className={styles.registrationStatus}>
          <span>{translate("global.registration-status")}</span>
        </div>
        <div className={styles.discountReceived}>
          <span>{translate("global.discount-received")}</span>
        </div>
        <div className={styles.actions}></div>
      </li>
      {props.list.map((item: any, index: number) => (
        <li className={styles.listItem} key={`${item.email}_${index}`}>
          <div className={styles.identificationNumber}>
            <span>{index + 1}</span>
          </div>
          <div className={styles.emailAddress}>
            <span>{item.email}</span>
          </div>
          <div className={styles.signUpDate}>
            <span>{item.registered_at}</span>
          </div>
          <div className={styles.signUpConfirmationDate}>
            <span>{item.confirmed_at ? item.confirmed_at : "-"}</span>
          </div>
          <div className={styles.registrationStatus}>
            <span>
              {getRegistrationStatus(item.registered_at, item.confirmed_at)}
            </span>
          </div>
          <div className={styles.discountReceived}>
            <span>{item.discount ? `${item.discount}%` : "-"}</span>
          </div>
          <div className={styles.actions}>
            <button
              className={arrowClass(item.email)}
              onClick={() => toggleArrow(item.email)}
            >
              <ArrowIcon/>
            </button>
          </div>
          {referralsInfo.includes(item.email) && (
            <div className={styles.dropDownContainer}>
              <ul>
                <li className={styles.signUpDate}>
                  <span>{translate("global.sign-up-date")}</span>
                  <span>{item.registered_at}</span>
                </li>
                <li className={styles.signUpConfirmationDate}>
                  <span>{translate("global.sign-up-confirmation-date")}</span>
                  <span>{item.confirmed_at ? item.confirmed_at : "-"}</span>
                </li>
                <li className={styles.registrationStatus}>
                  <span>{translate("global.registration-status")}</span>
                  <span>
                    {getRegistrationStatus(
                      item.registered_at,
                      item.confirmed_at,
                    )}
                  </span>
                </li>
                <li className={styles.discountReceived}>
                  <span>{translate("global.discount-received")}</span>
                  <span>{item.discount ? `${item.discount}%` : "-"}</span>
                </li>
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default ReferralList;
