import React from "react";
import classNames from "classnames";

import { getEducationLevelIcon } from "../../../../../services/common/getEducationLevelIcon";

import styles from "./styles.module.scss";

interface Props {
  onClick: (id: string) => any;
  id: string;
  level: string;
  isChecked: boolean;
}

const EducationLevelBox = (props: Props) => {
  const boxClass = classNames({
    [styles.box]: true,
    [styles.active]: props.isChecked,
  });

  return (
    <button
      type="button"
      className={boxClass}
      onClick={() => props.onClick(props.id)}
    >
      {getEducationLevelIcon(props.level)}
      <span>{props.level}</span>
    </button>
  );
};

export default EducationLevelBox;
