import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal, { ToggleContent } from "../../../../../components/Common/Modal";
import Input from "../../../../Common/Input";
import CountriesSelect from "../../../../Common/CountriesSelect";
import LanguagesSelect from "../../../../Common/LanguagesSelect";
import ChangePassword from "./ChangePassword";

import translate from "../../../../../services/translate";
import {
  countries,
  getCountryName,
} from "../../../../../services/common/countries";
import {
  languages,
  getLanguageName,
} from "../../../../../services/common/languages";

import { getTranslations } from "../../../../../redux/actions/app";
import {
  deleteAccount,
  updateAccount,
  changePassword,
} from "../../../../../redux/actions/auth";

import { ReactComponent as Avatar } from "../../../../../assets/images/avatar.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/images/trash.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../assets/images/trash_alt.svg";

// Types
import { userStateModel } from "../../../../../models/redux/user";
import {
  authStateModel,
  deleteAccount as deleteAccountFunction,
  updateAccount as updateAccountFunction,
  changePassword as changePasswordFunction,
} from "../../../../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
  auth: authStateModel;
  deleteAccount: deleteAccountFunction;
  updateAccount: updateAccountFunction;
  changePassword: changePasswordFunction;
  getTranslations: () => {};
}

export const ProfileEditUser = (props: Props) => {
  const [email, setEmail] = useState<string>("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [language, setLanguage] = useState("");
  const [languageCode, setLanguageCode] = useState("");

  useEffect(() => {
    setUserCountry();
    setUserLanguage();
    setEmail(props.user.email);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEmail(props.user.email);
  }, [props.user.email]);

  useEffect(() => {
    setUserCountry();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.country]);

  useEffect(() => {
    setUserLanguage();
    props.getTranslations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.language]);

  const setUserCountry = () => {
    const country = countries.find(
      (item: any) =>
        item.code.toLocaleLowerCase() === props.user.country.toLocaleLowerCase(),
    ) as any;

    if (country) {
      setCountry(getCountryName(country, props.user.language));
      setCountryCode(props.user.country);
    }
  };

  const setUserLanguage = () => {
    const language = languages.find(
      (item: any) =>
        item.code.toLocaleLowerCase() ===
        props.user.language.toLocaleLowerCase(),
    ) as any;

    if (language) {
      setLanguage(getLanguageName(language, props.user.language));
      setLanguageCode(props.user.language);
    }
  };

  const isLoading = () => props.auth.in_progress.update_account;

  return (
    <>
      <div className={styles.boxUser}>
        <h3>{translate("profile.basic-information")}</h3>
        <div className={styles.avatar}>
          <Avatar/>
        </div>
        <Input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          label={translate("global.email-address")}
          disabled
          error=""
        />
        <LanguagesSelect
          languageCode={languageCode}
          language={language}
          setLanguage={setLanguage}
          setLanguageCode={setLanguageCode}
          user={props.user}
          errors={null}
        />
        <CountriesSelect
          countryCode={countryCode}
          country={country}
          setCountry={setCountry}
          setCountryCode={setCountryCode}
          user={props.user}
          errors={null}
        />
        <ChangePassword
          changePassword={props.changePassword}
          errors={props.auth.errors}
          inProgress={props.auth.in_progress.update_account}
        />
        <ToggleContent
          toggle={(show: any) => (
            <button
              type="button"
              className={styles.deleteAccountButton}
              onClick={show}
            >
              <TrashIcon/>
              &nbsp;
              <span>{translate("profile.delete-account")}</span>
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.deleteAccountModal}>
                <TrashAltIcon/>
                <span>{translate("profile.delete-account-confirmation")}</span>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      hide();
                      props.deleteAccount();
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
      </div>
      <button
        className={styles.saveChangesButton}
        onClick={() =>
          props.updateAccount({
            country: countryCode.toLocaleLowerCase(),
            lang: languageCode.toLocaleLowerCase(),
          })
        }
        disabled={isLoading()}
      >
        {isLoading() ? (
          <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
        ) : (
          <span>{translate("global.save-changes")}</span>
        )}
      </button>
    </>
  );
};

const mapStateToProps = (state: {
  user: userStateModel;
  auth: authStateModel;
}) => ({
  user: state.user,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      deleteAccount,
      updateAccount,
      changePassword,
      getTranslations,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditUser);
