import pathOr from "ramda/src/pathOr";
import concat from "ramda/src/concat";
import isNil from "ramda/src/isNil";

// Types
import { questionsBaseActionModel, questionsBaseStateModel } from "../../../models/redux/questionsBase";

const initialState: questionsBaseStateModel = {
  list: [],
  listMeta: {
    first: 1,
    last: 1,
    next: null,
    prev: null,
  },
  in_progress: {
    search: false,
    duplicate: false,
  },
  downvoted_question_ids: [],
  upvoted_question_ids: [],
};

export const questionsBase = (state = initialState, action: questionsBaseActionModel) => {
  switch (action.type) {
    case "GET_QUESTIONS_FROM_QUESTIONS_BASE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          search: true,
        },
      };
    case "GET_QUESTIONS_FROM_QUESTIONS_BASE_SUCCESS":
      return {
        ...state,
        list: pathOr([], ["payload", "data"], action),
        listMeta: pathOr(initialState.listMeta, ["payload", "meta"], action),
        in_progress: {
          ...state.in_progress,
          search: false,
        },
      };
    case "GET_QUESTIONS_FROM_QUESTIONS_BASE_NEXT_PAGE_SUCCESS":
      return {
        ...state,
        list: concat(state.list, pathOr([], ["payload", "data"], action)),
        listMeta: pathOr(initialState.listMeta, ["payload", "meta"], action),
      };
    case "GET_QUESTIONS_FROM_QUESTIONS_BASE_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          search: false,
        },
      };
    case "DUPLICATE_QUESTIONS_FROM_QUESTIONS_BASE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          duplicate: true,
        },
      };
    case "DUPLICATE_QUESTION_FROM_QUESTIONS_BASE_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          duplicate: false,
        },
      };
    case "GET_USER_VOTES_SUCCESS":
      return {
        ...state,
        downvoted_question_ids: pathOr([], ["payload", "data", "downvoted_question_ids"], action),
        upvoted_question_ids: pathOr([], ["payload", "data", "upvoted_question_ids"], action),
      };
    case "VOTE_FOR_A_QUESTION_SUCCESS":
      const questionID = pathOr("", ["payload", "question_id"], action);
      const questionIndex = state.list.findIndex(item => item.id === questionID);
      const question = state.list[questionIndex];
      const type = pathOr("", ["payload", "type"], action) as string;

      return {
        ...state,
        list: !isNil(questionIndex) ?
          Object.assign(
            [],
            state.list,
            {
              [questionIndex]: {
                ...question,
                upvotes: type === "upvote" ?
                  state.upvoted_question_ids.includes(questionID) ?
                    question.upvotes - 1 :
                    question.upvotes + 1
                  :
                  state.upvoted_question_ids.includes(questionID) ?
                    question.upvotes - 1 :
                    question.upvotes,
                downvotes: type === "downvote" ?
                  state.downvoted_question_ids.includes(questionID) ?
                    question.downvotes - 1 :
                    question.downvotes + 1
                  :
                  state.downvoted_question_ids.includes(questionID) ?
                    question.downvotes - 1 :
                    question.downvotes,
              },
            },
          ) :
          state.list,
      };
    case "UNMOUNT_QUESTIONS_BASE":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
