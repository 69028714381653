import React from "react";

import translate from "../../../../../../../services/translate";

// Types
import { deleteQuestion as deleteQuestionFunction } from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  addNextQuestion: any;
  isLastQuestion: boolean;
  deleteQuestion: deleteQuestionFunction;
  testID: string;
  classID: string;
  questionID: string;
  isCollapsed: boolean;
}

export const QuestionBottom = (props: Props) => {
  return (
    <>
      {props.isLastQuestion && (
        <button
          type="button"
          onClick={() => props.addNextQuestion(true)}
          className={styles.addQuestion}
        >
          <span>+&nbsp;</span>
          <span>{translate("creator.add-next")}</span>
        </button>
      )}
    </>
  );
};

export default QuestionBottom;
