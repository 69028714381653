import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";
import { Link } from "react-router-dom";

import pathOr from "ramda/src/pathOr";
import move from "ramda/src/move";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as DnDTutorialIcon } from "../../../../assets/images/tutorials/dnd.svg";
import { ReactComponent as Pen } from "../../../../assets/images/pen_edit.svg";

import translate from "../../../../services/translate";
import {
  getSelectedClassName,
  getTotalPoints,
} from "../../../../services/common/test";
import {
  setWatchedTutorials,
  isTutorialWatched,
  userFromPL,
} from "../../../../services/common";

import TestCreatorHeader from "./Common/Header";
import TestQuestionTypes from "./Common/QuestionTypes";
import AddQuestionFromQuestionsBase from "./Common/AddQuestionFromQuestionsBase";
import Question from "./Common/Question";
import QuestionHeader from "./Common/Question/Header";

import Modal, { ToggleContent } from "../../../Common/Modal";

import GeneratePDF from "./GeneratePDF";
import DownloadTestModal from "../../../Common/DownloadTestModal";

import { getSubjects } from "../../../../redux/actions/teacher";
import {
  getQuestionTypes,
  getTest,
  updateTest,
  updateQuestion,
  unmountTest,
  deleteQuestion,
  createQuestionAnswerChoice,
  updateQuestionAnswerChoice,
  deleteQuestionAnswerChoice,
  createQuestionMatching,
  updateQuestionMatching,
  deleteQuestionMatching,
  generatePDF,
  validateTest,
  deleteQuestionImage,
  replaceQuestionGap,
} from "../../../../redux/actions/test";
import { publishTestInTestsBase } from "../../../../redux/actions/testsBase";
import { unmountCurriculum } from "../../../../redux/actions/curriculum";
import { showToast } from "../../../../redux/actions/app";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  subjectModel,
  classModel,
  getSubjects as getSubjectsFunction,
} from "../../../../models/redux/teacher";
import {
  testStateModel,
  questionModel,
  updateTest as updateTestFunction,
  updateQuestion as updateQuestionFunction,
  getQuestionTypes as getQuestionTypesFunction,
  getTest as getTestFunction,
  deleteQuestion as deleteQuestionFunction,
  createQuestionAnswerChoice as createQuestionAnswerChoiceFunction,
  updateQuestionAnswerChoice as updateQuestionAnswerChoiceFunction,
  deleteQuestionAnswerChoice as deleteQuestionAnswerChoiceFunction,
  createQuestionMatching as createQuestionMatchingFunction,
  updateQuestionMatching as updateQuestionMatchingFunction,
  deleteQuestionMatching as deleteQuestionMatchingFunction,
  generatePDF as generatePDFFunction,
  validateTest as validateTestFunction,
  deleteQuestionImage as deleteQuestionImageFunction,
  replaceQuestionGap as replaceQuestionGapFunction,
  answerChoiceModel,
  testTypes,
  matchingModel,
} from "../../../../models/redux/test";
import { userStateModel, userRoles } from "../../../../models/redux/user";
import { publishTestInTestsBase as publishTestInTestsBaseFunction } from "../../../../models/redux/testsBase";
import { unmountCurriculum as unmountCurriculumFunction } from "../../../../models/redux/curriculum";

import styles from "./styles.module.scss";
import SelectTimeContent from "./Common/SelectTimeContent";

interface Props {
  history: History;
  location: Location;
  classes: classModel[];
  subjects: subjectModel[];
  test: testStateModel;
  getSubjects: getSubjectsFunction;
  updateTest: updateTestFunction;
  updateQuestion: updateQuestionFunction;
  getQuestionTypes: getQuestionTypesFunction;
  getTest: getTestFunction;
  unmountTest: () => {};
  deleteQuestion: deleteQuestionFunction;
  createQuestionAnswerChoice: createQuestionAnswerChoiceFunction;
  updateQuestionAnswerChoice: updateQuestionAnswerChoiceFunction;
  deleteQuestionAnswerChoice: deleteQuestionAnswerChoiceFunction;
  createQuestionMatching: createQuestionMatchingFunction;
  updateQuestionMatching: updateQuestionMatchingFunction;
  deleteQuestionMatching: deleteQuestionMatchingFunction;
  generatePDF: generatePDFFunction;
  validateTest: validateTestFunction;
  deleteQuestionImage: deleteQuestionImageFunction;
  publishTestInTestsBase: publishTestInTestsBaseFunction;
  user: userStateModel;
  unmountCurriculum: unmountCurriculumFunction;
  showToast: any;
  replaceQuestionGap: replaceQuestionGapFunction;
  isDemo?: boolean;
}

export const TestCreator = (props: Props) => {
  const [showQuestionTypes, setQuestionTypesVisibility] = useState(false);
  const [questions, setQuestions] = useState([] as questionModel[]);
  const [collapsedQuestions, collapse] = useState([] as string[]);
  const [isCompact, setIsCompact] = useState(false);
  const [hideInstruction, setHideInstruction] = useState(false);
  const [dndEnabled, setDndEnabled] = useState(false);

  const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const selectedSubjectType = parsed.subject as string;
  const selectedTestType = parsed.type as string;
  const selectedTime = parsed.time as string;
  const testID = parsed.testID as string;
  let prevQuestionLength = usePrevious(
    pathOr([], ["test", "questions"], props).length,
  );
  let prevQuestionID = usePrevious(pathOr("", ["test", "id"], props));
  let prevErrorsLength = usePrevious(
    pathOr([], ["test", "errors"], props).length,
  );

  const currentClass = props.classes.find((item) => item.id === classID);

  const redirect = () => {
    if (currentClass && !currentClass.subject.id) {
      props.history.replace(`/pick-up-subjects/${classID}`);
      return;
    }

    if (!classID) {
      props.history.replace(
        `/test/creator/select-class${props.history.location.search}`,
      );
      return;
    }

    if (!selectedTestType) {
      props.history.replace(
        `/test/creator/select-type${props.history.location.search}`,
      );
      return;
    }

    // if (!selectedSubjectType) {
    //   props.history.replace(
    //     `/test/creator/select-subject${props.history.location.search}`
    //   );
    //   return;
    // }

    if (selectedTime === undefined && selectedTestType === "online") {
      props.history.replace(
        `/test/creator/select-time${props.history.location.search}`,
      );
      return;
    }
  };

  useEffect(() => {
    redirect();
    props.getSubjects();
    props.getQuestionTypes({
      type: selectedTestType ? selectedTestType : "paper",
    });

    if (classID && testID) {
      props.getTest({
        classID,
        testID,
      });
    }

    setQuestions(props.test.questions);

    return () => {
      props.unmountTest();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQuestions(props.test.questions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.questions]);

  useEffect(() => {
    redirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location]);

  useEffect(() => {
    if (showQuestionTypes) {
      const questionTypesNode = document.querySelector(
        "[class*=questionTypes]",
      );

      if (questionTypesNode) {
        questionTypesNode.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQuestionTypes]);

  useEffect(() => {
    if (
      prevQuestionID &&
      Number(prevQuestionLength) < props.test.questions.length
    ) {
      const questionContainerNodes = document.querySelectorAll(
        "[class*=questionContainer]",
      );
      const questionContainerArray = Array.from(questionContainerNodes);

      const lastQuestion =
        questionContainerArray[questionContainerArray.length - 1];

      if (lastQuestion) {
        const textareaNode = lastQuestion.querySelector("textarea");
        if (textareaNode) {
          textareaNode.focus();
          textareaNode.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.questions.length]);

  useEffect(() => {
    let collapsedQuestionsUpdated = Object.assign([], collapsedQuestions);
    collapsedQuestions.forEach((questionID) => {
      const pointers = props.test.errors.map(
        (error: any) => error.source.pointer,
      );
      const foundID = pointers.find((item) => item.includes(questionID));

      if (foundID) {
        collapsedQuestionsUpdated = collapsedQuestionsUpdated.filter(
          (item) => item !== questionID,
        );
      } else {
        props.test.questions.forEach((question) => {
          question.answer_choices.forEach((answerChoice) => {
            const foundAnswerChoiceID = pointers.find((item) =>
              item.includes(answerChoice.id),
            );

            if (foundAnswerChoiceID) {
              collapsedQuestionsUpdated = collapsedQuestionsUpdated.filter(
                (item) => item !== question.id,
              );
            }
          });
        });
      }
    });

    collapse(collapsedQuestionsUpdated);

    setTimeout(() => scrollToError(), 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.errors]);

  const scrollToError = () => {
    if (props.test.errors.length && !prevErrorsLength) {
      const firstErrorElement =
        (document.querySelector("[class*=error]") as HTMLTextAreaElement) ||
        HTMLInputElement;
      if (firstErrorElement && firstErrorElement.closest) {
        const container = firstErrorElement.closest(
          "[class*=questionContainer]",
        );
        if (container) {
          container.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  };

  const getSelectedSubjectName = () => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === selectedSubjectType,
    );

    if (selectedSubject) {
      return selectedSubject.name;
    }
    return props.test.subject.name;
  };

  const finish = (show: any) => {
    show();
  };

  const inProgressGeneratePDF = () =>
    pathOr(false, ["test", "in_progress", "generate_pdf"], props);

  const onDragEnd = (e: any) => {
    if (e && e.destination) {
      const questionID = e.draggableId;
      const order = e.destination.index + 1;

      setQuestions(move(e.source.index, e.destination.index, questions));

      if (order) {
        props.updateQuestion({
          classID: classID,
          testID: props.test.id,
          questionID,
          order,
        });
      }
    }
  };

  return (
    <section className={styles.container}>
      <div
        style={{
          opacity: 0,
          position: "absolute",
          zIndex: -999,
          transform: "scale(0.1)",
          top: 0,
        }}
      >
        <GeneratePDF
          history={props.history}
          location={props.location}
          isCompact={isCompact}
          hideInstruction={hideInstruction}
        />
      </div>
      <TestCreatorHeader
        history={props.history}
        testID={testID}
        classID={classID}
        selectedSubjectType={selectedSubjectType}
        selectedClassName={getSelectedClassName(props.classes, classID)}
        selectedSubjectName={getSelectedSubjectName()}
        selectedTestType={selectedTestType}
        selectedTime={selectedTime}
      />
      <div className={styles.summaryBox}>
        <span>{translate("global.summary")}:</span>
        <div>
          {(selectedTime || `${selectedTime}` === "null") && (
            <div className={styles.textContainer}>
              <span className={styles.darkGrey}>
                {translate("creator.time-limit-1")}{" "}
                {translate("creator.time-limit-2")}
                :&nbsp;
              </span>
              <span className={styles.violet}>
                {`${selectedTime}` === "null"
                  ? translate("creator.no-limit")
                  : `${selectedTime}min`}
              </span>
              <ToggleContent
                toggle={(show: any) => (
                  <button
                    type="button"
                    onClick={show}
                    className={styles.editTimeLimitButton}
                  >
                    <Pen/>
                  </button>
                )}
                content={(hide: () => {}) => (
                  <Modal hide={hide} customStyles={styles.editTimeLimitModal}>
                    <SelectTimeContent
                      history={props.history}
                      location={props.location}
                      defaultValue={parsed.time as string}
                      bottomContent={(
                        customSelectedTime: string,
                        selectedTime: string,
                      ) => (
                        <div
                          className={styles.editTimeLimitModalBottomContainer}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              const updatedTimeLimit = customSelectedTime
                                ? customSelectedTime
                                : selectedTime
                                  ? selectedTime
                                  : null;
                              const updatesSearchParams = Object.assign(
                                parsed,
                                { time: updatedTimeLimit },
                              );

                              props.history.replace({
                                search:
                                  queryString.stringify(updatesSearchParams),
                              });

                              props.updateTest({
                                id: props.test.id || testID,
                                online_data: {
                                  time_limit: updatedTimeLimit,
                                },
                              });

                              hide();
                            }}
                          >
                            {translate("global.save")}
                          </button>
                        </div>
                      )}
                    />
                  </Modal>
                )}
              />
            </div>
          )}
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.questions")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.test.questions.length}</span>
          </div>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.points")}:&nbsp;
            </span>
            <span className={styles.violet}>
              {getTotalPoints(props.test.questions)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.testContent}>
        {dndEnabled ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              id="dnd_creator_container"
              className={styles.dndQuestionContainer}
            >
              <Droppable droppableId="droppable_creator">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((question: questionModel, index: number) => (
                      <Draggable
                        key={`${question.id}_${index}`}
                        draggableId={question.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.dndQuestionDraggable}
                          >
                            <QuestionHeader
                              testID={testID}
                              classID={classID}
                              questionID={pathOr("", ["id"], question)}
                              order={pathOr(1, ["order"], question)}
                              points={pathOr(1, ["points"], question)}
                              pointsPerItem={pathOr(
                                null,
                                ["points_per_item"],
                                question,
                              )}
                              type={pathOr("", ["type"], question)}
                              subtype={pathOr(
                                "",
                                ["type_data", "subtype"],
                                question,
                              )}
                              subtypes={[]}
                              updateQuestion={props.updateQuestion}
                              answerChoices={pathOr(
                                [] as answerChoiceModel[],
                                ["answer_choices"],
                                question,
                              )}
                              matchings={pathOr(
                                [] as matchingModel[],
                                ["matchings"],
                                props,
                              )}
                              gaps={pathOr(
                                [] as string[],
                                ["question", "gaps"],
                                props,
                              )}
                              questionBodyHTML={pathOr(
                                "",
                                ["body_html"],
                                question,
                              )}
                              questionBody={pathOr("", ["body"], question)}
                              isCollapsed
                              isLastQuestion={questions.length === index + 1}
                              collapse={collapse}
                              collapsedQuestions={collapsedQuestions}
                              inDndMode
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        ) : (
          <div className={styles.dndQuestionContainer}>
            {questions.map((question: questionModel, index: number) => (
              <Question
                key={question.id}
                question={question}
                questionTypes={props.test.question_types}
                updateQuestion={props.updateQuestion}
                questionIndex={index}
                questionsLength={props.test.questions.length}
                testErrors={props.test.errors}
                testID={testID}
                classID={classID}
                addNextQuestion={setQuestionTypesVisibility}
                deleteQuestion={props.deleteQuestion}
                createQuestionAnswerChoice={props.createQuestionAnswerChoice}
                updateQuestionAnswerChoice={props.updateQuestionAnswerChoice}
                deleteQuestionAnswerChoice={props.deleteQuestionAnswerChoice}
                deleteQuestionImage={props.deleteQuestionImage}
                questionInProgress={props.test.in_progress.update_question}
                fullQuestionInProgress={
                  props.test.in_progress.question === question.id
                }
                collapse={collapse}
                collapsedQuestions={collapsedQuestions}
                user={props.user}
                hasCurriculum={props.test.has_curriculum}
                unmountCurriculum={props.unmountCurriculum}
                selectedTestType={selectedTestType}
                createQuestionMatching={props.createQuestionMatching}
                updateQuestionMatching={props.updateQuestionMatching}
                deleteQuestionMatching={props.deleteQuestionMatching}
                replaceQuestionGap={props.replaceQuestionGap}
              />
            ))}
          </div>
        )}
      </div>
      {(!props.test.questions.length || showQuestionTypes) && (
        <div className={styles.addQuestionContainer}>
          <TestQuestionTypes
            testID={testID}
            classID={classID}
            selectedSubjectType={selectedSubjectType}
            selectedClassName={getSelectedClassName(props.classes, classID)}
            selectedSubjectName={getSelectedSubjectName()}
            selectedTestType={selectedTestType}
            subjects={props.subjects}
            addNextQuestion={setQuestionTypesVisibility}
            questionLength={props.test.questions.length}
            history={props.history}
            selectedTime={selectedTime}
          />
          {userFromPL(props.user.country) && (
            <AddQuestionFromQuestionsBase
              testID={testID}
              classID={classID}
              selectedSubjectType={selectedSubjectType}
              selectedClassName={getSelectedClassName(props.classes, classID)}
              selectedSubjectName={getSelectedSubjectName()}
              selectedTestType={selectedTestType}
              subjects={props.subjects}
              addNextQuestion={setQuestionTypesVisibility}
              questionLength={props.test.questions.length}
              history={props.history}
              selectedTime={selectedTime}
            />
          )}
        </div>
      )}
      <div className={styles.settingsContainer}>
        {props.user.roles.includes(userRoles.admin) && (
          <button
            type="button"
            className={styles.publishBtn}
            disabled={!props.test.id}
            onClick={() =>
              props.publishTestInTestsBase({ testID: props.test.id })
            }
          >
            <span>{translate("creator.publish")}</span>
          </button>
        )}
        {dndEnabled ? (
          <button
            type="button"
            className={styles.dndEnabledBtn}
            onClick={() => setDndEnabled(false)}
          >
            <span>{translate("creator.back-to-the-creator")}</span>
          </button>
        ) : (
          <>
            {!!questions.length && (
              <button
                type="button"
                className={styles.dndEnabledBtn}
                onClick={() => {
                  setDndEnabled(true);
                  setQuestionTypesVisibility(false);

                  if (!isTutorialWatched("dnd")) {
                    setWatchedTutorials("dnd");
                    props.showToast({
                      text: "creator.dnd-tutorial-description",
                      status: "info",
                      customIcon: () => <DnDTutorialIcon/>,
                    });
                  }
                }}
              >
                <span>{translate("creator.change-questions-order")}</span>
              </button>
            )}
            {!!questions.length && selectedTestType === testTypes.PAPER && (
              <Link
                to={`/test/creator/preview${props.location.search}`}
                className={styles.previewBtn}
              >
                <span>{translate("creator.show-preview-mode")}</span>
              </Link>
            )}
            <ToggleContent
              toggle={(show: any) => (
                <button
                  disabled={!props.test.questions.length}
                  onClick={() => {
                    props.getTest({
                      classID,
                      testID,
                    });
                    props.validateTest({
                      testID,
                      callback: () => finish(show),
                    });
                  }}
                >
                  {translate("global.finish")}
                </button>
              )}
              content={(hide: () => {}) => (
                <DownloadTestModal
                  history={props.history}
                  location={props.location}
                  hide={() => {
                    hide();
                    props.showToast({
                      text: "creator.dnd-tutorial-description",
                      status: "info",
                      customIcon: () => <DnDTutorialIcon/>,
                    });
                  }}
                  isCompact={isCompact}
                  setIsCompact={setIsCompact}
                  hideInstruction={hideInstruction}
                  setHideInstruction={setHideInstruction}
                  inProgress={inProgressGeneratePDF()}
                  classID={classID}
                  testID={testID}
                  generatePDF={() => {
                    const element = document.querySelector(
                      "#paper-pdf-generate",
                    ) as HTMLElement;
                    if (element.innerHTML) {
                      props.generatePDF({
                        testID,
                        classID,
                        html: element.innerHTML,
                      });

                      hide();

                      props.showToast({
                        text: "test.test-has-been-downloaded-and-saved",
                        status: "info",
                      });
                    }
                  }}
                  hasFullAccess={props.user.access.full_access}
                  isOnline={selectedTestType === testTypes.ONLINE}
                />
              )}
            />
          </>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  test: testStateModel;
  user: userStateModel;
}) => ({
  classes: state.teacher.classes,
  subjects: state.teacher.subjects,
  test: state.test,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
      getQuestionTypes,
      getTest,
      unmountTest,
      updateTest,
      updateQuestion,
      deleteQuestion,
      createQuestionAnswerChoice,
      updateQuestionAnswerChoice,
      deleteQuestionAnswerChoice,
      createQuestionMatching,
      updateQuestionMatching,
      deleteQuestionMatching,
      replaceQuestionGap,
      generatePDF,
      validateTest,
      deleteQuestionImage,
      publishTestInTestsBase,
      unmountCurriculum,
      showToast,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCreator);
