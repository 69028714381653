import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import smoothscroll from "smoothscroll-polyfill";
import { StripeProvider } from "react-stripe-elements";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/browser";
import { hotjar } from "react-hotjar";

import Core from "./components/Core";
import "./index.scss";

library.add(fas, far, fab);

if (window) {
  smoothscroll.polyfill();
}

if (process.env.REACT_APP_GTM_KEY) {
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_KEY}`,
    dataLayer: {
      app: "web-app",
    },
  };

  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_ENV === "production") {
  hotjar.initialize(2670944, 6);
}

Sentry.init({ dsn: `${process.env.REACT_APP_SENTRY_DSN}` });

ReactDOM.render(
  <Provider store={configureStore()}>
    <StripeProvider apiKey={`${process.env.REACT_APP_STRIPE_API_KEY}`}>
      <Core/>
    </StripeProvider>
  </Provider>,
  document.getElementById("root"),
);
