import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { ReactComponent as ArrowDown } from "../../../../../../../../assets/images/small_arrow_down.svg";

import translate from "../../../../../../../../services/translate";

// Types
import {
  questionSubtypeModel,
  updateQuestion as updateQuestionFunction,
} from "../../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  subtype: string | null;
  subtypes: questionSubtypeModel[];
  updateQuestion: updateQuestionFunction;
  questionID: string;
  testID: string;
  classID: string;
}

export const MultipleChoiceSubtype = (props: Props) => {
  const [showDropdown, toggleDropdown] = useState(false);

  return (
    <div className={styles.subtype}>
      <span>{translate("creator.number-of-correct-answers")}:</span>
      <OutsideClickHandler onOutsideClick={() => toggleDropdown(false)}>
        <div className={styles.wrapper}>
          <div
            onClick={() => toggleDropdown(!showDropdown)}
            className={styles.inputContainer}
          >
            <button>
              {props.subtype
                ? translate(`subtype-${props.subtype}`)
                : translate("global.pick-up")}
            </button>
            <ArrowDown/>
          </div>
          {showDropdown && (
            <div className={styles.dropdown}>
              <ul>
                {props.subtypes.map((item: questionSubtypeModel) => (
                  <li key={item.type}>
                    <button
                      type="button"
                      onClick={() => {
                        props.updateQuestion({
                          questionID: props.questionID,
                          testID: props.testID,
                          classID: props.classID,
                          type_data: {
                            subtype: item.type,
                          },
                        });
                        toggleDropdown(false);
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default MultipleChoiceSubtype;
