import React from "react";
import { Link } from "react-router-dom";

import translate from "../../../../services/translate";

import { ReactComponent as PercentSignIcon } from "../../../../assets/images/percent_sign.svg";

import styles from "./styles.module.scss";

export const ReferralBox = () => {
  return (
    <div className={styles.referralBox}>
      <PercentSignIcon/>
      <h3>{translate("global.get-a-discount")}</h3>
      <p>{translate("global.referral-desc")}</p>
      <Link to="/referral">{translate("global.invite-your-friends")}</Link>
    </div>
  );
};

export default ReferralBox;
