import React from "react";
import DOMPurify from "dompurify";

// Types
import {
  questionModel,
  answerChoiceModel,
} from "../../../../../../../../models/redux/test";


interface Props {
  question: questionModel;
}

export const TestQuestionSingleChoicePDF = (props: Props) => {
  return (
    <div
      style={{
        width: "100%",
        pageBreakInside: "avoid",
        margin: "0",
        padding: "0",
      }}
    >
      {props.question.answer_choices.map((item: answerChoiceModel) => (
        <div
          key={`${item.id}_${item.order}`}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            margin: "0",
            padding: "2px 70px",
            boxSizing: "border-box",
          }}
        >
          <li
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              margin: "0",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "0 0 15px 0",
                margin: "0",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  minWidth: "19px",
                  minHeight: "19px",
                  width: "19px",
                  height: "19px",
                  border: "1px solid #000",
                  borderRadius: "50%",
                  margin: "0 20px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "transparent",
                  fontSize: "12px",
                }}
              >{"ABCDEF"[item.order - 1]}</div>
              <div
                className="questionBodyGlobal"
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#000",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{ __html: `${item.name_html ? DOMPurify.sanitize(item.name_html) : DOMPurify.sanitize(item.name)}` }}
              ></div>
            </div>
          </li>
        </div>
      ))}
    </div>
  );
};

export default TestQuestionSingleChoicePDF;
