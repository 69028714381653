import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ReactComponent as GraphicIcon } from "../../../assets/images/referral/graphic_1.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/referral/copy.svg";

import ReferralList from "./List";

import { copyToClipboard } from "../../../services/common/copyToClipboard";
import translate from "../../../services/translate";

import {
  getReferrals,
  resetBillingErrors,
} from "../../../redux/actions/billing";
import { showToast } from "../../../redux/actions/app";

// Types
import { History, Location } from "history";
import {
  billingStateModel,
  resetBillingErrors as resetBillingErrorsFunction,
  getReferrals as getReferralsFunction,
} from "../../../models/redux/billing";

import styles from "./styles.module.scss";
import { sendCopyReferralLinkEvent } from "../../../services/common/googleAnalytics";

interface Props {
  location: Location;
  history: History;
  resetBillingErrors: resetBillingErrorsFunction;
  getReferrals: getReferralsFunction;
  billing: billingStateModel;
  showToast: any;
}

export const Referral = (props: Props) => {
  useEffect(() => {
    props.getReferrals();

    return () => {
      props.resetBillingErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{translate("global.recommend-to-friends")}</h3>
      </div>
      <div className={styles.boxesContainer}>
        <div className={styles.referralLinkBox}>
          <span>{translate("global.your-referral-code")}</span>
          <div className={styles.inputContainer}>
            <input
              id="referral_code_input"
              type="text"
              value={`${window.location.origin}/sign-up?referral=${props.billing.account_id}`}
              onChange={() => {}}
              onCopy={sendCopyReferralLinkEvent}
            />
            <button
              type="button"
              onClick={() =>
                copyToClipboard("referral_code_input", () => {
                  props.showToast({ text: "global.copied", status: "success" });
                  sendCopyReferralLinkEvent();
                })
              }
            >
              <CopyIcon/>
              &nbsp;&nbsp;
              <span>{translate("global.copy")}</span>
            </button>
          </div>
        </div>
        <div className={styles.referralInfoBox}>
          <span>{props.billing.referrals.length}</span>
          <p>
            {translate("global.number-of-completed-referral-invitations-desc")}
          </p>
          <GraphicIcon/>
        </div>
      </div>
      {!!props.billing.referrals.length && (
        <>
          <h4>{translate("global.history-of-referrals")}</h4>
          <ReferralList list={props.billing.referrals}/>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: { billing: billingStateModel }) => ({
  billing: state.billing,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getReferrals,
      resetBillingErrors,
      showToast,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
