import React, { useState, useRef } from "react";
import propOr from "ramda/src/propOr";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import FormulaModal from "../../../../../Common/FormulaModal";
import VideoModal from "../../../../../Common/VideoModal";

import { allowedFormatsForBody } from "../../../../../../services/common";

// Types
import { History, Location } from "history";

import "react-quill/dist/quill.snow.css";
import "katex/dist/katex.min.css";
import styles from "./styles.module.scss";


interface Props {
  history: History;
  location: Location;
  value: any;
  handleOnChange: (object: any) => any;
  hasError: boolean;
}

export const LessonCreatorEditor = (props: Props) => {
  const [formulaModal, setFormulaModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [editorPosition, setEditorPosition] = useState(null);
  const [editorIsFocused, setEditorIsFocused] = useState(false);
  const editorModules = useRef({
    toolbar: {
      container: [
        "bold",
        "italic",
        "underline",
        {
          "list": "ordered",
        },
        {
          "list": "bullet",
        },
        "formula",
        "video",
      ],
      handlers: {
        "formula": () => {
          const editor = ReactQuillEditor.current.getEditor();
          const range = editor.getSelection();
          setEditorPosition(range);
          setFormulaModal(true);
        },
        "video": () => {
          const editor = ReactQuillEditor.current.getEditor();
          const range = editor.getSelection();
          setEditorPosition(range);
          setVideoModal(true);
        },
      },
    },
  });
  const ReactQuillEditor = useRef<any>(null);

  const setFormulaHTML = (formula: string) => {
    const editor = ReactQuillEditor.current.getEditor();
    const position = editorPosition ? propOr(0, "index", editorPosition) : 0;

    editor.insertEmbed(position, "formula", formula);

    props.handleOnChange({
      html: editor.root.innerHTML,
      plain: editor.getText(editor.root.innerHTML),
    });
  };

  const setVideoHTML = (video: string) => {
    const editor = ReactQuillEditor.current.getEditor();
    const position = editorPosition ? propOr(0, "index", editorPosition) : 0;
    editor.insertEmbed(position, "video", video);

    props.handleOnChange({
      html: editor.root.innerHTML,
      plain: editor.getText(editor.root.innerHTML),
    });
  };

  const reactQuillQuestionAnswerClass = classNames({
    "reactQuill": true,
    "reactQuillError": props.hasError,
  });

  const editor = () => (
    <OutsideClickHandler onOutsideClick={() => {
      if (editorIsFocused) {
        setEditorIsFocused(false);
      }
    }}>
      <ReactQuill
        ref={ReactQuillEditor}
        value={props.value.html}
        onChange={(value, delta, source, editor) => {
          console.log(value);
          const textHTML = DOMPurify.sanitize(value);
          const textPlain = editor.getText(textHTML as any);

          props.handleOnChange({
            html: value,
            plain: textPlain,
          });
        }}
        onFocus={() => setEditorIsFocused(true)}
        className={reactQuillQuestionAnswerClass}
        modules={editorModules.current}
        formats={allowedFormatsForBody}
      />
    </OutsideClickHandler>
  );

  return (
    <>
      <div className={styles.quillContainer}>{editor()}</div>
      {formulaModal &&
        <FormulaModal
          setFormulaModal={setFormulaModal}
          setFormulaHTML={setFormulaHTML}
        />
      }
      {videoModal &&
        <VideoModal
          setVideoModal={setVideoModal}
          setVideoHTML={setVideoHTML}
        />
      }
    </>
  );
};

export default LessonCreatorEditor;
