import pathOr from "ramda/src/pathOr";

// Types
import {
  billingStateModel,
  billingActionModel,
} from "../../../models/redux/billing";

const initialState: billingStateModel = {
  next_payment: {
    base_amount: null,
    amount: null,
    currency: "",
  },
  discount_codes: [],
  invoice_data: {
    address: {
      building_number: "",
      city: "",
      country: "",
      flat_number: "",
      postal_code: "",
      street_name: "",
    },
    company_name: "",
    tin: "",
    first_name: "",
    last_name: "",
  },
  in_progress: {
    update_invoice_data: false,
  },
  referrals: [],
  account_id: "",
  errors: [],
};

export const billing = (state = initialState, action: billingActionModel) => {
  switch (action.type) {
    case "GET_BILLING_NEXT_PAYMENT_SUCCESS":
      return {
        ...state,
        next_payment: pathOr(
          {
            base_amount: null,
            amount: null,
            currency: "",
          },
          ["payload", "data"],
          action,
        ),
      };
    case "GET_DISCOUNT_CODES_SUCCESS":
      return {
        ...state,
        discount_codes: pathOr(
          [],
          ["payload", "data"],
          action,
        ),
      };
    case "UPDATE_INVOICE_DATA":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_invoice_data: true,
        },
        errors: [],
      };
    case "UPDATE_INVOICE_DATA_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_invoice_data: false,
        },
        errors: [],
      };
    case "UPDATE_INVOICE_DATA_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_invoice_data: false,
        },
        errors: pathOr([], ["payload", "errors"], action),
      };
    case "GET_INVOICE_DATA_SUCCESS":
      return {
        ...state,
        invoice_data: pathOr(
          initialState.invoice_data,
          ["payload", "data"],
          action,
        ),
        errors: [],
      };
    case "GET_REFERRALS_SUCCESS":
      return {
        ...state,
        referrals: pathOr(
          initialState.referrals,
          ["payload", "data", "referrals"],
          action,
        ),
        account_id: pathOr(
          initialState.account_id,
          ["payload", "data", "account_id"],
          action,
        ),
      };
    case "VALIDATE_REFERRER_ACCOUNT_ID_FAILURE":
      return {
        ...state,
        errors: pathOr([], ["payload", "errors"], action),
      };
    case "RESET_BILLING_ERRORS":
      return {
        ...state,
        errors: initialState.errors,
      };
    default:
      return state;
  }
};
