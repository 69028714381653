import React, { useState } from "react";

import { ReactComponent as Logo } from "../../../../../assets/images/wisdy_logo.svg";

import {
  getLandingPageURL,
  getArrayBasedOnNumber,
} from "../../../../../services/common";
import translate from "../../../../../services/translate";

import Modal, { ToggleContent } from "../../../../Common/Modal";
import StudentVerificationCodeInput from "../StudentVerification/VerificationCodeInput";

// Types
import { Location, History } from "history";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  history: History;
  verified: boolean;
  sendAnswers?: (studentCode: string) => any;
}

export const TestOnlineNav = (props: Props) => {
  const initialStudentCode = {
    0: "",
    1: "",
    2: "",
    3: "",
  };
  const [verificationCode, setVerificationCode] =
    useState<any>(initialStudentCode);

  const getStudentCode = () => {
    return getArrayBasedOnNumber(4)
      .map((item: number) => `${verificationCode[item]}`)
      .join("");
  };

  const resetStudentCode = () => setVerificationCode(initialStudentCode);

  return (
    <section className={styles.container}>
      <nav className={styles.header}>
        <a href={getLandingPageURL()} className={styles.logoLink}>
          <Logo className={styles.logo}/>
        </a>
        {props.verified && props.sendAnswers && (
          <ToggleContent
            toggle={(show: any) => (
              <div className={styles.buttonContainer}>
                <button type="button" onClick={show}>
                  <span>{translate("test.send-answers")}</span>
                </button>
              </div>
            )}
            content={(hide: () => {}) => (
              <Modal
                hide={() => {
                  resetStudentCode();
                  hide();
                }}
                customStyles={styles.modal}
              >
                <div className={styles.sendAnswersModal}>
                  <h2>{translate("test.send-answers")}</h2>
                  <span className={styles.description}>
                    {translate("test.send-answers-confirmation")}
                  </span>
                  <div className={styles.studentCode}>
                    <span>{translate("global.access-code")}:</span>
                    <StudentVerificationCodeInput
                      history={props.history}
                      location={props.location}
                      verificationCode={verificationCode}
                      setVerificationCode={setVerificationCode}
                    />
                  </div>
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        resetStudentCode();
                        hide();
                      }}
                    >
                      <span>{translate("global.cancel")}</span>
                    </button>
                    <button
                      type="button"
                      disabled={getStudentCode().length < 4}
                      onClick={() =>
                        props.sendAnswers &&
                        props.sendAnswers(getStudentCode() as string)
                      }
                    >
                      <span>{translate("global.send")}</span>
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          />
        )}
      </nav>
    </section>
  );
};

export default TestOnlineNav;
