import React from "react";

import QuestionMatchingOnline from "../../../../../Online/Solve/Questions/Matching";

// Types
import {
  questionModel,
} from "../../../../../../../../models/redux/test";

interface Props {
  question: questionModel;
}

export const QuestionMatchingPreview = (props: Props) => {
  return (
    <QuestionMatchingOnline
      question={props.question}
      isReadOnly
      onMatchChange={() => {}}
    />
  );
};

export default QuestionMatchingPreview;
