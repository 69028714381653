import React from "react";

import { ReactComponent as CapIcon } from "../../../../../../assets/images/reports/cap.svg";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  studentsTotal: number;
  scannedTests: number;
}

export const TestReportClassParticipants = (props: Props) => {
  return (
    <div className={styles.container}>
      <CapIcon/>
      <div className={styles.textContainer}>
        <h4>{translate("report.test-participants")}</h4>
        <h5>
          <span>{props.scannedTests}</span>/{props.studentsTotal}
        </h5>
      </div>
    </div>
  );
};

export default TestReportClassParticipants;
