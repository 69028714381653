import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";

import propOr from "ramda/src/propOr";
import equals from "ramda/src/equals";

import translate from "../../../services/translate";
import SubjectBox from "../../Common/SubjectBox";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIcon } from "../../../assets/images/wisdy_icon_info.svg";

import { getSubjects, updateSubjects } from "../../../redux/actions/teacher";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../models/redux/teacher";
import { authStateModel } from "../../../models/redux/demo";

import styles from "../../App/PickUpSubjects/styles.module.scss";

interface Props {
  location: Location;
  history: History;
  getSubjects: getSubjectsFunction;
  updateSubjects: any;
  teacher: teacherStateModel;
  auth: authStateModel;
}

export const DemoPickUpSubject = (props: Props) => {
  const [selectedSubjects, updateSelectedSubjects] = useState<string[]>([]);

  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    if (props.auth.token) {
      props.getSubjects();
    } else {
      props.history.replace("/demo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subjects = propOr([], "subjects", props.teacher) as [];
    if (Array.isArray(subjects)) {
      const currentlySelectedSubjects: string[] = subjects
        .filter((subject: any) => subject.is_chosen)
        .map((item: any) => item.id);

      if (!equals(selectedSubjects, currentlySelectedSubjects)) {
        updateSelectedSubjects(currentlySelectedSubjects);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teacher]);

  const isSubjectSelected = (id: string) => selectedSubjects.includes(id);

  const changeSubject = (id: string) => {
    if (!isSubjectSelected(id)) {
      updateSelectedSubjects([id]);
      props.updateSubjects([id]);
    }
  };

  const getSelectedSubjectType = () => {
    const isChosesSubject = props.teacher.subjects.filter(
      (item) => item.id === selectedSubjects[0],
    );
    if (isChosesSubject[0]) {
      return isChosesSubject[0].type;
    }
    return "";
  };

  const linkClass = classNames({
    [styles.disabled]: !selectedSubjects.length && !getSelectedSubjectType(),
  });

  return (
    <section className={styles.container}>
      <h1>
        {translate("global.pick-up")} <span>{translate("global.subject")}</span>
      </h1>
      <p>{translate("select_subject.pick-up-subjects-demo-desc")}</p>
      <ul>
        {props.teacher.subjects.map((subject: any) => (
          <SubjectBox
            key={subject.id}
            id={subject.id}
            title={subject.name}
            subjectType={subject.type}
            onClick={() => changeSubject(subject.id)}
            checked={isSubjectSelected(subject.id)}
            radio
          />
        ))}
      </ul>
      {!parsed.edit && (
        <div className={styles.bottomContainer}>
          <Link
            to={`class/add?subject=${getSelectedSubjectType()}`}
            className={linkClass}
          >
            {translate("global.next")}
          </Link>
          <div className={styles.tooltipContainer}>
            <ReactTooltip
              id="tooltip"
              place="top"
              effect="solid"
              className={styles.tooltip}
            >
              <p>{translate("select_subject.pick-up-subjects-tooltip")}</p>
            </ReactTooltip>
            <i data-tip data-for="tooltip">
              <InfoIcon/>
            </i>
          </div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  auth: authStateModel;
}) => ({
  teacher: state.teacher,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
      updateSubjects,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoPickUpSubject);
