import React, { useEffect, useState } from "react";

import map from "ramda/src/map";
import when from "ramda/src/when";
import propEq from "ramda/src/propEq";
import propOr from "ramda/src/propOr";
import merge from "ramda/src/merge";

import { ReactComponent as ArrowIcon } from "../../../../../../../../assets/images/match_question_arrow.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../../assets/images/trash.svg";

import QuestionAnswerEditor from "../MultipleChoice/Editor";
import { getErrorMessage } from "../../../../../../../../services/common";

// Types
import {
  matchingModel,
  matchingItemModel,
  matchingMatchedItemModel,
} from "../../../../../../../../models/redux/test";
import { ErrorObject } from "../../../../../../../../models/common";

import styles from "../styles.module.scss";

interface Props {
  matchings: matchingModel[];
  questionID: string;
  createQuestionMatching: () => any;
  updateQuestion: (
    item_html: string,
    item: string,
    matched_item_html: string,
    matched_item: string,
    id: string
  ) => any;
  deleteQuestionMatching: (matchingID: string) => any;
  testErrors: ErrorObject[];
}

export const Matching = (props: Props) => {
  const [matchings, setMatchings] = useState([] as any);

  useEffect(() => {
    setMatchings(props.matchings);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !!props.matchings.length &&
      !!matchings.length &&
      !!props.matchings[0] &&
      !!matchings[0]
    ) {
      const matchingsLength = props.matchings[0].length;
      const localMatchingsLength = matchings[0].length;

      if (localMatchingsLength && matchingsLength > localMatchingsLength) {
        setMatchings(props.matchings);
        return;
      }

      if (localMatchingsLength !== matchingsLength) {
        setMatchings(props.matchings);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matchings]);

  const onAnswerNameChange = (
    value: any,
    id: string,
    key: string,
    index: number,
  ) => {
    const answer = matchings[index].find((item: any) => item.id === id);

    if (answer) {
      const updatedList = Object.assign(matchings, {
        [index]: map(
          // @ts-ignore
          when(
            propEq("id", id),
            // @ts-ignore
            (item: matchingModel) =>
              merge(item, {
                [`${key}_html`]: value.html,
                [key]: value.plain,
              }),
          ),
          matchings[index],
        ),
      });

      setMatchings(updatedList);
    }
  };

  const getError = (id: string, type: string) =>
    getErrorMessage(props.testErrors, `questions/matchings/${id}/${type}`);

  return (
    <>
      {matchings &&
        matchings.length &&
        Array.from(Array(matchings[0].length).keys()).map((num: number) => {
          const item = matchings[0][num] as matchingItemModel;
          const matchedItem = matchings[1][num] as matchingMatchedItemModel;
          return (
            <div
              key={`${matchings[0][num].id}_${matchings[0][num].order}`}
              className={styles.listItemContainer}
            >
              <li>
                <div>
                  <QuestionAnswerEditor
                    questionAnswer={propOr("", "item_html", item)}
                    setQuestionAnswer={(value: string) =>
                      onAnswerNameChange(value, item.id, "item", 0)
                    }
                    updateQuestion={(
                      item_html?: string,
                      item_text?: string,
                    ) => {
                      props.updateQuestion(
                        item_html ? item_html : matchings[0][num].item_html,
                        item_text ? item_text : matchings[0][num].item,
                        matchings[1][num].matched_item_html,
                        matchings[1][num].matched_item,
                        item.id,
                      );
                    }}
                    hasError={!!getError(item.id, "item")}
                  />
                  <div className={styles.matchQuestionArrow}>
                    <ArrowIcon/>
                  </div>
                  <QuestionAnswerEditor
                    questionAnswer={propOr(
                      "",
                      "matched_item_html",
                      matchedItem,
                    )}
                    setQuestionAnswer={(value: string) =>
                      onAnswerNameChange(
                        value,
                        matchedItem.id,
                        "matched_item",
                        1,
                      )
                    }
                    updateQuestion={(
                      matched_item_html?: string,
                      matched_item?: string,
                    ) => {
                      props.updateQuestion(
                        matchings[0][num].item_html,
                        matchings[0][num].item,
                        matched_item_html
                          ? matched_item_html
                          : matchings[1][num].matched_item_html,
                        matched_item
                          ? matched_item
                          : matchings[1][num].matched_item,
                        item.id,
                      );
                    }}
                    hasError={!!getError(item.id, "matched_item")}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    setMatchings(
                      matchings.map((wrapper: any, index: any) =>
                        wrapper.filter(
                          (matching: any) => matching.id !== item.id,
                        ),
                      ),
                    );

                    props.deleteQuestionMatching(item.id);
                  }}
                >
                  <TrashIcon/>
                </button>
              </li>
            </div>
          );
        })}
    </>
  );
};

export default Matching;
