import React from "react";
import DOMPurify from "dompurify";

// Types
import {
  questionModel,
  answerChoiceModel,
} from "../../../../../../../../../models/redux/test";


interface Props {
  question: questionModel;
}

export const TestQuestionMultipleChoiceCompactPDF = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        pageBreakInside: "avoid",
        margin: "0",
        padding: "0 40px 0 55px",
        boxSizing: "border-box",
      }}
    >
      {props.question.answer_choices.map((item: answerChoiceModel) => (
        <div
          key={`${item.id}_${item.order}`}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "0",
            padding: "2px 20px 13px 0",
            boxSizing: "border-box",
          }}
        >
          <li
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              margin: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "0",
                margin: "0",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  minWidth: "17px",
                  minHeight: "17px",
                  width: "17px",
                  height: "17px",
                  border: "1px solid #000",
                  borderRadius: "4px",
                  margin: "0 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "transparent",
                  fontSize: "10px",
                }}
              >{"ABCDEF"[item.order - 1]}</div>
              <div
                className="questionBodyGlobal"
                style={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19px",
                  color: "#000",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{ __html: `${item.name_html ? DOMPurify.sanitize(item.name_html) : DOMPurify.sanitize(item.name)}` }}
              ></div>
            </div>
          </li>
        </div>
      ))}
    </div>
  );
};

export default TestQuestionMultipleChoiceCompactPDF;
