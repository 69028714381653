import React, { useRef, useState, useEffect, ReactElement } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIcon } from "../../../assets/images/wisdy_icon_info.svg";

import styles from "./styles.module.scss";


interface Props {
  id: string;
  type: string;
  name: string;
  value: string | number;
  onChange(e: React.ChangeEvent<HTMLInputElement>): any;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): any;
  onKeyUp?(e: any): any;
  onKeyDown?(e: any): any;
  label?: string | ReactElement;
  error?: string;
  hideErrorMessage?: boolean;
  tooltip?: string | ReactElement;
  labelHiddenOnActive?: boolean;
  icon?: () => any;
  disabled?: boolean;
};

const Input = (props: Props) => {
  const [labelClassName, setLabelClassName] = useState("");
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.value) {
      setLabelClassName("");
      return;
    }
    setLabelClassName(styles.active);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const focusInput = () => {
    const node = inputEl.current;

    if (node) {
      node.focus();
      if (props.labelHiddenOnActive) return;
      setLabelClassName(styles.active);
    }
  };

  const onInputBlur = (e: any) => {
    if (!props.value) {
      setLabelClassName("");
    }
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const labelClass = classNames({
    [labelClassName]: true,
    [styles.error]: props.error,
    [styles.labelHiddenOnActive]: props.labelHiddenOnActive,
  });

  return (
    <>
      <div className={styles.inputContainer}>
        {props.icon && props.icon()}
        <input
          id={props.id}
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          ref={inputEl}
          onBlur={onInputBlur}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onFocus={() => {
            if (props.labelHiddenOnActive) return;
            setLabelClassName(styles.active);
          }}
          className={props.error && styles.error}
          autoComplete="off"
          disabled={props.disabled}
        />
        {props.tooltip &&
          <div className={styles.tooltipContainer}>
            <ReactTooltip
              id="tooltip"
              place="top"
              effect="solid"
              className={styles.tooltip}
            >
              <p>{props.tooltip}</p>
            </ReactTooltip>
            <i data-tip data-for="tooltip" className={props.error && styles.error}>
              <InfoIcon/>
            </i>
          </div>
        }
        {props.label &&
          <div className={styles.labelContainer} onClick={() => focusInput()}>
            <label
              htmlFor={props.id}
              className={labelClass}
            >
              {props.label}
            </label>
          </div>
        }
      </div>
      {(props.error && !props.hideErrorMessage) &&
        <span className={styles.error}>
          {props.error}
        </span>
      }
    </>
  );
};

export default Input;
