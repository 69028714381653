import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";

import { getSubjects } from "../../../../../redux/actions/teacher";
import {
  getQuestionTypes,
  getTest,
} from "../../../../../redux/actions/test";

import TestHeaderPDF from "./Header";
import TestHeaderCompactPDF from "./Header/Compact";

import TestInstructionPDF from "./Instruction";
import TestQuestionPDF from "./Question";

import { getTotalPoints } from "../../../../../services/common/test";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  subjectModel,
  classModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";
import {
  testStateModel,
  getQuestionTypes as getQuestionTypesFunction,
  getTest as getTestFunction,
} from "../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  history: History;
  location: Location;
  classes: classModel[];
  subjects: subjectModel[];
  test: testStateModel;
  getSubjects: getSubjectsFunction;
  getQuestionTypes: getQuestionTypesFunction;
  getTest: getTestFunction;
  isCompact: boolean;
  hideInstruction: boolean;
}

export const GeneratePDF = (props: Props) => {
  const [questions, setQuestions] = useState([] as any[]);

  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const testID = parsed.testID as string;

  useEffect(() => {
    props.getSubjects();
    props.getQuestionTypes({ type: "paper" });

    if (classID && testID) {
      props.getTest({
        classID,
        testID,
      });
    }

    setQuestions(props.test.questions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQuestions(props.test.questions);

  }, [props.test.questions, setQuestions]);

  return (
    <section
      className={styles.container}
      id="paper-pdf-generate"
    >
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.11.1/dist/katex.css" integrity="sha384-bsHo4/LA+lkZv61JspMDQB9QP1TtO4IgOf2yYS+J6VdAYLVyx1c3XKcsHh0Vy8Ws" crossOrigin="anonymous"/>
      <script defer src="https://cdn.jsdelivr.net/npm/katex@0.11.1/dist/katex.js" integrity="sha384-4z8mjH4yIpuK9dIQGR1JwbrfYsStrNK6MP+2Enhue4eyo0XlBDXOIPc8b6ZU0ajz" crossOrigin="anonymous"></script>
      <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"/>
      <style>{"\
        @page {\
          size: A4;\
          margin: 0;\
        }\
        .questionBodyGlobal p {\
          margin: 0;\
        }\
        .questionBodyGlobal ol {\
          margin: 0 0 0 1.5em;\
          padding: 0;\
        }\
        .questionBodyGlobal ul {\
          margin: 0 0 0 1.5em;\
          padding: 0;\
        }\
        .questionBodyGlobal ol li {\
          margin: 0 0 0 1.5em;\
          padding: 0;\
        }\
        .questionBodyGlobal ul li {\
          margin: 0 0 0 1.5em;\
          padding: 0;\
        }\
      "}</style>
      {props.isCompact ?
        <TestHeaderCompactPDF
          history={props.history}
          location={props.location}
          classes={props.classes}
          subjectName={props.test.subject.name}
          testName={props.test.name}
          totalPoints={getTotalPoints(questions)}
          isCompact={props.isCompact}
        /> :
        <TestHeaderPDF
          history={props.history}
          location={props.location}
          classes={props.classes}
          subjectName={props.test.subject.name}
          testName={props.test.name}
          totalPoints={getTotalPoints(questions)}
        />
      }
      {!props.hideInstruction &&
        <TestInstructionPDF/>
      }
      <TestQuestionPDF questions={questions} isCompact={props.isCompact}/>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel,
  test: testStateModel,
}) => ({
  classes: state.teacher.classes,
  subjects: state.teacher.subjects,
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getSubjects,
    getQuestionTypes,
    getTest,
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratePDF);
