import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import merge from "ramda/src/merge";

import { createTest } from "../../../../../../redux/actions/test";

import translate from "../../../../../../services/translate";

import { ReactComponent as QuestionFromBaseIcon } from "../../../../../../assets/images/question_types/question_from_base.svg";

// Types
import { History } from "history";
import {
  testStateModel,
  createTest as createTestFunction,
} from "../../../../../../models/redux/test";
import { subjectModel } from "../../../../../../models/redux/teacher";
import { userStateModel } from "../../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  subjects: subjectModel[];
  test: testStateModel;
  createTest: createTestFunction;
  testID: string;
  classID: string;
  selectedSubjectType: string;
  selectedClassName: string;
  selectedSubjectName: string;
  selectedTestType: string;
  addNextQuestion: any;
  questionLength: number;
  user: userStateModel;
  selectedTime: string;
}

export const AddQuestionFromQuestionsBase = (props: Props) => {
  const [redirect, triggerRedirection] = useState(false);

  const getClass = (itemType: string) =>
    classNames({
      [styles[itemType]]: true,
      [styles.disabled]: props.test.in_progress.create_question,
    });

  const onAddQuestionClick = () => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === props.selectedSubjectType,
    );

    if (!props.test.id && !props.testID && selectedSubject) {
      props.createTest({
        class_id: props.classID,
        history: props.history,
        lang: "",
        name: `${props.selectedSubjectName} - ${props.selectedClassName}`,
        subject_id: selectedSubject.id,
        type: props.selectedTestType,
        ...merge(
          {},
          props.selectedTestType === "online" && props.selectedTime
            ? {
              online_data: {
                time_limit: props.selectedTime,
              },
            }
            : {},
        ),
      });
    }

    triggerRedirection(true);
  };

  useEffect(() => {
    if (redirect && props.test.id !== "") {
      props.history.push(
        `/test/creator/questions-base?classID=${props.classID}&testID=${props.test.id}&subject=${props.selectedSubjectType}&type=${props.selectedTestType}`,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, props.test]);

  return (
    <section className={styles.addQuestionFromQuestionsBase}>
      <span>{translate("creator.or-add")}:</span>
      <ul>
        <li className={getClass("add")}>
          <ReactTooltip
            id="tooltip"
            place="top"
            effect="solid"
            className={styles.tooltip}
            offset={{ top: 15 }}
          >
            <p>{translate("creator.questions-base-no-access-text")}</p>
          </ReactTooltip>
          {props.user.access.full_access ? (
            <button type="button" onClick={onAddQuestionClick}>
              <QuestionFromBaseIcon/>
              <span>{translate("creator.question-from-base")}</span>
            </button>
          ) : (
            <button
              type="button"
              data-tip
              data-for="tooltip"
              onClick={() => {}}
              className={styles.disabledButton}
            >
              <QuestionFromBaseIcon/>
              <span>{translate("creator.question-from-base")}</span>
            </button>
          )}
        </li>
      </ul>
    </section>
  );
};

const mapStateToProps = (state: {
  test: testStateModel;
  user: userStateModel;
}) => ({
  test: state.test,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createTest,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddQuestionFromQuestionsBase);
