import groupWith from "ramda/src/groupWith";
import eqProps from "ramda/src/eqProps";

export const recalculateValue = (value: number) => {
  if (window.innerWidth < 376) {
    return value / 2.8;
  }
  if (window.innerWidth < 668) {
    return value / 2.5;
  }
  if (window.innerWidth < 1025) {
    return value / 2;
  }
  return value;
};

const getTestPreviewData = (
  question: HTMLElement,
  element: any,
  testHeight: number,
  pagesHeight: number,
  a4Height: number,
) => {
  const arr = Array.from(question.children[0].children);
  let updatedTestHeight = testHeight;
  let updatedPagesHeight = pagesHeight;
  let elements = [] as any;

  if (!!arr.length) {
    let elementFull = {
      ...element,
      fullQuestion: false,
      type: "",
      subtype: "",
      subtypeIndex: 0,
    };

    arr.forEach((item: any) => {
      if (item.offsetHeight) {
        updatedTestHeight += item.offsetHeight;

        // If whole container doesn't fit
        if (updatedTestHeight > updatedPagesHeight) {

          // Check if it's question notes container
          if (item.dataset.type === "question-notes") {
            let questionNotes = Array.from(item.children) as any;
            // Remove height of the whole notes container
            updatedTestHeight -= item.offsetHeight;
            let shouldBreak = false;

            if (questionNotes.length && questionNotes[0].dataset.type === "question-notes-blank-container") {
              questionNotes = Array.from(questionNotes[0].children) as any;
            }

            questionNotes.forEach((questionNote: any, index: number) => {
              let questionNoteHeight = questionNote.offsetHeight;
              if (questionNote.dataset.type === "question-notes-blank" && !index) {
                // Add margin (offsetHeight doesn't include margin)
                questionNoteHeight += 10;
              }

              updatedTestHeight += questionNoteHeight;

              elementFull.type = item.dataset.type;
              elementFull.subtype = questionNote.dataset.type;
              elementFull.subtypeIndex = index;

              // If note doesn't fit
              if (updatedTestHeight >= updatedPagesHeight && !shouldBreak) {
                shouldBreak = true;
                elementFull.page += 1;

                updatedTestHeight += (updatedPagesHeight - (updatedTestHeight - questionNoteHeight));
                updatedPagesHeight += a4Height;
              }
              elements.push(Object.assign({}, elementFull));
            });
          } else {
            elementFull.type = item.dataset.type;
            elementFull.subtype = "";
            elementFull.subtypeIndex = 0;
            elementFull.page += 1;

            updatedTestHeight = updatedTestHeight + (updatedPagesHeight - (updatedTestHeight - item.offsetHeight));
            updatedPagesHeight += a4Height;
            elements.push(Object.assign({}, elementFull));
          }
        } else {
          elementFull.type = item.dataset.type;

          if (item.dataset.type === "question-notes") {
            let questionNotes = Array.from(item.children) as any;

            if (questionNotes.length && questionNotes[0].dataset.type === "question-notes-blank-container") {
              questionNotes = Array.from(questionNotes[0].children) as any;
            }

            questionNotes.forEach((questionNote: any, index: number) => {
              elementFull.type = item.dataset.type;
              elementFull.subtype = questionNote.dataset.type;
              elementFull.subtypeIndex = index;

              elements.push(Object.assign({}, elementFull));
            });
          } else {
            elements.push(Object.assign({}, elementFull));
          }
        }
      }
    });

    return ({
      updatedTestHeight,
      updatedPagesHeight,
      elements,
    });
  }
};

export const getPreviewElement = (
  testID: string,
  setPages: (pages: any) => any,
) => {
  if (testID) {
    const a4 = document.querySelector("#hidden_a4") as HTMLElement;
    const previewTestQuestionsContainer = document.querySelector("#preview-test-container") as HTMLElement;
    const testTopHidden = document.querySelector("#testTopHidden") as HTMLElement;

    if (a4 && previewTestQuestionsContainer) {
      const a4Height = a4.offsetHeight;
      const questionsList = Array.from(previewTestQuestionsContainer.children) as any;
      let testHeight = testTopHidden.offsetHeight;
      let pagesHeight = a4Height;
      let elements = [] as any[];

      questionsList.forEach((question: HTMLElement) => {
        const updatedTestHeight = testHeight + question.offsetHeight;
        const questionChildren = question.children[0] as any;
        const element = {
          questionID: questionChildren.dataset.id,
          order: questionChildren.dataset.order,
          page: Math.ceil(testHeight / a4Height),
        };

        // Whole question fits page
        if (updatedTestHeight <= pagesHeight) {
          testHeight = updatedTestHeight;

          elements.push({
            ...element,
            fullQuestion: true,
            type: "question",
            page: Math.ceil(testHeight / a4Height),
          });

          return;
        } else {
          const testPreviewData = getTestPreviewData(question, element, testHeight, pagesHeight, a4Height) as any;
          testHeight = testPreviewData.updatedTestHeight;
          pagesHeight = testPreviewData.updatedPagesHeight;

          elements.push(...testPreviewData.elements);
          return;
        }
      });

      setPages(groupWith(eqProps("page"), elements));
    }
  }
  return;
};
