import React, { useEffect, useState } from "react";
import propOr from "ramda/src/propOr";

import PreviewHiddenTest from "../../../Test/Creator/Preview/HiddenTest";
import PreviewTestPages from "../../Creator/Preview/Pages";

import { getPreviewElement } from "../../../../../services/common/previewTest";

// Types
import { History, Location } from "history";
import { testStateModel } from "../../../../../models/redux/test";
import { classModel } from "../../../../../models/redux/teacher";

import styles from "./styles.module.scss";


interface Props {
  history: History;
  location: Location;
  test: testStateModel;
  classID: string;
  classes: classModel[];
  hideInstruction?: boolean;
  isCompact?: boolean;
}

export const TestDetailsPreviewTest = (props: Props) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    getPreviewElement(props.test.id, setPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPreviewElement(props.test.id, setPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test.questions]);

  const reloadPreview = () => getPreviewElement(props.test.id, setPages);

  return (
    <div className={styles.container}>
      <PreviewTestPages
        classID={props.classID}
        classes={props.classes}
        test={props.test}
        pages={pages}
        reloadPreview={reloadPreview}
        hideInstruction={propOr(false, "hideInstruction", props)}
        isCompact={propOr(false, "isCompact", props)}
      />
      <PreviewHiddenTest
        classID={props.classID}
        questions={props.test.questions}
        classes={[]}
        subjectName={props.test.subject.name}
        testName={props.test.name}
        reloadPreview={reloadPreview}
        hideInstruction={propOr(false, "hideInstruction", props)}
        isCompact={propOr(false, "isCompact", props)}
      />
    </div>
  );
};

export default TestDetailsPreviewTest;
