import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useSpring, animated } from "react-spring";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { addStyles, EditableMathField } from "react-mathquill";

import translate from "../../../services/translate";

import { ReactComponent as TimesIcon } from "../../../assets/images/times.svg";

import "katex/dist/katex.min.css";
import styles from "./styles.module.scss";

addStyles();

interface Props {
  setFormulaModal: (value: boolean) => any;
  setFormulaHTML: (formula: any) => any;
}

export const FormulaModal = (props: Props) => {
  const [formula, setFormula] = useState("");
  const EditableMathFieldRef = useRef<any>(null);

  useEffect(() => {
    EditableMathFieldRef.current.element.querySelector("textarea").focus();
  }, []);

  const modalProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  const modalContainerClass = classNames({
    [styles.modalContainer]: true,
    [styles.formulaModal]: true,
  });

  const closeFormulaModal = () => {
    props.setFormulaModal(false);
    setFormula("");
  };

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <animated.div style={modalProps} className={modalContainerClass}>
        <OutsideClickHandler onOutsideClick={closeFormulaModal}>
          <button type="button" onClick={closeFormulaModal}>
            <TimesIcon/>
          </button>
          <div className={styles.modal}>
            <div className={styles.formulaModalContent}>
              <h2>{translate("common.enter-formula")}</h2>
              <EditableMathField
                ref={EditableMathFieldRef}
                latex={formula}
                onChange={(mathField: any) => {
                  setFormula(mathField.latex());
                }}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://oeis.org/wiki/List_of_LaTeX_mathematical_symbols"
                className={styles.listOfTheSymbolsLink}
              >
                {translate("common.list-of-the-symbols-link")}
              </a>
              <button
                type="button"
                className={styles.saveFormulaButton}
                onClick={() => {
                  props.setFormulaHTML(formula);
                  closeFormulaModal();
                }}
              >
                <span>{translate("global.save")}</span>
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      </animated.div>
    </div>,
    document.getElementById("modal-formula") as HTMLElement,
  );
};

export default FormulaModal;
