import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

import Checkbox from "../Checkbox";
import Modal from "../Modal";

import translate from "../../../services/translate";

import { ReactComponent as CheckInCircleIcon } from "../../../assets/images/check_in_circle.svg";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  hide: any;
  isCompact: boolean;
  setIsCompact: any;
  hideInstruction: boolean;
  setHideInstruction: any;
  inProgress: boolean;
  generatePDF: any;
  classID: string;
  testID: string;
  hasFullAccess: boolean;
  isOnline: boolean;
  testDetails?: boolean;
  hasErrors?: boolean;
}

export const DownloadTestModal = (props: Props) => {
  const buttonContainerClass = classNames({
    [styles.buttonContainer]: !props.isOnline,
    [styles.buttonContainerOnline]: props.isOnline,
  });
  return (
    <Modal hide={props.hide} customStyles={styles.customFinishModal}>
      <div className={styles.finishModal}>
        <CheckInCircleIcon/>
        {!props.testDetails && (
          <p>{translate("creator.finish-test-description-1")}</p>
        )}
        <p>
          {!props.isOnline
            ? translate("test.finish-test-description-2")
            : translate("creator.finish-test-online-description-2")}
        </p>
        {!props.isOnline && (
          <div className={styles.downloadingOptions}>
            <div className={styles.downloadingOptionsBox}>
              <span>{translate("test.print-version")}</span>
              {!props.hasFullAccess ? (
                <div className={styles.tooltipContainer}>
                  <ReactTooltip
                    id="tooltip"
                    place="top"
                    effect="solid"
                    className={styles.tooltip}
                  >
                    <span>
                      {translate(
                        "common.compact-version-paid-version-description",
                      )}
                    </span>
                  </ReactTooltip>
                  <div data-tip data-for="tooltip">
                    <Checkbox
                      onClick={() => {}}
                      checked={props.isCompact}
                      radio
                      noIcon
                      disabled={!props.hasFullAccess || props.hasErrors}
                    >
                      <span>{translate("common.compact-version-short")}</span>
                    </Checkbox>
                  </div>
                </div>
              ) : (
                <Checkbox
                  onClick={() => props.setIsCompact(true)}
                  checked={props.isCompact}
                  radio
                  noIcon
                  disabled={!props.hasFullAccess || props.hasErrors}
                >
                  <span>{translate("common.compact-version-short")}</span>
                </Checkbox>
              )}
              <Checkbox
                onClick={() => props.setIsCompact(false)}
                checked={!props.isCompact}
                radio
                noIcon
                disabled={props.hasErrors}
              >
                <span>{translate("common.standard-version-short")}</span>
              </Checkbox>
            </div>
            <div className={styles.downloadingOptionsBox}>
              <span>{translate("test.bonuses")}</span>
              <Checkbox
                onClick={() => props.setHideInstruction(!props.hideInstruction)}
                checked={!props.hideInstruction}
                noIcon
                disabled={props.hasErrors}
              >
                <span>{translate("creator.answer-sheet-inctruction")}</span>
              </Checkbox>
            </div>
          </div>
        )}
        {props.hasErrors && (
          <p className={styles.error}>
            {translate("test.your-test-has-errors-description")}
          </p>
        )}
        <div className={buttonContainerClass}>
          {!props.isOnline && (
            <Link
              onClick={() => props.testDetails && props.hide()}
              to={
                props.testDetails
                  ? `/test?classID=${props.classID}&testID=${props.testID}`
                  : `/class/${props.classID}/tests`
              }
            >
              {props.testDetails
                ? translate("global.cancel")
                : translate("test.download-later")}
            </Link>
          )}
          {props.isOnline ? (
            <Link
              onClick={() => props.testDetails && props.hide()}
              to={`/test?classID=${props.classID}&testID=${props.testID}`}
            >
              {translate("global.finish")}
            </Link>
          ) : (
            <button
              disabled={props.inProgress || props.hasErrors}
              onClick={props.generatePDF}
            >
              {props.inProgress ? (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              ) : (
                <span>{translate("test.download-pdf")}</span>
              )}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DownloadTestModal;
