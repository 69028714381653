import React from "react";
import classNames from "classnames";

import isEmpty from "ramda/src/isEmpty";
import pathOr from "ramda/src/pathOr";
import path from "ramda/src/path";

// Types
import {
  resultObject,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  id: string;
  result?: resultObject;
  onAnswerChange: (number: number, answer: number) => any;
}

export const AnswerChoiceCheckbox = (props: Props) => {
  const isChecked = (answer: number) => {
    return pathOr<number[]>([], ["result", "answer_choices", "selected"], props).includes(answer);
  };

  const renderValue = (answer: number) => {
    const answerChoicesRepresentation = pathOr([], ["result", "answer_choices_representation", "all"], props);
    if (answerChoicesRepresentation && answerChoicesRepresentation.length) {
      return answerChoicesRepresentation[answer - 1];
    }
    return "";
  };

  const inputContainerClass = (isChecked: boolean) => classNames({
    [styles.inputContainer]: true,
    [styles.inputContainerChecked]: isChecked,
    [styles.inputContainerNotChecked]: !isChecked,
  });

  return (
    <>
      {
        !isEmpty(props.result) &&
        path(["result", "number"], props) &&
        pathOr([], ["result", "answer_choices", "all"], props).map((answer: number, index: number) => (
          <div
            key={`${index}_${path(["result", "number"], props)}`}
            className={inputContainerClass(isChecked(answer))}
          >
            <input
              type="checkbox"
              id={`acc_${answer}_${path(["result", "number"], props)}`}
              name={`acc_${answer}_${path(["result", "number"], props)}`}
              checked={isChecked(answer)}
              value={renderValue(answer)}
              onChange={() => props.onAnswerChange(
                pathOr(NaN, ["result", "number"], props),
                answer,
              )}
            />
            <label htmlFor={`acc_${answer}_${path(["result", "number"], props)}`}>
              {renderValue(answer)}
            </label>
          </div>
        ))
      }
    </>
  );
};

export default AnswerChoiceCheckbox;
