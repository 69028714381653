import { ErrorObject } from "../../common";

export const userRoles = {
  admin: "admin",
  unconfirmed: "unconfirmed",
};

export interface userStateModel {
  email: string;
  roles: string[];
  card_info: {
    last4: string;
    brand: string;
  };
  access: {
    expires_at: string;
    full_access: boolean;
  };
  app: string;
  currency: string;
  language: string;
  country: string;
  in_progress: {
    billing_card: boolean;
  };
  errors: {
    billing_card: ErrorObject[];
  };
};

export interface userActionModel {
  type: string;
  payload?: any;
};
