import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";
import path from "ramda/src/path";

import { ReactComponent as ArrowDown } from "../../../assets/images/small_arrow_down.svg";
import { ReactComponent as Logo } from "../../../assets/images/wisdy_logo.svg";
import { ReactComponent as DiscoverIcon } from "../../../assets/images/discover_icon.svg";
// import { ReactComponent as TestsBaseIcon } from "../../../assets/images/tests_base_icon.svg";

import translate from "../../../services/translate";

// Types
import { Location, History } from "history";
import { classModel } from "../../../models/redux/teacher";
import { userStateModel } from "../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  history: History;
  classes: classModel[];
  user: userStateModel;
}

export const Sidebar = (props: Props) => {
  const [activeClass, setActiveClass] = useState<string>("");

  useEffect(() => {
    const headings = Array.from(
      document.querySelectorAll("[id*=sidebar_heading]"),
    );

    headings.forEach((item: any, index: number) => {
      item.style.display = "none";
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (path(["open_class_dropdown"], parsed)) {
      setActiveClass(parsed.open_class_dropdown as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classes]);

  const onSidebarScroll = () => {
    const sidebar = document.querySelector(
      "[class*=sidebarContainer]",
    ) as HTMLElement;
    if (sidebar) {
      const sidebarScrollTop = sidebar.scrollTop;
      const headingContainer = document.querySelector(
        "[class*=headingContainer]",
      ) as HTMLElement;

      if (headingContainer) {
        const headings = Array.from(
          document.querySelectorAll("[id*=sidebar_heading]"),
        );

        if (headings.length) {
          headings.forEach((item: any, index: number) => {
            const box = document.querySelector(
              `[id*=sidebar_box_${index + 1}]`,
            ) as HTMLElement;

            if (!index) {
              item.style.position = "fixed";
              item.style.zIndex = 1;
              item.style.display = "flex";
              item.style.opacity = 0;

              if (sidebarScrollTop > box.offsetTop - 300) {
                item.style.opacity = 1;
              }
            }

            if (index && box && sidebarScrollTop > box.offsetTop - 300) {
              item.style.position = "fixed";
              item.style.zIndex = 1;
              item.style.display = "flex";
            } else if (index) {
              item.style.position = "initial";
              item.style.display = "none";
            }
          });
        }
      }
    }
  };

  const isClassActive = (id: string) => {
    return activeClass === id;
  };

  const toggleClass = (id: string) => {
    if (!isClassActive(id)) {
      setActiveClass(id);
      return;
    }

    setActiveClass("");
  };

  const arrowClass = (id: string) =>
    classNames({
      [styles.active]: isClassActive(id),
    });

  const isActive = (value: string) =>
    classNames({
      [styles.active]: props.history.location.pathname.includes(value),
    });

  const discoverClass = classNames({
    [styles.discover]: true,
    [styles.active]: props.history.location.pathname === "/",
  });

  // const testsBaseClass = classNames({
  //   [styles.testsBase]: true,
  //   [styles.active]: props.history.location.pathname === "/tests-base",
  // });

  return (
    <div className={styles.sidebarContainer} onScroll={onSidebarScroll}>
      <div className={styles.sidebarScroll}>
        <div className={styles.top}>
          <Link to="/" className={styles.logoLink}>
            <Logo className={styles.logo}/>
          </Link>
          {/* <Link to="/test/creator/select-class" className={styles.createTest}>
            +&nbsp;
            <span>
              {translate("global.create")} {translate("global.test")}
            </span>
          </Link> */}
          <Link to="/" className={discoverClass}>
            <DiscoverIcon/>
            <span>{translate("global.discover")}</span>
          </Link>
          {/* <Link to="/tests-base" className={testsBaseClass}>
            <TestsBaseIcon />
            <span>{translate("global.tests-base")}</span>
          </Link> */}
        </div>
        <div className={styles.headingContainer} id="sidebar_heading_1">
          <span className={styles.heading}>{translate("global.classes")}</span>
          <Link to="/class/add" className={styles.addClass}>
            <span>+</span>
          </Link>
        </div>
        <div className={styles.headingContainer}>
          <span className={styles.heading}>{translate("global.classes")}</span>
          <Link to="/class/add" className={styles.addClass}>
            <span>+</span>
          </Link>
        </div>
        <ul className={styles.list} id="sidebar_box_1">
          {props.classes.map((item) => (
            <li key={item.id} className={isActive(item.id)}>
              <button
                type="button"
                onClick={() => toggleClass(item.id)}
                className={isActive(item.id)}
              >
                <span>{item.name}</span>
                <ArrowDown className={arrowClass(item.id)}/>
              </button>
              {isClassActive(item.id) && (
                <ul>
                  <li className={isActive(`/class/${item.id}/tests`)}>
                    <Link to={`/class/${item.id}/tests`}>
                      <span>{translate("global.tests")}</span>
                    </Link>
                  </li>
                  <li className={isActive(`/class/${item.id}/lessons`)}>
                    <Link to={`/class/${item.id}/lessons`}>
                      <span>{translate("global.lessons")}</span>
                    </Link>
                  </li>
                  <li className={isActive(`/class/${item.id}/students`)}>
                    <Link to={`/class/${item.id}/students`}>
                      <span>{translate("global.students-list")}</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ul>
        <div className={styles.headingContainer} id="sidebar_heading_2">
          <span className={styles.heading}>{translate("global.folders")}</span>
        </div>
        {/* <div className={styles.headingContainer}>
          <span className={styles.heading}>{translate("global.other")}</span>
        </div>
        <ul className={styles.list} id="sidebar_box_2">
          <li className={isActive("/tests/all")}>
            <Link to="/tests/all">
              <span>{translate("global.all-tests")}</span>
            </Link>
          </li>
          {isPolish(props.user.language) &&
            <li className={isActive("/formative-assessment")}>
              <Link to="/formative-assessment">
                <span>{translate("global.formative-assessment-shortcut")}</span>
              </Link>
            </li>
          }
        </ul> */}
      </div>
    </div>
  );
};

export default Sidebar;
