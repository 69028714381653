import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";

import Input from "../../Common/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translate from "../../../services/translate";
import { getErrorMessage } from "../../../services/common";

import { createClass, unmountTeacher } from "../../../redux/actions/teacher";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  classModel,
  createClass as createClassFunction,
  subjectModel,
} from "../../../models/redux/teacher";
import { ErrorsPayload } from "../../../models/common";

import styles from "../../App/Class/Add/styles.module.scss";

interface Props {
  history: History;
  location: Location;
  isLoading: boolean;
  errors: ErrorsPayload[];
  unmountTeacher: any;
  createClass: createClassFunction;
  classes: classModel[];
  subjects: subjectModel[];
}

export const DemoAddClass = (props: Props) => {
  const [class_name, setClassName] = useState("");
  const [number_of_students, setNumberOfStudents] = useState("");

  const parsed = queryString.parse(props.location.search);
  const subjectType = parsed.subject as string;

  const pickedSubject = props.subjects.find(
    (item) => item.type === subjectType,
  );

  useEffect(() => {
    if (!subjectType) {
      props.history.replace("/demo");
    } else if (props.classes.length) {
      props.history.replace(
        `/demo/test/creator?classID=${props.classes[0].id}&subject=${subjectType}`,
      );
    }

    return () => {
      props.unmountTeacher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNextButtonDisabled = () =>
    !class_name.length || !number_of_students || Number(number_of_students) < 1;

  return (
    <section className={styles.container}>
      <div className={styles.top}>
        <h2>
          {translate("global.create")}&nbsp;
          <span className={styles.primaryHeadingText}>
            {translate("class.class-accusative")}
          </span>
        </h2>
        <p>{translate("class.create-class-desc")}</p>
        <div className={styles.box}>
          <div className={styles.inputContainer}>
            <Input
              id="class_name"
              type="text"
              name="class_name"
              value={class_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setClassName(e.target.value)
              }
              label={translate("class.classname")}
              error={getErrorMessage(props.errors, "name")}
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              id="number_of_students"
              type="number"
              name="number_of_students"
              value={number_of_students}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNumberOfStudents(e.target.value)
              }
              label={translate("class.number-of-students")}
              error={getErrorMessage(props.errors, "number_of_students")}
            />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <button
            className={styles.goBackButton}
            onClick={() => props.history.back()}
          >
            {translate("global.back")}
          </button>
          <button
            className={styles.nextButton}
            onClick={() => {
              props.createClass(
                {
                  name: class_name,
                  number_of_students: Number(number_of_students),
                  history: props.history,
                  selected_curriculums: [],
                  subject_id: pickedSubject ? pickedSubject.id : "",
                },
                (data: any) =>
                  props.history.replace(
                    `/demo/test/creator?classID=${data.data.id}&subject=${subjectType}`,
                  ),
              );
            }}
            disabled={isNextButtonDisabled() || props.isLoading}
          >
            {props.isLoading ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <>{translate("global.next")}</>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: { teacher: teacherStateModel }) => ({
  isLoading: state.teacher.isLoading,
  errors: state.teacher.errors,
  classes: state.teacher.classes,
  subjects: state.teacher.subjects,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createClass,
      unmountTeacher,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoAddClass);
