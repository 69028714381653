import React, { useState, useEffect, ReactNode } from "react";
import classNames from "classnames";

// import { getLanguageFromLocalStorage } from "../../../../../../services/api";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";
import translate from "../../../../../../services/translate";

interface Props {
  history: History;
  location: Location;
  bottomContent: (
    customSelectedTime: string,
    selectedTime: string
  ) => ReactNode;
  defaultValue?: string;
}

export const SelectTimeContent = (props: Props) => {
  const [selectedTime, updateSelectedTime] = useState<string>("");
  const [customSelectedTime, updateCustomSelectedTime] = useState<string>("");

  const timeButtonClass = (value: string) =>
    classNames({
      [styles.timeLimitButton]: value !== "",
      [styles.noTimeLimitButton]: value === "",
      [styles.active]: selectedTime === value,
    });

  useEffect(() => {
    if (props.defaultValue) {
      const values = ["15", "30", "45", "60"];
      if (values.includes(`${props.defaultValue}`)) {
        updateSelectedTime(`${props.defaultValue}`);
      } else if (`${props.defaultValue}` !== "null") {
        updateCustomSelectedTime(`${props.defaultValue}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <h1>
        {translate("creator.time-limit-1")}{" "}
        <span>{translate("creator.time-limit-2")}</span>
      </h1>
      <p>{translate("creator.time-limit-desc")}</p>
      <div className={styles.box}>
        <ul>
          <li>
            <button
              className={timeButtonClass("15")}
              disabled={!!customSelectedTime}
              onClick={() => updateSelectedTime("15")}
            >
              15<span>min</span>
            </button>
          </li>
          <li>
            <button
              className={timeButtonClass("30")}
              disabled={!!customSelectedTime}
              onClick={() => updateSelectedTime("30")}
            >
              30<span>min</span>
            </button>
          </li>
          <li>
            <button
              className={timeButtonClass("45")}
              disabled={!!customSelectedTime}
              onClick={() => updateSelectedTime("45")}
            >
              45<span>min</span>
            </button>
          </li>
          <li>
            <button
              className={timeButtonClass("60")}
              disabled={!!customSelectedTime}
              onClick={() => updateSelectedTime("60")}
            >
              60<span>min</span>
            </button>
          </li>
          <li>
            <button
              className={timeButtonClass("")}
              disabled={!!customSelectedTime}
              onClick={() => {
                updateSelectedTime("");
                updateCustomSelectedTime("");
              }}
            >
              {translate("creator.no-limit")}
            </button>
          </li>
        </ul>
        <span>{translate("creator.time-limit-or-pass-different-value")}</span>
        <input
          value={customSelectedTime}
          onChange={(e) =>
            Number.isInteger(Number(e.target.value)) &&
            updateCustomSelectedTime(e.target.value)
          }
        />
      </div>
      {props.bottomContent(customSelectedTime, selectedTime)}
    </section>
  );
};

export default SelectTimeContent;
