import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import queryString from "query-string";
import pathOr from "ramda/src/pathOr";
import propOr from "ramda/src/propOr";

import Modal, { ToggleContent } from "../../../../components/Common/Modal";
import Toggle from "../../../Common/Toggle";
import LessonDetailsNav from "./Details/Nav";
import GeneratePublicLinkModal from "../../../Common/GeneratePublicLinkModal";

import { ReactComponent as EyeIcon } from "../../../../assets/images/eye_icon.svg";
import { ReactComponent as PenIcon } from "../../../../assets/images/pen_edit.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/images/trash.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/images/link_icon.svg";
import { ReactComponent as TrashAltIcon } from "../../../../assets/images/trash_alt.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/images/small_arrow_down_grey.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/images/referral/copy.svg";

import { copyToClipboard } from "../../../../services/common/copyToClipboard";

import { unmountClass, getClass } from "../../../../redux/actions/class";
import {
  getLessons,
  unmountLessons,
  lessonsGenerateAccessToken,
} from "../../../../redux/actions/lessons";
import {
  deleteLesson,
  unpublishLesson,
  publishLesson,
} from "../../../../redux/actions/lesson";
import { getSubjects } from "../../../../redux/actions/teacher";
import { showToast } from "../../../../redux/actions/app";

import translate from "../../../../services/translate";

// Types
import { History, Location } from "history";
import {
  classStateModel,
  getClass as getClassFunction,
} from "../../../../models/redux/class";
import {
  lessonItem,
  lessonsStateModel,
  getLessons as getLessonsFunction,
  lessonsGenerateAccessToken as lessonsGenerateAccessTokenFunction,
} from "../../../../models/redux/lessons";
import {
  deleteLesson as deleteLessonFunction,
  lessonStatus,
  lessonStateModel,
  unpublishLesson as unpublishLessonFunction,
  publishLesson as publishLessonFunction,
} from "../../../../models/redux/lesson";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
  class: classStateModel;
  lessons: lessonsStateModel;
  lesson: lessonStateModel;
  teacher: teacherStateModel;
  unmountClass: () => {};
  unmountLessons: () => {};
  getClass: getClassFunction;
  getLessons: getLessonsFunction;
  deleteLesson: deleteLessonFunction;
  getSubjects: getSubjectsFunction;
  unpublishLesson: unpublishLessonFunction;
  publishLesson: publishLessonFunction;
  showToast: any;
  lessonsGenerateAccessToken: lessonsGenerateAccessTokenFunction;
}

export const LessonsClass = (props: Props) => {
  const parsed = queryString.parse(props.location.search);

  const classID = pathOr("", ["match", "params", "classID"], props);

  const accessToken = propOr("", "access-token", parsed) as string;
  const classIDFromSearchQuery = propOr("", "class-id", parsed) as string;
  const isPublic = props.location.pathname.startsWith("/lessons");

  const [lessonInfo, setLessonInfo] = useState<string[]>([]);

  const selectedClass = props.teacher.classes.find(
    (classItem) => classItem.id === classID,
  );

  useEffect(() => {
    props.getClass({ id: classID });
    props.getLessons({
      class_id: classID || classIDFromSearchQuery,
      access_token: accessToken,
    });
    props.getSubjects();

    return () => {
      props.unmountClass();
      props.unmountLessons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getClass({ id: classID });
    props.getLessons({
      class_id: classID || classIDFromSearchQuery,
      access_token: accessToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classID]);

  useEffect(() => {
    if (props.lessons.in_progress.update_lesson_status) {
      props.getLessons({
        class_id: classID || classIDFromSearchQuery,
        access_token: accessToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lessons.in_progress.update_lesson_status]);

  useEffect(() => {
    if (selectedClass && selectedClass.id && !selectedClass.subject.id) {
      props.history.replace(`/pick-up-subjects/${classID}`);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  const arrowClass = (id: string) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: lessonInfo.includes(id),
    });

  const containerClass = classNames({
    [styles.container]: true,
    [styles.containerPublic]: isPublic,
  });

  const toggleArrow = (id: string) => {
    const isChecked = lessonInfo.includes(id);

    if (isChecked) {
      setLessonInfo(lessonInfo.filter((value) => value !== id));
      return;
    }

    setLessonInfo(lessonInfo.concat(id));
  };

  return (
    <div className={containerClass}>
      {isPublic && <LessonDetailsNav/>}
      {!isPublic && (
        <div className={styles.header}>
          <h3>
            <Link to={`/class/${props.class.id}`}>{props.class.name}</Link>
            &nbsp;-&nbsp;
            <span>{translate("global.lessons")}</span>
          </h3>
          <div className={styles.buttonsContainer}>
            {!props.lessons.access_token && !!props.lessons.list.length && (
              <button
                type="button"
                onClick={() =>
                  props.lessonsGenerateAccessToken({ class_id: classID })
                }
                disabled={props.lessons.in_progress.update_lessons}
              >
                <span>{translate("lessons.generate-link-to-lesson-list")}</span>
              </button>
            )}
            <Link to={`/class/${props.class.id}/lessons/add`}>
              <span>+&nbsp;</span>
              <span>{translate("lessons.add-lesson")}</span>
            </Link>
          </div>
        </div>
      )}
      <div className={styles.view}>
        {props.lessons.access_token && !isPublic && (
          <div className={styles.settings}>
            <span className={styles.sectionText}>
              {translate("lessons.public-link-to-lesson")}
            </span>
            <div className={styles.settingsBox}>
              <div className={styles.generatePublicLinkBox}>
                <p>{translate("lessons.public-link-to-lesson-description")}</p>
                <div className={styles.right}>
                  <div className={styles.inputContainer}>
                    <input
                      id="lesson_access_token_input"
                      type="text"
                      value={
                        props.lessons.access_token
                          ? `${window.location.origin}/lessons?class-id=${classID}&access-token=${props.lessons.access_token}`
                          : ""
                      }
                      onChange={() => {}}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard("lesson_access_token_input", () =>
                          props.showToast({
                            text: "global.copied",
                            status: "success",
                          }),
                        )
                      }
                    >
                      <CopyIcon/>
                      &nbsp;&nbsp;
                      <span>{translate("global.copy")}</span>
                    </button>
                  </div>
                  {props.lessons.access_token && (
                    <ToggleContent
                      toggle={(show: any) => (
                        <button
                          type="button"
                          className={styles.regenerateLinkButton}
                          onClick={show}
                        >
                          {translate("lessons.generate-new-lesson-public-link")}
                        </button>
                      )}
                      content={(hide: () => {}) => (
                        <GeneratePublicLinkModal
                          hide={hide}
                          onSubmit={() =>
                            props.lessonsGenerateAccessToken(
                              { class_id: classID },
                              hide,
                            )
                          }
                          inProgress={props.lessons.in_progress.update_lessons}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ul className={styles.lessons}>
          {!!props.lessons.list.length && (
            <li className={styles.headingItem}>
              <div className={styles.lessonTopic}>
                <span>{translate("lessons.lesson-topic")}</span>
              </div>
              <div className={styles.lessonSubject}>
                <span>{translate("global.subject")}</span>
              </div>
              <div className={styles.lessonDate}>
                <span>{translate("global.date-added")}</span>
              </div>
              {!isPublic && (
                <div className={styles.lessonStatus}>
                  <span>{translate("lessons.status")}</span>
                </div>
              )}
              <div className={styles.actions}>
                <span>{translate("class.actions")}</span>
              </div>
            </li>
          )}
          {props.lessons.list.map((item: lessonItem) => (
            <li className={styles.listItem} key={item.id}>
              <div className={styles.lessonTopic}>
                <div className={styles.tooltipContainer}>
                  <ReactTooltip
                    id={`tooltip-lesson-name-${item.id}`}
                    place="right"
                    effect="solid"
                    multiline
                    className={styles.tooltip}
                    disable={item.topic.length < 26}
                  >
                    <p>{item.topic}</p>
                  </ReactTooltip>
                  <span data-tip data-for={`tooltip-lesson-name-${item.id}`}>
                    {item.topic}
                  </span>
                </div>
              </div>
              <div className={styles.lessonSubject}>
                <span>{item.subject_name}</span>
              </div>
              <div className={styles.lessonDate}>
                <span>{item.inserted_at}</span>
              </div>
              {!isPublic && (
                <div className={styles.lessonStatus}>
                  <Toggle
                    rightText={
                      item.status === lessonStatus.public
                        ? translate("lessons.public-lesson-short")
                        : translate("lessons.private-lesson-short")
                    }
                    onChange={() => {
                      if (item.status === lessonStatus.public) {
                        props.unpublishLesson({ lesson_id: item.id });
                        return;
                      }
                      props.publishLesson({ lesson_id: item.id });
                    }}
                    isOn={item.status === lessonStatus.public}
                    isDisabled={
                      props.lesson.in_progress.update_lesson ||
                      props.lessons.in_progress.update_lesson_status
                    }
                    isGrayedOut
                  />
                </div>
              )}
              <div className={styles.actions}>
                {!isPublic && item.access_token && (
                  <div className={styles.tooltipContainer}>
                    <ReactTooltip
                      id="tooltip-copy-lesson-link"
                      place="left"
                      effect="solid"
                      className={styles.tooltip}
                    >
                      <p>{translate("lessons.copy-public-link")}</p>
                    </ReactTooltip>
                    <i data-tip data-for="tooltip-copy-lesson-link">
                      <input
                        id={`lesson_public_link_${item.id}`}
                        className={styles.hiddenInput}
                        type="text"
                        value={`${window.location.origin}/lessons/${item.id}?access-token=${item.access_token}`}
                        onChange={() => {}}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          copyToClipboard(`lesson_public_link_${item.id}`, () =>
                            props.showToast({
                              text: "global.copied",
                              status: "success",
                            }),
                          )
                        }
                      >
                        <LinkIcon/>
                      </button>
                    </i>
                  </div>
                )}
                <div className={styles.tooltipContainer}>
                  <ReactTooltip
                    id="tooltip-lesson-details"
                    place="left"
                    effect="solid"
                    className={styles.tooltip}
                  >
                    <p>{translate("global.details")}</p>
                  </ReactTooltip>
                  <i data-tip data-for="tooltip-lesson-details">
                    <Link
                      to={
                        !isPublic
                          ? `/class/${props.class.id}/lessons/${item.id}`
                          : `/lessons/${item.id}?access-token=${item.access_token}`
                      }
                    >
                      <EyeIcon/>
                    </Link>
                  </i>
                </div>
                {!isPublic && (
                  <div className={styles.tooltipContainer}>
                    <ReactTooltip
                      id="tooltip-lesson-edit"
                      place="left"
                      effect="solid"
                      className={styles.tooltip}
                    >
                      <p>{translate("global.edit")}</p>
                    </ReactTooltip>
                    <i data-tip data-for="tooltip-lesson-edit">
                      <Link
                        to={`/class/${props.class.id}/lessons/${item.id}/edit`}
                      >
                        <PenIcon/>
                      </Link>
                    </i>
                  </div>
                )}
                {!isPublic && (
                  <ToggleContent
                    toggle={(show: any) => (
                      <div className={styles.tooltipContainer}>
                        <ReactTooltip
                          id="tooltip-lesson-delete"
                          place="left"
                          effect="solid"
                          className={styles.tooltip}
                        >
                          <p>{translate("global.delete")}</p>
                        </ReactTooltip>
                        <i data-tip data-for="tooltip-lesson-delete">
                          <button type="button" onClick={show}>
                            <TrashIcon/>
                          </button>
                        </i>
                      </div>
                    )}
                    content={(hide: () => {}) => (
                      <Modal isSmall hide={hide}>
                        <div className={styles.deleteLessonModal}>
                          <TrashAltIcon/>
                          <span>
                            {translate("lessons.delete-lesson-confirmation")}
                          </span>
                          <div className={styles.buttonContainer}>
                            <button type="button" onClick={hide}>
                              {translate("global.no")}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                props.deleteLesson(
                                  {
                                    lesson_id: item.id,
                                    class_id: classID,
                                  },
                                  () => hide(),
                                );
                              }}
                            >
                              {translate("global.yes")}
                            </button>
                          </div>
                        </div>
                      </Modal>
                    )}
                  />
                )}
                <button
                  className={arrowClass(item.id)}
                  onClick={() => toggleArrow(item.id)}
                >
                  <ArrowIcon/>
                </button>
              </div>
              {lessonInfo.includes(item.id) && (
                <div className={styles.dropDownContainer}>
                  <ul>
                    <li className={styles.lessonSubject}>
                      <span>{translate("global.subject")}</span>
                      <span>{item.subject_name}</span>
                    </li>
                    {!isPublic && (
                      <li className={styles.lessonDate}>
                        <span>{translate("global.date-added")}</span>
                        <span>{item.inserted_at}</span>
                      </li>
                    )}
                    {!isPublic && (
                      <li className={styles.lessonStatus}>
                        <span>{translate("lessons.status")}</span>
                        <Toggle
                          rightText={
                            item.status === lessonStatus.public
                              ? translate("lessons.public-lesson-short")
                              : translate("lessons.private-lesson-short")
                          }
                          onChange={() => {
                            if (item.status === lessonStatus.public) {
                              props.unpublishLesson({ lesson_id: item.id });
                              return;
                            }
                            props.publishLesson({ lesson_id: item.id });
                          }}
                          isOn={item.status === lessonStatus.public}
                          isDisabled={
                            props.lesson.in_progress.update_lesson ||
                            props.lessons.in_progress.update_lesson_status
                          }
                          isGrayedOut
                        />
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  classReducer: classStateModel;
  lessons: lessonsStateModel;
  lesson: lessonStateModel;
  teacher: teacherStateModel;
}) => ({
  class: state.classReducer,
  lessons: state.lessons,
  lesson: state.lesson,
  teacher: state.teacher,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClass,
      unmountClass,
      getLessons,
      unmountLessons,
      deleteLesson,
      getSubjects,
      unpublishLesson,
      publishLesson,
      showToast,
      lessonsGenerateAccessToken,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonsClass);
