import React from "react";
import pathOr from "ramda/src/pathOr";
import classNames from "classnames";

import { notesTypes } from "../../../../../../../services/common/test";

import { ReactComponent as LinesNote } from "../../../../../../../assets/images/question_notes/lines.svg";
import { ReactComponent as CheckedNote } from "../../../../../../../assets/images/question_notes/checked.svg";
import { ReactComponent as BlankNote } from "../../../../../../../assets/images/question_notes/blank.svg";
import { ReactComponent as DottedNote } from "../../../../../../../assets/images/question_notes/dotted.svg";
import { ReactComponent as PenmanshipNote } from "../../../../../../../assets/images/question_notes/penmanship.svg";

// Types
import {
  questionModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  question: questionModel;
  reloadPreview?: () => any;
}

export const QuestionNotesPreview = (props: Props) => {
  const noteSizeArray = Array.from(
    Array(
      pathOr([], ["question", "type_data", "notes_size"], props),
    ).keys(),
  );

  const noteContainerClass = (index: number) => classNames({
    [styles.noteContainer]: true,
    [styles.noteContainerFirst]: !index && props.question.type_data.notes_size > 1,
    [styles.noteContainerLast]: (
      index === (props.question.type_data.notes_size -1) &&
      props.question.type_data.notes_size > 1
    ),
    [styles.noteContainerSingle]: props.question.type_data.notes_size === 1,
  });

  const noteContainerBlankClass = (index: number) => classNames({
    [styles.noteContainerBlank]: true,
    [styles.noteContainerBlankFirst]: !index && props.question.type_data.notes_size > 1,
    [styles.noteContainerBlankLast]: (
      index === (props.question.type_data.notes_size -1) &&
      props.question.type_data.notes_size > 1
    ),
  });

  return (
    <div data-type="question-notes" className={styles.questionNotes}>
      {(props.question.type_data.notes_type === notesTypes.checked && !!props.question.type_data.notes_size) &&
        noteSizeArray.map((item: number, index: number) => (
          <div
            className={noteContainerClass(index)}
            data-type="question-notes-checked"
            key={item}
          >
            <CheckedNote/>
          </div>
        ))
      }
      {(props.question.type_data.notes_type === notesTypes.lines && !!props.question.type_data.notes_size) &&
        noteSizeArray.map((item: number, index: number) => (
          <div
            className={noteContainerClass(index)}
            data-type="question-notes-lines"
            key={item}
          >
            <LinesNote/>
          </div>
        ))
      }
      {(props.question.type_data.notes_type === notesTypes.penmanship && !!props.question.type_data.notes_size) &&
        noteSizeArray.map((item: number, index: number) => (
          <div
            className={noteContainerClass(index)}
            data-type="question-notes-penmanship"
            key={item}
          >
            <PenmanshipNote/>
          </div>
        ))
      }
      {(props.question.type_data.notes_type === notesTypes.dotted && !!props.question.type_data.notes_size) &&
        noteSizeArray.map((item: number, index: number) => (
          <div
            className={noteContainerClass(index)}
            data-type="question-notes-dotted"
            key={item}
          >
            <DottedNote/>
          </div>
        ))
      }
      {(props.question.type_data.notes_type === notesTypes.blank && !!props.question.type_data.notes_size) &&
        <div data-type="question-notes-blank-container" className={styles.blankNoteType}>
          {noteSizeArray.map((item: number, index: number) => (
            <div
              data-type="question-notes-blank"
              className={noteContainerBlankClass(index)}
              key={item}
            >
              <BlankNote/>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default QuestionNotesPreview;
