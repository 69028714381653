import pathOr from "ramda/src/pathOr";

// Types
import { curriculumStateModel, curriculumActionModel } from "../../../models/redux/curriculum";

const initialState: curriculumStateModel = {
  education_level: [],
  curriculums: [],
  class_curriculums: [],
  curriculum_details: {},
  question_skills: [],
  in_progress: {
    get_education_level: false,
    get_curriculums: false,
    get_curriculum_for_class: false,
    update_class_skills: false,
    delete_curriculum: false,
    get_question_skills: false,
    update_question_skills: false,
  },
};

export const curriculum = (state = initialState, action: curriculumActionModel) => {
  switch (action.type) {
    case "GET_EDUCATION_LEVEL":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_education_level: true,
        },
      };
    case "GET_EDUCATION_LEVEL_SUCCESS":
      return {
        ...state,
        education_level: pathOr([], ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_education_level: false,
        },
      };
    case "GET_EDUCATION_LEVEL_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_education_level: false,
        },
      };
    case "GET_CURRICULUMS":
      return {
        ...state,
        curriculums: [],
        in_progress: {
          ...state.in_progress,
          get_curriculums: true,
        },
      };
    case "GET_CURRICULUMS_SUCCESS":
      return {
        ...state,
        curriculums: pathOr([], ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_curriculums: false,
        },
      };
    case "GET_CURRICULUMS_FAILURE":
      return {
        ...state,
        curriculums: pathOr([], ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_curriculums: false,
        },
      };
    case "GET_CURRICULUMS_FOR_CLASS_SUCCESS":
      return {
        ...state,
        class_curriculums: pathOr([], ["payload", "data"], action),
      };
    case "GET_CURRICULUM_FOR_CLASS":
      return {
        ...state,
        curriculum_details: initialState.curriculum_details,
        in_progress: {
          ...state.in_progress,
          get_curriculum_for_class: true,
        },
      };
    case "GET_CURRICULUM_FOR_CLASS_SUCCESS":
      return {
        ...state,
        curriculum_details: pathOr({}, ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_curriculum_for_class: false,
        },
      };
    case "GET_CURRICULUM_FOR_CLASS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_curriculum_for_class: false,
        },
      };
    case "UPDATE_CLASS_SKILLS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_class_skills: true,
        },
      };
    case "UPDATE_CLASS_SKILLS_SUCCESS":
    case "UPDATE_CLASS_SKILLS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_class_skills: false,
        },
      };
    case "DELETE_CURRICULUM":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          delete_curriculum: true,
        },
      };
    case "DELETE_CURRICULUM_SUCCESS":
    case "DELETE_CURRICULUM_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          delete_curriculum: false,
        },
      };
    case "GET_QUESTION_SKILLS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_question_skills: true,
        },
      };
    case "GET_QUESTION_SKILLS_SUCCESS":
      return {
        ...state,
        question_skills: pathOr([], ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_question_skills: false,
        },
      };
    case "GET_QUESTION_SKILLS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_question_skills: false,
        },
      };
    case "UPDATE_QUESTION_SKILLS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_question_skills: true,
        },
      };
    case "UPDATE_QUESTION_SKILLS_SUCCESS":
    case "UPDATE_QUESTION_SKILLS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_question_skills: false,
        },
      };
    case "UNMOUNT_CURRICULUM":
      return initialState;
    default:
      return state;
  }
};
