interface MyWindow extends Window {
  ga: any;
}

declare var window: MyWindow;

const doesGAExist = () => (window as any) && window.ga;

export const sendCopyReferralLinkEvent = () => {
  if (doesGAExist()) {
    window.ga("send", {
      hitType: "event",
      eventCategory: "Referral",
      eventAction: "copy link",
      eventLabel: "Copy referral link",
    });
  }
};
