import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import propOr from "ramda/src/propOr";
import queryString from "query-string";
import AuthContainer from "../Common/Auth/Container";
import translate from "../../services/translate";
import { getLandingPageURL } from "../../services/common";

import {
  signUpConfirm,
  signUpConfirmResendLink,
} from "../../redux/actions/auth";

// Types
import { History, Location } from "history";
import {
  authStateModel,
  signUpConfirm as signUpConfirmFunction,
  signUpConfirmResendLink as signUpConfirmResendLinkFunction,
} from "../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  errors: any[];
  isLoading: boolean;
  signUpConfirm: signUpConfirmFunction;
  signUpConfirmResendLink: signUpConfirmResendLinkFunction;
  location: Location;
  history: History;
}

export const AccountConfirmation = (props: Props) => {
  useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    if (parsed && parsed.token) {
      props.signUpConfirm({
        token: propOr("", "token", parsed),
        history: props.history,
      });
      return;
    }

    props.history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!props.errors.length && (
        <AuthContainer
          heading={translate("account_confirmation.ups")}
          error
          hideIcon
        >
          <div className={styles.container}>
            <p className={styles.info}>
              {translate("account_confirmation.account-cannot-be-confirmed")}
            </p>
            <div className={styles.buttonsContainer}>
              <button
                type="button"
                onClick={() => {
                  props.signUpConfirmResendLink({
                    history: props.history,
                  });
                }}
              >
                {translate("account_confirmation.send-again")}
              </button>
            </div>
            <div className={styles.linkContainer}>
              <a href={getLandingPageURL()}>
                {translate("global.back-to-homepage")}
              </a>
            </div>
          </div>
        </AuthContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: { auth: authStateModel }) => ({
  errors: state.auth.errors,
  isLoading: state.auth.in_progress.general,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      signUpConfirm,
      signUpConfirmResendLink,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountConfirmation);
