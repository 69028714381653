import pathOr from "ramda/src/pathOr";
import uniq from "ramda/src/uniq";

// Types
import {
  testStateModel,
  testActionModel,
  questionModel,
} from "../../../models/redux/test";

const initialState: testStateModel = {
  id: "",
  class_id: "",
  class_name: "",
  lang: "",
  name: "",
  subject: {
    id: "",
    name: "",
    type: "",
  },
  question_types: [],
  questions: [],
  errors: [],
  status: "",
  type: "",
  updated_at: "",
  ends_at: "",
  in_progress: {
    get_test: false,
    generate_pdf: false,
    create_question: false,
    update_question: false,
    duplicate_test: false,
    update_test: false,
    send_answers_by_student: false,
    question: "",
  },
  has_curriculum: false,
  has_skills: false,
  time_limit: null,
};

export const test = (state = initialState, action: testActionModel) => {
  const question = state.questions.find(
    (question: questionModel) => question.id === pathOr("", ["payload", "questionID"], action),
  ) as questionModel;

  // const question = propOr([], "questions", state).find(
  //   (question: questionModel) => propOr("", "id", question) === pathOr("", ["payload", "questionID"], action)
  // ) as questionModel;

  switch (action.type) {
    case "CREATE_TEST_SUCCESS":
      return {
        ...state,
        id: pathOr("", ["payload", "data", "id"], action),
        errors: [],
      };
    case "UPDATE_TEST":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_test: true,
        },
      };
    case "UPDATE_TEST_SUCCESS":
      return {
        ...state,
        errors: state.errors.filter((item: any) => (
          item.source.pointer !== `${pathOr("", ["payload", "testID"], action)}/name`
        )),
        in_progress: {
          ...state.in_progress,
          update_test: false,
        },
      };
    case "UPDATE_TEST_FAILURE":
      return {
        ...state,
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
        in_progress: {
          ...state.in_progress,
          update_test: false,
        },
      };
    case "UPDATE_QUESTION":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_question: true,
        },
      };
    case "UPDATE_QUESTION_SUCCESS":
      return {
        ...state,
        errors: state.errors.filter((item: any) => (
          !item.source.pointer
            .includes(`questions/${pathOr("", ["payload", "questionID"], action)}/body`)
        )),
        in_progress: {
          ...state.in_progress,
          update_question: false,
        },
      };
    case "UPDATE_QUESTION_FAILURE":
      return {
        ...state,
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
        in_progress: {
          ...state.in_progress,
          update_question: false,
        },
      };
    case "UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS":
      let errorsList = state.errors;
      if (action.payload.isCorrect) {
        errorsList = state.errors.filter((item: any) => (
          !item.source.pointer
            .includes(`questions/${pathOr("", ["payload", "questionID"], action)}/answer_choices`)
        ));
      }
      return {
        ...state,
        errors: errorsList.filter((item: any) => (
          !item.source.pointer.includes(`${pathOr("", ["payload", "answerID"], action)}`)
        )),
      };
    case "CREATE_TEST_FAILURE":
      return {
        ...state,
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
      };
    case "GET_QUESTION_TYPES_SUCCESS":
      return {
        ...state,
        question_types: pathOr([], ["payload", "data"], action),
      };
    case "GET_TEST":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_test: true,
        },
      };
    case "GET_TEST_SUCCESS":
      return {
        ...state,
        ...pathOr({}, ["payload", "data"], action),
        in_progress: {
          ...state.in_progress,
          get_test: false,
        },
      };
    case "GET_TEST_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          get_test: false,
        },
      };
    case "GET_TEST_FROM_TESTS_BASE_SUCCESS":
      return {
        ...state,
        ...pathOr({}, ["payload", "data"], action),
      };
    case "GET_QUESTION_TYPES":
      return {
        ...state,
      };
    case "DELETE_QUESTION_ANSWER_CHOICE":
      return {
        ...state,
        questions: Object.assign(
          [],
          state.questions,
          {
            [state.questions.indexOf(question)]: {
              ...question,
              answer_choices: question.answer_choices
                .filter(answer => answer.id !== pathOr("", ["payload", "answerID"], action)),
            },
          },
        ),
      };
    case "DELETE_QUESTION_MATCHING_SUCCESS":
      return {
        ...state,
        questions: Object.assign(
          [],
          state.questions,
          {
            [state.questions.indexOf(question)]: {
              ...question,
              matchings: Object.assign(
                [],
                ({
                  0: (question && question.matchings && question.matchings[0]) ?
                    question.matchings[0].filter(item => item.id !== pathOr("", ["payload", "matchingID"], action)) :
                    question.matchings[0],
                  1: (question && question.matchings && question.matchings[1]) ?
                    question.matchings[1].filter(item => item.id !== pathOr("", ["payload", "matchingID"], action)) :
                    question.matchings[1],
                }),
              ),
            },
          },
        ),
      };
    case "CREATE_QUESTION":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          create_question: true,
        },
      };
    case "CREATE_QUESTION_SUCCESS":
    case "CREATE_QUESTION_FAILURE":
    case "CREATE_QUESTION_ANSWER_CHOICE_SUCCESS":
      const newAnswerChoice = {
        id: action.payload.answerID,
        name_html: "",
        name: "",
        order: (question && question.answer_choices) ?
          question.answer_choices.length + 1 :
          1,
        is_correct: false,
      };
      return {
        ...state,
        questions: Object.assign(
          [],
          state.questions,
          {
            [state.questions.indexOf(question)]: {
              ...question,
              answer_choices: (question && question.answer_choices) ?
                question.answer_choices.concat(newAnswerChoice) :
                [newAnswerChoice],
            },
          },
        ),
        in_progress: {
          ...state.in_progress,
          create_question: false,
        },
      };
    case "CREATE_QUESTION_MATCHING_SUCCESS":
      const newItem = {
        id: action.payload.matchingID,
        item: "",
        item_html: "",
        order: (question && question.matchings) ?
          question.matchings[0].length + 1 :
          1,
      };

      const newMatchedItem = {
        id: action.payload.matchingID,
        matched_item: "",
        matched_item_html: "",
        order: (question && question.matchings) ?
          question.matchings[1].length + 1 :
          1,
      };

      return {
        ...state,
        questions: Object.assign(
          [],
          state.questions,
          {
            [state.questions.indexOf(question)]: {
              ...question,
              matchings: Object.assign(
                [],
                ({
                  0: (question && question.matchings && question.matchings[0]) ? question.matchings[0].concat(newItem) : [newItem],
                  1: (question && question.matchings && question.matchings[1]) ? question.matchings[1].concat(newMatchedItem) : [newMatchedItem],
                }),
              ),
            },
          },
        ),
        in_progress: {
          ...state.in_progress,
          create_question: false,
        },
      };
    case "GENERATE_PDF":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          generate_pdf: true,
        },
      };
    case "GENERATE_PDF_FAILURE":
    case "GENERATE_PDF_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          generate_pdf: false,
        },
      };
    case "DUPLICATE_TEST":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          duplicate_test: true,
        },
      };
    case "DUPLICATE_TEST_FAILURE":
    case "DUPLICATE_TEST_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          duplicate_test: false,
        },
      };
    case "VALIDATE_TEST":
      return {
        ...state,
        errors: [],
      };
    case "VALIDATE_TEST_FAILURE":
      return {
        ...state,
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
      };
    case "UNMOUNT_TEST":
      return {
        ...state,
        ...initialState,
      };
    case "CREATE_TEST_RESULTS_BY_STUDENT":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          send_answers_by_student: true,
        },
      };
    case "CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          send_answers_by_student: false,
        },
      };
    case "CREATE_TEST_RESULTS_BY_STUDENT_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          send_answers_by_student: false,
        },
      };
    case "DELETE_QUESTION":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          question: pathOr("", ["payload", "questionID"], action),
        },
      };
    case "DELETE_QUESTION_SUCCESS":
    case "DELETE_QUESTION_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          question: "",
        },
      };
    default:
      return state;
  }
};
