import React, { useState, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import classNames from "classnames";
import propOr from "ramda/src/propOr";
import DOMPurify from "dompurify";

import { allowedFormatsForAnswers } from "../../../../../../../../../services/common";

import FormulaModal from "../../../../../../../../Common/FormulaModal";

import "react-quill/dist/quill.snow.css";
import "katex/dist/katex.min.css";
import styles from "./styles.module.scss";


interface Props {
  questionAnswer: string;
  setQuestionAnswer: (object: any) => any;
  updateQuestion: (name_html?: string, name?: string) => any;
  hasError: boolean;
}

export const QuestionAnswerEditor = (props: Props) => {
  const [formulaModal, setFormulaModal] = useState(false);
  const [editorPosition, setEditorPosition] = useState(null);
  const [editorIsFocused, setEditorIsFocused] = useState(false);
  const editorModules = useRef({
    toolbar: {
      container: allowedFormatsForAnswers,
      handlers: {
        "formula": () => {
          const editor = ReactQuillEditor.current.getEditor();
          const range = editor.getSelection();
          setEditorPosition(range);
          setFormulaModal(true);
        },
      },
    },
  });
  const ReactQuillEditor = useRef<any>(null);

  const setFormulaHTML = (formula: string) => {
    const editor = ReactQuillEditor.current.getEditor();
    const position = editorPosition ? propOr(0, "index", editorPosition) : 0;

    editor.insertEmbed(position, "formula", formula);

    props.setQuestionAnswer({
      html: editor.root.innerHTML,
      plain: editor.getText(editor.root.innerHTML),
    });
    props.updateQuestion(editor.root.innerHTML, editor.getText(editor.root.innerHTML));
  };

  const reactQuillQuestionAnswerClass = (isModal?: boolean) => classNames({
    "reactQuill": true,
    "reactQuillQuestionAnswer": true && !isModal,
    "reactQuillError": props.hasError,
    "reactQuillQuestionAnswerFocused": editorIsFocused && !isModal,
  });

  const editor = (isModal?: boolean) => (
    <OutsideClickHandler onOutsideClick={() => {
      if (editorIsFocused) {
        props.updateQuestion();
        setEditorIsFocused(false);
      }
    }}>
      <ReactQuill
        ref={ReactQuillEditor}
        defaultValue={props.questionAnswer}
        onChange={(value, delta, source, editor) => {
          const textHTML = DOMPurify.sanitize(value);
          const textPlain = editor.getText(textHTML as any);

          props.setQuestionAnswer({
            html: textHTML,
            plain: textPlain,
          });
        }}
        onFocus={() => setEditorIsFocused(true)}
        modules={editorModules.current}
        className={reactQuillQuestionAnswerClass(isModal)}
        formats={allowedFormatsForAnswers}
      />
    </OutsideClickHandler>
  );

  return (
    <>
      <div className={styles.quillContainer}>
        <div className={styles.editorDesktop}>{editor()}</div>
      </div>
      {formulaModal &&
        <FormulaModal
          setFormulaModal={setFormulaModal}
          setFormulaHTML={setFormulaHTML}
        />
      }
    </>
  );
};

export default QuestionAnswerEditor;
