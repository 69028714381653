import React from "react";

import AddPicture from "../../Body/AddPicture";
import translate from "../../../../../../../../services/translate";
import { ToggleContent } from "../../../../../../../Common/Modal";

import { ReactComponent as PictureIcon } from "../../../../../../../../assets/images/picture.svg";

// Types
import { updateQuestion as updateQuestionFunction } from "../../../../../../../../models/redux/test";

interface Props {
  questionID: string;
  testID: string;
  classID: string;
  updateQuestion: updateQuestionFunction;
  questionInProgress: boolean;
}

export const AddPictureButton = (props: Props) => {
  return (
    <ToggleContent
      toggle={(show: any) => (
        <button type="button" onClick={show}>
          <PictureIcon/>
          <span>{translate("creator.add-picture")}</span>
        </button>
      )}
      content={(hide: () => {}) => (
        <AddPicture
          hide={hide}
          updateQuestion={(image: any, callback?: () => any) =>
            props.updateQuestion(
              {
                questionID: props.questionID,
                testID: props.testID,
                classID: props.classID,
                image,
              },
              callback,
            )
          }
          questionInProgress={props.questionInProgress}
        />
      )}
    />
  );
};

export default AddPictureButton;
