import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import translate from "../../../../../services/translate";

import TestsListItem from "./Item";

// Types
import { History } from "history";
import { testModel } from "../../../../../models/redux/class";
import { deleteTest as deleteTestFunction } from "../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  tests: testModel[];
  classID: string;
  heading: string | ReactElement;
  showPlaceholder: boolean;
  deleteTest: deleteTestFunction;
  completedTests?: boolean;
}

export const TestsList = (props: Props) => {
  const container = classNames({
    [styles.tests]: !props.completedTests,
    [styles.testsCompleted]: props.completedTests,
  });

  return (
    <div className={container}>
      <h3>{props.heading}</h3>
      <ul className={styles.allTestsList}>
        {props.showPlaceholder && (
          <Link
            to={`/test/creator?classID=${props.classID}`}
            className={styles.createTestLink}
          >
            <span>+</span>
            <span>
              {translate("global.create")} {translate("global.new")}{" "}
              {translate("global.test")}
            </span>
          </Link>
        )}
        {props.tests.map((item: testModel) => (
          <TestsListItem
            key={`all_tests_${item.id}`}
            history={props.history}
            classID={props.classID}
            item={item}
            deleteTest={props.deleteTest}
          />
        ))}
      </ul>
    </div>
  );
};
export default TestsList;
