import propOr from "ramda/src/propOr";

// Types
import {
  createLessonPayload,
  createLessonAction,
  createLessonSuccessAction,
  createLessonFailureAction,
  unmountLessonAction,
  createLessonSuccessPayload,
  getLessonPayload,
  getLessonAction,
  getLessonSuccessPayload,
  getLessonSuccessAction,
  getLessonFailureAction,
  updateLessonPayload,
  updateLessonAction,
  updateLessonSuccessAction,
  updateLessonFailureAction,
  updateLessonSuccessPayload,
  deleteLessonPayload,
  deleteLessonAction,
  deleteLessonSuccessAction,
  deleteLessonFailureAction,
  deleteLessonSuccessPayload,
  lessonGenerateAccessTokenPayload,
  lessonGenerateAccessTokenAction,
  lessonGenerateAccessTokenSuccessPayload,
  lessonGenerateAccessTokenSuccessAction,
  lessonGenerateAccessTokenFailureAction,
  publishLessonPayload,
  publishLessonAction,
  publishLessonSuccessPayload,
  publishLessonSuccessAction,
  publishLessonFailureAction,
  unpublishLessonPayload,
  unpublishLessonAction,
  unpublishLessonSuccessPayload,
  unpublishLessonSuccessAction,
  unpublishLessonFailureAction,
  duplicateLessonPayload,
  duplicateLessonAction,
  duplicateLessonSuccessPayload,
  duplicateLessonSuccessAction,
  duplicateLessonFailureAction,
} from "../../../models/redux/lesson";
import { ErrorsPayload } from "../../../models/common";

// Create Lesson

export const createLesson = (payload: createLessonPayload): createLessonAction => {
  return {
    type: "CREATE_LESSON",
    payload,
  };
};

export const createLessonSuccess = (payload: createLessonSuccessPayload): createLessonSuccessAction => {
  if (payload.history) {
    payload.history.push(`/class/${payload.class_id}/lessons/${payload.lesson_id}`);
  }
  return {
    type: "CREATE_LESSON_SUCCESS",
  };
};

export const createLessonFailure = (payload: ErrorsPayload): createLessonFailureAction => {
  return {
    type: "CREATE_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Update Lesson

export const updateLesson = (payload: updateLessonPayload): updateLessonAction => {
  return {
    type: "UPDATE_LESSON",
    payload,
  };
};

export const updateLessonSuccess = (payload: updateLessonSuccessPayload): updateLessonSuccessAction => {
  if (payload.history) {
    payload.history.push(`/class/${payload.class_id}/lessons/${payload.lesson_id}`);
  }
  return {
    type: "UPDATE_LESSON_SUCCESS",
  };
};

export const updateLessonFailure = (payload: ErrorsPayload): updateLessonFailureAction => {
  return {
    type: "UPDATE_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Get Lesson

export const getLesson = (payload: getLessonPayload): getLessonAction => {
  return {
    type: "GET_LESSON",
    payload,
  };
};

export const getLessonSuccess = (payload: getLessonSuccessPayload): getLessonSuccessAction => {
  return {
    type: "GET_LESSON_SUCCESS",
    payload,
  };
};

export const getLessonFailure = (payload: ErrorsPayload): getLessonFailureAction => {
  return {
    type: "GET_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Delete Lesson

export const deleteLesson = (payload: deleteLessonPayload): deleteLessonAction => {
  return {
    type: "DELETE_LESSON",
    payload,
  };
};

export const deleteLessonSuccess = (payload: deleteLessonSuccessPayload, callback?: () => any): deleteLessonSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "DELETE_LESSON_SUCCESS",
    payload,
  };
};

export const deleteLessonFailure = (payload: ErrorsPayload): deleteLessonFailureAction => {
  return {
    type: "DELETE_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Generate Lesson's access token

export const lessonGenerateAccessToken = (payload: lessonGenerateAccessTokenPayload, callback?: () => any): lessonGenerateAccessTokenAction => {
  return {
    type: "LESSON_GENERATE_ACCESS_TOKEN",
    payload,
    callback,
  };
};

export const lessonGenerateAccessTokenSuccess = (payload: lessonGenerateAccessTokenSuccessPayload, callback?: () => any): lessonGenerateAccessTokenSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "LESSON_GENERATE_ACCESS_TOKEN_SUCCESS",
    payload,
  };
};

export const lessonGenerateAccessTokenFailure = (payload: ErrorsPayload): lessonGenerateAccessTokenFailureAction => {
  return {
    type: "LESSON_GENERATE_ACCESS_TOKEN_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Publish Lesson

export const publishLesson = (payload: publishLessonPayload): publishLessonAction => {
  return {
    type: "PUBLISH_LESSON",
    payload,
  };
};

export const publishLessonSuccess = (payload: publishLessonSuccessPayload): publishLessonSuccessAction => {
  return {
    type: "PUBLISH_LESSON_SUCCESS",
    payload,
  };
};

export const publishLessonFailure = (payload: ErrorsPayload): publishLessonFailureAction => {
  return {
    type: "PUBLISH_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Unpublish Lesson

export const unpublishLesson = (payload: unpublishLessonPayload): unpublishLessonAction => {
  return {
    type: "UNPUBLISH_LESSON",
    payload,
  };
};

export const unpublishLessonSuccess = (payload: unpublishLessonSuccessPayload): unpublishLessonSuccessAction => {
  return {
    type: "UNPUBLISH_LESSON_SUCCESS",
    payload,
  };
};

export const unpublishLessonFailure = (payload: ErrorsPayload): unpublishLessonFailureAction => {
  return {
    type: "UNPUBLISH_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Duplicate Lesson

export const duplicateLesson = (payload: duplicateLessonPayload, callback?: () => any): duplicateLessonAction => {
  return {
    type: "DUPLICATE_LESSON",
    payload,
    callback,
  };
};

export const duplicateLessonSuccess = (payload: duplicateLessonSuccessPayload, callback?: () => any): duplicateLessonSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "DUPLICATE_LESSON_SUCCESS",
    payload,
  };
};

export const duplicateLessonFailure = (payload: ErrorsPayload): duplicateLessonFailureAction => {
  return {
    type: "DUPLICATE_LESSON_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Other

export const unmountLesson = (): unmountLessonAction => {
  return {
    type: "UNMOUNT_LESSON",
  };
};
