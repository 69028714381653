import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import concat from "ramda/src/concat";
import contains from "ramda/src/contains";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import reject from "ramda/src/reject";
import uniq from "ramda/src/uniq";

import translate from "../../../../../services/translate";
import {
  getSelectedClassName,
  getTotalPoints,
} from "../../../../../services/common/test";

import TestCreatorHeader from "../Common/Header";
import Question from "./Search/Question";
import Search from "./Search";

import {
  getQuestionsFromQuestionsBase,
  duplicateQuestionsFromQuestionsBase,
  unmountQuestionsBase,
  getQuestionsFromQuestionsBaseNextPage,
  voteForAQuestion,
  getUserVotes,
} from "../../../../../redux/actions/questionsBase";
import { getTest, getQuestionTypes } from "../../../../../redux/actions/test";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  subjectModel,
  classModel,
} from "../../../../../models/redux/teacher";
import {
  questionsBaseStateModel,
  voteForAQuestion as voteForAQuestionFunction,
} from "../../../../../models/redux/questionsBase";
import {
  testStateModel,
  getTest as getTestFunction,
  getQuestionTypes as getQuestionTypesFunction,
} from "../../../../../models/redux/test";
import { appStateModel } from "../../../../../models/redux/app";
import { questionModel } from "../../../../../models/redux/questionsBase";
import {
  duplicateQuestionsFromQuestionsBase as duplicateQuestionsFromQuestionsBaseFunction,
  getQuestionsFromQuestionsBase as getQuestionsFromQuestionsBaseFunction,
  getQuestionsFromQuestionsBaseNextPage as getQuestionsFromQuestionsBaseNextPageFunction,
  getUserVotes as getUserVotesFunction,
} from "../../../../../models/redux/questionsBase";
import { listMetaModel } from "../../../../../models/common";
import { userStateModel } from "../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  app: appStateModel;
  classes: classModel[];
  questions: questionModel[];
  listMeta: listMetaModel;
  subjects: subjectModel[];
  test: testStateModel;
  duplicateQuestionsFromQuestionsBase: duplicateQuestionsFromQuestionsBaseFunction;
  getTest: getTestFunction;
  getQuestionsFromQuestionsBase: getQuestionsFromQuestionsBaseFunction;
  unmountQuestionsBase: () => {};
  getQuestionsFromQuestionsBaseNextPage: getQuestionsFromQuestionsBaseNextPageFunction;
  voteForAQuestion: voteForAQuestionFunction;
  getQuestionTypes: getQuestionTypesFunction;
  isSearchLoading: boolean;
  isDuplicateLoading: boolean;
  user: userStateModel;
  getUserVotes: getUserVotesFunction;
  downvotedQuestionIDs: string[];
  upvotedQuestionIDs: string[];
}

export const QuestionsBase = (props: Props) => {
  const sortOptions: any = {
    best_match: pathOr(
      "",
      ["app", "translations", "data", "global", "best-match"],
      props,
    ),
    most_popular: pathOr(
      "",
      ["app", "translations", "data", "global", "most-popular"],
      props,
    ),
    newest: pathOr(
      "",
      ["app", "translations", "data", "global", "newest"],
      props,
    ),
  };
  const defaultSortOption = "best_match";

  const [questions, setQuestions] = useState([] as questionModel[]);
  const [selectedQuestions, selectQuestion] = useState([] as string[]);
  const [term, setTerm] = useState("");
  const [searchOptions, setSearchOptions] = useState({
    sort: defaultSortOption,
  });
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState(
    [] as string[],
  );

  const [withSkills, setWithSkills] = useState(false);

  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const selectedSubjectType = parsed.subject as string;
  const selectedTestType = parsed.type as string;
  const testID = parsed.testID as string;
  const selectedTime = parsed.time as string;
  const pageSize = 10;

  const redirect = () => {
    if (!classID) {
      props.history.replace(
        `/test/creator/select-class${props.history.location.search}`,
      );
      return;
    }

    // if (!selectedSubjectType) {
    //   props.history.replace(
    //     `/test/creator/select-subject${props.history.location.search}`
    //   );
    //   return;
    // }

    if (!selectedTestType) {
      props.history.replace(
        `/test/creator/select-type${props.history.location.search}`,
      );
      return;
    }
  };

  const toggleQuestionSelection = (
    selectedQuestions: string[],
    id: string,
  ): string[] => {
    if (contains(id, selectedQuestions)) {
      return reject(
        (selectedId: string) => id === selectedId,
        selectedQuestions,
      );
    }

    return uniq(concat(selectedQuestions, [id]));
  };

  const getSearchResults = (termValue?: string, searchOptionsValue?: any) => {
    const payload = {
      page: 1,
      page_size: pageSize,
      sort: pathOr(undefined, ["sort"], searchOptionsValue),
      subject_id: props.test.subject.id,
      term: termValue || undefined,
      question_type: selectedQuestionTypes,
      with_skills: withSkills,
    };

    props.getQuestionsFromQuestionsBase(payload);
  };

  const getSelectedSubjectName = () => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === selectedSubjectType,
    );

    if (selectedSubject) {
      return selectedSubject.name;
    }
    return props.test.subject.name;
  };

  useEffect(() => {
    redirect();
    props.getQuestionTypes({ type: "paper" });
    props.getUserVotes();

    if (classID && testID) {
      props.getTest({
        classID,
        testID,
      });
    }

    return () => {
      props.unmountQuestionsBase();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathOr("", ["test", "subject", "id"], props) !== "") {
      getSearchResults();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test]);

  useEffect(() => {
    setQuestions(props.questions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questions]);

  useEffect(() => {
    redirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location]);

  return (
    <section className={styles.container}>
      <TestCreatorHeader
        history={props.history}
        testID={testID}
        classID={classID}
        selectedClassName={getSelectedClassName(props.classes, classID)}
        selectedSubjectName={getSelectedSubjectName()}
        selectedSubjectType={selectedSubjectType}
        selectedTestType={selectedTestType}
        selectedTime={selectedTime}
      />
      <div className={styles.summaryBox}>
        <span>{translate("global.summary")}:</span>
        <div>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.questions")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.test.questions.length}</span>
          </div>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.points")}:&nbsp;
            </span>
            <span className={styles.violet}>
              {getTotalPoints(props.test.questions)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.questionsBaseContainer}>
        <Search
          isSearchLoading={props.isSearchLoading}
          term={term}
          setTerm={setTerm}
          app={props.app}
          getSearchResults={getSearchResults}
          sortOptions={sortOptions}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          defaultSortOption={defaultSortOption}
          questionTypes={props.test.question_types}
          selectedQuestionTypes={selectedQuestionTypes}
          setSelectedQuestionTypes={setSelectedQuestionTypes}
          withSkills={withSkills}
          setWithSkills={setWithSkills}
          user={props.user}
        />
        <div className={styles.questionsBaseResults}>
          {!questions.length ? (
            <div className={styles.questionsBaseNoResultsContainer}>
              <h3>{translate("creator.no-questions-found-for-this-query")}</h3>
            </div>
          ) : (
            questions.map((question: any) => (
              <Question
                key={question.id}
                index={question.id}
                isSelected={contains(question.id, selectedQuestions)}
                selectQuestion={(id: any) =>
                  selectQuestion((selectedQuestions) =>
                    toggleQuestionSelection(selectedQuestions, id),
                  )
                }
                question={question}
                voteForAQuestion={props.voteForAQuestion}
                downvotedQuestionIDs={props.downvotedQuestionIDs}
                upvotedQuestionIDs={props.upvotedQuestionIDs}
              />
            ))
          )}
        </div>
        {props.listMeta.next &&
          props.listMeta.next <= props.listMeta.last &&
          props.listMeta.last > 1 && (
          <button
            type="button"
            onClick={() =>
              props.getQuestionsFromQuestionsBaseNextPage({
                page: props.listMeta.next as number,
                page_size: pageSize,
                sort: prop("sort", searchOptions),
                subject_id: props.test.subject.id,
                term: term || undefined,
              })
            }
            className={styles.nextPageButton}
          >
            <span>{translate("creator.next-page")}</span>
          </button>
        )}
        <div className={styles.controlsContainer}>
          <button
            type="button"
            className={styles.cancelButton}
            disabled={!props.test.id}
            onClick={props.history.back}
          >
            <span>{translate("global.cancel")}</span>
          </button>
          <button
            type="button"
            className={styles.addButton}
            disabled={!selectedQuestions.length || props.isDuplicateLoading}
            onClick={() =>
              props.duplicateQuestionsFromQuestionsBase({
                class_id: classID,
                history: props.history,
                question_ids: selectedQuestions,
                subject_type: selectedSubjectType,
                test_id: testID,
                test_type: selectedTestType,
              })
            }
          >
            {props.isDuplicateLoading ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <span>{translate("creator.add")}</span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  app: appStateModel;
  teacher: teacherStateModel;
  questionsBase: questionsBaseStateModel;
  test: testStateModel;
  user: userStateModel;
}) => ({
  app: state.app,
  classes: state.teacher.classes,
  questions: state.questionsBase.list,
  listMeta: state.questionsBase.listMeta,
  isSearchLoading: state.questionsBase.in_progress.search,
  isDuplicateLoading: state.questionsBase.in_progress.duplicate,
  subjects: state.teacher.subjects,
  test: state.test,
  user: state.user,
  downvotedQuestionIDs: state.questionsBase.downvoted_question_ids,
  upvotedQuestionIDs: state.questionsBase.upvoted_question_ids,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      duplicateQuestionsFromQuestionsBase,
      getQuestionsFromQuestionsBase,
      getTest,
      unmountQuestionsBase,
      getQuestionsFromQuestionsBaseNextPage,
      voteForAQuestion,
      getQuestionTypes,
      getUserVotes,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsBase);
