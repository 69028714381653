import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CreditCardDisplayInvoice from "./Invoice";
import Modal, { ToggleContent } from "../../../../../../Common/Modal";

import translate from "../../../../../../../services/translate";

import { ReactComponent as CrossedArrowsIcon } from "../../../../../../../assets/images/payments/crossed_arrows.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../../../assets/images/trash_alt.svg";

import VisaIcon from "../../../../../../../assets/images/payments/brands/visa.png";
import DefaultIcon from "../../../../../../../assets/images/payments/brands/default.png";
import MasterCardIcon from "../../../../../../../assets/images/payments/brands/master_card.png";
import AmericanExpressIcon from "../../../../../../../assets/images/payments/brands/american_express.png";

// Types
import { userStateModel } from "../../../../../../../models/redux/user";
import {
  deleteBillingCard as deleteBillingCardFunc,
  creditCardBrand,
} from "../../../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
  setPaymentMethods: (value: boolean) => any;
  deleteBillingCard: deleteBillingCardFunc;
}

export const CreditCardDisplay = (props: Props) => {
  const getCardIcon = (brand: string) => {
    if (brand === creditCardBrand.Visa) {
      return VisaIcon;
    } else if (brand === creditCardBrand.MasterCard) {
      return MasterCardIcon;
    } else if (brand === creditCardBrand.AmericanExpress) {
      return AmericanExpressIcon;
    }
    return DefaultIcon;
  };

  const isLoading = () => props.user.in_progress.billing_card;

  return (
    <div className={styles.creditCardDisplayContainer}>
      <h3>{translate("profile.payment-method")}</h3>
      <div className={styles.creditCardDisplayContent}>
        <CreditCardDisplayInvoice/>
        <div className={styles.card}>
          <img
            src={getCardIcon(props.user.card_info.brand)}
            alt="credit-card"
          />
          <span>**** **** **** {props.user.card_info.last4}</span>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <ToggleContent
          toggle={(show: any) => (
            <button type="button" disabled={isLoading()} onClick={show}>
              {isLoading() ? (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              ) : (
                <span>{translate("profile.delete-credit-card-info")}</span>
              )}
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.removeCardModal}>
                <TrashAltIcon/>
                <span>
                  {translate("profile.delete-credit-card-confirmation")}
                </span>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      hide();
                      props.deleteBillingCard();
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
        <ToggleContent
          toggle={(show: any) => (
            <button type="button" onClick={show}>
              <span>{translate("profile.change-payment-method")}</span>
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.removeCardModal}>
                <CrossedArrowsIcon/>
                <span>
                  {translate(
                    "profile.delete-credit-card-change-method-confirmation",
                  )}
                </span>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      hide();
                      props.setPaymentMethods(true);
                      props.deleteBillingCard();
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
      </div>
    </div>
  );
};

export default CreditCardDisplay;
