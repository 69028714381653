import propOr from "ramda/src/propOr";
import prop from "ramda/src/prop";
import moment from "moment";

import { PathInterface } from "../../models/common";

export const getErrorMessage = (
  errors: any[],
  pointer: string,
  equals = false,
) => {
  if (errors && !!errors.length) {
    let error;
    if (equals) {
      error = errors.find((err) => err.source.pointer === pointer);
    } else {
      error = errors.find((err) => err.source.pointer.includes(pointer));
    }

    if (error) {
      return error.detail;
    }
  }
  return "";
};

export const isPolish = (userLanguage?: string) =>
  userLanguage === "pl" ||
  (propOr("", "language", navigator) as string)
    .toLocaleLowerCase()
    .includes("pl");

export const userFromPL = (userCountry: string) => userCountry === "pl";

export const getPath = (path?: PathInterface) => {
  if (path) {
    return isPolish() ? path.pl : path.default;
  }
  return "";
};

export const getLandingPageURL = (path?: PathInterface) => {
  const language = isPolish() ? "/pl" : "";

  return `${process.env.REACT_APP_LANDING_PAGE_URL}${language}${getPath(path)}`;
};

export const isWindowSmallScreen = () => {
  return window && window.innerWidth < 668;
};

export const outsideClickHelper = (
  e: any,
  className: string,
  callback: () => void,
) => {
  let tempElement = prop("target", e);
  let els = [];
  while (tempElement) {
    els.unshift(tempElement);
    tempElement = tempElement.parentNode;
  }

  if (
    els.length &&
    !!els.find((item: HTMLElement) => {
      const element = propOr("", "className", item) as string;
      if (typeof element === "string") {
        return element.includes(className);
      }
      return false;
    })
  ) {
    return;
  }
  callback();
};

export const calculateDaysTill = (from: any, to: any) =>
  moment.duration(from.diff(to)).asDays();

export const parseDateTime = (formattedDateTime: string) =>
  moment(formattedDateTime, "YYYY-MM-DDThh:mm:ssZ");

export const formatAsDate = (dateTime: any) => dateTime.format("YYYY-MM-DD");

export const formatAsDateTime = (dateTime: any) =>
  dateTime.format("YYYY-MM-DD hh:mm");

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getRedirectToPath = () => {
  if (window && window.location && window.location.pathname.length > 1) {
    return `?redirect_to=${window.location.pathname}`;
  }
  return "";
};

export const generateArraySearchQuery = (array: string[], key: string) =>
  array
    .map((item, index) => {
      if (!index) {
        return `?${key}%5B%5D=${item}`;
      }
      return `&${key}%5B%5D=${item}`;
    })
    .join("");

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const setWatchedTutorials = (tutorial: string) => {
  if (localStorage) {
    const watchedTutorials = localStorage.getItem("watchedTutorials");
    if (watchedTutorials) {
      let parsedWatchedTutorials;
      try {
        parsedWatchedTutorials = JSON.parse(watchedTutorials);
      } catch (e) {
        return;
      }
      if (
        parsedWatchedTutorials &&
        !parsedWatchedTutorials.includes(tutorial)
      ) {
        localStorage.setItem(
          "watchedTutorials",
          JSON.stringify(parsedWatchedTutorials.push(tutorial)),
        );
      }
    } else {
      localStorage.setItem("watchedTutorials", JSON.stringify([tutorial]));
    }
  }
};

export const isTutorialWatched = (tutorial: string) => {
  if (localStorage) {
    const watchedTutorials = localStorage.getItem("watchedTutorials");
    if (watchedTutorials) {
      let parsedWatchedTutorials;
      try {
        parsedWatchedTutorials = JSON.parse(watchedTutorials);
      } catch (e) {
        return false;
      }
      if (parsedWatchedTutorials && parsedWatchedTutorials.includes(tutorial)) {
        return true;
      }
    }
  }
  return false;
};

export const allowedFormatsForAnswers = [
  "bold",
  "italic",
  "underline",
  "formula",
];

export const allowedFormatsForBody = [
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "formula",
  "video",
];

export const freemiumUserMaxClassroomLimit = 1;

export const getArrayBasedOnNumber = (N: number) =>
  Array.from(Array(N), (_, i) => i);

let debounceTimeout: NodeJS.Timer;

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
) => {
  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (debounceTimeout) clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};
