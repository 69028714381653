import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import pathOr from "ramda/src/pathOr";
import mergeRight from "ramda/src/mergeRight";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import isNil from "ramda/src/isNil";

import TestReportStudentPoints from "./Points";
import TestReportStudentPercentagePoints from "./PointsPercentage";
import TestReportStudentQuestions from "./Questions";
import Modal, { ToggleContent } from "../../../../Common/Modal";

import { ReactComponent as TrashAltIcon } from "../../../../../assets/images/trash_alt.svg";

import translate from "../../../../../services/translate";

import {
  getStudentReport,
  resetStudentReport,
  generateStudentReportPDF,
} from "../../../../../redux/actions/report";
import {
  getTest,
  getTestResults,
  updateTestResults,
  deleteTestResults,
} from "../../../../../redux/actions/test";
import { unmountClass } from "../../../../../redux/actions/class";

// Types
import { History, Location } from "history";
import { reportStateModel } from "../../../../../models/redux/report";
import {
  studentReportModel,
  getStudentReport as getStudentReportFunction,
  resetStudentReport as resetStudentReportFunction,
  generateStudentReportPDF as generateStudentReportPDFFunction,
} from "../../../../../models/redux/report";
import {
  resultObject,
  QuestionTypes,
  getTestResults as getTestResultsFunction,
  updateTestResults as updateTestResultsFunction,
  deleteTestResults as deleteTestResultsFunction,
} from "../../../../../models/redux/test";
import { userStateModel } from "../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
  studentReport: studentReportModel;
  getStudentReport: getStudentReportFunction;
  resetStudentReport: resetStudentReportFunction;
  generateStudentReportPDF: generateStudentReportPDFFunction;
  unmountClass: () => {};
  generatePDFinProgress: boolean;
  getTestResults: getTestResultsFunction;
  studentResults: resultObject[];
  updateTestResults: updateTestResultsFunction;
  deleteTestResults: deleteTestResultsFunction;
  hasFullAccess: boolean;
}

export const TestReportStudent = (props: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [localStudentResults, setLocalStudentResults] = useState<
    resultObject[]
  >([]);

  useEffect(() => {
    props.getStudentReport({
      testID: props.match.params.id,
      studentID: props.match.params.studentID,
    });

    return () => {
      props.resetStudentReport();
      props.unmountClass();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.studentReport.result_id) {
      props.getTestResults({
        testID: props.match.params.id,
        resultID: props.studentReport.result_id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.studentReport.result_id]);

  useEffect(() => {
    if (props.studentResults) {
      setLocalStudentResults(props.studentResults);
    }
  }, [props.studentResults]);

  const updateScore = (callback: any) => {
    props.updateTestResults(
      {
        testID: props.match.params.id,
        resultID: props.studentReport.result_id,
        answers: localStudentResults.map((item) => ({
          id: item.id,
          ...mergeRight(
            {},
            // @ts-ignore
            Number.isInteger(Number(propOr(0, "points", item)))
              ? { points: prop("points", item) }
              : {},
          ),
          ...mergeRight(
            {},
            // @ts-ignore
            item.answer_choices
              ? {
                answer_choices: pathOr(
                  [],
                  ["answer_choices", "selected"],
                  item,
                ),
              }
              : {},
          ),
        })),
      },
      () => {
        callback();
        props.getStudentReport({
          testID: props.match.params.id,
          studentID: props.match.params.studentID,
        });
        props.getTestResults({
          testID: props.match.params.id,
          resultID: props.studentReport.result_id,
        });
      },
    );
  };

  const isAnyOpenQuestionWithoutScore = () => {
    const openQuestions = props.studentResults.filter(
      (item) => item.type === QuestionTypes.OPEN,
    );

    if (Array.isArray(openQuestions) && !!openQuestions.length) {
      return openQuestions.find((item) => isNil(item.points));
    }
    return false;
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h4>
          <Link
            to={`/test?classID=${props.studentReport.class_id}&testID=${props.match.params.id}`}
          >
            {props.studentReport.test_name}
          </Link>{" "}
          /&nbsp;
          <Link to={`/test/${props.match.params.id}/report/class`}>
            {translate("report.report")}
          </Link>{" "}
          /&nbsp;
          <span>
            {translate("students.student")} {props.studentReport.student_number}
          </span>
        </h4>
        <h3>
          {translate("students.student-number")}{" "}
          {props.studentReport.student_number}
        </h3>
        <div className={styles.buttonContainer}>
          {!editMode && (
            <div className={styles.tooltipContainer}>
              {!props.hasFullAccess && (
                <ReactTooltip
                  id="student-report-generate-pdf-limit-tooltip"
                  place="top"
                  effect="solid"
                  className={styles.tooltip}
                >
                  <span>
                    {translate(
                      "report.generate-pdf-limit-paid-version-description",
                    )}
                  </span>
                </ReactTooltip>
              )}
              <div
                data-tip
                data-for="student-report-generate-pdf-limit-tooltip"
              >
                <button
                  type="button"
                  disabled={props.generatePDFinProgress || !props.hasFullAccess}
                  onClick={() =>
                    props.generateStudentReportPDF({
                      testID: props.match.params.id,
                      studentID: props.match.params.studentID,
                    })
                  }
                >
                  {props.generatePDFinProgress ? (
                    <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
                  ) : (
                    <span>{translate("report.generate-pdf")}</span>
                  )}
                </button>
              </div>
            </div>
          )}
          {!editMode && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
              }}
            >
              <span>
                {translate("global.edit")} {translate("report.report")}
              </span>
            </button>
          )}
          {editMode && (
            <button
              type="button"
              onClick={() => {
                updateScore(() => setEditMode(false));
              }}
            >
              <span>{translate("global.save-changes")}</span>
            </button>
          )}
          {editMode && (
            <button
              type="button"
              onClick={() => {
                setEditMode(false);
                setLocalStudentResults(props.studentResults);
              }}
            >
              <span>{translate("global.cancel")}</span>
            </button>
          )}
        </div>
      </div>
      {isAnyOpenQuestionWithoutScore() && (
        <div className={styles.boxInfo}>
          <p>
            <b>{translate("global.warning")}!&nbsp;</b>
            <span>
              {translate("report.edit-student-score-open-question-information")}
            </span>
            <b>
              &nbsp;“{translate("global.edit")} {translate("report.report")}”
            </b>
          </p>
        </div>
      )}
      <section className={styles.content}>
        <TestReportStudentPoints
          totalPoints={props.studentReport.points_total}
          points={props.studentReport.points}
          editMode={editMode}
        />
        <TestReportStudentPercentagePoints
          pointsPercentage={props.studentReport.points_percentage}
          editMode={editMode}
        />
        <TestReportStudentQuestions
          questions={props.studentReport.questions}
          testID={props.match.params.id}
          classID={props.studentReport.class_id}
          studentID={props.match.params.studentID}
          editMode={editMode}
          localStudentResults={localStudentResults}
          setLocalStudentResults={setLocalStudentResults}
        />
      </section>
      {editMode && (
        <ToggleContent
          toggle={(show: any) => (
            <button
              type="button"
              className={styles.deleteStudentResultButton}
              onClick={() => {
                show();
              }}
            >
              <span>
                {translate("global.delete")} {translate("report.score")}
              </span>
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.deleteStudentResultModal}>
                <TrashAltIcon/>
                <span>
                  {translate("report.delete-student-result-confirmation-text")}
                </span>
                <div className={styles.buttonContainer}>
                  <button
                    type="button"
                    onClick={() => {
                      props.deleteTestResults(
                        {
                          testID: props.match.params.id,
                          resultID: props.studentReport.result_id,
                        },
                        () => {
                          hide();
                          props.history.push(
                            `/test/${props.match.params.id}/report/class`,
                          );
                        },
                      );
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: {
  report: reportStateModel;
  user: userStateModel;
}) => ({
  studentReport: state.report.student,
  studentResults: state.report.studentResults.answers,
  generatePDFinProgress: state.report.in_progress.generate_pdf,
  hasFullAccess: state.user.access.full_access,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTest,
      unmountClass,
      getStudentReport,
      resetStudentReport,
      generateStudentReportPDF,
      getTestResults,
      updateTestResults,
      deleteTestResults,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestReportStudent);
