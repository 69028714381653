import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propOr from "ramda/src/propOr";
import queryString from "query-string";

import { resetPassword, unmountAuth } from "../../redux/actions/auth";
import AuthContainer from "../Common/Auth/Container";
import Input from "../Common/Input";
import { getErrorMessage, getLandingPageURL } from "../../services/common";
import translate from "../../services/translate";

// Types
import { Location, History } from "history";
import {
  authStateModel,
  resetPassword as resetPasswordFunction,
} from "../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  errors: any[];
  isLoading: boolean;
  resetPassword: resetPasswordFunction;
  unmountAuth: () => {};
  location: Location;
  history: History;
  isAuthenticated: null | boolean;
}

export const ResetPassword = (props: Props) => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    return () => {
      props.unmountAuth();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.push("/");
    }
  }, [props]);

  const isPasswordEmpty = () => !password.length;
  const isPasswordConfirmationEmpty = () => !password_confirmation.length;
  const isSubmitButtonDisabled = () => {
    return (
      isPasswordEmpty() || isPasswordConfirmationEmpty() || props.isLoading
    );
  };

  return (
    <AuthContainer heading={translate("reset_password.reset-password")}>
      <>
        <p className={styles.info}>
          {translate("reset_password.reset-password-description")}
        </p>
        <form
          className={styles.form}
          onSubmit={(e: any) => {
            e.preventDefault();
            const parsed = queryString.parse(props.location.search);
            props.resetPassword({
              password,
              password_confirmation,
              token: propOr("", "token", parsed),
              history: props.history,
            });
          }}
        >
          <Input
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            label={translate("reset_password.new-password")}
            error={getErrorMessage(props.errors, "password", true)}
          />
          <Input
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            value={password_confirmation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirmation(e.target.value)
            }
            label={translate("reset_password.repeat-new-password")}
            error={getErrorMessage(props.errors, "password_confirmation", true)}
          />
          <div
            className={`${styles.buttonsContainer} ${styles.submitContainer}`}
          >
            <button
              type="submit"
              disabled={isSubmitButtonDisabled() || props.isLoading}
              className={props.isLoading ? styles.buttonIsLoading : ""}
            >
              {translate("reset_password.save-new-password")}
              {props.isLoading && (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              )}
            </button>
          </div>
          <div className={`${styles.buttonsContainer} ${styles.linkContainer}`}>
            <a href={getLandingPageURL()}>
              {translate("global.back-to-homepage")}
            </a>
          </div>
        </form>
      </>
    </AuthContainer>
  );
};

const mapStateToProps = (state: { auth: authStateModel }) => ({
  errors: state.auth.errors,
  isLoading: state.auth.in_progress.general,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      resetPassword,
      unmountAuth,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
