import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { of, concat } from "rxjs";
import { mergeMap, pluck, switchMap } from "rxjs/operators";
import join from "ramda/src/join";
import reject from "ramda/src/reject";

import {
  getQuestionsFromQuestionsBaseSuccess,
  getQuestionsFromQuestionsBaseFailure,
  duplicateQuestionFromQuestionsBaseSuccess,
  duplicateQuestionFromQuestionsBaseFailure,
  getQuestionsFromQuestionsBaseNextPageSuccess,
  getQuestionsFromQuestionsBaseNextPageFailure,
  voteForAQuestionSuccess,
  voteForAQuestionFailure,
  getUserVotesSuccess,
  getUserVotesFailure,
} from "../../actions/questionsBase";

import { apiCall, getTokenFromState } from "../../../services/api";

// Types
import {
  getQuestionsFromQuestionsBaseAction,
  getQuestionsFromQuestionsBasePayload,
  duplicateQuestionFromQuestionsBasePayload,
  duplicateQuestionsFromQuestionsBaseAction,
  getQuestionsFromQuestionsBaseNextPageAction,
  voteForAQuestionAction,
  voteForAQuestionPayload,
  duplicateQuestionFromQuestionsBaseFailureAction,
  getUserVotesAction,
  voteForAQuestionSuccessAction,
  duplicateQuestionFromQuestionsBaseSuccessAction,
} from "../../../models/redux/questionsBase";
import { XHRPayload, ErrorsPayload } from "../../../models/common";
import { historyPush, showToast } from "../../actions/app";

// Get Questions From Questions Base

export const getQuestionsFromQuestionsBaseEpic: Epic = (
  action$: ActionsObservable<getQuestionsFromQuestionsBaseAction>,
) =>
  action$.pipe(
    ofType("GET_QUESTIONS_FROM_QUESTIONS_BASE"),
    pluck("payload"),
    switchMap(getQuestionsFromQuestionsBase),
  );

export const getQuestionsFromQuestionsBase = (
  payload: getQuestionsFromQuestionsBasePayload,
) => {
  const baseURL = "/questions-base/questions";

  const params = join(
    "&",
    reject(
      (param: any) => param === undefined,
      [
        `pagination%5Bpage%5D=${payload.page}`,
        `pagination%5Bpage_size%5D=${payload.page_size}`,
        payload.subject_id ? `subject_id=${payload.subject_id}` : undefined,
        payload.term ? `term=${payload.term}` : undefined,
        payload.sort ? `sort=${payload.sort}` : undefined,
        payload.question_type && !!payload.question_type.length
          ? `type=${payload.question_type.join(",")}`
          : undefined,
        payload.with_skills ? `with_skills=${payload.with_skills}` : undefined,
      ],
    ),
  );

  return apiCall(
    `${baseURL}?${params}`,
    "GET",
    {},
    (data: XHRPayload) => getQuestionsFromQuestionsBaseSuccess(data.response),
    getQuestionsFromQuestionsBaseFailure,
  );
};

// Get Questions From Questions Base Next Page

export const getQuestionsFromQuestionsBaseNextPageEpic: Epic = (
  action$: ActionsObservable<getQuestionsFromQuestionsBaseNextPageAction>,
) =>
  action$.pipe(
    ofType("GET_QUESTIONS_FROM_QUESTIONS_BASE_NEXT_PAGE"),
    pluck("payload"),
    switchMap(getQuestionsFromQuestionsBaseNextPage),
  );

export const getQuestionsFromQuestionsBaseNextPage = (
  payload: getQuestionsFromQuestionsBasePayload,
) => {
  const baseURL = "/questions-base/questions";

  const params = join(
    "&",
    reject(
      (param: any) => param === undefined,
      [
        `pagination%5Bpage%5D=${payload.page}`,
        `pagination%5Bpage_size%5D=${payload.page_size}`,
        payload.subject_id ? `subject_id=${payload.subject_id}` : undefined,
        payload.term ? `term=${payload.term}` : undefined,
        payload.sort ? `sort=${payload.sort}` : undefined,
      ],
    ),
  );

  return apiCall(
    `${baseURL}?${params}`,
    "GET",
    {},
    (data: XHRPayload) =>
      getQuestionsFromQuestionsBaseNextPageSuccess(data.response),
    getQuestionsFromQuestionsBaseNextPageFailure,
  );
};

// Duplicate Questions From Questions Base

export const duplicateQuestionsFromQuestionsBaseEpic: Epic = (
  action$: ActionsObservable<duplicateQuestionsFromQuestionsBaseAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_QUESTIONS_FROM_QUESTIONS_BASE"),
    pluck("payload"),
    switchMap(duplicateQuestionFromQuestionsBase),
  );

export const duplicateQuestionFromQuestionsBase = (
  payload: duplicateQuestionFromQuestionsBasePayload,
) =>
  apiCall(
    "/questions-base/questions/duplicate",
    "POST",
    {
      question_ids: payload.question_ids,
      test_id: payload.test_id,
    },
    (data: XHRPayload) =>
      duplicateQuestionFromQuestionsBaseSuccess(data.response, payload),
    (error: ErrorsPayload) =>
      duplicateQuestionFromQuestionsBaseFailure(error, payload),
  );

export const duplicateQuestionFromQuestionsBaseFailureEpic: Epic = (
  action$: ActionsObservable<duplicateQuestionFromQuestionsBaseFailureAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_QUESTION_FROM_QUESTIONS_BASE_FAILURE"),
    pluck("redirectPayload"),
    mergeMap((payload: any) =>
      concat(
        of(
          showToast({
            text: "creator.duplicate-question-from-questions-base-failure-text",
            status: "error",
          }),
        ),
        of(
          historyPush(
            payload.history,
            `/test/creator?classID=${payload.classID}&subject=${payload.subjectType}&testID=${payload.testID}&type=${payload.testType}`,
          ),
        ),
      ),
    ),
  );

export const duplicateQuestionFromQuestionsBaseSuccessEpic: Epic = (
  action$: ActionsObservable<duplicateQuestionFromQuestionsBaseSuccessAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_QUESTION_FROM_QUESTIONS_BASE_SUCCESS"),
    pluck("redirectPayload"),
    mergeMap((payload: any) =>
      concat(
        of(
          historyPush(
            payload.history,
            `/test/creator?classID=${payload.class_id}&subject=${payload.subject_type}&testID=${payload.test_id}&type=${payload.test_type}`,
          ),
        ),
      ),
    ),
  );

// Vote For A Question

export const voteForAQuestionEpic: Epic = (
  action$: ActionsObservable<voteForAQuestionAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("VOTE_FOR_A_QUESTION"),
    pluck("payload"),
    switchMap((payload: voteForAQuestionPayload) =>
      voteForAQuestion(payload, state$),
    ),
  );

export const voteForAQuestion = (
  payload: voteForAQuestionPayload,
  state$: StateObservable<void>,
) =>
  apiCall(
    `/questions-base/questions/${payload.question_id}/votes`,
    "POST",
    {
      type: payload.type,
    },
    (data: XHRPayload) => voteForAQuestionSuccess(payload),
    voteForAQuestionFailure,
    getTokenFromState(state$),
  );

export const voteForAQuestionSuccessEpic: Epic = (
  action$: ActionsObservable<voteForAQuestionSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("VOTE_FOR_A_QUESTION_SUCCESS"),
    switchMap(() => getUserVotes(state$)),
  );

// Get User Votes

export const getUserVotesEpic: Epic = (
  action$: ActionsObservable<getUserVotesAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GET_USER_VOTES"),
    pluck("payload"),
    switchMap(() => getUserVotes(state$)),
  );

export const getUserVotes = (state$: StateObservable<void>) =>
  apiCall(
    "/questions-base/votes",
    "GET",
    {},
    (data: XHRPayload) => getUserVotesSuccess(data.response),
    getUserVotesFailure,
    getTokenFromState(state$),
  );
