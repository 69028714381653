import React from "react";

import translate from "../../../../../../services/translate";
import { getSelectedClassName } from "../../../../../../services/common/test";

// Types
import { classModel } from "../../../../../../models/redux/teacher";

import stylesDefault from "./styles.module.scss";
import stylesCompact from "./styles_compact.module.scss";

interface Props {
  classID: string;
  testName: string;
  subjectName: string;
  classes: classModel[];
  totalPoints: number;
  isCompact?: boolean;
}

export const PreviewTestHeader = (props: Props) => {
  const styles = props.isCompact ? stylesCompact : stylesDefault;

  return (
    <div className={styles.testInfo} id="testInfoHeader">
      <div className={styles.top}>
        <div className={styles.textContainer}>
          <span className={styles.darkGrey}>
            {translate("class.class")}:&nbsp;
          </span>
          <span className={styles.violet}>
            {getSelectedClassName(props.classes, props.classID)}
          </span>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.darkGrey}>
            {translate("global.subject")}:&nbsp;
          </span>
          <span className={styles.violet}>{props.subjectName}</span>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.darkGrey}>
            {translate("creator.total-score")}:&nbsp;
          </span>
          <span className={styles.violet}>{props.totalPoints}p</span>
        </div>
      </div>
      <div className={styles.bottom}>
        <h1>{props.testName}</h1>
      </div>
    </div>
  );
};

export default PreviewTestHeader;
