import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { getSubjects } from "../../../../../redux/actions/teacher";

import translate from "../../../../../services/translate";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";

import styles from "./styles.module.scss";
import SelectTimeContent from "../Common/SelectTimeContent";

interface Props {
  history: History;
  location: Location;
  teacher: teacherStateModel;
  getSubjects: getSubjectsFunction;
}

export const SelectTimeForTest = (props: Props) => {
  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    const classID = parsed.classID;
    const testType = parsed.type;
    // const selectedSubject = parsed.subject;
    const parsedSelectedTime = parsed.time;

    props.getSubjects();

    if (!classID) {
      props.history.push(`/test/creator/select-class${props.location.search}`);
      return;
    }

    if (!testType) {
      props.history.push(`/test/creator/select-type${props.location.search}`);
      return;
    }

    // if (!selectedSubject) {
    //   props.history.push(
    //     `/test/creator/select-subject${props.location.search}`
    //   );
    //   return;
    // }

    if (parsedSelectedTime) {
      props.history.push(`/test/creator${props.location.search}`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linkClass = (customSelectedTime: string) =>
    classNames({
      [styles.disabled]:
        customSelectedTime.length &&
        !Number.isInteger(Number(customSelectedTime)),
    });

  return (
    <SelectTimeContent
      history={props.history}
      location={props.location}
      bottomContent={(customSelectedTime: string, selectedTime: string) => (
        <div className={styles.bottomContainer}>
          <Link
            to={`/test/creator${props.history.location.search}&time=${
              customSelectedTime
                ? customSelectedTime
                : selectedTime
                  ? selectedTime
                  : null
            }`}
            className={linkClass(customSelectedTime)}
          >
            {translate("global.accept")}
          </Link>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: { teacher: teacherStateModel }) => ({
  teacher: state.teacher,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTimeForTest);
