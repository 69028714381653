import React from "react";
import queryString from "query-string";

import translate from "../../../../../../../services/translate";

// Types
import { History, Location } from "history";
import { classModel } from "../../../../../../../models/redux/teacher";

interface Props {
  history: History;
  location: Location;
  classes: classModel[];
  subjectName: string;
  testName: string;
  totalPoints: number;
  isCompact: boolean;
}

export const TestHeaderCompactPDF = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;

  const getSelectedClassName = () => {
    const selectedClass = props.classes.find(
      (item: any) => item.id === classID,
    );

    if (selectedClass) {
      return selectedClass.name;
    }
    return "";
  };

  return (
    <div
      style={{
        borderBottom: "1px solid #000",
        margin: "0 0 20px 0",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: "12px",
          margin: "0 0 10px 0",
        }}
      >
        <div
          style={{
            margin: "0 30px 0 0",
          }}
        >
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
              textTransform: "capitalize",
            }}
          >
            {translate("class.class")}:&nbsp;
          </span>
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
            }}
          >
            {getSelectedClassName()}
          </span>
        </div>
        <div
          style={{
            margin: "0 30px 0 0",
          }}
        >
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
              textTransform: "capitalize",
            }}
          >
            {translate("global.subject")}:&nbsp;
          </span>
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
            }}
          >
            {props.subjectName}
          </span>
        </div>
        <div
          style={{
            margin: "0 30px 0 0",
          }}
        >
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
            }}
          >
            {translate("creator.total-score")}:&nbsp;
          </span>
          <span
            style={{
              color: "#000",
              fontFamily: "Lato",
            }}
          >
            {props.totalPoints}p
          </span>
        </div>
      </div>
      <div
        style={{
          margin: "15px 0",
        }}
      >
        <h1
          style={{
            fontSize: "20px",
            lineHeight: "20px",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Lato",
            margin: "0",
          }}
        >
          {props.testName}
        </h1>
      </div>
    </div>
  );
};

export default TestHeaderCompactPDF;
