import React, { useRef } from "react";
import classNames from "classnames";

import { ReactComponent as Pen } from "../../../../../../assets/images/pen_edit.svg";

import translate from "../../../../../../services/translate";
import { getErrorMessage } from "../../../../../../services/common";

// Types
import { History } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  lessonTopic: string;
  setLessonTopic: (value: string) => any;
  className: string;
  subjectName: string;
  errors: any;
}

export const LessonCreatorHeader = (props: Props) => {
  const lessonNameInputElement = useRef(null) as any;

  const errorClass = classNames({
    [styles.error]: getErrorMessage(props.errors, "topic"),
  });

  const focusLessonNameInputElement = () => {
    if (lessonNameInputElement) {
      lessonNameInputElement.current.focus();
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.top}>
        <span
          className={`${styles.violet} ${errorClass}`}
          onClick={focusLessonNameInputElement}
        >
          {translate("lessons.lesson-topic")}
        </span>
        <div className={styles.right}>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("class.classname")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.className}</span>
          </div>
          <div className={styles.textContainer}>
            <span className={styles.darkGrey}>
              {translate("global.subject")}:&nbsp;
            </span>
            <span className={styles.violet}>{props.subjectName}</span>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Pen className={errorClass} onClick={focusLessonNameInputElement}/>
        <input
          id="lesson-name-input"
          className={errorClass}
          onChange={(e) => props.setLessonTopic(e.target.value)}
          value={props.lessonTopic}
          ref={lessonNameInputElement}
        />
        <div className={styles.bottomInfo}>
          {getErrorMessage(props.errors, "topic") && (
            <span className={errorClass}>
              {getErrorMessage(props.errors, "topic")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonCreatorHeader;
