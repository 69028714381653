import pathOr from "ramda/src/pathOr";
import concat from "ramda/src/concat";

// Types
import { testsBaseActionModel, testsBaseStateModel } from "../../../models/redux/testsBase";

const initialState: testsBaseStateModel = {
  list: [],
  listMeta: {
    first: 1,
    last: 1,
    next: null,
    prev: null,
  },
  in_progress: {
    search: false,
  },
};

export const testsBase = (state = initialState, action: testsBaseActionModel) => {
  switch (action.type) {
    case "TESTS_BASE_GET_TESTS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          search: true,
        },
      };
    case "TESTS_BASE_GET_TESTS_SUCCESS":
      return {
        ...state,
        list: pathOr([], ["payload", "data"], action),
        listMeta: pathOr(initialState.listMeta, ["payload", "meta"], action),
        in_progress: {
          ...state.in_progress,
          search: false,
        },
      };
    case "TESTS_BASE_GET_TESTS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          search: false,
        },
      };
    case "TESTS_BASE_GET_TESTS_NEXT_PAGE_SUCCESS":
      return {
        ...state,
        list: concat(state.list, pathOr([], ["payload", "data"], action)),
        listMeta: pathOr(initialState.listMeta, ["payload", "meta"], action),
      };
    default:
      return state;
  }
};
