import pathOr from "ramda/src/pathOr";

// Types
import {
  lessonsStateModel,
  lessonsActionModel,
} from "../../../models/redux/lessons";

const initialState: lessonsStateModel = {
  access_token: null,
  list: [],
  in_progress: {
    update_lessons: false,
    update_lesson_status: false,
  },
};

export const lessons = (state = initialState, action: lessonsActionModel) => {
  switch (action.type) {
    case "GET_LESSONS_SUCCESS":
      return {
        ...state,
        access_token: pathOr(null, ["payload", "data", "access_token"], action),
        list: pathOr([], ["payload", "data", "lessons"], action),
        in_progress: {
          ...state.in_progress,
          update_lesson_status: false,
        },
      };
    case "UNMOUNT_LESSONS":
      return {
        ...state,
        ...initialState,
      };
    case "LESSONS_GENERATE_ACCESS_TOKEN":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lessons: true,
        },
      };
    case "LESSONS_GENERATE_ACCESS_TOKEN_SUCCESS":
      return {
        ...state,
        access_token: pathOr("", ["payload", "data", "access_token"], action),
        in_progress: {
          ...state.in_progress,
          update_lessons: false,
        },
      };
    case "LESSONS_GENERATE_ACCESS_TOKEN_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lessons: false,
        },
      };
    case "PUBLISH_LESSON_SUCCESS":
    case "UNPUBLISH_LESSON_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson_status: true,
        },
      };
    default:
      return state;
  }
};
