import React from "react";

import { ReactComponent as FileIcon } from "../../../../../../assets/images/reports/questionmark.svg";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  pointsAveragePercentageClosedQuestions: number | null;
  pointsAveragePercentageOpenQuestions: number | null;
}

export const ClosedVSOpenQuestionsAverageScore = (props: Props) => {
  return (
    <div className={styles.container}>
      <FileIcon/>
      <div className={styles.infoContainer}>
        <div className={styles.textContainer}>
          <h4>{translate("report.closed-questions")}</h4>
          <h5>{props.pointsAveragePercentageClosedQuestions}%</h5>
        </div>
        <div className={styles.textContainer}>
          <h4>{translate("report.open-questions")}</h4>
          <h5>{props.pointsAveragePercentageOpenQuestions}%</h5>
        </div>
      </div>
    </div>
  );
};

export default ClosedVSOpenQuestionsAverageScore;
