import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import pathOr from "ramda/src/pathOr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import CurriculumDetailsBox from "../../../Curriculum/Details/Box";

import Modal, { ToggleContent } from "../../../../Common/Modal";

import { ReactComponent as ArrowLeft } from "../../../../../assets/images/arrow_left.svg";
import { ReactComponent as PenAltIcon } from "../../../../../assets/images/pen_alt.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../assets/images/trash_alt.svg";

import translate from "../../../../../services/translate";
import { getEducationLevelIcon } from "../../../../../services/common/getEducationLevelIcon";

import {
  getCurriculumForClass,
  updateClassSkills,
  deleteCurriculum,
  unmountCurriculum,
} from "../../../../../redux/actions/curriculum";
import { getSubjects } from "../../../../../redux/actions/teacher";

// Types
import { History, Location } from "history";
import {
  curriculumStateModel,
  getCurriculumForClass as getCurriculumForClassFunction,
  curriculumDetailsSkill,
  updateClassSkills as updateClassSkillsFunction,
  deleteCurriculum as deleteCurriculumFunction,
  unmountCurriculum as unmountCurriculumFunction,
} from "../../../../../models/redux/curriculum";
import {
  teacherStateModel,
  subjectModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
  curriculum: curriculumStateModel;
  subjects: subjectModel[];
  getCurriculumForClass: getCurriculumForClassFunction;
  updateClassSkills: updateClassSkillsFunction;
  getSubjects: getSubjectsFunction;
  deleteCurriculum: deleteCurriculumFunction;
  unmountCurriculum: unmountCurriculumFunction;
}

export const ClassCurriculumDetails = (props: Props) => {
  const [selectedSkills, setSelectedSkills] = useState([]);

  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    props.getSubjects();
    props.getCurriculumForClass({
      class_id: parsed.classID as string,
      curriculum_id: props.match.params.id as string,
    });

    return () => {
      props.unmountCurriculum();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedSkills(
      getAllChosenSkills(
        pathOr([], ["curriculum", "curriculum_details", "skills"], props),
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculum.curriculum_details]);

  const getSubjectName = () => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === parsed.subject,
    );

    if (selectedSubject) {
      return selectedSubject.name;
    }
    return "";
  };

  const getAllChosenSkills = (children: any[], IDsArray?: any) => {
    let arr = IDsArray || ([] as string[]);
    children.forEach((children: any) => {
      if (children.is_chosen) {
        arr.push(children.id);
        if (children.children && !!children.children.length) {
          getAllChosenSkills(children.children, arr);
        }
      }
    });

    return arr;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <Link to={`/class/${parsed.classID}`}>
            <ArrowLeft/>
          </Link>
          <span>{translate("curriculum.curriculum")}</span>
        </h3>
        <div className={styles.buttonContainer}>
          <Link
            to={`/class/curriculum/add?classID=${parsed.classID}&subject=${parsed.subject}&curriculumID=${props.match.params.id}`}
          >
            <PenAltIcon/>
            &nbsp;
            <span>{translate("global.change")}</span>
          </Link>
          <ToggleContent
            toggle={(show: any) => (
              <button type="button" onClick={show}>
                <TrashAltIcon/>
                &nbsp;
                <span>{translate("curriculum.delete-curriculum-short")}</span>
              </button>
            )}
            content={(hide: () => {}) => (
              <Modal isSmall hide={hide}>
                <div className={styles.deleteCurriculumModal}>
                  <TrashAltIcon/>
                  <span className={styles.description}>
                    {translate("curriculum.delete-curriculum-confirmation")}
                  </span>
                  <div className={styles.buttonContainer}>
                    <button type="button" onClick={hide}>
                      {translate("global.cancel")}
                    </button>
                    <button
                      type="button"
                      disabled={props.curriculum.in_progress.delete_curriculum}
                      onClick={() => {
                        props.deleteCurriculum(
                          {
                            class_id: parsed.classID as string,
                            curriculum_id: props.match.params.id as string,
                          },
                          () => props.history.push(`/class/${parsed.classID}`),
                        );
                      }}
                    >
                      {props.curriculum.in_progress.delete_curriculum ? (
                        <FontAwesomeIcon
                          className="fa-spin"
                          icon="circle-notch"
                        />
                      ) : (
                        <span>{translate("global.delete")}</span>
                      )}
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          />
        </div>
      </div>
      <div className={styles.content}>
        {pathOr("", ["curriculum", "curriculum_details", "id"], props) &&
          !selectedSkills.length && (
          <div className={styles.tip}>
            <span>{translate("curriculum.tip")}!</span>
            <p>
              {translate("curriculum.update-class-skills-tip-description")}
            </p>
          </div>
        )}
        <h4>{getSubjectName()}</h4>
        <div className={styles.progress}>
          <span>{translate("curriculum-progress")}</span>
          <ReactTooltip
            id="tooltip"
            place="top"
            effect="solid"
            className={styles.tooltip}
            offset={{ top: 15 }}
          >
            <p>
              {pathOr(
                0,
                ["curriculum", "curriculum_details", "completeness"],
                props,
              )}
              %
            </p>
          </ReactTooltip>
          <div data-tip data-for="tooltip">
            <div
              style={{
                width: `${pathOr(
                  0,
                  ["curriculum", "curriculum_details", "completeness"],
                  props,
                )}%`,
              }}
            />
          </div>
        </div>
        {pathOr(
          "",
          ["curriculum", "curriculum_details", "education_level"],
          props,
        ) && (
          <div className={styles.curriculumInfo}>
            {getEducationLevelIcon(
              pathOr(
                "",
                ["curriculum", "curriculum_details", "education_level"],
                props,
              ),
            )}
            <div className={styles.curriculumInfoText}>
              <span>
                {pathOr(
                  "",
                  ["curriculum", "curriculum_details", "education_level"],
                  props,
                )}
              </span>
              {pathOr(
                "",
                ["curriculum", "curriculum_details", "variant"],
                props,
              ) && (
                <div className={styles.variant}>
                  <span>{translate("global.variant")}:&nbsp;</span>
                  <span>
                    {pathOr(
                      "",
                      ["curriculum", "curriculum_details", "variant"],
                      props,
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        <ul>
          {pathOr(
            [],
            ["curriculum", "curriculum_details", "skills"],
            props,
          ).map((skill: curriculumDetailsSkill) => (
            <CurriculumDetailsBox
              key={skill.id}
              id={skill.id}
              body={skill.body}
              children={skill.children}
              isChosen={skill.is_chosen}
              selectedSkills={selectedSkills}
              inProgress={props.curriculum.in_progress.update_class_skills}
              onClick={(ids: string[]) => {
                props.updateClassSkills({
                  class_id: parsed.classID as string,
                  curriculum_id: props.match.params.id as string,
                  skills: ids,
                });
              }}
              unfoldOnParentClick
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  curriculum: curriculumStateModel;
  teacher: teacherStateModel;
}) => ({
  curriculum: state.curriculum,
  subjects: state.teacher.subjects,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCurriculumForClass,
      updateClassSkills,
      getSubjects,
      deleteCurriculum,
      unmountCurriculum,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassCurriculumDetails);
