import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

import translate from "../../../../../../services/translate";

import { ReactComponent as EyeIcon } from "../../../../../../assets/images/eye_icon.svg";

// Types
import { testReportQuestionModel } from "../../../../../../models/redux/report";

import styles from "./styles.module.scss";

interface Props {
  questions: testReportQuestionModel[];
  testID: string;
  classID: string;
  displayOnly?: boolean;
}

export const TestReportClassQuestionsStats = (props: Props) => {
  const percentagePointContainerClass = (points: number | null) =>
    classNames({
      [styles.scorePercentagePoints]: true,
      [styles.valueLeft]: Number(points) >= 60 && Number(points) <= 90,
      [styles.valueCenter]: Number(points) > 90,
      [styles.valueRight]: Number(points) < 60,
    });

  const getWrongAnswersPercentage = (correctAnswersPercentage: number | null) =>
    100 - Number(correctAnswersPercentage);

  return (
    <div className={styles.container}>
      {!props.displayOnly && (
        <div className={styles.header}>
          <h4>{translate("report.questions-stats")}</h4>
        </div>
      )}
      <ul className={styles.questions}>
        <li className={styles.headingItem}>
          <div className={styles.questionNumber}>
            <span>{translate("report.question-number-short")}</span>
          </div>
          <div className={styles.scorePercentagePoints}>
            <span>{translate("report.correct-answers-short")}</span>
          </div>
          <div className={styles.scorePercentagePoints}>
            <span>{translate("report.wrong-answers-short")}</span>
          </div>
          <div className={styles.actions}></div>
        </li>
        {props.questions.map((item: testReportQuestionModel) => (
          <li className={styles.listItem} key={item.number}>
            <div className={styles.questionNumber}>
              <span className={styles.number}>{item.number}</span>
            </div>
            <div
              className={percentagePointContainerClass(
                item.correct_answers_percentage,
              )}
            >
              <i>
                <i
                  className={styles.percentageBar}
                  style={{ width: `${item.correct_answers_percentage}%` }}
                ></i>
                <span className={styles.percentagePoints}>
                  {item.correct_answers_percentage}%
                </span>
              </i>
            </div>
            <div
              className={percentagePointContainerClass(
                getWrongAnswersPercentage(item.correct_answers_percentage),
              )}
            >
              <i>
                <i
                  className={styles.percentageBar}
                  style={{
                    width: `${getWrongAnswersPercentage(
                      item.correct_answers_percentage,
                    )}%`,
                  }}
                ></i>
                <span className={styles.percentagePoints}>
                  {getWrongAnswersPercentage(item.correct_answers_percentage)}%
                </span>
              </i>
            </div>
            <div className={styles.actions}>
              {!props.displayOnly && (
                <div className={styles.tooltipContainer}>
                  <ReactTooltip
                    id="tooltip"
                    place="left"
                    effect="solid"
                    className={styles.tooltip}
                  >
                    <p>{translate("global.details")}</p>
                  </ReactTooltip>
                  <i data-tip data-for="tooltip">
                    <Link
                      to={`/test/${props.testID}/report/class/${props.classID}/question/${item.number}`}
                    >
                      <EyeIcon/>
                    </Link>
                  </i>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestReportClassQuestionsStats;
