import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, mergeMap } from "rxjs/operators";
import { concat, of, empty } from "rxjs";

import {
  getEducationLevelSuccess,
  getEducationLevelFailure,
  getCurriculumsSuccess,
  getCurriculumsFailure,
  createCurriculumSuccess,
  createCurriculumFailure,
  getCurriculumsForClassSuccess,
  getCurriculumsForClassFailure,
  getCurriculumForClassSuccess,
  getCurriculumForClassFailure,
  updateClassSkillsSuccess,
  updateClassSkillsFailure,
  deleteCurriculumSuccess,
  deleteCurriculumFailure,
  getQuestionSkillsSuccess,
  getQuestionSkillsFailure,
  updateQuestionSkillsSuccess,
  updateQuestionSkillsFailure,
  updateCurriculumForClassSuccess,
  updateCurriculumForClassFailure,
} from "../../actions/curriculum";
import { showToast } from "../../actions/app";

import { apiCall } from "../../../services/api";
import { generateArraySearchQuery } from "../../../services/common";

// Types
import {
  getEducationLevelAction,
  getCurriculumsAction,
  getCurriculumsPayload,
  createCurriculumAction,
  createCurriculumPayload,
  assignCurriculumsAction,
  createCurriculumSuccessAction,
  getCurriculumsForClassAction,
  getCurriculumsForClassPayload,
  getCurriculumForClassAction,
  getCurriculumForClassPayload,
  updateClassSkillsAction,
  updateClassSkillsPayload,
  updateClassSkillsSuccessAction,
  deleteCurriculumAction,
  deleteCurriculumPayload,
  deleteCurriculumSuccessAction,
  deleteCurriculumFailureAction,
  getQuestionSkillsAction,
  getQuestionSkillsPayload,
  updateQuestionSkillsAction,
  updateQuestionSkillsPayload,
  updateQuestionSkillsSuccessAction,
  updateQuestionSkillsFailureAction,
  updateCurriculumForClassAction,
  updateCurriculumForClassPayload,
  updateCurriculumForClassSuccessAction,
  updateCurriculumForClassFailureAction,
  updateQuestionSkillsSuccessPayload,
  getEducationLevelPayload,
} from "../../../models/redux/curriculum";
import { XHRPayload } from "../../../models/common";
import { getTest } from "../test";

// Get Education Level

export const getEducationLevelEpic: Epic = (
  action$: ActionsObservable<getEducationLevelAction>,
) =>
  action$.pipe(
    ofType("GET_EDUCATION_LEVEL"),
    pluck("payload"),
    switchMap(getEducationLevel),
  );

export const getEducationLevel = (payload: getEducationLevelPayload) =>
  apiCall(
    `/teachers/education-levels${generateArraySearchQuery(
      payload.subjects,
      "subjects",
    )}`,
    "GET",
    {},
    (data: XHRPayload) => getEducationLevelSuccess(data.response),
    getEducationLevelFailure,
  );

// Get Curriculums

export const getCurriculumsEpic: Epic = (
  action$: ActionsObservable<getCurriculumsAction>,
) =>
  action$.pipe(
    ofType("GET_CURRICULUMS"),
    pluck("payload"),
    switchMap(getCurriculums),
  );

export const getCurriculums = (payload: getCurriculumsPayload) =>
  apiCall(
    `/teachers/education-levels/${payload.id}/curriculums?subject_id=${payload.subject_id}`,
    "GET",
    {},
    (data: XHRPayload) => getCurriculumsSuccess(data.response),
    getCurriculumsFailure,
  );

// Create Curriculum

export const createCurriculumEpic: Epic = (
  action$: ActionsObservable<createCurriculumAction>,
) =>
  action$.pipe(
    ofType("CREATE_CURRICULUM"),
    pluck("payload"),
    switchMap(createCurriculum),
  );

export const createCurriculum = (payload: createCurriculumPayload) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums`,
    "POST",
    {
      curriculum_id: payload.curriculum_id,
    },
    () => createCurriculumSuccess({ class_id: payload.class_id }),
    createCurriculumFailure,
  );

export const createCurriculumSuccessEpic: Epic = (
  action$: ActionsObservable<createCurriculumSuccessAction>,
) =>
  action$.pipe(
    ofType("CREATE_CURRICULUM_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.curriculum-has-been-saved",
            status: "success",
          }),
        ),
      ),
    ),
  );

// Assign Curriculums

export const assignCurriculumsEpic: Epic = (
  action$: ActionsObservable<assignCurriculumsAction>,
) =>
  action$.pipe(
    ofType("ASSIGN_CURRICULUMS"),
    mergeMap((data: any) =>
      concat(
        ...data.payload.curriculums.map((curriculumID: string) => {
          return createCurriculum({
            curriculum_id: curriculumID,
            class_id: data.payload.class_id,
          });
        }),
        data.payload.class_id
          ? getCurriculumsForClass({
            class_id: data.payload.class_id,
          })
          : of(empty()),
      ),
    ),
  );

// Get Curriculums For Class

export const getCurriculumsForClassEpic: Epic = (
  action$: ActionsObservable<getCurriculumsForClassAction>,
) =>
  action$.pipe(
    ofType("GET_CURRICULUMS_FOR_CLASS"),
    pluck("payload"),
    switchMap(getCurriculumsForClass),
  );

export const getCurriculumsForClass = (
  payload: getCurriculumsForClassPayload,
) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums`,
    "GET",
    {},
    (data: XHRPayload) => getCurriculumsForClassSuccess(data.response),
    getCurriculumsForClassFailure,
  );

// Get Curriculum For a Class

export const getCurriculumForClassEpic: Epic = (
  action$: ActionsObservable<getCurriculumForClassAction>,
) =>
  action$.pipe(
    ofType("GET_CURRICULUM_FOR_CLASS"),
    pluck("payload"),
    switchMap(getCurriculumForClass),
  );

export const getCurriculumForClass = (payload: getCurriculumForClassPayload) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums/${payload.curriculum_id}`,
    "GET",
    {},
    (data: XHRPayload) => getCurriculumForClassSuccess(data.response),
    getCurriculumForClassFailure,
  );

// Update Curriculum For a Class

export const updateCurriculumForClassEpic: Epic = (
  action$: ActionsObservable<updateCurriculumForClassAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CURRICULUM_FOR_CLASS"),
    switchMap((data) => updateCurriculumForClass(data.payload, data.callback)),
  );

export const updateCurriculumForClass = (
  payload: updateCurriculumForClassPayload,
  callback?: () => any,
) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums/${payload.curriculum_id}`,
    "PATCH",
    {
      target_curriculum_id: payload.target_curriculum_id,
    },
    () => updateCurriculumForClassSuccess(callback),
    updateCurriculumForClassFailure,
  );

export const updateCurriculumForClassSuccessEpic: Epic = (
  action$: ActionsObservable<updateCurriculumForClassSuccessAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CURRICULUM_FOR_CLASS_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.curriculum-has-been-deleted",
            status: "success",
          }),
        ),
      ),
    ),
  );

// Get Question's Skills

export const getQuestionSkillsEpic: Epic = (
  action$: ActionsObservable<getQuestionSkillsAction>,
) =>
  action$.pipe(
    ofType("GET_QUESTION_SKILLS"),
    switchMap((data) => getQuestionSkills(data.payload, data.callback)),
  );

export const getQuestionSkills = (
  payload: getQuestionSkillsPayload,
  callback?: () => any,
) =>
  apiCall(
    `/tests/questions/${payload.question_id}/skills`,
    "GET",
    {},
    (data: XHRPayload) => getQuestionSkillsSuccess(data.response, callback),
    getQuestionSkillsFailure,
  );

// Update Question's Skills

export const updateQuestionSkillsEpic: Epic = (
  action$: ActionsObservable<updateQuestionSkillsAction>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_SKILLS"),
    switchMap((data) => updateQuestionSkills(data.payload, data.callback)),
  );

export const updateQuestionSkills = (
  payload: updateQuestionSkillsPayload,
  callback?: () => any,
) =>
  apiCall(
    `/tests/questions/${payload.question_id}/skills`,
    "PATCH",
    {
      skills: payload.skills,
    },
    () =>
      updateQuestionSkillsSuccess(
        {
          class_id: payload.class_id,
          test_id: payload.test_id,
        },
        callback,
      ),
    updateQuestionSkillsFailure,
  );

export const updateQuestionSkillsSuccessEpic: Epic = (
  action$: ActionsObservable<updateQuestionSkillsSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_SKILLS_SUCCESS"),
    pluck("payload"),
    mergeMap((payload: updateQuestionSkillsSuccessPayload) =>
      concat(
        getTest(
          {
            classID: payload.class_id,
            testID: payload.test_id,
          },
          state$,
        ),
        of(
          showToast({
            text: "curriculum.skill-has-been-assigned-to-a-question",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const updateQuestionSkillsFailureEpic: Epic = (
  action$: ActionsObservable<updateQuestionSkillsFailureAction>,
) =>
  action$.pipe(
    ofType("UPDATE_QUESTION_SKILLS_FAILURE"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.skill-has-not-been-assigned-to-a-question",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const updateCurriculumForClassFailureEpic: Epic = (
  action$: ActionsObservable<updateCurriculumForClassFailureAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CURRICULUM_FOR_CLASS_FAILURE"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.curriculum-has-been-saved",
            status: "success",
          }),
        ),
      ),
    ),
  );

// Update Class Skills

export const updateClassSkillsEpic: Epic = (
  action$: ActionsObservable<updateClassSkillsAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CLASS_SKILLS"),
    pluck("payload"),
    switchMap(updateClassSkills),
  );

export const updateClassSkills = (payload: updateClassSkillsPayload) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums/${payload.curriculum_id}/skills`,
    "PATCH",
    {
      skills: payload.skills,
    },
    () => updateClassSkillsSuccess(payload),
    updateClassSkillsFailure,
  );

export const updateClassSkillsSuccessEpic: Epic = (
  action$: ActionsObservable<updateClassSkillsSuccessAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CLASS_SKILLS_SUCCESS"),
    pluck("payload"),
    switchMap(getCurriculumForClass),
  );

// Delete Curriculum

export const deleteCurriculumEpic: Epic = (
  action$: ActionsObservable<deleteCurriculumAction>,
) =>
  action$.pipe(
    ofType("DELETE_CURRICULUM"),
    switchMap((data) => deleteCurriculum(data.payload, data.callback)),
  );

export const deleteCurriculum = (
  payload: deleteCurriculumPayload,
  callback?: () => any,
) =>
  apiCall(
    `/teachers/classes/${payload.class_id}/curriculums/${payload.curriculum_id}`,
    "DELETE",
    {},
    () => deleteCurriculumSuccess(payload, callback),
    deleteCurriculumFailure,
  );

export const deleteCurriculumSuccessEpic: Epic = (
  action$: ActionsObservable<deleteCurriculumSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_CURRICULUM_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.curriculum-has-been-deleted",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const deleteCurriculumFailureEpic: Epic = (
  action$: ActionsObservable<deleteCurriculumFailureAction>,
) =>
  action$.pipe(
    ofType("DELETE_CURRICULUM_FAILURE"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "curriculum.curriculum-has-not-been-deleted",
            status: "success",
          }),
        ),
      ),
    ),
  );
