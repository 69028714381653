import React, { ReactElement } from "react";
import classNames from "classnames";
import { ReactComponent as Check } from "../../../assets/images/checkbox_check.svg";

import styles from "./styles.module.scss";


interface Props {
  checked: boolean;
  onClick: () => any;
  children?: string | ReactElement;
  disabled?: boolean;
  radio?: boolean;
  noIcon?: boolean;
};

const Checkbox = (props: Props) => {
  const containerClass = classNames({
    [styles.container]: true,
    [styles.disabledContainer]: props.disabled,
    [styles.checkbox]: !props.radio,
    [styles.radio]: props.radio,
    [styles.noIcon]: props.noIcon,
    [styles.noIconChecked]: props.noIcon && props.checked,
  });

  return (
    <label className={containerClass}>
      {props.children}
      <input
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onClick}
      />
      <span className={styles.checkmark}>
        {props.checked && <Check/>}
      </span>
    </label>
  );
};

export default Checkbox;
