import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";

import { ReactComponent as ArrowLeft } from "../../../../../assets/images/arrow_left.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/images/save_icon.svg";

import EducationLevelBox from "../../../Curriculum/EducationLevel/Box";
import CurriculumListItem from "../../../Curriculum/ListItem";

import translate from "../../../../../services/translate";
import {
  getCurriculumsList,
  updateSelectedCurriculumsArray,
  curriculumsListItem,
} from "../../../../../services/common/curriculums";
import { getChosenSubjectsIDs } from "../../../../../services/common/subjects";

import {
  getEducationLevel,
  getCurriculums,
  unmountCurriculum,
  assignCurriculums,
  updateCurriculumForClass,
} from "../../../../../redux/actions/curriculum";
import { historyPush } from "../../../../../redux/actions/app";
import { getSubjects } from "../../../../../redux/actions/teacher";

// Types
import { History, Location } from "history";
import { ErrorsPayload } from "../../../../../models/common";
import {
  teacherStateModel,
  subjectModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";
import {
  curriculumStateModel,
  educationLevel,
  getEducationLevel as getEducationLevelFunction,
  getCurriculums as getCurriculumsFunction,
  unmountCurriculum as unmountCurriculumFunction,
  assignCurriculums as assignCurriculumsFunction,
  updateCurriculumForClass as updateCurriculumForClassFunction,
} from "../../../../../models/redux/curriculum";
import { historyPush as historyPushFunction } from "../../../../../models/redux/app";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  errors: ErrorsPayload[];
  userLanguage: string;
  getEducationLevel: getEducationLevelFunction;
  curriculum: curriculumStateModel;
  getCurriculums: getCurriculumsFunction;
  unmountCurriculum: unmountCurriculumFunction;
  assignCurriculums: assignCurriculumsFunction;
  historyPush: historyPushFunction;
  updateCurriculumForClass: updateCurriculumForClassFunction;
  getSubjects: getSubjectsFunction;
  subjects: subjectModel[];
}

export const ClassCurriculumAdd = (props: Props) => {
  const [selectedEducationLevelID, setSelectedEducationLevelID] = useState("");
  const [selectedCurriculums, setSelectedCurriculums] = useState<string[]>([]);
  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    props.getSubjects();

    if (!!props.subjects.length) {
      props.getEducationLevel({
        subjects: getChosenSubjectsIDs(getSubjectsByType(props.subjects)),
      });
    }

    return () => {
      props.unmountCurriculum();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEducationLevelID && !!props.subjects.length) {
      const selectedSubject = getSubjectsByType(props.subjects)[0];
      if (selectedSubject) {
        props.getCurriculums({
          id: selectedEducationLevelID,
          subject_id: selectedSubject.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEducationLevelID, props.subjects]);

  useEffect(() => {
    if (!!props.subjects.length) {
      props.getEducationLevel({
        subjects: getChosenSubjectsIDs(getSubjectsByType(props.subjects)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjects]);

  const getSubjectsByType = (subjects: subjectModel[]) =>
    subjects.filter((item: subjectModel) => item.type === parsed.subject);

  const getFilteredCurriculumList = () =>
    getCurriculumsList(props.curriculum.curriculums).filter(
      (item: curriculumsListItem) => item.subject === parsed.subject,
    );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <button onClick={props.history.back}>
            <ArrowLeft/>
          </button>
          <span>{translate("curriculum.curriculum")}</span>
        </h3>
        <div className={styles.buttonContainer}>
          <button
            type="button"
            onClick={() => {
              if (parsed.curriculumID) {
                props.updateCurriculumForClass(
                  {
                    curriculum_id: parsed.curriculumID as string,
                    class_id: parsed.classID as string,
                    target_curriculum_id: selectedCurriculums[0],
                  },
                  () =>
                    props.history.push(
                      `/class/curriculum/${selectedCurriculums[0]}?classID=${parsed.classID}&subject=${parsed.subject}`,
                    ),
                );
                return;
              }
              props.assignCurriculums(
                {
                  curriculums: selectedCurriculums,
                  class_id: parsed.classID as string,
                },
                () =>
                  props.historyPush(
                    props.history,
                    `/class/curriculum/${selectedCurriculums[0]}?classID=${parsed.classID}&subject=${parsed.subject}`,
                  ),
              );
            }}
            disabled={!selectedEducationLevelID || !selectedCurriculums.length}
          >
            <SaveIcon/>
            <span>{translate("global.save")}</span>
          </button>
        </div>
      </div>
      <div className={styles.content}>
        <h4>
          {translate("global.pick-up")}&nbsp;
          <span className={styles.primaryHeadingText}>
            {translate("class.education-level")}
          </span>
        </h4>
        <div className={styles.educationLevelContainer}>
          {props.curriculum.education_level.map((item: educationLevel) => {
            return (
              <EducationLevelBox
                key={item.id}
                id={item.id}
                level={item.level}
                onClick={(id: string) => {
                  setSelectedCurriculums([]);
                  setSelectedEducationLevelID(id);
                }}
                isChecked={item.id === selectedEducationLevelID}
              />
            );
          })}
        </div>
        {!!getFilteredCurriculumList().length && (
          <>
            <h4>
              {translate("global.pick-up")}&nbsp;
              <span className={styles.primaryHeadingText}>
                {translate("global.variant")}
              </span>
            </h4>
            <div className={styles.curriculumsContainer}>
              {getFilteredCurriculumList().map((item: curriculumsListItem) => {
                return (
                  <CurriculumListItem
                    key={item.subject}
                    list={item.list}
                    selectedCurriculums={selectedCurriculums}
                    onClick={(id: string) => {
                      setSelectedCurriculums(
                        updateSelectedCurriculumsArray(
                          id,
                          selectedCurriculums,
                          item.list,
                        ),
                      );
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  curriculum: curriculumStateModel;
  teacher: teacherStateModel;
}) => ({
  curriculum: state.curriculum,
  subjects: state.teacher.subjects,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getEducationLevel,
      getCurriculums,
      unmountCurriculum,
      assignCurriculums,
      historyPush,
      updateCurriculumForClass,
      getSubjects,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassCurriculumAdd);
