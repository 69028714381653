import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import isNil from "ramda/src/isNil";

import translate from "../../../../../services/translate";

import {
  getClassReport,
  resetClassReport,
  generateClassReportPDF,
} from "../../../../../redux/actions/report";
import { getTest, createTestResults } from "../../../../../redux/actions/test";
import { unmountClass } from "../../../../../redux/actions/class";

import TestReportClassParticipants from "./Participants";
import TestReportClassAverageScore from "./AverageScore";
import TestReportClassStudents from "./Students";
import TestReportClassQuestionsStats from "./QuestionsStats";
import TestReportClassClosedVSOpenQuestionsAverageScore from "./ClosedVSOpenQuestionsAverageScore";

// Types
import { History, Location } from "history";
import { reportStateModel } from "../../../../../models/redux/report";
import {
  testReportModel,
  getClassReport as getClassReportFunction,
  resetClassReport as resetClassReportFunction,
  generateClassReportPDF as generateClassReportPDFFunction,
} from "../../../../../models/redux/report";
import { createTestResults as createTestResultsFunction } from "../../../../../models/redux/test";
import { userStateModel } from "../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
  report: testReportModel;
  getClassReport: getClassReportFunction;
  resetClassReport: resetClassReportFunction;
  generateClassReportPDF: generateClassReportPDFFunction;
  unmountClass: () => {};
  generatePDFinProgress: boolean;
  createTestResults: createTestResultsFunction;
  hasFullAccess: boolean;
}

export const TestReportClass = (props: Props) => {
  useEffect(() => {
    props.getClassReport({ testID: props.match.params.id });

    return () => {
      props.resetClassReport();
      props.unmountClass();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h4>
          <Link
            to={`/test?classID=${props.report.class_id}&testID=${props.match.params.id}`}
          >
            {props.report.name}
          </Link>{" "}
          /&nbsp;
          <span>{translate("report.test-report")}</span>
        </h4>
        <h3>{translate("report.test-report")}</h3>
        <div className={styles.tooltipContainer}>
          {!props.hasFullAccess && (
            <ReactTooltip
              id="class-report-generate-pdf-limit-tooltip"
              place="top"
              effect="solid"
              className={styles.tooltip}
            >
              <span>
                {translate(
                  "report.generate-pdf-limit-paid-version-description",
                )}
              </span>
            </ReactTooltip>
          )}
          <div data-tip data-for="class-report-generate-pdf-limit-tooltip">
            <button
              type="button"
              disabled={props.generatePDFinProgress || !props.hasFullAccess}
              onClick={() =>
                props.generateClassReportPDF({ testID: props.match.params.id })
              }
            >
              {props.generatePDFinProgress ? (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              ) : (
                <span>{translate("report.generate-pdf")}</span>
              )}
            </button>
          </div>
        </div>
      </div>
      <section className={styles.content}>
        <div className={styles.statsBoxesContainer}>
          <TestReportClassParticipants
            studentsTotal={props.report.students_total}
            scannedTests={props.report.participants}
          />
          <TestReportClassAverageScore
            pointsAveragePercentage={props.report.points_average_percentage}
          />
          {!isNil(props.report.points_average_percentage_closed_questions) &&
            !isNil(props.report.points_average_percentage_open_questions) && (
            <TestReportClassClosedVSOpenQuestionsAverageScore
              pointsAveragePercentageClosedQuestions={
                props.report.points_average_percentage_closed_questions
              }
              pointsAveragePercentageOpenQuestions={
                props.report.points_average_percentage_open_questions
              }
            />
          )}
        </div>
        <TestReportClassStudents
          students={props.report.students}
          pointsTotal={props.report.points_total}
          testID={props.match.params.id}
          createTestResults={props.createTestResults}
          history={props.history}
        />
        <TestReportClassQuestionsStats
          questions={props.report.questions}
          testID={props.match.params.id}
          classID={props.report.class_id}
        />
      </section>
    </section>
  );
};

const mapStateToProps = (state: {
  report: reportStateModel;
  user: userStateModel;
}) => ({
  report: state.report.class,
  generatePDFinProgress: state.report.in_progress.generate_pdf,
  hasFullAccess: state.user.access.full_access,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClassReport,
      resetClassReport,
      getTest,
      unmountClass,
      generateClassReportPDF,
      createTestResults,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestReportClass);
