import React from "react";
import { ReactComponent as Biology } from "../../../assets/images/subjects/biology.svg";
import { ReactComponent as Chemistry } from "../../../assets/images/subjects/chemistry.svg";
import { ReactComponent as English } from "../../../assets/images/subjects/english.svg";
import { ReactComponent as French } from "../../../assets/images/subjects/french.svg";
import { ReactComponent as Geography } from "../../../assets/images/subjects/geography.svg";
import { ReactComponent as German } from "../../../assets/images/subjects/german.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/images/subjects/history.svg";
import { ReactComponent as Mathematics } from "../../../assets/images/subjects/mathematics.svg";
import { ReactComponent as Physics } from "../../../assets/images/subjects/physics.svg";
import { ReactComponent as Polish } from "../../../assets/images/subjects/polish.svg";
import { ReactComponent as Russian } from "../../../assets/images/subjects/russian.svg";
import { ReactComponent as Other } from "../../../assets/images/subjects/other.svg";
import { ReactComponent as PrimaryEducation } from "../../../assets/images/subjects/primary_education.svg";

export const getSubjectIcon = (type: string) => {
  const components = {
    biology: Biology,
    chemistry: Chemistry,
    english: English,
    french: French,
    geography: Geography,
    german: German,
    history: HistoryIcon,
    mathematics: Mathematics,
    physics: Physics,
    polish: Polish,
    russian: Russian,
    other: Other,
    primary_education: PrimaryEducation,
  } as any;

  if (type) {
    const IconName = components[type];
    if (IconName) {
      return React.createElement(IconName, {});
    }
  }
};
