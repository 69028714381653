import React, { useState } from "react";

import ReactCrop from "react-image-crop";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "../../../../../../../Common/Modal";
import translate from "../../../../../../../../services/translate";

import { ReactComponent as PictureIcon } from "../../../../../../../../assets/images/picture.svg";
import { ReactComponent as SwitchCameraIcon } from "../../../../../../../../assets/images/switch_camera.svg";

import "react-image-crop/dist/ReactCrop.css";

import styles from "./styles.module.scss";

interface Props {
  hide: () => any;
  updateQuestion: any;
  questionInProgress: boolean;
}

export const AddPicture = (props: Props) => {
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement>();
  const [crop, setCrop] = useState({
    unit: "%" as "%",
    x: 0,
    y: 0,
    height: 100,
    width: 100,
    maxHeight: 400,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png"],
    onDrop: (file: any) => {
      setPreview(URL.createObjectURL(file[0]));
    },
  });

  const getCroppedImg = (image: any, crop: any, fileName: string) => {
    const canvas = document.createElement("canvas");
    canvas.width = (image.width * crop.width) / 100;
    canvas.height = (image.height * crop.height) / 100;
    const ctx = canvas.getContext("2d") as any;

    ctx.drawImage(
      image,
      (crop.x * image.naturalWidth) / 100,
      (crop.y * image.naturalHeight) / 100,
      (crop.width * image.naturalWidth) / 100,
      (crop.height * image.naturalHeight) / 100,
      0,
      0,
      (crop.width * image.width) / 100,
      (crop.height * image.height) / 100,
    );

    // CROP
    return new Promise((resolve) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        const fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    })
      .then((blobUrl: any) => fetch(blobUrl))
      .then((response: any) => response.blob())
      .then((blob: any) => blob);
  };

  const makeClientCrop = async (percentCrop: any, imageRef: any) => {
    if (imageRef && percentCrop.width && percentCrop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        percentCrop,
        "question_img.jpg",
      );
      setFile(croppedImageUrl);
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
    makeClientCrop(crop, image);
  };

  const onCropComplete = (crop: any, percentCrop: any) => {
    makeClientCrop(percentCrop, imageRef);
  };

  const savePicture = (callback?: () => any) => {
    if (file) {
      let data = new FormData();
      const newFile = new File([file as any], "question_img.jpg");

      data.append("image", newFile);
      props.updateQuestion(data, callback);
    }
  };

  return (
    <Modal hide={props.hide} customStyles={styles.modal}>
      <div className={styles.addPictureModal}>
        <span>{translate("creator.add-picture")}</span>
        {preview ? (
          <div className={styles.cropImageContainer}>
            <ReactCrop
              onChange={(crop: any, percentCrop: any) => setCrop(percentCrop)}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              src={preview}
              crop={crop}
              className={styles.cropImage}
            />
          </div>
        ) : (
          <div className={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps()}/>
            <PictureIcon/>
            <p>{translate("creator.add-picture-desc")}</p>
            <span>{translate("creator.allowed-file-formats")}:</span>
            <span>.jpg .png</span>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <button type="button" onClick={props.hide}>
            {translate("global.cancel")}
          </button>
          {preview && (
            <div className={styles.changePicture} {...getRootProps()}>
              <input {...getInputProps()}/>
              <SwitchCameraIcon/>
            </div>
          )}
          <button
            type="button"
            onClick={() => savePicture(props.hide)}
            disabled={!preview || props.questionInProgress}
          >
            {props.questionInProgress ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <span>{translate("creator.crop")}</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPicture;
