import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";

// Types
import {
  QuestionTypes,
  questionModel,
  QuestionSubtype,
  answerChoiceModel,
} from "../../../../../../../../models/redux/test";

import stylesDefault from "../styles.module.scss";
import stylesCompact from "../styles_compact.module.scss";


interface Props {
  question: questionModel;
  isCompact?: boolean;
  showCorrectAnswers?: boolean;
}

export const QuestionMultipleChoiceSingleAnswerPreview = (props: Props) => {
  const styles = props.isCompact ? stylesCompact : stylesDefault;

  const isMultipleChoice = () => {
    return props.question.type === QuestionTypes.MULTIPLE_CHOICE;
  };

  const isSingleSubType = () => {
    return props.question.type_data.subtype === QuestionSubtype.SINGLE;
  };

  const multipleChoiceAnswerClass = (isCorrect: boolean) => classNames({
    [styles.multipleChoiceAnswerCorrect]: props.showCorrectAnswers && isCorrect,
  });

  return (
    <>
      {(isMultipleChoice() && isSingleSubType()) &&
        <div
          data-type="question-answers-mc-sa"
          className={styles.container}
        >
          {props.question.answer_choices.map((item: answerChoiceModel) => (
            <div
              key={`${item.id}_${item.order}`}
              className={styles.listItemContainer}
            >
              <li>
                <div>
                  <div className={styles.inputContainer}>
                    <input
                      type="radio"
                      name=""
                      checked={props.showCorrectAnswers && item.is_correct}
                      value=""
                      onChange={() => {}}
                    />
                    <span className={multipleChoiceAnswerClass(item.is_correct)}>{"ABCDEF"[item.order - 1]}</span>
                  </div>
                  <div
                    className={styles.answerName}
                    dangerouslySetInnerHTML={{ __html: `${item.name_html ? DOMPurify.sanitize(item.name_html) : DOMPurify.sanitize(item.name)}` }}
                  ></div>
                </div>
              </li>
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default QuestionMultipleChoiceSingleAnswerPreview;
