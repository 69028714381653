import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { getTranslations } from "../../redux/actions/app";
import { signInByToken } from "../../redux/actions/auth";
import { ReactComponent as Logo } from "../../assets/images/wisdy_logo.svg";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import queryString from "query-string";

import App from "../App";
import AccountConfirmation from "../AccountConfirmation";
import SignUp from "../SignUp";
import SignIn from "../SignIn";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import Demo from "../Demo";
import DemoSignUp from "../Demo/SignUp";
import LessonDetails from "../App/Class/Lessons/Details";
import LessonsClass from "../App/Class/Lessons";
import TestOnlineSolve from "../App/Test/Online/Solve";
import TestOnlineStudentVerification from "../App/Test/Online/StudentVerification";
import TestOnlineSuccess from "../App/Test/Online/Success";
import Toast from "../Common/Toast";

import EmailHasBeenSent from "../EmailHasBeenSent";

import { getRedirectToPath } from "../../services/common";

// Types
import { authStateModel } from "../../models/redux/auth";
import { appStateModel, toastModel } from "../../models/redux/app";
import { teacherStateModel } from "../../models/redux/teacher";
import { userStateModel } from "../../models/redux/user";

import "./styles.scss";

interface Props {
  signInByToken: () => {};
  getTranslations: () => {};
  translations: any;
  auth: authStateModel;
  toast: toastModel;
  teacher?: teacherStateModel;
  user?: userStateModel;
}

const nonAuthenticatedRoute = (path: string, Component: any, props: Props) => {
  return (
    <Route
      exact
      path={path}
      render={(routeProps) => {
        if (props.auth.token) {
          if (window) {
            const parsed = queryString.parse(window.location.search);
            if (parsed && parsed.redirect_to) {
              return <Redirect to={`${parsed.redirect_to}`}/>;
            }
          }
          return <Redirect to="/"/>;
        }
        return <Component {...routeProps}/>;
      }}
    />
  );
};

export const Core = (props: Props) => {
  const [isLoading, updateIsLoading] = useState(true);

  useEffect(() => {
    props.signInByToken();
    props.getTranslations();

    const rootElement = document.getElementById("root") as HTMLElement;
    if (window && rootElement) {
      rootElement.style.height = `${window.innerHeight}px`;

      window.onresize = function () {
        rootElement.style.height = `${window.innerHeight}px`;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isLoading &&
      !isEmpty(props.translations) &&
      !isNil(props.auth.isAuthenticated)
    ) {
      updateIsLoading(false);
    }
  }, [props, isLoading]);

  return (
    <Router>
      {isLoading ? (
        <div className="loader">
          <Logo/>
        </div>
      ) : (
        <div className="core">
          <Switch>
            {nonAuthenticatedRoute("/sign-up", SignUp, props)}
            {nonAuthenticatedRoute("/sign-in", SignIn, props)}
            {nonAuthenticatedRoute("/forgot-password", ForgotPassword, props)}
            {nonAuthenticatedRoute("/reset-password", ResetPassword, props)}
            <Route path="/demo" component={Demo}/>
            <Route path="/lessons/:lessonID" component={LessonDetails}/>
            <Route exact path="/lessons" component={LessonsClass}/>
            <Route exact path="/test-online" component={TestOnlineSolve}/>
            <Route
              exact
              path="/test-online-verification"
              component={TestOnlineStudentVerification}
            />
            <Route
              exact
              path="/test-online-success"
              component={TestOnlineSuccess}
            />
            <Route exact path="/demo-sign-up" component={DemoSignUp}/>
            <Route
              exact
              path="/account-confirmation"
              component={AccountConfirmation}
            />
            <Route
              exact
              path="/email-has-been-sent"
              component={EmailHasBeenSent}
            />
            <Route
              path="/"
              render={(routeProps) => {
                if (!props.auth.token) {
                  return <Redirect to={`/sign-in${getRedirectToPath()}`}/>;
                }
                return <App {...(routeProps as any)}/>;
              }}
            />
          </Switch>
          {props.toast.text && <Toast toast={props.toast}/>}
          <div id="modal-root"></div>
          <div id="modal-formula"></div>
          <div id="modal-video"></div>
        </div>
      )}
    </Router>
  );
};

const mapStateToProps = (state: {
  auth: authStateModel;
  app: appStateModel;
}) => ({
  auth: state.auth,
  translations: state.app.translations.data,
  toast: state.app.toast,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTranslations,
      signInByToken,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Core);
