import merge from "ramda/src/merge";
import { of, concat } from "rxjs";
import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, map, mergeMap } from "rxjs/operators";

import {
  createLessonSuccess,
  createLessonFailure,
  getLessonSuccess,
  getLessonFailure,
  updateLessonSuccess,
  updateLessonFailure,
  deleteLessonSuccess,
  deleteLessonFailure,
  lessonGenerateAccessTokenSuccess,
  lessonGenerateAccessTokenFailure,
  publishLessonSuccess,
  publishLessonFailure,
  unpublishLessonSuccess,
  unpublishLessonFailure,
  duplicateLessonSuccess,
  duplicateLessonFailure,
} from "../../actions/lesson";
import { showToast } from "../../actions/app";
import { getLessons } from "../../actions/lessons";

import { apiCall, getTokenFromState } from "../../../services/api";

// Types
import {
  createLessonAction,
  createLessonPayload,
  createLessonSuccessAction,
  getLessonPayload,
  getLessonAction,
  updateLessonAction,
  updateLessonPayload,
  updateLessonSuccessAction,
  deleteLessonPayload,
  deleteLessonAction,
  deleteLessonSuccessAction,
  lessonGenerateAccessTokenAction,
  lessonGenerateAccessTokenPayload,
  lessonGenerateAccessTokenSuccessAction,
  publishLessonAction,
  publishLessonPayload,
  unpublishLessonAction,
  unpublishLessonPayload,
  duplicateLessonAction,
  duplicateLessonPayload,
  duplicateLessonSuccessAction,
  duplicateLessonFailureAction,
} from "../../../models/redux/lesson";
import { XHRPayload } from "../../../models/common";

// Create Lesson

export const createLessonEpic: Epic = (
  action$: ActionsObservable<createLessonAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_LESSON"),
    pluck("payload"),
    switchMap((payload: createLessonPayload) => createLesson(payload, state$)),
  );

export const createLesson = (
  payload: createLessonPayload,
  state$: StateObservable<void>,
) => {
  return apiCall(
    "/teachers/lessons",
    "POST",
    {
      class_id: payload.class_id,
      subject_id: payload.subject_id,
      topic: payload.topic,
      ...merge(
        {},
        payload.description ? { description: payload.description } : {},
      ),
      ...merge({}, payload.tasks ? { tasks: payload.tasks } : {}),
      ...merge({}, payload.homework ? { homework: payload.homework } : {}),
      ...merge({}, payload.goal ? { goal: payload.goal } : {}),
      ...merge(
        {},
        payload.success_criteria
          ? { success_criteria: payload.success_criteria }
          : {},
      ),
    },
    (data: XHRPayload) =>
      createLessonSuccess({
        lesson_id: data.response.data.id,
        class_id: payload.class_id,
        history: payload.history,
      }),
    createLessonFailure,
    getTokenFromState(state$),
  );
};

export const createLessonSuccessEpic: Epic = (
  action$: ActionsObservable<createLessonSuccessAction>,
) =>
  action$.pipe(
    ofType("CREATE_LESSON_SUCCESS"),
    map(() =>
      showToast({ text: "lessons.lesson-has-been-created", status: "success" }),
    ),
  );

// Update Lesson

export const updateLessonEpic: Epic = (
  action$: ActionsObservable<updateLessonAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_LESSON"),
    pluck("payload"),
    switchMap((payload: updateLessonPayload) => updateLesson(payload, state$)),
  );

export const updateLesson = (
  payload: updateLessonPayload,
  state$: StateObservable<void>,
) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}`,
    "PATCH",
    {
      topic: payload.topic,
      ...merge(
        {},
        payload.description ? { description: payload.description } : {},
      ),
      ...merge({}, payload.tasks ? { tasks: payload.tasks } : {}),
      ...merge({}, payload.homework ? { homework: payload.homework } : {}),
      ...merge({}, payload.goal ? { goal: payload.goal } : {}),
      ...merge(
        {},
        payload.success_criteria
          ? { success_criteria: payload.success_criteria }
          : {},
      ),
    },
    (data: XHRPayload) =>
      updateLessonSuccess({
        lesson_id: payload.lesson_id,
        class_id: payload.class_id,
        history: payload.history,
      }),
    updateLessonFailure,
    getTokenFromState(state$),
  );
};

export const updateLessonSuccessEpic: Epic = (
  action$: ActionsObservable<updateLessonSuccessAction>,
) =>
  action$.pipe(
    ofType("UPDATE_LESSON_SUCCESS"),
    map(() =>
      showToast({ text: "lessons.lesson-has-been-updated", status: "success" }),
    ),
  );

// Get Lesson

export const getLessonEpic: Epic = (
  action$: ActionsObservable<getLessonAction>,
) => action$.pipe(ofType("GET_LESSON"), pluck("payload"), switchMap(getLesson));

export const getLesson = (payload: getLessonPayload) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}${
      payload.access_token ? `?access_token=${payload.access_token}` : ""
    }`,
    "GET",
    {},
    (data: XHRPayload) => getLessonSuccess(data.response),
    getLessonFailure,
  );
};

// Delete Lesson

export const deleteLessonEpic: Epic = (
  action$: ActionsObservable<deleteLessonAction>,
) =>
  action$.pipe(
    ofType("DELETE_LESSON"),
    switchMap((data) => deleteLesson(data.payload, data.callback)),
  );

export const deleteLesson = (
  payload: deleteLessonPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}`,
    "DELETE",
    {},
    () => deleteLessonSuccess({ class_id: payload.class_id }, callback),
    deleteLessonFailure,
  );
};

export const deleteLessonSuccessEpic: Epic = (
  action$: ActionsObservable<deleteLessonSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_LESSON_SUCCESS"),
    mergeMap((data: any) =>
      concat(
        of(
          showToast({
            text: "lessons.lesson-has-been-deleted",
            status: "success",
          }),
        ),
        of(getLessons(data.payload)),
      ),
    ),
  );

// Generate Lesson's access token

export const lessonGenerateAccessTokenEpic: Epic = (
  action$: ActionsObservable<lessonGenerateAccessTokenAction>,
) =>
  action$.pipe(
    ofType("LESSON_GENERATE_ACCESS_TOKEN"),
    switchMap((data) => lessonGenerateAccessToken(data.payload, data.callback)),
  );

export const lessonGenerateAccessToken = (
  payload: lessonGenerateAccessTokenPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}/access-token`,
    "PATCH",
    {},
    (data: XHRPayload) =>
      lessonGenerateAccessTokenSuccess(data.response, callback),
    lessonGenerateAccessTokenFailure,
  );
};

export const lessonGenerateAccessTokenSuccessEpic: Epic = (
  action$: ActionsObservable<lessonGenerateAccessTokenSuccessAction>,
) =>
  action$.pipe(
    ofType("LESSON_GENERATE_ACCESS_TOKEN_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "lessons.public-link-to-lesson-has-been-generated",
            status: "success",
          }),
        ),
      ),
    ),
  );

// Publish Lesson

export const publishLessonEpic: Epic = (
  action$: ActionsObservable<publishLessonAction>,
) =>
  action$.pipe(
    ofType("PUBLISH_LESSON"),
    pluck("payload"),
    switchMap(publishLesson),
  );

export const publishLesson = (payload: publishLessonPayload) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}/publish`,
    "PATCH",
    {},
    (data: XHRPayload) => publishLessonSuccess(data.response),
    publishLessonFailure,
  );
};

// Unpublish Lesson

export const unpublishLessonEpic: Epic = (
  action$: ActionsObservable<unpublishLessonAction>,
) =>
  action$.pipe(
    ofType("UNPUBLISH_LESSON"),
    pluck("payload"),
    switchMap(unpublishLesson),
  );

export const unpublishLesson = (payload: unpublishLessonPayload) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}/unpublish`,
    "PATCH",
    {},
    (data: XHRPayload) => unpublishLessonSuccess(data.response),
    unpublishLessonFailure,
  );
};

// Duplicate Lesson

export const duplicateLessonEpic: Epic = (
  action$: ActionsObservable<duplicateLessonAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_LESSON"),
    switchMap((data) => duplicateLesson(data.payload, data.callback)),
  );

export const duplicateLesson = (
  payload: duplicateLessonPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/teachers/lessons/${payload.lesson_id}/duplicate`,
    "POST",
    {
      class_id: payload.class_id,
    },
    (data: XHRPayload) => duplicateLessonSuccess(data.response, callback),
    duplicateLessonFailure,
  );
};

export const duplicateLessonSuccessEpic: Epic = (
  action$: ActionsObservable<duplicateLessonSuccessAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_LESSON_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "lessons.lesson-has-been-duplicated",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const duplicateLessonFailureEpic: Epic = (
  action$: ActionsObservable<duplicateLessonFailureAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_LESSON_FAILURE"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "lessons.lesson-has-not-been-duplicated",
            status: "error",
          }),
        ),
      ),
    ),
  );
