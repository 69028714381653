export interface XHRPayload {
  xhr: XMLHttpRequest;
  response: any;
};

export interface ErrorObject {
  code: string;
  detail: string;
  source: {
    pointer: string;
  };
  status: number;
  title: string;
};

export interface ErrorsPayload {
  errors: ErrorObject[];
};

export interface PathInterface {
  default: string,
  [key: string]: string;
};

export interface Payment {
  focusedElement: "cardNumber" | "cardExpiry" | "cardCvc" | "",
};

export const ErrorCodes = {
  freePlanLimitExceeded: "free_plan_limit_exceeded",
};

export interface listMetaModel {
  first: number;
  last: number;
  next: number | null;
  prev: number | null;
};
