import {
  getAllTestsAction,
  getAllTestsSuccessAction,
  getAllTestsFailureAction,
  getAllTestsSuccessPayload,
} from "../../../models/redux/tests";
import { ErrorsPayload } from "../../../models/common";

// Get All Tests

export function getAllTests(): getAllTestsAction {
  return {
    type: "GET_ALL_TESTS",
  };
};

export function getAllTestsSuccess(payload: getAllTestsSuccessPayload): getAllTestsSuccessAction {
  return {
    type: "GET_ALL_TESTS_SUCCESS",
    payload,
  };
};

export function getAllTestsFailure(payload: ErrorsPayload): getAllTestsFailureAction {
  return {
    type: "GET_ALL_TESTS_FAILURE",
    payload,
  };
};
