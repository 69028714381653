import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import Input from "../Input";

import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";
import { languages, getLanguageName } from "../../../services/common/languages";

import { ReactComponent as ArrowDownGrey } from "../../../assets/images/small_arrow_down_grey.svg";

// Types
import { userStateModel } from "../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  setLanguageCode: (code: string) => any;
  setLanguage: (code: string) => any;
  languageCode: string;
  language: string;
  errors: any;
  user: userStateModel;
}

export const LanguagesSelect = (props: Props) => {
  const [languageList, setLanguageList] = useState(languages);
  const [showLanguageDropdown, toggleLanguageDropdown] = useState(false);

  return (
    <div className={styles.inputContainer}>
      <OutsideClickHandler
        onOutsideClick={(e: any) => {
          outsideClickHelper(e, "dropdown", () =>
            toggleLanguageDropdown(false),
          );
          if (!props.languageCode) {
            props.setLanguageCode("");
            props.setLanguage("");
          } else {
            const language = languages.find(
              (item: any) =>
                item.code.toLocaleLowerCase() ===
                props.languageCode.toLocaleLowerCase(),
            ) as any;
            if (language) {
              props.setLanguage(getLanguageName(language, props.user.language));
            }
          }
          setLanguageList(languages);
        }}
      >
        <div className={styles.wrapper}>
          <div onClick={() => toggleLanguageDropdown(true)}>
            <div>
              <Input
                id="language"
                type="text"
                name="language"
                value={props.language}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLanguageList(languages);
                  props.setLanguage(e.target.value);
                }}
                label={translate("common.language")}
                icon={() => <ArrowDownGrey/>}
                error={props.errors}
              />
            </div>
          </div>
          {showLanguageDropdown && (
            <div className={styles.dropdown}>
              <ul>
                {languageList.map((item: any) => (
                  <li key={item.code}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setLanguageCode(item.code);
                        props.setLanguage(
                          getLanguageName(item, props.user.language),
                        );
                        toggleLanguageDropdown(false);
                      }}
                    >
                      {getLanguageName(item, props.user.language)}
                    </button>
                  </li>
                ))}
                {!languageList.length && (
                  <li>
                    <span>{translate("global.no-results")}</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default LanguagesSelect;
