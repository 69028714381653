import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
// import { Link } from "react-router-dom";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import { ReactComponent as EmptyBoxIcon } from "../../../../../assets/images/payments/empty_box.svg";
import { ReactComponent as InfoCircleIcon } from "../../../../../assets/images/info_circle.svg";
import { ReactComponent as ArrowIcon } from "../../../../..//assets/images/small_arrow_down_grey.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/images/lock.svg";

import {
  getDiscountCodes,
  updateDiscountCodes,
  getBillingNextPayment,
} from "../../../../../redux/actions/billing";

import translate from "../../../../../services/translate";

// Types
import {
  discountCode,
  billingStateModel,
  getDiscountCodes as getDiscountCodesFunc,
  updateDiscountCodes as updateDiscountCodesFunc,
  getBillingNextPayment as getBillingNextPaymentFunc,
} from "../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  billing: billingStateModel;
  getDiscountCodes: getDiscountCodesFunc;
  updateDiscountCodes: updateDiscountCodesFunc;
  getBillingNextPayment: getBillingNextPaymentFunc;
}

export const DiscountCodes = (props: Props) => {
  const [discountCodes, setDiscountCodes] = useState<string[]>([]);

  useEffect(() => {
    props.getDiscountCodes();
    props.getBillingNextPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDiscountCodesEmpty = () => !props.billing.discount_codes.length;

  const discountCodeAlreadyApplied = () => {
    return (
      props.billing.next_payment.amount !==
      props.billing.next_payment.base_amount
    );
  };

  const boxClass = classNames({
    [styles.discountCodesBox]: true,
    [styles.discountCodesBoxEmpty]: isDiscountCodesEmpty(),
  });

  const arrowClass = (id: string) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: discountCodes.includes(id),
    });

  const toggleArrow = (id: string) => {
    const isChecked = discountCodes.includes(id);

    if (isChecked) {
      setDiscountCodes(discountCodes.filter((value: string) => value !== id));
      return;
    }

    setDiscountCodes(discountCodes.concat(id));
  };

  return (
    <div className={boxClass}>
      <h3>{translate("profile.available-discount-codes")}</h3>
      {!!props.billing.discount_codes.length && (
        <div className={styles.discountInfoDesc}>
          <span>
            {translate("profile.you-can-use-one-discount-per-transaction-desc")}
          </span>
        </div>
      )}
      <div className={styles.content}>
        {isDiscountCodesEmpty() ? (
          <div className={styles.placeholderContent}>
            <EmptyBoxIcon/>
            <div className={styles.placeholderText}>
              <p>{translate("profile.no-discount-codes-desc")}</p>
              <a
                href={`${process.env.REACT_APP_LANDING_PAGE_URL}/referral-program`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{translate("profile.see-how-to-get-discount")}</span>
              </a>
            </div>
          </div>
        ) : (
          <ul className={styles.discountCodes}>
            <li className={styles.headingItem}>
              <div className={styles.code}>
                <span>{translate("global.code")}</span>
              </div>
              <div className={styles.discountNumber}>
                <span>{translate("profile.discount-number")}</span>
              </div>
              <div className={styles.activation}>
                <span>{translate("profile.activation")}</span>
              </div>
              <div className={styles.lock}>
                <span></span>
              </div>
              <div className={styles.actions}></div>
            </li>
            {props.billing.discount_codes.map((item: discountCode) => (
              <li className={styles.listItem} key={item.id}>
                <div className={styles.code}>
                  <span>{item.code}</span>
                </div>
                <div className={styles.discountNumber}>
                  <span>-{item.percent_off}%</span>
                </div>

                <div className={styles.activation}>
                  <button
                    onClick={() =>
                      props.updateDiscountCodes({ discount_code_id: item.id })
                    }
                    disabled={item.is_used || discountCodeAlreadyApplied()}
                  >
                    <span>
                      {!item.is_used && translate("profile.activate")}
                      {item.is_used && translate("profile.used")}
                    </span>
                  </button>
                </div>

                <div className={styles.lock}>
                  {!item.is_used && discountCodeAlreadyApplied() && (
                    <i className={styles.lockIcon}>
                      <LockIcon/>
                    </i>
                  )}
                </div>

                <div className={styles.actions}>
                  <div className={styles.tooltipContainer}>
                    <ReactTooltip
                      id={`tooltip_${item.id}`}
                      place="left"
                      effect="solid"
                      className={styles.tooltip}
                    >
                      <p>{item.name}</p>
                    </ReactTooltip>
                    <i data-tip data-for={`tooltip_${item.id}`}>
                      <InfoCircleIcon/>
                    </i>
                  </div>
                  <button
                    className={arrowClass(item.id)}
                    onClick={() => toggleArrow(item.id)}
                  >
                    <ArrowIcon/>
                  </button>
                </div>
                {discountCodes.includes(item.id) && (
                  <section className={styles.dropDownContainer}>
                    <span>{translate("profile.discount-number")}</span>
                    <span>-{item.percent_off}%</span>
                    <div className={styles.tooltipContainer}>
                      <ReactTooltip
                        id={`tooltip_${item.id}`}
                        place="left"
                        effect="solid"
                        className={styles.tooltip}
                      >
                        <p>{item.name}</p>
                      </ReactTooltip>
                      <i data-tip data-for={`tooltip_${item.id}`}>
                        <InfoCircleIcon/>
                      </i>
                    </div>
                  </section>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: { billing: billingStateModel }) => ({
  billing: state.billing,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDiscountCodes,
      getBillingNextPayment,
      updateDiscountCodes,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodes);
