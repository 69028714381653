import React from "react";

import translate from "../../../../../../services/translate";

import styles from "../../../../../../styles/Test/Instruction/styles.module.scss";

export const PreviewTestInstruction = () => {
  return (
    <div className={styles.instruction} id="testInfoInstruction">
      <h2>{translate("creator.test-instruction-heading")}</h2>
      <p>
        <span>{translate("creator.test-instruction-desc-1")}&nbsp;</span>
        <span>{translate("creator.test-instruction-desc-2")}</span>
        <i></i>
        <span>{translate("creator.test-instruction-desc-3")}</span>
        <span className={styles.circle}>
          <i></i>
        </span>
        <span>{translate("creator.test-instruction-desc-4")}</span>
      </p>
    </div>
  );
};

export default PreviewTestInstruction;
