import { ofType, ActionsObservable, Epic } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  getAllTestsSuccess,
  getAllTestsFailure,
} from "../../actions/tests";

import { apiCall } from "../../../services/api";

// Types
import {
  getAllTestsAction,
} from "../../../models/redux/tests";
import { XHRPayload } from "../../../models/common";

// Get All Tests

export const getAllTestsEpic: Epic = (action$: ActionsObservable<getAllTestsAction>) => (
  action$.pipe(
    ofType("GET_ALL_TESTS"),
    switchMap(getAllTests),
  )
);

export const getAllTests = () => (
  apiCall(
    "/tests/tests",
    "GET",
    {},
    (data: XHRPayload) => getAllTestsSuccess(data.response),
    getAllTestsFailure,
  )
);
