import React, { useState, useRef } from "react";
import classNames from "classnames";
import { CardCvcElement } from "react-stripe-elements";

import translate from "../../../../../../../../services/translate";

// Types
import { Payment } from "../../../../../../../../models/common";

import styles from "../styles.module.scss";

interface Props {
  focusedElement: Payment["focusedElement"];
  setFocusedElement: (value: Payment["focusedElement"]) => any;
}

export const CardCvc = (props: Props) => {
  const [cardCvcEmpty, setCardCvcEmpty] = useState<boolean>(true);
  const CardCvcEl = useRef<any>(null);

  const isActive = (value: string) =>
    props.focusedElement === value || !cardCvcEmpty;

  const labelClass = (value: string) =>
    classNames({
      [styles.active]: isActive(value),
    });

  return (
    <div className={styles.cardCvcInputContainer}>
      <div
        className={styles.labelContainer}
        onClick={() => CardCvcEl.current.getElement().focus()}
      >
        <label className={labelClass("cardCvc")}>
          <span>{translate("profile.card-cvc")}</span>
        </label>
      </div>
      <CardCvcElement
        onChange={(e) => setCardCvcEmpty(e.empty)}
        onBlur={(e) => props.setFocusedElement("")}
        onFocus={(e) => props.setFocusedElement(e.elementType as any)}
        ref={CardCvcEl}
        className="stripeInput"
        placeholder={isActive("cardCvc") ? "CVC" : ""}
        style={{ base: { color: "#5B5BB4" } }}
      />
    </div>
  );
};

export default CardCvc;
