import React from "react";

// Types
import {
  questionModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  question: questionModel;
}

export const QuestionImageOnline = (props: Props) => {
  return (
    <>
      {props.question.image &&
        <img
          id={`question_img_${props.question.id}`}
          data-type="question-image"
          className={styles.questionImg}
          src={props.question.image}
          alt={`img_${props.question.id}`}
        />
      }
    </>
  );
};

export default QuestionImageOnline;
