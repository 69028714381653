import pathOr from "ramda/src/pathOr";

// Types
import {
  resourcesStateModel,
  resourcesActionModel,
} from "../../../models/redux/resources";

const initialState: resourcesStateModel = {
  list: [],
};

export const resources = (state = initialState, action: resourcesActionModel) => {
  switch (action.type) {
    case "GET_RESOURCES_SUCCESS":
      return {
        ...state,
        list: pathOr([], ["payload", "data"], action),
      };
    case "UNMOUNT_RESOURCES":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
