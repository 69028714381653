import React from "react";

import TestOnlineNav from "../Nav";

import translate from "../../../../../services/translate";

import { ReactComponent as TestOnlineSuccessIcon } from "../../../../../assets/images/test_online_success_icon.svg";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
}

export const TestOnlineSuccess = (props: Props) => {
  return (
    <div className={styles.container}>
      <TestOnlineNav
        history={props.history}
        location={props.location}
        verified={false}
      />
      <div className={styles.innerContainer}>
        <div className={styles.box}>
          <div className={styles.left}>
            <h1>{translate("global.congratulations")}!</h1>
            <p>{translate("test.test-online-success-description-1")}</p>
            <p>{translate("test.test-online-success-description-2")}</p>
          </div>
          <TestOnlineSuccessIcon/>
        </div>
      </div>
    </div>
  );
};

export default TestOnlineSuccess;
