import propOr from "ramda/src/propOr";

// Types
import {
  getSubjectsAction,
  getSubjectsSuccessAction,
  getSubjectsFailureAction,
  getSubjectsPayload,
  updateSubjectsAction,
  updateSubjectsSuccessAction,
  createClassPayload,
  createClassAction,
  createClassSuccessAction,
  createClassFailureAction,
  unmountTeacherAction,
  createClassSuccessPayload,
  getClassesAction,
  getClassesSuccessAction,
  getClassesFailureAction,
  classesModel,
} from "../../../models/redux/teacher";
import { ErrorsPayload } from "../../../models/common";
import { History } from "history";

// Subjects

export const getSubjects = (): getSubjectsAction => {
  return {
    type: "GET_SUBJECTS",
  };
};

export const getSubjectsSuccess = (
  payload: getSubjectsPayload,
): getSubjectsSuccessAction => {
  return {
    type: "GET_SUBJECTS_SUCCESS",
    payload: {
      data: payload.data,
    },
  };
};

export const getSubjectsFailure = (): getSubjectsFailureAction => {
  return {
    type: "GET_SUBJECTS_FAILURE",
  };
};

export const updateSubjects = (subjects: string[]): updateSubjectsAction => {
  return {
    type: "UPDATE_SUBJECTS",
    payload: subjects,
  };
};

export const updateSubjectsSuccess = (): updateSubjectsSuccessAction => {
  return {
    type: "UPDATE_SUBJECTS_SUCCESS",
  };
};

// Class

export const getClasses = (): getClassesAction => {
  return {
    type: "GET_CLASSES",
  };
};

export const getClassesSuccess = (
  payload: classesModel,
): getClassesSuccessAction => {
  return {
    type: "GET_CLASSES_SUCCESS",
    payload,
  };
};

export const getClassesFailure = (): getClassesFailureAction => {
  return {
    type: "GET_CLASSES_FAILURE",
  };
};

export const createClass = (
  payload: createClassPayload,
  callback?: (data: any) => any,
): createClassAction => {
  return {
    type: "CREATE_CLASS",
    payload,
    callback,
  };
};

export const createClassSuccess = (
  payload: createClassSuccessPayload,
  history: History,
  callback?: (data?: any) => any,
): createClassSuccessAction => {
  if (callback) {
    callback();
  } else {
    // history.push(`/class/${payload.data.id}/tests?open_class_dropdown=${payload.data.id}`);
    history.push(`/pick-up-subjects/${payload.data.id}`);
  }

  return {
    type: "CREATE_CLASS_SUCCESS",
  };
};

export const createClassFailure = (
  payload: ErrorsPayload,
): createClassFailureAction => {
  return {
    type: "CREATE_CLASS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Other

export const unmountTeacher = (): unmountTeacherAction => {
  return {
    type: "UNMOUNT_TEACHER",
  };
};
