import pathOr from "ramda/src/pathOr";
import uniq from "ramda/src/uniq";

// Types
import {
  lessonStateModel,
  lessonActionModel,
  lessonStatus,
} from "../../../models/redux/lesson";

const initialState: lessonStateModel = {
  topic: "",
  description: "",
  goal: "",
  tasks: "",
  homework: "",
  success_criteria: "",
  access_token: "",
  status: "",
  subject: "",
  subject_name: "",
  class_name: "",
  in_progress: {
    create_lesson: false,
    update_lesson: false,
  },
  errors: [],
};

export const lesson = (state = initialState, action: lessonActionModel) => {
  switch (action.type) {
    case "CREATE_LESSON":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          create_lesson: true,
        },
        errors: [],
      };
    case "CREATE_LESSON_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          create_lesson: false,
        },
        errors: [],
      };
    case "CREATE_LESSON_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          create_lesson: false,
        },
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
      };
    case "UPDATE_LESSON":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: true,
        },
        errors: [],
      };
    case "UPDATE_LESSON_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
        errors: [],
      };
    case "UPDATE_LESSON_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
        errors: uniq(state.errors.concat(pathOr([], ["payload", "errors"], action))),
      };
    case "GET_LESSON_SUCCESS":
      return {
        ...state,
        topic: pathOr("", ["payload", "data", "topic"], action),
        description: pathOr("", ["payload", "data", "description"], action),
        goal: pathOr("", ["payload", "data", "goal"], action),
        tasks: pathOr("", ["payload", "data", "tasks"], action),
        homework: pathOr("", ["payload", "data", "homework"], action),
        success_criteria: pathOr("", ["payload", "data", "success_criteria"], action),
        access_token: pathOr("", ["payload", "data", "access_token"], action),
        status: pathOr("", ["payload", "data", "status"], action),
        subject: pathOr("", ["payload", "data", "subject"], action),
        subject_name: pathOr("", ["payload", "data", "subject_name"], action),
        class_name: pathOr("", ["payload", "data", "class_name"], action),
      };
    case "LESSON_GENERATE_ACCESS_TOKEN":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: true,
        },
      };
    case "LESSON_GENERATE_ACCESS_TOKEN_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "LESSON_GENERATE_ACCESS_TOKEN_SUCCESS":
      return {
        ...state,
        access_token: pathOr("", ["payload", "data", "access_token"], action),
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "PUBLISH_LESSON":
    case "UNPUBLISH_LESSON":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: true,
        },
      };
    case "PUBLISH_LESSON_SUCCESS":
      return {
        ...state,
        status: lessonStatus.public,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "UNPUBLISH_LESSON_SUCCESS":
      return {
        ...state,
        status: lessonStatus.private,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "PUBLISH_LESSON_FAILURE":
    case "UNPUBLISH_LESSON_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "DUPLICATE_LESSON_SUCCESS":
    case "DUPLICATE_LESSON_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: false,
        },
      };
    case "DUPLICATE_LESSON":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_lesson: true,
        },
      };
    case "UNMOUNT_LESSON":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
