import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../../Common/Input";

import Modal, { ToggleContent } from "../../../components/Common/Modal";
import { AddClassCurriculum } from "./Curriculum";

import { ReactComponent as TrashAltIcon } from "../../../assets/images/trash_alt.svg";
import { ReactComponent as TrashIcon } from "../../../assets/images/trash.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/images/arrow_left.svg";
import { ReactComponent as File } from "../../../assets/images/file.svg";
import { ReactComponent as Students } from "../../../assets/images/students.svg";
import { ReactComponent as PenIcon } from "../../../assets/images/pen_edit.svg";

import { ReactComponent as WishListIcon } from "../../../assets/images/curriculum/wishlist.svg";

import { getClasses } from "../../../redux/actions/teacher";
import {
  deleteClass,
  updateClass,
  resetClassErrors,
  getClass,
} from "../../../redux/actions/class";
import { getCurriculumsForClass } from "../../../redux/actions/curriculum";

import translate from ".././../../services/translate";
import { getErrorMessage, userFromPL } from "../../../services/common";

// Types
import { History } from "history";
import {
  classModel,
  getClasses as getClassesFunction,
  teacherStateModel,
} from "../../../models/redux/teacher";
import {
  classStateModel,
  getClass as getClassFunction,
  deleteClass as deleteClassFunction,
  updateClass as updateClassFunction,
} from "../../../models/redux/class";
import { userStateModel } from "../../../models/redux/user";
import {
  curriculumStateModel,
  getCurriculumsForClass as getCurriculumsForClassFunction,
  curriculum,
} from "../../../models/redux/curriculum";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  class: classStateModel;
  classes: classModel[];
  match: any;
  getClasses: getClassesFunction;
  deleteClass: deleteClassFunction;
  getClass: getClassFunction;
  updateClass: updateClassFunction;
  resetClassErrors: () => any;
  user: userStateModel;
  curriculum: curriculumStateModel;
  getCurriculumsForClass: getCurriculumsForClassFunction;
  isLoading: boolean;
}

export const Class = (props: Props) => {
  const [classNameRepeat, setClassNameRepeat] = useState("");
  const [className, setClassName] = useState("");
  const [editClassInputFocused, setEditClassInputFocused] = useState(false);
  const [selectedCurriculums, setSelectedCurriculums] = useState<string[]>([]);

  const classNameInputElement = useRef(null) as any;

  useEffect(() => {
    props.getClasses();
    props.getCurriculumsForClass({ class_id: props.match.params.id });
    props.getClass({ id: `${props.match.params.id}` });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!classNameEqualsLocalName()) {
      setClassName(props.class.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.class.name]);

  const focusClassNameInputElement = () => {
    if (classNameInputElement) {
      classNameInputElement.current.focus();
      setEditClassInputFocused(true);
    }
  };

  const updateClass = () => {
    props.updateClass({
      id: props.class.id,
      class_name: className,
    });
  };

  const classNameEqualsLocalName = () => props.class.name === className;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          {props.class.in_progress.update_class ? (
            <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
          ) : (
            <>
              {editClassInputFocused ? (
                <PenIcon/>
              ) : (
                <Link to="/classes">
                  <ArrowLeft/>
                </Link>
              )}
            </>
          )}
          <input
            id="class_name_heading"
            onFocus={() => setEditClassInputFocused(true)}
            onBlur={() => {
              setEditClassInputFocused(false);
              if (!classNameEqualsLocalName()) {
                updateClass();
              }
            }}
            onChange={(e) => setClassName(e.target.value)}
            value={className}
            ref={classNameInputElement}
            style={{
              width: `${className.length ? className.length : 1}ch`,
            }}
          />
          {getErrorMessage(props.class.errors.update_class, "") && (
            <span className={styles.error}>
              {getErrorMessage(props.class.errors.update_class, "")}
            </span>
          )}
        </h3>
        <div className={styles.buttonContainer}>
          <button type="button" onClick={focusClassNameInputElement}>
            <span>{translate("global.edit")}</span>
          </button>
          <ToggleContent
            toggle={(show: any) => (
              <button type="button" onClick={show}>
                <TrashIcon/>
                &nbsp;
                <span>
                  {translate("global.delete")}{" "}
                  {translate("class.class-accusative")}
                </span>
              </button>
            )}
            content={(hide: () => {}) => (
              <Modal
                hide={() => {
                  hide();
                  props.resetClassErrors();
                }}
                customStyles={styles.modal}
              >
                <div className={styles.deleteClassModal}>
                  <TrashAltIcon/>
                  <span className={styles.description}>
                    {translate("class.delete-class-confirmation")}
                  </span>
                  <Input
                    id="class_name_repeat"
                    type="text"
                    name="class_name_repeat"
                    value={classNameRepeat}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setClassNameRepeat(e.target.value)
                    }
                    label={translate("class.classname")}
                    error={
                      getErrorMessage(
                        props.class.errors.delete_class,
                        props.match.params.id,
                      ) ||
                      getErrorMessage(
                        props.class.errors.delete_class,
                        "class_name",
                      )
                    }
                  />
                  <div className={styles.buttonContainer}>
                    <button type="button" onClick={hide}>
                      {translate("global.cancel")}
                    </button>
                    <button
                      type="button"
                      disabled={
                        !classNameRepeat.length ||
                        props.class.in_progress.delete_class
                      }
                      onClick={() => {
                        props.deleteClass(
                          {
                            id: props.match.params.id,
                            class_name: classNameRepeat,
                          },
                          () => {
                            hide();
                            setTimeout(() => props.history.push("/classes"), 0);
                          },
                        );
                      }}
                    >
                      {props.class.in_progress.delete_class ? (
                        <FontAwesomeIcon
                          className="fa-spin"
                          icon="circle-notch"
                        />
                      ) : (
                        <span>{translate("global.delete")}</span>
                      )}
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          />
        </div>
      </div>
      <ul className={styles.classes}>
        <li>
          <Link to={`/class/${props.match.params.id}/tests`}>
            <File/>
            <span>{translate("global.tests")}</span>
          </Link>
        </li>
        <li>
          <Link to={`/class/${props.match.params.id}/students`}>
            <Students/>
            <span>{translate("global.students-list")}</span>
          </Link>
        </li>
        <li>
          <Link to={`/class/${props.match.params.id}/lessons`}>
            <Students/>
            <span>{translate("global.lessons")}</span>
          </Link>
        </li>
      </ul>
      {userFromPL(props.user.country) && (
        <ul className={styles.curriculum}>
          <h3>
            <WishListIcon/>
            &nbsp;{translate("curriculum.curriculum")}
          </h3>
          {!props.curriculum.class_curriculums.length && (
            <AddClassCurriculum
              history={props.history}
              setSelectedCurriculums={setSelectedCurriculums}
              selectedCurriculums={selectedCurriculums}
              subject={props.class.subject}
              classId={props.class.id}
            />
          )}
          {props.curriculum.class_curriculums.map((item: curriculum) => (
            <li key={item.subject} className={styles.subject}>
              {item.id && (
                <span className={styles.topRightText}>
                  {translate("class.curriculum-is-assigned")}
                </span>
              )}
              <Link
                to={
                  item.id
                    ? `/class/curriculum/${item.id}?classID=${props.match.params.id}&subject=${item.subject}`
                    : `/class/curriculum/add?classID=${props.match.params.id}&subject=${item.subject}`
                }
              >
                {item.subject_name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  classReducer: classStateModel;
  user: userStateModel;
  curriculum: curriculumStateModel;
}) => ({
  isLoading: state.teacher.isLoading,
  classes: state.teacher.classes,
  class: state.classReducer,
  user: state.user,
  curriculum: state.curriculum,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
      getClass,
      deleteClass,
      updateClass,
      resetClassErrors,
      getCurriculumsForClass,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Class);
