import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import InvoiceDetails from "../../../InvoiceDetails";

import equals from "ramda/src/equals";

import { getInvoiceData } from "../../../../../../../../redux/actions/billing";

import Modal, { ToggleContent } from "../../../../../../../Common/Modal";

import { isPolish } from "../../../../../../../../services/common";
import translate from "../../../../../../../../services/translate";
import { countries } from "../../../../../../../../services/common/countries";

import { ReactComponent as PenEditIcon } from "../../../../../../../../assets/images/pen_edit.svg";

// Types
import {
  billingStateModel,
  getInvoiceData as getInvoiceDataFunc,
  invoiceData as invoiceDataModel,
} from "../../../../../../../../models/redux/billing";
import { userStateModel } from "../../../../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  billing: billingStateModel;
  getInvoiceData: getInvoiceDataFunc;
  user: userStateModel;
}

export const CreditCardDisplayInvoice = (props: Props) => {
  const [invoiceData, setInvoiceData] = useState<invoiceDataModel>({
    address: {
      building_number: "",
      city: "",
      country: "",
      flat_number: "",
      postal_code: "",
      street_name: "",
    },
    company_name: "",
    tin: "",
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    props.getInvoiceData();
    setInvoiceData(props.billing.invoice_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equals(props.billing.invoice_data, invoiceData)) {
      return;
    }

    setInvoiceData(props.billing.invoice_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.billing]);

  const getCountryName = () => {
    const country = countries.find(
      (item: any) =>
        item.code.toLocaleLowerCase() ===
        props.billing.invoice_data.address.country.toLocaleLowerCase(),
    ) as any;

    if (country) {
      return country[`name_${isPolish(props.user.language) ? "pl" : "en"}`];
    }
    return "";
  };

  const showAddress = () =>
    invoiceData.address.street_name &&
    (invoiceData.address.building_number || invoiceData.address.flat_number);

  return (
    <div className={styles.invoice}>
      <span>
        {translate("profile.invoice-details-1")}{" "}
        {translate("profile.invoice-details-2")}:
      </span>
      <ul>
        <li>
          {invoiceData.first_name} {invoiceData.last_name}
        </li>
        {invoiceData.company_name && <li>{invoiceData.company_name}</li>}
        {showAddress() && (
          <li>
            {invoiceData.address.street_name &&
              `${invoiceData.address.street_name} `}
            {invoiceData.address.building_number}
            {invoiceData.address.flat_number &&
              `/${invoiceData.address.flat_number}`}
          </li>
        )}
        <li>
          {invoiceData.address.postal_code &&
            `${invoiceData.address.postal_code} `}
          {invoiceData.address.city && `${invoiceData.address.city}, `}
          {getCountryName()}
        </li>
        {invoiceData.tin && (
          <li>
            {translate("profile.tin-number")}: {invoiceData.tin}
          </li>
        )}
      </ul>
      <ToggleContent
        toggle={(show: any) => (
          <button type="button" onClick={show}>
            <PenEditIcon/>
            &nbsp;
            <span>{translate("profile.fill-out-invoice-details")}</span>
          </button>
        )}
        content={(hide: () => {}) => (
          <Modal hide={hide} customStyles={styles.modal}>
            <InvoiceDetails hide={hide}/>
          </Modal>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: {
  billing: billingStateModel;
  user: userStateModel;
}) => ({
  billing: state.billing,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getInvoiceData,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditCardDisplayInvoice);
