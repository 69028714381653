import React, { useState, useEffect } from "react";

import QuestionHeaderOnline from "../Header";
import QuestionImageOnline from "../Image";

// Types
import {
  questionModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  question: questionModel;
  onFillInTheGapsChange: (gaps: any) => void;
  isReadOnly?: boolean;
}

export const QuestionFillInTheGapsOnline = (props: Props) => {
  const [localGaps, setLocalGaps] = useState<string[]>([]);
  const [shufflededOptions, setShufflededOptions] = useState([] as any[]);

  useEffect(() => {
    if (props.question.gaps && props.question.gaps.length > 0) {
      setShufflededOptions(shuffle(props.question.gaps));
    }
  }, [props.question.gaps]);

  const disableInputs = () => {
    if (props.question.body_html && props.question.body_html.includes("<input")) {
      if (props.isReadOnly) {
        const splittedHTML = props.question.body_html.split("<input");

        const updatedHTML = splittedHTML
          .map((txt, index) => {
            if (index + 1 <= splittedHTML.length - 1) {
              return txt + "<input disabled";
            }
            return txt;
          })
          .join("");

        return updatedHTML;
      } else {
        const splittedHTML = props.question.body_html
          .replace(/<input value=(.*?)>/g, "[[gap]]")
          .split("[[gap]]");

        const updatedHTML = splittedHTML
          .map((txt, index) => {
            if (index + 1 <= splittedHTML.length - 1) {
              return txt + "<input value=\"\">";
            }
            return txt;
          })
          .join("");

        return updatedHTML;
      }
    }

    return props.question.body_html;
  };

  const shuffle = (a: any[]) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  return (
    <div className={styles.mainContainer}>
      {props.question.type_data.show_gap_options &&
        <div className={styles.fillInTheGapsGapsOptionsContent}>
          <ul className={styles.fillInTheGapsGapsOptions}>
            {shufflededOptions.map(item => (
              <li
                key={item.id}
                style={{
                  textDecoration: localGaps.includes(item.names[0]) ? "line-through" : "none",
                }}
              >
                {item.names[0]}
              </li>
            ))}
          </ul>
        </div>
      }
      <QuestionHeaderOnline
        question={{ ...props.question, body_html: disableInputs() }}
        onBlurHandler={(e) => {
          const gaps = Array.from(e.currentTarget.getElementsByTagName("input")).map((c: any) => c.value) as string[];

          setLocalGaps(gaps);

          props.onFillInTheGapsChange(gaps);
        }}
      />
      <QuestionImageOnline question={props.question}/>
    </div>
  );
};

export default QuestionFillInTheGapsOnline;
