import React from "react";

import Checkbox from "../Checkbox";
import translate from "../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  isCompact: boolean;
  setIsCompact: any;
  reloadPreview: any;
  setHideInstruction: any;
  hideInstruction: any;
}

export const PrintOptionsRow = (props: Props) => {
  return (
    <div className={styles.printOptions}>
      <span>{translate("test.preview-settings")}</span>
      <div className={styles.options}>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={props.isCompact}
            onClick={() => {
              props.setIsCompact(!props.isCompact);
              setTimeout(() => props.reloadPreview(), 500);
            }}
            noIcon
          />
          <button
            type="button"
            onClick={() => {
              props.setIsCompact(!props.isCompact);
              setTimeout(() => props.reloadPreview(), 500);
            }}
          >
            <span>{translate("test.compact-version")}</span>
          </button>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={!props.hideInstruction}
            onClick={() => {
              props.setHideInstruction(!props.hideInstruction);
              setTimeout(() => props.reloadPreview(), 500);
            }}
            noIcon
          />
          <button
            type="button"
            onClick={() => {
              props.setHideInstruction(!props.hideInstruction);
              setTimeout(() => props.reloadPreview(), 500);
            }}
          >
            <span>{translate("test.instruction")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintOptionsRow;
