import React from "react";

import translate from "../../../../../../../services/translate";
import { notesTypes } from "../../../../../../../services/common/test";

import { ReactComponent as CheckedSmallIcon } from "../../../../../../../assets/images/question_notes/small/checked.svg";
import { ReactComponent as LinesSmallIcon } from "../../../../../../../assets/images/question_notes/small/lines.svg";
import { ReactComponent as BlankSmallIcon } from "../../../../../../../assets/images/question_notes/small/blank.svg";
import { ReactComponent as DottedSmallIcon } from "../../../../../../../assets/images/question_notes/small/dotted.svg";
import { ReactComponent as PenmanshipSmallIcon } from "../../../../../../../assets/images/question_notes/small/penmanship.svg";

import { ReactComponent as CheckedNote } from "../../../../../../../assets/images/question_notes/checked.svg";
import { ReactComponent as LinesNote } from "../../../../../../../assets/images/question_notes/lines.svg";
import { ReactComponent as BlankNote } from "../../../../../../../assets/images/question_notes/blank.svg";
import { ReactComponent as DottedNote } from "../../../../../../../assets/images/question_notes/dotted.svg";
import { ReactComponent as PenmanshipNote } from "../../../../../../../assets/images/question_notes/penmanship.svg";

import { ReactComponent as TrashAltIcon } from "../../../../../../../assets/images/trash_alt.svg";

// Types
import { updateQuestion as updateQuestionFunction } from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  questionID: string;
  testID: string;
  classID: string;
  type: string;
  notesSize: number;
  notesType: string;
  updateQuestion: updateQuestionFunction;
}

export const QuestionNotes = (props: Props) => {
  const noteSizeArray = Array.from(Array(props.notesSize).keys());

  const updateQuestion = (noteSize: number, noteType: string | null) => {
    props.updateQuestion({
      classID: props.classID,
      testID: props.testID,
      questionID: props.questionID,
      type_data: {
        notes_size: noteSize,
        notes_type: noteType,
      },
    });
  };

  return (
    <div className={styles.questionNotes}>
      <span>{translate("creator.add-notes-to-question-description")}:</span>
      {!props.notesSize && (
        <div className={styles.notesTypesButtons}>
          <button
            type="button"
            onClick={() => updateQuestion(1, notesTypes.checked)}
          >
            <CheckedSmallIcon/>
          </button>
          <button
            type="button"
            onClick={() => updateQuestion(1, notesTypes.lines)}
          >
            <LinesSmallIcon/>
          </button>
          <button
            type="button"
            onClick={() => updateQuestion(1, notesTypes.penmanship)}
          >
            <PenmanshipSmallIcon/>
          </button>
          <button
            type="button"
            onClick={() => updateQuestion(1, notesTypes.dotted)}
          >
            <DottedSmallIcon/>
          </button>
          <button
            type="button"
            onClick={() => updateQuestion(1, notesTypes.blank)}
          >
            <BlankSmallIcon/>
          </button>
        </div>
      )}
      <div className={styles.noteType}>
        {props.notesType === notesTypes.checked &&
          !!props.notesSize &&
          noteSizeArray.map((item: number) => <CheckedNote key={item}/>)}
        {props.notesType === notesTypes.lines &&
          !!props.notesSize &&
          noteSizeArray.map((item: number) => <LinesNote key={item}/>)}
        {props.notesType === notesTypes.penmanship &&
          !!props.notesSize &&
          noteSizeArray.map((item: number) => <PenmanshipNote key={item}/>)}
        {props.notesType === notesTypes.dotted &&
          !!props.notesSize &&
          noteSizeArray.map((item: number) => <DottedNote key={item}/>)}
        {props.notesType === notesTypes.blank && !!props.notesSize && (
          <div className={styles.blankNoteType}>
            {noteSizeArray.map((item: number) => (
              <BlankNote key={item}/>
            ))}
          </div>
        )}
        {!!props.notesSize && (
          <div className={styles.noteSettings}>
            <button
              type="button"
              onClick={() =>
                updateQuestion(props.notesSize + 1, props.notesType)
              }
            >
              +
            </button>
            <button
              type="button"
              onClick={() =>
                updateQuestion(props.notesSize - 1, props.notesType)
              }
            >
              -
            </button>
            <button type="button" onClick={() => updateQuestion(0, null)}>
              <TrashAltIcon/>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionNotes;
