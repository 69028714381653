import React, { useState } from "react";
import mergeRight from "ramda/src/mergeRight";
import keys from "ramda/src/keys";
import OutsideClickHandler from "react-outside-click-handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Checkbox from "../../../../../Common/Checkbox";

import { ReactComponent as ArrowDownGrey } from "../../../../../../assets/images/small_arrow_down_grey.svg";
import {
  outsideClickHelper,
  userFromPL,
} from "../../../../../../services/common";

import translate from "../../../../../../services/translate";
import Input from "../../../../../Common/Input";

// Types
import { appStateModel } from "../../../../../../models/redux/app";
import { questionTypeModel } from "../../../../../../models/redux/test";
import { userStateModel } from "../../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  app: appStateModel;
  getSearchResults: any;
  term: string;
  setTerm: (value: string) => any;
  sortOptions: any;
  searchOptions: any;
  setSearchOptions: any;
  defaultSortOption: string;
  questionTypes: questionTypeModel[];
  selectedQuestionTypes: string[];
  setSelectedQuestionTypes: (value: string[]) => any;
  isSearchLoading: boolean;
  withSkills: boolean;
  setWithSkills: (value: boolean) => any;
  user: userStateModel;
}

export const Search = (props: Props) => {
  const getSortOptionName = (sortOption: string): string => {
    return props.sortOptions[sortOption];
  };

  const [showSortOptionsDropdown, toggleSortOptionsDropdown] = useState(false);
  const sortOptionsKeys = keys(props.sortOptions);
  const [selectedSortOptionName, setSortOptionName] = useState(
    getSortOptionName(props.defaultSortOption),
  );

  const changeSortOption = (sortOption: string) => {
    setSortOptionName(getSortOptionName(sortOption));
    const updatedSearchOptions = mergeRight(props.searchOptions, {
      sort: sortOption,
    });
    props.setSearchOptions(updatedSearchOptions);
    return;
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>{translate("creator.questions-base")}</h1>
      </div>
      <div className={styles.searchBarContainer}>
        <Input
          id="search-bar"
          name="search-bar"
          type="text"
          value={props.term}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              props.getSearchResults(props.term, props.searchOptions);
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setTerm(e.target.value);
          }}
        />
        <div className={styles.searchButtonContainer}>
          <button
            type="button"
            className={styles.searchButton}
            disabled={props.isSearchLoading}
            onClick={() =>
              props.getSearchResults(props.term, props.searchOptions)
            }
          >
            {props.isSearchLoading ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <span>{translate("global.search")}</span>
            )}
          </button>
        </div>
      </div>
      <div className={styles.searchOptionsContainer}>
        <div className={styles.questionsTypeContainer}>
          <div className={styles.label}>
            <span>{translate("test.question-type")}:</span>
          </div>
          <ul>
            {props.questionTypes.map((item) => (
              <li key={item.type}>
                <Checkbox
                  checked={props.selectedQuestionTypes.includes(item.type)}
                  disabled={props.isSearchLoading}
                  onClick={() => {
                    if (props.selectedQuestionTypes.includes(item.type)) {
                      props.setSelectedQuestionTypes(
                        props.selectedQuestionTypes.filter(
                          (questionType) => questionType !== item.type,
                        ),
                      );
                      return;
                    }
                    props.setSelectedQuestionTypes(
                      props.selectedQuestionTypes.concat(item.type),
                    );
                  }}
                  noIcon
                >
                  <span>{item.name}</span>
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
        {userFromPL(props.user.country) && (
          <div className={styles.curriculumContainer}>
            <div className={styles.label}>
              <span>{translate("curriculum.curriculum")}:</span>
            </div>
            <ul>
              <li>
                <Checkbox
                  checked={props.withSkills}
                  disabled={props.isSearchLoading}
                  onClick={() => props.setWithSkills(!props.withSkills)}
                  noIcon
                >
                  <span>{translate("creator.assigned")}</span>
                </Checkbox>
              </li>
            </ul>
          </div>
        )}
        <div className={styles.sortContainer}>
          <div className={styles.label}>
            <span>{translate("global.sort")}:</span>
          </div>
          <OutsideClickHandler
            onOutsideClick={(e: any) => {
              outsideClickHelper(e, "dropdown", () =>
                toggleSortOptionsDropdown(false),
              );
            }}
          >
            <div className={styles.wrapper}>
              <div onClick={() => toggleSortOptionsDropdown(true)}>
                <Input
                  id="subject"
                  type="text"
                  name="subject"
                  value={selectedSortOptionName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeSortOption(e.target.value);
                  }}
                  icon={() => <ArrowDownGrey/>}
                  label={<>{translate("global.pick-up")}</>}
                  labelHiddenOnActive
                />
              </div>
              {showSortOptionsDropdown && (
                <div className={styles.dropdown}>
                  <ul>
                    {sortOptionsKeys.map((sortOption: any, index: number) => (
                      <li key={index}>
                        <button
                          type="button"
                          onClick={() => {
                            changeSortOption(sortOption);
                            toggleSortOptionsDropdown(false);
                          }}
                        >
                          <span>{getSortOptionName(sortOption)}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      <hr className={styles.divider}/>
    </div>
  );
};

export default Search;
