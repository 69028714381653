import React from "react";

import { ReactComponent as FileIcon } from "../../../../../../assets/images/reports/file.svg";

import translate from "../../../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  pointsAveragePercentage: any;
}

export const TestReportClassAverageScore = (props: Props) => {
  return (
    <div className={styles.container}>
      <FileIcon/>
      <div className={styles.textContainer}>
        <h4>{translate("global.average-score")}</h4>
        <h5>
          {props.pointsAveragePercentage
            ? `${props.pointsAveragePercentage}%`
            : "--"}
        </h5>
      </div>
    </div>
  );
};

export default TestReportClassAverageScore;
