import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import classNames from "classnames";
import merge from "ramda/src/merge";

import {
  createTest,
  createQuestion,
} from "../../../../../../redux/actions/test";

import translate from "../../../../../../services/translate";

import { ReactComponent as MultipleChoiceQuestionIcon } from "../../../../../../assets/images/question_types/multiple_choice.svg";
import { ReactComponent as OpenQuestionIcon } from "../../../../../../assets/images/question_types/open.svg";
import { ReactComponent as TrueFalseQuestionIcon } from "../../../../../../assets/images/question_types/true_false.svg";
import { ReactComponent as MatchingQuestionIcon } from "../../../../../../assets/images/question_types/matching.svg";
import { ReactComponent as FillInTheGapsQuestionIcon } from "../../../../../../assets/images/question_types/fill_in_the_gaps.svg";
import { ReactComponent as TimesIcon } from "../../../../../../assets/images/times.svg";

// Types
import { History } from "history";
import {
  QuestionTypes,
  testStateModel,
  createTest as createTestFunction,
  createQuestion as createQuestionFunction,
  questionTypeModel,
} from "../../../../../../models/redux/test";
import { subjectModel } from "../../../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  subjects: subjectModel[];
  test: testStateModel;
  createTest: createTestFunction;
  createQuestion: createQuestionFunction;
  testID: string;
  classID: string;
  selectedSubjectType: string;
  selectedClassName: string;
  selectedSubjectName: string;
  selectedTestType: string;
  addNextQuestion: any;
  questionLength: number;
  selectedTime?: string;
}

export const TestQuestionTypes = (props: Props) => {
  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    if (questionType && props.test.id) {
      props.addNextQuestion(false);
      props.createQuestion({
        classID: props.classID,
        testID: props.test.id,
        lang: "",
        body_html: "",
        body: "",
        points: 1,
        ...(questionType === QuestionTypes.MATCHING ||
        questionType === QuestionTypes.FILL_IN_THE_GAPS
          ? { points_per_item: 1 }
          : {}),
        type: questionType,
      });
      setQuestionType("");
    }
  }, [props, questionType]);

  const getQuestionTypeIcon = (questionType: string) => {
    if (questionType === QuestionTypes.MULTIPLE_CHOICE) {
      return <MultipleChoiceQuestionIcon/>;
    }

    if (questionType === QuestionTypes.OPEN) {
      return <OpenQuestionIcon/>;
    }

    if (questionType === QuestionTypes.TRUE_FALSE) {
      return <TrueFalseQuestionIcon/>;
    }

    if (questionType === QuestionTypes.MATCHING) {
      return <MatchingQuestionIcon/>;
    }

    if (questionType === QuestionTypes.FILL_IN_THE_GAPS) {
      return <FillInTheGapsQuestionIcon/>;
    }
  };

  const onQuestionTypeClick = (item: string) => {
    const selectedSubject = props.subjects.find(
      (item: any) => item.type === props.selectedSubjectType,
    );

    if (!props.test.id && !props.testID && selectedSubject) {
      props.createTest({
        class_id: props.classID,
        lang: "",
        name: `${props.selectedSubjectName} - ${props.selectedClassName}`,
        subject_id: selectedSubject.id,
        history: props.history,
        type: props.selectedTestType,
        ...merge(
          {},
          props.selectedTestType === "online" && props.selectedTime
            ? {
              online_data: {
                time_limit: props.selectedTime,
              },
            }
            : {},
        ),
      });
    }
    setQuestionType(item);
  };

  const questionTypeClass = (itemType: string) =>
    classNames({
      [styles[itemType]]: true,
      [styles.disabled]: props.test.in_progress.create_question,
    });

  return (
    <section className={styles.questionTypes}>
      {!!props.questionLength && (
        <button
          className={styles.closeButton}
          onClick={() => props.addNextQuestion(false)}
        >
          <TimesIcon/>
        </button>
      )}
      <span>{translate("creator.select-question-type")}:</span>
      <ul>
        {props.test.question_types.map((item: questionTypeModel) => (
          <li key={item.type} className={questionTypeClass(item.type)}>
            <button
              type="button"
              onClick={() => onQuestionTypeClick(item.type)}
            >
              {getQuestionTypeIcon(item.type)}
              <span>{item.name}</span>
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
};

const mapStateToProps = (state: { test: testStateModel }) => ({
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createTest,
      createQuestion,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestQuestionTypes);
