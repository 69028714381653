import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as HatIcon } from "../../../../assets/images/dashboard/my_classes/hat.svg";

import translate from "../../../../services/translate";

import styles from "./styles.module.scss";

export const MyClassesBox = () => {
  return (
    <div className={styles.box}>
      <HatIcon/>
      <h3>{translate("global.my-classes")}</h3>
      <Link to="/classes">{translate("global.show")}</Link>
    </div>
  );
};

export default MyClassesBox;
