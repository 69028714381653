import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import propOr from "ramda/src/propOr";

import { ReactComponent as Check } from "../../../../../assets/images/check_icon.svg";
import translate from "../../../../../services/translate";

// Types
import { History, Location } from "history";
import { teacherStateModel } from "../../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  isLoading: boolean;
  location: Location;
  history: History;
}

// INFO: This Component isn't used anywhere, but it might be useful later.
//
// Do not delete this component and translations it uses.
//

export const AddClassSuccess = (props: Props) => {
  const [classID, setClassID] = useState("");

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    setClassID(propOr("", "id", parsed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <h1>
          <Check/>
          <span>{translate("global.you-did-it")}</span>
        </h1>
        <p>{translate("class.add-class-success-desc-first")}</p>
        <p>{translate("class.add-class-success-desc-second")}</p>
        <span>{translate("class.remember-that")}!</span>
        <p>{translate("class.add-class-success-desc-third")}</p>
      </div>
      <div className={styles.bottomContainer}>
        <Link to="/">
          <span>{translate("global.back-to-homepage")}</span>
        </Link>
        <Link to={`/class/${classID}/tests`}>
          <span>{translate("class.go-to-class")}</span>
        </Link>
      </div>
    </section>
  );
};

const mapStateToProps = (state: { teacher: teacherStateModel }) => ({
  isLoading: state.teacher.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClassSuccess);
