import { of, concat } from "rxjs";
import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, map, mergeMap } from "rxjs/operators";
import {
  getClassSuccess,
  getClassFailure,
  getStudentsSuccess,
  getStudentsFailure,
  deleteStudentSuccess,
  deleteStudentFailure,
  addStudentSuccess,
  addStudentFailure,
  getStudentDetailsSuccess,
  getStudentDetailsFailure,
  deleteClassSuccess,
  deleteClassFailure,
  updateClassSuccess,
  updateClassFailure,
} from "../../actions/class";
import { showToast } from "../../actions/app";
import { getClasses } from "../teacher";

import { apiCall } from "../../../services/api";

// Types
import {
  getClassAction,
  getClassPayload,
  getStudentsAction,
  getStudentsPayload,
  deleteStudentAction,
  deleteStudentPayload,
  deleteStudentSuccessAction,
  addStudentPayload,
  addStudentAction,
  addStudentSuccessAction,
  addStudentFailureAction,
  deleteStudentFailureAction,
  getStudentDetailsPayload,
  getStudentDetailsAction,
  deleteClassAction,
  deleteClassPayload,
  deleteClassSuccessAction,
  updateClassAction,
  updateClassPayload,
  updateClassSuccessAction,
  updateClassSuccessPayload,
} from "../../../models/redux/class";
import { XHRPayload } from "../../../models/common";

// Get Class

export const getClassEpic: Epic = (
  action$: ActionsObservable<getClassAction>,
) => action$.pipe(ofType("GET_CLASS"), pluck("payload"), switchMap(getClass));

export const getClass = (payload: getClassPayload) => {
  return apiCall(
    `/teachers/classes/${payload.id}`,
    "GET",
    {},
    (data: XHRPayload) => getClassSuccess(data.response),
    getClassFailure,
  );
};

// Delete Class

export const deleteClassEpic: Epic = (
  action$: ActionsObservable<deleteClassAction>,
) =>
  action$.pipe(
    ofType("DELETE_CLASS"),
    switchMap((data) => deleteClass(data.payload, data.callback)),
  );

export const deleteClass = (
  payload: deleteClassPayload,
  callback: () => any,
) => {
  return apiCall(
    `/teachers/classes/${payload.id}`,
    "DELETE",
    {
      class_name: payload.class_name,
    },
    (data: XHRPayload) => deleteClassSuccess(callback),
    deleteClassFailure,
  );
};

export const deleteClassSuccessEpic: Epic = (
  action$: ActionsObservable<deleteClassSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_CLASS_SUCCESS"),
    mergeMap((payload: any) =>
      concat(
        of(
          showToast({ text: "class.class-has-been-deleted", status: "success" }),
        ),
      ),
    ),
  );

// Update Class

export const updateClassEpic: Epic = (
  action$: ActionsObservable<updateClassAction>,
) =>
  action$.pipe(
    ofType("UPDATE_CLASS"),
    pluck("payload"),
    switchMap(updateClass),
  );

export const updateClass = (payload: updateClassPayload) => {
  return apiCall(
    `/teachers/classes/${payload.id}`,
    "PATCH",
    {
      name: payload.class_name,
      ...(payload.subject ? { subject_id: payload.subject } : {}),
    },
    () => updateClassSuccess({ id: payload.id }),
    updateClassFailure,
  );
};

export const updateClassSuccessEpic: Epic = (
  action$: ActionsObservable<updateClassSuccessAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_CLASS_SUCCESS"),
    pluck("payload"),
    mergeMap((payload: updateClassSuccessPayload) =>
      concat(
        getClass(payload),
        getClasses(state$),
        of(showToast({ text: "global.updated", status: "success" })),
      ),
    ),
  );

// Get Student

export const getStudentsEpic: Epic = (
  action$: ActionsObservable<getStudentsAction>,
) =>
  action$.pipe(
    ofType("GET_STUDENTS"),
    pluck("payload"),
    switchMap(getStudents),
  );

export const getStudents = (payload: getStudentsPayload) => {
  return apiCall(
    `/teachers/classes/${payload.classID}/students`,
    "GET",
    {},
    (data: XHRPayload) => getStudentsSuccess(data.response),
    getStudentsFailure,
  );
};

// Delete Student

export const deleteStudentEpic: Epic = (
  action$: ActionsObservable<deleteStudentAction>,
) =>
  action$.pipe(
    ofType("DELETE_STUDENT"),
    pluck("payload"),
    switchMap(deleteStudent),
  );

export const deleteStudent = (payload: deleteStudentPayload) => {
  return apiCall(
    `/teachers/classes/${payload.classID}/students/${payload.studentID}`,
    "DELETE",
    {},
    () => deleteStudentSuccess({ classID: payload.classID }),
    deleteStudentFailure,
  );
};

export const deleteStudentSuccessEpic: Epic = (
  action$: ActionsObservable<deleteStudentSuccessAction>,
) =>
  action$.pipe(
    ofType("DELETE_STUDENT_SUCCESS"),
    pluck("payload"),
    mergeMap((payload: any) =>
      concat(
        getStudents(payload),
        of(
          showToast({
            text: "class.student-has-been-deleted",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const deleteStudentFailureEpic: Epic = (
  action$: ActionsObservable<deleteStudentFailureAction>,
) =>
  action$.pipe(
    ofType("DELETE_STUDENT_FAILURE"),
    pluck("payload"),
    map(() =>
      showToast({
        text: "students.student-has-not-been-added",
        status: "error",
      }),
    ),
  );

// Add Student

export const addStudentEpic: Epic = (
  action$: ActionsObservable<addStudentAction>,
) =>
  action$.pipe(ofType("ADD_STUDENT"), pluck("payload"), switchMap(addStudent));

export const addStudent = (payload: addStudentPayload) => {
  return apiCall(
    `/teachers/classes/${payload.classID}/students`,
    "POST",
    {
      number: payload.number,
    },
    () => addStudentSuccess({ classID: payload.classID }),
    addStudentFailure,
  );
};

export const addStudentSuccessEpic: Epic = (
  action$: ActionsObservable<addStudentSuccessAction>,
) =>
  action$.pipe(
    ofType("ADD_STUDENT_SUCCESS"),
    pluck("payload"),
    mergeMap((payload: any) =>
      concat(
        getStudents(payload),
        of(
          showToast({
            text: "students.student-has-been-added",
            status: "success",
          }),
        ),
      ),
    ),
  );

export const addStudentFailureEpic: Epic = (
  action$: ActionsObservable<addStudentFailureAction>,
) =>
  action$.pipe(
    ofType("ADD_STUDENT_FAILURE"),
    pluck("payload"),
    map(() =>
      showToast({
        text: "students.student-has-not-been-added",
        status: "error",
      }),
    ),
  );

// Get Student Details

export const getStudentDetailsEpic: Epic = (
  action$: ActionsObservable<getStudentDetailsAction>,
) =>
  action$.pipe(
    ofType("GET_STUDENT_DETAILS"),
    pluck("payload"),
    switchMap(getStudentDetails),
  );

export const getStudentDetails = (payload: getStudentDetailsPayload) => {
  return apiCall(
    `/reports/students/${payload.studentID}`,
    "GET",
    {},
    (data: XHRPayload) => getStudentDetailsSuccess(data.response),
    getStudentDetailsFailure,
  );
};
