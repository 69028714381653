import React from "react";
import { ReactComponent as SzkolaPodstawowa_1_3 } from "../../../assets/images/curriculum/education_level/pl/szkola_podstawowa_1_3.svg";
import { ReactComponent as SzkolaPodstawowa_4_8 } from "../../../assets/images/curriculum/education_level/pl/szkola_podstawowa_4_8.svg";
import { ReactComponent as BranzowaSzkola1Stopnia } from "../../../assets/images/curriculum/education_level/pl/branzowa_szkola_1_stopnia.svg";
import { ReactComponent as LiceumTechnikum } from "../../../assets/images/curriculum/education_level/pl/liceum_technikum.svg";
import { ReactComponent as Inny } from "../../../assets/images/curriculum/education_level/pl/inny.svg";

export const getEducationLevelIcon = (level: string) => {
  const components = {
    "Szkoła podstawowa I-III": SzkolaPodstawowa_1_3,
    "Szkoła podstawowa IV-VIII": SzkolaPodstawowa_4_8,
    "Branżowa szkoła I stopnia": BranzowaSzkola1Stopnia,
    "Liceum/Technikum": LiceumTechnikum,
    "Inny": Inny,
  } as any;

  if (level) {
    const IconName = components[level];
    if (IconName) {
      return React.createElement(IconName, {});
    }
  }
};
