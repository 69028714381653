import React, { useState } from "react";
import { injectStripe } from "react-stripe-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CardNumber from "./CardNumber";
import CardExpiry from "./CardExpiry";
import CardCvc from "./CardCvc";

import translate from "../../../../../../../services/translate";
import { getErrorMessage } from "../../../../../../../services/common";

// Types
import { Payment, ErrorObject } from "../../../../../../../models/common";
import { addBillingCard as addBillingCardFunc } from "../../../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  stripe?: any;
  isLoading: boolean;
  addBillingCard: addBillingCardFunc;
  errors: ErrorObject[];
}

export const CreditCardForm = (props: Props) => {
  const [focusedElement, setFocusedElement] =
    useState<Payment["focusedElement"]>("");
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const paymentRequest = (e: any) => {
    e.preventDefault();
    setIsButtonLoading(true);

    props.stripe.createPaymentMethod("card").then(({ paymentMethod }: any) => {
      setIsButtonLoading(false);
      if (paymentMethod && paymentMethod.id) {
        props.addBillingCard({ payment_method_id: paymentMethod.id });
      }
    });
  };

  const isLoading = () => isButtonLoading || props.isLoading;

  return (
    <form className={styles.creditCardContainer} onSubmit={paymentRequest}>
      <p>{translate("profile.credit-card-info-desc")}:</p>
      <CardNumber
        focusedElement={focusedElement}
        setFocusedElement={setFocusedElement}
      />
      <CardExpiry
        focusedElement={focusedElement}
        setFocusedElement={setFocusedElement}
      />
      <CardCvc
        focusedElement={focusedElement}
        setFocusedElement={setFocusedElement}
      />
      {getErrorMessage(props.errors, "") && (
        <span className={styles.error}>
          {getErrorMessage(props.errors, "")}
        </span>
      )}
      <button type="submit" disabled={isLoading()}>
        {isLoading() ? (
          <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
        ) : (
          <span>{translate("profile.save-credit-card")}</span>
        )}
      </button>
    </form>
  );
};

export default injectStripe(CreditCardForm);
