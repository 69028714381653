import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Route, Switch } from "react-router-dom";

import DemoNav from "./Nav";
import DemoPickUpSubject from "./PickUpSubject";
import DemoAddClass from "./AddClass";
import DemoTestCreator from "./TestCreator";
import DemoPreviewTest from "./TestCreator/Preview";

import { signUpAsGuest } from "../../redux/actions/demo";

import { removeTokenFromLocalStorage } from "../../services/api";

// Types
import { Location, History } from "history";
import { signUpAsGuest as signUpAsGuestFunction } from "../../models/redux/demo";
import { userStateModel } from "../../models/redux/user";

import styles from "./styles.module.scss";


interface Props {
  location: Location;
  history: History;
  user: userStateModel;
  signUpAsGuest: signUpAsGuestFunction;
}

export const Demo = (props: Props) => {
  useEffect(() => {
    removeTokenFromLocalStorage();

    if (!props.user.roles.includes("guest")) {
      props.signUpAsGuest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.user.roles.includes("guest")) {
      props.history.replace("/demo/pick-up-subject");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.roles]);

  return (
    <section className={styles.container}>
      <DemoNav
        history={props.history}
        location={props.location}
      />
      <div className={styles.view}>
        <div className={styles.innerView}>
          <Switch>
            <Route
              path="/demo/pick-up-subject"
              component={DemoPickUpSubject}
            />
            <Route
              path="/demo/class/add"
              component={DemoAddClass}
            />
            <Route
              exact
              path="/demo/test/creator"
              component={DemoTestCreator}
            />
            <Route
              exact
              path="/demo/test/creator/preview"
              component={DemoPreviewTest}
            />
          </Switch>
        </div>
      </div>
      <div id="modal-root"></div>
      <div id="modal-formula"></div>
      <div id="modal-video"></div>
    </section>
  );
};

const mapStateToProps = (state: { user: userStateModel }) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    signUpAsGuest,
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Demo);
