import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OutsideClickHandler from "react-outside-click-handler";
import pathOr from "ramda/src/pathOr";
import path from "ramda/src/path";
import isNil from "ramda/src/isNil";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../../Common/Input";
import TestsListItemGlobal from "../Class/Tests/List/Item/Global";

import { getSubjectIcon } from "../../../services/common/getSubjectIcon";
import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";

import {
  testsBaseGetTests,
  testsBaseGetTestsNextPage,
} from "../../../redux/actions/testsBase";
import { getSubjects } from "../../../redux/actions/teacher";

import { ReactComponent as ArrowDownGrey } from "../../../assets/images/small_arrow_down_grey.svg";
import { ReactComponent as AllSubjectsIcon } from "../../../assets/images/all_subjects_icon.svg";

// Types
import { History } from "history";
import {
  testsBaseStateModel,
  testsBaseTestModel,
  testsBaseGetTests as testsBaseGetTestsFunction,
  testsBaseGetTestsNextPage as testsBaseGetTestsNextPageFunction,
} from "../../../models/redux/testsBase";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  teacher: teacherStateModel;
  testsBase: testsBaseStateModel;
  testsBaseGetTests: testsBaseGetTestsFunction;
  testsBaseGetTestsNextPage: testsBaseGetTestsNextPageFunction;
  getSubjects: getSubjectsFunction;
}

export const TestsBase = (props: Props) => {
  const [showSubjectsDropdown, toggleSubjectsDropdown] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [selectedSubjectID, setSelectedSubjectID] = useState("");
  const [itemsPerPage] = useState(15);
  const [subjectsList, setSubjectsList] = useState<any>([]);

  const allTestsListElement = useRef(null) as any;

  useEffect(() => {
    props.getSubjects();
    props.testsBaseGetTests({
      page: 1,
      page_size: itemsPerPage,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubjectsList(props.teacher.subjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teacher.subjects]);

  const loadMoreTests = () => {
    if (!isNil(path(["testsBase", "listMeta", "next"], props))) {
      props.testsBaseGetTestsNextPage({
        subject_id: selectedSubjectID,
        page: props.testsBase.listMeta.next as number,
        page_size: itemsPerPage,
      });
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h3>{translate("global.tests-base")}</h3>
        <section className={styles.filters}>
          <div className={styles.inputContainer}>
            <OutsideClickHandler
              onOutsideClick={(e: any) => {
                outsideClickHelper(e, "dropdown", () =>
                  toggleSubjectsDropdown(false),
                );
              }}
            >
              <div className={styles.wrapper}>
                <div onClick={() => toggleSubjectsDropdown(true)}>
                  <Input
                    id="subject"
                    type="text"
                    name="subject"
                    value={subjectName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const subjectItem = props.teacher.subjects.filter(
                        (item: any) =>
                          item.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase()),
                      ) as any[];
                      setSubjectsList(subjectItem);
                      setSubjectName(e.target.value);
                    }}
                    onBlur={() => {
                      if (subjectID) {
                        const subject = props.teacher.subjects.find(
                          (item: any) => item.id === subjectID,
                        );
                        if (subject) {
                          setSubjectName(subject.name);
                          return;
                        }
                      } else {
                        setSubjectName("");
                      }
                      setSubjectsList(props.teacher.subjects);
                    }}
                    icon={() => <ArrowDownGrey/>}
                    label={
                      <>
                        {translate("global.pick-up")}{" "}
                        {translate("global.subject")}
                      </>
                    }
                    labelHiddenOnActive
                  />
                </div>
                {showSubjectsDropdown && (
                  <div className={styles.dropdown}>
                    <ul>
                      <li>
                        <button
                          type="button"
                          onClick={() => {
                            setSubjectName("");
                            setSubjectID("");
                            toggleSubjectsDropdown(false);
                          }}
                        >
                          <AllSubjectsIcon/>
                          <span>{translate("sign_up.all-subjects")}</span>
                        </button>
                      </li>
                      {subjectsList.map((item: any) => (
                        <li key={item.id}>
                          <button
                            type="button"
                            onClick={() => {
                              setSubjectName(item.name);
                              setSubjectID(item.id);
                              toggleSubjectsDropdown(false);
                            }}
                          >
                            {getSubjectIcon(item.type)}
                            <span>{item.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          <button
            type="button"
            disabled={props.testsBase.in_progress.search}
            className={styles.searchButton}
            onClick={() => {
              props.testsBaseGetTests({
                subject_id: subjectID,
                page: 1,
                page_size: itemsPerPage,
              });
              setSelectedSubjectID(subjectID);
            }}
          >
            {props.testsBase.in_progress.search ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <>{translate("global.search")}</>
            )}
          </button>
        </section>
      </div>
      <section className={styles.tests}>
        <ul className={styles.allTestsList} ref={allTestsListElement}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreTests}
            hasMore={
              !!pathOr([], ["testsBase", "list"], props).length &&
              pathOr(false, ["testsBase", "listMeta", "next"], props)
            }
            loader={
              <div key={0}>
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              </div>
            }
            threshold={50}
            useWindow={false}
            getScrollParent={() => document.querySelector("[class*=view]")}
          >
            {pathOr([], ["testsBase", "list"], props).map(
              (item: testsBaseTestModel) => (
                <TestsListItemGlobal
                  key={`test_base_${item.id}`}
                  history={props.history}
                  item={item}
                />
              ),
            )}
          </InfiniteScroll>
        </ul>
      </section>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  testsBase: testsBaseStateModel;
}) => ({
  teacher: state.teacher,
  testsBase: state.testsBase,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      testsBaseGetTests,
      testsBaseGetTestsNextPage,
      getSubjects,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestsBase);
