import { ajax } from "rxjs/ajax";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import pathOr from "ramda/src/pathOr";
import Cookies from "universal-cookie";

export const setTokenInCookies = (token: string) => {
  const cookies = new Cookies();
  cookies.set(
    "wisdyToken",
    token,
    {
      maxAge: 30 * 24 * 60 * 60,
      domain: `${process.env.REACT_APP_WISDY_DOMAIN}`,
    },
  );
};

export const removeTokenFromCookies = () => {
  const cookies = new Cookies();
  cookies.remove(
    "wisdyToken",
    {
      maxAge: 30 * 24 * 60 * 60,
      domain: `${process.env.REACT_APP_WISDY_DOMAIN}`,
    },
  );
};

export const removeTokenFromLocalStorage = () => {
  if (localStorage) {
    localStorage.removeItem("wisdyToken");
  }
};

export const saveTokenInLocalStorage = (token: string) => {
  if (localStorage) {
    localStorage.setItem("wisdyToken", token);
  }
};

export const saveLanguageInLocalStorage = (language: string) => {
  if (localStorage) {
    localStorage.setItem("wisdyLanguage", language);
  }
};

export const getTokenFromLocalStorage = () => {
  if (localStorage) {
    const token = localStorage.getItem("wisdyToken");
    return token ? token : "";
  }
  return "";
};

export const getLanguageFromLocalStorage = () => {
  if (localStorage) {
    const language = localStorage.getItem("wisdyLanguage");
    return language ? language : "";
  }
  return "";
};

export const getTokenFromState = (state$: any) => {
  return pathOr("", ["value", "auth", "token"], state$);
};

export const defaultHeaders = (tokenFromState: string, isFormData?: boolean) => {
  const token = tokenFromState || getTokenFromLocalStorage();
  const language = getLanguageFromLocalStorage();
  const headers = {
    "Authorization": "",
    "Authorization-Token": `${process.env.REACT_APP_WISDY_AUTH_TOKEN}`,
  } as any;

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  if (token) {
    headers["Authorization"] = token;
  }

  if (language) {
    headers["Accept-Language"] = language;
  }

  return headers;
};

export const apiCall = (
  path: string,
  method: any,
  body: any,
  onSuccess: any,
  onFailure: any,
  tokenFromState = "",
  headers = defaultHeaders(tokenFromState, false),
  customURL = "",
) => {
  const url = `${customURL ? customURL : process.env.REACT_APP_API_URL}${path}`;
  return ajax({
    url,
    method,
    headers,
    body,
  }).pipe(
    map(onSuccess),
    catchError(error => {
      return of(onFailure(error.response));
    }),
  );
};

export const getTokenFromHeaders = (xhr: XMLHttpRequest) => {
  if (xhr && xhr.getResponseHeader) {
    const header = xhr.getResponseHeader("authorization");
    if (header) {
      return header;
    }
  }
  return "";
};
