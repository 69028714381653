import React from "react";
import { Elements } from "react-stripe-elements";

import Subscription from "./Subscription";
import PaymentInfo from "./PaymentInfo";
import DiscountCodes from "./DiscountCodes";

import translate from "../../../../services/translate";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
}

export const ProfileBilling = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{translate("global.premium-account")}</h3>
      </div>
      <div className={styles.boxContainer}>
        <Elements>
          <Subscription location={props.location}/>
        </Elements>
        <div className={styles.leftBox}>
          <PaymentInfo/>
          <DiscountCodes/>
        </div>
      </div>
    </div>
  );
};

export default ProfileBilling;
