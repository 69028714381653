import React, { useEffect, useState } from "react";

import classNames from "classnames";

import map from "ramda/src/map";
import when from "ramda/src/when";
import propEq from "ramda/src/propEq";
import propOr from "ramda/src/propOr";
import merge from "ramda/src/merge";

import { ReactComponent as TrashIcon } from "../../../../../../../../../assets/images/trash.svg";

import QuestionAnswerEditor from "../Editor";
import { getErrorMessage } from "../../../../../../../../../services/common";

// Types
import {
  answerChoiceModel,
} from "../../../../../../../../../models/redux/test";
import { ErrorObject } from "../../../../../../../../../models/common";

import styles from "../../styles.module.scss";


interface Props {
  answerChoices: answerChoiceModel[];
  questionID: string;
  createQuestionAnswerChoice: () => any,
  updateQuestion: (name_html: string, name: string, id: string, isCorrect: boolean) => any;
  deleteQuestionAnswerChoice: (answerID: string) => any;
  testErrors: ErrorObject[];
}

export const Single = (props: Props) => {
  const [answerChoices, setAnswerChoices] = useState([] as answerChoiceModel[]);
  const [isChecked, setIsChecked] = useState("");

  const getValue = (item: answerChoiceModel) => `single-answer_${props.questionID}_${item.id}`;

  useEffect(() => {
    const isCheckedItem = props.answerChoices.find(item => item.is_correct);

    setAnswerChoices(props.answerChoices);

    if (isCheckedItem) {
      setIsChecked(getValue(isCheckedItem));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const answerChoicesLength = props.answerChoices.length;
    const localAnswerChoicesLength = answerChoices.length;
    if (localAnswerChoicesLength && answerChoicesLength > localAnswerChoicesLength) {
      setAnswerChoices(answerChoices.concat(props.answerChoices[answerChoicesLength - 1]));
      return;
    }

    if (localAnswerChoicesLength !== answerChoicesLength) {
      setAnswerChoices(props.answerChoices);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answerChoices]);

  const onAnswerNameChange = (value: any, id: string) => {
    const answer = answerChoices.find(item => item.id === id);

    if (answer) {
      setAnswerChoices(
        map(
          when(
            propEq("id", id),
            (item: answerChoiceModel) => merge(item, { name_html: value.html, name: value.plain }),
          ),
          answerChoices,
        ),
      );
    }
  };

  const setCorrectAnswer = (item: answerChoiceModel) => {
    props.updateQuestion(
      item.name_html,
      item.name,
      item.id,
      true,
    );

    setIsChecked(getValue(item));
  };

  const getError = (id: string) => getErrorMessage(props.testErrors, `${id}/name`);

  const radioHasErrorClass = (id: string) => classNames({
    [styles.error]: getErrorMessage(props.testErrors, `questions/answer_choices/${id}`),
  });

  return (
    <>
      {answerChoices.map((item: answerChoiceModel) => (
        <div key={`${item.id}_${item.order}`} className={styles.listItemContainer}>
          <li>
            <div>
              <input
                type="radio"
                name={`${props.questionID}_question_answer_radio`}
                checked={isChecked === getValue(item)}
                value={getValue(item)}
                onChange={() => setCorrectAnswer(item)}
                className={radioHasErrorClass(item.id)}
              />
              <QuestionAnswerEditor
                questionAnswer={propOr("", "name_html", item)}
                setQuestionAnswer={(value: string) => onAnswerNameChange(value, item.id)}
                updateQuestion={(name_html?: string, name?: string) => {
                  const answer = answerChoices.find((answer) => answer.id === item.id) as answerChoiceModel;
                  props.updateQuestion(
                    name_html ? name_html : answer.name_html,
                    name ? name : answer.name,
                    item.id,
                    isChecked === getValue(item),
                  );
                }}
                hasError={!!getError(item.id)}
              />
            </div>
            <button type="button" onClick={(e) => {
              setAnswerChoices(
                answerChoices
                  .filter(answer => answer.id !== item.id)
                  .map((item, index) => ({ ...item, order: index+1 })),
              );
              props.deleteQuestionAnswerChoice(item.id);
            }}>
              <TrashIcon/>
            </button>
          </li>
          {getError(item.id) &&
            <span className={styles.error}>{getError(item.id)}</span>
          }
        </div>
      ))}
    </>
  );
};

export default Single;
