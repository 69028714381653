import { ofType, ActionsObservable, Epic, StateObservable } from "redux-observable";
import { switchMap, pluck } from "rxjs/operators";
import {
  signUpAsGuestSuccess,
  signUpAsGuestFailure,
  upgradeGuestAccountSuccess,
  upgradeGuestAccountFailure,
} from "../../actions/demo";

import { apiCall, getTokenFromState } from "../../../services/api";

// Types
import {
  signUpAsGuestAction, upgradeGuestAccountAction, upgradeGuestAccountPayload,
} from "../../../models/redux/demo";
import { XHRPayload } from "../../../models/common";

// Sign Up As Guest

export const signUpAsGuestEpic: Epic = (action$: ActionsObservable<signUpAsGuestAction>) => (
  action$.pipe(
    ofType("SIGN_UP_AS_GUEST"),
    switchMap(() => signUpAsGuest()),
  )
);

export const signUpAsGuest = () => (
  apiCall(
    "/sign-up/as-guest",
    "POST",
    {},
    (data: XHRPayload) => signUpAsGuestSuccess(data),
    signUpAsGuestFailure,
  )
);

// Upgrade Guest Account

export const upgradeGuestAccountEpic: Epic = (
  action$: ActionsObservable<upgradeGuestAccountAction>,
  state$: StateObservable<void>,
) => (
  action$.pipe(
    ofType("UPGRADE_GUEST_ACCOUNT"),
    pluck("payload"),
    switchMap((payload: upgradeGuestAccountPayload) => upgradeGuestAccount(payload, state$)),
  )
);

export const upgradeGuestAccount = (payload: upgradeGuestAccountPayload, state$: StateObservable<void>) => (
  apiCall(
    "/sign-up/upgrade-guest-account",
    "PATCH",
    {
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    },
    (data: XHRPayload) => upgradeGuestAccountSuccess(data, payload.history),
    upgradeGuestAccountFailure,
    getTokenFromState(state$),
  )
);
