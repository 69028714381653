export const pl = {
  global: {
    "back-to-homepage": "wróć do strony głównej",
    "email-address": "adres e-mail",
    password: "hasło",
    "you-did-it": "udało się!",
    "email-has-been-sent":
      "Link został pomyślnie wysłany. Sprawdź swoją skrzynkę e-mail,",
    "forgot-password": "zapomniałeś hasła?",
    send: "wyślij",
    "pick-up": "wybierz",
    subject: "przedmiot",
    subjects: "przedmioty",
    next: "dalej",
    "sign-out": "wyloguj",
    create: "stwórz",
    back: "wstecz",
    discover: "odkrywaj",
    "teacher-panel": "panel nauczyciela",
    "dashboard-desc": "wszystko czego potrzebujesz, w jednym miejscu...",
    edit: "edytuj",
    profile: "profil",
    classes: "zajęcia",
    // students: "uczniowie",
    tests: "testy",
    new: "nowy",
    test: "test",
    accept: "potwierdzam",
    "this-place-is-empty": "to miejsce jeszcze jest puste",
    testname: "nazwa testu",
    yes: "tak",
    no: "nie",
    "all-tests": "wszystkie testy",
    delete: "usuń",
    duplicate: "duplikuj",
    "see-more": "zobacz więcej",
    "no-results": "brak wyników",
    about: "o nas",
    "privacy-policy": "polityka prywatności",
    // "terms-and-conditions": "regulamin",
    help: "pomoc",
    folders: "foldery",
    other: "inne",
    "students-list": "lista uczniów",
    "date-added": "data dodania",
    details: "szczegóły",
    warning: "uwaga",
    change: "zmień",
    "extend-validity": "przedłuż",
    "days-till-subscription-over-two":
      "tyle dni pozostało do końca okresu ważności Twojego konta Pro",
    "average-score": "średnia wyników",
    save: "zapisz",
    code: "kod",
    finish: "zakończ",
    "my-subjects": "moje przedmioty",
    cancel: "anuluj",
    "get-a-discount": "uzyskaj rabat",
    "referral-desc": "za każdego znajomego otrzymasz 50% rabatu na subskrypcję",
    "invite-your-friends": "zaproś znajomych",
    "recommend-to-friends": "poleć znajomym",
    "history-of-referrals": "historia poleceń",
    "your-referral-code": "twój kod polecający",
    "number-of-completed-referral-invitations-desc":
      "tyle osób do tej pory skorzystało z Twojego polecenia",
    "sign-up-date": "data rejestracji",
    "sign-up-confirmation-date": "data potwierdzenia",
    "registration-status": "status rejestracji",
    "discount-received": "uzyskany rabat",
    "identification-number-short": "lp.",
    "referral-status-finished": "ukończona",
    "referral-status-in-progress": "w trakcie",
    copy: "kopiuj",
    // and: "oraz",
    "terms-of-use": "warunki korzystania z serwisu",
    copied: "skopiowano",
    "dashboard-mobile-app-box-heading":
      "nie masz jeszcze naszej aplikacji do skanowania arkuszy odpowiedzi?",
    "dashboard-mobile-app-box-desktop-text-1": "zeskanuj QR kod",
    "dashboard-mobile-app-box-desktop-text-2": "i pobierz bezpłatną",
    "dashboard-mobile-app-box-desktop-text-3": "aplikację na telefon",
    "dashboard-mobile-app-box-mobile-text-1": "pobierz ją bezpłatnie",
    "save-changes": "zapisz zmiany",
    "my-classes": "moje zajęcia",
    "duplicate-test-description-2":
      "Wybierz z listy zajęcia do których chcesz go przypisać i kliknij “Duplikuj”",
    "tests-base": "baza testów",
    summary: "podsumowanie",
    points: "punkty",
    questions: "pytania",
    fold: "zwiń",
    unfold: "rozwiń",
    "leave-demo": "opuść demo",
    variant: "przedział",
    updated: "Zaktualizowano!",
    "test-online": "test online",
    close: "zamknij",
    "best-match": "od najlepiej dopasowanych",
    "most-popular": "od najbardziej popularnych",
    newest: "od najnowszych",
    sort: "sortowanie",
    lessons: "lekcje",
    lesson: "lekcja",
    "premium-account": "konto Pro",
    "free-plan-limit-exceeded": "Twój limit został osiągnięty",
    "premium-plan-description":
      "Przejdź na plan Pro i ciesz się nieograniczonym dostępem do wszystkich funkcjonalności Wisdy",
    premium: "Pro",
    "with-wisdy": "z Wisdy",
    "you-can-do-more": "możesz więcej",
    "formative-assessment": "ocenianie kształtujące",
    "formative-assessment-shortcut": "OK",
    "access-code": "kod dostępu",
    congratulations: "gratulacje",
    search: "szukaj",
    "changes-have-been-saved": "zmiany zostały zapisane",
    "changes-have-not-been-saved": "zmiany nie zostały zapisane",
    optional: "opcjonalne",
    show: "zobacz",
  },
  sign_up: {
    "sign-up": "zarejestruj się",
    registration: "rejestracja",
    "repeat-password": "powtórz hasło",
    "password-validation":
      "Hasło musi się składać z min. 8 znaków, jednej dużej litery i jednej cyfry",
    "privacy-policy-acceptance-first-part": "akceptuję",
    "privacy-policy-acceptance-link-part": "politykę prywatności",
    "privacy-policy-acceptance-last-part": "oraz",
    "referral-code-error-text":
      "Kod zniżkowy, którego chcesz użyć prawdopodobnie jest niepoprawny. \nAby uzyskać zniżkę sprawdź dokładnie poprawność linku.",
    "all-subjects": "wszystkie przedmioty",
    "a-test": "test",
    "a-class-accusative": "zajęcia",
  },
  sign_in: {
    "sign-in": "zaloguj się",
    login: "logowanie",
    "dont-have-an-account-first-part": "nie masz jeszcze",
    "dont-have-an-account-second-part": "konta?",
    "sign-in-page-title": "Twórz, oceniaj, analizuj - szybciej!",
    "sign-in-page-subtitle-1": "Załóż",
    "sign-in-page-subtitle-2": "bezpłatne konto",
    "sign-in-page-subtitle-3":
      ", a my dostarczymy\nCi narzędzia, które ułatwią Ci codzienną\npracę oraz pozwolą być lepszym\nnauczycielem.",
  },
  account_confirmation: {
    "account-cannot-be-confirmed":
      "Wygląda na to, że Twój link potwierdzający stracił ważność. Aby dokończyć rejestrację konta kliknij przycisk “wyślij ponownie”, a wyślemy do Ciebie nowy link.",
    "send-again": "wyślij ponownie",
    ups: "upss...",
  },
  forgot_password: {
    "forgot-password-description":
      "Wpisz poniżej adres e-mail jaki podawałeś podczas zakładania konta. Tam wyślemy Ci link do strony, na której będzie można łatwo utworzyć nowe hasło.",
  },
  reset_password: {
    "reset-password-description":
      "Wysłałeś do nas prośbę o reset hasła. Teraz możesz ustalić nowe, pamiętaj, że  musi się ono składać z min. 8 znaków, jednej dużej litery i jednej cyfry.",
    "save-new-password": "zapisz nowe hasło",
    "new-password": "nowe hasło",
    "repeat-new-password": "powtórz nowe hasło",
    "reset-password": "Zresetuj stare hasło",
  },
  select_subject: {
    "pick-up-subject-desc":
      "uzupełnij pola poniżej i kliknij “potwierdzam”, aby rozpocząć dodawanie pytań w Twoim teście",
    "pick-up-subjects-desc":
      "aby przejść dalej wybierz przedmiot, który chcesz przypisać do zajęć",
    "pick-up-subjects-tooltip":
      "aby przejść dalej, konieczne jest wybranie przedmiotu",
    choice: "wybór",
    "subject-second": "przedmiotu",
    "pick-up-subjects-demo-desc":
      "zaznacz przedmiot z którego chcesz stworzyć test. Następnie kliknij dalej, aby przejść do tworzenia zajęć dla której test jest przeznaczony",
  },
  class: {
    "class-accusative": "zajęcia",
    "create-class-desc": "wpisz nazwę zajęć oraz dodaj liczbę jej uczniów",
    classname: "nazwa zajęć",
    "add-class": "dodaj zajęcia",
    "number-of-students": "liczba uczniów",
    "add-class-success-desc-first": "twoje zajęcia zostały pomyślnie dodane.",
    "add-class-success-desc-second":
      "teraz możesz przejść do zakładki z nowymi zajęciami i stworzyć swój pierwszy test.",
    "remember-that": "pamiętaj, że",
    "add-class-success-desc-third":
      "edycji przedmiotów jakie do siebie przypisałeś możesz dokonać w zakładce “Odkrywaj” klikając w kafel “Moje przedmioty”.",
    "go-to-class": "idź do zajęć",
    class: "zajęcia",
    "select-or-create-new-class": "wybierz z istniejących lub dodaj nową",
    or: "lub",
    actions: "akcje",
    "delete-student-confirmation": "czy na pewno chcesz usunąć tego ucznia?",
    "delete-student": "usuń ucznia",
    "student-has-been-deleted": "usunięto ucznia",
    "created-at": "utworzono",
    "average-score-from-all-tests": "średnia ze wszystkich testów",
    reports: "raporty",
    "delete-class-confirmation": "aby usunąć zajęcia wpisz jej nazwę",
    "class-has-been-deleted": "usunięto zajęcia",
    "select-education-level-description":
      "Jeśli chcesz do zajęć przypisać podstawę programową",
    "variant-or-variants": "przedział(y)",
    "curriculum-is-assigned": "Podstawa przypisana",
    "education-level": "etap edukacji",
    "classes-limit-paid-version-description":
      "W darmowej wersji Wisdy możesz stworzyć 2 zajęcia",
  },
  classes: {
    "classes-list": "lista zajęć",
  },
  creator: {
    "select-question-type": "wybierz rodzaj pytania",
    punctation: "punktacja",
    "number-of-correct-answers": "poprawnych odpowiedzi",
    "add-picture": "dodaj obrazek",
    "delete-question": "usuń pytanie",
    "add-next": "dodaj następne",
    "delete-question-confirmation": "czy na pewno chcesz usunąć to pytanie?",
    answer_choices_info:
      "aby oznaczyć poprawną odpowiedź kliknij w pole po lewej stronie od wariantu",
    "add-answer-choice": "dodaj wariant odpowiedzi",
    "true-false-desc-1": "Oceń prawdziwość poniższego stwierdzenia. Wybierz",
    "true-false-desc-2": "jeśli stwierdzenie jest prawdziwe lub",
    "true-false-desc-3": "jeśli uważasz je za fałszywe.",
    "quit-preview-mode": "wyjdź z podglądu",
    "show-preview-mode": "zobacz podgląd",
    "finish-test-description-1": "twój test został poprawnie zapisany.",
    "test-instruction-heading": "instrukcja uzupełniania karty odpowiedzi",
    "test-instruction-desc-1":
      "Odpowiedzi do pytań zamkniętych zaznacz na karcie odpowiedzi w części przeznaczonej dla ucznia.",
    "test-instruction-desc-2": "Zamaluj",
    "test-instruction-desc-3":
      "pola do tego przeznaczone. Błędne zaznaczenie otocz kółkiem",
    "test-instruction-desc-4": "a następnie zaznacz poprawną odpowiedź.",
    "add-picture-desc": "aby dodać obrazek wybierz plik ze swojego urządzenia",
    "allowed-file-formats": "dopuszczalne formaty plików",
    crop: "przytnij",
    "total-score": "Łącznie do zdobycia",
    "add-notes-to-question-description":
      "miejsce na wpisanie odpowiedzi przez ucznia",
    "add-gap": "Dodaj lukę",
    "fill-in-the-gaps-show-answers-info-1":
      "Nie musisz martwić się kolejnością odpowiedzi w tabeli -",
    "fill-in-the-gaps-show-answers-info-2":
      "Zmienimy ją automatycznie podczas przeprowadzania testu",
    "fill-in-the-gaps-show-correct-answers-in-the-box":
      "Wyświetl poprawne odpowiedzi w tabelce",
    "fill-in-the-gaps-add-alternative-answers":
      "Dodaj wiele poprawnych odpowiedzi dla luki",
    "fill-in-the-gaps-add-alternative-answer": "Dodaj alternatywną odpowiedź",
    "fill-in-the-gaps-is-case-sensitive": "Zwracaj uwagę na wielkość liter",
    "fill-in-the-gaps-main-answer": "Główna odpowiedź",
    "fill-in-the-gaps-add-alternative-answers-info-1":
      "Aby mieć możliwość dodania wielu poprawnych odpowiedzi,",
    "fill-in-the-gaps-add-alternative-answers-info-2":
      "dodaj i uzupełnij co najmniej jedną lukę",
    "time-limit-1": "Limit",
    "time-limit-2": "czasu",
    "time-limit-desc":
      "Zdecyduj ile czasu uczniowie będą mieli na test, który tworzysz, a następnie kliknij potwierdzam.",
    "no-limit": "Brak limitu",
    "time-limit-or-pass-different-value": "Lub wpisz inną wartość w minutach",
    "questions-base-no-access-text":
      "Baza pytań jest dostępna dla użytkowników Pro",
    assigned: "przypisana",
    "add-matching": "Dodaj kolejne dopasowanie",
    "matching-question-info":
      "Nie musisz martwić się tasowaniem wariantów. Kiedy zakończysz edycję pytania zrobimy to za Ciebie.",
    "punctation-per-item": "Za odpowiedź",
    "punctation-per-question": "Całość",
    "next-page": "następna strona",
    "finish-test-online-description-2":
      "Kliknij przycisk \"Zakończ\", aby wyjść z kreatora",
    "duplicate-question-from-questions-base-failure-text":
      "nie udało się dodać wszystkich zaznaczonych pytań",
    "assign-skill": "przypisz umiejętność",
    "add-skills-to-a-question-descritpion":
      "Możesz zaznaczyć poszczególne tematy lub cały dział",
    "selected-variant": "wybrany zakres",
    "re-assign-skill": "zmień umiejętność",
    "answer-sheet-inctruction": "instrukcja uzupełniania arkusza odpowiedzi",
    "change-questions-order": "zmień kolejność pytań",
    "no-questions-found-for-this-query":
      "nie znaleźliśmy pytań dla tego zapytania",
    "back-to-the-creator": "wróć do kreatora testu",
    "dnd-tutorial-description":
      "Aby zmienić pozycję pytania chwyć pytanie w dowolnym miejscu za pomocą myszki a następnie przeciągnij na odpowiednią pozycję",
    "type-of-test": "typ testu",
    choose: "wybierz",
    "choose-type-of-test-description":
      "Wybierz typ testu, który Cię interesuje",
    "or-add": "lub dodaj",
    "question-from-base": "pytanie z bazy",
    add: "dodaj",
    "times-used": "liczba użyć",
    "user-ratings": "ocena użytkowników",
    "questions-base": "baza pytań",
    publish: "publikuj",
    online: "online",
    paper: "papierowy",
  },
  test: {
    "finish-test-description-2": "wybierz opcję, w jakiej chcesz pobrać test",
    "download-pdf": "pobierz pdf",
    "see-report": "zobacz raport",
    "question-type": "typ pytania",
    "download-later": "pobierz później",
    "test-has-been-duplicated": "test został zduplikowany",
    "test-has-not-been-duplicated": "test nie został zduplikowany",
    "change-test-status-to-completed-confirmation-text":
      "czy na pewno chcesz zmienić status testu na \"Przeprowadzony\"? Przeprowadzonych testów nie można edytować.",
    "time-limit-answers-will-be-sent-automatically-info":
      "Po upływie czasu wyniki zostaną wysłane automatycznie",
    // information: "Informacja",
    "time-limit-time-left-info-1": "Pozostało Ci",
    "time-limit-time-left-info-2": "na rozwiązanie testu",
    "test-has-been-downloaded-and-saved":
      "Test został poprawnie pobrany i zapisany",
    "your-test-has-errors-description":
      "Twój test posiada błędy. Wróć do edycji testu.",
    "download-test": "pobierz test",
    "duplicate-test": "duplikuj test",
    "delete-test": "usuń test",
    "preview-settings": "opcje podglądu",
    "compact-version": "wersja kompaktowa",
    instruction: "instrukcja",
    "print-version": "wersja wydruku",
    bonuses: "dodatki",
    compact: "kompaktowy",
    standard: "standardowy",
    "public-link-to-online-test-description":
      "link do udostępnienia dla uczniów",
    "test-online-success-description-1":
      "Twoje odpowiedzi zostały przesłane do nauczyciela",
    "test-online-success-description-2":
      "Czekaj na informacje zwrotną jaką ocenę otrzymałeś",
    "send-answers": "wyślij odpowiedzi",
    "accept-it": "potwierdzam",
    "access-verification-1": "weryfikacja",
    "access-verification-2": "dostępu",
    "access-verification-description":
      "W ten sposób upewnimy się, że powinieneś mieć dostęp do tej zawartości",
    "wrong-student-code-or-student-number":
      "Niepoprawny kod dostępu lub numer ucznia",
    "send-answers-confirmation":
      "Jesteś pewien, że chcesz wysłać swoje odpowiedzi? Po ich wysłaniu, ponowne rozwiązanie testu nie będzie możliwe",
    "answers-has-not-been-sent": "Odpowiedzi nie zostały wysłane",
  },
  tests: {
    "last-completed-tests": "ostatnio przeprowadzone",
    "delete-test-confirmation": "czy na pewno chcesz usunąć ten test?",
    "number-of-questions": "liczba pytań",
    "all-tests-prefix": "wszystkie",
  },
  report: {
    "score-points": "wynik puntkowy",
    "student-score": "uzyskany wynik",
    "generate-pdf": "generuj pdf",
    "test-report": "raport sprawdzianu",
    "test-participants": "uczestnicy testu",
    "students-stats": "statystyki uczniów",
    "maximum-points-to-get": "maksymalna liczba punktów do zdobycia",
    "score-points-short": "wynik pkt.",
    "score-percentage-points-short": "wynik proc.",
    "questions-stats": "statystyki pytań",
    "question-number-short": "numer pyt.",
    "correct-answers-short": "poprawne odp.",
    "wrong-answers-short": "błędne odp.",
    score: "wynik",
    "answer-given-short": "udzielona odp.",
    "correct-answer-short": "poprawna odp.",
    "question-number-short-2": "nr pyt.",
    "question-details": "szczegóły pytania",
    "question-content": "treść pytania",
    report: "raport",
    "gained-points": "zdobyte punkty",
    "score-percentage-points": "wynik procentowy",
    "question-type": "typ pytania",
    "closed-questions": "pytania zamknięte",
    "open-questions": "pytania otwarte",
    "edit-student-score-open-question-information":
      "Ten sprawdzian zawierał pytania otwarte, za które punktację musisz umieścić w raporcie samodzielnie. Możesz to zrobić klikając przycisk",
    "fill-in-a-score": "uzupełnij wynik",
    "add-a-score": "dodaj wynik",
    "delete-student-result-confirmation-text":
      "czy na pewno chcesz usunąć wynik tego ucznia?",
    "generate-pdf-limit-paid-version-description":
      "W darmowej wersji Wisdy nie możesz pobierać raportów",
  },
  students: {
    "add-student": "dodaj ucznia",
    "student-number": "uczeń nr",
    "student-number-heading": "numer ucznia",
    student: "uczeń",
    "add-student-desc":
      "wpisz numer w dzienniku ucznia, którego chcesz dodać. Dzięki temu będziemy mogli go umieścić w odpowiednim miejscu na liście.",
    "number-from-lesson-diary": "numer z dziennika",
    "student-has-been-added": "dodano nowego ucznia",
    "student-has-not-been-added": "nie dodano nowego ucznia",
    "add-student-double-confirmation-desc":
      "uczeń, którego chcesz dodać posiada numer w dzienniku, którego dodanie wpłynie na numerację innych uczniów. \nJesteś pewien, że chcesz zaktualizować numery w dzienniku uczniów?",
  },
  profile: {
    "basic-information": "informacje podstawowe",
    subscription: "subskrypcja",
    "choose-payment-method": "wybierz metodę płatności",
    "days-till-subscription-over": "liczba dni pozostałych do końca",
    day: "dzień",
    days: "dni",
    "your-subscription-is-active": "twoja subskrypcja jest aktywna",
    "your-subscription-is-not-active": "twoja subskrypcja nie jest aktywna",
    "credit-card": "karta płatniczna",
    transfer: "przelew",
    "payment-method": "metoda płatności",
    "card-number": "numer karty",
    "card-expiry": "data ważności",
    "card-cvc": "kod CVC",
    "credit-card-info-desc": "dane do karty",
    "chosen-payment-method": "wybrana metoda",
    "chosen-currency": "wybrana waluta",
    "save-credit-card": "zapisz kartę",
    "change-payment-method": "zmień metodę płatności",
    "delete-credit-card-info": "usuń dane karty",
    "delete-credit-card-confirmation":
      "Czy na pewno chcesz usunąć zapisane dane karty?",
    "delete-credit-card-change-method-confirmation":
      "Zmiana metody płatności wiąże się z usunięciem danych karty. \nCzy jesteś pewien?",
    "payment-has-been-processed": "płatność została przetworzona",
    "payment-has-not-been-processed": "płatność nie została przetworzona",
    "credit-card-has-been-added": "karta została dodana",
    "credit-card-has-not-been-added": "karta nie została dodana",
    "actual-subscription-amount": "aktualna wartość subskrypcji",
    "amount-before-discount": "wartość przed rabatem",
    "payment-info-discount-desc":
      "rabat zostanie naliczony od następnego kresu rozliczeniowego",
    "available-discount-codes": "dostępne kody zniżkowe",
    "discount-number": "wysokość rabatu",
    activation: "aktywacja",
    used: "wykorzystany",
    activate: "aktywuj",
    "no-discount-codes-desc":
      "Przykro nam, ale aktualnie nie posiadasz żadnego kodu rabatowego.",
    "see-how-to-get-discount": "sprawdź jak zdobyć rabat",
    "payment-info-tooltip-desc":
      "Aby obniżyć kwotę do zapłaty aktywuj dostępne kody rabatowe.",
    "you-can-use-one-discount-per-transaction-desc":
      "PAMIĘTAJ!  Jednorazowo możesz wykorzystać tylko jeden kod rabatowy.",
    "invoice-details-1": "dane",
    "invoice-details-2": "do faktury",
    "fill-out-form-below": "uzupełnij poniższy formularz",
    "fill-out-invoice-details": "uzupełnij dane do faktury",
    "company-name": "nazwa firmy",
    streetname: "nazwa ulicy",
    "flat-number": "nr lokalu",
    "building-number": "nr budynku",
    city: "miasto",
    "postal-code": "kod pocztowy",
    country: "kraj",
    "tin-number": "NIP",
    "first-name": "imię",
    "last-name": "nazwisko",
    "payment-transfer-7-days-tooltip-text":
      "Ta forma płatności może zostać wybrana tylko jeśli ważność konta wynosi mniej niż 7 dni",
    "payment-transfer-is-not-supported-currency":
      "Wybrana waluta nie jest wspierana przy przelewach",
    "delete-account": "usuń konto",
    "delete-account-confirmation":
      "czy na pewno chcesz usunąć swoje konto w Wisdy?",
    "pricing-promotion-duration-info": "promocja trwa do końca marca",
    "promotion-discount-info": "promocyjna cena",
    "wisdy-old-price-usd": "",
    "wisdy-old-price-pln": "",
    "change-password": "zmień hasło",
    "current-password": "obecne hasło",
    "additional-info": "informacje dodatkowe",
    "invoice-details-2-transfer": "płatności",
    "active-subscription-description":
      "Po tym czasie przejdziesz na plan bezpłatny (Standard)",
    "inactive-subscription-description": "",
  },
  test_base: {
    "test-has-not-been-published": "test nie został opublikowany",
    "test-has-been-published": "test został opublikowany",
  },
  pricing: {
    "pricing-standard-classes-limit": "2 zajęcia",
    "pricing-standard-tests-limit": "5 testów miesięcznie",
    "pricing-standard-curriculum": "Podstawa Programowa",
    "pricing-standard-tests-base": "Baza testów",
    "pricing-standard-scanning-app": "Skanowanie za pomocą aplikacji mobilnej",
    "pricing-standard-creator": "Zaawansowany edytor tekstu",
    "pricing-standard-formulas": "Wzory matematyczne",
    "pricing-standard-reports-online": "Raporty online",
    "pricing-premium-classes": "Nielimitowana liczba zajęć",
    "pricing-premium-tests": "Nielimitowana liczba testów",
    "pricing-premium-questions-base": "Baza pytań",
    "pricing-premium-reports-pdf": "Raporty w wersji pdf",
    "pricing-premium-compact-version": "Wersja kompaktowa testu",
    "pricing-premium-paragraph":
      "Ta wersja zawiera wszystkie funkcjonalności z wersji standard oraz:",
    buy: "kup",
    "for-free": "za darmo",
    "choose-the-best-plan": "wybierz nalepszy plan",
    "for-yourself": "dla siebie",
    "create-assess-and-analyze":
      "Twórz, oceniaj, analizuj szybciej i skup się na tym co najważniejsze - nauczaniu",
    "standard-plan": "Standard",
    "premium-plan": "Pro",
  },
  lessons: {
    "add-lesson": "dodaj lekcję",
    // "all-lessons": "wszystkie lekcje",
    "lesson-topic": "temat lekcji",
    "lesson-goal": "cel lekcji",
    "lesson-description": "opis",
    "lesson-tasks": "zadania",
    "pick-up-subject-for-lesson-description":
      "Wybierz przedmiot, a następnie kliknij “Dalej”, aby rozpocząć tworzenie lekcji",
    "create-lesson": "stwórz lekcję",
    "lesson-has-been-created": "Lekcja została stworzona",
    "lesson-has-not-been-created": "Nie udało się stworzyć lekcji",
    "lesson-success-criteria": "kryteria sukcesu",
    "delete-lesson-confirmation": "czy na pewno chcesz usunąć tę lekcję?",
    "lesson-has-been-updated": "lekcja została zaktualizowana",
    // "lesson-has-not-been-updated": "lekcja nie została zaktualizowana",
    "update-lesson": "zaktualizuj lekcję",
    "lesson-has-been-deleted": "lekcja została usunięta",
    // "lesson-has-not-been-deleted": "lekcja nie została usunięta",
    "public-link-to-lesson": "link do udostępnienia dla uczniów",
    generate: "wygeneruj",
    "public-link-to-lesson-generate-description":
      "Wygeneruj publiczny link do lekcji. Osoby posiadające ten link będą miały dostęp do tej lekcji.",
    "public-link-to-lesson-description":
      "Brawo! Wygenerowałeś link. Teraz możesz go skopiować i wysłać do swoich uczniów bądź innej oosby.",
    "generate-new-lesson-public-link": "wygeneruj nowy link",
    "public-link-to-lesson-has-been-generated":
      "link publiczny do lekcji został wygenerowany",
    "edit-lesson": "edytuj lekcję",
    "private-lesson-short": "prywatna",
    "public-lesson-short": "publiczna",
    "lesson-status": "status lekcji",
    status: "status",
    "copy-public-link": "kopiuj link publiczny",
    "generate-link-to-lesson-list": "generuj link do listy",
    "public-link-to-lessons-has-been-generated":
      "link publiczny do listy lekcji został wygenerowany",
    "lesson-has-been-duplicated": "lekcja została zduplikowana",
    "lesson-has-not-been-duplicated": "lekcja nie została zduplikowana",
    "duplicate-a-lesson": "duplikuj lekcję",
    "a-lesson": "lekcję",
    "duplicate-lesson-description":
      "Wybierz z listy zajęcia, a następnie kliknij “Duplikuj”",
    "lesson-homework": "zadanie domowe",
  },
  curriculum: {
    "curriculum-has-been-saved":
      "Do zajęć została przypisana Podstawa Programowa",
    curriculum: "Podstawa Programowa",
    "select-curriculum-description":
      "Wybierz Podstawę Programową dla wybranych przedmiotów",
    "curriculum-has-been-deleted": "Podstawa Programowa została usunięta",
    "curriculum-has-not-been-deleted":
      "Nie udało się usunąć Podstawy Programowej",
    "skill-has-been-assigned-to-a-question":
      "Umiejętność została przypisana do pytania",
    "skill-has-not-been-assigned-to-a-question":
      "Umiejętność nie została przypisana do pytania",
    "delete-curriculum-short": "usuń podstawę",
    "curriculum-progress": "stopień realizacji",
    "delete-curriculum-confirmation":
      "Czy na pewno chcesz usunąć tę Podstawę Programową?",
    tip: "wskazówka",
    "update-class-skills-tip-description":
      "W tym miejscu możesz zaznaczać tematy jakie już zrealizowałeś w ramach przeprowadzonych zajęć. Dzięki systematycznemu uzupełnianiu tej sekcji będziemy mogli wyświetlać Ci statystyki dotyczące stopnia realizacji podstawy programowej.",
  },
  common: {
    "compact-version-short": "kompaktowa",
    "standard-version-short": "standardowa",
    "enter-formula": "wpisz wzór",
    "enter-video-url": "wpisz adres video",
    "compact-version-paid-version-description":
      "Wersja kompaktowa jest dostępna tylko dla użytkowników Pro",
    "list-of-the-symbols-link": "lista symboli (LaTeX)",
    "generate-new-public-link-description":
      "Wygenerowanie nowego linku spowoduje dezaktywację poprzedniego. Wszystkie osoby, które miały do niego dostęp utracą możliwość odczytu.",
    "do-you-want-to-continue": "czy chcesz kontynuować?",
    language: "Język",
  },

  "free-plan-limit-test-description":
    "Darmowa wersja umożliwia stworzenie 2 testów",
  "wisdy-current-price-pln": "9.99",
  "wisdy-current-price-usd": "5",
  verification: "weryfikacja",
  "access-dative": "dostępu",
  "available-soon": "Dostępne wkrótce",
  "try-later": "spróbuj później",
  "account-has-been-confirmed": "twoje konto zostało potwierdzone",
  "sign-in-header-first-part": "Zaloguj się,",
  "sign-in-header-second-part": "aby zacząć",
  "multiple-choice-question": "wielokrotnego wyboru",
  "open-question": "otwarte",
  "true-false-question": "Prawda / Fałsz",
  "enter-question-body": "wpisz treść pytania...",
  "enter-statement": "wpisz treść stwierdzenia...",
  "subtype-single": "jedna",
  "subtype-multiple": "więcej niż jedna",
  answer_choice_placeholder: "wariant odpowiedzi",
  "test-status-in_progress": "w trakcie",
  "test-status-completed": "przeprowadzony",
  "keep-scanning": "skanuj dalej",
  of: "z",
  "scanned-student-number": "zeskanowano Uczeń nr",
  "scan-again": "zeskanuj ponownie",
  "there-was-a-problem": "napotkaliśmy problem",
  scan: "zeskanuj",
  "scan-to-receive-score": "zeskanuj kartę odpowiedzi żeby uzyskać wynik",
  "our-advice": "nasza rada",
  "scanning-hint":
    "upewnij się, że arkusz znajduje się we właściwej pozycji oraz że jest całkowicie widoczny i nic go nie przesłania. W razie potrzeby użyj flasha.",
  skipped: "opuszczone",
  "pass-rate": "zdawalność",
  "no-access-to-camera": "Nie ma dostępu do aparatu",
  "ask-for-camera-access": "Zezwól na dostęp",
  "account-validity-period": "Ważność konta",
  "points-short": "pkt.",
  "no-camera-access-desc":
    "Aplikacja Wisdy wymaga dostępu do aparatu. Możesz go nadać w ustawieniach telefonu.",
  "student-has-not-been-deleted": "nie udało się usunąć ucznia",
  absent: "nieobecny",
  "finish-test-inactive-account-1":
    "twój okres testowy dobiegł końca, aby dalej korzystać ze wszystich funkcjonalności Wisdy - wykup subskrybcję",
  "buy-subscription": "wykup subskrypcję",
  "class-name-has-been-updated": "nazwa zajęć została zmieniona",
  "edit-class-name": "edytuj nazwę zajęć",
  "duplicate-test-description-1": "Chcesz wykorzystać ten test jeszcze raz?",
  "detailed-result": "wynik",
  "summary-mobile-description-first":
    "Aby zobaczyć podsumowanie uzupełnij wynik z sekcji “pytania otwarte”",
  "summary-mobile-description-second":
    "Jeśli chcesz, możesz to zrobić później z poziomu aplikacji webowej",
  "fill-in-score": "uzupełnij wynik",
  "results-of-the-test": "wyniki uzyskany z całego testu",
  "delete-curriculum": "usuń podstawę programową",
  "curriculum-has-not-been-saved":
    "Nie udało się przypisać Podstawy Programowej",
  "download-now": "pobierz teraz",
  "optional-info": "informacje opcjonalne",
  "tests-limit-paid-version-description":
    "W darmowej wersji Wisdy możesz stworzyć maksymalnie 5 testów",
  "plan-limit-page-heading": "Z Wisdy Pro możesz więcej",
  "free-plan-limit-class-description":
    "Darmowa wersja umożliwia stworzenie jednych zajęć",
  "plan-limit-page-button-text": "Przejdź do cennika",
  "dashboard-mobile-app-box-mobile-text-2": "na swój telefon",
};
