import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";

import QuestionPreview from "../../Creator/Preview/Question";
import TestReportStudentQuestions from "../Student/Questions";
import TestReportClassQuestionsStats from "../Class/QuestionsStats";

import translate from "../../../../../services/translate";

import {
  getStudentReport,
  resetStudentReport,
  getClassReport,
  resetClassReport,
} from "../../../../../redux/actions/report";
import { getTest, unmountTest } from "../../../../../redux/actions/test";
import { unmountClass } from "../../../../../redux/actions/class";

// Types
import { History, Location } from "history";
import {
  reportStateModel,
  studentReportQuestionModel,
  testReportQuestionModel,
} from "../../../../../models/redux/report";
import {
  studentReportModel,
  testReportModel,
  getStudentReport as getStudentReportFunction,
  resetStudentReport as resetStudentReportFunction,
  getClassReport as getClassReportFunction,
  resetClassReport as resetClassReportFunction,
} from "../../../../../models/redux/report";
import {
  testStateModel,
  getTest as getTestFunction,
  questionModel,
} from "../../../../../models/redux/test";

import styles from "./styles.module.scss";
import CurriculumDetailsBox from "../../../Curriculum/Details/Box";
import { curriculumDetailsSkill } from "../../../../../models/redux/curriculum";

interface Props {
  history: History;
  location: Location;
  match: any;
  studentReport: studentReportModel;
  classReport: testReportModel;
  getStudentReport: getStudentReportFunction;
  resetStudentReport: resetStudentReportFunction;
  getClassReport: getClassReportFunction;
  resetClassReport: resetClassReportFunction;
  test: testStateModel;
  getTest: getTestFunction;
  unmountTest: () => {};
  unmountClass: () => {};
  generatePDFinProgress: boolean;
}

export const TestReportQuestionDetails = (props: Props) => {
  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    props.getTest({
      classID: props.match.params.classID,
      testID: props.match.params.testID,
    });

    if (parsed && parsed.studentID) {
      props.getStudentReport({
        testID: props.match.params.testID,
        studentID: `${parsed.studentID}`,
      });
    } else {
      props.getClassReport({ testID: props.match.params.testID });
    }

    return () => {
      props.unmountTest();
      props.resetStudentReport();
      props.resetClassReport();
      props.unmountClass();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestion = () =>
    props.test.questions.find(
      (question) => question.order === Number(props.match.params.questionNumber),
    ) as questionModel;

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h4>
          <Link
            to={`/test?classID=${props.match.params.classID}&testID=${props.match.params.testID}`}
          >
            {props.test.name}
          </Link>{" "}
          /&nbsp;
          <Link to={`/test/${props.match.params.testID}/report/class`}>
            {translate("report.report")}
          </Link>{" "}
          /&nbsp;
          {parsed && parsed.studentID && props.studentReport.student_number && (
            <>
              <Link
                to={`/test/${props.match.params.testID}/report/student/${parsed.studentID}`}
              >
                {translate("students.student")}{" "}
                {props.studentReport.student_number}
              </Link>{" "}
              /&nbsp;
            </>
          )}
          <span>{translate("report.question-details")}</span>
        </h4>
        <h3>{translate("report.question-details")}</h3>
      </div>
      <section className={styles.content}>
        {parsed &&
          !parsed.studentID &&
          !!props.classReport.questions.length &&
          !!Number(props.match.params.questionNumber) && (
          <TestReportClassQuestionsStats
            questions={
                [
                  props.classReport.questions.find(
                    (item: testReportQuestionModel) =>
                      item.number === Number(props.match.params.questionNumber),
                  ),
                ] as testReportQuestionModel[]
            }
            testID={props.match.params.id}
            classID={props.classReport.class_id}
            displayOnly
          />
        )}
        {parsed &&
          parsed.studentID &&
          !!props.studentReport.questions.length &&
          !!Number(props.match.params.questionNumber) && (
          <TestReportStudentQuestions
            questions={
                [
                  props.studentReport.questions.find(
                    (item: studentReportQuestionModel) =>
                      item.number === Number(props.match.params.questionNumber),
                  ),
                ] as studentReportQuestionModel[]
            }
            testID={props.match.params.testID}
            classID={props.studentReport.class_id}
            studentID={`${parsed.studentID}`}
            displayOnly
          />
        )}
        {!!props.test.questions.length &&
          !!Number(props.match.params.questionNumber) && (
          <div className={styles.questionPreview}>
            <span className={styles.heading}>
              {translate("report.question-content")}
            </span>
            <QuestionPreview
              index={props.match.params.questionNumber}
              question={getQuestion()}
              showCorrectAnswers
            />
          </div>
        )}
        {getQuestion() && !!getQuestion().skills.length && (
          <ul className={styles.skills}>
            <span className={styles.heading}>
              {translate("curriculum.curriculum")}
            </span>
            {getQuestion().skills.map((skill: curriculumDetailsSkill) => (
              <CurriculumDetailsBox
                key={skill.id}
                id={skill.id}
                body={skill.body}
                children={skill.children}
                isChosen={skill.is_chosen}
                selectedSkills={[]}
                onClick={() => {}}
                inProgress={false}
                readOnly
              />
            ))}
          </ul>
        )}
      </section>
    </section>
  );
};

const mapStateToProps = (state: {
  report: reportStateModel;
  test: testStateModel;
}) => ({
  studentReport: state.report.student,
  classReport: state.report.class,
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTest,
      unmountClass,
      getStudentReport,
      resetStudentReport,
      unmountTest,
      getClassReport,
      resetClassReport,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestReportQuestionDetails);
