import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import pathOr from "ramda/src/pathOr";

import translate from "../../../services/translate";

import { ReactComponent as AcademicCapIcon } from "../../../assets/images/bottom_bar/academic_cap.svg";
import { ReactComponent as ExploreToolIcon } from "../../../assets/images/bottom_bar/explore_tool.svg";
import { ReactComponent as FileIcon } from "../../../assets/images/bottom_bar/file.svg";
// import { ReactComponent as SettingsIcon } from "../../../assets/images/bottom_bar/settings.svg";

// import { userFromPL } from "../../../services/common";

import styles from "./styles.module.scss";

export const BottomBar = (props: any) => {
  // const [otherMenu, toggleOtherMenu] = useState(false);

  useEffect(() => {
    addAdditionalPaddingOnAppleMobileDevices();
  }, [props.history.location.pathname]);

  const isActive = (value: string) =>
    classNames({
      [styles.active]: props.history.location.pathname === value,
    });

  const addAdditionalPaddingOnAppleMobileDevices = () => {
    const userAgent = pathOr("", ["navigator", "userAgent"], window) as string;

    if (
      userAgent &&
      (userAgent.includes("iPad") || userAgent.includes("iPhone"))
    ) {
      const containerBottomBar = document.querySelector(
        "[class*=containerBottomBar]",
      ) as HTMLElement;

      if (containerBottomBar) {
        containerBottomBar.style.height = "95px";
        containerBottomBar.style.padding = "20px 0 35px 0";
      }
    }
  };

  return (
    <>
      {/* {otherMenu &&
        <OutsideClickHandler onOutsideClick={() => toggleOtherMenu(false)}>
          <ul className={styles.otherMenu}>
            <li>
              <Link to="/tests/all">
                <span>{translate("global.all-tests")}</span>
              </Link>
            </li>
            {userFromPL(props.user.country) &&
              <li>
                <Link to="/formative-assessment">
                  <span>{translate("global.formative-assessment")}</span>
                </Link>
              </li>
            }
          </ul>
        </OutsideClickHandler>
      } */}
      <div className={styles.containerBottomBar}>
        <Link to="/" className={isActive("/")}>
          <ExploreToolIcon/>
          <span>{translate("global.discover")}</span>
        </Link>
        <Link to="/classes" className={isActive("/classes")}>
          <AcademicCapIcon/>
          <span>{translate("global.classes")}</span>
        </Link>
        <Link
          to="/test/creator/select-class"
          className={isActive("/test/creator/select-class")}
        >
          <FileIcon/>
          <span>
            {translate("global.create")} {translate("global.test")}
          </span>
        </Link>
        {/* <button type="button" onClick={() => toggleOtherMenu(!otherMenu)}>
          <SettingsIcon />
          <span>{translate("global.other")}</span>
        </button> */}
      </div>
    </>
  );
};

export default BottomBar;
