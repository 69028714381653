import React, { useState, useRef } from "react";
import classNames from "classnames";
import { CardNumberElement } from "react-stripe-elements";

import translate from "../../../../../../../../services/translate";

// Types
import { Payment } from "../../../../../../../../models/common";

import styles from "../styles.module.scss";

interface Props {
  focusedElement: Payment["focusedElement"];
  setFocusedElement: (value: Payment["focusedElement"]) => any;
}

export const CardNumber = (props: Props) => {
  const [cardNumberEmpty, setCardNumberEmpty] = useState<boolean>(true);
  const CardNumberEl = useRef<any>(null);

  const isActive = (value: string) =>
    props.focusedElement === value || !cardNumberEmpty;

  const labelClass = (value: string) =>
    classNames({
      [styles.active]: isActive(value),
    });

  return (
    <div className={styles.creditCardInputContainer}>
      <div
        className={styles.labelContainer}
        onClick={() => CardNumberEl.current.getElement().focus()}
      >
        <label className={labelClass("cardNumber")}>
          <span>{translate("profile.card-number")}</span>
        </label>
      </div>
      <CardNumberElement
        onChange={(e) => setCardNumberEmpty(e.empty)}
        onBlur={(e) => props.setFocusedElement("")}
        onFocus={(e) => props.setFocusedElement(e.elementType as any)}
        ref={CardNumberEl}
        className="stripeInput"
        placeholder={isActive("cardNumber") ? "1234 1234 1234 1234" : ""}
        style={{ base: { color: "#5B5BB4" } }}
      />
    </div>
  );
};

export default CardNumber;
