import React from "react";
import translate from "../../../../../../../../services/translate";
import {
  parseDateTime,
  formatAsDate,
} from "../../../../../../../../services/common";

import { ReactComponent as ThumbUpIcon } from "../../../../../../../../assets/images/questions_base/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../../../../../../../assets/images/questions_base/thumb_down.svg";

import { ReactComponent as ThumbUpFilledIcon } from "../../../../../../../../assets/images/questions_base/thumb_up_filled.svg";
import { ReactComponent as ThumbDownFilledIcon } from "../../../../../../../../assets/images/questions_base/thumb_down_filled.svg";

// Types
import {
  questionModel,
  voteForAQuestion as voteForAQuestionFunction,
} from "../../../../../../../../models/redux/questionsBase";

import styles from "./styles.module.scss";

interface Props {
  question: questionModel;
  voteForAQuestion: voteForAQuestionFunction;
  downvotedQuestionIDs: string[];
  upvotedQuestionIDs: string[];
}

export const Info = (props: Props) => {
  const getUpvoteIcon = () => {
    if (props.upvotedQuestionIDs.includes(props.question.id)) {
      return <ThumbUpFilledIcon/>;
    }
    return <ThumbUpIcon/>;
  };

  const getDownvoteIcon = () => {
    if (props.downvotedQuestionIDs.includes(props.question.id)) {
      return <ThumbDownFilledIcon/>;
    }
    return <ThumbDownIcon/>;
  };

  return (
    <div className={styles.info}>
      <div className={styles.infoDetail}>
        <span className={styles.name}>{translate("global.date-added")}:</span>
        <span className={styles.value}>
          {formatAsDate(parseDateTime(props.question.published_at))}
        </span>
      </div>
      <div className={styles.infoDetail}>
        <span className={styles.name}>{translate("creator.times-used")}:</span>
        <span className={styles.value}>{props.question.duplications}</span>
      </div>
      <div className={styles.infoDetail}>
        <span className={styles.name}>
          {translate("creator.user-ratings")}:
        </span>
        <span className={styles.value}>
          <button
            type="button"
            onClick={() =>
              props.voteForAQuestion({
                question_id: props.question.id,
                type: "upvote",
              })
            }
          >
            {getUpvoteIcon()}&nbsp;{props.question.upvotes}
          </button>
          <button
            type="button"
            onClick={() =>
              props.voteForAQuestion({
                question_id: props.question.id,
                type: "downvote",
              })
            }
          >
            {getDownvoteIcon()}&nbsp;{props.question.downvotes}
          </button>
        </span>
      </div>
    </div>
  );
};

export default Info;
