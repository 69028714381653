import React from "react";
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";

import styles from "./styles.module.scss";

interface Props {
  value: string;
  data?: any;
}

const Translate = (props: Props) => {
  const path: string[] = `${props.value}`.toString().split(".");
  const value: string | undefined = pathOr("", ["data", ...path], props);
  if (typeof value !== "string") return <></>;
  return <span className={styles.text}>{value.split("\n").join("\n")}</span>;
};

const mapStateToProps = (state: { app: any }) => ({
  data: state.app.translations.data,
});

export default connect(mapStateToProps, null)(Translate);
