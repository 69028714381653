import React from "react";

import translate from "../../../../../../../../../services/translate";

import { ReactComponent as PenEditIcon } from "../../../../../../../../../assets/images/pen_edit.svg";

// Types
import {
  curriculumStateModel,
  curriculumDetailsSkill,
} from "../../../../../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";
import pathOr from "ramda/src/pathOr";
import CurriculumDetailsBox from "../../../../../../../Curriculum/Details/Box";

interface Props {
  questionID: string;
  curriculum: curriculumStateModel;
  setSelectedSkills: (id: string[]) => any;
  selectedSkills: string[];
  unmount: () => any;
}

export const QuestionCurriculumSelectSkills = (props: Props) => {
  return (
    <div className={styles.container}>
      <h2>
        <span className={styles.primaryHeadingText}>
          {pathOr(
            "",
            ["curriculum", "curriculum_details", "education_level"],
            props,
          )}
        </span>
      </h2>
      <p>{translate("creator.add-skills-to-a-question-descritpion")}</p>
      <div className={styles.selectedVariant}>
        <span>{translate("creator.selected-variant")}:</span>
        <span>
          {pathOr(
            pathOr(
              "",
              ["curriculum", "curriculum_details", "education_level"],
              props,
            ),
            ["curriculum", "curriculum_details", "variant"],
            props,
          )}
        </span>
        <button type="button" onClick={props.unmount}>
          <PenEditIcon/>
          {translate("global.change")}
        </button>
      </div>
      <ul>
        {pathOr([], ["curriculum", "curriculum_details", "skills"], props).map(
          (skill: curriculumDetailsSkill) => (
            <CurriculumDetailsBox
              key={skill.id}
              id={skill.id}
              body={skill.body}
              children={skill.children}
              isChosen={props.selectedSkills.includes(skill.id)}
              selectedSkills={props.selectedSkills}
              inProgress={props.curriculum.in_progress.update_class_skills}
              onClick={props.setSelectedSkills}
              darkMode
              chosenIfExists
              unfoldOnParentClick
            />
          ),
        )}
      </ul>
    </div>
  );
};

export default QuestionCurriculumSelectSkills;
