import React from "react";

import { ReactComponent as Logo } from "../../../../../../assets/images/wisdy_logo.svg";

import { getLandingPageURL } from "../../../../../../services/common";

import styles from "./styles.module.scss";


export const LessonDetailsNav = () => {
  return (
    <section className={styles.container}>
      <nav className={styles.header}>
        <a
          href={getLandingPageURL()}
          className={styles.logoLink}
        >
          <Logo className={styles.logo}/>
        </a>
      </nav>
    </section>
  );
};

export default LessonDetailsNav;
