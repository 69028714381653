import React from "react";

import Header from "./Header";
import Image from "./Image";
import MultipleChoiceSingleAnswer from "./MultipleChoice/Single";
import MultipleChoiceMultipleAnswers from "./MultipleChoice/Multiple";
import TrueFalse from "./TrueFalse";
import Info from "./Info";

// Types
import {
  questionModel,
  voteForAQuestion as voteForAQuestionFunction,
} from "../../../../../../../models/redux/questionsBase";

import styles from "./styles.module.scss";


interface Props {
  index: number,
  question: questionModel;
  isSelected: any;
  selectQuestion: any;
  voteForAQuestion: voteForAQuestionFunction;
  downvotedQuestionIDs: string[];
  upvotedQuestionIDs: string[];
}

export const Question = (props: Props) => {
  return (
    <section className={styles.questionContainer}>
      <Header
        question={props.question}
        selectQuestion={props.selectQuestion}
        isSelected={props.isSelected}
      />
      <Image
        question={props.question}
        index={props.index}
      />
      <MultipleChoiceSingleAnswer
        question={props.question}
      />
      <MultipleChoiceMultipleAnswers
        question={props.question}
      />
      <TrueFalse
        question={props.question}
      />
      <Info
        question={props.question}
        voteForAQuestion={props.voteForAQuestion}
        downvotedQuestionIDs={props.downvotedQuestionIDs}
        upvotedQuestionIDs={props.upvotedQuestionIDs}
      />
    </section>
  );
};

export default Question;
