import React from "react";
import classNames from "classnames";
import propOr from "ramda/src/propOr";
import pathOr from "ramda/src/pathOr";

import QuestionHeaderPreview from "./Header";
import QuestionMultipleChoiceSingleAnswerPreview from "./MultipleChoice/Single";
import QuestionMultipleChoiceMultipleAnswersPreview from "./MultipleChoice/Multiple";
import QuestionTrueFalsePreview from "./TrueFalse";
import QuestionImagePreview from "./Image";
import QuestionNotesPreview from "./Notes";
import QuestionMatchingPreview from "./Online/Matching";

// Types
import {
  questionModel, QuestionTypes,
} from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  question: questionModel;
  index: number;
  isHidden?: boolean;
  showCorrectAnswers?: boolean;
  reloadPreview?: () => any;
  isCompact?: boolean;
}

export const QuestionPreview = (props: Props) => {
  const containerClass = classNames({
    [styles.questionContainer]: true,
    "preview_test_question": props.isHidden,
  });

  if (props.question.type === QuestionTypes.MATCHING) {
    return (
      <section
        id={`page_index_${props.index}`}
        className={styles.onlineQuestionContainer}
        data-order={props.question.order}
      >
        <QuestionMatchingPreview question={props.question}/>
      </section>
    );
  }

  return (
    <section
      id={`page_index_${props.index}`}
      className={containerClass}
      data-id={pathOr("", ["question", "id"], props)}
      data-order={props.question.order}
    >
      <QuestionHeaderPreview
        question={props.question}
        isCompact={propOr(false, "isCompact", props)}
      />
      <QuestionImagePreview
        question={props.question}
        index={props.index}
        reloadPreview={props.reloadPreview}
        isCompact={propOr(false, "isCompact", props)}
      />
      <QuestionMultipleChoiceSingleAnswerPreview
        question={props.question}
        showCorrectAnswers={props.showCorrectAnswers}
        isCompact={propOr(false, "isCompact", props)}
      />
      <QuestionMultipleChoiceMultipleAnswersPreview
        question={props.question}
        showCorrectAnswers={props.showCorrectAnswers}
        isCompact={propOr(false, "isCompact", props)}
      />
      <QuestionTrueFalsePreview
        question={props.question}
        showCorrectAnswers={props.showCorrectAnswers}
        isCompact={propOr(false, "isCompact", props)}
      />
      <QuestionNotesPreview question={props.question}/>
    </section>
  );
};

export default QuestionPreview;
