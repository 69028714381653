import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import moment from "moment";
import { Elements, injectStripe } from "react-stripe-elements";
import queryString from "query-string";

import {
  addBillingCard,
  deleteBillingCard,
  resetBillingErrors,
  updateBillingSettings,
  getBillingNextPayment,
} from "../../../../../redux/actions/billing";
import { signInByToken } from "../../../../../redux/actions/auth";
import { showToast } from "../../../../../redux/actions/app";

import PaymentMethods from "./PaymentMethods";
import CreditCardContainer from "./CreditCard";
import CreditCardDisplay from "./CreditCard/Display";

import translate from "../../../../../services/translate";
import { calculateDaysTill } from "../../../../../services/common";

import { ReactComponent as SubscriptionIcon } from "../../../../../assets/images/payments/settings_subscription_box.svg";

// Types
import { Location } from "history";
import { userStateModel } from "../../../../../models/redux/user";
import {
  billingStateModel,
  addBillingCard as addBillingCardFunc,
  deleteBillingCard as deleteBillingCardFunc,
  resetBillingErrors as resetBillingErrorsFunc,
  updateBillingSettings as updateBillingSettingsFunc,
  getBillingNextPayment as getBillingNextPaymentFunc,
} from "../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  user: userStateModel;
  billing: billingStateModel;
  addBillingCard: addBillingCardFunc;
  deleteBillingCard: deleteBillingCardFunc;
  resetBillingErrors: resetBillingErrorsFunc;
  updateBillingSettings: updateBillingSettingsFunc;
  getBillingNextPayment: getBillingNextPaymentFunc;
  stripe?: any;
  showToast: any;
  signInByToken: () => {};
}

export const ProfileEditSubscription = (props: Props) => {
  const [daysTillOver, setDaysTillOver] = useState<number>(0);
  const [paymentMethods, setPaymentMethods] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  const defualtSubscriptionPeriod = 30;

  useEffect(() => {
    calculateDaysTillOver();
    setLoaderWidth();

    const parsed = queryString.parse(props.location.search);
    if (parsed.source && parsed.client_secret) {
      props.stripe
        .retrieveSource({
          id: parsed.source,
          client_secret: parsed.client_secret,
        })
        .then((result: any) => {
          if (
            result.source.status !== "failed" &&
            result.source.status !== "canceled"
          ) {
            setTimeout(() => {
              props.signInByToken();
              props.getBillingNextPayment();
            }, 5000);
            props.showToast({
              text: "profile.payment-has-been-processed",
              status: "success",
            });
            return;
          }
          props.showToast({
            text: "profile.payment-has-not-been-processed",
            status: "error",
          });
        });
    }

    return () => {
      props.resetBillingErrors();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateDaysTillOver();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.access.expires_at]);

  useEffect(() => {
    setSelectedPaymentMethod("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.card_info.last4]);

  useEffect(() => {
    setLoaderWidth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysTillOver]);

  const setLoaderWidth = () => {
    const loader = document.querySelector(
      "[class*=daysTillOver] i > i",
    ) as HTMLElement;

    if (loader) {
      if (isMoreThanDaysLeft(defualtSubscriptionPeriod)) {
        loader.style.width = "100%";
        return;
      }
      loader.style.width = `${Math.ceil(
        (daysTillOver * 100) / defualtSubscriptionPeriod,
      )}%`;
    }
  };

  const calculateDaysTillOver = () => {
    const expires_at = moment(props.user.access.expires_at, "YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    const daysTillSubscriptionOver = Math.ceil(
      calculateDaysTill(expires_at, today),
    );

    if (daysTillSubscriptionOver >= 0) {
      setDaysTillOver(daysTillSubscriptionOver);
    }
  };

  const isMoreThanDaysLeft = (days: number) => Number(daysTillOver) > days;

  return (
    <div className={styles.boxSubscription}>
      <h3>{translate("profile.subscription")}</h3>
      <div className={styles.content}>
        <p>
          {props.user.access.full_access
            ? translate("profile.your-subscription-is-active")
            : translate("profile.your-subscription-is-not-active")}
        </p>
        <div className={styles.daysTillOver}>
          <div className={styles.heading}>
            <p>{translate("profile.days-till-subscription-over")}:</p>
            <div>
              <span>{daysTillOver}</span>
              <span>
                &nbsp;
                {Number(daysTillOver) !== 1
                  ? translate("profile.days")
                  : translate("profile.day")}
              </span>
            </div>
          </div>
          <i>
            <i/>
          </i>
        </div>
        <p className={styles.subscriptionDescription}>
          {props.user.access.full_access
            ? translate("profile.active-subscription-description")
            : translate("profile.inactive-subscription-description")}
        </p>
        {paymentMethods ? (
          <Elements>
            <PaymentMethods
              pickPaymentMethod={(value: string) => {
                setSelectedPaymentMethod(value);
                setPaymentMethods(false);
              }}
              location={props.location}
              user={props.user}
              billing={props.billing}
            />
          </Elements>
        ) : (
          <>
            {!selectedPaymentMethod && !props.user.card_info.brand && (
              <button type="button" onClick={() => setPaymentMethods(true)}>
                <span>{translate("profile.choose-payment-method")}</span>
              </button>
            )}
          </>
        )}
      </div>
      {selectedPaymentMethod === "credit-card" && (
        <CreditCardContainer
          addBillingCard={props.addBillingCard}
          user={props.user}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setPaymentMethods={setPaymentMethods}
          resetBillingErrors={props.resetBillingErrors}
          updateBillingSettings={props.updateBillingSettings}
        />
      )}
      {props.user.card_info.brand &&
        !paymentMethods &&
        !selectedPaymentMethod && (
        <CreditCardDisplay
          user={props.user}
          setPaymentMethods={setPaymentMethods}
          deleteBillingCard={props.deleteBillingCard}
        />
      )}
      {!paymentMethods &&
        !selectedPaymentMethod &&
        !props.user.card_info.brand && (
        <SubscriptionIcon className={styles.bottomIcon}/>
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  user: userStateModel;
  billing: billingStateModel;
}) => ({
  user: state.user,
  billing: state.billing,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addBillingCard,
      deleteBillingCard,
      resetBillingErrors,
      showToast,
      signInByToken,
      updateBillingSettings,
      getBillingNextPayment,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectStripe(ProfileEditSubscription));
