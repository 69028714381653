import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { signOut } from "../../../redux/actions/auth";

import { ReactComponent as Logo } from "../../../assets/images/wisdy_logo.svg";
import { ReactComponent as Avatar } from "../../../assets/images/avatar.svg";
import { ReactComponent as EllipsesSidebar } from "../../../assets/images/ellipses_sidebar.svg";
import { ReactComponent as AboutIcon } from "../../../assets/images/header/about.svg";
import { ReactComponent as EditProfileIcon } from "../../../assets/images/header/edit_profile.svg";
// import { ReactComponent as HelpIcon } from "../../../assets/images/header/help.svg";
import { ReactComponent as PrivacyPolicyIcon } from "../../../assets/images/header/privacy_policy.svg";
import { ReactComponent as SignOutIcon } from "../../../assets/images/header/sign_out.svg";
import { ReactComponent as TermsAndConditionsIcon } from "../../../assets/images/header/terms_and_conditions.svg";
// import { ReactComponent as TestsBaseIcon } from "../../../assets/images/header/tests_base.svg";

import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";
import { wisdyInstagramURL } from "../../../services/common/urls";

// Types
import { History } from "history";
import { userStateModel } from "../../../models/redux/user";
import { signOut as signOutFunction } from "../../../models/redux/auth";
import { classModel } from "../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
  signOut: signOutFunction;
  history: History;
  classes: classModel[];
}

export const Header = (props: Props) => {
  const [showDropdown, toggleDropdown] = useState(false);

  const isActive = classNames({
    [styles.active]: showDropdown,
  });

  // TODO: uncomment Help list item (and imported icon) when there will be created view for that section.

  return (
    <section className={styles.container}>
      <nav className={styles.header}>
        <Link to="/" className={styles.logoLink}>
          <Logo className={styles.logo}/>
        </Link>
        <OutsideClickHandler
          onOutsideClick={(e: any) => {
            outsideClickHelper(e, "dropdown", () => toggleDropdown(false));
          }}
        >
          <button onClick={() => toggleDropdown(!showDropdown)}>
            <Avatar className={styles.avatar}/>
            <EllipsesSidebar className={isActive}/>
          </button>
        </OutsideClickHandler>
      </nav>
      {showDropdown && (
        <div className={styles.dropdown}>
          <a href={wisdyInstagramURL} target="_blank" rel="noopener noreferrer">
            <AboutIcon/>
            <span>{translate("global.about")}</span>
          </a>
          <a
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrivacyPolicyIcon/>
            <span>{translate("global.privacy-policy")}</span>
          </a>
          <a
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms-of-use`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TermsAndConditionsIcon/>
            <span>{translate("global.terms-of-use")}</span>
          </a>
          {/* <a
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <HelpIcon/>
            <span>{translate("global.help")}</span>
          </a> */}
          <Link to="/profile/edit">
            <EditProfileIcon/>
            <span>
              {translate("global.edit")} {translate("global.profile")}
            </span>
          </Link>
          {/* <Link to="/tests-base">
            <TestsBaseIcon/>
            <span>{translate("global.tests-base")}</span>
          </Link> */}
          <button className={styles.signOutButton} onClick={props.signOut}>
            <SignOutIcon/>
            <span>{translate("global.sign-out")}</span>
          </button>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: { user: userStateModel }) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      signOut,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
