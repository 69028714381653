import {
  ofType,
  ActionsObservable,
  Epic,
  StateObservable,
} from "redux-observable";
import { switchMap, pluck, debounceTime, map } from "rxjs/operators";
import {
  getSubjectsSuccess,
  getSubjectsFailure,
  getSubjects as getFreshSubjects,
  updateSubjectsSuccess,
  createClassSuccess,
  createClassFailure,
  getClassesSuccess,
  getClassesFailure,
  getClasses as getClassesAction,
} from "../../actions/teacher";

import { apiCall, getTokenFromState } from "../../../services/api";

// Types
import {
  getSubjectsAction,
  updateSubjectsAction,
  createClassAction,
  createClassPayload,
  getClassesAction as getClassesActionModel,
  createClassSuccessAction,
} from "../../../models/redux/teacher";
import { XHRPayload } from "../../../models/common";

// Subjects

export const getSubjectsEpic: Epic = (
  action$: ActionsObservable<getSubjectsAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GET_SUBJECTS"),
    switchMap(() => getSubjects(state$)),
  );

export const getSubjects = (state$: StateObservable<void>) =>
  apiCall(
    "/teachers/subjects",
    "GET",
    {},
    (data: any) => getSubjectsSuccess(data.response),
    getSubjectsFailure,
    getTokenFromState(state$),
  );

export const updateSubjectsEpic: Epic = (
  action$: ActionsObservable<updateSubjectsAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("UPDATE_SUBJECTS"),
    debounceTime(500),
    pluck("payload"),
    switchMap((payload: string[]) => updateSubjects(payload, state$)),
  );

export const updateSubjects = (
  payload: string[],
  state$: StateObservable<void>,
) => {
  return apiCall(
    "/teachers/subjects",
    "PATCH",
    { subjects: payload },
    updateSubjectsSuccess,
    getFreshSubjects,
    getTokenFromState(state$),
  );
};

// Get Class

export const getClassesEpic: Epic = (
  action$: ActionsObservable<getClassesActionModel>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("GET_CLASSES"),
    switchMap(() => getClasses(state$)),
  );

export const getClasses = (state$: StateObservable<void>) => {
  return apiCall(
    "/teachers/classes",
    "GET",
    {},
    (data: XHRPayload) => getClassesSuccess(data.response),
    getClassesFailure,
    getTokenFromState(state$),
  );
};

// Create Class

export const createClassEpic: Epic = (
  action$: ActionsObservable<createClassAction>,
  state$: StateObservable<void>,
) =>
  action$.pipe(
    ofType("CREATE_CLASS"),
    switchMap((data) => createClass(data.payload, state$, data.callback)),
  );

export const createClass = (
  payload: createClassPayload,
  state$: StateObservable<void>,
  callback?: (data: any) => any,
) => {
  return apiCall(
    "/teachers/classes",
    "POST",
    {
      name: payload.name,
      number_of_students: payload.number_of_students,
      ...(payload.subject_id ? { subject_id: payload.subject_id } : {}),
    },
    (data: XHRPayload) => {
      return createClassSuccess(
        data.response,
        payload.history,
        callback ? () => callback(data.response) : undefined,
      );
    },
    createClassFailure,
    getTokenFromState(state$),
  );
};

export const createClassSuccessEpic: Epic = (
  action$: ActionsObservable<createClassSuccessAction>,
) => action$.pipe(ofType("CREATE_CLASS_SUCCESS"), map(getClassesAction));
