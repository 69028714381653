import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";

import equals from "ramda/src/equals";
import pathOr from "ramda/src/pathOr";

import TestsList from "./List";

import { getSubjects } from "../../../../redux/actions/teacher";
import { getClass, unmountClass } from "../../../../redux/actions/class";
import { deleteTest } from "../../../../redux/actions/test";

import translate from "../../../../services/translate";

// Types
import { TestStatusTypes } from "../../../../models/redux/test";
import { History } from "history";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../../models/redux/teacher";
import {
  classStateModel,
  getClass as getClassFunction,
} from "../../../../models/redux/class";
import { deleteTest as deleteTestFunction } from "../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  match: {
    params: {
      classID: string;
      id?: string;
    };
  };
  getSubjects: getSubjectsFunction;
  teacher: teacherStateModel;
  getClass: getClassFunction;
  deleteTest: deleteTestFunction;
  class: classStateModel;
  unmountClass: () => {};
}

export const TestsClass = (props: Props) => {
  const classID = pathOr("", ["match", "params", "classID"], props);
  const [selectedFilterItems, updateSelectedFilterItems] = useState<string[]>(
    [],
  );

  const selectedClass = props.teacher.classes.find(
    (classItem) => classItem.id === classID,
  );

  useEffect(() => {
    props.getClass({ id: `${props.match.params.id}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    props.getSubjects();

    return () => {
      props.unmountClass();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedFilterItems.length) {
      const teacherSubjects = props.teacher.subjects
        .filter((item) => item.is_chosen)
        .map((subject) => ({
          id: subject.id,
          name: subject.name,
          type: subject.type,
        }));
      if (teacherSubjects.length) {
        const typesOfTeacherSubjects = teacherSubjects.map((item) => item.type);
        if (!equals(typesOfTeacherSubjects, selectedFilterItems)) {
          updateSelectedFilterItems(typesOfTeacherSubjects);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.class.tests, props.teacher.subjects]);

  useEffect(() => {
    if (selectedClass && selectedClass.id && !selectedClass.subject.id) {
      props.history.replace(`/pick-up-subjects/${classID}`);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  // const isFilterItemSelected = (type: string) =>
  //   selectedFilterItems.includes(type);

  // const changeSelectedFilterItems = (type: string) => {
  //   if (!isFilterItemSelected(type)) {
  //     const selectedItems = uniq(selectedFilterItems.concat(type));
  //     updateSelectedFilterItems(selectedItems);
  //     return;
  //   }

  //   const selectedItems = reject(
  //     (val: any) => val === type,
  //     selectedFilterItems
  //   );

  //   updateSelectedFilterItems(selectedItems);
  // };

  const getLastCompletedTestsList = () => {
    return props.class.tests.filter(
      (test) => test.status === TestStatusTypes.COMPLETED,
    );
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h3>
          <Link to={`/class/${props.class.id}`}>{props.class.name}</Link>
          &nbsp;-&nbsp;
          <span>{translate("global.tests")}</span>
        </h3>
      </div>
      {!props.class.tests.length && (
        <div className={styles.placeholder}>
          <div className={styles.createTestContainer}>
            <h3>{translate("global.this-place-is-empty")}</h3>
            <Link
              to={`/test/creator?classID=${props.match.params.id}`}
              className={styles.createTestLink}
            >
              <span>+</span>
              <span>
                {translate("global.create")} {translate("global.new")}{" "}
                {translate("global.test")}
              </span>
            </Link>
          </div>
        </div>
      )}
      {!!getLastCompletedTestsList().length && (
        <TestsList
          history={props.history}
          heading={translate("tests.last-completed-tests")}
          tests={getLastCompletedTestsList()}
          deleteTest={props.deleteTest}
          classID={pathOr("", ["match", "params", "id"], props)}
          showPlaceholder={false}
          completedTests
        />
      )}
      {!!props.class.tests.length && (
        <TestsList
          history={props.history}
          heading={translate("tests.all-tests-prefix")}
          tests={props.class.tests}
          deleteTest={props.deleteTest}
          classID={pathOr("", ["match", "params", "id"], props)}
          showPlaceholder
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  classReducer: classStateModel;
}) => ({
  teacher: state.teacher,
  class: state.classReducer,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
      getClass,
      unmountClass,
      deleteTest,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestsClass);
