import propOr from "ramda/src/propOr";
import path from "ramda/src/path";
import isEmpty from "ramda/src/isEmpty";
import {
  getTokenFromHeaders,
  saveLanguageInLocalStorage,
  saveTokenInLocalStorage,
  setTokenInCookies,
} from "../../../services/api";

// Types
import {
  signUpAsGuestAction,
  signUpAsGuestSuccessAction,
  signUpAsGuestSuccessPayload,
  signUpAsGuestFailureAction,
  upgradeGuestAccountAction,
  upgradeGuestAccountSuccessAction,
  upgradeGuestAccountSuccessPayload,
  upgradeGuestAccountFailureAction,
  upgradeGuestAccountPayload,
} from "../../../models/redux/demo";
import { XHRPayload, ErrorsPayload } from "../../../models/common";
import { History } from "history";

// Sign Up As Guest

export const signUpAsGuest = (): signUpAsGuestAction => {
  return {
    type: "SIGN_UP_AS_GUEST",
  };
};

export const signUpAsGuestSuccess = (payload: XHRPayload): signUpAsGuestSuccessAction => {
  const data = path(["response", "data", "session"], payload) as signUpAsGuestSuccessPayload;
  const token = getTokenFromHeaders(payload.xhr);

  if (propOr("", "language", data)) {
    saveLanguageInLocalStorage(data.language);
  }

  return {
    type: "SIGN_UP_AS_GUEST_SUCCESS",
    payload: {
      token: token,
      email: propOr("", "email", data),
      roles: propOr([], "roles", data),
      card_info: !isEmpty(propOr({}, "card_info", data)) ? data.card_info : { last4: "", brand: "" },
      access: propOr(
        {
          expires_at: "",
          full_access: false,
        },
        "access",
        data,
      ),
      app: propOr("", "app", data),
      currency: propOr("", "currency", data),
      language: propOr("", "language", data),
    },
  };
};

export const signUpAsGuestFailure = (payload: ErrorsPayload): signUpAsGuestFailureAction => {
  return {
    type: "SIGN_UP_AS_GUEST_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Upgrade Guest Account

export const upgradeGuestAccount = (payload: upgradeGuestAccountPayload): upgradeGuestAccountAction => {
  return {
    type: "UPGRADE_GUEST_ACCOUNT",
    payload,
  };
};

export const upgradeGuestAccountSuccess = (payload: XHRPayload, history: History): upgradeGuestAccountSuccessAction => {
  const data = path(["response", "data", "session"], payload) as upgradeGuestAccountSuccessPayload;
  const token = getTokenFromHeaders(payload.xhr);
  const roles = propOr([], "roles", data) as string[];

  saveTokenInLocalStorage(token);
  if (propOr("", "language", data)) {
    saveLanguageInLocalStorage(data.language);
  }

  if (roles && !roles.includes("guest")) {
    setTokenInCookies(token);
  }

  history.push("/");

  return {
    type: "UPGRADE_GUEST_ACCOUNT_SUCCESS",
    payload: {
      token: token,
      email: propOr("", "email", data),
      roles,
      card_info: !isEmpty(propOr({}, "card_info", data)) ? data.card_info : { last4: "", brand: "" },
      access: propOr(
        {
          expires_at: "",
          full_access: false,
        },
        "access",
        data,
      ),
      app: propOr("", "app", data),
      currency: propOr("", "currency", data),
      language: propOr("", "language", data),
    },
  };
};

export const upgradeGuestAccountFailure = (payload: ErrorsPayload): upgradeGuestAccountFailureAction => {
  return {
    type: "UPGRADE_GUEST_ACCOUNT_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};
