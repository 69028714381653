import React, { useState } from "react";

import QuestionHeaderOnline from "../Header";
import QuestionImageOnline from "../Image";

import QuestionOpenEditorOnline from "./Editor";

// Types
import { History, Location } from "history";
import {
  questionModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  history: History;
  location: Location;
  question: questionModel;
  onAnswerChoiceChange: (answerHTML: string) => any;
  isReadOnly?: boolean;
}

export const QuestionOpenOnline = (props: Props) => {
  const [answerBody, setAnswerBody] = useState({ html: "", plain: "" });

  return (
    <div className={styles.mainContainer}>
      <QuestionHeaderOnline question={props.question}/>
      <QuestionImageOnline question={props.question}/>
      {!props.isReadOnly &&
        <QuestionOpenEditorOnline
          history={props.history}
          location={props.location}
          value={answerBody}
          handleOnChange={(object: any) => {
            setAnswerBody(object);
            props.onAnswerChoiceChange(object.html as string);
          }}
        />
      }
    </div>
  );
};

export default QuestionOpenOnline;
