import React from "react";
import classNames from "classnames";

import translate from "../../../services/translate";

import { ReactComponent as Check } from "../../../assets/images/check.svg";
import { ReactComponent as Warning } from "../../../assets/images/warning.svg";

// Types
import { toastModel } from "../../../models/redux/app";

import styles from "./styles.module.scss";

interface Props {
  toast: toastModel;
}

const Toast = (props: Props) => {
  const isError = () => props.toast.status === "error";
  const isSuccess = () => props.toast.status === "success";
  const isInfo = () => props.toast.status === "info";

  const toastClass = classNames({
    [styles.container]: true,
    [styles.success]: isSuccess(),
    [styles.error]: isError(),
    [styles.info]: isInfo(),
  });

  return (
    <div className={toastClass}>
      {isSuccess() && <Check/>}
      {isError() && <Warning/>}
      {props.toast.customIcon && props.toast.customIcon()}
      <span>{translate(props.toast.text)}</span>
    </div>
  );
};

export default Toast;
