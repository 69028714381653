import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { signIn, unmountAuth } from "../../redux/actions/auth";
import AuthContainer from "../Common/Auth/Container";
import Input from "../Common/Input";
import { getErrorMessage, getLandingPageURL } from "../../services/common";
import translate from "../../services/translate";

// Types
import { Location, History } from "history";
import {
  authStateModel,
  signIn as signInFunction,
} from "../../models/redux/auth";

import styles from "./styles.module.scss";

interface Props {
  errors: any[];
  isLoading: boolean;
  signIn: signInFunction;
  unmountAuth: () => {};
  location: Location;
  history: History;
  isAuthenticated: null | boolean;
}

export const SignIn = (props: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    return () => {
      props.unmountAuth();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.push("/");
    }
  }, [props]);

  const isEmailEmpty = () => !email.length;
  const isPasswordEmpty = () => !password.length;
  const isSubmitButtonDisabled = () => {
    return isEmailEmpty() || isPasswordEmpty() || props.isLoading;
  };

  return (
    <AuthContainer heading={translate("sign_in.login")} isSignIn>
      <form
        className={styles.form}
        onSubmit={(e: any) => {
          e.preventDefault();
          props.signIn({ email, password });
        }}
      >
        <Input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          label={translate("global.email-address")}
          error={getErrorMessage(props.errors, "email")}
        />
        <Input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          label={translate("global.password")}
          error={getErrorMessage(props.errors, "password")}
        />
        <div className={styles.linkContainer}>
          <Link to="/forgot-password">
            {translate("global.forgot-password")}
          </Link>
          <Link to="/sign-up">
            {translate("sign_in.dont-have-an-account-first-part")}{" "}
            {translate("sign_in.dont-have-an-account-second-part")}
          </Link>
        </div>
        <div className={`${styles.buttonsContainer} ${styles.submitContainer}`}>
          <button
            type="submit"
            disabled={isSubmitButtonDisabled() || props.isLoading}
            className={props.isLoading ? styles.buttonIsLoading : ""}
          >
            {translate("sign_in.sign-in")}
            {props.isLoading && (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            )}
          </button>
        </div>
        <div className={`${styles.buttonsContainer} ${styles.linkContainer}`}>
          <a href={getLandingPageURL()}>
            {translate("global.back-to-homepage")}
          </a>
        </div>
      </form>
    </AuthContainer>
  );
};

const mapStateToProps = (state: { auth: authStateModel }) => ({
  errors: state.auth.errors,
  isLoading: state.auth.in_progress.general,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      signIn,
      unmountAuth,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
