import React from "react";

import { notesTypes } from "../../../../../../services/common/test";

import TestQuestionTrueFalseCompactPDF from "./TrueFalse/Compact";
import TestQuestionTrueFalsePDF from "./TrueFalse";

import TestQuestionSingleChoiceCompactPDF from "./MultipleChoice/Single/Compact";
import TestQuestionSingleChoicePDF from "./MultipleChoice/Single";

import TestQuestionMultipleChoicePDF from "./MultipleChoice/Multiple";
import TestQuestionMultipleChoiceCompactPDF from "./MultipleChoice/Multiple/Compact";

import TestQuestionBodyPDF from "./Body";
import TestQuestionBodyCompactPDF from "./Body/Compact";

import { ReactComponent as LinesNote } from "../../../../../../assets/images/question_notes/lines.svg";
import { ReactComponent as CheckedNote } from "../../../../../../assets/images/question_notes/checked.svg";
import { ReactComponent as BlankNote } from "../../../../../../assets/images/question_notes/blank.svg";
import { ReactComponent as DottedNote } from "../../../../../../assets/images/question_notes/dotted.svg";
import { ReactComponent as PenmanshipNote } from "../../../../../../assets/images/question_notes/penmanship.svg";

// Types
import {
  QuestionTypes,
  questionModel,
  QuestionSubtype,
} from "../../../../../../models/redux/test";


interface Props {
  questions: questionModel[];
  isCompact: boolean;
}

export const TestQuestionPDF = (props: Props) => {
  const isTrueFalse = (question: questionModel) => {
    return question.type === QuestionTypes.TRUE_FALSE;
  };

  const isMultipleChoice = (question: questionModel) => {
    return question.type === QuestionTypes.MULTIPLE_CHOICE;
  };

  const isSingleSubType = (question: questionModel) => {
    return question.type_data.subtype === QuestionSubtype.SINGLE;
  };

  const isMultipleSubType = (question: questionModel) => {
    return question.type_data.subtype === QuestionSubtype.MULTIPLE;
  };

  const noteSizeArray = (question: questionModel) => Array.from(Array(question.type_data.notes_size).keys());

  return (
    <>
      {props.questions.map((question: questionModel) => (
        <div
          key={`${question.id}`}
          style={{
            margin: "0",
            width: "100%",
            padding: "0",
            fontFamily: "Lato",
          }}
        >
          {props.isCompact ?
            <TestQuestionBodyCompactPDF
              question={question}
            /> :
            <TestQuestionBodyPDF
              question={question}
            />
          }
          {question.image &&
            (props.isCompact ?
              <img
                style={{
                  alignSelf: "flex-start",
                  padding: "0",
                  margin: "0 0 15px 55px",
                }}
                src={question.image}
                alt={`img_${question.id}`}
              /> :
              <img
                style={{
                  alignSelf: "flex-start",
                  padding: "0",
                  margin: "0 0 25px 71px",
                }}
                src={question.image}
                alt={`img_${question.id}`}
              />
            )
          }
          {(isMultipleChoice(question) && isSingleSubType(question)) &&
            (props.isCompact ?
              <TestQuestionSingleChoiceCompactPDF
                question={question}
              /> :
              <TestQuestionSingleChoicePDF
                question={question}
              />
            )
          }
          {(isMultipleChoice(question) && isMultipleSubType(question)) &&
            (props.isCompact ?
              <TestQuestionMultipleChoiceCompactPDF
                question={question}
              /> :
              <TestQuestionMultipleChoicePDF
                question={question}
              />
            )
          }
          {isTrueFalse(question) &&
            (props.isCompact ?
              <TestQuestionTrueFalseCompactPDF
                question={question}
              /> :
              <TestQuestionTrueFalsePDF
                question={question}
              />
            )
          }
          {!!question.type_data.notes_size &&
            <div
              style={{
                width: "100%",
                maxWidth: "95%",
                margin: "10px auto",
              }}
            >
              {question.type_data.notes_type === notesTypes.checked &&
                noteSizeArray(question).map((item: number, index: number) => {
                  if (index) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          margin: "-1px auto 0 auto",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <CheckedNote
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <CheckedNote
                      key={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: "0 auto",
                        pageBreakInside: "avoid",
                      }}
                    />
                  );
                })
              }
              {question.type_data.notes_type === notesTypes.lines &&
                noteSizeArray(question).map((item: number, index: number) => {
                  if (index) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          margin: "-1px auto 0 auto",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <LinesNote
                          key={item}
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <LinesNote
                      key={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: "0",
                        pageBreakInside: "avoid",
                      }}
                    />
                  );
                })
              }
              {question.type_data.notes_type === notesTypes.penmanship &&
                noteSizeArray(question).map((item: number, index: number) => {
                  if (index) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          margin: "-1px auto 0 auto",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <PenmanshipNote
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <PenmanshipNote
                      key={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: "0 auto",
                        pageBreakInside: "avoid",
                      }}
                    />
                  );
                })
              }
              {question.type_data.notes_type === notesTypes.dotted &&
                noteSizeArray(question).map((item: number, index: number) => {
                  if (index) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          margin: "-1px auto 0 auto",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <DottedNote
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <DottedNote
                      key={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: "0 auto",
                        pageBreakInside: "avoid",
                      }}
                    />
                  );
                })
              }
              {question.type_data.notes_type === notesTypes.blank &&
                noteSizeArray(question).map((item: number, index: number) => {
                  if (!index && question.type_data.notes_size > 1) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "0 auto",
                          pageBreakInside: "avoid",
                          borderTop: "1px solid #AFAFAF",
                          borderLeft: "1px solid #AFAFAF",
                          borderRight: "1px solid #AFAFAF",
                        }}
                      >
                        <BlankNote
                          key={item}
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                            opacity: "0",
                          }}
                        />
                      </div>
                    );
                  } else if (question.type_data.notes_size === 1) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "0 auto",
                          pageBreakInside: "avoid",
                          borderTop: "1px solid #AFAFAF",
                          borderLeft: "1px solid #AFAFAF",
                          borderRight: "1px solid #AFAFAF",
                          borderBottom: "1px solid #AFAFAF",
                        }}
                      >
                        <BlankNote
                          key={item}
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                            opacity: "0",
                          }}
                        />
                      </div>
                    );
                  } else if ((
                    index === (question.type_data.notes_size -1) &&
                    question.type_data.notes_size > 1
                  )) {
                    return (
                      <div
                        key={item}
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "-1px auto 0 auto",
                          pageBreakInside: "avoid",
                          borderBottom: "1px solid #AFAFAF",
                          borderLeft: "1px solid #AFAFAF",
                          borderRight: "1px solid #AFAFAF",
                        }}
                      >
                        <BlankNote
                          key={item}
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0",
                            opacity: "0",
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      key={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        pageBreakInside: "avoid",
                        borderLeft: "1px solid #AFAFAF",
                        borderRight: "1px solid #AFAFAF",
                      }}
                    >
                      <BlankNote
                        key={item}
                        style={{
                          width: "100%",
                          height: "auto",
                          opacity: "0",
                          pageBreakInside: "avoid",
                        }}
                      />
                    </div>
                  );
                })
              }
            </div>
          }
        </div>
      ))}
    </>
  );
};

export default TestQuestionPDF;
