import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../../../../../Common/Input";
import CountriesSelect from "../../../../../Common/CountriesSelect";

import translate from "../../../../../../services/translate";

import {
  updateInvoiceData,
  getInvoiceData,
} from "../../../../../../redux/actions/billing";

import { getErrorMessage } from "../../../../../../services/common";
import {
  countries,
  getCountryName,
} from "../../../../../../services/common/countries";

// Types
import {
  billingStateModel,
  updateInvoiceData as updateInvoiceDataFunc,
  getInvoiceData as getInvoiceDataFunc,
} from "../../../../../../models/redux/billing";
import { userStateModel } from "../../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  billing: billingStateModel;
  updateInvoiceData: updateInvoiceDataFunc;
  getInvoiceData: getInvoiceDataFunc;
  hide: any;
  user: userStateModel;
  callback?: any;
}

export const InvoiceDetails = (props: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [flatNumber, setFlatNumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tinNumber, setTinNumber] = useState("");

  const isLoading = () => props.billing.in_progress.update_invoice_data;

  useEffect(() => {
    props.getInvoiceData();

    return () => {
      props.getInvoiceData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const country = countries.find(
      (item: any) =>
        item.code.toLocaleLowerCase() ===
        props.billing.invoice_data.address.country.toLocaleLowerCase(),
    ) as any;

    if (country) {
      setCountry(getCountryName(country, props.user.language));
      setCountryCode(props.billing.invoice_data.address.country);
    }
    setFirstName(props.billing.invoice_data.first_name);
    setLastName(props.billing.invoice_data.last_name);
    setStreetName(props.billing.invoice_data.address.street_name);
    setBuildingNumber(props.billing.invoice_data.address.building_number);
    setFlatNumber(props.billing.invoice_data.address.flat_number);
    setCity(props.billing.invoice_data.address.city);
    setPostalCode(props.billing.invoice_data.address.postal_code);
    setCompanyName(props.billing.invoice_data.company_name);
    setTinNumber(props.billing.invoice_data.tin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.billing.invoice_data]);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        <span>{translate("profile.invoice-details-1")}</span>{" "}
        {translate("profile.invoice-details-2-transfer")}
      </h4>
      <h5>{translate("profile.fill-out-form-below")}:</h5>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          props.updateInvoiceData(
            {
              address: {
                building_number: buildingNumber,
                city,
                country: countryCode.toLocaleLowerCase(),
                flat_number: flatNumber,
                postal_code: postalCode,
                street_name: streetName,
              },
              company_name: companyName,
              tin: tinNumber,
              first_name: firstName,
              last_name: lastName,
            },
            () => {
              props.hide();
              if (props.callback) {
                props.callback(e);
              }
            },
          );
        }}
      >
        <div className={styles.row}>
          <div>
            <Input
              id="first_name"
              type="text"
              name="first_name"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              label={translate("profile.first-name")}
              error={getErrorMessage(props.billing.errors, "first_name")}
            />
          </div>
          <div>
            <Input
              id="last_name"
              type="text"
              name="last_name"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
              label={translate("profile.last-name")}
              error={getErrorMessage(props.billing.errors, "last_name")}
            />
          </div>
        </div>
        <h5>
          {translate("profile.additional-info")} ({translate("global.optional")}
          ):
        </h5>
        <Input
          id="street_name"
          type="text"
          name="street_name"
          value={streetName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setStreetName(e.target.value)
          }
          label={translate("profile.streetname")}
          error={getErrorMessage(props.billing.errors, "street_name")}
        />
        <div className={styles.row}>
          <div>
            <Input
              id="building_number"
              type="text"
              name="building_number"
              value={buildingNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBuildingNumber(e.target.value)
              }
              label={translate("profile.building-number")}
              error={getErrorMessage(props.billing.errors, "building_number")}
            />
          </div>
          <div>
            <Input
              id="flat_number"
              type="text"
              name="flat_number"
              value={flatNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFlatNumber(e.target.value)
              }
              label={translate("profile.flat-number")}
              error={getErrorMessage(props.billing.errors, "flat_number")}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <Input
              id="city"
              type="text"
              name="city"
              value={city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCity(e.target.value)
              }
              label={translate("profile.city")}
              error={getErrorMessage(props.billing.errors, "city")}
            />
          </div>
          <CountriesSelect
            countryCode={countryCode}
            country={country}
            setCountry={setCountry}
            setCountryCode={setCountryCode}
            user={props.user}
            errors={getErrorMessage(props.billing.errors, "country")}
          />
        </div>
        <Input
          id="postal_code"
          type="text"
          name="postal_code"
          value={postalCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPostalCode(e.target.value)
          }
          label={translate("profile.postal-code")}
          error={getErrorMessage(props.billing.errors, "postal_code")}
        />
        <Input
          id="company_name"
          type="text"
          name="company_name"
          value={companyName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCompanyName(e.target.value)
          }
          label={translate("profile.company-name")}
          error={getErrorMessage(props.billing.errors, "company_name")}
        />
        <Input
          id="tin_number"
          type="text"
          name="tin_number"
          value={tinNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTinNumber(e.target.value)
          }
          label={translate("profile.tin-number")}
          error={getErrorMessage(props.billing.errors, "tin")}
        />
        <button type="submit" disabled={isLoading()}>
          {isLoading() ? (
            <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
          ) : (
            <span>{translate("global.next")}</span>
          )}
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state: {
  billing: billingStateModel;
  user: userStateModel;
}) => ({
  billing: state.billing,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateInvoiceData,
      getInvoiceData,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
