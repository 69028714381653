import { History } from "history";
import { ErrorObject } from "../../common";

export const lessonStatus = {
  public: "public",
  private: "private",
};

export interface lessonStateModel {
  topic: string;
  description: string;
  goal: string;
  tasks: string;
  homework: string;
  success_criteria: string;
  status: string;
  subject: string;
  subject_name: string;
  access_token: string;
  class_name: string;
  in_progress: {
    create_lesson: boolean;
    update_lesson: boolean;
  };
  errors: ErrorObject[];
};

export interface lessonActionModel {
  type: string;
  payload?: any;
};

// Create Lesson

const CREATE_LESSON = "CREATE_LESSON";
const CREATE_LESSON_SUCCESS = "CREATE_LESSON_SUCCESS";
const CREATE_LESSON_FAILURE = "CREATE_LESSON_FAILURE";

export interface createLessonPayload {
  class_id: string;
  subject_id: string;
  topic: string;
  history: History;
  description?: string;
  tasks?: string;
  goal?: string;
  homework?: string;
  success_criteria?: string;
};

export interface createLessonSuccessPayload {
  lesson_id: string;
  class_id: string;
  history: History;
};

export interface createLesson {
  ({ class_id, subject_id, description, topic, history, tasks, goal, homework, success_criteria }: createLessonPayload): any;
};

export interface createLessonAction {
  type: typeof CREATE_LESSON;
  payload: createLessonPayload;
};

export interface createLessonSuccessAction {
  type: typeof CREATE_LESSON_SUCCESS;
};

export interface createLessonFailureAction {
  type: typeof CREATE_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Update Lesson

const UPDATE_LESSON = "UPDATE_LESSON";
const UPDATE_LESSON_SUCCESS = "UPDATE_LESSON_SUCCESS";
const UPDATE_LESSON_FAILURE = "UPDATE_LESSON_FAILURE";

export interface updateLessonPayload {
  class_id: string;
  lesson_id: string;
  topic: string;
  history: History;
  description?: string;
  tasks?: string;
  goal?: string;
  homework?: string;
  success_criteria?: string;
};

export interface updateLessonSuccessPayload {
  lesson_id: string;
  class_id: string;
  history: History;
};

export interface updateLesson {
  ({ class_id, lesson_id, description, topic, history, tasks, goal, homework, success_criteria }: updateLessonPayload): any;
};

export interface updateLessonAction {
  type: typeof UPDATE_LESSON;
  payload: updateLessonPayload;
};

export interface updateLessonSuccessAction {
  type: typeof UPDATE_LESSON_SUCCESS;
};

export interface updateLessonFailureAction {
  type: typeof UPDATE_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Get Lesson

const GET_LESSON = "GET_LESSON";
const GET_LESSON_SUCCESS = "GET_LESSON_SUCCESS";
const GET_LESSON_FAILURE = "GET_LESSON_FAILURE";

export interface getLessonPayload {
  lesson_id: string;
  access_token?: string;
};

export interface getLessonSuccessPayload {
  data: {
    id: string;
    inserted_at: string;
    updated_at: string;
    description: string;
    topic: string;
    tasks: string;
    goal: string;
    homework: string;
    success_criteria: string;
    subject: string;
    subject_name: string;
    class_name: string;
  }
};

export interface getLesson {
  ({ lesson_id, access_token }: getLessonPayload): any;
};

export interface getLessonAction {
  type: typeof GET_LESSON;
  payload: getLessonPayload;
};

export interface getLessonSuccessAction {
  type: typeof GET_LESSON_SUCCESS;
  payload: getLessonSuccessPayload;
};

export interface getLessonFailureAction {
  type: typeof GET_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Delete Lesson

const DELETE_LESSON = "DELETE_LESSON";
const DELETE_LESSON_SUCCESS = "DELETE_LESSON_SUCCESS";
const DELETE_LESSON_FAILURE = "DELETE_LESSON_FAILURE";

export interface deleteLessonPayload {
  lesson_id: string;
  class_id: string;
};

export interface deleteLessonSuccessPayload {
  class_id: string;
};

export interface deleteLesson {
  ({ lesson_id }: deleteLessonPayload, callback?: () => any): any;
};

export interface deleteLessonAction {
  type: typeof DELETE_LESSON;
  payload: deleteLessonPayload;
  callback?: () => any;
};

export interface deleteLessonSuccessAction {
  type: typeof DELETE_LESSON_SUCCESS;
  payload: deleteLessonSuccessPayload;
};

export interface deleteLessonFailureAction {
  type: typeof DELETE_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Generate Lesson's access token

const LESSON_GENERATE_ACCESS_TOKEN = "LESSON_GENERATE_ACCESS_TOKEN";
const LESSON_GENERATE_ACCESS_TOKEN_SUCCESS = "LESSON_GENERATE_ACCESS_TOKEN_SUCCESS";
const LESSON_GENERATE_ACCESS_TOKEN_FAILURE = "LESSON_GENERATE_ACCESS_TOKEN_FAILURE";

export interface lessonGenerateAccessTokenPayload {
  lesson_id: string;
};

export interface lessonGenerateAccessTokenSuccessPayload {
  data: {
    access_token: string;
  };
};

export interface lessonGenerateAccessToken {
  ({ lesson_id }: lessonGenerateAccessTokenPayload, callback?: () => any): any;
};

export interface lessonGenerateAccessTokenAction {
  type: typeof LESSON_GENERATE_ACCESS_TOKEN;
  payload: lessonGenerateAccessTokenPayload;
  callback?: () => any;
};

export interface lessonGenerateAccessTokenSuccessAction {
  type: typeof LESSON_GENERATE_ACCESS_TOKEN_SUCCESS;
  payload: lessonGenerateAccessTokenSuccessPayload;
};

export interface lessonGenerateAccessTokenFailureAction {
  type: typeof LESSON_GENERATE_ACCESS_TOKEN_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Publish Lesson

const PUBLISH_LESSON = "PUBLISH_LESSON";
const PUBLISH_LESSON_SUCCESS = "PUBLISH_LESSON_SUCCESS";
const PUBLISH_LESSON_FAILURE = "PUBLISH_LESSON_FAILURE";

export interface publishLessonPayload {
  lesson_id: string;
};

export interface publishLessonSuccessPayload {
  data: string;
};

export interface publishLesson {
  ({ lesson_id }: publishLessonPayload): any;
};

export interface publishLessonAction {
  type: typeof PUBLISH_LESSON;
  payload: publishLessonPayload;
};

export interface publishLessonSuccessAction {
  type: typeof PUBLISH_LESSON_SUCCESS;
  payload: publishLessonSuccessPayload;
};

export interface publishLessonFailureAction {
  type: typeof PUBLISH_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Unpublish Lesson

const UNPUBLISH_LESSON = "UNPUBLISH_LESSON";
const UNPUBLISH_LESSON_SUCCESS = "UNPUBLISH_LESSON_SUCCESS";
const UNPUBLISH_LESSON_FAILURE = "UNPUBLISH_LESSON_FAILURE";

export interface unpublishLessonPayload {
  lesson_id: string;
};

export interface unpublishLessonSuccessPayload {
  data: string;
};

export interface unpublishLesson {
  ({ lesson_id }: unpublishLessonPayload): any;
};

export interface unpublishLessonAction {
  type: typeof UNPUBLISH_LESSON;
  payload: unpublishLessonPayload;
};

export interface unpublishLessonSuccessAction {
  type: typeof UNPUBLISH_LESSON_SUCCESS;
  payload: unpublishLessonSuccessPayload;
};

export interface unpublishLessonFailureAction {
  type: typeof UNPUBLISH_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Duplicate Lesson

const DUPLICATE_LESSON = "DUPLICATE_LESSON";
const DUPLICATE_LESSON_SUCCESS = "DUPLICATE_LESSON_SUCCESS";
const DUPLICATE_LESSON_FAILURE = "DUPLICATE_LESSON_FAILURE";

export interface duplicateLessonPayload {
  lesson_id: string;
  class_id: string;
};

export interface duplicateLessonSuccessPayload {
  data: {
    id: string;
  };
};

export interface duplicateLesson {
  ({ lesson_id, class_id }: duplicateLessonPayload, callback?: () => any): any;
};

export interface duplicateLessonAction {
  type: typeof DUPLICATE_LESSON;
  payload: duplicateLessonPayload;
  callback?: () => any;
};

export interface duplicateLessonSuccessAction {
  type: typeof DUPLICATE_LESSON_SUCCESS;
  payload: duplicateLessonSuccessPayload;
};

export interface duplicateLessonFailureAction {
  type: typeof DUPLICATE_LESSON_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Other

const UNMOUNT_LESSON = "UNMOUNT_LESSON";

export interface unmountLessonAction {
  type: typeof UNMOUNT_LESSON;
};
