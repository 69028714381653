import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";

import QuestionHeaderOnline from "../../Header";
import QuestionImageOnline from "../../Image";

// Types
import {
  QuestionTypes,
  questionModel,
  QuestionSubtype,
  answerChoiceModel,
} from "../../../../../../../../models/redux/test";

import styles from "../styles.module.scss";


interface Props {
  question: questionModel;
  checkedAnswerChoices: number[];
  onAnswerChoiceClick: (order: number) => any;
  isReadOnly?: boolean;
}

export const QuestionMultipleChoiceSingleAnswerOnline = (props: Props) => {
  const isMultipleChoice = () => {
    return props.question.type === QuestionTypes.MULTIPLE_CHOICE;
  };

  const isSingleSubType = () => {
    return props.question.type_data.subtype === QuestionSubtype.SINGLE;
  };

  const multipleChoiceAnswerClass = (order: number) => classNames({
    [styles.multipleChoiceAnswerCorrect]: props.checkedAnswerChoices.includes(order),
  });

  return (
    <div className={styles.mainContainer}>
      <QuestionHeaderOnline question={props.question}/>
      <QuestionImageOnline question={props.question}/>
      {(isMultipleChoice() && isSingleSubType()) &&
        <div
          data-type="question-answers-mc-sa"
          className={styles.container}
        >
          {props.question.answer_choices.map((item: answerChoiceModel) => (
            <div
              key={`${item.id}_${item.order}`}
              className={styles.listItemContainer}
            >
              <li>
                <div>
                  <div
                    className={styles.inputContainer}
                    onClick={() => {
                      if (props.isReadOnly) {
                        return;
                      }
                      props.onAnswerChoiceClick(item.order);
                    }}
                  >
                    <input
                      type="radio"
                      name=""
                      checked={props.checkedAnswerChoices.includes(item.order)}
                      value=""
                      onChange={() => {}}
                    />
                    <span className={multipleChoiceAnswerClass(item.order)}>{"ABCDEF"[item.order - 1]}</span>
                  </div>
                  <div
                    className={styles.answerName}
                    dangerouslySetInnerHTML={{ __html: `${item.name_html ? DOMPurify.sanitize(item.name_html) : DOMPurify.sanitize(item.name)}` }}
                  ></div>
                </div>
              </li>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default QuestionMultipleChoiceSingleAnswerOnline;
