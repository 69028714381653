export const copyToClipboard = (elementID: string, callback?: (params?: any) => any) => {
  const copyText = document.getElementById(elementID) as any;

  if (copyText) {
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    copyText.blur();

    if (callback) {
      callback();
    }
  }
};
