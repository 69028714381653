import React from "react";
import translate from "../../../../../../../../services/translate";
import pathOr from "ramda/src/pathOr";

import Checkbox from "../../../../../../../Common/Checkbox";

// Types
import { QuestionTypes } from "../../../../../../../../models/redux/test";
import { questionModel } from "../../../../../../../../models/redux/questionsBase";

import styles from "./styles.module.scss";

interface Props {
  question: questionModel;
  selectQuestion: any;
  isSelected: boolean;
}

export const Header = (props: Props) => {
  const isTrueFalse = () => {
    return props.question.type === QuestionTypes.TRUE_FALSE;
  };

  const getBody = (props: Props) => {
    if (props.question.body_html) {
      return props.question.body_html;
    }

    return pathOr("", ["question", "body"], props);
  };

  return (
    <div data-type="question-header" className={styles.header}>
      <span className={styles.checkboxContainer}>
        <Checkbox
          onClick={() => props.selectQuestion(props.question.id)}
          checked={props.isSelected}
          noIcon
        />
      </span>
      {isTrueFalse() ? (
        <p className={styles.body}>
          <span>{translate("creator.true-false-desc-1")}</span>
          <span className={styles.bold}>
            &nbsp;{props.question.answer_choices[0].name}&nbsp;
          </span>
          <span>{translate("creator.true-false-desc-2")}</span>
          <span className={styles.bold}>
            &nbsp;{props.question.answer_choices[1].name}&nbsp;
          </span>
          <span>{translate("creator.true-false-desc-3")}</span>
        </p>
      ) : (
        <div
          className={`${styles.body}`}
          dangerouslySetInnerHTML={{ __html: getBody(props) }}
        ></div>
      )}
    </div>
  );
};

export default Header;
