import React from "react";
import translate from "../../../../../../../services/translate";
import DOMPurify from "dompurify";
import pathOr from "ramda/src/pathOr";

// Types
import {
  QuestionTypes,
  questionModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  question: questionModel;
  onBlurHandler?: (e: any) => any;
}

export const QuestionHeaderOnline = (props: Props) => {
  const isTrueFalse = () => {
    return props.question.type === QuestionTypes.TRUE_FALSE;
  };

  return (
    <div data-type="question-header" className={styles.header}>
      <span className={styles.order}>{props.question.order}</span>
      {isTrueFalse() ? (
        <p className={styles.body}>
          <>
            <span>{translate("creator.true-false-desc-1")}</span>
            <span className={styles.bold}>
              &nbsp;{props.question.answer_choices[0].name}&nbsp;
            </span>
            <span>{translate("creator.true-false-desc-2")}</span>
            <span className={styles.bold}>
              &nbsp;{props.question.answer_choices[1].name}&nbsp;
            </span>
            <span>{translate("creator.true-false-desc-3")}</span>
          </>
        </p>
      ) : (
        <div
          className={`${styles.body} questionBodyGlobal`}
          dangerouslySetInnerHTML={{
            __html: `${
              props.question.body_html
                ? DOMPurify.sanitize(props.question.body_html)
                : DOMPurify.sanitize(pathOr("", ["question", "body"], props))
            }`,
          }}
          onBlur={(e: any) => {
            if (props.onBlurHandler) {
              props.onBlurHandler(e);
            }
          }}
        ></div>
      )}
      <div className={styles.points}>
        <span className={styles.pointsNumber}>{props.question.points}p</span>
      </div>
    </div>
  );
};

export default QuestionHeaderOnline;
