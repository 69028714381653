import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import queryString from "query-string";

import StudentVerificationCodeInput from "./VerificationCodeInput";

import translate from "../../../../../services/translate";
import { getArrayBasedOnNumber } from "../../../../../services/common";

import TestOnlineNav from "../Nav";

import { getTest, unmountTest } from "../../../../../redux/actions/test";

// Types
import { History, Location } from "history";
import {
  testStateModel,
  getTest as getTestFunction,
} from "../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  match: any;
  inProgress: boolean;
  getTest: getTestFunction;
  test: testStateModel;
  unmountTest: () => {};
}

export const TestOnlineStudentVerification = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const testID = parsed.testID as string;

  const [verificationCode, setVerificationCode] = useState<any>({
    0: "",
    1: "",
    2: "",
    3: "",
  });
  const [studentNumber, setStudentNumber] = useState<number | undefined>();

  const getStudentCode = () => {
    return getArrayBasedOnNumber(4)
      .map((item: number) => `${verificationCode[item]}`)
      .join("");
  };

  useEffect(() => {
    if (!testID) {
      const splittedSearchQuery = props.location.search.split("testID=");

      if (splittedSearchQuery.length > 1) {
        props.history.replace(
          `/test-online-verification${splittedSearchQuery[0]}&testID=${splittedSearchQuery[1]}`,
        );
      }
    }

    props.unmountTest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.test.id === testID && props.test.class_id === classID) {
      props.history.replace(`/test-online${props.history.location.search}`, {
        verificationCode: getStudentCode(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.test]);

  const sudentNumberClass = (value: number) =>
    classNames({
      [styles.active]: value === studentNumber,
    });

  const isFormFilledOut = () => getStudentCode().length === 4 && studentNumber;

  return (
    <div className={styles.container}>
      <TestOnlineNav
        history={props.history}
        location={props.location}
        verified={false}
      />
      <div className={styles.innerContainer}>
        <div className={styles.box}>
          <h1>
            {translate("test.access-verification-1")}{" "}
            <span>{translate("test.access-verification-2")}</span>
          </h1>
          <p>{translate("test.access-verification-description")}</p>
          <div className={styles.studentCode}>
            <span>{translate("global.access-code")}:</span>
            <StudentVerificationCodeInput
              history={props.history}
              location={props.location}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
            />
          </div>
          <div className={styles.studentNumber}>
            <span>{translate("students.number-from-lesson-diary")}:</span>
            <div className={styles.numbersContainer}>
              {getArrayBasedOnNumber(40).map((value: number) => (
                <label
                  key={`verification_student_number_input_${value}`}
                  className={sudentNumberClass(value + 1)}
                >
                  <span>{value + 1}</span>
                  <input
                    type="checkbox"
                    onChange={() => setStudentNumber(value + 1)}
                  />
                </label>
              ))}
            </div>
          </div>
          <button
            type="button"
            disabled={props.inProgress || !isFormFilledOut()}
            onClick={() =>
              props.getTest({
                classID,
                testID,
                studentCode: getStudentCode(),
                studentNumber: `${studentNumber}`,
              })
            }
          >
            {props.inProgress ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <span>{translate("test.accept-it")}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { test: testStateModel }) => ({
  test: state.test,
  inProgress: state.test.in_progress.get_test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTest,
      unmountTest,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestOnlineStudentVerification);
