import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";

import { getClasses } from "../../../redux/actions/teacher";

import translate from "../../../services/translate";

// Types
import { History } from "history";
import {
  classModel,
  getClasses as getClassesFunction,
  teacherStateModel,
} from "../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  classes: classModel[];
  getClasses: getClassesFunction;
}

export const Classes = (props: Props) => {
  useEffect(() => {
    props.getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{translate("classes.classes-list")}</h3>
        <Link to="/class/add">
          <span>+&nbsp;</span>
          <span>{translate("class.add-class")}</span>
        </Link>
      </div>
      <ul className={styles.classes}>
        {props.classes.map((item: classModel) => (
          <li key={item.id}>
            <Link to={`/class/${item.id}`}>
              <span>{item.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: { teacher: teacherStateModel }) => ({
  classes: state.teacher.classes,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Classes);
