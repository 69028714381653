import React, { useState, useRef } from "react";
import classNames from "classnames";
import { CardExpiryElement } from "react-stripe-elements";

import translate from "../../../../../../../../services/translate";

// Types
import { Payment } from "../../../../../../../../models/common";

import styles from "../styles.module.scss";

interface Props {
  focusedElement: Payment["focusedElement"];
  setFocusedElement: (value: Payment["focusedElement"]) => any;
}

export const CardExpiry = (props: Props) => {
  const [cardExpiryEmpty, setCardExpiryEmpty] = useState<boolean>(true);
  const CardExpiryEl = useRef<any>(null);

  const isActive = (value: string) =>
    props.focusedElement === value || !cardExpiryEmpty;

  const labelClass = (value: string) =>
    classNames({
      [styles.active]: isActive(value),
    });

  return (
    <div className={styles.cardExpiryInputContainer}>
      <div
        className={styles.labelContainer}
        onClick={() => CardExpiryEl.current.getElement().focus()}
      >
        <label className={labelClass("cardExpiry")}>
          <span>{translate("profile.card-expiry")}</span>
        </label>
      </div>
      <CardExpiryElement
        onChange={(e) => setCardExpiryEmpty(e.empty)}
        onBlur={(e) => props.setFocusedElement("")}
        onFocus={(e) => props.setFocusedElement(e.elementType as any)}
        ref={CardExpiryEl}
        className="stripeInput"
        placeholder={isActive("cardExpiry") ? "MM/YY" : ""}
        style={{ base: { color: "#5B5BB4" } }}
      />
    </div>
  );
};

export default CardExpiry;
