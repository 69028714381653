import { History } from "history";
import { ErrorsPayload, ErrorObject } from "../../common";
import { questionSkill } from "../curriculum";

export enum testTypes {
  PAPER = "paper",
  ONLINE = "online",
};

export enum QuestionTypes {
  MULTIPLE_CHOICE = "multiple_choice",
  TRUE_FALSE = "true_false",
  OPEN = "open",
  MATCHING = "matching",
  FILL_IN_THE_GAPS = "fill_in_the_gaps",
};

export enum QuestionSubtype {
  SINGLE = "single",
  MULTIPLE = "multiple",
};

export enum TestStatusTypes {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
};

export interface SubjectModel {
  id: string;
  name: string;
  type: string;
};

export interface answerChoiceModel {
  id: string;
  is_correct: boolean;
  name_html: string;
  name: string;
  order: number;
}

export interface matchingItemModel {
  id: string;
  item: string;
  item_html: string;
  order: number;
}

export interface matchingMatchedItemModel {
  id: string;
  matched_item: string;
  matched_item_html: string;
  order: number;
}

export type matchingModel = matchingItemModel[] & matchingMatchedItemModel[];

export type gapModel = {
  id: string;
  names: string[];
  order: number;
}

export interface typeDataModel {
  notes_size: number;
  notes_type: string | null;
  subtype: string | null;
  check_case_in_gaps?: boolean;
  show_gap_options?: boolean;
}

export interface questionModel {
  id: string;
  body_html: string | null;
  body: string | null;
  points: number;
  points_per_item: number | null;
  order: number;
  image: string;
  answer_choices: answerChoiceModel[];
  matchings: matchingModel[];
  gaps: gapModel[];
  type_data: typeDataModel;
  type: string;
  skills: questionSkill[];
}

export interface testStateModel {
  id: string;
  class_id: string;
  class_name: string;
  lang: string;
  name: string;
  subject: SubjectModel;
  question_types: questionTypeModel[];
  questions: questionModel[];
  errors: ErrorObject[];
  status: string;
  type: string;
  updated_at: string;
  in_progress: {
    get_test: boolean;
    generate_pdf: boolean;
    create_question: boolean;
    update_question: boolean;
    duplicate_test: boolean;
    update_test: boolean;
    send_answers_by_student: boolean;
    question: string;
  },
  has_skills: boolean;
  has_curriculum: boolean;
  ends_at: string;
  time_limit: number | null;
};

export interface testActionModel {
  type: string;
  payload?: any;
};

// Create Test

const CREATE_TEST = "CREATE_TEST";
const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS";
const CREATE_TEST_FAILURE = "CREATE_TEST_FAILURE";

export interface createTestPayload {
  class_id: string;
  lang: string;
  name: string;
  subject_id: string;
  history: History;
  type: string;
  online_data?: {
    time_limit: string;
  };
};

export interface createTestSuccessPayload {
  testID: string;
  classID: string;
};

export interface createTest {
  ({ class_id, lang, name, subject_id, history, type }: createTestPayload): any;
};

export interface createTestAction {
  type: typeof CREATE_TEST;
  payload: createTestPayload;
};

export interface createTestSuccessAction {
  type: typeof CREATE_TEST_SUCCESS;
  payload: createTestSuccessPayload;
};

export interface createTestFailureAction {
  type: typeof CREATE_TEST_FAILURE;
  payload: {
    errors: ErrorObject[];
    history: History;
  };
};

// Update Test

const UPDATE_TEST = "UPDATE_TEST";
const UPDATE_TEST_SUCCESS = "UPDATE_TEST_SUCCESS";
const UPDATE_TEST_FAILURE = "UPDATE_TEST_FAILURE";

export interface updateTestPayload {
  id: string;
  name?: string;
  status?: string;
  online_data?: {
    time_limit?: string | null;
  };
};

export interface updateTestSuccessPayload {
  testID: string;
};

export interface updateTest {
  ({ id, name, online_data }: updateTestPayload, callback?: () => any): any;
};

export interface updateTestAction {
  type: typeof UPDATE_TEST;
  payload: updateTestPayload;
  callback?: () => any;
};

export interface updateTestSuccessAction {
  type: typeof UPDATE_TEST_SUCCESS;
  payload: updateTestSuccessPayload;
};

export interface updateTestFailureAction {
  type: typeof UPDATE_TEST_FAILURE;
  payload: ErrorsPayload;
};

// Delete Test

const DELETE_TEST = "DELETE_TEST";
const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS";
const DELETE_TEST_FAILURE = "DELETE_TEST_FAILURE";

export interface deleteTestPayload {
  id: string;
  classID: string;
};

export interface deleteTest {
  ({ id, classID }: deleteTestPayload): any;
};

export interface deleteTestAction {
  type: typeof DELETE_TEST;
  payload: deleteTestPayload;
};

export interface deleteTestSuccessAction {
  type: typeof DELETE_TEST_SUCCESS;
  payload: deleteTestPayload;
};

export interface deleteTestFailureAction {
  type: typeof DELETE_TEST_FAILURE;
  payload: ErrorsPayload;
};

// Get Question Types

const GET_QUESTION_TYPES = "GET_QUESTION_TYPES";
const GET_QUESTION_TYPES_SUCCESS = "GET_QUESTION_TYPES_SUCCESS";
const GET_QUESTION_TYPES_FAILURE = "GET_QUESTION_TYPES_FAILURE";

export interface questionSubtypeModel {
  name: string;
  type: string;
}

export interface questionTypeModel {
  name: string;
  subtypes: questionSubtypeModel[];
  type: string;
}

export interface getQuestionTypesPayload {
  type: string;
};

export interface getQuestionTypesSuccessPayload {
  data: questionTypeModel[];
};

export interface getQuestionTypes {
  ({ type }: getQuestionTypesPayload): any;
};

export interface getQuestionTypesAction {
  type: typeof GET_QUESTION_TYPES;
  payload: getQuestionTypesPayload;
};

export interface getQuestionTypesSuccessAction {
  type: typeof GET_QUESTION_TYPES_SUCCESS;
  payload: getQuestionTypesSuccessPayload;
};

export interface getQuestionTypesFailureAction {
  type: typeof GET_QUESTION_TYPES_FAILURE;
  payload: ErrorsPayload;
};

// Create Question

const CREATE_QUESTION = "CREATE_QUESTION";
const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
const CREATE_QUESTION_FAILURE = "CREATE_QUESTION_FAILURE";

export interface createQuestionPayload {
  classID: string;
  testID: string;
  body_html: string;
  body: string;
  lang: string;
  points: number;
  points_per_item?: number;
  type: string;
};

export interface createQuestionSuccessPayload {
  testID: string;
  classID: string;
};

export interface createQuestion {
  ({
    classID,
    testID,
    body_html,
    body,
    lang,
    points,
    points_per_item,
    type,
  }: createQuestionPayload): any;
};

export interface createQuestionAction {
  type: typeof CREATE_QUESTION;
  payload: createQuestionPayload;
};

export interface createQuestionSuccessAction {
  type: typeof CREATE_QUESTION_SUCCESS;
  payload: createQuestionSuccessPayload;
};

export interface createQuestionFailureAction {
  type: typeof CREATE_QUESTION_FAILURE;
  payload: ErrorsPayload;
};

// Update Question

const UPDATE_QUESTION = "UPDATE_QUESTION";
const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

export interface updateQuestionPayload {
  classID: string;
  testID: string;
  questionID: string;
  body_html?: string;
  body?: string;
  points?: number;
  pointsPerItem?: number;
  order?: number;
  type_data?: any;
  image?: any;
  gaps?: string[][];
};

export interface updateQuestionSuccessPayload {
  testID: string;
  classID: string;
  questionID: string;
};

export interface updateQuestion {
  ({
    classID,
    testID,
    questionID,
    body_html,
    body,
    points,
    pointsPerItem,
    order,
    type_data,
    image,
    gaps,
  }: updateQuestionPayload, callback?: () => any): any;
};

export interface updateQuestionAction {
  type: typeof UPDATE_QUESTION;
  payload: updateQuestionPayload;
  callback?: () => any;
};

export interface updateQuestionSuccessAction {
  type: typeof UPDATE_QUESTION_SUCCESS;
  payload: updateQuestionSuccessPayload;
};

export interface updateQuestionFailureAction {
  type: typeof UPDATE_QUESTION_FAILURE;
  payload: ErrorsPayload;
};

// Delete Question

const DELETE_QUESTION = "DELETE_QUESTION";
const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export interface deleteQuestionPayload {
  classID: string;
  testID: string;
  questionID: string;
};

export interface deleteQuestionSuccessPayload {
  testID: string;
  classID: string;
  questionID: string;
};

export interface deleteQuestion {
  ({ classID, testID, questionID }: deleteQuestionPayload): any;
};

export interface deleteQuestionAction {
  type: typeof DELETE_QUESTION;
  payload: deleteQuestionPayload;
};

export interface deleteQuestionSuccessAction {
  type: typeof DELETE_QUESTION_SUCCESS;
  payload: deleteQuestionSuccessPayload;
};

export interface deleteQuestionFailureAction {
  type: typeof DELETE_QUESTION_FAILURE;
  payload: ErrorsPayload;
};

// Get Test

const GET_TEST = "GET_TEST";
const GET_TEST_SUCCESS = "GET_TEST_SUCCESS";
const GET_TEST_FAILURE = "GET_TEST_FAILURE";

export interface getTestPayload {
  classID: string;
  testID: string;
  studentCode?: string;
  studentNumber?: string;
};

export interface getTestSuccessPayload {
  data: {
    class_id: string;
    class_name: string;
    has_skills: boolean;
    has_curriculum: boolean;
    id: string;
    name: string;
    questions: questionModel[]
    status: string;
    subject: SubjectModel;
    type: string;
    updated_at: string;
  }
};

export interface getTest {
  ({ classID, testID, studentCode, studentNumber }: getTestPayload): any;
};

export interface getTestAction {
  type: typeof GET_TEST;
  payload: getTestPayload;
};

export interface getTestSuccessAction {
  type: typeof GET_TEST_SUCCESS;
  payload: getTestSuccessPayload;
};

export interface getTestFailureAction {
  type: typeof GET_TEST_FAILURE;
  payload: {
    errors: ErrorObject[];
    hasStudentCodeAndNumber: boolean;
  };
};

// Create Question Answer Choice

const CREATE_QUESTION_ANSWER_CHOICE = "CREATE_QUESTION_ANSWER_CHOICE";
const CREATE_QUESTION_ANSWER_CHOICE_SUCCESS = "CREATE_QUESTION_ANSWER_CHOICE_SUCCESS";
const CREATE_QUESTION_ANSWER_CHOICE_FAILURE = "CREATE_QUESTION_ANSWER_CHOICE_FAILURE";

export interface createQuestionAnswerChoicePayload {
  questionID: string;
  classID: string;
  testID: string;
  name: string;
};

export interface createQuestionAnswerChoiceSuccessPayload {
  testID: string;
  classID: string;
  answerID: string;
  questionID: string;
};

export interface createQuestionAnswerChoice {
  ({ questionID, classID, testID, name }: createQuestionAnswerChoicePayload): any;
};

export interface createQuestionAnswerChoiceAction {
  type: typeof CREATE_QUESTION_ANSWER_CHOICE;
  payload: createQuestionAnswerChoicePayload;
};

export interface createQuestionAnswerChoiceSuccessAction {
  type: typeof CREATE_QUESTION_ANSWER_CHOICE_SUCCESS;
  payload: createQuestionAnswerChoiceSuccessPayload;
};

export interface createQuestionAnswerChoiceFailureAction {
  type: typeof CREATE_QUESTION_ANSWER_CHOICE_FAILURE;
  payload: ErrorsPayload;
};

// Update Question Answer Choice

const UPDATE_QUESTION_ANSWER_CHOICE = "UPDATE_QUESTION_ANSWER_CHOICE";
const UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS = "UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS";
const UPDATE_QUESTION_ANSWER_CHOICE_FAILURE = "UPDATE_QUESTION_ANSWER_CHOICE_FAILURE";

export interface updateQuestionAnswerChoicePayload {
  answerID: string;
  questionID: string;
  classID: string;
  testID: string;
  name?: string;
  name_html?: string;
  isCorrect?: boolean;
};

export interface updateQuestionAnswerChoiceSuccessPayload {
  testID: string;
  classID: string;
  answerID: string;
  questionID: string;
  isCorrect?: boolean;
};

export interface updateQuestionAnswerChoice {
  ({ answerID, questionID, classID, testID }: updateQuestionAnswerChoicePayload): any;
};

export interface updateQuestionAnswerChoiceAction {
  type: typeof UPDATE_QUESTION_ANSWER_CHOICE;
  payload: updateQuestionAnswerChoicePayload;
};

export interface updateQuestionAnswerChoiceSuccessAction {
  type: typeof UPDATE_QUESTION_ANSWER_CHOICE_SUCCESS;
  payload: updateQuestionAnswerChoiceSuccessPayload;
};

export interface updateQuestionAnswerChoiceFailureAction {
  type: typeof UPDATE_QUESTION_ANSWER_CHOICE_FAILURE;
  payload: ErrorsPayload;
};

// Delete Question Answer Choice

const DELETE_QUESTION_ANSWER_CHOICE = "DELETE_QUESTION_ANSWER_CHOICE";
const DELETE_QUESTION_ANSWER_CHOICE_SUCCESS = "DELETE_QUESTION_ANSWER_CHOICE_SUCCESS";
const DELETE_QUESTION_ANSWER_CHOICE_FAILURE = "DELETE_QUESTION_ANSWER_CHOICE_FAILURE";

export interface deleteQuestionAnswerChoicePayload {
  answerID: string;
  questionID: string;
  classID: string;
  testID: string;
};

export interface deleteQuestionAnswerChoiceSuccessPayload {
  testID: string;
  classID: string;
  answerID: string;
  questionID: string;
};

export interface deleteQuestionAnswerChoice {
  ({ answerID, questionID, classID, testID }: deleteQuestionAnswerChoicePayload): any;
};

export interface deleteQuestionAnswerChoiceAction {
  type: typeof DELETE_QUESTION_ANSWER_CHOICE;
  payload: deleteQuestionAnswerChoicePayload;
};

export interface deleteQuestionAnswerChoiceSuccessAction {
  type: typeof DELETE_QUESTION_ANSWER_CHOICE_SUCCESS;
  payload: deleteQuestionAnswerChoiceSuccessPayload;
};

export interface deleteQuestionAnswerChoiceFailureAction {
  type: typeof DELETE_QUESTION_ANSWER_CHOICE_FAILURE;
  payload: ErrorsPayload;
};

// Create Question Matching

const CREATE_QUESTION_MATCHING = "CREATE_QUESTION_MATCHING";
const CREATE_QUESTION_MATCHING_SUCCESS = "CREATE_QUESTION_MATCHING_SUCCESS";
const CREATE_QUESTION_MATCHING_FAILURE = "CREATE_QUESTION_MATCHING_FAILURE";

export interface createQuestionMatchingPayload {
  questionID: string;
  classID: string;
  testID: string;
  item?: string;
  item_html?: string;
  matched_item?: string;
  matched_item_html?: string;
};

export interface createQuestionMatchingSuccessPayload {
  testID: string;
  classID: string;
  matchingID: string;
  questionID: string;
};

export interface createQuestionMatching {
  ({ questionID, classID, testID }: createQuestionMatchingPayload): any;
};

export interface createQuestionMatchingAction {
  type: typeof CREATE_QUESTION_MATCHING;
  payload: createQuestionMatchingPayload;
};

export interface createQuestionMatchingSuccessAction {
  type: typeof CREATE_QUESTION_MATCHING_SUCCESS;
  payload: createQuestionMatchingSuccessPayload;
};

export interface createQuestionMatchingFailureAction {
  type: typeof CREATE_QUESTION_MATCHING_FAILURE;
  payload: ErrorsPayload;
};

// Update Question Matching

const UPDATE_QUESTION_MATCHING = "UPDATE_QUESTION_MATCHING";
const UPDATE_QUESTION_MATCHING_SUCCESS = "UPDATE_QUESTION_MATCHING_SUCCESS";
const UPDATE_QUESTION_MATCHING_FAILURE = "UPDATE_QUESTION_MATCHING_FAILURE";

export interface updateQuestionMatchingPayload {
  matchingID: string;
  questionID: string;
  classID: string;
  testID: string;
  item?: string;
  item_html?: string;
  matched_item?: string;
  matched_item_html?: string;
};

export interface updateQuestionMatchingSuccessPayload {
  testID: string;
  classID: string;
  matchingID: string;
  questionID: string;
};

export interface updateQuestionMatching {
  ({ matchingID, questionID, classID, testID }: updateQuestionMatchingPayload): any;
};

export interface updateQuestionMatchingAction {
  type: typeof UPDATE_QUESTION_MATCHING;
  payload: updateQuestionMatchingPayload;
};

export interface updateQuestionMatchingSuccessAction {
  type: typeof UPDATE_QUESTION_MATCHING_SUCCESS;
  payload: updateQuestionMatchingSuccessPayload;
};

export interface updateQuestionMatchingFailureAction {
  type: typeof UPDATE_QUESTION_MATCHING_FAILURE;
  payload: ErrorsPayload;
};

// Delete Question Matching

const DELETE_QUESTION_MATCHING = "DELETE_QUESTION_MATCHING";
const DELETE_QUESTION_MATCHING_SUCCESS = "DELETE_QUESTION_MATCHING_SUCCESS";
const DELETE_QUESTION_MATCHING_FAILURE = "DELETE_QUESTION_MATCHING_FAILURE";

export interface deleteQuestionMatchingPayload {
  matchingID: string;
  questionID: string;
  classID: string;
  testID: string;
};

export interface deleteQuestionMatchingSuccessPayload {
  testID: string;
  classID: string;
  matchingID: string;
  questionID: string;
};

export interface deleteQuestionMatching {
  ({ matchingID, questionID, classID, testID }: deleteQuestionMatchingPayload): any;
};

export interface deleteQuestionMatchingAction {
  type: typeof DELETE_QUESTION_MATCHING;
  payload: deleteQuestionMatchingPayload;
};

export interface deleteQuestionMatchingSuccessAction {
  type: typeof DELETE_QUESTION_MATCHING_SUCCESS;
  payload: deleteQuestionMatchingSuccessPayload;
};

export interface deleteQuestionMatchingFailureAction {
  type: typeof DELETE_QUESTION_MATCHING_FAILURE;
  payload: ErrorsPayload;
};

// Generate PDF

const GENERATE_PDF = "GENERATE_PDF";
const GENERATE_PDF_SUCCESS = "GENERATE_PDF_SUCCESS";
const GENERATE_PDF_FAILURE = "GENERATE_PDF_FAILURE";

export interface generatePDFPayload {
  testID: string;
  classID?: string;
  html: string;
};

export interface generatePDFSuccessPayload {
  data: {
    url: string;
  };
};

export interface generatePDF {
  ({ testID, html }: generatePDFPayload): any;
};

export interface generatePDFAction {
  type: typeof GENERATE_PDF;
  payload: generatePDFPayload;
};

export interface generatePDFSuccessAction {
  type: typeof GENERATE_PDF_SUCCESS;
  payload: generatePDFSuccessPayload;
};

export interface generatePDFFailureAction {
  type: typeof GENERATE_PDF_FAILURE;
  payload: ErrorsPayload;
};

// Validate Test

const VALIDATE_TEST = "VALIDATE_TEST";
const VALIDATE_TEST_SUCCESS = "VALIDATE_TEST_SUCCESS";
const VALIDATE_TEST_FAILURE = "VALIDATE_TEST_FAILURE";

export interface validateTestPayload {
  testID: string;
  callback: () => any;
};

export interface validateTestSuccessPayload {
  callback: () => any;
};

export interface validateTest {
  ({ testID, callback }: validateTestPayload): any;
};

export interface validateTestAction {
  type: typeof VALIDATE_TEST;
  payload: validateTestPayload,
};

export interface validateTestSuccessAction {
  type: typeof VALIDATE_TEST_SUCCESS;
  payload: validateTestSuccessPayload;
};

export interface validateTestFailureAction {
  type: typeof VALIDATE_TEST_FAILURE;
  payload: ErrorsPayload;
};

// Delete Question Image

const DELETE_QUESTION_IMAGE = "DELETE_QUESTION_IMAGE";
const DELETE_QUESTION_IMAGE_SUCCESS = "DELETE_QUESTION_IMAGE_SUCCESS";
const DELETE_QUESTION_IMAGE_FAILURE = "DELETE_QUESTION_IMAGE_FAILURE";

export interface deleteQuestionImagePayload {
  classID: string;
  testID: string;
  questionID: string;
};

export interface deleteQuestionImageSuccessPayload {
  testID: string;
  classID: string;
  questionID: string;
};

export interface deleteQuestionImage {
  ({ classID, testID, questionID }: deleteQuestionImagePayload): any;
};

export interface deleteQuestionImageAction {
  type: typeof DELETE_QUESTION_IMAGE;
  payload: deleteQuestionImagePayload;
};

export interface deleteQuestionImageSuccessAction {
  type: typeof DELETE_QUESTION_IMAGE_SUCCESS;
  payload: deleteQuestionImageSuccessPayload;
};

export interface deleteQuestionImageFailureAction {
  type: typeof DELETE_QUESTION_IMAGE_FAILURE;
  payload: ErrorsPayload;
};

// Duplicate Test

const DUPLICATE_TEST = "DUPLICATE_TEST";
const DUPLICATE_TEST_SUCCESS = "DUPLICATE_TEST_SUCCESS";
const DUPLICATE_TEST_FAILURE = "DUPLICATE_TEST_FAILURE";

export interface duplicateTestPayload {
  classID: string;
  testID: string;
  history: History;
};

export interface duplicateTest {
  ({ classID, testID, history }: duplicateTestPayload, callback?: () => any): any;
};

export interface duplicateTestAction {
  type: typeof DUPLICATE_TEST;
  payload: duplicateTestPayload;
  callback?: () => {};
};

export interface duplicateTestSuccessAction {
  type: typeof DUPLICATE_TEST_SUCCESS;
};

export interface duplicateTestFailureAction {
  type: typeof DUPLICATE_TEST_FAILURE;
  payload: {
    errors: ErrorObject[];
    history: History;
  };
};

// Create Test Results

const CREATE_TEST_RESULTS = "CREATE_TEST_RESULTS";
const CREATE_TEST_RESULTS_SUCCESS = "CREATE_TEST_RESULTS_SUCCESS";
const CREATE_TEST_RESULTS_FAILURE = "CREATE_TEST_RESULTS_FAILURE";

export interface createTestResultsPayload {
  testID: string;
  studentID: string;
};

export interface createTestResults {
  ({ testID, studentID }: createTestResultsPayload, callback?: () => any): any;
};

export interface createTestResultsAction {
  type: typeof CREATE_TEST_RESULTS;
  payload: createTestResultsPayload;
  callback?: () => {};
};

export interface createTestResultsSuccessAction {
  type: typeof CREATE_TEST_RESULTS_SUCCESS;
};

export interface createTestResultsFailureAction {
  type: typeof CREATE_TEST_RESULTS_FAILURE;
  payload: ErrorsPayload;
};

// Create Test Results By Student

const CREATE_TEST_RESULTS_BY_STUDENT = "CREATE_TEST_RESULTS_BY_STUDENT";
const CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS = "CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS";
const CREATE_TEST_RESULTS_BY_STUDENT_FAILURE = "CREATE_TEST_RESULTS_BY_STUDENT_FAILURE";

export interface studentAnswer {
  question_id: string;
  answer_choices?: number[];
  answer?: string;
}

export interface createTestResultsByStudentPayload {
  answers: studentAnswer[];
  studentCode: string;
  studentNumber: number;
  testID: string;
};

export interface createTestResultsByStudent {
  ({ answers, studentCode, studentNumber, testID }: createTestResultsByStudentPayload, callback?: () => any): any;
};

export interface createTestResultsByStudentAction {
  type: typeof CREATE_TEST_RESULTS_BY_STUDENT;
  payload: createTestResultsByStudentPayload;
  callback?: () => {};
};

export interface createTestResultsByStudentSuccessAction {
  type: typeof CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS;
};

export interface createTestResultsByStudentFailureAction {
  type: typeof CREATE_TEST_RESULTS_BY_STUDENT_FAILURE;
  payload: ErrorsPayload;
};

// Get Test Results

const GET_TEST_RESULTS = "GET_TEST_RESULTS";
const GET_TEST_RESULTS_SUCCESS = "GET_TEST_RESULTS_SUCCESS";
const GET_TEST_RESULTS_FAILURE = "GET_TEST_RESULTS_FAILURE";

export interface getTestResultsPayload {
  testID: string;
  resultID: string;
};

export interface resultObject {
  id: string;
  max_points?: number;
  points?: number;
  answer_choices: {
    all: number[];
    correct: number[];
    selected: number[];
  } | null,
  answer_choices_representation: {
    all: string[];
    correct: string[];
    selected: string[];
  } | null,
  subtype: string | null;
  type: string;
  number: number;
};

export interface getTestResultsSuccessPayload {
  answers: resultObject[];
};

export interface getTestResults {
  ({ testID, resultID }: getTestResultsPayload): any;
};

export interface getTestResultsAction {
  type: typeof GET_TEST_RESULTS;
  payload: getTestResultsPayload;
};

export interface getTestResultsSuccessAction {
  type: typeof GET_TEST_RESULTS_SUCCESS;
  payload: getTestResultsSuccessPayload;
};

export interface getTestResultsFailureAction {
  type: typeof GET_TEST_RESULTS_FAILURE;
  payload: ErrorsPayload;
};

// Update Test Results

const UPDATE_TEST_RESULTS = "UPDATE_TEST_RESULTS";
const UPDATE_TEST_RESULTS_SUCCESS = "UPDATE_TEST_RESULTS_SUCCESS";
const UPDATE_TEST_RESULTS_FAILURE = "UPDATE_TEST_RESULTS_FAILURE";

export interface updateResultsObject {
  id: string;
  points?: number;
  answer_choices?: number[];
};

export interface updateTestResultsPayload {
  testID: string;
  resultID: string;
  answers: updateResultsObject[];
};

export interface updateTestResults {
  ({ testID, resultID, answers }: updateTestResultsPayload, callback?: () => any): any;
};

export interface updateTestResultsAction {
  type: typeof UPDATE_TEST_RESULTS;
  payload: updateTestResultsPayload;
  callback?: () => {};
};

export interface updateTestResultsSuccessAction {
  type: typeof UPDATE_TEST_RESULTS_SUCCESS;
};

export interface updateTestResultsFailureAction {
  type: typeof UPDATE_TEST_RESULTS_FAILURE;
  payload: ErrorsPayload;
};

// Delete Test Result

const DELETE_TEST_RESULTS = "DELETE_TEST_RESULTS";
const DELETE_TEST_RESULTS_SUCCESS = "DELETE_TEST_RESULTS_SUCCESS";
const DELETE_TEST_RESULTS_FAILURE = "DELETE_TEST_RESULTS_FAILURE";

export interface deleteTestResultsPayload {
  testID: string;
  resultID: string;
};

export interface deleteTestResults {
  ({ testID, resultID }: deleteTestResultsPayload, callback?: () => any): any;
};

export interface deleteTestResultsAction {
  type: typeof DELETE_TEST_RESULTS;
  payload: deleteTestResultsPayload;
  callback?: () => {};
};

export interface deleteTestResultsSuccessAction {
  type: typeof DELETE_TEST_RESULTS_SUCCESS;
};

export interface deleteTestResultsFailureAction {
  type: typeof DELETE_TEST_RESULTS_FAILURE;
  payload: ErrorsPayload;
};

// Replace Question Gap

const REPLACE_QUESTION_GAP = "REPLACE_QUESTION_GAP";
const REPLACE_QUESTION_GAP_SUCCESS = "REPLACE_QUESTION_GAP_SUCCESS";
const REPLACE_QUESTION_GAP_FAILURE = "REPLACE_QUESTION_GAP_FAILURE";

export interface replaceQuestionGapPayload {
  testID: string;
  classID: string;
  questionID: string;
  names: string[][];
};

export interface replaceQuestionGapSuccessPayload {
  testID: string;
  classID: string;
  questionID: string;
};

export interface replaceQuestionGap {
  ({ testID, classID, questionID, names }: replaceQuestionGapPayload): any;
};

export interface replaceQuestionGapAction {
  type: typeof REPLACE_QUESTION_GAP;
  payload: replaceQuestionGapPayload;
};

export interface replaceQuestionGapSuccessAction {
  type: typeof REPLACE_QUESTION_GAP_SUCCESS;
  payload: replaceQuestionGapSuccessPayload;
};

export interface replaceQuestionGapFailureAction {
  type: typeof REPLACE_QUESTION_GAP_FAILURE;
  payload: ErrorsPayload;
};

// Other

const UNMOUNT_TEST = "UNMOUNT_TEST";

export interface unmountTestAction {
  type: typeof UNMOUNT_TEST;
};
