import React, { useState } from "react";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import { ReactComponent as Logo } from "../../../assets/images/wisdy_logo.svg";
import { ReactComponent as Hamburger } from "../../../assets/images/hamburger.svg";

import {
  getLandingPageURL,
  outsideClickHelper,
} from "../../../services/common";
import translate from "../../../services/translate";

// Types
import { Location, History } from "history";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  history: History;
}

export const DemoNav = (props: Props) => {
  const [showDropdown, toggleDropdown] = useState(false);

  const hamburgerClass = classNames({
    [styles.hamburger]: true,
    [styles.hamburgerCross]: showDropdown,
  });

  return (
    <section className={styles.container}>
      <nav className={styles.header}>
        <a href={getLandingPageURL()} className={styles.logoLink}>
          <Logo className={styles.logo}/>
        </a>
        <div className={styles.links}>
          <a href={getLandingPageURL()} className={styles.gradientLink}>
            <span>{translate("global.leave-demo")}</span>
          </a>
          <OutsideClickHandler
            onOutsideClick={(e: any) => {
              outsideClickHelper(e, "dropdown", () => toggleDropdown(false));
            }}
          >
            <button
              className={styles.hamburgerContainer}
              onClick={() => toggleDropdown(!showDropdown)}
            >
              <Hamburger className={hamburgerClass}/>
            </button>
          </OutsideClickHandler>
        </div>
        {!!showDropdown && (
          <div className={styles.dropdown}>
            <a href={getLandingPageURL()} className={styles.gradientLink}>
              <span>{translate("global.leave-demo")}</span>
            </a>
          </div>
        )}
      </nav>
    </section>
  );
};

export default DemoNav;
