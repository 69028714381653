import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";

import translate from "../../../../../services/translate";
import { getPreviewElement } from "../../../../../services/common/previewTest";

import PreviewHiddenTest from "./HiddenTest";
import PreviewTestPages from "./Pages";
import PrintOptionsRow from "../../../../Common/PrintOptionsRow";

import { getSubjects } from "../../../../../redux/actions/teacher";
import {
  getQuestionTypes,
  getTest,
  unmountTest,
  updateQuestion,
} from "../../../../../redux/actions/test";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  subjectModel,
  classModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";
import {
  testStateModel,
  getQuestionTypes as getQuestionTypesFunction,
  getTest as getTestFunction,
  updateQuestion as updateQuestionFunction,
} from "../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  classes: classModel[];
  subjects: subjectModel[];
  test: testStateModel;
  getSubjects: getSubjectsFunction;
  getQuestionTypes: getQuestionTypesFunction;
  getTest: getTestFunction;
  unmountTest: () => {};
  updateQuestion: updateQuestionFunction;
}

export const PreviewTest = (props: Props) => {
  const [questions, setQuestions] = useState([] as any[]);
  const [pages, setPages] = useState([]);
  const [isCompact, setIsCompact] = useState(false);
  const [hideInstruction, setHideInstruction] = useState(false);

  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const selectedSubjectType = parsed.subject as string;
  const testID = parsed.testID as string;

  useEffect(() => {
    if (!classID) {
      props.history.replace(
        `/test/creator/select-class${props.history.location.search}`,
      );
      return;
    }

    if (!selectedSubjectType) {
      props.history.replace(
        `/test/creator/select-type${props.history.location.search}`,
      );
      return;
    }

    props.getSubjects();
    props.getQuestionTypes({ type: "paper" });

    getPreviewElement(props.test.id, setPages);

    if (classID && testID) {
      props.getTest({
        classID,
        testID,
      });
    }

    setQuestions(props.test.questions);

    return () => {
      props.unmountTest();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPreviewElement(props.test.id, setPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  useEffect(() => {
    setQuestions(props.test.questions);
  }, [props.test.questions, setQuestions]);

  const reloadPreview = () => getPreviewElement(props.test.id, setPages);

  return (
    <section className={styles.container}>
      <PrintOptionsRow
        reloadPreview={reloadPreview}
        hideInstruction={hideInstruction}
        setHideInstruction={setHideInstruction}
        isCompact={isCompact}
        setIsCompact={setIsCompact}
      />
      <PreviewTestPages
        classID={classID}
        classes={props.classes}
        test={props.test}
        pages={pages}
        reloadPreview={reloadPreview}
        hideInstruction={hideInstruction}
        isCompact={isCompact}
      />
      <PreviewHiddenTest
        classID={classID}
        questions={questions}
        classes={props.classes}
        subjectName={props.test.subject.name}
        testName={props.test.name}
        reloadPreview={reloadPreview}
        hideInstruction={hideInstruction}
        isCompact={isCompact}
      />
      <div className={styles.settingsContainer}>
        <Link
          to={`/test/creator${props.location.search}`}
          className={styles.returnBtn}
        >
          <span>{translate("creator.quit-preview-mode")}</span>
        </Link>
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  teacher: teacherStateModel;
  test: testStateModel;
}) => ({
  classes: state.teacher.classes,
  subjects: state.teacher.subjects,
  test: state.test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
      getQuestionTypes,
      getTest,
      unmountTest,
      updateQuestion,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewTest);
