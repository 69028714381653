import { ofType, ActionsObservable, Epic } from "redux-observable";
import { switchMap, pluck, map } from "rxjs/operators";

import {
  testsBaseGetTestsSuccess,
  testsBaseGetTestsFailure,
  testsBaseGetTestsNextPageSuccess,
  testsBaseGetTestsNextPageFailure,
  getTestFromTestsBaseSuccess,
  getTestFromTestsBaseFailure,
  publishTestInTestsBaseSuccess,
  publishTestInTestsBaseFailure,
  duplicateTestFromTestsBaseSuccess,
  duplicateTestFromTestsBaseFailure,
} from "../../actions/testsBase";

import { apiCall } from "../../../services/api";

// Types
import {
  testsBaseGetTestsAction,
  testsBaseGetTestsPayload,
  testsBaseGetTestsNextPageAction,
  testsBaseGetTestsNextPagePayload,
  getTestFromTestsBaseAction,
  getTestFromTestsBasePayload,
  publishTestInTestsBaseAction,
  publishTestInTestsBasePayload,
  publishTestInTestsBaseSuccessAction,
  publishTestInTestsBaseFailureAction,
  duplicateTestFromTestsBaseAction,
  duplicateTestFromTestsBasePayload,
  duplicateTestFromTestsBaseSuccessAction,
  duplicateTestFromTestsBaseFailureAction,
} from "../../../models/redux/testsBase";
import { XHRPayload } from "../../../models/common";
import { showToast } from "../../actions/app";

// Tests Base Get Tests

export const testsBaseGetTestsEpic: Epic = (
  action$: ActionsObservable<testsBaseGetTestsAction>,
) =>
  action$.pipe(
    ofType("TESTS_BASE_GET_TESTS"),
    pluck("payload"),
    switchMap(testsBaseGetTests),
  );

export const testsBaseGetTests = (payload: testsBaseGetTestsPayload) =>
  apiCall(
    `/tests-base/tests?pagination%5Bpage%5D=${
      payload.page
    }&pagination%5Bpage_size%5D=${payload.page_size}${
      payload.subject_id ? `&subject_id=${payload.subject_id}` : ""
    }`,
    "GET",
    {},
    (data: XHRPayload) => testsBaseGetTestsSuccess(data.response),
    testsBaseGetTestsFailure,
  );

// Tests Base Get Tests Next Page

export const testsBaseGetTestsNextPageEpic: Epic = (
  action$: ActionsObservable<testsBaseGetTestsNextPageAction>,
) =>
  action$.pipe(
    ofType("TESTS_BASE_GET_TESTS_NEXT_PAGE"),
    pluck("payload"),
    switchMap(testsBaseGetTestsNextPage),
  );

export const testsBaseGetTestsNextPage = (
  payload: testsBaseGetTestsNextPagePayload,
) =>
  apiCall(
    `/tests-base/tests?pagination%5Bpage%5D=${
      payload.page
    }&pagination%5Bpage_size%5D=${payload.page_size}${
      payload.subject_id ? `&subject_id=${payload.subject_id}` : ""
    }`,
    "GET",
    {},
    (data: XHRPayload) => testsBaseGetTestsNextPageSuccess(data.response),
    testsBaseGetTestsNextPageFailure,
  );

// Get Test From Tests Base

export const getTestFromTestsBaseEpic: Epic = (
  action$: ActionsObservable<getTestFromTestsBaseAction>,
) =>
  action$.pipe(
    ofType("GET_TEST_FROM_TESTS_BASE"),
    pluck("payload"),
    switchMap(getTestFromTestsBase),
  );

export const getTestFromTestsBase = (payload: getTestFromTestsBasePayload) =>
  apiCall(
    `/tests-base/tests/${payload.testID}`,
    "GET",
    {},
    (data: XHRPayload) => getTestFromTestsBaseSuccess(data.response),
    getTestFromTestsBaseFailure,
  );

// Publish Test in Tests Base

export const publishTestInTestsBaseEpic: Epic = (
  action$: ActionsObservable<publishTestInTestsBaseAction>,
) =>
  action$.pipe(
    ofType("PUBLISH_TEST_IN_TESTS_BASE"),
    pluck("payload"),
    switchMap(publishTestInTestsBase),
  );

export const publishTestInTestsBase = (
  payload: publishTestInTestsBasePayload,
) =>
  apiCall(
    `/tests-base/tests/${payload.testID}/publish`,
    "PATCH",
    {},
    publishTestInTestsBaseSuccess,
    publishTestInTestsBaseFailure,
  );

export const publishTestInTestsSuccessEpic: Epic = (
  action$: ActionsObservable<publishTestInTestsBaseSuccessAction>,
) =>
  action$.pipe(
    ofType("PUBLISH_TEST_IN_TESTS_BASE_SUCCESS"),
    map(() =>
      showToast({
        text: "test_base.test-has-been-published",
        status: "success",
      }),
    ),
  );

export const publishTestInTestsFailureEpic: Epic = (
  action$: ActionsObservable<publishTestInTestsBaseFailureAction>,
) =>
  action$.pipe(
    ofType("PUBLISH_TEST_IN_TESTS_BASE_FAILURE"),
    map(() =>
      showToast({
        text: "test_base.test-has-not-been-published",
        status: "error",
      }),
    ),
  );

// Duplicate a Test from Tests Base

export const duplicateTestFromTestsBaseEpic: Epic = (
  action$: ActionsObservable<duplicateTestFromTestsBaseAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST_FROM_TESTS_BASE"),
    switchMap((data) => duplicateTestFromTestsBase(data.payload, data.callback)),
  );

export const duplicateTestFromTestsBase = (
  payload: duplicateTestFromTestsBasePayload,
  callback?: () => any,
) =>
  apiCall(
    `/tests-base/tests/${payload.testID}/duplicate`,
    "POST",
    { class_id: payload.classID },
    () => duplicateTestFromTestsBaseSuccess(callback),
    duplicateTestFromTestsBaseFailure,
  );

export const duplicateTestFromTestsBaseSuccessEpic: Epic = (
  action$: ActionsObservable<duplicateTestFromTestsBaseSuccessAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST_FROM_TESTS_BASE_SUCCESS"),
    map(() =>
      showToast({ text: "test.test-has-been-duplicated", status: "success" }),
    ),
  );

export const duplicateTestFromTestsBaseFailureEpic: Epic = (
  action$: ActionsObservable<duplicateTestFromTestsBaseFailureAction>,
) =>
  action$.pipe(
    ofType("DUPLICATE_TEST_FROM_TESTS_BASE_FAILURE"),
    map(() =>
      showToast({ text: "test.test-has-not-been-duplicated", status: "error" }),
    ),
  );
