import React, { useState } from "react";
import classNames from "classnames";
import { injectStripe } from "react-stripe-elements";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import Modal, { ToggleContent } from "../../../../../Common/Modal";
import { calculateDaysTill } from "../../../../../../services/common";
import InvoiceDetails from "../InvoiceDetails";

import translate from "../../../../../../services/translate";

import { ReactComponent as CreditCardIcon } from "../../../../../../assets/images/payments/credit_card.svg";
import { ReactComponent as TransferIcon } from "../../../../../../assets/images/payments/transfer.svg";
import { ReactComponent as LockIcon } from "../../../../../../assets/images/lock.svg";

// Types
import { Location } from "history";
import { userStateModel } from "../../../../../../models/redux/user";
import { billingStateModel } from "../../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  location: Location;
  user: userStateModel;
  billing: billingStateModel;
  pickPaymentMethod: (value: string) => any;
  stripe?: any;
}

export const PaymentMethods = (props: Props) => {
  const [paymentMethod, setPaymentMethod] = useState<string>("");

  const isChecked = (value: string) => paymentMethod === value;

  const calculateDaysTillOver = () => {
    const expires_at = moment(props.user.access.expires_at, "YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    return calculateDaysTill(expires_at, today);
  };

  const daysTillOverBiggerThanSeven = () => calculateDaysTillOver() > 7;
  const isNotSupportedCurrencyForTransfer = () =>
    !(props.user.currency === "pln" || props.user.currency === "eur");

  const isTransferDisabled = (value: string) => {
    if (value === "transfer") {
      if (isNotSupportedCurrencyForTransfer()) {
        return true;
      } else if (daysTillOverBiggerThanSeven()) {
        return true;
      }
    }
    return false;
  };

  const itemClass = (value: string) =>
    classNames({
      [styles.active]: isChecked(value),
      [styles.disabled]: isTransferDisabled(value),
    });

  const paymentTransfer = (e: any) => {
    e.preventDefault();
    props.stripe
      .createSource({
        type: "p24",
        amount: props.billing.next_payment.amount,
        currency: props.billing.next_payment.currency,
        owner: {
          email: props.user.email,
        },
        redirect: {
          return_url: `${window.location.origin}/profile/billing`,
        },
        metadata: {
          product: "subscription",
        },
      })
      .then((result: any) => {
        if (result.source) {
          window.open(result.source.redirect.url, "_parent");
        }
      });
  };

  return (
    <div className={styles.paymentMethodsContainer}>
      <h3>{translate("profile.payment-method")}</h3>
      <ul className={styles.paymentMethods}>
        <li
          className={itemClass("credit-card")}
          onClick={() => setPaymentMethod("credit-card")}
        >
          <div className={styles.listItemInnerWrapper}>
            <div className={styles.radio}>
              {isChecked("credit-card") && <i/>}
            </div>
            <span>&nbsp;{translate("profile.credit-card")}</span>
          </div>
          <CreditCardIcon/>
        </li>
        {isTransferDisabled("transfer") ? (
          <div data-tip data-for="tooltip" className={styles.lockIconContainer}>
            <ReactTooltip
              id="tooltip"
              place="top"
              effect="solid"
              className={styles.tooltip}
            >
              {daysTillOverBiggerThanSeven() && (
                <p>
                  {translate("profile.payment-transfer-7-days-tooltip-text")}
                </p>
              )}
              {isNotSupportedCurrencyForTransfer() && (
                <p>
                  {translate(
                    "profile.payment-transfer-is-not-supported-currency",
                  )}
                </p>
              )}
            </ReactTooltip>
            <li className={itemClass("transfer")}>
              <div className={styles.listItemInnerWrapper}>
                <LockIcon/>
                <div className={styles.radio}>
                  {isChecked("transfer") && <i/>}
                </div>
                <span>&nbsp;{translate("transfer")}</span>
              </div>
              <TransferIcon/>
            </li>
          </div>
        ) : (
          <li
            className={itemClass("transfer")}
            onClick={() => setPaymentMethod("transfer")}
          >
            <div className={styles.listItemInnerWrapper}>
              <div className={styles.radio}>
                {isChecked("transfer") && <i/>}
              </div>
              <span>&nbsp;{translate("profile.transfer")}</span>
            </div>
            <TransferIcon/>
          </li>
        )}
      </ul>
      <ToggleContent
        toggle={(show: any) => (
          <button disabled={!paymentMethod.length} onClick={show}>
            <span>{translate("global.pick-up")}</span>
          </button>
        )}
        content={(hide: () => {}) => (
          <Modal hide={hide} customStyles={styles.modal}>
            <InvoiceDetails
              hide={hide}
              callback={(e: any) => {
                if (paymentMethod === "transfer") {
                  paymentTransfer(e);
                }
                props.pickPaymentMethod(paymentMethod);
              }}
            />
          </Modal>
        )}
      />
    </div>
  );
};

export default injectStripe(PaymentMethods);
