import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import move from "ramda/src/move";
import isNil from "ramda/src/isNil";

import QuestionHeaderOnline from "../Header";
import QuestionImageOnline from "../Image";

// Types
import {
  questionModel,
  matchingItemModel,
} from "../../../../../../../models/redux/test";

import styles from "./styles.module.scss";


interface Props {
  onMatchChange: (arr: any) => void;
  question: questionModel;
  isReadOnly?: boolean;
}

export const QuestionMatchingOnline = (props: Props) => {
  const [matches, setMatches] = useState([] as any[]);
  const [shufflededOptions, setShufflededOptions] = useState([] as any[]);

  const DROPPABLE_MATCHING_QUESTION_MATCHED_ITEM = "droppable_matching_question_matched_item_";
  const DROPPABLE_MATCHING_QUESTION_ITEM = "droppable_matching_question_item_";

  const shuffle = (a: any[]) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  useEffect(() => {
    if (props.question.matchings && props.question.matchings.length > 0) {
      const matchesList = props.question.matchings[0].map(item => ({ left: item.order, right: null }));

      setMatches(matchesList);

      setShufflededOptions(shuffle(props.question.matchings[1]));
    }
  }, [props.question.matchings]);

  const onDragEnd = (e: any) => {
    if (e && e.destination && e.source) {
      const itemOrder = e.destination.droppableId.split(DROPPABLE_MATCHING_QUESTION_ITEM)[1];
      let matchedItemOrder = "";

      if (e.source.droppableId.includes(DROPPABLE_MATCHING_QUESTION_MATCHED_ITEM)) {
        matchedItemOrder = e.source.droppableId.split(DROPPABLE_MATCHING_QUESTION_MATCHED_ITEM)[1];

        const updatedList = matches.map(item => {
          if (Number(item.left) === Number(itemOrder)) {
            return ({ ...item, right: `${matchedItemOrder}` });
          }

          return item;
        });

        setMatches(updatedList);
        props.onMatchChange(updatedList);
      } else {
        const movedList = move(e.source.index, e.destination.index, matches);

        const updatedList = movedList.map((item, index) => ({ left: `${index + 1}`, right: `${item.right}` }));

        setMatches(updatedList);
        props.onMatchChange(updatedList);
      }
    }
  };

  const getMatchedItem = (matching: any, index: number): any => {
    if (props.question.matchings && props.question.matchings[index]) {
      const obj = props.question.matchings[index].find(mtch => mtch.order === Number(matches.find(mtch => Number(mtch.left) === Number(matching.order)).right));
      return obj ? obj : { matched_item: "" };
    }

    return ({
      matched_item: "",
    });
  };

  const hasMatchedItem = (index: number) => matches && matches[index] && !isNil(matches[index].right) && Number.isInteger(Number(matches[index].right));

  const dropPlaceClass = (index: number, isUsingPlaceholder: boolean) => classNames({
    [styles.dropPlace]: true,
    [styles.dropPlaceEmpty]: !hasMatchedItem(index),
    [styles.dropPlaceIsUsingPlaceholder]: isUsingPlaceholder,
  });

  const matchedItemPlacedClass = (isDraggingOver: boolean) => classNames({
    [styles.matchedItemPlaced]: true,
    [styles.matchedItemPlacedDraggingOver]: isDraggingOver,
  });

  return (
    <div className={styles.mainContainer}>
      <QuestionHeaderOnline question={props.question}/>
      <QuestionImageOnline question={props.question}/>
      <div
        data-type="question-answers-mc-sa"
        className={styles.container}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            id={`dnd_matching_question_container_${props.question.id}`}
            className={styles.dndMatchingQuestionContainer}
          >
            {(props.question.matchings && props.question.matchings.length > 0) &&
              props.question.matchings[0].map((matching: matchingItemModel, index: number) => (
                <div className={styles.itemWrapper} key={`${matching.id}_${index}`}>
                  <Droppable droppableId={`${DROPPABLE_MATCHING_QUESTION_ITEM}${matching.order}`}>
                    {(provided, snapshot) => (
                      <div className={styles.itemContainer}>
                        <div
                          className={styles.itemText}
                          dangerouslySetInnerHTML={{
                            __html: `${matching.item_html ? DOMPurify.sanitize(matching.item_html) : DOMPurify.sanitize(matching.item)}`,
                          }}
                        ></div>
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={dropPlaceClass(index, !!snapshot.isUsingPlaceholder)}
                        >
                          <Draggable
                            key={`matched_${matching.id}_${matching.order}`}
                            draggableId={`matched_${matching.id}`}
                            index={index}
                            isDragDisabled={!hasMatchedItem(index) || props.isReadOnly}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={matchedItemPlacedClass(!!snapshot.draggingOver)}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${(hasMatchedItem(index) && props.question.matchings.length > 1) ? DOMPurify.sanitize(getMatchedItem(matching, 1).matched_item_html) : ""}`,
                                }}
                              >
                              </div>
                            )}
                          </Draggable>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </div>
              ))
            }
            <div className={styles.optionsContainer}>
              {shufflededOptions.length > 0 &&
                shufflededOptions
                  .filter(item => !matches.find((mtch: any) => Number(mtch.right) === Number(item.order)))
                  .map((matching: any, index: number) => {
                    return (
                      <Droppable key={`${matching.id}_${index}`} droppableId={`${DROPPABLE_MATCHING_QUESTION_MATCHED_ITEM}${matching.order}`} isDropDisabled>
                        {(provided) => (
                          <div ref={provided.innerRef} className={styles.notMatchedWrapper}>
                            <Draggable
                              key={`not_matched_${matching.id}_${matching.order}`}
                              draggableId={`not_matched_${matching.id}`}
                              index={index}
                              isDragDisabled={props.isReadOnly}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.matchedItem}
                                  dangerouslySetInnerHTML={{
                                    __html: `${matching.matched_item_html ? DOMPurify.sanitize(matching.matched_item_html) : DOMPurify.sanitize(matching.matched_item)}`,
                                  }}
                                ></div>
                              )}
                            </Draggable>
                          </div>
                        )}
                      </Droppable>
                    );
                  })
              }
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default QuestionMatchingOnline;
