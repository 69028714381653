import pathOr from "ramda/src/pathOr";
import sortBy from "ramda/src/sortBy";
import prop from "ramda/src/prop";

// Types
import {
  classStateModel,
  classActionModel,
  studentModel,
} from "../../../models/redux/class";

const initialState: classStateModel = {
  id: "",
  name: "",
  students: [],
  studentDetails: {
    percentage_points: 0,
    number: 0,
    reports: [],
  },
  tests: [],
  in_progress: {
    delete_class: false,
    update_class: false,
  },
  errors: {
    delete_class: [],
    update_class: [],
  },
  subject: {
    id: "",
    name: "",
    type: "",
  },
};

export const classReducer = (
  state = initialState,
  action: classActionModel,
) => {
  switch (action.type) {
    case "GET_CLASS_SUCCESS":
      return {
        ...state,
        ...pathOr({}, ["payload", "data"], action),
      };
    case "DELETE_CLASS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          delete_class: true,
        },
        errors: {
          ...state.errors,
          delete_class: [],
        },
      };
    case "DELETE_CLASS_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          delete_class: false,
        },
        errors: {
          ...state.errors,
          delete_class: [],
        },
      };
    case "DELETE_CLASS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          delete_class: false,
        },
        errors: {
          ...state.errors,
          delete_class: pathOr([], ["payload", "errors"], action),
        },
      };
    case "UPDATE_CLASS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_class: true,
        },
        errors: {
          ...state.errors,
          update_class: [],
        },
      };
    case "UPDATE_CLASS_SUCCESS":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_class: false,
        },
        errors: {
          ...state.errors,
          update_class: [],
        },
      };
    case "UPDATE_CLASS_FAILURE":
      return {
        ...state,
        in_progress: {
          ...state.in_progress,
          update_class: false,
        },
        errors: {
          ...state.errors,
          update_class: pathOr([], ["payload", "errors"], action),
        },
      };
    case "GET_STUDENTS_SUCCESS":
      return {
        ...state,
        students: sortBy(
          prop("number"),
          pathOr([], ["payload", "data"], action).map((item: studentModel) => ({
            ...item,
            number: Number(item.number),
          })),
        ),
      };
    case "GET_STUDENT_DETAILS":
      return {
        ...state,
        studentDetails: initialState.studentDetails,
      };
    case "GET_STUDENT_DETAILS_SUCCESS":
      return {
        ...state,
        studentDetails: {
          ...pathOr(initialState.studentDetails, ["payload", "data"], action),
        },
      };
    case "RESET_CLASS_ERRORS":
      return {
        ...state,
        errors: {
          update_class: [],
          delete_class: [],
        },
      };
    case "UNMOUNT_CLASS":
      return {
        ...state,
        id: "",
        name: "",
        students: [],
        tests: [],
        in_progress: {
          delete_class: false,
          update_class: false,
        },
        errors: {
          delete_class: [],
          update_class: [],
        },
      };
    default:
      return state;
  }
};
