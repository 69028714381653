import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useSpring, animated } from "react-spring";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";

import translate from "../../../services/translate";

import Input from "../Input";

import { ReactComponent as TimesIcon } from "../../../assets/images/times.svg";

import styles from "./styles.module.scss";

interface Props {
  setVideoModal: (value: boolean) => any;
  setVideoHTML: (video: any) => any;
}

export const VideoModal = (props: Props) => {
  const [videoURL, setVideoURL] = useState("");

  const modalProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  const modalContainerClass = classNames({
    [styles.modalContainer]: true,
    [styles.videoModal]: true,
  });

  const closeVideoModal = () => {
    props.setVideoModal(false);
    setVideoURL("");
  };

  const getEmbedURL = (url: string) => {
    if (url.includes("?v=")) {
      const id = url.split("?v=")[1];
      return `https://www.youtube.com/embed/${id}`;
    }

    if (url.includes("https://youtu.be/")) {
      const id = url.split("https://youtu.be/")[1];
      return `https://www.youtube.com/embed/${id}`;
    }

    return "";
  };

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <animated.div style={modalProps} className={modalContainerClass}>
        <OutsideClickHandler onOutsideClick={closeVideoModal}>
          <button type="button" onClick={closeVideoModal}>
            <TimesIcon/>
          </button>
          <div className={styles.modal}>
            <div className={styles.videoModalContent}>
              <h2>{translate("common.enter-video-url")}</h2>
              <Input
                id="video-url-input"
                name="video-url"
                value={videoURL}
                type="text"
                onChange={(e: any) => {
                  setVideoURL(e.target.value);
                }}
              />
              <button
                type="button"
                className={styles.saveVideoButton}
                onClick={() => {
                  props.setVideoHTML(getEmbedURL(videoURL));
                  closeVideoModal();
                }}
              >
                <span>{translate("global.save")}</span>
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      </animated.div>
    </div>,
    document.getElementById("modal-video") as HTMLElement,
  );
};

export default VideoModal;
