import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import Input from "../Input";

import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";
import { countries, getCountryName } from "../../../services/common/countries";

import { ReactComponent as ArrowDownGrey } from "../../../assets/images/small_arrow_down_grey.svg";

// Types
import { userStateModel } from "../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  setCountryCode: (code: string) => any;
  setCountry: (code: string) => any;
  countryCode: string;
  country: string;
  errors: any;
  user: userStateModel;
}

export const CountriesSelect = (props: Props) => {
  const [countryList, setCountryList] = useState(countries);
  const [showCountryDropdown, toggleCountryDropdown] = useState(false);

  return (
    <div className={styles.inputContainer}>
      <OutsideClickHandler
        onOutsideClick={(e: any) => {
          outsideClickHelper(e, "dropdown", () => toggleCountryDropdown(false));
          if (!props.countryCode) {
            props.setCountryCode("");
            props.setCountry("");
          } else {
            const country = countries.find(
              (item: any) =>
                item.code.toLocaleLowerCase() ===
                props.countryCode.toLocaleLowerCase(),
            ) as any;
            if (country) {
              props.setCountry(getCountryName(country, props.user.language));
            }
          }
          const list = countries.filter((item: any) =>
            getCountryName(item, props.user.language)
              .toLocaleLowerCase()
              .includes(props.country.toLocaleLowerCase()),
          );
          setCountryList(list);
        }}
      >
        <div className={styles.wrapper}>
          <div onClick={() => toggleCountryDropdown(true)}>
            <div>
              <Input
                id="country"
                type="text"
                name="country"
                value={props.country}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const list = countries.filter((item: any) =>
                    getCountryName(item, props.user.language)
                      .toLocaleLowerCase()
                      .includes(e.target.value.toLocaleLowerCase()),
                  );
                  setCountryList(list);
                  props.setCountry(e.target.value);
                }}
                label={translate("profile.country")}
                icon={() => <ArrowDownGrey/>}
                error={props.errors}
              />
            </div>
          </div>
          {showCountryDropdown && (
            <div className={styles.dropdown}>
              <ul>
                {countryList.map((item: any) => (
                  <li key={item.code}>
                    <button
                      type="button"
                      onClick={() => {
                        props.setCountryCode(item.code);
                        props.setCountry(
                          getCountryName(item, props.user.language),
                        );
                        toggleCountryDropdown(false);
                      }}
                    >
                      {getCountryName(item, props.user.language)}
                    </button>
                  </li>
                ))}
                {!countryList.length && (
                  <li>
                    <span>{translate("global.no-results")}</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default CountriesSelect;
