export const en = {
  global: {
    "back-to-homepage": "back to homepage",
    "email-address": "e-mail address",
    password: "password",
    "you-did-it": "everything went well!",
    "email-has-been-sent":
      "The link has been sent successfully. Check your email inbox.",
    "forgot-password": "forgot password?",
    send: "send",
    "pick-up": "Pick",
    subject: "subject",
    subjects: "subjects",
    next: "next",
    "sign-out": "sign out",
    create: "create",
    back: "back",
    discover: "discover",
    "teacher-panel": "teacher panel",
    "dashboard-desc": "everything you need in one place...",
    edit: "edit",
    profile: "profile",
    classes: "team",
    students: "students",
    tests: "tests",
    new: "new",
    test: "test",
    accept: "I accept",
    "this-place-is-empty": "this place is still empty",
    testname: "test name",
    yes: "yes",
    no: "no",
    "all-tests": "all tests",
    delete: "delete",
    duplicate: "duplicate",
    "see-more": "see more",
    "no-results": "no results",
    about: "about",
    "privacy-policy": "privacy policy",
    "terms-and-conditions": "terms of use",
    help: "help",
    folders: "folders",
    other: "other",
    "students-list": "Students",
    "date-added": "Added at",
    details: "details",
    warning: "warning",
    change: "change",
    "extend-validity": "extend",
    "days-till-subscription-over-two":
      "This many days are left until the end of your subscription period",
    "average-score": "Average score",
    save: "save",
    code: "code",
    finish: "finish",
    "my-subjects": "my subjects",
    cancel: "cancel",
    "get-a-discount": "get a discount",
    "referral-desc":
      "for each friend you will receive a 50% discount on subscription",
    "invite-your-friends": "invite your friends",
    "recommend-to-friends": "recommend to friends",
    "history-of-referrals": "history of referrals",
    "your-referral-code": "your referral code",
    "number-of-completed-referral-invitations-desc":
      "This many people signed up after your invitation",
    "sign-up-date": "Sign up date",
    "sign-up-confirmation-date": "Sign up confirmation date",
    "registration-status": "Registration status",
    "discount-received": "Discount received",
    "identification-number-short": "ID",
    "referral-status-finished": "finished",
    "referral-status-in-progress": "in progress",
    copy: "copy",
    // and: "and",
    "terms-of-use": "Terms of Use",
    copied: "copied",
    "dashboard-mobile-app-box-heading":
      "Have you already downloaded our mobile scanning app?",
    "dashboard-mobile-app-box-desktop-text-1": "Scan QR code",
    "dashboard-mobile-app-box-desktop-text-2": "and download a free",
    "dashboard-mobile-app-box-desktop-text-3": "mobile app for your device",
    "dashboard-mobile-app-box-mobile-text-1": "Download for free",
    "save-changes": "save changes",
    "my-classes": "my teams",
    "duplicate-test-description-2":
      "Choose where the test should be duplicated",
    "tests-base": "tests base",
    summary: "summary",
    points: "Points",
    questions: "questions",
    fold: "fold",
    unfold: "unfold",
    "leave-demo": "leave demo",
    variant: "range",
    updated: "Updated!",
    "test-online": "test online",
    close: "close",
    "best-match": "best match",
    "most-popular": "most popular",
    newest: "newest",
    sort: "sort",
    lessons: "lessons",
    lesson: "lesson",
    "premium-account": "Pro account",
    "free-plan-limit-exceeded": "You've reached your limit",
    "premium-plan-description":
      "Buy Pro plan and use all features without limits",
    premium: "Pro",
    "with-wisdy": "with Wisdy",
    "you-can-do-more": "you can do more",
    "formative-assessment": "formative assessment",
    "formative-assessment-shortcut": "FA",
    "access-code": "access code",
    congratulations: "congratulations",
    search: "search",
    "changes-have-been-saved": "changes have been saved",
    "changes-have-not-been-saved": "changes haven't been saved",
    optional: "optional",
    show: "show",
  },
  sign_up: {
    "sign-up": "sign up",
    registration: "sign up",
    "repeat-password": "repeat password",
    "password-validation":
      "The password must contain at least 8 characters, one upper case letter and one number",
    "privacy-policy-acceptance-first-part":
      "By checking this box you confirm that you have read our",
    "privacy-policy-acceptance-link-part": "Privacy Policy",
    "privacy-policy-acceptance-last-part":
      "and that you have read and agree to our",
    "referral-code-error-text":
      "The discount code you want to use is incorrect.\nCan you double check, please?.",
    "all-subjects": "all subjects",
    "a-test": "a test",
    "a-class-accusative": "a team",
  },
  sign_in: {
    "sign-in": "sign in",
    login: "sign in",
    "dont-have-an-account-first-part": "don't have",
    "dont-have-an-account-second-part": "an account?",
    "sign-in-page-title": "Create, assess, analyze - faster!",
    "sign-in-page-subtitle-1": "Create a",
    "sign-in-page-subtitle-2": "free account",
    "sign-in-page-subtitle-3":
      " and let us provide\nyou with tools that will make your work\neasier and help you become a better\nteacher.",
  },
  account_confirmation: {
    "account-cannot-be-confirmed":
      "It appears your confirmation link has expired. To complete the account registration, click \"send again\" and we will send you a new link. ",
    "send-again": "send again",
    ups: "upss...",
  },
  forgot_password: {
    "forgot-password-description":
      "Enter below the email address you provided when creating the account.We will send you a link to a page where you can easily create a new password.",
  },
  reset_password: {
    "reset-password-description":
      "You have sent us a password reset request. Now you can set a new one, remember that it must consist of min. 8 characters, one upper case letter and one number.",
    "save-new-password": "save new password",
    "new-password": "new password",
    "repeat-new-password": "repeat new password",
    "reset-password": "Change your password",
  },
  select_subject: {
    "pick-up-subject-desc":
      "Choose a subject and click \"confirm\" to start adding questions to your test.",
    "pick-up-subjects-desc": "pick a subject to move on",
    "pick-up-subjects-tooltip": "to proceed, you must select at least one item",
    choice: "choose",
    "subject-second": "subject",
    "pick-up-subjects-demo-desc": "select subject you want to create test from",
  },
  class: {
    "class-accusative": "team",
    "create-class-desc": "enter the team name and add the number of students",
    classname: "team name",
    "add-class": "add a team",
    "number-of-students": "number of students",
    "add-class-success-desc-first": "your team has been successfully added.",
    "add-class-success-desc-second":
      "now you can go to the tab with the new team and create your first test.",
    "remember-that": "remember that",
    "add-class-success-desc-third":
      "you can edit the items you have assigned to yourself in the \"Discover\" tab by clicking on \"My items\".",
    "go-to-class": "go to team",
    class: "team",
    "select-or-create-new-class": "Select or create a new team",
    or: "or",
    actions: "actions",
    "delete-student-confirmation":
      "are you sure you want to delete this student?",
    "delete-student": "delete student",
    "student-has-been-deleted": "student has been deleted",
    "created-at": "created at",
    "average-score-from-all-tests": "average score from all tests",
    reports: "reports",
    "delete-class-confirmation": "write team name if you want to delete it",
    "class-has-been-deleted": "team has been deleted",
    "select-education-level-description":
      "If you want to add a curriculum to your team",
    "variant-or-variants": "range(s)",
    "curriculum-is-assigned": "Curriculum is assigned",
    "education-level": "education level",
    "classes-limit-paid-version-description":
      "Non-Pro users can create only two teams",
  },
  classes: {
    "classes-list": "Teams",
  },
  creator: {
    "select-question-type": "select a question type",
    punctation: "Score",
    "number-of-correct-answers": "single/multiple answer",
    "add-picture": "add a picture",
    "delete-question": "delete question",
    "add-next": "add next",
    "delete-question-confirmation":
      "are you sure you want to delete this question?",
    answer_choices_info:
      "To mark the correct answer, click in the box to the left of the answer",
    "add-answer-choice": "Add an answer",
    "true-false-desc-1": "For the following statements/questions, pick",
    "true-false-desc-2": " for True or",
    "true-false-desc-3": "for False",
    "quit-preview-mode": "exit preview mode",
    "show-preview-mode": "show preview mode",
    "finish-test-description-1": "your test has been saved successfully.",
    "test-instruction-heading": "answer sheet instruction",
    "test-instruction-desc-1":
      "Mark the answers to multiple choice questions in the student's section of the answer sheet.",
    "test-instruction-desc-2": "Mark",
    "test-instruction-desc-3":
      "the relevant answers. If you made an error, then circle the incorrect answer",
    "test-instruction-desc-4": "and then mark the correct one",
    "add-picture-desc": "Click here to upload a picture",
    "allowed-file-formats": "Acceptable file types",
    crop: "crop",
    "total-score": "Total score",
    "add-notes-to-question-description":
      "space for the student to enter a response",
    "add-gap": "Add gap",
    "fill-in-the-gaps-show-answers-info-1":
      "Don't worry about answers order in the box -",
    "fill-in-the-gaps-show-answers-info-2":
      "We will automatically shuffle them",
    "fill-in-the-gaps-show-correct-answers-in-the-box":
      "Show correct answers in the box",
    "fill-in-the-gaps-add-alternative-answers":
      "Add alternative correct answers",
    "fill-in-the-gaps-add-alternative-answer": "Add alternative correct answer",
    "fill-in-the-gaps-is-case-sensitive": "Pay attention to capitals",
    "fill-in-the-gaps-main-answer": "Main answer",
    "fill-in-the-gaps-add-alternative-answers-info-1":
      "If you want to add alternative answers,",
    "fill-in-the-gaps-add-alternative-answers-info-2":
      "add and fill in at least one gap",
    "time-limit-1": "Time",
    "time-limit-2": "limit",
    "time-limit-desc":
      "Decide how much time your Students will have to complete the test",
    "no-limit": "No limit",
    "time-limit-or-pass-different-value": "Or enter different value in minutes",
    "questions-base-no-access-text":
      "Questions base is available for Pro users",
    assigned: "assigned",
    "add-matching": "Add another match",
    "matching-question-info":
      "Don't worry about shuffling. We'll do it for you.",
    "punctation-per-item": "Item",
    "punctation-per-question": "Question",
    "next-page": "next page",
    "finish-test-online-description-2":
      "Click \"Finish\" button to exit a creator",
    "duplicate-question-from-questions-base-failure-text":
      "We couldn't add all selected questions",
    "assign-skill": "assign a skill",
    "add-skills-to-a-question-descritpion":
      "You can select whole section or specific skills",
    "selected-variant": "selected range",
    "re-assign-skill": "change a skill",
    "answer-sheet-inctruction": "answer sheet instruction",
    "change-questions-order": "change questions order",
    "no-questions-found-for-this-query": "No questions found for this query",
    "back-to-the-creator": "back to the test creator",
    "dnd-tutorial-description":
      "If you want to change position of the question, grab it and drag to the position that you want",
    "type-of-test": "type of test",
    choose: "choose",
    "choose-type-of-test-description":
      "Choose type of test you are interested in",
    "or-add": "or add",
    "question-from-base": "question from base",
    add: "add",
    "times-used": "times used",
    "user-ratings": "user ratings",
    "questions-base": "questions base",
    publish: "publish",
    online: "online",
    paper: "paper",
  },
  test: {
    "finish-test-description-2":
      "select options below to download test in specific version",
    "download-pdf": "download pdf",
    "see-report": "see report",
    "question-type": "question type",
    "download-later": "download later",
    "test-has-been-duplicated": "test has been duplicated",
    "test-has-not-been-duplicated": "test has not been duplicated",
    "change-test-status-to-completed-confirmation-text":
      "are you sure you want to change test status to \"Completed\"? Completed test cannot be edited.",
    "time-limit-answers-will-be-sent-automatically-info":
      "If time is over, the answers will be sent automatically",
    information: "Information",
    "time-limit-time-left-info-1": "You still have",
    "time-limit-time-left-info-2": "to finish your test",
    "test-has-been-downloaded-and-saved":
      "The test has been successfully downloaded and saved",
    "your-test-has-errors-description":
      "Your test has errors. Return to the test creator.",
    "download-test": "download test",
    "duplicate-test": "duplicate test",
    "delete-test": "delete test",
    "preview-settings": "preview settings",
    "compact-version": "compact version",
    instruction: "instruction",
    "print-version": "print version",
    bonuses: "bonuses",
    compact: "compact",
    standard: "standard",
    "public-link-to-online-test-description":
      "Public link to share with students",
    "test-online-success-description-1":
      "Your answers have been sent to the teacher",
    "test-online-success-description-2": "You can close that page",
    "send-answers": "send answers",
    "accept-it": "accept",
    "access-verification-1": "access",
    "access-verification-2": "verification",
    "access-verification-description": "Enter your access code and number",
    "wrong-student-code-or-student-number":
      "Wrong access code or student's number",
    "send-answers-confirmation":
      "Are you sure that you want to send your answers? After sending them, you won't be able to do this again",
    "answers-has-not-been-sent": "Answers has not been sent",
  },
  tests: {
    "last-completed-tests": "Last completed",
    "delete-test-confirmation": "Do you want to delete the test?",
    "number-of-questions": "number of questions",
    "all-tests-prefix": "all",
  },
  report: {
    "score-points": "points",
    "student-score": "student score",
    "generate-pdf": "generate pdf",
    "test-report": "test report",
    "test-participants": "Participants",
    "students-stats": "Students’ statistics",
    "maximum-points-to-get": "Maximum points to get",
    "score-points-short": "Score - points",
    "score-percentage-points-short": "Score - percentage",
    "questions-stats": "Questions’ statistics",
    "question-number-short": "Question no.",
    "correct-answers-short": "Correct answers",
    "wrong-answers-short": "Incorrect answers",
    score: "score",
    "answer-given-short": "answer given",
    "correct-answer-short": "correct answer",
    "question-number-short-2": "Question no.",
    "question-details": "question details",
    "question-content": "question content",
    report: "report",
    "gained-points": "points gained",
    "score-percentage-points": "percentage points",
    "question-type": "question type",
    "closed-questions": "closed questions",
    "open-questions": "open questions",
    "edit-student-score-open-question-information":
      "This test includes open questions. You can fill in a score by clicking",
    "fill-in-a-score": "fill in a score",
    "add-a-score": "add a score",
    "delete-student-result-confirmation-text":
      "are you sure you want to delete this student score?",
    "generate-pdf-limit-paid-version-description":
      "Non-Pro users cannot download reports",
  },
  students: {
    "add-student": "Add a student",
    "student-number": "student no.",
    "student-number-heading": "student's no.",
    student: "student",
    "add-student-desc": "Enter the number of a student that you want to add. ",
    "number-from-lesson-diary": "Number from the school register",
    "student-has-been-added": "new student has been added",
    "student-has-not-been-added": "student has not been added",
    "add-student-double-confirmation-desc":
      "the student you want to add has a number that will affect the numbering of other students.\nAre you sure you want to update the student register numbers?",
  },
  profile: {
    "basic-information": "Basic information",
    subscription: "Subscription",
    "choose-payment-method": "Choose payment method",
    "days-till-subscription-over": "Subscription expires after",
    day: "day",
    days: "days",
    "your-subscription-is-active": "Your subscription is active",
    "your-subscription-is-not-active": "Your subscription is active",
    "credit-card": "credit card",
    transfer: "transfer",
    "payment-method": "payment method",
    "card-number": "card number",
    "card-expiry": "expiration date",
    "card-cvc": "CVC",
    "credit-card-info-desc": "credit card info",
    "chosen-payment-method": "chosen payment method",
    "chosen-currency": "chosen currency",
    "save-credit-card": "save credit card",
    "change-payment-method": "change payment method",
    "delete-credit-card-info": "delete credit card",
    "delete-credit-card-confirmation":
      "Are you sure you want to delete credit card?",
    "delete-credit-card-change-method-confirmation":
      "Changing the payment method is associated with the deletion of your current card details.\nAre you sure?",
    "payment-has-been-processed": "The payment has been processed",
    "payment-has-not-been-processed": "The payment has not been processed",
    "credit-card-has-been-added": "Your card has been added",
    "credit-card-has-not-been-added": "Your card has not been added",
    "actual-subscription-amount": "next subscription price",
    "amount-before-discount": "Before discount",
    "payment-info-discount-desc":
      "This is the price that you’ll pay next time your subscription is renewed",
    "available-discount-codes": "Your discount codes",
    "discount-number": "Discount",
    activation: "Activation",
    used: "Used",
    activate: "Activate",
    "no-discount-codes-desc":
      "We are sorry but you do not currently have any discount codes.",
    "see-how-to-get-discount": "I want a discount",
    "payment-info-tooltip-desc":
      "Activate the available discount code to reduce the subscription price",
    "you-can-use-one-discount-per-transaction-desc":
      "REMEMBER! You can only use one discount per transaction.",
    "invoice-details-1": "Invoice",
    "invoice-details-2": "details",
    "fill-out-form-below": "fill out form below",
    "fill-out-invoice-details": "fill out invoice details",
    "company-name": "company name",
    streetname: "streetname",
    "flat-number": "house number",
    "building-number": "building number",
    city: "city",
    "postal-code": "postal code",
    country: "country",
    "tin-number": "TIN",
    "first-name": "first name",
    "last-name": "last name",
    "payment-transfer-7-days-tooltip-text":
      "This form of payment can only be selected if the account is valid less than 7 days",
    "payment-transfer-is-not-supported-currency":
      "Chosen currency is not supported in transfer payments",
    "delete-account": "delete account",
    "delete-account-confirmation":
      "are you sure you want to delete your account?",
    "pricing-promotion-duration-info": "promotion untill end of March",
    "promotion-discount-info": "sale",
    "wisdy-old-price-usd": "",
    "wisdy-old-price-pln": "",
    "change-password": "change password",
    "current-password": "current-password",
    "additional-info": "additional info",
    "invoice-details-2-transfer": "details",
    "active-subscription-description":
      "After the subscription expires, your plan will downgrade to Standard (free plan)",
    "inactive-subscription-description": "",
  },
  test_base: {
    "test-has-not-been-published": "test has not been published",
    "test-has-been-published": "test has been published",
  },
  pricing: {
    "pricing-standard-classes-limit": "2 teams",
    "pricing-standard-tests-limit": "5 tests per month",
    "pricing-standard-curriculum": "Curriculum",
    "pricing-standard-tests-base": "Tests base",
    "pricing-standard-scanning-app": "Free scanning app",
    "pricing-standard-creator": "Advanced Text Editor",
    "pricing-standard-formulas": "Formulas support",
    "pricing-standard-reports-online": "Online reports",
    "pricing-premium-classes": "Unlimited teams",
    "pricing-premium-tests": "Unlimited tests",
    "pricing-premium-questions-base": "Questions base",
    "pricing-premium-reports-pdf": "Reports in PDF format",
    "pricing-premium-compact-version": "Compact version",
    "pricing-premium-paragraph":
      "Pros version includes all functionalities from standard version and:",
    buy: "buy",
    "for-free": "for-free",
    "choose-the-best-plan": "choose the best plan",
    "for-yourself": "for yourself",
    "create-assess-and-analyze":
      "Create, assess and analyse your tests faster and focus on what's most important - teaching",
    "standard-plan": "Standard",
    "premium-plan": "Pro",
  },
  lessons: {
    "add-lesson": "add a lesson",
    // "all-lessons": "all lessons",
    "lesson-topic": "topic",
    "lesson-goal": "goal",
    "lesson-description": "description",
    "lesson-tasks": "tasks",
    "pick-up-subject-for-lesson-description":
      "Select the subject, then click \"Next\", if you want to start creating a lesson",
    "create-lesson": "create a lesson",
    "lesson-has-been-created": "Lesson has been created",
    "lesson-has-not-been-created": "Lesson has not been created",
    "lesson-success-criteria": "success criteria",
    "delete-lesson-confirmation":
      "are you sure you want to delete this lesson?",
    "lesson-has-been-updated": "lesson has been updated",
    // "lesson-has-not-been-updated": "lesson has not been updated",
    "update-lesson": "update a lesson",
    "lesson-has-been-deleted": "lesson has been deleted",
    // "lesson-has-not-been-deleted": "lesson has not been deleted",
    "public-link-to-lesson": "Public link to share with students",
    generate: "generate",
    "public-link-to-lesson-generate-description":
      "Generate public link to a lesson. People, who have this link will have access to this lesson.",
    "public-link-to-lesson-description":
      "Congratulations! You have generated a public link. You can copy it and send it to your students or other person.",
    "generate-new-lesson-public-link": "generate new public link",
    "public-link-to-lesson-has-been-generated":
      "public link to a lesson has been generated",
    "edit-lesson": "edit a lesson",
    "private-lesson-short": "private",
    "public-lesson-short": "public",
    "lesson-status": "lesson's status",
    status: "status",
    "copy-public-link": "Copy public link",
    "generate-link-to-lesson-list": "generate link to a list",
    "public-link-to-lessons-has-been-generated":
      "public link to a list of lessons has been generated",
    "lesson-has-been-duplicated": "lesson has been duplicated",
    "lesson-has-not-been-duplicated": "lesson has not been duplicated",
    "duplicate-a-lesson": "duplicate a lesson",
    "a-lesson": "a lesson",
    "duplicate-lesson-description":
      "Choose where the lesson should be duplicated",
    "lesson-homework": "homework",
  },
  curriculum: {
    "curriculum-has-been-saved": "Curriculum has been added",
    curriculum: "Curriculum",
    "select-curriculum-description": "Select Curriculum for specific subjects",
    "curriculum-has-been-deleted": "Curriculum has been deleted",
    "curriculum-has-not-been-deleted": "Curriculum has not been deleted",
    "skill-has-been-assigned-to-a-question":
      "A skill has been assigned to a question",
    "skill-has-not-been-assigned-to-a-question":
      "A skill has not been assigned to a question",
    "delete-curriculum-short": "delete curriculum",
    "curriculum-progress": "progress",
    "delete-curriculum-confirmation":
      "are you sure you want to delete this Curriculum?",
    tip: "tip",
    "update-class-skills-tip-description":
      "You can select topics which you already accomplished. We will display an information about your progress.",
  },
  common: {
    "compact-version-short": "compact",
    "standard-version-short": "standard",
    "enter-formula": "enter formula",
    "enter-video-url": "enter video url",
    "compact-version-paid-version-description":
      "Compact version is available only for Pro users",
    "list-of-the-symbols-link": "list of the symbols (LaTeX)",
    "generate-new-public-link-description":
      "Generating a new link will deactivate previous one. All people, who had access to it, will no longer be able to read it.",
    "do-you-want-to-continue": "do you want to continue?",
    language: "Language",
  },
  "free-plan-limit-test-description": "Free plan allows you to create 2 tests",
  "wisdy-current-price-pln": "9",
  "wisdy-current-price-usd": "5",
  verification: "verification",
  "access-dative": "access",
  "available-soon": "Available soon",
  "try-later": "try again later",
  "account-has-been-confirmed": "your account has been confirmed",
  "sign-in-header-first-part": "Sign In,",
  "sign-in-header-second-part": "to get started",
  "multiple-choice-question": "multiple choice",
  "open-question": "open",
  "true-false-question": "True / False",
  "enter-question-body": "Enter a question",
  "enter-statement": "Statement/question",
  "subtype-single": "Single",
  "subtype-multiple": "Multiple",
  answer_choice_placeholder: "Answer",
  "test-status-in_progress": "in progress",
  "test-status-completed": "Completed",
  "keep-scanning": "next",
  of: "of",
  "scanned-student-number": "Student no.",
  "scan-again": "scan again",
  "there-was-a-problem": "there was a problem",
  scan: "scan",
  "scan-to-receive-score": "scan answer sheet to receive score",
  "our-advice": "our advice",
  "scanning-hint":
    "be sure that answer sheet is in right position, completely visible and nothing covers it.",
  skipped: "skipped",
  "pass-rate": "Pass rate",
  "no-access-to-camera": "No access to camera",
  "ask-for-camera-access": "Allow",
  "account-validity-period": "Account",
  "points-short": "p.",
  "no-camera-access-desc":
    "Wisdy requires an access to the camera. You can grant it in the phone's settings.",
  "student-has-not-been-deleted": "student has not been deleted",
  absent: "absent",
  "finish-test-inactive-account-1":
    "your test period has finished. Please subscribe if you want continue to use all of Wisdy functionalities",
  "buy-subscription": "buy subscription",
  "class-name-has-been-updated": "team name has been changed",
  "edit-class-name": "edit team name",
  "duplicate-test-description-1": "Do you want to use this test again?",
  "detailed-result": "results",
  "summary-mobile-description-first":
    "To see the summary, fill in the score of open questions",
  "summary-mobile-description-second":
    "No worries! You can do this later in web application",
  "fill-in-score": "fill in",
  "results-of-the-test": "results of the test",
  "delete-curriculum": "delete curriculum",
  "curriculum-has-not-been-saved": "Curriculum has not been saved",
  "download-now": "download now",
  "optional-info": "optional-info",
  "tests-limit-paid-version-description":
    "Non-Pro users can create only five tests",
  "plan-limit-page-heading": "With Wisdy Pro you can do more",
  "free-plan-limit-class-description":
    "Free plan allows you to create two teams",
  "plan-limit-page-button-text": "Go to the pricing",
  "dashboard-mobile-app-box-mobile-text-2": "on your phone",
};
