import { ErrorsPayload, ErrorObject } from "../../common";

export interface discountCode {
  code: string;
  id: string;
  is_used: boolean;
  name: string;
  percent_off: number;
};

export interface invoiceData {
  address: {
    building_number: string;
    city: string;
    country: string;
    flat_number: string;
    postal_code: string;
    street_name: string;
  };
  company_name: string;
  tin: string;
  first_name: string;
  last_name: string;
};

export interface billingStateModel {
  next_payment: {
    base_amount: number | null;
    amount: number | null;
    currency: string;
  };
  discount_codes: discountCode[];
  invoice_data: invoiceData;
  in_progress: {
    update_invoice_data: boolean;
  };
  referrals: referral[];
  account_id: string;
  errors: ErrorObject[];
}

export interface billingActionModel {
  type: string;
  payload?: {
    data?: any;
    errors?: any;
  };
};

// Add Billing Card

const ADD_BILLING_CARD = "ADD_BILLING_CARD";
const ADD_BILLING_CARD_SUCCESS = "ADD_BILLING_CARD_SUCCESS";
const ADD_BILLING_CARD_FAILURE = "ADD_BILLING_CARD_FAILURE";

export interface addBillingCardPayload {
  payment_method_id: string;
};

export interface addBillingCardSuccessPayload {
  data: {
    card_info: {
      last4: string;
      brand: string;
    };
  }
};

export interface addBillingCard {
  ({ payment_method_id }: addBillingCardPayload): any;
};

export interface addBillingCardAction {
  type: typeof ADD_BILLING_CARD;
  payload: addBillingCardPayload;
};

export interface addBillingCardSuccessAction {
  type: typeof ADD_BILLING_CARD_SUCCESS;
  payload: addBillingCardSuccessPayload;
};

export interface addBillingCardFailureAction {
  type: typeof ADD_BILLING_CARD_FAILURE;
  payload: ErrorsPayload;
};

// Delete Billing Card

const DELETE_BILLING_CARD = "DELETE_BILLING_CARD";
const DELETE_BILLING_CARD_SUCCESS = "DELETE_BILLING_CARD_SUCCESS";
const DELETE_BILLING_CARD_FAILURE = "DELETE_BILLING_CARD_FAILURE";

export interface deleteBillingCard {
  (): any;
};

export interface deleteBillingCardAction {
  type: typeof DELETE_BILLING_CARD;
};

export interface deleteBillingCardSuccessAction {
  type: typeof DELETE_BILLING_CARD_SUCCESS;
};

export interface deleteBillingCardFailureAction {
  type: typeof DELETE_BILLING_CARD_FAILURE;
  payload: ErrorsPayload;
};

// Get Billing Next Payment

const GET_BILLING_NEXT_PAYMENT = "GET_BILLING_NEXT_PAYMENT";
const GET_BILLING_NEXT_PAYMENT_SUCCESS = "GET_BILLING_NEXT_PAYMENT_SUCCESS";
const GET_BILLING_NEXT_PAYMENT_FAILURE = "GET_BILLING_NEXT_PAYMENT_FAILURE";

export interface getBillingNextPaymentSuccessPayload {
  data: {
    amount: number;
    base_amount: number;
    currency: string;
  };
};

export interface getBillingNextPayment {
  (): any;
};

export interface getBillingNextPaymentAction {
  type: typeof GET_BILLING_NEXT_PAYMENT;
};

export interface getBillingNextPaymentSuccessAction {
  type: typeof GET_BILLING_NEXT_PAYMENT_SUCCESS;
  payload: getBillingNextPaymentSuccessPayload;
};

export interface getBillingNextPaymentFailureAction {
  type: typeof GET_BILLING_NEXT_PAYMENT_FAILURE;
  payload: ErrorsPayload;
};

// Update Billing Settings

const UPDATE_BILLING_SETTINGS = "UPDATE_BILLING_SETTINGS";
const UPDATE_BILLING_SETTINGS_SUCCESS = "UPDATE_BILLING_SETTINGS_SUCCESS";
const UPDATE_BILLING_SETTINGS_FAILURE = "UPDATE_BILLING_SETTINGS_FAILURE";

export interface updateBillingSettingsPayload {
  currency: string;
};

export interface updateBillingSettings {
  ({ currency }: updateBillingSettingsPayload): any;
};

export interface updateBillingSettingsAction {
  type: typeof UPDATE_BILLING_SETTINGS;
  payload: updateBillingSettingsPayload;
};

export interface updateBillingSettingsSuccessAction {
  type: typeof UPDATE_BILLING_SETTINGS_SUCCESS;
};

export interface updateBillingSettingsFailureAction {
  type: typeof UPDATE_BILLING_SETTINGS_FAILURE;
  payload: ErrorsPayload;
};

// Get Discount Codes

const GET_DISCOUNT_CODES = "GET_DISCOUNT_CODES";
const GET_DISCOUNT_CODES_SUCCESS = "GET_DISCOUNT_CODES_SUCCESS";
const GET_DISCOUNT_CODES_FAILURE = "GET_DISCOUNT_CODES_FAILURE";

export interface getDiscountCodesSuccessPayload {
  data: discountCode[];
};

export interface getDiscountCodes {
  (): any;
};

export interface getDiscountCodesAction {
  type: typeof GET_DISCOUNT_CODES;
};

export interface getDiscountCodesSuccessAction {
  type: typeof GET_DISCOUNT_CODES_SUCCESS;
  payload: getDiscountCodesSuccessPayload;
};

export interface getDiscountCodesFailureAction {
  type: typeof GET_DISCOUNT_CODES_FAILURE;
  payload: ErrorsPayload;
};

// Update Discount Codes

const UPDATE_DISCOUNT_CODES = "UPDATE_DISCOUNT_CODES";
const UPDATE_DISCOUNT_CODES_SUCCESS = "UPDATE_DISCOUNT_CODES_SUCCESS";
const UPDATE_DISCOUNT_CODES_FAILURE = "UPDATE_DISCOUNT_CODES_FAILURE";

export interface updateDiscountCodesPayload {
  discount_code_id: string;
};

export interface updateDiscountCodes {
  ({ discount_code_id }: updateDiscountCodesPayload): any;
};

export interface updateDiscountCodesAction {
  type: typeof UPDATE_DISCOUNT_CODES;
  payload: updateDiscountCodesPayload;
};

export interface updateDiscountCodesSuccessAction {
  type: typeof UPDATE_DISCOUNT_CODES_SUCCESS;
};

export interface updateDiscountCodesFailureAction {
  type: typeof UPDATE_DISCOUNT_CODES_FAILURE;
  payload: ErrorsPayload;
};

// Get Invoice Data

const GET_INVOICE_DATA = "GET_INVOICE_DATA";
const GET_INVOICE_DATA_SUCCESS = "GET_INVOICE_DATA_SUCCESS";
const GET_INVOICE_DATA_FAILURE = "GET_INVOICE_DATA_FAILURE";

export interface getInvoiceDataPayload {
  address: {
    building_number: string;
    city: string;
    country: string;
    flat_number: string;
    postal_code: string;
    street_name: string;
  },
  company_name: string;
  tin: string;
  first_name: string;
  last_name: string;
};

export interface getInvoiceData {
  (): any;
};

export interface getInvoiceDataAction {
  type: typeof GET_INVOICE_DATA;
};

export interface getInvoiceDataSuccessAction {
  type: typeof GET_INVOICE_DATA_SUCCESS;
  payload: getInvoiceDataPayload;
};

export interface getInvoiceDataFailureAction {
  type: typeof GET_INVOICE_DATA_FAILURE;
  payload: ErrorsPayload;
};

// Upade Invoice Data

const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
const UPDATE_INVOICE_DATA_SUCCESS = "UPDATE_INVOICE_DATA_SUCCESS";
const UPDATE_INVOICE_DATA_FAILURE = "UPDATE_INVOICE_DATA_FAILURE";

export interface updateInvoiceDataPayload {
  address: {
    building_number: string;
    city: string;
    country: string;
    flat_number: string;
    postal_code: string;
    street_name: string;
  },
  company_name: string;
  tin: string;
  first_name: string;
  last_name: string;
};

export interface updateInvoiceData {
  ({ address, company_name, tin, first_name, last_name }: updateInvoiceDataPayload, callback: () => any): any;
};

export interface updateInvoiceDataAction {
  type: typeof UPDATE_INVOICE_DATA;
  payload: updateInvoiceDataPayload;
  callback: any;
};

export interface updateInvoiceDataSuccessAction {
  type: typeof UPDATE_INVOICE_DATA_SUCCESS;
};

export interface updateInvoiceDataFailureAction {
  type: typeof UPDATE_INVOICE_DATA_FAILURE;
  payload: ErrorsPayload;
};

// Validate Referrer Account ID

const VALIDATE_REFERRER_ACCOUNT_ID = "VALIDATE_REFERRER_ACCOUNT_ID";
const VALIDATE_REFERRER_ACCOUNT_ID_SUCCESS = "VALIDATE_REFERRER_ACCOUNT_ID_SUCCESS";
const VALIDATE_REFERRER_ACCOUNT_ID_FAILURE = "VALIDATE_REFERRER_ACCOUNT_ID_FAILURE";

export interface validateReferrerAccountIDPayload {
  referrer_account_id: string;
};

export interface validateReferrerAccountID {
  ({ referrer_account_id }: validateReferrerAccountIDPayload): any;
};

export interface validateReferrerAccountIDAction {
  type: typeof VALIDATE_REFERRER_ACCOUNT_ID;
  payload: validateReferrerAccountIDPayload;
};

export interface validateReferrerAccountIDSuccessAction {
  type: typeof VALIDATE_REFERRER_ACCOUNT_ID_SUCCESS;
};

export interface validateReferrerAccountIDFailureAction {
  type: typeof VALIDATE_REFERRER_ACCOUNT_ID_FAILURE;
  payload: ErrorsPayload;
};

// Get Referrals

const GET_REFERRALS = "GET_REFERRALS";
const GET_REFERRALS_SUCCESS = "GET_REFERRALS_SUCCESS";
const GET_REFERRALS_FAILURE = "GET_REFERRALS_FAILURE";

export interface referral {
  confirmed_at: string;
  discount: number;
  email: string;
  registered_at: string;
}

export interface getReferralsSuccessPayload {
  data: {
    account_id: string;
    referrals: referral[];
  };
};

export interface getReferrals {
  (): any;
};

export interface getReferralsAction {
  type: typeof GET_REFERRALS;
};

export interface getReferralsSuccessAction {
  type: typeof GET_REFERRALS_SUCCESS;
  payload: getReferralsSuccessPayload;
};

export interface getReferralsFailureAction {
  type: typeof GET_REFERRALS_FAILURE;
  payload: ErrorsPayload;
};

// Other

export enum creditCardBrand {
  Visa = "visa",
  MasterCard = "mastercard",
  AmericanExpress = "amex",
};

const RESET_BILLING_ERRORS = "RESET_BILLING_ERRORS";

export interface resetBillingErrors {
  (): any;
};

export interface resetBillingErrorsAction {
  type: typeof RESET_BILLING_ERRORS;
};
