import React, { ReactElement } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";


interface Props {
  isOn: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): any;
  leftText?: string | ReactElement;
  rightText?: string | ReactElement;
  isDisabled?: boolean;
  isGrayedOut?: boolean;
};

const Toggle = (props: Props) => {
  const inputContainer = classNames({
    [styles.inputContainer]: true,
    [styles.isChecked]: props.isOn,
    [styles.isDisabled]: props.isDisabled,
    [styles.isGrayedOut]: props.isGrayedOut && !props.isOn,
  });

  return (
    <div className={styles.container}>
      {props.leftText && <span>{props.leftText}</span>}
      <label className={inputContainer}>
        <input
          type="checkbox"
          onChange={props.onChange}
          disabled={props.isDisabled}
        ></input>
        <i/>
      </label>
      {props.rightText && <span>{props.rightText}</span>}
    </div>
  );
};

export default Toggle;
