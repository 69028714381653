import pathOr from "ramda/src/pathOr";

import { getLanguageFromLocalStorage } from "../../../services/api";
import { en } from "../../../services/common/translations/en";
import { pl } from "../../../services/common/translations/pl";
import { isPolish } from "../../../services/common";

// Types
import { appActionModel, appStateModel } from "../../../models/redux/app";

const initialState: appStateModel = {
  translations: {
    isLoading: false,
    data: {},
    hasError: false,
  },
  toast: {
    text: "",
    status: "",
    customIcon: undefined,
  },
};

export const app = (state = initialState, action: appActionModel) => {
  switch (action.type) {
    case "GET_TRANSLATIONS":
      const language = getLanguageFromLocalStorage();
      return {
        ...state,
        translations: {
          ...state.translations,
          data: isPolish(language) ? pl : en,
          hasError: false,
        },
      };
    case "SHOW_TOAST":
      return {
        ...state,
        toast: {
          text: pathOr("", ["payload", "text"], action),
          status: pathOr("", ["payload", "status"], action),
          customIcon: pathOr(undefined, ["payload", "customIcon"], action),
        },
      };
    case "HIDE_TOAST":
      return {
        ...state,
        toast: {
          text: "",
          status: "",
        },
      };
    default:
      return state;
  }
};
