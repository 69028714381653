import React from "react";

import translate from "../../../../../../../../services/translate";

import Modal, { ToggleContent } from "../../../../../../../Common/Modal";

import { ReactComponent as TrashIcon } from "../../../../../../../../assets/images/trash.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../../../../assets/images/trash_alt.svg";

// Types
import { deleteQuestion as deleteQuestionFunction } from "../../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  testID: string;
  classID: string;
  questionID: string;
  deleteQuestion: deleteQuestionFunction;
}

export const DeleteQuestionButton = (props: Props) => {
  return (
    <ToggleContent
      toggle={(show: any) => (
        <button type="button" onClick={show}>
          <TrashIcon/>
          {translate("creator.delete-question")}
        </button>
      )}
      content={(hide: () => {}) => (
        <Modal isSmall hide={hide}>
          <div className={styles.deleteQuestionModal}>
            <TrashAltIcon/>
            <span>{translate("creator.delete-question-confirmation")}</span>
            <div className={styles.buttonContainer}>
              <button type="button" onClick={hide}>
                {translate("global.no")}
              </button>
              <button
                type="button"
                onClick={() => {
                  hide();
                  props.deleteQuestion({
                    testID: props.testID,
                    classID: props.classID,
                    questionID: props.questionID,
                  });
                }}
              >
                {translate("global.yes")}
              </button>
            </div>
          </div>
        </Modal>
      )}
    />
  );
};

export default DeleteQuestionButton;
