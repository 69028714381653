import pathOr from "ramda/src/pathOr";

// Types
import { studentStateModel, studentActionModel } from "../../../models/redux/student";

const initialState: studentStateModel = {
  code: "",
  number: null,
};

export const student = (state = initialState, action: studentActionModel) => {
  switch (action.type) {
    case "GET_TEST":
      return {
        ...state,
        code: pathOr("", ["payload", "studentCode"], action),
        number: pathOr(null, ["payload", "studentNumber"], action),
      };
    case "CREATE_TEST_RESULTS_BY_STUDENT_SUCCESS":
      return {
        ...state,
        code: "",
        number: null,
      };
    default:
      return state;
  }
};
