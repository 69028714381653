import React, { useEffect, useState } from "react";

import Input from "../../../../../../../../Common/Input";

import translate from "../../../../../../../../../services/translate";

import { ReactComponent as TrashIcon } from "../../../../../../../../../assets/images/trash.svg";

// Types
import {
  gapModel,
  replaceQuestionGap as replaceQuestionGapFunction,
} from "../../../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  testID: string;
  questionID: string;
  classID: string;
  replaceQuestionGap: replaceQuestionGapFunction;
  gaps: gapModel[];
  questionInProgress: boolean;
}

export const FillInTheGapsMoreCorrectAnswers = (props: Props) => {
  const [localGaps, setLocalGaps] = useState<gapModel[]>([]);

  useEffect(() => {
    setLocalGaps(props.gaps);
  }, [props.gaps]);

  const updateQuestion = (gaps: gapModel[]) => {
    props.replaceQuestionGap({
      testID: props.testID,
      classID: props.classID,
      questionID: props.questionID,
      names: gaps.map((item) => item.names),
    });
  };

  return (
    <div className={styles.fillInTheGapsMoreCorrectAnswers}>
      {localGaps.map((item, index: number) => (
        <div key={`gap_key_${index}`}>
          {item.names.map((name, nameIndex) => {
            if (nameIndex === 0) {
              return (
                <div
                  key={`gap_key_${index}_${nameIndex}`}
                  className={styles.mainGapName}
                >
                  <span>
                    {index + 1}.{" "}
                    {translate("creator.fill-in-the-gaps-main-answer")}:
                  </span>
                  <span>{name}</span>
                </div>
              );
            }

            return (
              <li key={`gap_key_${index}_${nameIndex}`}>
                <div>
                  <Input
                    id={`gap_${index}_${nameIndex}`}
                    type="text"
                    name={`gap_${index}_${nameIndex}`}
                    value={name}
                    disabled={props.questionInProgress}
                    onChange={(e: any) => {
                      const updatedGaps = localGaps.map((gap, gapIndex) => {
                        if (gapIndex === index) {
                          return {
                            ...gap,
                            names: gap.names.map((item, itemIndex) => {
                              if (itemIndex === nameIndex) {
                                return e.target.value;
                              }

                              return item;
                            }),
                          };
                        }

                        return gap;
                      });

                      setLocalGaps(updatedGaps);
                      updateQuestion(updatedGaps);
                    }}
                  />
                </div>
                <button
                  type="button"
                  disabled={props.questionInProgress}
                  onClick={(e) => {
                    const updatedGaps = localGaps.map((gap, gapIndex) => {
                      if (gapIndex === index) {
                        return {
                          ...gap,
                          names: gap.names.filter(
                            (item, itemIndex) => itemIndex !== nameIndex,
                          ),
                        };
                      }

                      return gap;
                    });

                    setLocalGaps(updatedGaps);
                    updateQuestion(updatedGaps);
                  }}
                >
                  <TrashIcon/>
                </button>
              </li>
            );
          })}
          <button
            className={styles.addAlternativeAnswerButton}
            type="button"
            disabled={props.questionInProgress}
            onClick={() => {
              const updatedGaps = localGaps.map((gap, gapIndex) => {
                if (gapIndex === index) {
                  return {
                    ...gap,
                    names: [...gap.names, ""],
                  };
                }

                return gap;
              });

              setLocalGaps(updatedGaps);
            }}
          >
            <span>
              + {translate("creator.fill-in-the-gaps-add-alternative-answer")}
            </span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default FillInTheGapsMoreCorrectAnswers;
