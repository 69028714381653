import { ofType, ActionsObservable, Epic } from "redux-observable";
import { timer } from "rxjs";
import { map, pluck, debounce } from "rxjs/operators";
import { hideToast } from "../../actions/app";

// Types
import { showToastAction, showToastPayload } from "../../../models/redux/app";

// Toast

export const showToastEpic: Epic = (
  action$: ActionsObservable<showToastAction>,
) =>
  action$.pipe(
    ofType("SHOW_TOAST"),
    pluck("payload"),
    debounce((payload: showToastPayload) =>
      payload.status === "info" ? timer(10000) : timer(3000),
    ),
    map(hideToast),
  );
