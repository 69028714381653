import React, { useEffect, useState } from "react";

import { ReactComponent as InfoIcon } from "../../../../../../../../../assets/images/info.svg";

import Checkbox from "../../../../../../../../Common/Checkbox";
import FillInTheGapsMoreCorrectAnswers from "../MoreCorrectAnswers";

import translate from "../../../../../../../../../services/translate";

// Types
import {
  gapModel,
  typeDataModel,
  replaceQuestionGap as replaceQuestionGapFunction,
  updateQuestion as updateQuestionFunction,
} from "../../../../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  questionID: string;
  testID: string;
  classID: string;
  replaceQuestionGap: replaceQuestionGapFunction;
  gaps: gapModel[];
  typeData: typeDataModel;
  updateQuestion: updateQuestionFunction;
  questionInProgress: boolean;
}

export const FillInTheGapsSettings = (props: Props) => {
  const [hasAlternativeAnswers, setHasAlternativeAnswers] = useState(false);

  useEffect(() => {
    if (props.gaps && !!props.gaps.find((item) => item.names.length > 1)) {
      setHasAlternativeAnswers(true);
    }
  }, [props.gaps]);

  return (
    <div className={styles.fillInTheGapsSettings}>
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={!!props.typeData.check_case_in_gaps}
          onClick={() => {
            props.updateQuestion({
              classID: props.classID,
              testID: props.testID,
              questionID: props.questionID,
              type_data: {
                check_case_in_gaps: !props.typeData.check_case_in_gaps,
              },
            });
          }}
          disabled={props.questionInProgress}
          noIcon
        />
        <button
          type="button"
          disabled={props.questionInProgress}
          onClick={() => {
            props.updateQuestion({
              classID: props.classID,
              testID: props.testID,
              questionID: props.questionID,
              type_data: {
                check_case_in_gaps: !props.typeData.check_case_in_gaps,
              },
            });
          }}
        >
          <span>{translate("creator.fill-in-the-gaps-is-case-sensitive")}</span>
        </button>
      </div>
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={!!props.typeData.show_gap_options}
          onClick={() => {
            props.updateQuestion({
              classID: props.classID,
              testID: props.testID,
              questionID: props.questionID,
              type_data: {
                show_gap_options: !props.typeData.show_gap_options,
              },
            });
          }}
          disabled={props.questionInProgress}
          noIcon
        />
        <button
          type="button"
          disabled={props.questionInProgress}
          onClick={() => {
            props.updateQuestion({
              classID: props.classID,
              testID: props.testID,
              questionID: props.questionID,
              type_data: {
                show_gap_options: !props.typeData.show_gap_options,
              },
            });
          }}
        >
          <span>
            {translate(
              "creator.fill-in-the-gaps-show-correct-answers-in-the-box",
            )}
          </span>
        </button>
      </div>
      <div className={styles.infoRight}>
        <InfoIcon/>
        <span>
          <>
            <span>
              {translate("creator.fill-in-the-gaps-show-answers-info-1")}
            </span>
            <span>
              {translate("creator.fill-in-the-gaps-show-answers-info-2")}
            </span>
          </>
        </span>
      </div>
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={hasAlternativeAnswers}
          onClick={() => {
            if (props.gaps.find((item) => item.names.length > 1)) {
              props.replaceQuestionGap({
                testID: props.testID,
                classID: props.classID,
                questionID: props.questionID,
                names: props.gaps.map((item) => [item.names[0]]),
              });
              setHasAlternativeAnswers(false);
            } else if (hasAlternativeAnswers) {
              setHasAlternativeAnswers(false);
            } else {
              setHasAlternativeAnswers(true);
            }
          }}
          disabled={props.questionInProgress}
          noIcon
        />
        <button
          type="button"
          disabled={props.questionInProgress}
          onClick={() => {
            if (props.gaps.find((item) => item.names.length > 1)) {
              props.replaceQuestionGap({
                testID: props.testID,
                classID: props.classID,
                questionID: props.questionID,
                names: props.gaps.map((item) => [item.names[0]]),
              });
              setHasAlternativeAnswers(false);
            } else if (hasAlternativeAnswers) {
              setHasAlternativeAnswers(false);
            } else {
              setHasAlternativeAnswers(true);
            }
          }}
        >
          <span>
            {translate("creator.fill-in-the-gaps-add-alternative-answers")}
          </span>
        </button>
      </div>
      {hasAlternativeAnswers &&
        !props.gaps.map((item) => item.names[0]).length && (
        <div className={styles.infoRight}>
          <InfoIcon/>
          <span>
            <>
              <span>
                {translate(
                  "creator.fill-in-the-gaps-add-alternative-answers-info-1",
                )}
              </span>
              <span>
                {translate(
                  "creator.fill-in-the-gaps-add-alternative-answers-info-2",
                )}
              </span>
            </>
          </span>
        </div>
      )}
      {hasAlternativeAnswers &&
        !!props.gaps.map((item) => item.names[0]).length && (
        <FillInTheGapsMoreCorrectAnswers
          testID={props.testID}
          questionID={props.questionID}
          classID={props.classID}
          replaceQuestionGap={props.replaceQuestionGap}
          gaps={props.gaps}
          questionInProgress={props.questionInProgress}
        />
      )}
    </div>
  );
};

export default FillInTheGapsSettings;
