import { ofType, ActionsObservable, Epic } from "redux-observable";
import { of, concat } from "rxjs";
import { switchMap, pluck, mergeMap } from "rxjs/operators";

import {
  getLessonsSuccess,
  getLessonsFailure,
  lessonsGenerateAccessTokenSuccess,
  lessonsGenerateAccessTokenFailure,
} from "../../actions/lessons";
import { showToast } from "../../actions/app";

import { apiCall } from "../../../services/api";

// Types
import {
  getLessonsPayload,
  getLessonsAction,
  lessonsGenerateAccessTokenAction,
  lessonsGenerateAccessTokenPayload,
  lessonsGenerateAccessTokenSuccessAction,
} from "../../../models/redux/lessons";
import { XHRPayload } from "../../../models/common";

// Get Lessons

export const getLessonsEpic: Epic = (
  action$: ActionsObservable<getLessonsAction>,
) =>
  action$.pipe(ofType("GET_LESSONS"), pluck("payload"), switchMap(getLessons));

export const getLessons = (payload: getLessonsPayload) => {
  return apiCall(
    `/teachers/classes/${payload.class_id}/lessons${
      payload.access_token ? `?access_token=${payload.access_token}` : ""
    }`,
    "GET",
    {},
    (data: XHRPayload) => getLessonsSuccess(data.response),
    getLessonsFailure,
  );
};

// Generate Lessons access token

export const lessonsGenerateAccessTokenEpic: Epic = (
  action$: ActionsObservable<lessonsGenerateAccessTokenAction>,
) =>
  action$.pipe(
    ofType("LESSONS_GENERATE_ACCESS_TOKEN"),
    switchMap((data) => lessonsGenerateAccessToken(data.payload, data.callback)),
  );

export const lessonsGenerateAccessToken = (
  payload: lessonsGenerateAccessTokenPayload,
  callback?: () => any,
) => {
  return apiCall(
    `/teachers/classes/${payload.class_id}/lessons/access_token`,
    "PATCH",
    {},
    (data: XHRPayload) =>
      lessonsGenerateAccessTokenSuccess(data.response, callback),
    lessonsGenerateAccessTokenFailure,
  );
};

export const lessonsGenerateAccessTokenSuccessEpic: Epic = (
  action$: ActionsObservable<lessonsGenerateAccessTokenSuccessAction>,
) =>
  action$.pipe(
    ofType("LESSONS_GENERATE_ACCESS_TOKEN_SUCCESS"),
    mergeMap(() =>
      concat(
        of(
          showToast({
            text: "lessons.public-link-to-lessons-has-been-generated",
            status: "success",
          }),
        ),
      ),
    ),
  );
