import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EducationLevelBox from "../../../../../../../Curriculum/EducationLevel/Box";
import CurriculumListItem from "../../../../../../../Curriculum/ListItem";

import translate from "../../../../../../../../../services/translate";

import {
  getCurriculumsList,
  curriculumsListItem,
  updateSelectedCurriculumsArray,
} from "../../../../../../../../../services/common/curriculums";

// Types
import {
  curriculumStateModel,
  educationLevel,
  getEducationLevel as getEducationLevelFunction,
  getCurriculums as getCurriculumsFunction,
  assignCurriculums as assignCurriculumsFunction,
} from "../../../../../../../../../models/redux/curriculum";

import styles from "./styles.module.scss";

interface Props {
  curriculum: curriculumStateModel;
  subject: string;
  subjectID: string;
  getEducationLevel: getEducationLevelFunction;
  assignCurriculums: assignCurriculumsFunction;
  getCurriculums: getCurriculumsFunction;
  setSelectedCurriculums: (ids: string[]) => any;
  selectedCurriculums: string[];
  classId: string;
}

export const QuestionCurriculumSelectCurriculum = (props: Props) => {
  const [selectedEducationLevelID, setSelectedEducationLevelID] = useState("");

  useEffect(() => {
    props.getEducationLevel({ subjects: [props.subjectID] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEducationLevelID) {
      props.getCurriculums({
        id: selectedEducationLevelID,
        subject_id: props.subjectID,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEducationLevelID]);

  const getFilteredCurriculumList = () =>
    getCurriculumsList(props.curriculum.curriculums).filter(
      (item: curriculumsListItem) => item.subject === props.subject,
    );

  return (
    <div className={styles.container}>
      <h2>
        {translate("global.pick-up")}&nbsp;
        <span className={styles.primaryHeadingText}>
          {translate("class.education-level")}
        </span>
      </h2>
      <div className={styles.educationLevelContainer}>
        {props.curriculum.in_progress.get_education_level ? (
          <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
        ) : (
          props.curriculum.education_level.map((item: educationLevel) => {
            return (
              <EducationLevelBox
                key={item.id}
                id={item.id}
                level={item.level}
                onClick={(id: string) => {
                  props.setSelectedCurriculums([]);
                  setSelectedEducationLevelID(id);
                }}
                isChecked={item.id === selectedEducationLevelID}
              />
            );
          })
        )}
      </div>
      {props.curriculum.in_progress.get_curriculums ? (
        <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
      ) : (
        !!props.curriculum.curriculums.length && (
          <div className={styles.curriculumsContainer}>
            <h2>
              {translate("global.pick-up")}&nbsp;
              <span className={styles.primaryHeadingText}>
                {translate("global.variant")}
              </span>
            </h2>
            {getFilteredCurriculumList().map((item: curriculumsListItem) => {
              return (
                <CurriculumListItem
                  key={item.subject}
                  list={item.list}
                  selectedCurriculums={props.selectedCurriculums}
                  onClick={(id: string) => {
                    props.setSelectedCurriculums(
                      updateSelectedCurriculumsArray(
                        id,
                        props.selectedCurriculums,
                        item.list,
                      ),
                    );
                  }}
                  singleSubject
                />
              );
            })}
            <button
              onClick={() => {
                props.assignCurriculums({
                  curriculums: props.selectedCurriculums,
                  class_id: props.classId,
                });
              }}
            >
              {translate("global.save")}
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default QuestionCurriculumSelectCurriculum;
