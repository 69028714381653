import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as EyeIcon } from "../../../../../assets/images/eye_icon.svg";
import { ReactComponent as ArrowIcon } from "../../../../../assets/images/small_arrow_down_grey.svg";

import translate from "../../../../../services/translate";

// Types
import { studentDetailsReportModel } from "../../../../../models/redux/class";

import styles from "./styles.module.scss";

interface Props {
  reports: studentDetailsReportModel[];
  match: any;
}

export const StudentDetailsReports = (props: Props) => {
  const [reportInfo, setReportInfo] = useState<string[]>([]);

  const arrowClass = (id: string) =>
    classNames({
      [styles.toggleIcon]: true,
      [styles.active]: reportInfo.includes(id),
    });

  const toggleArrow = (id: string) => {
    const isChecked = reportInfo.includes(id);

    if (isChecked) {
      setReportInfo(reportInfo.filter((value) => value !== id));
      return;
    }

    setReportInfo(reportInfo.concat(id));
  };

  return (
    <ul className={styles.reports}>
      <li className={styles.headingItem}>
        <div className={styles.testName}>
          <span>{translate("global.testname")}</span>
        </div>
        <div className={styles.subject}>
          <span>{translate("global.subject")}</span>
        </div>
        <div className={styles.createdAt}>
          <span>{translate("class.created-at")}</span>
        </div>
        <div className={styles.percentagePoints}>
          <span>{translate("report.score-percentage-points-short")}</span>
        </div>
        <div className={styles.actions}></div>
      </li>
      {props.reports.map((item: studentDetailsReportModel) => (
        <li className={styles.listItem} key={item.id}>
          <div className={styles.testName}>
            <span>{item.test_name}</span>
          </div>
          <div className={styles.subject}>
            <span>{item.subject}</span>
          </div>
          <div className={styles.createdAt}>
            <span>{item.created_at}</span>
          </div>
          <div className={styles.percentagePoints}>
            <span>{item.percentage_points}%</span>
          </div>
          <div className={styles.actions}>
            <div className={styles.tooltipContainer}>
              <ReactTooltip
                id="tooltip"
                place="left"
                effect="solid"
                className={styles.tooltip}
              >
                <p>{translate("global.details")}</p>
              </ReactTooltip>
              <i data-tip data-for="tooltip">
                <Link
                  to={`/test/${item.test_id}/report/student/${props.match.params.studentID}`}
                >
                  <EyeIcon/>
                </Link>
              </i>
            </div>
            <button
              className={arrowClass(item.id)}
              onClick={() => toggleArrow(item.id)}
            >
              <ArrowIcon/>
            </button>
          </div>
          {reportInfo.includes(item.id) && (
            <div className={styles.dropDownContainer}>
              <ul>
                <li className={styles.subject}>
                  <span>{translate("global.subject")}</span>
                  <span>{item.subject}</span>
                </li>
                <li className={styles.createdAt}>
                  <span>{translate("class.created-at")}</span>
                  <span>{item.created_at}</span>
                </li>
                <li className={styles.percentagePoints}>
                  <span>
                    {translate("report.score-percentage-points-short")}
                  </span>
                  <span>{item.percentage_points}%</span>
                </li>
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default StudentDetailsReports;
