import React, { ReactElement } from "react";
import classNames from "classnames";

import { ReactComponent as Icon } from "../../../../assets/images/wisdy_icon_2.svg";
import { ReactComponent as Logo } from "../../../../assets/images/wisdy_logo.svg";
import { ReactComponent as Check } from "../../../../assets/images/check_icon.svg";
import { ReactComponent as Times } from "../../../../assets/images/times_circle_icon.svg";

import { ReactComponent as Cloud } from "../../../../assets/images/sign_up/cloud.svg";
import { ReactComponent as Card } from "../../../../assets/images/sign_up/card.svg";
import { ReactComponent as Bulb } from "../../../../assets/images/sign_up/bulb.svg";

import translate from "../../../../services/translate";

import styles from "./styles.module.scss";

interface Props {
  heading: string | ReactElement;
  children: ReactElement;
  success?: boolean;
  error?: boolean;
  hideIcon?: boolean;
  info?: () => any;
  isSignIn?: boolean;
}

const AuthContainer = (props: Props) => {
  const headingClass = classNames({
    [styles.error]: props.error,
    [styles.success]: props.success,
  });

  return (
    <section className={styles.container}>
      <div className={styles.left}>
        {!props.isSignIn ? (
          <div className={styles.info}>
            <Cloud className={styles.cloud}/>
            <Card className={styles.card}/>
            <Bulb className={styles.bulb}/>
            <Logo className={styles.logo}/>
            <h1>{translate("sign_in.sign-in-page-title")}</h1>
            <p>
              {translate("sign_in.sign-in-page-subtitle-1")}{" "}
              <b>{translate("sign_in.sign-in-page-subtitle-2")}</b>
              {translate("sign_in.sign-in-page-subtitle-3")}
            </p>
          </div>
        ) : (
          <Icon/>
        )}
      </div>
      <div className={styles.right}>
        {props.isSignIn && (
          <a
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}`}
            className={styles.logoLink}
          >
            <Logo/>
          </a>
        )}
        <h1 className={headingClass}>
          {props.success && !props.hideIcon && <Check/>}
          {props.error && !props.hideIcon && <Times/>}
          <span>{props.heading}</span>
        </h1>
        {props.info && props.info()}
        {props.children}
      </div>
    </section>
  );
};

export default AuthContainer;
