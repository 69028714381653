import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import translate from "../../../../../services/translate";

import Modal, { ToggleContent } from "../../../../Common/Modal";

// Types
import { History, Location } from "history";
import {
  testStateModel,
  TestStatusTypes,
  updateTest as updateTestFunction,
  getTest as getTestFunction,
} from "../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  test: testStateModel;
  classID: string;
  updateTest: updateTestFunction;
  getTest: () => getTestFunction;
}

export const TestDetailsHeader = (props: Props) => {
  const isUpdateTestInProgress = () => props.test.in_progress.update_test;

  const editTestLinkClass = classNames({
    [styles.disabled]: isUpdateTestInProgress(),
  });

  return (
    <div className={styles.header}>
      <div className={styles.textContainer}>
        <h3>{props.test.name}</h3>
        {props.classID && (
          <div className={styles.statusContainer}>
            <i className={styles[`icon_${props.test.status}`]}/>
            <span>{translate(`test-status-${props.test.status}`)}</span>
          </div>
        )}
      </div>
      {props.classID && props.test.status === TestStatusTypes.COMPLETED && (
        <Link to={`/test/${props.test.id}/report/class`}>
          <span>{translate("test.see-report")}</span>
        </Link>
      )}
      {props.classID && props.test.status === TestStatusTypes.IN_PROGRESS && (
        <div className={styles.buttonContainer}>
          <ToggleContent
            toggle={(show: any) => (
              <button
                type="button"
                disabled={
                  isUpdateTestInProgress() || !!props.test.errors.length
                }
                onClick={() => {
                  show();
                }}
              >
                {isUpdateTestInProgress() ? (
                  <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
                ) : (
                  <span>
                    {translate("global.finish")} {translate("global.test")}
                  </span>
                )}
              </button>
            )}
            content={(hide: () => {}) => (
              <Modal isSmall hide={hide}>
                <div className={styles.changeTestStatusModal}>
                  <span>
                    {translate(
                      "test.change-test-status-to-completed-confirmation-text",
                    )}
                  </span>
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        hide();
                        props.updateTest(
                          { id: props.test.id, status: "completed" },
                          () => props.getTest(),
                        );
                      }}
                    >
                      {translate("global.yes")}
                    </button>
                    <button type="button" onClick={hide}>
                      {translate("global.no")}
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          />
          <Link
            to={`/test/creator?classID=${props.test.class_id}&type=${
              props.test.type
            }&subject=${props.test.subject.type}&testID=${props.test.id}${
              props.test.time_limit !== undefined
                ? `&time=${props.test.time_limit}`
                : ""
            }`}
            className={editTestLinkClass}
          >
            <span>{translate("global.edit")}</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TestDetailsHeader;
