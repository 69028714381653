import { ErrorObject } from "../../common";

export const resourceType = {
  formativeAssessment: "formative_assessment",
};

export interface resourcesStateModel {
  list: any[];
};

export interface resourcesActionModel {
  type: string;
  payload?: any;
};

// Get Lessons

const GET_RESOURCES = "GET_RESOURCES";
const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";

export interface getResourcesPayload {
  type: string;
};

export interface resourceItem {
  file: string;
  image: string;
  name: string;
};

export interface getResourcesSuccessPayload {
  data: resourceItem[],
};

export interface getResources {
  ({ type }: getResourcesPayload): any;
};

export interface getResourcesAction {
  type: typeof GET_RESOURCES;
  payload: getResourcesPayload;
};

export interface getResourcesSuccessAction {
  type: typeof GET_RESOURCES_SUCCESS;
  payload: getResourcesSuccessPayload;
};

export interface getResourcesFailureAction {
  type: typeof GET_RESOURCES_FAILURE;
  payload: {
    errors: ErrorObject[];
  };
};

// Other

const UNMOUNT_RESOURCES = "UNMOUNT_RESOURCES";

export interface unmountResourcesAction {
  type: typeof UNMOUNT_RESOURCES;
};
