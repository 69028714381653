import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import pathOr from "ramda/src/pathOr";

// Types
import {
  QuestionTypes,
  questionModel,
  answerChoiceModel,
} from "../../../../../../../models/redux/test";

import stylesDefault from "./styles.module.scss";
import stylesCompact from "./styles_compact.module.scss";


interface Props {
  question: questionModel;
  isCompact?: boolean;
  showCorrectAnswers?: boolean;
}

export const QuestionTrueFalsePreview = (props: Props) => {
  const styles = props.isCompact ? stylesCompact : stylesDefault;

  const isTrueFalse = () => {
    return props.question.type === QuestionTypes.TRUE_FALSE;
  };

  const trueFalseAnswerClass = (isCorrect: boolean) => classNames({
    [styles.trueFalseAnswer]: true,
    [styles.trueFalseAnswerCorrect]: props.showCorrectAnswers && isCorrect,
  });

  return (
    <>
      {isTrueFalse() &&
        <div data-type="question-answers-tf" className={styles.trueFalse}>
          <div
            className={`${styles.trueFalseBody} questionBodyGlobal`}
            dangerouslySetInnerHTML={{
              __html: `${props.question.body_html ?
                DOMPurify.sanitize(props.question.body_html) :
                DOMPurify.sanitize(pathOr("", ["question", "body"], props))}`,
            }}
          ></div>
          <div className={styles.trueFalseAnswerContainer}>
            {props.question.answer_choices.map((item: answerChoiceModel) => (
              <span
                key={item.id}
                className={trueFalseAnswerClass(item.is_correct)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      }
    </>
  );
};

export default QuestionTrueFalsePreview;
