import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import classNames from "classnames";

import translate from "../../../../../services/translate";

import { ReactComponent as PaperTestIcon } from "../../../../../assets/images/test_types/paper.svg";
import { ReactComponent as OnlineTestIcon } from "../../../../../assets/images/test_types/online.svg";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";
import { teacherStateModel } from "../../../../../models/redux/teacher";

interface Props {
  history: History;
  location: Location;
}

export const SelectTestType = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const [selectedTestType, setSelectedTestType] = useState<string>("");
  const classID = parsed.classID;
  const state = useSelector((state: { teacher: teacherStateModel }) => ({
    teacher: state.teacher,
  }));

  const selectedClass = state.teacher.classes.find(
    (classItem) => classItem.id === classID,
  );

  useEffect(() => {
    const selectedTestType = parsed.type;

    if (!classID) {
      props.history.push(`/test/creator/select-class${props.location.search}`);
      return;
    }

    if (selectedTestType) {
      props.history.push(`/test/creator${props.location.search}`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linkClass = classNames({
    [styles.disabled]: !selectedTestType.length,
  });

  const testTypeItemClass = (type: string) =>
    classNames({
      [styles.active]: type === selectedTestType,
    });

  return (
    <section className={styles.container}>
      <h1>
        {translate("creator.choose")}{" "}
        <span>{translate("creator.type-of-test")}</span>
      </h1>
      <p>{translate("creator.choose-type-of-test-description")}</p>
      <ul className={styles.testTypeList}>
        <li className={testTypeItemClass("paper")}>
          <button type="button" onClick={() => setSelectedTestType("paper")}>
            <PaperTestIcon/>
            <span>{translate("creator.paper")}</span>
          </button>
        </li>
        <li className={testTypeItemClass("online")}>
          <button type="button" onClick={() => setSelectedTestType("online")}>
            <OnlineTestIcon/>
            <span>{translate("creator.online")}</span>
          </button>
        </li>
      </ul>
      <div className={styles.bottomContainer}>
        <Link
          to={`/test/creator${
            selectedTestType === "online" ? "/select-time" : ""
          }?classID=${classID}&type=${selectedTestType}&subject=${
            selectedClass ? selectedClass.subject.type : ""
          }`}
          className={linkClass}
        >
          {translate("global.accept")}
        </Link>
      </div>
    </section>
  );
};

export default SelectTestType;
