// Types
import {
  getClassReportAction,
  getClassReportPayload,
  getClassReportSuccessPayload,
  getClassReportSuccessAction,
  getClassReportFailureAction,
  resetClassReportAction,
  generateClassReportPDFAction,
  generateClassReportPDFPayload,
  generateClassReportPDFSuccessPayload,
  generateClassReportPDFSuccessAction,
  generateClassReportPDFFailureAction,
  getStudentReportPayload,
  getStudentReportAction,
  getStudentReportSuccessPayload,
  getStudentReportSuccessAction,
  getStudentReportFailureAction,
  resetStudentReportAction,
  generateStudentReportPDFPayload,
  generateStudentReportPDFAction,
  generateStudentReportPDFSuccessPayload,
  generateStudentReportPDFSuccessAction,
  generateStudentReportPDFFailureAction,
} from "../../../models/redux/report";
import { ErrorsPayload } from "../../../models/common";

// Get Class Report

export const getClassReport = (payload: getClassReportPayload): getClassReportAction => {
  return {
    type: "GET_CLASS_REPORT",
    payload,
  };
};

export const getClassReportSuccess = (payload: getClassReportSuccessPayload): getClassReportSuccessAction => {
  return {
    type: "GET_CLASS_REPORT_SUCCESS",
    payload,
  };
};

export const getClassReportFailure = (payload: ErrorsPayload): getClassReportFailureAction => {
  return {
    type: "GET_CLASS_REPORT_FAILURE",
    payload,
  };
};

// Generate Class Report PDF

export const generateClassReportPDF = (payload: generateClassReportPDFPayload): generateClassReportPDFAction => {
  return {
    type: "GENERATE_CLASS_REPORT_PDF",
    payload,
  };
};

export const generateClassReportPDFSuccess = (payload: generateClassReportPDFSuccessPayload): generateClassReportPDFSuccessAction => {
  window.open(payload.data.url, "_parent", "download");

  return {
    type: "GENERATE_CLASS_REPORT_PDF_SUCCESS",
    payload,
  };
};

export const generateClassReportPDFFailure = (payload: ErrorsPayload): generateClassReportPDFFailureAction => {
  return {
    type: "GENERATE_CLASS_REPORT_PDF_FAILURE",
    payload,
  };
};

// Get Student Report

export const getStudentReport = (payload: getStudentReportPayload): getStudentReportAction => {
  return {
    type: "GET_STUDENT_REPORT",
    payload,
  };
};

export const getStudentReportSuccess = (payload: getStudentReportSuccessPayload): getStudentReportSuccessAction => {
  return {
    type: "GET_STUDENT_REPORT_SUCCESS",
    payload,
  };
};

export const getStudentReportFailure = (payload: ErrorsPayload): getStudentReportFailureAction => {
  return {
    type: "GET_STUDENT_REPORT_FAILURE",
    payload,
  };
};

// Generate Student Report PDF

export const generateStudentReportPDF = (payload: generateStudentReportPDFPayload): generateStudentReportPDFAction => {
  return {
    type: "GENERATE_STUDENT_REPORT_PDF",
    payload,
  };
};

export const generateStudentReportPDFSuccess = (payload: generateStudentReportPDFSuccessPayload): generateStudentReportPDFSuccessAction => {
  window.open(payload.data.url, "_parent", "download");

  return {
    type: "GENERATE_STUDENT_REPORT_PDF_SUCCESS",
    payload,
  };
};

export const generateStudentReportPDFFailure = (payload: ErrorsPayload): generateStudentReportPDFFailureAction => {
  return {
    type: "GENERATE_STUDENT_REPORT_PDF_FAILURE",
    payload,
  };
};

// Other

export const resetClassReport = (): resetClassReportAction => {
  return {
    type: "RESET_CLASS_REPORT",
  };
};

export const resetStudentReport = (): resetStudentReportAction => {
  return {
    type: "RESET_STUDENT_REPORT",
  };
};
