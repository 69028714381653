import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import classNames from "classnames";
import pathOr from "ramda/src/pathOr";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoCircleIcon } from "../../../../../assets/images/info_circle.svg";

import { getBillingNextPayment } from "../../../../../redux/actions/billing";

import translate from "../../../../../services/translate";
import { getAmount } from "../../../../../services/common/pricing";

// Types
import { appStateModel } from "../../../../../models/redux/app";
import {
  billingStateModel,
  getBillingNextPayment as getBillingNextPaymentFunc,
} from "../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  billing: billingStateModel;
  getBillingNextPayment: getBillingNextPaymentFunc;
  translations: any;
}

export const PaymentInfo = (props: Props) => {
  useEffect(() => {
    props.getBillingNextPayment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOldPrice = () =>
    props.translations[
      `wisdy-old-price-${pathOr(
        "pln",
        ["billing", "next_payment", "currency"],
        props,
      )}`
    ];

  const actualSubscriptionAmountClass = classNames({
    [styles.actualSubscriptionAmount]: true,
  });

  return (
    <div className={styles.paymentInfoBox}>
      {getOldPrice() && (
        <span className={styles.discountTextInfo}>
          *{translate("profile.promotion-discount-info")}
        </span>
      )}
      <h3>{translate("global.details")}</h3>
      <div className={styles.content}>
        <div className={styles.heading}>
          <p className={actualSubscriptionAmountClass}>
            {translate("profile.actual-subscription-amount")}:
          </p>
          <div className={styles.rightContainer}>
            <div className={styles.amount}>
              <span className={styles.old}>{getOldPrice()}</span>
              <div className={styles.current}>
                <span>
                  {getAmount(
                    `${pathOr(
                      "",
                      ["billing", "next_payment", "amount"],
                      props,
                    )}`,
                  )}
                </span>
                <span>
                  {pathOr("", ["billing", "next_payment", "currency"], props)}
                </span>
              </div>
            </div>
            {!!props.billing.discount_codes.length && (
              <div className={styles.tooltipContainer}>
                <ReactTooltip
                  id="tooltip_dicount_payment_info"
                  place="left"
                  effect="solid"
                  className={styles.tooltip}
                >
                  <p>{translate("profile.payment-info-tooltip-desc")}</p>
                </ReactTooltip>
                <i data-tip data-for="tooltip_dicount_payment_info">
                  <InfoCircleIcon/>
                </i>
              </div>
            )}
          </div>
        </div>
        {props.billing.next_payment.amount !==
          props.billing.next_payment.base_amount && (
          <>
            <span className={styles.greyText}>
              {translate("profile.amount-before-discount")}: &nbsp;
              {getAmount(
                `${pathOr(
                  "",
                  ["billing", "next_payment", "base_amount"],
                  props,
                )}`,
              )}
              <span>
                &nbsp;
                {pathOr("", ["billing", "next_payment", "currency"], props)}
              </span>
            </span>
            <span className={styles.greyText}>
              {translate("profile.payment-info-discount-desc")}
            </span>
          </>
        )}
        {getOldPrice() && (
          <span className={styles.promotionDurationText}>
            *{translate("profile.pricing-promotion-duration-info")}
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  billing: billingStateModel;
  app: appStateModel;
}) => ({
  billing: state.billing,
  translations: state.app.translations.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getBillingNextPayment,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
