import React from "react";

import { getArrayBasedOnNumber } from "../../../../../../services/common";

// Types
import { History, Location } from "history";

import styles from "./styles.module.scss";


interface Props {
  history: History;
  location: Location;
  verificationCode: any;
  setVerificationCode: any;
};

export const StudentVerificationCodeInput = (props: Props) => {
  const onVerificationCodeChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const lastCharacter = value[value.length - 1];

    if (value === "") {
      const code = ({ ...props.verificationCode, [index]: "" });
      props.setVerificationCode(code);
      return;
    }

    if (!isNaN(Number(lastCharacter))) {
      const code = ({ ...props.verificationCode, [index]: lastCharacter });
      props.setVerificationCode(code);

      const nextInputElement = document.getElementById(`verification_code_input_${index+1}`) as HTMLInputElement;
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };

  const onVerificationCodeKeyDown = (index: number, e: any) => {
    const value = e.target.value;
    const key = e.keyCode || e.charCode;

    if (value === "" && (key === 8 || key === 46)) {
      const previousInputElement = document.getElementById(`verification_code_input_${index-1}`) as HTMLInputElement;
      if (previousInputElement) {
        previousInputElement.focus();
      }
      return;
    }
  };

  return (
    <>
      {getArrayBasedOnNumber(4).map((index: number) => (
        <input
          key={`verification_code_input_${index}`}
          id={`verification_code_input_${index}`}
          className={styles.studentCodeInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onVerificationCodeChange(index, e)}
          onKeyDown={(e: any) => onVerificationCodeKeyDown(index, e)}
          value={props.verificationCode[index]}
        />
      ))}
    </>
  );
};

export default StudentVerificationCodeInput;
