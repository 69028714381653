import propOr from "ramda/src/propOr";

// Types
import {
  getLessonsPayload,
  getLessonsAction,
  getLessonsSuccessPayload,
  getLessonsSuccessAction,
  getLessonsFailureAction,
  unmountLessonsAction,
  lessonsGenerateAccessTokenPayload,
  lessonsGenerateAccessTokenAction,
  lessonsGenerateAccessTokenSuccessPayload,
  lessonsGenerateAccessTokenSuccessAction,
  lessonsGenerateAccessTokenFailureAction,
} from "../../../models/redux/lessons";
import { ErrorsPayload } from "../../../models/common";

// Get Lessons

export const getLessons = (payload: getLessonsPayload): getLessonsAction => {
  return {
    type: "GET_LESSONS",
    payload,
  };
};

export const getLessonsSuccess = (payload: getLessonsSuccessPayload): getLessonsSuccessAction => {
  return {
    type: "GET_LESSONS_SUCCESS",
    payload,
  };
};

export const getLessonsFailure = (payload: ErrorsPayload): getLessonsFailureAction => {
  return {
    type: "GET_LESSONS_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Generate Lessons access token

export const lessonsGenerateAccessToken = (payload: lessonsGenerateAccessTokenPayload, callback?: () => any): lessonsGenerateAccessTokenAction => {
  return {
    type: "LESSONS_GENERATE_ACCESS_TOKEN",
    payload,
    callback,
  };
};

export const lessonsGenerateAccessTokenSuccess = (payload: lessonsGenerateAccessTokenSuccessPayload, callback?: () => any): lessonsGenerateAccessTokenSuccessAction => {
  if (callback) {
    callback();
  }
  return {
    type: "LESSONS_GENERATE_ACCESS_TOKEN_SUCCESS",
    payload,
  };
};

export const lessonsGenerateAccessTokenFailure = (payload: ErrorsPayload): lessonsGenerateAccessTokenFailureAction => {
  return {
    type: "LESSONS_GENERATE_ACCESS_TOKEN_FAILURE",
    payload: {
      errors: propOr([], "errors", payload),
    },
  };
};

// Other

export const unmountLessons = (): unmountLessonsAction => {
  return {
    type: "UNMOUNT_LESSONS",
  };
};
