import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import OutsideClickHandler from "react-outside-click-handler";

import propOr from "ramda/src/propOr";

import { ReactComponent as ArrowDownGrey } from "../../../../../assets/images/small_arrow_down_grey.svg";

import Input from "../../../../Common/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translate from "../../../../../services/translate";
import {
  getErrorMessage,
  outsideClickHelper,
} from "../../../../../services/common";

import {
  getClasses,
  unmountTeacher,
} from "../../../../../redux/actions/teacher";

// Types
import {
  classModel,
  getClasses as getClassesFunction,
} from "../../../../../models/redux/teacher";
import { ErrorsPayload } from "../../../../../models/common";
import { History } from "history";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  isLoading: boolean;
  errors: ErrorsPayload[];
  classes: classModel[];
  unmountTeacher: any;
  getClasses: getClassesFunction;
}

export const SelectClassForTest = (props: Props) => {
  const [selectedClass, setSelectedClass] = useState({} as classModel);
  const [className, setClassName] = useState("");
  const [classList, setClassList] = useState([] as classModel[]);
  const [showDropdown, toggleDropdown] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const classID = parsed.classID;

    if (classID) {
      props.history.push(
        `/test/creator/select-type${props.history.location.search}`,
      );
      return;
    }

    props.getClasses();

    setClassList(props.classes);

    return () => {
      props.unmountTeacher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClassList(props.classes);
  }, [props.classes]);

  const isNextButtonDisabled = () => !(selectedClass && selectedClass.id);

  return (
    <section className={styles.container}>
      <div className={styles.top}>
        <h1>
          {translate("global.pick-up")}{" "}
          <span>{translate("class.class-accusative")}</span>
        </h1>
        <p>{translate("class.select-or-create-new-class")}</p>
        <div className={styles.box}>
          <div className={styles.inputContainer}>
            <OutsideClickHandler
              onOutsideClick={(e: any) => {
                outsideClickHelper(e, "dropdown", () => toggleDropdown(false));
                setClassName(propOr("", "name", selectedClass));
                setClassList(props.classes);
              }}
            >
              <div className={styles.wrapper}>
                <div onClick={() => toggleDropdown(true)}>
                  <Input
                    id="class_name"
                    type="text"
                    name="class_name"
                    value={className}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const classItem = props.classes.filter(
                        (item: classModel) => item.name.includes(e.target.value),
                      ) as classModel[];
                      setClassList(classItem);
                      setClassName(e.target.value);
                    }}
                    icon={() => <ArrowDownGrey/>}
                    label={
                      <>
                        {translate("global.pick-up")}{" "}
                        {translate("class.class-accusative")}
                      </>
                    }
                    error={getErrorMessage(props.errors, "name")}
                    labelHiddenOnActive
                  />
                </div>
                {showDropdown && (
                  <div className={styles.dropdown}>
                    <ul>
                      {classList.map((item: classModel) => (
                        <li key={item.id}>
                          <button
                            type="button"
                            onClick={() => {
                              setClassName(item.name);
                              setSelectedClass(item);
                              toggleDropdown(false);
                            }}
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                      {!classList.length && (
                        <li>
                          <span>{translate("global.no-results")}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          <span className={styles.or}>{translate("class.or")}</span>
          <div className={styles.inputContainer}>
            <Link to="/class/add" className={styles.addClass}>
              +&nbsp;<span>{translate("class.add-class")}</span>
            </Link>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <button
            className={styles.goBackButton}
            onClick={() => props.history.back()}
          >
            {translate("global.back")}
          </button>
          <button
            className={styles.nextButton}
            onClick={() => {
              props.history.push(
                `/test/creator/select-type?classID=${selectedClass.id}`,
              );
            }}
            disabled={isNextButtonDisabled() || props.isLoading}
          >
            {props.isLoading ? (
              <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
            ) : (
              <>{translate("global.next")}</>
            )}
          </button>
        </div>
      </div>
      <Link to="/">
        <span>{translate("global.back-to-homepage")}</span>
      </Link>
    </section>
  );
};

const mapStateToProps = (state: any) => ({
  classes: state.teacher.classes,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
      unmountTeacher,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectClassForTest);
