import pathOr from "ramda/src/pathOr";

// Types
import { reportStateModel, reportActionModel } from "../../../models/redux/report";

const initialState: reportStateModel = {
  class: {
    class_id: "",
    class_name: "",
    subject_name: "",
    name: "",
    points_average_percentage: 0,
    points_total: 0,
    questions: [],
    participants: 0,
    scanned_tests: 0,
    students: [],
    students_total: 0,
    points_average_percentage_closed_questions: null,
    points_average_percentage_open_questions: null,
  },
  student: {
    class_id: "",
    result_id: "",
    class_name: "",
    points: 0,
    points_percentage: 0,
    points_total: 0,
    questions: [],
    student_number: 0,
    subject_name: "",
    test_name: "",
  },
  studentResults: {
    answers: [],
  },
  in_progress: {
    generate_pdf: false,
  },
};

export const report = (state = initialState, action: reportActionModel) => {
  switch (action.type) {
    case "GET_CLASS_REPORT_SUCCESS":
      return {
        ...state,
        class: {
          ...initialState.class,
          ...pathOr(
            initialState.class,
            ["payload", "data"],
            action,
          ),
        },
      };
    case "RESET_CLASS_REPORT":
      return {
        ...state,
        class: initialState.class,
      };
    case "GENERATE_CLASS_REPORT_PDF":
    case "GENERATE_STUDENT_REPORT_PDF":
      return {
        ...state,
        in_progress: {
          generate_pdf: true,
        },
      };
    case "GENERATE_CLASS_REPORT_PDF_SUCCESS":
    case "GENERATE_CLASS_REPORT_PDF_FAILURE":
    case "GENERATE_STUDENT_REPORT_PDF_SUCCESS":
    case "GENERATE_STUDENT_REPORT_PDF_FAILURE":
      return {
        ...state,
        in_progress: {
          generate_pdf: false,
        },
      };
    case "RESET_STUDENT_REPORT":
      return {
        ...state,
        student: initialState.student,
        studentResults: initialState.studentResults,
      };
    case "GET_STUDENT_REPORT_SUCCESS":
      return {
        ...state,
        student: {
          ...initialState.student,
          ...pathOr(
            initialState.student,
            ["payload", "data"],
            action,
          ),
        },
      };
    case "GET_TEST_RESULTS_SUCCESS":
      return {
        ...state,
        studentResults: {
          ...initialState.studentResults,
          ...pathOr(
            initialState.studentResults,
            ["payload", "data"],
            action,
          ),
        },
      };
    default:
      return state;
  }
};
