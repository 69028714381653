import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { getSubjects } from "../../../../../redux/actions/teacher";

import translate from "../../../../../services/translate";
import SubjectBox from "../../../../Common/SubjectBox";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIcon } from "../../../../../assets/images/wisdy_icon_info.svg";

// Types
import { History, Location } from "history";
import {
  teacherStateModel,
  getSubjects as getSubjectsFunction,
} from "../../../../../models/redux/teacher";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  teacher: teacherStateModel;
  getSubjects: getSubjectsFunction;
}

export const SelectSubjectForTest = (props: Props) => {
  const parsed = queryString.parse(props.location.search);
  const [selectedSubject, updateSelectedSubject] = useState<string>("");
  const testType = parsed.type;

  useEffect(() => {
    props.getSubjects();
    const classID = parsed.classID;
    const selectedSubject = parsed.subject;

    if (!classID) {
      props.history.push(`/test/creator/select-class${props.location.search}`);
      return;
    }

    if (!testType) {
      props.history.push(`/test/creator/select-type${props.location.search}`);
      return;
    }

    if (selectedSubject) {
      props.history.push(`/test/creator${props.location.search}`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linkClass = classNames({
    [styles.disabled]: !selectedSubject.length,
  });

  return (
    <section className={styles.container}>
      <h1>
        {translate("select_subject.choice")}{" "}
        <span>{translate("select_subject.subject-second")}</span>
      </h1>
      <p>{translate("select_subject.pick-up-subject-desc")}</p>
      <ul>
        {props.teacher.subjects
          .filter((item) => item.is_chosen)
          .map((subject: any) => (
            <SubjectBox
              key={subject.id}
              id={subject.id}
              title={subject.name}
              subjectType={subject.type}
              onClick={() => updateSelectedSubject(subject.type)}
              checked={subject.type === selectedSubject}
              radio
            />
          ))}
        {!props.teacher.subjects.filter((item) => item.is_chosen).length && (
          <li>
            <Link to={`/pick-up-subjects?next=/class/${parsed.classID}/tests`}>
              <span>
                {translate("global.pick-up")} {translate("global.subjects")}
              </span>
            </Link>
          </li>
        )}
      </ul>
      <div className={styles.bottomContainer}>
        <Link
          to={`/test/creator${testType === "online" ? "/select-time" : ""}${
            props.history.location.search
          }&subject=${selectedSubject}`}
          className={linkClass}
        >
          {translate("global.accept")}
        </Link>
        <div className={styles.tooltipContainer}>
          <ReactTooltip
            id="tooltip"
            place="top"
            effect="solid"
            className={styles.tooltip}
          >
            <p>{translate("select_subject.pick-up-subjects-tooltip")}</p>
          </ReactTooltip>
          <i data-tip data-for="tooltip">
            <InfoIcon/>
          </i>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: { teacher: teacherStateModel }) => ({
  teacher: state.teacher,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSubjects,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectSubjectForTest);
