import pathOr from "ramda/src/pathOr";

// Types
import { teacherStateModel, teacherActionModel } from "../../../models/redux/teacher";

const initialState: teacherStateModel = {
  subjects: [],
  classes: [],
  isLoading: false,
  errors: [],
};

export const teacher = (state = initialState, action: teacherActionModel) => {
  switch (action.type) {
    case "GET_SUBJECTS_SUCCESS":
      return {
        ...state,
        subjects: pathOr([], ["payload", "data"], action),
      };
    case "CREATE_CLASS":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_CLASS_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "CREATE_CLASS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: pathOr([], ["payload", "errors"], action),
      };
    case "UNMOUNT_TEACHER":
      return {
        ...state,
        isLoading: false,
        errors: [],
      };
    case "GET_CLASSES_SUCCESS":
      return {
        ...state,
        classes: pathOr([], ["payload", "data"], action),
      };
    case "GET_SUBJECTS":
    case "GET_SUBJECTS_FAILURE":
    case "GET_CLASSES":
    case "GET_CLASSES_FAILURE":
      return {
        ...state,
      };
    default:
      return state;
  }
};
