// Types
import { History } from "history";
import {
  getTranslationsPayload,
  getTranslationsAction,
  getTranslationsSuccessAction,
  getTranslationsFailureAction,
  showToastAction,
  showToastPayload,
  hideToastAction,
  historyPushAction,
  historyGoBackAction,
} from "../../../models/redux/app";

// Translations

export const getTranslations = (): getTranslationsAction => {
  return {
    type: "GET_TRANSLATIONS",
  };
};

export const getTranslationsSuccess = (
  payload: getTranslationsPayload,
): getTranslationsSuccessAction => {
  return {
    type: "GET_TRANSLATIONS_SUCCESS",
    payload: {
      data: payload.data,
    },
  };
};

export const getTranslationsFailure = (): getTranslationsFailureAction => {
  return {
    type: "GET_TRANSLATIONS_FAILURE",
  };
};

// Toast

export const showToast = (payload: showToastPayload): showToastAction => {
  return {
    type: "SHOW_TOAST",
    payload,
  };
};

export const hideToast = (): hideToastAction => {
  return {
    type: "HIDE_TOAST",
  };
};

// Push History

export const historyPush = (
  history: History,
  path: string,
): historyPushAction => {
  if (history && path) {
    history.push(path);
  }

  return {
    type: "HISTORY_PUSH",
  };
};

// History Go Back

export const historyGoBack = (history: History): historyGoBackAction => {
  if (history) {
    history.back();
  }

  return {
    type: "HISTORY_GO_BACK",
  };
};

// Empty

export const empty = (): any => {
  return {
    type: "EMPTY",
  };
};
