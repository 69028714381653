import React from "react";
import classNames from "classnames";
import { ReactComponent as Check } from "../../../assets/images/checkbox_check.svg";

import { getSubjectIcon } from "../../../services/common/getSubjectIcon";

import styles from "./styles.module.scss";


interface Props {
  id: string;
  checked: boolean;
  radio?: boolean;
  subjectType: string;
  title: string;
  onClick: () => any;
};

export const SubjectBox = (props: Props) => {
  const boxClass = classNames({
    [styles.subjectBox]: true,
    [styles.active]: props.checked,
  });

  return (
    <li className={boxClass} onClick={props.onClick}>
      {getSubjectIcon(props.subjectType)}
      <div className={styles.checkbox}>
        {props.radio ?
          <div className={styles.radio}>
            {props.checked && <i/>}
          </div> :
          <span className={styles.checkmark}>
            {props.checked && <Check/>}
          </span>
        }
      </div>
      <span>{props.title}</span>
    </li>
  );
};

export default SubjectBox;
