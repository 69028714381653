import React, { useState, useEffect } from "react";
import { Elements } from "react-stripe-elements";

import CreditCardForm from "./Form";

import translate from "../../../../../../services/translate";

// Types
import { userStateModel } from "../../../../../../models/redux/user";
import {
  addBillingCard as addBillingCardFunc,
  resetBillingErrors as resetBillingErrorsFunc,
  updateBillingSettings as updateBillingSettingsFunc,
} from "../../../../../../models/redux/billing";

import styles from "./styles.module.scss";

interface Props {
  user: userStateModel;
  addBillingCard: addBillingCardFunc;
  setSelectedPaymentMethod: (value: string) => any;
  setPaymentMethods: (value: boolean) => any;
  resetBillingErrors: resetBillingErrorsFunc;
  updateBillingSettings: updateBillingSettingsFunc;
}

export const CreditCardContainer = (props: Props) => {
  const [showChangeCurrencyForm, setShowChangeCurrencyForm] =
    useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("");

  useEffect(() => {
    setCurrency(props.user.currency);
    setShowChangeCurrencyForm(false);
  }, [props.user.currency]);

  return (
    <div className={styles.selectedCreditCardMethod}>
      <h3>{translate("profile.payment-method")}</h3>
      <div className={styles.selectedPaymentLineSection}>
        <p>
          {translate("profile.chosen-payment-method")}:{" "}
          <span>{translate("profile.credit-card")}</span>
        </p>
        <button
          onClick={() => {
            props.setSelectedPaymentMethod("");
            props.setPaymentMethods(true);
            props.resetBillingErrors();
          }}
        >
          <span>{translate("global.change")}</span>
        </button>
      </div>
      <div className={styles.selectedPaymentLineSection}>
        <p>
          {translate("profile.chosen-currency")}:{" "}
          <span className={styles.upercase}>{props.user.currency}</span>
        </p>
        {showChangeCurrencyForm ? (
          <button onClick={() => props.updateBillingSettings({ currency })}>
            <span>{translate("global.save")}</span>
          </button>
        ) : (
          <button onClick={() => setShowChangeCurrencyForm(true)}>
            <span>{translate("global.change")}</span>
          </button>
        )}
        {showChangeCurrencyForm && (
          <div className={styles.changeCurrency}>
            <div className={styles.currency}>
              <input
                type="radio"
                id="currency_pln"
                name="currency_pln"
                checked={currency === "pln"}
                value="pln"
                onChange={() => setCurrency("pln")}
              />
              <label htmlFor="currency_pln">PLN</label>
            </div>
            <div className={styles.currency}>
              <input
                type="radio"
                id="currency_usd"
                name="currency_usd"
                checked={currency === "usd"}
                value="usd"
                onChange={() => setCurrency("usd")}
              />
              <label htmlFor="currency_usd">USD</label>
            </div>
          </div>
        )}
      </div>
      <Elements>
        <CreditCardForm
          addBillingCard={props.addBillingCard}
          isLoading={props.user.in_progress.billing_card}
          errors={props.user.errors.billing_card}
        />
      </Elements>
    </div>
  );
};

export default CreditCardContainer;
