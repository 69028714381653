import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import isNil from "ramda/src/isNil";

import translate from "../../../../../../services/translate";

import { ReactComponent as EyeIcon } from "../../../../../../assets/images/eye_icon.svg";
import { ReactComponent as TheBestIcon } from "../../../../../../assets/images/reports/the_best.svg";
import { ReactComponent as TheWorstIcon } from "../../../../../../assets/images/reports/the_worst.svg";

// Types
import { History } from "history";
import { testReportStudentModel } from "../../../../../../models/redux/report";
import { createTestResults as createTestResultsFunction } from "../../../../../../models/redux/test";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  students: testReportStudentModel[];
  pointsTotal: number;
  testID: string;
  createTestResults: createTestResultsFunction;
}

export const TestReportClassStudents = (props: Props) => {
  const percentagePointContainerClass = (pointsPercentage: number | null) =>
    classNames({
      [styles.scorePercentagePoints]: !isNil(pointsPercentage),
      [styles.scoreNoPercentagePoints]: isNil(pointsPercentage),
      [styles.valueLeft]:
        Number(pointsPercentage) >= 60 && Number(pointsPercentage) <= 90,
      [styles.valueCenter]: Number(pointsPercentage) > 90,
      [styles.valueRight]: Number(pointsPercentage) < 60,
    });

  const percentageBarClass = (
    isTheBest: boolean,
    pointsPercentage: number | null,
  ) =>
    classNames({
      [styles.percentageBar]: true,
      [styles.percentageBarBest]: isTheBest,
      [styles.percentageBarZero]: !isTheBest && !pointsPercentage,
    });

  const percentagePointsClass = (pointsPercentage: number | null) =>
    classNames({
      [styles.percentagePoints]: true,
      [styles.percentagePointsZero]: !pointsPercentage,
    });

  const actionsClass = (pointsPercentage: number | null) =>
    classNames({
      [styles.actions]: !isNil(pointsPercentage),
      [styles.actionsNoPointsPercentage]: isNil(pointsPercentage),
    });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{translate("report.students-stats")}</h4>
        <span>
          {translate("report.maximum-points-to-get")}: {props.pointsTotal}
        </span>
      </div>
      <ul className={styles.students}>
        <li className={styles.headingItem}>
          <div className={styles.studentNumber}>
            <span>{translate("students.student-number-heading")}</span>
          </div>
          <div className={styles.scorePoints}>
            <span>{translate("report.score-points-short")}</span>
          </div>
          <div className={styles.scorePercentagePoints}>
            <span>{translate("report.score-percentage-points-short")}</span>
          </div>
          <div className={styles.actions}></div>
        </li>
        {props.students.map((item: testReportStudentModel) => (
          <li className={styles.listItem} key={item.number}>
            <div className={styles.studentNumber}>
              <span className={styles.number}>
                <span>{translate("students.student-number")}</span>&nbsp;
                {item.number}
              </span>
            </div>
            <div className={styles.scorePoints}>
              <span className={styles.number}>
                {isNil(item.points) ? "-" : item.points}
              </span>
            </div>
            <div
              className={percentagePointContainerClass(item.points_percentage)}
            >
              {!isNil(item.points_percentage) && (
                <i>
                  <i
                    className={percentageBarClass(
                      item.is_the_best,
                      item.points_percentage,
                    )}
                    style={{ width: `${item.points_percentage}%` }}
                  ></i>
                  <span
                    className={percentagePointsClass(item.points_percentage)}
                  >
                    {item.points_percentage}%
                  </span>
                </i>
              )}
              {item.is_the_best && <TheBestIcon/>}
              {!item.is_the_best && item.points_percentage === 0 && (
                <TheWorstIcon/>
              )}
              {!item.is_the_best && !!item.points_percentage && (
                <div className={styles.iconPlaceholder}></div>
              )}
            </div>
            <div className={actionsClass(item.points_percentage)}>
              {!item.has_result && (
                <button
                  type="button"
                  className={styles.actionsButton}
                  onClick={() => {
                    props.createTestResults(
                      { testID: props.testID, studentID: item.id },
                      () =>
                        props.history.push(
                          `/test/${props.testID}/report/student/${item.id}`,
                        ),
                    );
                  }}
                >
                  {translate("report.add-a-score")}
                </button>
              )}
              {item.has_result && isNil(item.points_percentage) && (
                <Link
                  className={styles.actionsButton}
                  to={`/test/${props.testID}/report/student/${item.id}`}
                >
                  {translate("report.fill-in-a-score")}
                </Link>
              )}
              {!isNil(item.points_percentage) && (
                <div className={styles.tooltipContainer}>
                  <ReactTooltip
                    id="tooltip"
                    place="left"
                    effect="solid"
                    className={styles.tooltip}
                  >
                    <p>{translate("global.details")}</p>
                  </ReactTooltip>
                  <i data-tip data-for="tooltip">
                    <Link
                      to={`/test/${props.testID}/report/student/${item.id}`}
                    >
                      <EyeIcon/>
                    </Link>
                  </i>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestReportClassStudents;
