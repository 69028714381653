import React from "react";

import classNames from "classnames";

import Single from "./MultipleChoice/Single";
import Multiple from "./MultipleChoice/Multiple";
import TrueFalse from "./TrueFalse";
import Matching from "./Matching";

import translate from "../../../../../../../services/translate";
import { getErrorMessage } from "../../../../../../../services/common";

import { ReactComponent as InfoIcon } from "../../../../../../../assets/images/info.svg";

// Types
import {
  QuestionTypes,
  answerChoiceModel,
  QuestionSubtype,
  createQuestionAnswerChoice as createQuestionAnswerChoiceFunction,
  updateQuestionAnswerChoice as updateQuestionAnswerChoiceFunction,
  deleteQuestionAnswerChoice as deleteQuestionAnswerChoiceFunction,
  createQuestionMatching as createQuestionMatchingFunction,
  updateQuestionMatching as updateQuestionMatchingFunction,
  deleteQuestionMatching as deleteQuestionMatchingFunction,
} from "../../../../../../../models/redux/test";
import { ErrorObject } from "../../../../../../../models/common";

import styles from "./styles.module.scss";

interface Props {
  classID: string;
  testID: string;
  questionID: string;
  subtype: string;
  type: string;
  answerChoices: answerChoiceModel[];
  matchings: any;
  testErrors: ErrorObject[];
  createQuestionAnswerChoice: createQuestionAnswerChoiceFunction;
  updateQuestionAnswerChoice: updateQuestionAnswerChoiceFunction;
  deleteQuestionAnswerChoice: deleteQuestionAnswerChoiceFunction;
  createQuestionMatching: createQuestionMatchingFunction;
  updateQuestionMatching: updateQuestionMatchingFunction;
  deleteQuestionMatching: deleteQuestionMatchingFunction;
  isOnline: boolean;
}

export const QuestionAnswers = (props: Props) => {
  const updateQuestion = (
    name_html: string,
    name: string,
    id: string,
    isCorrect: boolean,
  ) => {
    const answerChoice = props.answerChoices.find((item) => item.id === id);

    if (answerChoice) {
      props.updateQuestionAnswerChoice({
        answerID: id,
        questionID: props.questionID,
        classID: props.classID,
        testID: props.testID,
        name,
        name_html,
        isCorrect: isCorrect,
      });
    }
  };

  const updateQuestionMatching = (
    item_html: string,
    item: string,
    matched_item_html: string,
    matched_item: string,
    id: string,
  ) => {
    const matching = props.matchings[0].find((item: any) => item.id === id);

    if (matching) {
      props.updateQuestionMatching({
        matchingID: id,
        questionID: props.questionID,
        classID: props.classID,
        testID: props.testID,
        item,
        item_html,
        matched_item,
        matched_item_html,
      });
    }
  };

  const containerClass = classNames({
    [styles.container]: true,
    [styles.containerTrueFalse]: props.type === QuestionTypes.TRUE_FALSE,
  });

  const addAnswerChoiceClass = classNames({
    [styles.addAnswerChoice]: true,
    [styles.addAnswerChoiceOnline]: props.isOnline,
  });

  const createQuestionAnswerChoice = () => {
    props.createQuestionAnswerChoice({
      questionID: props.questionID,
      classID: props.classID,
      testID: props.testID,
      name: "",
    });
  };

  const deleteQuestionAnswerChoice = (answerID: string) => {
    props.deleteQuestionAnswerChoice({
      questionID: props.questionID,
      classID: props.classID,
      testID: props.testID,
      answerID: answerID,
    });
  };

  const createQuestionMatching = () => {
    props.createQuestionMatching({
      questionID: props.questionID,
      classID: props.classID,
      testID: props.testID,
      item: "",
      item_html: "",
      matched_item: "",
      matched_item_html: "",
    });
  };

  const deleteQuestionMatching = (matchingID: string) => {
    props.deleteQuestionMatching({
      questionID: props.questionID,
      classID: props.classID,
      testID: props.testID,
      matchingID: matchingID,
    });
  };

  const isTrueFalse = () => {
    return props.type === QuestionTypes.TRUE_FALSE;
  };

  const isMultipleChoice = () => {
    return props.type === QuestionTypes.MULTIPLE_CHOICE;
  };

  const isMatching = () => {
    return props.type === QuestionTypes.MATCHING;
  };

  // const isFillInTheGaps = () => {
  //   return props.subtype === QuestionTypes.FILL_IN_THE_GAPS;
  // };

  const isSingleSubType = () => {
    return props.subtype === QuestionSubtype.SINGLE;
  };

  const isMultipleSubType = () => {
    return props.subtype === QuestionSubtype.MULTIPLE;
  };

  const getError = (id: string) =>
    getErrorMessage(props.testErrors, `questions/${id}/answer_choices`);
  const getMatchingQuestionError = (id: string) =>
    getErrorMessage(props.testErrors, `questions/${id}/matchings`);

  const questionListClass = classNames({
    [styles.list]: !isMatching(),
    [styles.listFull]: isMatching(),
  });

  return (
    <section className={containerClass}>
      <ul
        className={questionListClass}
        id={`question_list_${props.questionID}`}
      >
        {isTrueFalse() && (
          <TrueFalse
            answerChoices={props.answerChoices}
            updateQuestion={(name_html, name, id, isCorrect) =>
              updateQuestion(name_html, name, id, isCorrect)
            }
            questionID={props.questionID}
            testErrors={props.testErrors}
          />
        )}
        {isMultipleChoice() && isSingleSubType() && (
          <Single
            answerChoices={props.answerChoices}
            updateQuestion={(name_html, name, id, isCorrect) =>
              updateQuestion(name_html, name, id, isCorrect)
            }
            createQuestionAnswerChoice={createQuestionAnswerChoice}
            deleteQuestionAnswerChoice={(answerID: string) =>
              deleteQuestionAnswerChoice(answerID)
            }
            questionID={props.questionID}
            testErrors={props.testErrors}
          />
        )}
        {isMultipleChoice() && isMultipleSubType() && (
          <Multiple
            answerChoices={props.answerChoices}
            updateQuestion={(name_html, name, id, isCorrect) =>
              updateQuestion(name_html, name, id, isCorrect)
            }
            createQuestionAnswerChoice={createQuestionAnswerChoice}
            deleteQuestionAnswerChoice={(answerID: string) =>
              deleteQuestionAnswerChoice(answerID)
            }
            questionID={props.questionID}
            testErrors={props.testErrors}
          />
        )}
        {isMatching() && (
          <Matching
            matchings={props.matchings}
            updateQuestion={(
              item_html,
              item,
              matched_item_html,
              matched_item,
              id,
            ) =>
              updateQuestionMatching(
                item_html,
                item,
                matched_item_html,
                matched_item,
                id,
              )
            }
            createQuestionMatching={createQuestionMatching}
            deleteQuestionMatching={(matchingID: string) =>
              deleteQuestionMatching(matchingID)
            }
            questionID={props.questionID}
            testErrors={props.testErrors}
          />
        )}
      </ul>
      {(isMultipleChoice() || isTrueFalse()) && (
        <div className={styles.info}>
          <InfoIcon/>
          <span>{translate("creator.answer_choices_info")}</span>
        </div>
      )}
      {isMatching() && (
        <div className={styles.infoRight}>
          <InfoIcon/>
          <span>{translate("creator.matching-question-info")}</span>
        </div>
      )}
      {getError(props.questionID) && (
        <span className={styles.errorFullWidth}>
          {getError(props.questionID)}
        </span>
      )}
      {getMatchingQuestionError(props.questionID) && (
        <span className={styles.errorFullWidth}>
          {getMatchingQuestionError(props.questionID)}
        </span>
      )}
      {isMultipleChoice() && props.answerChoices.length < 6 && (
        <button
          className={addAnswerChoiceClass}
          onClick={createQuestionAnswerChoice}
        >
          <span>+&nbsp;</span>
          <span>{translate("creator.add-answer-choice")}</span>
        </button>
      )}
      {isMatching() &&
        props.matchings.length > 0 &&
        props.matchings[0].length < 6 && (
        <button
          className={addAnswerChoiceClass}
          onClick={createQuestionMatching}
        >
          <span>+&nbsp;</span>
          <span>{translate("creator.add-matching")}</span>
        </button>
      )}
    </section>
  );
};

export default QuestionAnswers;
