import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pathOr from "ramda/src/pathOr";

import GeneratePDF from "../../Creator/GeneratePDF";

import translate from "../../../../../services/translate";
import Modal, { ToggleContent } from "../../../../Common/Modal";
import DuplicateTestModal from "../../../../Common/DuplicateTestModal";
import DownloadTestModal from "../../../../Common/DownloadTestModal";

import { ReactComponent as PDFIcon } from "../../../../../assets/images/test_details/pdf.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/images/test_details/trash.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/test_details/copy.svg";
import { ReactComponent as TrashAltIcon } from "../../../../../assets/images/trash_alt.svg";

import { deleteTest, generatePDF } from "../../../../../redux/actions/test";

// Types
import { History, Location } from "history";
import {
  testStateModel,
  deleteTest as deleteTestFunction,
  generatePDF as generatePDFFunction,
  testTypes,
} from "../../../../../models/redux/test";
import { userStateModel } from "../../../../../models/redux/user";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  location: Location;
  test: testStateModel;
  classID: string;
  deleteTest: deleteTestFunction;
  generatePDF: generatePDFFunction;
  user: userStateModel;
}

export const TestDetailsSidebarRight = (props: Props) => {
  const [isCompact, setIsCompact] = useState(false);
  const [hideInstruction, setHideInstruction] = useState(false);

  const inProgressGeneratePDF = () =>
    pathOr(false, ["test", "in_progress", "generate_pdf"], props);

  const isTestOnline = () => props.test.type === testTypes.ONLINE;

  return (
    <div className={styles.sidebarRight}>
      <div
        style={{
          opacity: 0,
          position: "absolute",
          zIndex: -999,
          transform: "scale(0.1)",
          top: 0,
        }}
      >
        <GeneratePDF
          history={props.history}
          location={props.location}
          isCompact={isCompact}
          hideInstruction={hideInstruction}
        />
      </div>
      {props.classID && !isTestOnline() && (
        <ToggleContent
          toggle={(show: any) => (
            <button
              type="button"
              disabled={inProgressGeneratePDF()}
              onClick={show}
            >
              {inProgressGeneratePDF() ? (
                <FontAwesomeIcon className="fa-spin" icon="circle-notch"/>
              ) : (
                <PDFIcon/>
              )}
              <span>{translate("test.download-test")}</span>
            </button>
          )}
          content={(hide: () => {}) => (
            <DownloadTestModal
              history={props.history}
              location={props.location}
              hide={hide}
              isCompact={isCompact}
              setIsCompact={setIsCompact}
              hideInstruction={hideInstruction}
              setHideInstruction={setHideInstruction}
              inProgress={inProgressGeneratePDF()}
              classID={props.classID}
              testID={props.test.id}
              generatePDF={() => {
                const element = document.querySelector(
                  "#paper-pdf-generate",
                ) as HTMLElement;
                if (element.innerHTML) {
                  props.generatePDF({
                    testID: props.test.id,
                    html: element.innerHTML,
                  });
                }
              }}
              testDetails
              hasFullAccess={props.user.access.full_access}
              isOnline={isTestOnline()}
              hasErrors={!!props.test.errors.length}
            />
          )}
        />
      )}
      <ToggleContent
        toggle={(show: any) => (
          <button
            type="button"
            onClick={show}
            disabled={inProgressGeneratePDF()}
          >
            <CopyIcon/>
            <span>{translate("test.duplicate-test")}</span>
          </button>
        )}
        content={(hide: () => {}) => (
          <DuplicateTestModal
            testID={props.test.id}
            classID={props.classID}
            hide={hide}
            history={props.history}
          />
        )}
      />
      {props.classID && (
        <ToggleContent
          toggle={(show: any) => (
            <button
              type="button"
              onClick={show}
              disabled={inProgressGeneratePDF()}
            >
              <TrashIcon/>
              <span>{translate("test.delete-test")}</span>
            </button>
          )}
          content={(hide: () => {}) => (
            <Modal isSmall hide={hide}>
              <div className={styles.deleteQuestionModal}>
                <TrashAltIcon/>
                <span>{translate("tests.delete-test-confirmation")}</span>
                <div className={styles.buttonContainer}>
                  <button type="button" onClick={hide}>
                    {translate("global.no")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      hide();
                      props.deleteTest({
                        id: props.test.id,
                        classID: props.classID,
                      });
                      props.history.push(`/class/${props.classID}/tests`);
                    }}
                  >
                    {translate("global.yes")}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: { user: userStateModel }) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      deleteTest,
      generatePDF,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestDetailsSidebarRight);
