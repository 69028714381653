import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OutsideClickHandler from "react-outside-click-handler";
import propOr from "ramda/src/propOr";

import translate from "../../../services/translate";
import { outsideClickHelper } from "../../../services/common";

import { ReactComponent as ArrowDownGrey } from "../../../assets/images/small_arrow_down_grey.svg";

import Modal from "../Modal";
import Input from "../Input";

import { getClasses } from "../../../redux/actions/teacher";
import { duplicateTest } from "../../../redux/actions/test";
import { duplicateTestFromTestsBase } from "../../../redux/actions/testsBase";
import { getClass } from "../../../redux/actions/class";

// Types
import { History } from "history";
import {
  classModel,
  getClasses as getClassesFunction,
} from "../../../models/redux/teacher";
import { duplicateTest as duplicateTestFunction } from "../../../models/redux/test";
import { duplicateTestFromTestsBase as duplicateTestFromTestsBaseFunction } from "../../../models/redux/testsBase";
import { getClass as getClassFunction } from "../../../models/redux/class";

import styles from "./styles.module.scss";

interface Props {
  history: History;
  testID: string;
  classID?: string;
  classes: classModel[];
  duplicateTest: duplicateTestFunction;
  duplicateTestFromTestsBase: duplicateTestFromTestsBaseFunction;
  getClasses: getClassesFunction;
  getClass: getClassFunction;
  hide: () => any;
  currentClassID?: string;
  inProgress: boolean;
}

export const DuplicateTestModal = (props: Props) => {
  const [selectedClass, setSelectedClass] = useState({} as classModel);
  const [className, setClassName] = useState("");
  const [classList, setClassList] = useState([] as classModel[]);
  const [showDropdown, toggleDropdown] = useState(false);

  useEffect(() => {
    props.getClasses();

    setClassList(props.classes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClassList(props.classes);
  }, [props.classes]);

  const isNextButtonDisabled = () =>
    !(selectedClass && selectedClass.id) || props.inProgress;

  return (
    <Modal customStyles={styles.modal} hide={props.hide}>
      <div className={styles.duplicateTestModal}>
        <span>
          <span>{translate("global.duplicate")}</span>{" "}
          {translate("sign_up.a-test")}
        </span>
        <p>{translate("global.duplicate-test-description-2")}</p>
        <OutsideClickHandler
          onOutsideClick={(e: any) => {
            outsideClickHelper(e, "dropdown", () => toggleDropdown(false));
            setClassName(propOr("", "name", selectedClass));
            setClassList(props.classes);
          }}
        >
          <div className={styles.wrapper}>
            <div onClick={() => toggleDropdown(true)}>
              <Input
                id="class_name"
                type="text"
                name="class_name"
                value={className}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const classItem = props.classes.filter((item: classModel) =>
                    item.name.includes(e.target.value),
                  ) as classModel[];
                  setClassList(classItem);
                  setClassName(e.target.value);
                }}
                icon={() => <ArrowDownGrey/>}
                label={
                  <>
                    {translate("global.pick-up")}{" "}
                    {translate("sign_up.a-class-accusative")}
                  </>
                }
                labelHiddenOnActive
              />
            </div>
            {showDropdown && (
              <div className={styles.dropdown}>
                <ul>
                  {classList.map((item: classModel) => (
                    <li key={item.id}>
                      <button
                        type="button"
                        onClick={() => {
                          setClassName(item.name);
                          setSelectedClass(item);
                          toggleDropdown(false);
                        }}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                  {!classList.length && (
                    <li>
                      <span>{translate("global.no-results")}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </OutsideClickHandler>
        <button
          type="button"
          disabled={isNextButtonDisabled()}
          onClick={() => {
            if (props.classID || props.currentClassID) {
              props.duplicateTest(
                {
                  testID: props.testID,
                  classID: selectedClass.id,
                  history: props.history,
                },
                () => {
                  props.hide();
                  if (props.currentClassID) {
                    props.getClass({ id: props.currentClassID });
                  }
                },
              );
              return;
            }
            props.duplicateTestFromTestsBase(
              { testID: props.testID, classID: selectedClass.id },
              () => {
                props.hide();
              },
            );
          }}
        >
          {translate("global.duplicate")}
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  classes: state.teacher.classes,
  inProgress: state.test.in_progress.duplicate_test,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getClasses,
      duplicateTest,
      duplicateTestFromTestsBase,
      getClass,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateTestModal);
