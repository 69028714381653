import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import queryString from "query-string";

import TestDetailsHeader from "./Header";
import TestDetailsPreviewTest from "./PreviewTest";
import TestDetailsSidebarLeft from "./SidebarLeft";
import TestDetailsSidebarRight from "./SidebarRight";

import TestOnlineSolve from "../Online/Solve";

import { ReactComponent as CopyIcon } from "../../../../assets/images/referral/copy.svg";

import { showToast } from "../../../../redux/actions/app";
import {
  getTest,
  unmountTest,
  updateTest,
  validateTest,
} from "../../../../redux/actions/test";
import { getTestFromTestsBase } from "../../../../redux/actions/testsBase";

// Types
import { History, Location } from "history";
import {
  testStateModel,
  getTest as getTestFunction,
  updateTest as updateTestFunction,
  testTypes,
  validateTest as validateTestFunction,
} from "../../../../models/redux/test";
import { getTestFromTestsBase as getTestFromTestsBaseFunction } from "../../../../models/redux/testsBase";
import {
  teacherStateModel,
  classModel,
} from "../../../../models/redux/teacher";

import styles from "./styles.module.scss";
import translate from "../../../../services/translate";
import { copyToClipboard } from "../../../../services/common/copyToClipboard";

interface Props {
  history: History;
  location: Location;
  match: any;
  test: testStateModel;
  getTest: getTestFunction;
  getTestFromTestsBase: getTestFromTestsBaseFunction;
  unmountTest: () => {};
  classes: classModel[];
  updateTest: updateTestFunction;
  showToast: any;
  validateTest: validateTestFunction;
}

export const TestDetails = (props: Props) => {
  const [mode, setMode] = useState("previewMode");

  const parsed = queryString.parse(props.location.search);
  const classID = parsed.classID as string;
  const testID = parsed.testID as string;

  useEffect(() => {
    if (classID) {
      props.getTest({ classID, testID });
      props.validateTest({ testID, callback: () => {} });
    } else {
      props.getTestFromTestsBase({ testID });
    }

    return () => {
      props.unmountTest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewMode = () => mode === "previewMode";

  return (
    <div className={styles.container}>
      <TestDetailsHeader
        history={props.history}
        location={props.location}
        test={props.test}
        classID={classID}
        updateTest={props.updateTest}
        getTest={() => props.getTest({ classID, testID })}
      />
      {props.test.type === testTypes.ONLINE && (
        <div className={styles.settingsBox}>
          <div className={styles.onlineTestLinkBox}>
            <div className={styles.left}>
              <span>{translate("global.test-online")}</span>
              {props.test.errors.length ? (
                <p className={styles.error}>
                  {translate("test.your-test-has-errors-description")}
                </p>
              ) : (
                <p>
                  {translate("test.public-link-to-online-test-description")}
                </p>
              )}
            </div>
            <div className={styles.right}>
              {!props.test.errors.length && (
                <div className={styles.inputContainer}>
                  <input
                    id="testonline_link_input"
                    type="text"
                    value={`${window.location.origin}/test-online${props.history.location.search}`}
                    onChange={() => {}}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      copyToClipboard("testonline_link_input", () =>
                        props.showToast({
                          text: "global.copied",
                          status: "success",
                        }),
                      )
                    }
                  >
                    <CopyIcon/>
                    &nbsp;&nbsp;
                    <span>{translate("global.copy")}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.content}>
        {props.test.id && (
          <TestDetailsSidebarLeft mode={mode} setMode={setMode}/>
        )}
        {previewMode && props.test.id && (
          <>
            {props.test.type === testTypes.PAPER && (
              <TestDetailsPreviewTest
                history={props.history}
                location={props.location}
                test={props.test}
                classID={classID}
                classes={props.classes}
              />
            )}
            {props.test.type === testTypes.ONLINE && (
              <TestOnlineSolve
                history={props.history}
                location={props.location}
                match={props.match}
                isReadOnly
              />
            )}
          </>
        )}
        {props.test.id && (
          <TestDetailsSidebarRight
            history={props.history}
            location={props.location}
            test={props.test}
            classID={classID}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  test: testStateModel;
  teacher: teacherStateModel;
}) => ({
  test: state.test,
  classes: state.teacher.classes,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTest,
      unmountTest,
      getTestFromTestsBase,
      updateTest,
      showToast,
      validateTest,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestDetails);
