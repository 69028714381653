import { ofType, ActionsObservable, Epic } from "redux-observable";
import { switchMap, pluck } from "rxjs/operators";
import {
  getClassReportSuccess,
  getClassReportFailure,
  generateClassReportPDFSuccess,
  generateClassReportPDFFailure,
  getStudentReportSuccess,
  getStudentReportFailure,
  generateStudentReportPDFSuccess,
  generateStudentReportPDFFailure,
} from "../../actions/report";

import { apiCall } from "../../../services/api";

// Types
import {
  getClassReportAction,
  getClassReportPayload,
  generateClassReportPDFAction,
  generateClassReportPDFPayload,
  getStudentReportAction,
  getStudentReportPayload,
  generateStudentReportPDFAction,
  generateStudentReportPDFPayload,
} from "../../../models/redux/report";
import { XHRPayload } from "../../../models/common";


// Get Class Report

export const getClassReportEpic: Epic = (action$: ActionsObservable<getClassReportAction>) => (
  action$.pipe(
    ofType("GET_CLASS_REPORT"),
    pluck("payload"),
    switchMap((payload: getClassReportPayload) => getClassReport(payload)),
  )
);

export const getClassReport = (payload: getClassReportPayload) => (
  apiCall(
    `/reports/tests/${payload.testID}`,
    "GET",
    {},
    (data: XHRPayload) => getClassReportSuccess(data.response),
    getClassReportFailure,
  )
);

// Get Student Report

export const getStudentReportEpic: Epic = (action$: ActionsObservable<getStudentReportAction>) => (
  action$.pipe(
    ofType("GET_STUDENT_REPORT"),
    pluck("payload"),
    switchMap((payload: getStudentReportPayload) => getStudentReport(payload)),
  )
);

export const getStudentReport = (payload: getStudentReportPayload) => (
  apiCall(
    `/reports/tests/${payload.testID}/students/${payload.studentID}`,
    "GET",
    {},
    (data: XHRPayload) => getStudentReportSuccess(data.response),
    getStudentReportFailure,
  )
);

// Generate Class Report PDF

export const generateClassReportPDFEpic: Epic = (action$: ActionsObservable<generateClassReportPDFAction>) => (
  action$.pipe(
    ofType("GENERATE_CLASS_REPORT_PDF"),
    pluck("payload"),
    switchMap((payload: generateClassReportPDFPayload) => generateClassReportPDF(payload)),
  )
);

export const generateClassReportPDF = (payload: generateClassReportPDFPayload) => (
  apiCall(
    `/reports/tests/${payload.testID}/generate`,
    "POST",
    {},
    (data: XHRPayload) => generateClassReportPDFSuccess(data.response),
    generateClassReportPDFFailure,
  )
);

// Generate Student Report PDF

export const generateStudentReportPDFEpic: Epic = (action$: ActionsObservable<generateStudentReportPDFAction>) => (
  action$.pipe(
    ofType("GENERATE_STUDENT_REPORT_PDF"),
    pluck("payload"),
    switchMap((payload: generateStudentReportPDFPayload) => generateStudentReportPDF(payload)),
  )
);

export const generateStudentReportPDF = (payload: generateStudentReportPDFPayload) => (
  apiCall(
    `/reports/tests/${payload.testID}/students/${payload.studentID}/generate`,
    "POST",
    {},
    (data: XHRPayload) => generateStudentReportPDFSuccess(data.response),
    generateStudentReportPDFFailure,
  )
);
